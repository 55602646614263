import React, { Component } from 'react';
import { Col, Row, Card, Input, Form, Spinner } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import strik from '../../assets/img/strik.png';
import {
  CLEAR_TICKETS_STATE,
  UPDATE_TICKETS_PROPS,
  CLEAR_GENERAL_STATE,
} from '../../Redux/Actions/types';
// import GeneralMap from "../../General/GeneralMap";
import { handleSaveTicket, renderSectorOptions } from './TicketFunctions';
import { isRtl, selectFormStyle } from '../../General/GeneralStyle';
import { getDefaultValue } from '../../General/SelectFunctions';
import Select from 'react-select';
import { renderErrorMessage } from '../../General/GeneralFunctions';
import { getUser } from '../../utils/StorageFunctions';
import SearchBox from '../../General/MapSearch/SearchBox';
import { extractLatLng } from '../../General/MapFunctions';
import TooMap from '../../General/TooMap';

class CreateTicket extends Component {
  componentDidMount() {
    if (getUser() === null) {
      this.props.history.push('/login');
    }
    this.props.general([], CLEAR_GENERAL_STATE);

    this.props.general([], CLEAR_TICKETS_STATE);
  }

  onMapClick = (lat, lng) => {
    const { general } = this.props;
    general(
      [{ prop: 'data.form.location', value: [`POINT(${lng} ${lat})`] }],
      UPDATE_TICKETS_PROPS
    );
  };

  render() {
    const {
      history,
      form,
      isLoadingCreate,
      general,
      location,
      addTicket,
      mapSearch,
      myPosition,
      ipAddress,
      getSocketResult,
      errorMessages,
    } = this.props;
    return (
      <div className='page-container'>
        <Row>
          <Col
            xl={8}
            lg={8}
            md={8}
            sm={12}
            xs={12}
            className='mx-auto text-center'
          >
            <h3 className='page_create_title'>{local.AddTicket}</h3>
            <Card className='create_card'>
              <Form>
                <Row>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className='mt-3 mx-auto'
                  >
                    <div className='container_add_input mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <Input
                        className='add_input'
                        value={form.title}
                        onChange={(e) =>
                          general(
                            [
                              {
                                prop: 'data.form.title',
                                value: e.target.value,
                              },
                            ],
                            UPDATE_TICKETS_PROPS
                          )
                        }
                        placeholder={local.Title}
                      />
                    </div>
                    {renderErrorMessage(form.title, errorMessages.Title)}
                  </Col>

                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className='mt-3 mx-auto'
                  >
                    <div className='container_add_input mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <div className='form_select_container'>
                        <Select
                          key={form.sector}
                          placeholder={local.Sector}
                          value={getDefaultValue(
                            form.sector,
                            renderSectorOptions()
                          )}
                          options={renderSectorOptions()}
                          onChange={(e) =>
                            e !== null
                              ? general(
                                  [
                                    {
                                      prop: 'data.form.sector',
                                      value: e.value,
                                    },
                                  ],
                                  UPDATE_TICKETS_PROPS
                                )
                              : general(
                                  [
                                    {
                                      prop: 'data.form.sector',
                                      value: null,
                                    },
                                  ],
                                  UPDATE_TICKETS_PROPS
                                )
                          }
                          isClearable={true}
                          isRtl={isRtl()}
                          styles={selectFormStyle}
                          isSearchable={true}
                        />
                      </div>
                    </div>
                    {renderErrorMessage(form.sector, errorMessages.Sector)}
                  </Col>

                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className='mt-3 mx-auto'
                  >
                    <div className='container_add_input mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <Input
                        className='add_input'
                        value={form.description}
                        onChange={(e) =>
                          general(
                            [
                              {
                                prop: 'data.form.description',
                                value: e.target.value,
                              },
                            ],
                            UPDATE_TICKETS_PROPS
                          )
                        }
                        placeholder={local.Description}
                      />
                    </div>
                    {renderErrorMessage(
                      form.description,
                      errorMessages.Description
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className='mt-3 mx-auto'
                  >
                    <Card
                      className='mapCard'
                      style={{
                        height: '490px',
                        width: '100%',
                      }}
                    >
                      <SearchBox
                        mapSearch={mapSearch}
                        general={general}
                        action={UPDATE_TICKETS_PROPS}
                        propObj={[{ prop: 'data.form.location', value: [] }]}
                        myPosition={myPosition}
                        ipAddress={ipAddress}
                        getSocketResult={getSocketResult}
                        handlePlaceSearch={this.onMapClick}
                      />

                      <TooMap
                        location={location}
                        onMapClick={this.onMapClick}
                        general={general}
                        point={extractLatLng(form.location)}
                      />
                      {/* <GeneralMap
                            location={location}
                            onMapClick={this.onMapClick}
                            general={general}
                            point={extractLatLng(form.location)}
                          /> */}
                    </Card>
                    {form.location.length === 0 && errorMessages.Location ? (
                      <span className='error_message'>
                        {errorMessages.Location}
                      </span>
                    ) : null}
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className='mt-5 mx-auto'
                  >
                    <button
                      className='save_btn'
                      onClick={(e) =>
                        handleSaveTicket(e, addTicket, form, general)
                      }
                    >
                      {isLoadingCreate === true ? <Spinner /> : local.Save}
                    </button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const GeneralMaptateToProps = ({ TicketsR, GeneralR }) => {
  return {
    form: TicketsR.data.form,
    errorMessages: TicketsR.data.errorMessages,
    isLoadingCreate: TicketsR.isLoadingCreate,
    location: GeneralR.location,
    mapSearch: GeneralR.mapSearch,
    myPosition: GeneralR.myPosition,
    ipAddress: GeneralR.ipAddress,
  };
};
export default connect(GeneralMaptateToProps, actions)(CreateTicket);
