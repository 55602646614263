import {
  UPDATE_USERS_PROPS,
  MERGE_USERS_PROPS,
  DELETE_USERS_PROPS,
  CLEAR_USERS_STATE,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    items: null,
    pagination: null,
    guests: null,
    filterArray: [
      {
        op: 4,
        fieldName: "FirstName",
        value: "",
      },

      {
        op: 3,
        fieldName: "Country",
        value: "",
      },
      {
        op: 4,
        fieldName: "PhoneNumber",
        value: "",
      },
      {
        op: 0,
        fieldName: "",
        value: null,
      },
      {
        op: 4,
        fieldName: "Email",
        value: "",
      },
    ],
    isLoading: false,
    isLoadingSearch: false,
  },
  form: {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    gender: null,
    birthDate: null,
    country: "",
    phoneNumber: "",
    id: "",
  },
  isLoadingCreate: false,
  userStatistics: {
    total: 0,
    inActiveByEmail: 0,
    activeByEmail: 0,
    inActiveByPhone: 0,
    activeByPhone: 0,
  },

  usersOptions: [],
};

const UsersR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_USERS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_USERS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_USERS_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_USERS_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default UsersR;
