import LocalizedStrings from "react-localization";
import { MAP_LANGUAGE } from "../General/StaticKeys";
import { en } from "./en";
import { ar } from "./ar";

export const local = new LocalizedStrings({
  en: en,
  ar: ar,
});

export const toogleLanguage = (lang) => {
  if (lang === "en") {
    localStorage.removeItem(MAP_LANGUAGE);

    local.setLanguage("en");
    localStorage.setItem(MAP_LANGUAGE, "en");
  } else {
    localStorage.removeItem(MAP_LANGUAGE);

    local.setLanguage("ar");
    localStorage.setItem(MAP_LANGUAGE, "ar");
  }

  window.location.href = "";
  // window.location.reload();
};

export const changeLanguage = () => {
  let lang = localStorage.getItem(MAP_LANGUAGE);
  if (lang !== null) {
    if (lang === "en") {
      local.setLanguage("en");
      document.documentElement.setAttribute("dir", "ltr");
      document.documentElement.setAttribute("lang", "en");
      document.title = "Mapy";
    } else {
      local.setLanguage("ar");
      document.documentElement.setAttribute("dir", "rtl");
      document.documentElement.setAttribute("lang", "ar");
      document.title = "مابي";
    }
  } else {
    local.setLanguage("ar");
    localStorage.setItem(MAP_LANGUAGE, "ar");
    document.documentElement.setAttribute("dir", "rtl");
    document.documentElement.setAttribute("lang", "ar");
    document.title = "مابي";
  }
};

export const getLanguage = () => {
  let lang = localStorage.getItem(MAP_LANGUAGE);
  if (lang !== null) {
    return lang;
  }
};

export const changeDirection = () => {
  let dir = getLanguage() === "en" ? "ltr" : "rtl";
  return dir;
};
