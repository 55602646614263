import { showConfirmMessage } from "../../General/GeneralFunctions";
import { MAP_USER } from "../../General/StaticKeys";
import { local } from "../../lang/local";

export const check_is_authorize = (history) => {
  let item = JSON.parse(localStorage.getItem(MAP_USER));

  if (item !== null) {
    if (item.phoneNumberConfirmed === false) {
      showConfirmMessage(local.VerifiyByPhone, "error");
      history.push("/confirm_sms");
    } else {
      if (item.data !== undefined) {
        if (item.data.token !== undefined) {
          history.push("/");
        }
      }
    }
  }
};
