import React from "react";
import {
  Col,
  Row,
  Input,
  Label,
  Button,
  UncontrolledCollapse,
} from "reactstrap";
import { local } from "../../lang/local";
import avater2 from "../../assets/img/avater2.svg";
import { columnAlignLeft } from "../../General/GeneralStyle";
import { WorkingHoursCard } from "../../General/TableFunctions";
import { FaAngleDown } from "react-icons/fa";
import { renderMultipleImages } from "../../General/GeneralFunctions";
const ContribuationProfile = ({ data, general, workingDaysModal }) => {
  return data ? (
    <div className="mt-3 mb-3">
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mx-auto mt-3">
          <div className="img_profile_container mx-auto">
            {renderMultipleImages(data.images, general, avater2, true)}
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Id}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.id}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.EnglishName}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.name?.en}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.ArabicName}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.name?.ar}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Mobile}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.osm.phone}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Layer}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.layer}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Categories}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.categories.toString()}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Website}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.osm.website}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Status}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={
                !data.approved
                  ? local.Pedning
                  : data.approved === false
                  ? local.Reject
                  : local.Accept
              }
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        {Object.entries(data.osm).map((entry, index) => {
          if (
            entry[0] !== "phone" &&
            entry[0] !== "website" &&
            entry[0] !== "description" &&
            entry[0] !== "phone" &&
            entry[0] !== "workinghours"
          )
            return (
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={`${columnAlignLeft()} mx-auto mt-3`}
                key={index}
              >
                <Label className="bold">
                  {local[entry[0]] ? local[entry[0]] : entry[0]}
                </Label>
                <div className="container_add_input mx-auto mt-1">
                  <Input
                    className="add_input normal"
                    value={entry[1]}
                    disabled={true}
                  />
                </div>
              </Col>
            );
        })}
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Address}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.osm.description?.key}
              disabled={true}
            />
          </div>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
          style={{
            color: "white !important",
          }}
        >
          <Label className="bold">{local.OpenningHours}</Label>

          <div
            className="container_add_input mx-auto mt-1"
            style={{ marginBottom: "1rem" }}
          >
            <Button className="hours_profile_btn center" id="toggler">
              <FaAngleDown />
              <p>{local.OpenningHours}</p>
            </Button>
          </div>
          <UncontrolledCollapse toggler="#toggler">
            <WorkingHoursCard
              workingDaysModal={{
                isOpen: workingDaysModal.isOpen,
                data: data.osm.workinghours,
              }}
            />
          </UncontrolledCollapse>
        </Col>
      </Row>
    </div>
  ) : null;
};

export default ContribuationProfile;
