import React, { Component } from 'react';
import { Col, Row, Card, Input, Form, Spinner } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import strik from '../../assets/img/strik.png';
import {
  UPDATE_ROLES_PROPS,
  CLEAR_ROLES_STATE,
} from '../../Redux/Actions/types';
import { handleSave } from './RolesFunctions';
import {renderErrorMessage } from '../../General/GeneralFunctions';
import { getUser } from '../../utils/StorageFunctions';
class CreateRole extends Component {
  componentDidMount() {
    if (getUser() === null) {
      this.props.history.push('/login');
    }
    this.props.general([], CLEAR_ROLES_STATE);
  }

  render() {
    const { form, isLoadingCreate, general, addRole, errorMessages } =
      this.props;
    return (
      <div className='page-container'>
        <Row>
          <Col
            xl={8}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className='mx-auto text-center'
          >
            <h3 className='page_create_title'>{local.AddRole}</h3>
            <Card className='create_card'>
              <Form>
                <Row>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    className='mt-3 mx-auto'
                  >
                    <div className='container_add_input ticket_input_form mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <Input
                        className='add_input'
                        value={form.name}
                        onChange={(e) =>
                          general(
                            [
                              {
                                prop: 'data.form.name',
                                value: e.target.value,
                              },
                            ],
                            UPDATE_ROLES_PROPS
                          )
                        }
                        placeholder={local.Name}
                      />
                    </div>
                    {renderErrorMessage(form.name, errorMessages.Name)}
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    className='mt-4 mx-auto'
                  >
                    <div className='container_add_input ticket_input_form mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <Input
                        className='add_input'
                        value={form.description}
                        onChange={(e) =>
                          general(
                            [
                              {
                                prop: 'data.form.description',
                                value: e.target.value,
                              },
                            ],
                            UPDATE_ROLES_PROPS
                          )
                        }
                        placeholder={local.Description}
                      />
                    </div>
                    {renderErrorMessage(
                      form.description,
                      errorMessages.Description
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    className='mt-5 mx-auto'
                  >
                    <button
                      className='save_btn'
                      onClick={(e) => handleSave(e, addRole, form, general)}
                    >
                      {isLoadingCreate === true ? <Spinner /> : local.Save}
                    </button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ RolesR, GeneralR }) => {
  return {
    form: RolesR.data.form,
    errorMessages: RolesR.data.errorMessages,
    isLoadingCreate: RolesR.data.isLoadingCreate,
    location: GeneralR.location,
  };
};
export default connect(mapStateToProps, actions)(CreateRole);
