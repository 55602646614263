import React, { useState, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
// import BreadHeader from './BreadHeader';
import routes from '../routes';
import * as actions from '../../Redux/Actions/index';

import './layout.scss';

const Header = React.lazy(() => import('./Header'));
const Aside = React.lazy(() => import('./Aside'));

function Layout({ history, getLocationWithIp }) {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [active, setActive] = useState('');

  useEffect(() => {
    getLocationWithIp();
  }, []);

  useEffect(() => {
    if (history.location.pathname !== '/') {
      setActive(
        history.location.pathname.split('/')[
          history.location.pathname.split('/').length - 1
        ]
      );
    }
  }, []);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = () => {
    setToggled(!toggled);
  };
  const loading = () => (
    <div className='animated fadeIn pt-1 text-center'>{/* <Loader /> */}</div>
  );
  const goToPage = (e, path, value) => {
    e.preventDefault();
    if (path) {
      history.push(path);
      setActive(value);
    }
  };

  return (
    <>
      <Suspense fallback={loading()}>
        <Header
          history={history}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
      </Suspense>
      <div className={`app ${toggled ? 'toggled' : ''}`}>
        <Suspense fallback={loading()}>
          <Aside
            collapsed={collapsed}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            active={active}
            goToPage={goToPage}
          />
        </Suspense>

        <main>
          {/* <BreadHeader history={history} active={active} goToPage={goToPage} /> */}
          <div className='block'>
            <Switch>
              {routes.map((route) => {
                let Component = route.component;
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    name={route.name}
                    render={(props) => <Component {...props} />}
                    exact
                  />
                );
              })}
            </Switch>
          </div>
        </main>
      </div>
    </>
  );
}

export default connect(null, actions)(Layout);
