import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Input,
  Card,
  Form,
  Button,
  Spinner,
} from "reactstrap";
import mapy from "../../assets/img/auth/mapy.png";
import altawasol from "../../assets/img/auth/altawasol.png";
import { showConfirmMessage } from "../../General/GeneralFunctions";
import { getLanguage, local } from "../../lang/local";
import { connect } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import { UPDATE_AUTH_PROPS } from "../../Redux/Actions/types";
import MenuLanguage from "../../General/MenuLanguage";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";

import { renderEyePassword } from "../../Pages/Register/RegisterFunction";
import { MAP_USER } from "../../General/StaticKeys";

const ResetPassword = (props) => {
  const { history, form, resetPassword, isLoading, general } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem(MAP_USER));

    if (!form.email && !form.phone && user) {
      if (!user.phone && user.email) {
        general(
          [
            { prop: "resetForm.email", value: user.email },
            { prop: "resetForm.phone", value: user.phone },
          ],
          UPDATE_AUTH_PROPS
        );
      }

      if (form.phone && !form.email && user) {
        general(
          [
            { prop: "resetForm.email", value: user.email },
            { prop: "resetForm.phone", value: user.phone },
          ],
          UPDATE_AUTH_PROPS
        );
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      general(
        [
          {
            prop: "resetForm",
            value: {
              password: "",
              confirmPassword: "",
              email: "",
              phone: "",
              token: "",
            },
          },
        ],
        UPDATE_AUTH_PROPS
      );
    };
  }, [props.current]);
  const handleReset = () => {
    if (!form.token || !form.password || !form.confirmPassword) {
      if (!form.token) {
        showConfirmMessage(local.CodeRequired, "error");
      }

      if (!form.password) {
        showConfirmMessage(local.EnterPassword, "error");
      }

      if (form.password && form.password.length < 8) {
        showConfirmMessage(local.PasswordValidate, "error");
      }

      if (!form.confirmPassword) {
        showConfirmMessage(local.ConfirmPasswordRequired, "error");
      }

      if (!form.password !== !form.confirmPassword) {
        showConfirmMessage(local.PasswordMatched, "error");
      }
    } else {
      resetPassword(form, history);
    }
  };

  return (
    <div className="app">
      <div className="app-body">
        <div className="main_container login_wrapper">
          <MenuLanguage />

          <Container>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
                <img src={mapy} alt="mapy" width="200" />
              </Col>
            </Row>
            <Row>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="mx-auto mt-5"
              >
                <Card className="create_card sign_card">
                  <Form>
                    <Row>
                      <Col
                        xl={8}
                        lg={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className="mx-auto mt-5"
                      >
                        <div className="container_add_input mx-auto">
                          <Input
                            type="text"
                            placeholder={local.ConfirmationCode}
                            className="add_input"
                            value={form.token}
                            onChange={(e) =>
                              general(
                                [
                                  {
                                    prop: "resetForm.token",
                                    value: e.target.value,
                                  },
                                ],
                                UPDATE_AUTH_PROPS
                              )
                            }
                          />
                        </div>
                        <input
                          type="text"
                          style={{
                            position: "absolute",
                            zIndex: "-1",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {form.email ? (
                        <Col
                          xl={8}
                          lg={8}
                          md={8}
                          sm={12}
                          xs={12}
                          className="mx-auto mt-5"
                        >
                          <div className="container_add_input mx-auto">
                            <Input
                              disabled={true}
                              type="email"
                              placeholder={local.Email}
                              className="add_input"
                              value={form.email}
                              onChange={(e) =>
                                general(
                                  [
                                    {
                                      prop: "resetForm.email",
                                      value: e.target.value,
                                    },
                                  ],
                                  UPDATE_AUTH_PROPS
                                )
                              }
                            />
                          </div>
                        </Col>
                      ) : null}
                      {form.phone ? (
                        <Col
                          xl={8}
                          lg={8}
                          md={8}
                          sm={12}
                          xs={12}
                          className="mx-auto mt-5"
                        >
                          <div className="container_add_input mx-auto">
                            <PhoneInput
                              disabled={true}
                              lang={getLanguage() === "ar" ? ar : ""}
                              country={"sa"}
                              // onlyCountries={[
                              //   "eg",
                              //   "sa",
                              //   "ae",
                              //   "ku",
                              //   "lb",
                              //   "om",
                              //   "bh",
                              // ]}
                              masks={{
                                eg: ".. ........",
                                sa: ".. ... ....",
                                ae: ".. .......",
                              }}
                              countryCodeEditable={false}
                              value={form.phone}
                              onChange={(e) =>
                                general(
                                  [{ prop: "resetForm.phone", value: e }],
                                  UPDATE_AUTH_PROPS
                                )
                              }
                            />
                          </div>
                        </Col>
                      ) : null}
                    </Row>

                    <Row>
                      <Col
                        xl={8}
                        lg={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className="mx-auto mt-5"
                      >
                        <input
                          type="password"
                          style={{
                            position: "absolute",
                            zIndex: "-1",
                          }}
                        />
                        <div className="container_add_input mx-auto">
                          <Input
                            type={showPassword === true ? "text" : "password"}
                            placeholder={local.Password}
                            className="removed_input_style pass_input"
                            value={form.password}
                            onChange={(e) =>
                              general(
                                [
                                  {
                                    prop: "resetForm.password",
                                    value: e.target.value,
                                  },
                                ],
                                UPDATE_AUTH_PROPS
                              )
                            }
                          />

                          {renderEyePassword(showPassword, setShowPassword)}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xl={8}
                        lg={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className="mx-auto mt-5"
                      >
                        <div className="container_add_input mx-auto">
                          <Input
                            type={
                              showConfirmPassword === true ? "text" : "password"
                            }
                            placeholder={local.ConfirmPassword}
                            className="removed_input_style pass_input"
                            value={form.confirmPassword}
                            onChange={(e) =>
                              general(
                                [
                                  {
                                    prop: "resetForm.confirmPassword",
                                    value: e.target.value,
                                  },
                                ],
                                UPDATE_AUTH_PROPS
                              )
                            }
                          />
                          {renderEyePassword(
                            showConfirmPassword,
                            setShowConfirmPassword
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="ml-3 mr-3 mb-5">
                      <Col
                        xl={8}
                        lg={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className="mx-auto mt-5 mb-2"
                      >
                        <Button
                          className="save_btn mx-auto"
                          onClick={handleReset}
                        >
                          {isLoading === true ? <Spinner /> : local.Save}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="center mt-5"
              >
                <p className="mobile_signin_text" style={{ color: "#3B3B3B" }}>
                  {local.SIGNFROM + " "}
                  <span
                    className="mobile_signin_text cursor bold"
                    style={{ color: "#12A9CC" }}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/login");
                    }}
                  >
                    {local.HERE}
                  </span>
                </p>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
                <img src={altawasol} alt="mapy" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ AuthR }) => {
  return {
    form: AuthR.resetForm,
    isLoading: AuthR.isLoadingReset,
  };
};
export default connect(mapStateToProps, actions)(ResetPassword);
