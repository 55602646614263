import { SendRequest } from "./api";
import { UPDATE_COMPLAINTS_PROPS } from "./types";
import {
  convertToFormData,
  showConfirmMessage,
  showSuccessedConfirm
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
export const fetchComplaints = (filterArray, page) => dispatch => {
  if (filterArray.length > 1) {
    dispatch({
      type: UPDATE_COMPLAINTS_PROPS,
      payload: [{ prop: "data.isLoadingSearch", value: true }]
    });
  }
  dispatch({
    type: UPDATE_COMPLAINTS_PROPS,
    payload: [{ prop: "data.isLoading", value: true }]
  });
  SendRequest()
    .post(`/GeoEvent/Get?page=${page}`, filterArray)
    .then(res => {
      if (res.status === 200) {
        let pagination = Object.assign({}, res.data.data);
        delete pagination.items;
        debugger;
        if (res.data.data.items.length === 0 && filterArray.length > 1) {
          showConfirmMessage(local.NoSearch, "error");
        }
        dispatch({
          type: UPDATE_COMPLAINTS_PROPS,
          payload: [
            { prop: "data.items", value: res.data.data.items },
            { prop: "data.pagination", value: pagination }
          ]
        });
      }

      dispatch({
        type: UPDATE_COMPLAINTS_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false }
        ]
      });
    })
    .catch(e => {
      dispatch({
        type: UPDATE_COMPLAINTS_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false }
        ]
      });
    })
    .finally();
};

export const addComplaint = formValues => dispatch => {
  dispatch({
    type: UPDATE_COMPLAINTS_PROPS,
    payload: [{ prop: "data.isLoadingCreate", value: true }]
  });
  let newFormValues = Object.assign({}, formValues);

  let formData = convertToFormData(newFormValues);
  SendRequest()
    .post("/GeoEvent", formData)
    .then(response => {
      if (response.status === 200) {
        dispatch({
          type: UPDATE_COMPLAINTS_PROPS,
          payload: [
            {
              prop: "data.form",
              value: {
                GeoEventsTypeId: formValues.GeoEventsTypeId,
                Note: "",
                Location: formValues.Location,
                Images: []
              }
            }
          ]
        });

        showSuccessedConfirm(local.AddedSuccessfully);
      }
      dispatch({
        type: UPDATE_COMPLAINTS_PROPS,
        payload: [{ prop: "data.isLoadingCreate", value: false }]
      });
    })
    .catch(e => {
      console.log(e);
      if (Object.values(e)[2] !== undefined) {
        if (Object.values(e)[2].data !== undefined) {
          showConfirmMessage(Object.values(e)[2].data.message, "error");
        }
      }
      dispatch({
        type: UPDATE_COMPLAINTS_PROPS,
        payload: [{ prop: "data.isLoadingCreate", value: false }]
      });
    })
    .finally();
};
