import { saveRecentSearch, getZoom } from "../../../General/GeneralFunctions";
import {
  UPDATE_LOCATION_MENU_PROPS,
  UPDATE_SEARCH_MENU_PROPS,
  UPDATE_USER_ACCOUNT_PROPS,
} from "../../../Redux/Actions/types";

export const handlePlaceClick = (
  e,
  item,
  general,
  getSocketResult,
  myPosition,
  ipAddress,
  searchImages
) => {
  general(
    [
      {
        prop: "location_data.lat",
        value: "",
      },
      { prop: "location_data.images_names", value: [] },
      { prop: "location_data.images", value: [] },
    ],

    UPDATE_LOCATION_MENU_PROPS
  );
  let value = item.data
    ? item.data.properties.name
      ? item.data.properties.name
      : item.label
    : item.label;
  searchImages(value);
  saveRecentSearch(item.value[1], item.value[0], item.label);
  e.preventDefault();
  general(
    [
      { prop: "search_data.defaultZoom", value: getZoom(item.layer) },
      { prop: "search_data.map_lat", value: item.value[1] },
      { prop: "search_data.map_lng", value: item.value[0] },
      { prop: "search_data.toViewSearch", value: false },
    ],
    UPDATE_SEARCH_MENU_PROPS
  );

  general(
    [{ prop: "userAccount.isOpen", value: false }],
    UPDATE_USER_ACCOUNT_PROPS
  );
  general(
    [
      { prop: "location_data.placeType", value: "place" },
      {
        prop: "location_data.address",
        value: item.label,
      },
      {
        prop: "location_data.toViewSideInfo",
        value: true,
      },
      {
        prop: "location_data.lat",
        value: item.value[1],
      },

      {
        prop: "location_data.lng",
        value: item.value[0],
      },

      {
        prop: "location_data.distance",
        value: "0 Km",
      },
      {
        prop: "location_data.icon_type",
        value: "search",
      },
      {
        prop: "location_data.gis_id",
        value: item.data
          ? item.data.properties
            ? item.data.properties.gid
            : ""
          : "",
      },
    ],
    UPDATE_LOCATION_MENU_PROPS
  );

  getSocketResult(
    null,
    {
      start: myPosition.allowLocation === true ? myPosition.lat : ipAddress.lat,
      end: item.value[1],
    },
    {
      start: myPosition.allowLocation === true ? myPosition.lng : ipAddress.lng,
      end: item.value[0],
    },
    "distance"
  );
};
