import {
  UPDATE_SUPPORT_PROPS,
  MERGE_SUPPORT_PROPS,
  DELETE_SUPPORT_PROPS,
  CLEAR_SUPPORT_STATE,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    venues: { items: null, count: 0 },
    places: { items: null, count: 0 },
    totalPlaces: 0,
  },
};

const SupportR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SUPPORT_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_SUPPORT_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_SUPPORT_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_SUPPORT_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default SupportR;
