import {
  convertToFormData,
  renderName,
  showConfirmMessage,
  showSuccessedConfirm,
} from "../../General/GeneralFunctions";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";
import { local } from "../../lang/local";
import { SendRequest } from "./api";
import {
  DELETE_VENUES_PROPS,
  MERGE_VENUES_PROPS,
  UPDATE_GENERAL_PROPS,
  UPDATE_VENUES_PROPS,
} from "./types";

export const fetchVenues = (filterArray, page) => (dispatch) => {
  if (filterArray.length > 0) {
    dispatch({
      type: UPDATE_VENUES_PROPS,
      payload: [{ prop: "data.isLoadingSearch", value: true }],
    });
  }
  dispatch({
    type: UPDATE_VENUES_PROPS,
    payload: [{ prop: "data.isLoading", value: true }],
  });
  SendRequest()
    .post(`/Venues/Get?page=${page}`, filterArray)
    .then((res) => {
      if (res.status === 200) {
        let pagination = Object.assign({}, res.data.data);
        delete pagination.items;

        if (res.data.data.items.length === 0) {
          showConfirmMessage(local.NoSearch, "error");
        }
        dispatch({
          type: UPDATE_VENUES_PROPS,
          payload: [
            { prop: "data.items", value: res.data.data.items },
            { prop: "data.pagination", value: pagination },
          ],
        });
      }

      dispatch({
        type: UPDATE_VENUES_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_VENUES_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const addVenue = (formValues, callback) => (dispatch) => {
  dispatch({
    type: UPDATE_VENUES_PROPS,
    payload: [{ prop: "data.isLoadingCreate", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);
  let formData = convertToFormData(newFormValues);
  SendRequest()
    .post("/Venues", formData)
    .then((res) => {
      if (res.status === 200) {
        if (callback) {
          callback();
        }
        showSuccessedConfirm(local.AddedSuccessfully);

        dispatch({
          type: UPDATE_VENUES_PROPS,
          payload: [
            {
              prop: "data.form",
              value: {
                Name: { en: "", ar: "" },
                Logo: null,
                Phone: "",
              },
            },
          ],
        });
      }
      dispatch({
        type: UPDATE_VENUES_PROPS,
        payload: [{ prop: "data.isLoadingCreate", value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_VENUES_PROPS,
        payload: [{ prop: "data.isLoadingCreate", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const updateVenue = (formValues, history) => (dispatch) => {
  dispatch({
    type: UPDATE_VENUES_PROPS,
    payload: [{ prop: "data.isLoadingCreate", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);
  let formData = convertToFormData(newFormValues);
  SendRequest()
    .put(`/Venues?venueId=${newFormValues.id}`, formData)
    .then((res) => {
      if (res.status === 200) {
        showSuccessedConfirm(local.SuccessfullyUpdated);

        history.push("/dashboard/venues");
      }
      dispatch({
        type: UPDATE_VENUES_PROPS,
        payload: [{ prop: "data.isLoadingCreate", value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_VENUES_PROPS,
        payload: [{ prop: "data.isLoadingCreate", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const DeleteVenue = (id, index) => (dispatch) => {
  dispatch({
    type: UPDATE_VENUES_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: null }],
  });
  SendRequest()
    .delete(`/Venues?venueId=${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_VENUES_PROPS,
          payload: [
            {
              prop: "data.items." + index,
            },
          ],
        });
        showSuccessedConfirm(local.DeletedSuccessfully);
      }
      dispatch({
        type: UPDATE_VENUES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
    })
    .catch((err) => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_VENUES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
      console.log(err);
    })
    .finally();
};

export const deleteMultipleVenues = (ids, setChecked) => (dispatch) => {
  dispatch({
    type: UPDATE_VENUES_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: true }],
  });

  SendRequest()
    .delete(`/Venues?venueId=${ids[0]}`)
    .then((res) => {
      if (res.status === 200) {
        ids.shift();

        if (ids.length > 0) {
          dispatch(deleteMultipleVenues(ids, setChecked));
        } else {
          setChecked([]);
          showSuccessedConfirm(local.DeletedSuccessfully);
          dispatch(fetchVenues([], 1));
        }
      }
      dispatch({
        type: UPDATE_VENUES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
    })
    .catch((err) => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_VENUES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
      console.log(err);
    })
    .finally();
};

export const getVenueForDropDown = (page, searchValue, type) => (dispatch) => {
  let newFilterArray = [];
  let arregex = /[\u0600-\u06FF]/;
  let lang = "en";
  if (arregex.test(searchValue) === true) {
    lang = "ar";
  } else {
    lang = "en";
  }

  let url = "/Venues/Get";
  if (page !== null) {
    url = url + `?page=${page}`;
  }
  if (searchValue !== undefined) {
    newFilterArray = [
      {
        op: type === undefined ? 4 : 0,
        fieldName: type === undefined ? `Name@${lang}` : "Id",
        value: searchValue,
      },
    ];
  }

  SendRequest()
    .post(url, newFilterArray)
    .then((res) => {
      if (res.status === 200) {
        let newVenues = [];

        res.data.data.items.forEach((ven) => {
          if (ven.name[local.lang] !== undefined) {
            newVenues.push({
              label: renderName(ven.name),
              value: ven.id,
            });
          } else {
            newVenues.push({
              label: "no name " + ven.id,
              value: ven.id,
            });
          }
        });
        return dispatch({
          type: UPDATE_VENUES_PROPS,
          payload: [{ prop: "venuesOptions", value: newVenues }],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const loadVenueOptions =
  (search, loadedOptions, { page }) =>
  async (dispatch) => {
    let newFilterArray = [];
    let newVenues = [];
    let hasMore = false;
    let url = "/Venues/Get";
    let arregex = /[\u0600-\u06FF]/;
    let lang = "en";
    if (arregex.test(search) === true) {
      lang = "ar";
    } else {
      lang = "en";
    }

    if (search) {
      newFilterArray.push({
        op: 4,
        fieldName: `Name@${lang}`,
        value: search,
      });
    } else {
      if (page !== null) {
        url = url + `?page=${page}`;
      }
    }

    await SendRequest()
      .post(url, newFilterArray)
      .then((res) => {
        if (res.status === 200) {
          res.data.data.items.forEach((ven) => {
            if (ven.name[local.lang] !== undefined) {
              newVenues.push({
                label: renderName(ven.name),
                value: ven.id,
              });
            } else {
              newVenues.push({
                label: "no name " + ven.id,
                value: ven.id,
              });
            }
          });
          if (res.data.data.currentPage < res.data.data.totalPages) {
            hasMore = true;
          }

          if (page === 1) {
            dispatch({
              type: UPDATE_VENUES_PROPS,
              payload: [{ prop: "venuesOptions", value: newVenues }],
            });
          } else {
            dispatch({
              type: MERGE_VENUES_PROPS,
              payload: [{ prop: "venuesOptions", value: newVenues }],
            });
          }
        }
      })
      .catch((e) => {})
      .finally();
    return {
      options: newVenues,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

export const fetchVenue = (id, type, index) => (dispatch) => {
  SendRequest()
    .get(`/Venues/${id}`)
    .then((res) => {
      if (res.status === 200) {
        if (type === undefined) {
          if (res.data.data) {
            let enName = res.data.data.name
              ? res.data.data.name.en
                ? res.data.data.name.en
                : ""
              : "";

            let arName = res.data.data.name
              ? res.data.data.name.ar
                ? res.data.data.name.ar
                : ""
              : "";

            let logo = res.data.data.logo
              ? IMAGE_BASE_PATH + res.data.data.logo
              : null;

            let phone = res.data.data.phone ? res.data.data.phone : "";

            dispatch({
              type: UPDATE_VENUES_PROPS,
              payload: [
                {
                  prop: "data.form",
                  value: {
                    Name: { en: enName, ar: arName },
                    Logo: logo,
                    Phone: phone,
                    id,
                  },
                },
              ],
            });
          }
        } else {
          if (type === "profile") {
            if (res.data.data.branches) {
              dispatch({
                type: UPDATE_GENERAL_PROPS,
                payload: [
                  {
                    prop: "profileModal.data.branches",
                    value: res.data.data.branches,
                  },
                ],
              });

              dispatch({
                type: UPDATE_VENUES_PROPS,
                payload: [
                  {
                    prop: `data.items.${index}.branches`,
                    value: res.data.data.branches,
                  },
                ],
              });
            }
          }
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
