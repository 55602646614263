import React from "react";
import {
  Row,
  Col,
  Container,
  Input,
  Card,
  Form,
  Button,
  Spinner,
} from "reactstrap";
import mapy from "../../assets/img/auth/mapy.png";
import altawasol from "../../assets/img/auth/altawasol.png";
import { showConfirmMessage } from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { connect } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import { UPDATE_AUTH_PROPS } from "../../Redux/Actions/types";
import MenuLanguage from "../../General/MenuLanguage";

const UpdateEmail = ({ history, form, isLoading, general, updateEmail }) => {
  const handleUpdateEmail = (e) => {
    e.preventDefault();
    if (form.newemail) {
      updateEmail(form.newemail, history);
    } else {
      showConfirmMessage(local.EnterEmail, "error");
    }
  };

  return (
    <div className="app">
      <div className="app-body">
        <div className="main_container login_wrapper">
          <MenuLanguage />

          <Container>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
                <img src={mapy} alt="mapy" width="200" />
              </Col>
            </Row>
            <Row>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="mx-auto mt-5"
              >
                <Card className="create_card sign_card">
                  <Form>
                    <Row>
                      <Col
                        xl={8}
                        lg={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className="mx-auto mt-5"
                      >
                        <div className="container_add_input mx-auto">
                          <Input
                            type="text"
                            placeholder={local.Email}
                            className="add_input"
                            value={form.newemail}
                            onChange={(e) =>
                              general(
                                [
                                  {
                                    prop: "updateEmailForm.newemail",
                                    value: e.target.value,
                                  },
                                ],
                                UPDATE_AUTH_PROPS
                              )
                            }
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="ml-3 mr-3 mb-5">
                      <Col
                        xl={8}
                        lg={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className="mx-auto mt-5 mb-2"
                      >
                        <Button
                          className="save_btn mx-auto"
                          onClick={handleUpdateEmail}
                        >
                          {isLoading === true ? <Spinner /> : local.Save}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="center mt-5"
              >
                <p className="mobile_signin_text" style={{ color: "#3B3B3B" }}>
                  {local.SIGNFROM + " "}
                  <span
                    className="mobile_signin_text cursor bold"
                    style={{ color: "#12A9CC" }}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/login");
                    }}
                  >
                    {local.HERE}
                  </span>
                </p>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
                <img src={altawasol} alt="mapy" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ AuthR }) => {
  return {
    form: AuthR.updateEmailForm,
    isLoading: AuthR.isLoadingUpdateEmail,
  };
};
export default connect(mapStateToProps, actions)(UpdateEmail);
