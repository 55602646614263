import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import slider from '../../../assets/img/slider.png';
import lang from '../../../assets/img/lang.png';
import ArrowRight from '../../../assets/img/arraw.png';
import ArrowLeft from '../../../assets/img/Arrow-left.png';
import { getLanguage, local, toogleLanguage } from '../../../lang/local';
import Typography from '@mui/material/Typography';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';
export default function HeaderCard() {
  const [metrics, setMetrics] = useState(local.Metrics);

  const onLanguage = (e) => {
    if (getLanguage() === 'en') {
      toogleLanguage('ar');
    } else {
      toogleLanguage('en');
    }
  };
  const alignment = () => {
    let align = getLanguage() === 'ar' ? 'right' : 'none';
    return align;
  };
  const arrowDir = () => {
    let Arrow = getLanguage() === 'ar' ? ArrowLeft : ArrowRight;
    return Arrow;
  };

  return (
    <Paper sx={{ width: '100%', maxWidth: '100%' }}>
      <MenuList>
        <MenuItem onClick={onLanguage}>
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{ marginInlineStart: '10px' }}
          >
            <div className='img_container_account'>
              <img
                src={lang}
                alt=''
                className='imgFull cursor'
              />
            </div>
          </Typography>
          <ListItemText
            sx={{ textAlign: alignment(), marginInlineStart: '10px' }}
          >
            {local.Language}
          </ListItemText>
          <ListItemIcon>
            <div className='img_container_account'>
              <img src={arrowDir()} alt='' className='imgFull cursor' />
            </div>
          </ListItemIcon>
        </MenuItem>

        <MenuItem>
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{ marginInlineStart: '10px' }}
          >
            <div className='img_container_account'>
              <img
                src={slider}
                alt=''
                className='cursor'
              />
            </div>
          </Typography>
          <ListItemText
            sx={{ textAlign: alignment(), marginInlineStart: '10px' }}
          >
            {local.DistanceUnits}
          </ListItemText>

          <ListItemIcon>
            <UncontrolledDropdown setActiveFromChild>
              <DropdownToggle tag='span' className='table_list_dropdown'>
                {metrics}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  tag='a'
                  className='text-center'
                  active={metrics === local.Km ? true : false}
                  onClick={(e) => {
                    e.preventDefault();
                    setMetrics(local.Km);
                  }}
                >
                  {local.Km}
                </DropdownItem>
                <DropdownItem
                  tag='a'
                  className='text-center'
                  active={metrics === local.Mile ? true : false}
                  onClick={(e) => {
                    e.preventDefault();
                    setMetrics(local.Mile);
                  }}
                >
                  {local.Mile}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ListItemIcon>
        </MenuItem>
      </MenuList>
    </Paper>
  );
}
