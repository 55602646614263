import React, { useRef, Fragment, useEffect, useState } from 'react';
import ActionSheet from 'actionsheet-react';
import './mobileView.css';
import { BsDashLg } from 'react-icons/bs';
const MobileView = ({ children, search_data }) => {
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevProps = usePrevious({
    search_data,
  });

  const [height, setHeight] = useState('40%');
  const style = {
    sheet_Style: {
      height: search_data.isSlideDown ? '90%' : height,
      borderRadius: 'inherit',
      border: 'inherit',
      transitionDuration: '0.5s',
      transitionTimingFunction: 'ease-in-out',
    },
  };
  const ref = useRef();

  useEffect(() => {
    ref.current.open();
  }, []);

  useEffect(() => {
    if (prevProps) {
      if (
        prevProps.search_data.isSlideDown !== search_data.isSlideDown &&
        !search_data.isSlideDown
      ) {
        setHeight('40%');
      }
    }
  }, [search_data]);

  const toggleHeight = () => {
    if (height === '40%') {
      setHeight('90%');
    } else {
      setHeight('40%');
    }
  };
  // const handleClose=()=>{
  //   ref.current.open();
  //   setHeight('7%');

  // }

  return (
    <Fragment>
      <ActionSheet
        ref={ref}
        sheetStyle={style.sheet_Style}
        sheetTransition='0.5s ease-in-out'
        closeOnBgTap={false}
        threshold={30}
        touchEnable={false}
        mouseEnable={false}
        // onClose={handleClose}
      >
        <div className='sheet_container text-center'>
          <button
            className='no-btn-style header-btn-sheet'
            onClick={toggleHeight}
          >
            <BsDashLg size={30} />
          </button>
          {children}
        </div>
      </ActionSheet>
    </Fragment>
  );
};

export default MobileView;
