import {
  UPDATE_TICKETS_PROPS,
  MERGE_TICKETS_PROPS,
  DELETE_TICKETS_PROPS,
  CLEAR_TICKETS_STATE,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    items: null,
    pagination: null,
    isLoadingDelete: null,

    form: {
      title: "",
      description: "",
      status: true,
      sector: null,
      location: [],
    },

    errorMessages: {
      Title: "",
      Description: "",
      Sector: "",
      Location: "",
    },

    item_index: null,
    isLoadingCreate: false,
    isLoading: false,
    filterArray: [
      {
        op: 4,
        fieldName: "Title",
        value: "",
      },

      {
        op: 0,
        fieldName: "Sector",
        value: null,
      },

      {
        op: 5,
        fieldName: "Created",
        value: "",
      },
      {
        op: 6,
        fieldName: "Created",
        value: "",
      },
    ],
    isLoadingSearch: false,
  },

  data_message: {
    isOpen: false,
    general_Files_Count: 0,
    imagesCount: 0,
    filesCount: 0,
    items: null,
    pagination: null,
    filterArray: [],

    ticket: null,
    form: { Msg: { text: "" }, Attachments: [] },
    isLoadingCreate: false,
    isLoading: false,
    isLoadingDelete: null,
  },
};

const TicketsR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_TICKETS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_TICKETS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_TICKETS_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_TICKETS_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default TicketsR;
