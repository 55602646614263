import {
  UPDATE_DIRECTION_MENU_PROPS,
  CLEAR_DIRECTION_MENU_STATE,
  MERGE_DIRECTION_MENU_PROPS,
  DELETE_DIRECTION_MENU_PROPS,
} from "../Actions/types";
import * as DIRECTION_MENU from "./methods";

const INITIAL_STATE = {
  direction_data: {
    waytoGo: "car",
    isOpen: false,
    isLoading: false,
    routing: [],
    selecting_routing: null,
    start_location: null,
    end_location: null,
    active_class: 0,
  },

  startLocation: {
    search_type: "auto_complete",
    gis_id: null,
    title: "",
    lat: "",
    lng: "",
    searchResults: [],
    searchValue: "",
    dragableMarker: { lat: "", lng: "" },
  },
  endLocation: {
    search_type: "auto_complete",
    gis_id: null,
    title: "",
    lat: "",
    lng: "",
    searchResults: [],
    searchValue: "",
    dragableMarker: { lat: "", lng: "" },
  },

  search_direction: {
    isOpen: false,
    search_title: "",
    type: "",
    home_info: null,
    work_info: null,
    isAddHomeWork: false,
  },
};

const DirectionR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DIRECTION_MENU_PROPS: {
      return DIRECTION_MENU.updateProps(state, action);
    }
    case MERGE_DIRECTION_MENU_PROPS: {
      return DIRECTION_MENU.mergeProps(state, action);
    }
    case DELETE_DIRECTION_MENU_PROPS: {
      return DIRECTION_MENU.deleteProps(state, action);
    }
    case CLEAR_DIRECTION_MENU_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};

export default DirectionR;
