import star_place from "../assets/img/star_place.png";
import heart_place from "../assets/img/heart_place.png";
import { getLanguage } from "../lang/local";
export const _lists = [
  {
    label: getLanguage() === "en" ? "Favorite list" : "القائمة المفضلة",
    value: "Favorite list",
    image: star_place,
  },
  {
    label: getLanguage() === "en" ? "Want to go" : "اريد الذهاب",
    value: "Want to go",
    image: heart_place,
  },
];
