import React, { useState } from "react";
// import { Doughnut } from "react-chartjs-2";
import { Col, Row } from "reactstrap";
import {
  statistics_style_1,
  statistics_style_2,
  statistics_style_3,
  statistics_style_4,
  statistics_style_5,
} from "../../../General/GeneralStyle";
import { local } from "../../../lang/local";

import "./DoughnutChardCard.css";

const DoughnutChartCard = ({ layers }) => {
  const [item1, setItem1] = useState(false);
  const [item2, setItem2] = useState(false);
  const [item3, setItem3] = useState(false);
  const [item4, setItem4] = useState(false);
  const [item5, setItem5] = useState(false);

  const getLayer = (layer) => {
    if (layers.length > 0) {
      let item = layers.find((l) => l.title === layer);
      if (item !== undefined) {
        return +item.count;
      } else {
        return null;
      }
    }
  };

  const claculteTotalItems = () => {
    if (layers.length > 0) {
      let newLayers = [];
      layers.map((layer, index) => {
        if (item1 === false && layer.title === "cafe") {
          newLayers.push(layer);
        } else if (item2 === false && layer.title === "restaurant") {
          newLayers.push(layer);
        } else if (item3 === false && layer.title === "hospital") {
          newLayers.push(layer);
        } else if (item4 === false && layer.title === "bank") {
          newLayers.push(layer);
        } else if (item5 === false && layer.title === "shopping_mall") {
          newLayers.push(layer);
        }
      });

      let item = newLayers.map((l) => l.count);

      return item.reduce((a, b) => a + b, 0);
    } else {
      return 0;
    }
  };
  return (
    <div
      className="doughnutChardCard_container"
      data-aos="fade-up"
      data-aos-delay="20"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <Row>
        <Col
          xl={10}
          lg={10}
          md={10}
          sm={11}
          xs={11}
          className="mx-auto center mt-3"
        >
          <div
            className="statistics_item"
            style={{
              background: statistics_style_1(
                (getLayer("cafe") / claculteTotalItems()) * 100
              ),
              border: item1 === true ? "none" : null,
            }}
            data-aos="zoom-in"
            data-aos-delay="20"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div
              className="statistics_item"
              style={{
                background: statistics_style_2(
                  (getLayer("restaurant") / claculteTotalItems()) * 100
                ),
                border: item2 === true ? "none" : null,
              }}
            >
              <div
                className="statistics_item"
                style={{
                  background: statistics_style_3(
                    (getLayer("hospital") / claculteTotalItems()) * 100
                  ),
                  border: item3 === true ? "none" : null,
                }}
              >
                <div
                  className="statistics_item"
                  style={{
                    background: statistics_style_4(
                      (getLayer("bank") / claculteTotalItems()) * 100
                    ),
                    border: item4 === true ? "none" : null,
                  }}
                >
                  <div
                    className="statistics_item statistics_item_child center"
                    style={{
                      background: statistics_style_5(
                        (getLayer("shopping_mall") / claculteTotalItems()) * 100
                      ),
                      border: item5 === true ? "none" : null,
                    }}
                  >
                    {claculteTotalItems()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-3">
          <Row>
            <Col className="flex">
              <div
                className="stat_content_container alignItem flex"
                style={{
                  textDecoration: item1 === true ? "line-through" : "none",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setItem1(!item1);
                }}
              >
                <span id="circle_1" className="stat_content_circle" />
                <span>{local.Cafe + ":" + getLayer("cafe")}</span>
              </div>
            </Col>
            <Col className="flex">
              <div
                className="stat_content_container alignItem flex"
                style={{
                  textDecoration: item2 === true ? "line-through" : "none",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setItem2(!item2);
                }}
              >
                <span id="circle_2" className="stat_content_circle" />
                <span>{local.Restaurants + ":" + getLayer("restaurant")}</span>
              </div>
            </Col>
            <Col className="flex">
              <div
                className="stat_content_container alignItem flex"
                style={{
                  textDecoration: item3 === true ? "line-through" : "none",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setItem3(!item3);
                }}
              >
                <span id="circle_3" className="stat_content_circle" />
                <span>{local.Hoispital + ":" + getLayer("hospital")}</span>
              </div>
            </Col>
            <Col className="flex">
              <div
                className="stat_content_container alignItem flex"
                style={{
                  textDecoration: item4 === true ? "line-through" : "none",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setItem4(!item4);
                }}
              >
                <span id="circle_4" className="stat_content_circle" />
                <span>{local.Bank + ":" + getLayer("bank")}</span>
              </div>
            </Col>
            <Col className="flex">
              <div
                className="stat_content_container alignItem flex"
                style={{
                  textDecoration: item5 === true ? "line-through" : "none",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setItem5(!item5);
                }}
              >
                <span id="circle_5" className="stat_content_circle" />
                <span>{local.Shopping + ":" + getLayer("shopping_mall")}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Doughnut
        data={{
          labels: [
            local.Cafe,
            local.Restaurants,
            local.Hoispital,
            local.Bank,
            local.Groceries,
          ],
          datasets: [
            {
              label: "",
              data: [
                getLayer("cafe"),
                getLayer("96- restaurant"),
                getLayer("hospital"),
                getLayer("bank"),
                getLayer("56- food"),
              ],
              backgroundColor: [
                "#00A0E9",
                "#B033AB",
                "#CB5B66",
                "#F39800",
                "#FFFFFF",
              ],
              borderColor: [
                "rgba(0, 160, 233, .7)",
                "rgba(176, 51, 171, .7)",
                "rgba(203, 91, 102, .7)",
                "rgba(0, 35, 69, .7)",
                "rgba(255, 255, 255, .7)",
              ],
              borderWidth: 1,
            },
          ],
        }}
        plugins={[
          {
            beforeDraw: function (chart) {
              var width = chart.chart.width,
                height = chart.chart.height,
                ctx = chart.chart.ctx;

              ctx.restore();
              var fontSize = (height / 150).toFixed(2);
              ctx.font = fontSize + "em sans-serif";
              ctx.fillStyle = "white";
              ctx.textBaseline = "middle";

              var text = claculteTotalItems(),
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2.3;

              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        ]}
        height={220}
        options={{
          mantainAspectRation: false,
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              boxWidth: 10,
            },
          },
          elements: {
            arc: {
              borderWidth: 0,
              borderColor: "black",
              borderAlign: "inner",
            },
          },
        }}
      /> */}
    </div>
  );
};
export default DoughnutChartCard;
