import { getGeneralUsers } from ".";
import {
  getQueryString,
  removeDublicatedValues,
  removeDuplicateElement,
  renderName,
} from "../../General/GeneralFunctions";
import { RequestGis, SendRequest } from "./api";
import { UPDATE_BUSINESS_PROPS, UPDATE_VENUES_PROPS } from "./types";
import { local } from "../../lang/local";
export const fetchBranchesVenues = () => (dispatch) => {
  SendRequest()
    .post("/Venues/Get?page=1", [])
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data.items.length > 0) {
          let newVenues = [];
          res.data.data.items.forEach((ven) => {
            if (ven.name[local.lang] !== undefined) {
              newVenues.push({
                label: renderName(ven.name),
                value: ven.id,
              });
            } else {
              newVenues.push({
                label: "no name " + ven.id,
                value: ven.id,
              });
            }
          });
          dispatch({
            type: UPDATE_VENUES_PROPS,
            payload: [{ prop: "venuesOptions", value: newVenues }],
          });
          let ids = res.data.data.items.map((item) => item.id);
          dispatch(fetchLastBranches(ids));
          dispatch(fetchLastDistributors(ids));
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const fetchLastBranches = (venusIDs) => (dispatch) => {
  let filterArray = [
    {
      op: 0,
      fieldName: "Type",
      value: 0,
    },
    {
      op: 3,
      fieldName: "VenueId",
      value: venusIDs,
    },
  ];
  SendRequest()
    .post("/Branch/Get?page=1", filterArray)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_BUSINESS_PROPS,
          payload: [{ prop: "data.branches", value: res.data.data.items }],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const fetchLastDistributors = (venusIDs) => (dispatch) => {
  let filterArray = [
    {
      op: 0,
      fieldName: "Type",
      value: 1,
    },
    {
      op: 3,
      fieldName: "VenueId",
      value: venusIDs,
    },
  ];
  SendRequest()
    .post("/Branch/Get?page=1", filterArray)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_BUSINESS_PROPS,
          payload: [{ prop: "data.distributors", value: res.data.data.items }],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const fetchSupport = (generalUsers) => (dispatch) => {
  SendRequest()
    .post("/Ticket/Get?page=1", [])
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data.items) {
          let users = res.data.data.items.map((u) => u.userId);
          let uniqueTotals = removeDuplicateElement(users);
          dispatch(getGeneralUsers(uniqueTotals, generalUsers));
        }
        dispatch({
          type: UPDATE_BUSINESS_PROPS,
          payload: [
            { prop: "data.support_tickets", value: res.data.data.items },
          ],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

// export const fetchCustomerReviews = (page) => (dispatch) => {
//   let item = page === undefined ? 1 : page;
//   SendRequest()
//     .post(`/User/Get?page=${item}`, [])
//     .then((res) => {
//       if (res.status === 200) {
//         if (res.data.data.items.length > 0) {
//           let userIDs = res.data.data.items.map((u) => u.id);
//           userIDs.push(getUser().data.id);

//           if (
//             res.data.data.currentPage < res.data.data.totalPages &&
//             res.data.data.currentPage <= 3
//           ) {
//             dispatch(fetchCustomerReviews(res.data.data.currentPage + 1));
//           }

//           dispatch(getReviews(userIDs));
//         }
//       }
//     })
//     .catch((e) => {
//       console.log(e);
//     })
//     .finally();
// };

// export const getReviews = (userIDs) => (dispatch) => {
//   SendRequest()
//     .post("/Review/Get?page", [
//       {
//         op: 3,
//         fieldName: "User@id",
//         value: userIDs,
//       },
//     ])
//     .then((res) => {
//       if (res.status === 200) {
//         dispatch({
//           type: UPDATE_BUSINESS_PROPS,
//           payload: [{ prop: "data.reviews", value: res.data.data.items }],
//         });
//       }
//     })
//     .catch((e) => {
//       console.log(e);
//     })
//     .finally();
// };

export const getReviewCountes = () => (dispatch) => {
  // [
  //   {
  //     op: 3,
  //     fieldName: "User@id",
  //     value: userIDs,
  //   },
  // ];
  SendRequest()
    .post("/Review/Get", [])
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_BUSINESS_PROPS,
          payload: [
            { prop: "data.statistcs.reviews", value: res.data.data.totalItems },
          ],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const searchPlaces = (query, location) => (dispatch) => {
  let lat = location.nearest_location.lat;
  let lng = location.nearest_location.lng;

  if (location.mylocation.lat !== "" && location.mylocation.lng !== "") {
    lat = location.mylocation.lat;
    lng = location.mylocation.lng;
  }
  let country = location.country;
  if (country === "") {
    country = "eg";
  }

  let arregex = /[\u0600-\u06FF]/;
  let lang = "en";
  if (arregex.test(query) === true) {
    lang = "ar";
  } else {
    lang = "en";
  }

  let body = {
    query,
    radius: 200,
    size: 40,
    lat,
    lng,
    lang,
    country,
    layer: "",
  };
  dispatch({
    type: UPDATE_BUSINESS_PROPS,
    payload: [{ prop: "search.isLoading", value: true }],
  });

  SendRequest()
    .get("/GeoServices/radiusgeocode" + getQueryString(body))
    .then((res) => {
      let places = [];
      if (res.status >= 199 && res.status <= 299) {
        if (res.data.data !== undefined) {
          if (res.data.data.features !== undefined) {
            if (Array.isArray(res.data.data.features)) {
              res.data.data.features.forEach((place) => {
                places.push({
                  label: removeDublicatedValues(
                    place.properties.name,
                    place.properties.street,
                    place.properties.county,
                    place.properties.region,
                    place.properties.country,
                    place.properties.continent
                  ),
                  value: place.geometry.coordinates,
                  layer: place.properties.layer,
                  data: place,
                });
              });
              dispatch({
                type: UPDATE_BUSINESS_PROPS,
                payload: [{ prop: "search.results", value: places }],
              });
            }
          }
        }
      }
      dispatch({
        type: UPDATE_BUSINESS_PROPS,
        payload: [{ prop: "search.isLoading", value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_BUSINESS_PROPS,
        payload: [{ prop: "search.isLoading", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const getPlacesByLayer = (key, location) => (dispatch) => {
  let lat = location.nearest_location.lat;
  let lng = location.nearest_location.lng;

  if (location.mylocation.lat !== "" && location.mylocation.lng !== "") {
    lat = location.mylocation.lat;
    lng = location.mylocation.lng;
  }
  let country = location.country;
  if (country === "") {
    country = "eg";
  }

  let arregex = /[\u0600-\u06FF]/;
  let lang = "en";
  if (arregex.test(key) === true) {
    lang = "ar";
  } else {
    lang = "en";
  }

  let body = {
    key,
    lang,
  };
  dispatch({
    type: UPDATE_BUSINESS_PROPS,
    payload: [{ prop: "search.isLoading", value: true }],
  });

  RequestGis()
    .post("/cafe" + getQueryString(body), [lng, lat])
    .then((res) => {
      let places = [];
      if (res.status >= 199 && res.status <= 299) {
        if (res.data !== undefined) {
          if (res.data.features !== undefined) {
            if (Array.isArray(res.data.features)) {
              res.data.features.forEach((place) => {
                places.push({
                  label: removeDublicatedValues(
                    place.properties.name,
                    place.properties.street,
                    place.properties.county,
                    place.properties.region,
                    place.properties.country,
                    place.properties.continent
                  ),
                  value: place.geometry.coordinates,
                  layer: place.properties.layer,
                  data: place,
                });
              });
              dispatch({
                type: UPDATE_BUSINESS_PROPS,
                payload: [{ prop: "search.results", value: places }],
              });
            }
          }
        }
      }
      dispatch({
        type: UPDATE_BUSINESS_PROPS,
        payload: [{ prop: "search.isLoading", value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_BUSINESS_PROPS,
        payload: [{ prop: "search.isLoading", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const getBranchesDistributorsCounter = () => (dispatch) => {
  let types = [
    { value: 0, prop: "data.statistcs.beanches" },
    { value: 1, prop: "data.statistcs.distributors" },
  ];

  types.map((type) => {
    let filterArray = [
      {
        op: 0,
        fieldName: "Type",
        value: type.value,
      },
    ];
    SendRequest()
      .post("/Branch/Get", filterArray)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_BUSINESS_PROPS,
            payload: [{ prop: type.prop, value: res.data.data.totalItems }],
          });
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally();
  });
};
