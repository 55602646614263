import React from "react";
import { Card, Row, Col } from "reactstrap";
import { webAsideCardStyle } from '../../../utils/MapViewStyle';


import {
  UPDATE_USER_ACCOUNT_PROPS,
  UPDATE_SEARCH_MENU_PROPS
} from "../../../Redux/Actions/types";
import { renderRowHeader } from '../../../utils/MapViewFunctions';


import { connect } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import FooterCard from "./FooterCard";
import HeaderCard from "./HeaderCard";
const UserMenu = ({ general, logout, history }) => {
  return (
    <Card className="web_view_card" style={webAsideCardStyle}>
      {renderRowHeader(
        [
          {
            payload: [{ prop: "search_data.toViewSearch", value: true }],
            action: UPDATE_SEARCH_MENU_PROPS
          },

          {
            payload: [{ prop: "userAccount.isOpen", value: true }],
            action: UPDATE_USER_ACCOUNT_PROPS
          }
        ],
        general
      )}

      <div className="user_container">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mx-auto">
            <HeaderCard />
          </Col>
        </Row>

        <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mx-auto mt-3">
            <FooterCard general={general} logout={logout} history={history} />
          </Col>
        </Row>
      </div>
    </Card>
  );
};
const mapStateToProps = ({ UserAccountR }) => {
  return { userAccount: UserAccountR.userAccount };
};
export default connect(mapStateToProps, actions)(UserMenu);
