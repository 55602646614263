export const en = {
  lang: "en",
  Clang: "En",
  Settings: "Settings",
  Language2: "Arabic",
  Home: "Home",
  Support: "Support",
  Markting: "Markting",
  Users: "Users",
  Places: "Places",
  NumberTransfers: "Number of transfers",
  ThisMonth: "This month",
  Welcome: "Welcome",
  GoToMap: "Go to map",

  GoToMap: "",
  LastLogin: "From your last login on the system",
  newPlace: "new place",
  Added: "has been added",
  newUser: "user",
  Registered: "has been registered",
  Admin: "Admin",
  Map: "Mapy",
  DailyDownload: "daily download",
  MonthlyDownload: "monthly download",
  CurrentUsers: "Number of Current Users",
  InActivePlaces: "in active places",
  RegisterPlaces: "registerd places",
  AddedPlaces: "added places",
  ActivationCodeInArraive: "Activation Code not Arrived",
  SuggestedPlaces: "SuggestedPlaces to be edited",
  UsersApp: "users of app",
  AddUser: "Add User",
  UnderReview: "places under review",
  users: "users",
  ReviewPlaces: "Review Places",
  ComplaintsSystem: "complaints system",
  RatingsReview: "Review Ratings",
  ManagementTeam: "management teams",
  FastAction: "Fast action",
  MoreOneUser: "You must choose more than one user",
  Activation: "Activation",
  Stopping: "Stopping",
  SerialNumber: "Serial Number",
  Name: "Name",
  Email: "Email",
  email: "Email",
  Country: "Country",
  City: "City",
  HoppingTimes: "Hopping Times",
  NofPlacesAdded: "N of Places Added",
  Status: "Status",
  Login: "Login",
  UserName: "UserName",
  NuUser: "Number of Users",
  PlacesNumber: "Number of Current Places",
  Logout: "Logout",
  DailyDownloads: "Daily Downloads",
  MonthlyDownloads: "Monthly Downloads",
  ActiveUsers: "Active Users",
  SupportRequest: "Support Request",
  DashboardUsers: "Dashboard Users",
  Restaurants: "Restaurants",
  restaurant: "Restaurants",
  TotalPlaces: "Total Places",
  TotalUsers: "Total Users",
  MostShared: "Most shared on the app",
  PlacesReview: "Places awaiting review",
  PeopleSupport: "People Support",
  Reply: "reply",
  CompanySupport: "Company Support",
  CompanyAccount: "Company Account",
  Newlyjoined: "Newly joined",
  TypeHere: "Type here",
  Tickets: "Tickets",
  RecentChats: "Recent Chats",
  CreateTicket: "Create New Ticket",
  Archived: "Archived",
  Active: "Active",
  InActive: "In Active",
  BusinessAccount: "Business Account",
  RegisteredBranches: "Registered Branches",
  RegisteredDistributors: "Registered Distributors",
  BranchName: "Branch Name",
  ShareLink: "Share Link",
  ShowOnMap: "Show On Map",
  ShareBulkLink: "bulk link",
  AddBranch: "Add Branch",
  BranchesGroup: "Branches Group",
  AddBranchGroup: "Add Branches Group",
  BranchId: "Branch Number",
  Address: "Address",
  LocateMap: "Locate on the map",
  Save: "Save",
  CategorySearch: "Category Search",
  Resturant: "Restaurant",
  Cafe: "Cafe",
  SuperMarket: "Market",
  Pharmacy: "Pharmacy",
  CustomerReviews: "Customer Reviews",
  OpenSupport: "New Support",
  LatestSupport: "Latest Support Request",
  AddNew: "Add New",
  Explore: "Explore",
  Direction: "Direction",
  RECENTSEARCH: "RECENT SEARCH",
  CLEAR: "CLEAR",
  PLACENAME: "PLACE NAME",
  CITYNAME: "CITY NAME",
  Violations: "Complaints",
  Location: "Location",
  UserNote: "User Note",
  Time: "Time",
  User: "User",
  SlowTraffic: "Slow Traffic",
  SpeedRadar: "Speed Radar",
  Accident: "Accident",
  BrokenVehicle: "Broken Vehicle",
  Closed: "Closed",
  WorkingArea: "Working Area",
  Danger: "Danger",
  MAPCHAT: "Map Chat",
  ObjectRoad: "Object on road",
  HELP: "Help",
  MAPError: "MAP Error",
  Language: "Language",
  WITHFACEBOOK: "CONTINUE WITH FACEBOOK",
  WITHGOOGLE: "CONTINUE WITH GOOGLE",
  MOBILESIGNUP: "OR SIGNUP WITH MOBILE",
  MOBILESIGNIN: "OR SIGNIN WITH MOBILE",
  SIGNFROM: "OR,SIGN IN FROM",
  SIGNFROM1: "SIGN IN FROM",
  SIGNUPFROM: "OR,SIGN UP FROM",
  SIGNUPFROM1: "SIGN UP FROM",
  HERE: "HERE",
  UserDenied: "User denied the request for Geolocation",
  UnavailableLoc: "Location information is unavailable",
  LocationTimedOut: "The request to get user location timed out",
  UnknownError: "An unknown error occurred",
  LoadMore: "Load More",
  ConfirmPassword: "Confirm Password",
  SignUp: "Sign Up",
  SignIn: "Sign In",
  Arabic: "Arabic",
  English: "English",
  AccountCreated: "Account successfully created",
  subLang: "en",
  Menu: "Menu",
  Password: "Password",
  EnterUserNamePassword: "UserName and Password is Required",
  EnterUserName: "UserName is Required",
  EnterPassword: "Password is Required",
  CreatedIn: "Created In",
  FirstName: "First Name",
  LastName: "Last Name",
  BirthDay: "Birth Day",
  Male: "Male",
  Female: "Female",
  DataRequired: "All Data is Required",
  ValidEmail: "Enter correct Email",
  PasswordMatched: "Confirm Password is not the same as the Password",
  SomethingError: "Something Error",
  PasswordValidate: "Your password must be at least 8 Numbers",
  Code: "Code",
  EnterEmail: "Enter your Email",
  EnterPhoneNumber: "Enter Phone Number",
  SkipNow: "Skip for Now",
  EnterEmaiOrPhone: "Enter Email or Phone Number",
  ResetYourPassword: "Reset your Password",
  SelectImage: "Select Image",
  Venues: "Companies",
  AddVenue: "Add Company",
  EditVenue: "Edit Company",
  Id: "Id",
  Image: "Image",
  Roles: "Roles",
  AddRole: "Add Role",
  Description: "Description",
  Permissions: "Permissions",
  EnglishName: "Name EN",
  ArabicName: "Name AR",
  Claims: "Claims",
  AddClaim: "Add Claim",
  OpenningHours: "Openning Hours",
  Done: "Done",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  AddDateTime: "Add Date",
  From: "From",
  To: "To",
  VerifiyByPhone: "Your account is deactivated",
  ConfirmAccount: "Confirm Account",
  ResendCode: "Resend Code",
  SmsSent: "Sms Sent successfully",
  ConfirmedSuccessfully: "Account Confirmed Successfully",
  AddAnother: "Add Another",
  AddComplaintType: "Add Complaint Type",
  ComplaintType: "Complaint Type",
  FullNameRequired: "English and Arabic Name is Required",
  AddedSuccessfully: "Added Successfully",
  NoName: "No Name",
  DeletedSuccessfully: "Deleted Successfully",
  SuccessfullyUpdated: "Successfully Updated",
  Images: "Images",
  Layer: "Layer",
  Categories: "Categories",
  PlaceStatus: "Place Status",
  Pedning: "Pending",
  Approved: "Approved",
  NotApproved: "Not Approved",
  Edit: "Edit",
  Delete: "Delete",
  View: "View",
  Day: "Day",
  Hide: "Hide",
  Accept: "Accept",
  Reject: "Reject",
  PlaceAdded: "The place has been added",
  PlaceRejected: "Adding a place has been rejected",
  DataRequiredValidation: "Enter all Required Data",
  Branches: "Branches",
  BranchType: "Branch Type",
  Branch: "Branch",
  Shop: "Distributor Shop",
  UpdateBranch: "Update Branch",
  AddTicket: "Add Ticket",
  Title: "Title",
  ApprovedPlace: "I was approved to add the place",
  RefusedSuccessfully: "The place has been refused.",
  FullName: "Full Name",
  JoinDate: "Join Date",
  Activated: "Activated",
  NotActivated: "Not Activated",
  Deactivate: "Deactivate",
  AccountDeactivated: "The account has been deactivated",
  Sector: "Sector",
  Business: "Business",
  Gis: "Gis",
  Mobile: "Mobile",
  AddedBy: "Added By",
  TicketBusiness: "This ticket is for business Systems",
  TicketGis: "This ticket is for Gis Systems",
  TicketMobile: "This ticket is for Mobile Systems",
  EditTicket: "Edit Ticket",
  claimType: "claim Type",
  RolesClaims: "Attached Claims to Roles",
  AddRoleClaim: "Attach Claim to Role",
  UsersClaims: "Attached Claims to Users",
  AddUserClaim: "Attach Claim to User",
  UsersRoles: "Attached Roles to Users",
  AddRoleUser: "Attach Role to User",
  Responsibilities: "Responsibilities",
  Search: "Search",
  ChooseUser: "Choose User First",
  ChooseRole: "Choose Role First",
  EditPlace: "Edit Place",
  ChooseList: "Choose from the list",
  InActiveUsers: "In Active Users",
  Hoispital: "Hoispital",
  Bank: "Bank",
  Groceries: "Groceries",
  Reviews: "Reviews",
  NoReviewsFound: "No Reviews Found",
  Loading: "Loading",
  SeaarchValueRequired: "Enter Search Keyword",
  All: "All",
  AllTimes: "All Times",
  CurrentMonth: "Current Month",
  Last15Days: "last 15 days",
  PreviousMonth: "Previous Month",
  ValidateFileLength: "File length must be equal or less then 10 mb",
  ValidateFileLength2: "File length must be equal or less then 1 mb",

  EditTicketMessage: "Edit Ticket Message",
  Audio: "Audio",
  Document: "Document",
  Video: "Video",
  VideoLink: "Video Link",
  InvalidLink: "Enter a valid link",
  Photo: "Photo",
  Files: "Files",
  Record: "Record",
  DeleteAllMessages: "Delete All Messages",

  //start claims
  Contribution: "Contribution",
  GeoEvent: "Complaints",
  GeoEvent_Get: "View",
  Contribution_Delete: "Delete",
  Contribution_Create: "Add",
  Contribution_Update: "Edit",
  Contribution_Confirm: "Accept/Reject Place",
  GeoEventType_Delete: "Delete Type of Complaint",
  GeoEventType_Create: "Add Complaint Type",
  GeoEventType_Update: "Edit Complaint Type",
  ReviewsBusiness_Delete: "Delete",
  ReviewsBusiness: "Review Places",
  ReviewsBusiness_Create: "Add",
  ReviewsBusiness_Update: "Edit",
  Branches_Create: "Add",
  Branches_Update: "Edit",
  Branches_Delete: "Delete",
  Venues_Delete: "Delete",
  Venues_Create: "Add",
  Venues_Update: "Edit",
  Ticket: "Support Tickets",
  Ticket_Create: "Add",
  Ticket_Delete: "Delete",
  Ticket_Update: "Edit",
  TicketMessage_Create: "Add",
  TicketMessage_Delete: "Delete",
  TicketMessage_Update: "Edit",
  Contribution_Get: "View",
  Venues_Get: "View",
  Branches_Get: "View",
  Ticket_Get: "View",
  GeoEventType_Get: "View",
  GeoEventType: "Types of Complaint",
  UserBusiness: "Users",
  UserBusiness_ActivateUser: "Activate",
  UserBusiness_DeActivateUser: "Deactivate",
  UserBusiness_GetUsers: "View",
  RoleBusiness_GetRoles: "View",
  RoleBusiness_GetRole: "View",
  RoleBusiness_Delete: "Delete",
  TicketMessage_GetTicketMessages: "View",
  TicketMessage: "Ticket Messages",
  RoleBusiness_Create: "Add",
  RoleClaims: "Role Claims",
  RoleBusiness_AttachRoleClaim: "Add",
  RoleBusiness_GetUserRoles: "View",
  UserRole: "Attached Roles to Users",
  RoleBusiness_AttachUserRole: "Attach",
  RoleBusiness_GetUserClaims: "View",
  UserClaims: "User Claims",
  RoleBusiness_AttachUserClaim: "Add",
  RoleBusiness_UpdateRole: "Edit",
  RoleBusiness_DetachRoleClaim: "Remove",
  RoleBusiness_DetachUserClaim: "Remove",
  RoleBusiness_DetachUserRole: "Remove",
  Venues_GetById: "Profile",
  Branches_GetById: "Profile",

  //end claims
  NoMessageAvaliable: "No Messages Avaliable",
  NoControlPanel:
    "You do not have any permissions to use Control Panel services",
  AnyDataRequired: "Enter Search Value",
  NoSearch: "no search result found",
  Date: "Date",
  TicketClosed: "Ticket closed successfully",
  Close: "Close",
  PolygonInsideError:
    "It is not possible to add a polygon inside another polygon ",
  ChoosePlace: "Choose Place",
  ContinueAdding: "Continue as adding a new place",
  "96- restaurant": "Restaurants",
  bank: "Banks",
  "56- food": "Groceries",
  hospital: "Hospitals",
  cafe: "Cafe",
  CopyLink: "Copy Link",
  Shopping: "Shopping",
  EditBranch: "Edit Branch",
  Sure: "Are you sure",
  No: "No",
  Yes: "Yes",
  ActivateUser: "you want to activate this user",
  DeactivateUser: "you want to deactivate this user",
  ConfirmEdit: "you want to modify this item",
  ConfirmDelete: "you want to delete selected Items",
  Distributors: "Distributors",
  CreateDistributor: "Create Distributor",
  EditDistributor: "Edit Distributor",
  ConfirmAcceptPlace: "you want to accept this place",
  ConfirmRejectPlace: "you want to reject this place",
  Distributor: "Distributor",
  OK: "Ok",
  EditRole: "Edit Role",
  UpdateComplaintType: "Edit Complaint Type",
  ViewVenues: "View Companies",
  ViewDistributors: "View Distributors",
  ViewBranches: "View Branches",
  ViewPlaces: "View Places",
  ViewRoles: "View Roles",
  UserProfile: "User Profile",
  VenueProfile: "Company Profile",
  BranchProfile: "Branch Profile",
  DistributorProfile: "Distributor Profile",
  PlaceProfile: "Place Profile",
  facebook: "Facebook",
  whatsapp: "Whatsapp",
  instgram: "Instgram",
  snapshat: "Snapshat",
  wheelchair: "Wheelchair",
  RoleProfile: "Role Profile",
  RoleClaimProfile: "Role Claim Profile",
  Role: "Role",
  UserClaimProfile: "User Claim Profile",
  UserRoleProfile: "User Role Profile",
  ComplaintProfile: "Complaint Profile",
  EnglishLang: "Only English Language is Allowed",
  ArabicLang: "Only Arabic Language is Allowed",
  shopping_mall: "Shopping",
  Type: "Type",
  Facebook: "Facebook",
  Instgram: "Instagram",
  Twitter: "Twitter",
  Snapshat: "Snapshat",
  ChooseAll: "Choose All",
  AddSocial: "Add Social Media",
  PhonePasswordRequired: "Mobile Number or Password is incorrect",

  //webview
  MarkedLocation: "Marked location",
  EDITPLACE: " EDIT PLACE",
  Directions: "Directions",
  DarkMode: "Dark mode",
  LightMode: "Light Mode",
  FollowSystem: "Follow System",
  DistanceUnits: "Distance units",
  Metrics: "Metrics",
  SavedPalces: "Saved palces",
  About: "About",
  Help: "Help",
  MyLocation: "My location",
  More: "More...",
  YourLocation: "Your Location",
  EndLocation: "End Location",
  START: "START",
  AddWork: "Add Work",
  SelectOnMap: "Select on map",
  Work: "Work",
  ADDTO: " ADD TO",
  ChooseDestination: "choose your destination",
  NoConnection: "No internet Connection",
  NoConnectionDesc:
    "Your internet connection is currently not available please check or try again.",
  TryAgain: "Try again",
  NoLocation: "No Location Allowed",
  NoLocationDesc: "You must allow the browser to determine your location",
  SearchValueRequired: "search value is required",
  SamePointValidation:
    "The starting point and the Ending point are in the same place, please change any of them",
  SearchResult: "Search Result",
  Pharmacies: "Pharmacies",
  ATMs: "ATMs",
  Gas: "Gas",
  CarServices: "Car Services",
  DoneCopy: "Copied successfully",
  Gender: "Gender",
  MobileNumberCode: "Enter the code sent to the mobile number",
  EmailCode: "Enter the code sent to the Email",
  CheckEmail: "An activation link has been sent to your email",
  activateAccount: "Wait... your account is now being activated",
  PhoneNumberRequired: "Enter your Mobile Number",
  HomeLocation: "Home Location",
  AddHome: "Add Home",
  AddName: "Add Name",
  AddNewPlace: "Add New Place",
  Category: "Category",
  Website: "WebSite",
  OpeningHours: "Opening hours",
  UploadImages: "Upload Images",
  AddPlace: "Add Place",
  SavedPlaces: "Saved places",
  FavoriteList: "Favorite list",
  WantToGo: "Want to go",
  AddToList: "Add to list",
  AddAnotherList: "Add another list",
  SelectType: "Please choose Type",
  MyContributions: "My Contributions",
  PlacesContribtion: "Your Places helps everyone get around easily",
  Opinions: "Your opinions helps others to decide where to go",
  ReplacedUserName: "Phone Number",
  RegisteredPlaces: "Registered Places",
  UnRegisteredPlaces: "Un Registered Places",
  ForgetPassword: "Forget Password",
  Remove: "Remove",
  EnterLink: "Enter Link",
  NotUrl: "it is not Valid Url",
  useDefault: "use default",
  useCustom: "use custom",
  Dashboard: "Dashboard",
  WhatsApp: "WhatsApp",
  ImageRequired: "Image is Required",
  SelectCompany: "Select Company",
  TokenExpired: "token is expired,Please Log in Again",
  NameEnRequired: "English Name is Required",
  NameArRequired: "Arabic Name is Required",
  LocationRequired: "Location is Required",
  NameEnORArRequired: "English or Arabic Name is Required",
  SelectLayer: "Select Layer",
  NameRequired: "Name is Required",
  DescriptionRequired: "Description is Required",
  RoleRequired: "Select Role",
  ClaimRequired: "Select Claims",
  SelectComplaintType: "Select Complaint Type",
  SelectUser: "Select User",
  TitleRequired: "Title is Required",
  SelectSector: "Select Type",
  Year: "Year",
  Month: "Month",
  Hour: "Hour",
  Minute: "Minute",
  Second: "Second",
  UsersCounts: "Users Counts",
  Km: "Kilo Meter",
  DetachRoleClaim: "Role Claim",
  AttachRoleClaim: "Role Claim",
  DetachUserClaim: "User Claim",
  UpdateRole: "Role",
  AttachUserClaim: "UserClaim",
  GetUserClaims: "User Claims",
  RoleBusinessDelete: "Delete Role",
  DetachUserRole: "User Role",
  AttachUserRole: "User Role",
  GetUserRole: "User Role",
  GetRoles: "Roles",
  GetRole: "Roles",
  RoleBusinessCreate: "Add Role",
  AddComplaint: "Add Complaint",
  Note: "Note",
  Mile: "Mile",
  BusinessAnalysis: "Business Analysis",
  Branches2: "Branches",
  NoClaims: "No Claims For this Role",
  ActivationCode: "Activation Code",
  PhoneEmailRequired: "Phone or Email is Required",
  CodeSentSuccessfully: "The code has been sent successfully",
  CodeRequired: "Enter the Activation Code",
  ConfirmPasswordRequired: "Confirm Password is Required",
  ConfirmationCode: "Confirmation Code",
  PasswordReset: "The password has been reset successfully",
  ActiveByMobile: "Active By Mobile",
  ActiveByEmail: "Active By Email",
  InActiveByMobile: "In Active By Mobile",
  InActiveByEmail: "InActive By Email",
  LoginByMobile: "Login By Mobile",
  LoginByEmail: "Login By Email",
  EditEmailCode: "Edit the Email or send the Code",
  EditEmail: "Edit Email",
  SendCode: "Send Code",
  NumberofActiveUsersEmail: "Active Users by Email",
  NumberofActiveUsersPhone: "Active Users by Phone",
  NumberofInActiveUsersEmail: "In Active Users by Email",
  NumberofInActiveUsersPhone: "In Active Users by Phone",
  Total: "Total",
  CountryCode: "Country Code ex(eg)",
  Profile: "Profile",
  ActivationExpire: "Activation code will expire in ",
  EditData: "Edit Data",
  ByMobile: "By Mobile",
  ByEmail: "By Email",
  ThisPermission: "This permission ",
  cannotbeRepeated: "cannot be repeated with the user ",
  FirstNameLength: "First Name Length must be equal or greater than 3",
  LastNameLength: "Last Name Length must be equal or greater than 3",
  FirstAndLastNameLength:
    "First and Last Names Length must be equal or greater than 3",
  DoNotHavePermission: "You do not have permission to access this information",
  ExpiredCode: "invalid or Expired code",
  "role has users attached to it":
    "cannot delete this role beacuse is attached to a user",
  Rating: "Rating",
  Options: "Options",
  Add: "Add",
  Guests: "Guests",
  TotalDownloads: "Total downloads",
  //countries
  eg: "Egypt",
  Eg: "Egypt",
  EG: "Egypt",
  Egypt: "Egypt",
  sa: "Saudi Arabia",
  Sa: "Saudi Arabia",
  SA: "Saudi Arabia",
  lb: "Lebanon",
  LB: "Lebanon",
  Lb: "Lebanon",
  jo: "Jordan",
  Jo: "Jordan",
  JO: "Jordan",
  us: "United States",
  Us: "United States",
  US: "United States",
};
