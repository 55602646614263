import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { local } from "../lang/local";
import { Col, Row, Button } from "reactstrap";
import { UPDATE_GENERAL_PROPS } from "../Redux/Actions/types";
import { FiX } from "react-icons/fi";
import {  leftRightProperty } from "../General/GeneralStyle";
const ProfileModal = ({
  profileModal,
  general,
  title,
  Children,
  workingDaysModal,
  rolesOptions,
  usersOptions,
  ComplaintsOptions,
  location
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const toggle = () => {
    general(
      [{ prop: "profileModal.isOpen", value: !profileModal.isOpen }],
      UPDATE_GENERAL_PROPS
    );

    if (profileModal.isOpen === false) {
      general(
        [{ prop: "profileModal.data", value: null }],
        UPDATE_GENERAL_PROPS
      );
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={fullScreen}
        open={profileModal.isOpen}
        onClose={toggle}
        aria-labelledby="responsive-dialog-title"
        scroll="body"
      >
        <div className="modal_close" style={leftRightProperty} onClick={toggle}>
          <FiX className="icon" />
        </div>
        <div className="profile_card">
          <DialogTitle id="responsive-dialog-title center">
            <h3 className="text-center mt-3">{title}</h3>
          </DialogTitle>
          <DialogContent>
            <Row>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="mx-auto mt-3"
              >
                <Children
                  data={profileModal.data}
                  general={general}
                  workingDaysModal={workingDaysModal}
                  rolesOptions={rolesOptions}
                  usersOptions={usersOptions}
                  ComplaintsOptions={ComplaintsOptions}
                  location={location}
                />
              </Col>
            </Row>
          </DialogContent>
          <Row>
            <Col
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              className="mx-auto mt-3 mb-3"
            >
              <Button onClick={toggle} className="save_btn mx-auto">
                {local.OK}
              </Button>
            </Col>
          </Row>
        </div>
      </Dialog>
    </div>
  );
};

export default ProfileModal;
