import React from "react";
import {
  Button,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  Modal,
} from "reactstrap";
import { leftStyle } from "./GeneralStyle";
import { local } from "../lang/local";
import { getPermissions } from "../utils/Permissions";
import { HiDotsVertical } from "react-icons/hi";
import { GoPlusSmall } from "react-icons/go";
import { confirmAlert } from "react-confirm-alert";
import { UPDATE_GENERAL_PROPS } from "../Redux/Actions/types";

export const renderAddButton = (title, history, routeName, permissionName) => {
  if (
    getPermissions(true, true)[permissionName] !== undefined ||
    getPermissions(true, true).Administrator !== undefined
  )
    return (
      <Button
        style={leftStyle}
        className="search_btn mt-2"
        onClick={(e) => {
          e.preventDefault();
          history.push(routeName);
        }}
      >
        <GoPlusSmall size={20} />
        <span>{title}</span>
      </Button>
    );
};

const confirmToDelete = (e, deleteMultiple, checked, setChecked, id) => {
  e.preventDefault();

  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui">
          <h1>{local.Sure}</h1>
          <p>{local.ConfirmDelete}</p>
          <button onClick={onClose}>{local.No}</button>
          <button
            onClick={() => {
              deleteMultiple(checked, setChecked, id);
              onClose();
            }}
          >
            {local.Yes}
          </button>
        </div>
      );
    },
  });
};

export const renderMultipleDeleteButton = (
  checked,
  setChecked,
  deleteMultiple,
  isLoadingDelete,
  permissionName,
  id
) => {
  // if (
  //   getPermissions(true, true)[permissionName] !== undefined ||
  //   getPermissions(true, true).Administrator !== undefined
  // ) {
  if (checked.length > 0) {
    return (
      <Button
        // style={leftStyle}
        className="remove_btn mt-2"
        onClick={(e) =>
          confirmToDelete(e, deleteMultiple, checked, setChecked, id)
        }
      >
        <span className="remove_text">
          {isLoadingDelete === true ? <Spinner /> : local.Delete}
        </span>
      </Button>
    );
  }
  // }
};

export const toggleChecked = (e, value, checked, setChecked) => {
  e.preventDefault();

  if (Array.isArray(value)) {
    setChecked([]);
    if (JSON.stringify(value) === JSON.stringify(checked)) {
      setChecked([]);
    } else {
      setChecked(value);
    }
  } else {
    setChecked([]);
    if (checked.length === 0) {
      setChecked([...checked, value]);
    } else {
      if (checked.find((v) => v === value)) {
        let items = [...checked];
        items.splice(checked.indexOf(value), 1);
        setChecked(items);
      } else {
        setChecked([...checked, value]);
      }
    }
  }
};

export const renderCheckoBox = (
  value,
  checked,
  setChecked,
  type,
  permissionName
) => {
  // if (
  //   getPermissions(true, true)[permissionName] !== undefined ||
  //   getPermissions(true, true).Administrator !== undefined
  // ) {
  return (
    <div
      className="container"
      onClick={(e) => toggleChecked(e, value, checked, setChecked)}
    >
      <div className="round">
        <input
          type="checkbox"
          id="checkbox"
          checked={
            checked.find((v) => v === value) ||
            (type === "all" &&
              JSON.stringify(checked) === JSON.stringify(value))
              ? true
              : false
          }
        />
        <label htmlFor="checkbox"></label>
      </div>
    </div>
  );
  // }
};

export const renderTableDropDown = (
  item,
  index,
  deleteItem,
  editItem,
  deletePermission,
  editPermission
) => {
  return (
    <UncontrolledDropdown direction="up">
      <DropdownToggle tag="a" className="nav-link table_list_dropdown">
        <HiDotsVertical size={20} style={{ color: "#272D3B" }} />
      </DropdownToggle>
      <DropdownMenu className="table_dropdown_menu">
        {editPermission !== null ? (
          // getPermissions(true, true)[editPermission] !== undefined ||
          // getPermissions(true, true).Administrator !== undefined ? (
          editItem !== null ? (
            <DropdownItem
              tag="a"
              className="cursor weightFont"
              onClick={(e) => editItem(e, item, index)}
            >
              {local.Edit}
            </DropdownItem>
          ) : null
        ) : // ) : null
        null}

        {deletePermission !== null ? (
          // getPermissions(true, true)[deletePermission] !== undefined ||
          // getPermissions(true, true).Administrator !== undefined ? (
          deleteItem !== null ? (
            <DropdownItem
              tag="a"
              className="cursor weightFont"
              onClick={(e) => deleteItem(e, item, index)}
            >
              {local.Delete}
            </DropdownItem>
          ) : null
        ) : // ) : null
        null}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export const WorkingHoursModal = ({ workingDaysModal, general }) => {
  const toggle = (e) => {
    e.preventDefault();

    general(
      [
        {
          prop: "workingDaysModal",
          value: { isOpen: !workingDaysModal.isOpen, data: null },
        },
      ],
      UPDATE_GENERAL_PROPS
    );
  };

  return (
    <Modal isOpen={workingDaysModal.isOpen} toggle={toggle} fade={false}>
      <WorkingHoursCard toggle={toggle} workingDaysModal={workingDaysModal} />
    </Modal>
  );
};
export const renderDateTime = (date) => {
  let dateTime = new Date(date).toLocaleTimeString();
  if (!isNaN(new Date(date))) {
    return dateTime;
  } else {
    return date;
  }
};
export const WorkingHoursCard = ({ toggle, workingDaysModal }) => {
  return (
    <Card className="opening_hours_card">
      <Row className="mt-3">
        <Col xl={4} lg={4} md={4} sm={4} xs={4} className="text-center">
          <p style={{ fontWeight: "bold" }}>{local.Day}</p>
        </Col>
        <Col xl={4} lg={4} md={4} sm={4} xs={4} className="text-center">
          <p style={{ fontWeight: "bold" }}>{local.From}</p>
        </Col>
        <Col xl={4} lg={4} md={4} sm={4} xs={4} className="text-center">
          <p style={{ fontWeight: "bold" }}> {local.To}</p>
        </Col>
      </Row>

      {workingDaysModal.data
        ? Object.entries(workingDaysModal.data).map((work, i) => {
            return (
              <Row key={i}>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="text-center mt-2"
                >
                  <p>{work ? local[work[0]] : ""}</p>
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="text-center mt-2"
                >
                  <p>
                    {work[1]
                      ? work[1].from !== null && work[1].from !== "null"
                        ? renderDateTime(work[1].from)
                        : null
                      : null}
                  </p>
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="text-center mt-2"
                >
                  <p>
                    {work[1]
                      ? work[1].to !== null && work[1].to !== "null"
                        ? renderDateTime(work[1].to)
                        : null
                      : null}
                  </p>
                </Col>
              </Row>
            );
          })
        : null}

      {toggle ? (
        <Row className="mt-5 mb-3">
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
            <Button className="mx-auto save_btn" onClick={toggle}>
              {local.Close}
            </Button>
          </Col>
        </Row>
      ) : null}
    </Card>
  );
};
