import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import TableList from './TableList';
import { getUser } from '../../utils/StorageFunctions';
import ProfileModal from '../../utils/ProfileModal';
import RoleProfile from './RoleProfile';
import { getPermissions } from '../../utils/Permissions';
import { CLEAR_ROLES_STATE } from '../../Redux/Actions/types';

class Roles extends Component {
  componentDidMount() {
    this.getRole();
  }

  getRole = () => {
    const { history, fetchRoles, general } = this.props;
    general([], CLEAR_ROLES_STATE);
    if (getUser() === null) {
      history.push('/login');
    } else {
      if (
        getPermissions(true, true).RoleBusiness_GetRoles === undefined &&
        getPermissions(true, true).Administrator === undefined
      ) {
        history.push('/');
      } else {
        fetchRoles();
      }
    }
  };
  render() {
    const {
      history,
      data,
      DeleteRole,
      general,
      deleteMultipleRoles,
      profileModal,
      getClaimsByRole,
    } = this.props;
    return (
      <div className='page-container'>
        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={local.RoleProfile}
          Children={RoleProfile}
        />

        <Row>
          <Col>
            <TableList
              history={history}
              data={data}
              DeleteRole={DeleteRole}
              general={general}
              deleteMultipleRoles={deleteMultipleRoles}
              getClaimsByRole={getClaimsByRole}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ RolesR, GeneralR }) => {
  return {
    data: RolesR.data,
    profileModal: GeneralR.profileModal,
  };
};
export default connect(mapStateToProps, actions)(Roles);
