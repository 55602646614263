import React from 'react';
import { Row, Col } from 'reactstrap';
import close_icon from '../assets/img/close_icon.png';
import webview_avter from '../assets/img/webview_avter.svg';
import { IMAGE_BASE_PATH } from '../General/StaticKeys';
import { leftStyle, rightStyle } from '../General/GeneralStyle';
import { getUser, renderUserName } from './StorageFunctions';

export const handleCloseMenu = (e, items, general) => {
  e.preventDefault();
  items.map((item) => {
    general(item.payload, item.action);
  });
};

export const renderRowHeader = (items, general, title) => {
  return (
    <Row className='ml-1 mr-1'>
      <Col>
        <img
          src={close_icon}
          alt='close_icon cursor'
          style={rightStyle}
          onClick={(e) => handleCloseMenu(e, items, general)}
        />

        {title === undefined ? (
          <div style={leftStyle}>
            <div className='text-center'>
              <img
                src={
                  getUser()
                    ? getUser().data.image
                      ? IMAGE_BASE_PATH + getUser().data.image
                      : webview_avter
                    : webview_avter
                }
                style={{
                  borderRadius: '100%',
                  width: '50px',
                  height: '50px',
                }}
                alt='webview_avter'
                className='mt-3'
              />
              <p className='user_name_text'>{renderUserName()}</p>
            </div>
          </div>
        ) : (
          <div className='flex alignItem'>
            <p className='add_place_title'>{title}</p>
          </div>
        )}
      </Col>
    </Row>
  );
};
