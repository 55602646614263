import { Request } from "./api";
import { MAP_USER } from "../../General/StaticKeys";
import { UPDATE_REGISTER_PROPS } from "./types";
import {
  renderFormData,
  showConfirmMessage,
  showSuccessedConfirm
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
export const register = (formValues, history) => dispatch => {
  let newFormValues = Object.assign({}, formValues);
  let formData = renderFormData(newFormValues);

  dispatch({
    type: UPDATE_REGISTER_PROPS,
    payload: [{ prop: "registerObj.isLoading", value: true }]
  });
  Request()
    .post("Auth/Register", formData)
    .then(res => {
      if (res.status === 200) {
        localStorage.setItem(MAP_USER, JSON.stringify(res.data.data));
        showSuccessedConfirm(local.AccountCreated);

        if (formValues.phoneNumber && !formValues.email) {
          history.push("/confirm_sms");
        } else {
          history.push("/confirm_email");
        }
      }

      dispatch({
        type: UPDATE_REGISTER_PROPS,
        payload: [{ prop: "registerObj.isLoading", value: false }]
      });
    })
    .catch(err => {
      dispatch({
        type: UPDATE_REGISTER_PROPS,
        payload: [{ prop: "registerObj.isLoading", value: false }]
      });
      if (Object.values(err)[2].data.data) {
        Object.values(Object.values(err)[2].data.data).map(e => {
          showConfirmMessage(e[0], "error");
        });
      }

      console.log(err);
    })
    .finally();
};
