import { getLanguage } from "../lang/local";

export const Direction = {
  direction: getLanguage() === "en" ? "ltr" : "rtl",
  float: getLanguage() === "en" ? "left" : "right",
};

export const rightDirection = {
  direction: getLanguage() === "en" ? "rtl" : "ltr",
};
export const leftDirection = {
  direction: getLanguage() === "en" ? "ltr" : "rtl",
};

export const leftStyle = {
  float: getLanguage() === "en" ? "left" : "right",
};
export const rightStyle = { float: getLanguage() === "en" ? "right" : "left" };

export const leftClearStyle = {
  float: getLanguage() === "en" ? "left" : "right",
  clear: getLanguage() === "en" ? "left" : "right",
};

export const rightClearStyle = {
  clear: getLanguage() === "en" ? "right" : "left",
};
// export const menuItem = {

// };
export const columnAlignLeft = () => {
  if (getLanguage() === "en") {
    return "text-left";
  } else {
    return "text-right";
  }
};

export const columnAlignRight = () => {
  if (getLanguage() === "en") {
    return "text-right";
  } else {
    return "text-left";
  }
};

export const fadeRight = () => {
  if (getLanguage() === "en") {
    return "fade-right";
  } else {
    return "fade-left";
  }
};

export const leftRightProperty = {
  left: getLanguage() === "en" ? "0px" : null,
  right: getLanguage() === "ar" ? "0px" : null,
};

export const rightLeftProperty = {
  left: getLanguage() === "ar" ? "0px" : null,
  right: getLanguage() === "en" ? "0px" : null,
};
export const leftProperty = {
  left: getLanguage() === "en" ? "10px" : null,
  right: getLanguage() === "ar" ? "10px" : null,
};
export const leftProperty2 = {
  left: getLanguage() === "en" ? "20px" : null,
  right: getLanguage() === "ar" ? "20px" : null,
};

export const isRtl = () => {
  if (getLanguage() === "en") {
    return false;
  } else {
    return true;
  }
};

export const selectSearchStyle = {
  option: (provided) => ({
    ...provided,
    color: "#3b3b3b",
    padding: 10,
    borderRadius: "10px",
    textAlign: "center",
    zIndex: 1000000,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,

    zIndex: 1000000,
  }),
};

export const selectFormStyle = {
  option: (provided) => ({
    ...provided,
    color: "#3b3b3b",
    padding: 10,
    borderRadius: "10px",
    textAlign: "center",
    zIndex: 1000000,
  }),

  menu: (provided) => {
    const zIndex = "10000";
    return { ...provided, zIndex };
  },
  singleValue: (provided) => {
    const color = "#082849";
    return { ...provided, color };
  },
  input: (provided) => ({
    ...provided,
    zIndex: 10000,
  }),
};

export const selectFormStyle2 = {
  option: (provided) => ({
    ...provided,
    color: "#3b3b3b",
    padding: 10,
    borderRadius: "10px",
    textAlign: "center",
    zIndex: 1000000,
  }),
  singleValue: (provided) => {
    const color = "#082849";

    return { ...provided, color };
  },

  input: (provided) => ({
    ...provided,
    zIndex: 10000,
  }),
};

export const supportCardDesc = {
  textAlign: getLanguage() === "en" ? "left" : "right",
  float: getLanguage() === "en" ? "left" : "right",
};

export const statistics_style_1 = (percent) => {
  return `linear-gradient(transparent,transparent) padding-box,linear-gradient(to right, #1f3759 ${
    100 - percent
  }%, #00A0E9 ${percent}%) border-box`;
};

export const statistics_style_2 = (percent) => {
  return `linear-gradient(transparent, transparent) padding-box,linear-gradient(to right, #113151 ${
    100 - percent
  }%, #A82CD0 ${percent}%) border-box`;
};

export const statistics_style_3 = (percent) => {
  return `linear-gradient(transparent, transparent) padding-box,linear-gradient(to right, #1f3759 ${
    100 - percent
  }%, #D4767F ${percent}%) border-box`;
};

export const statistics_style_4 = (percent) => {
  return `linear-gradient(transparent, transparent) padding-box,linear-gradient(to right, #113151 ${
    100 - percent
  }%, #ED9013 ${percent}%) border-box`;
};
export const statistics_style_5 = (percent) => {
  return `linear-gradient(#002345, #002345) padding-box,linear-gradient(to right, #1f3759 ${
    100 - percent
  }%, wheat ${percent}%) border-box`;
};

export const rateReviewCard = {
  right: getLanguage() === "en" ? "5px" : null,
  left: getLanguage() === "ar" ? "5px" : null,
  position: "absolute",
};


export const mapIconStyle = {
  right: getLanguage() === "en" ? "10px" : null,
  left: getLanguage() === "ar" ? "10px" : null,
};


export const arrrawLangStyle = {
  transform: getLanguage() === "en" ? null : "rotate(-180deg)",
};

export const buttonPlaceStyle = {
  left: getLanguage() === "ar" ? "0px" : null,
  right: getLanguage() === "en" ? "0px" : null,
  borderRadius:
    getLanguage() === "en" ? "0px 22px 22px 0px" : "22px 0px 0px 22px",
  justifyContent: "space-evenly",
};

export const leftPropStyle = {
  left: getLanguage() === "ar" ? "20px" : null,
  right: getLanguage() === "en" ? "20px" : null,
};

export const alignTextRight = {
  textAlign: getLanguage() === "en" ? "left" : "right",
};
