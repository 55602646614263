import {
  UPDATE_COMPLAINTS_PROPS,
  CLEAR_COMPLAINTS_STATE,
  MERGE_COMPLAINTS_PROPS,
  DELETE_COMPLAINTS_PROPS,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    items: null,
    pagination: null,
    filterArray: [
      {
        op: 5,
        fieldName: "CreatedAt",
        value: "",
      },
      {
        op: 6,
        fieldName: "CreatedAt",
        value: "",
      },

      {
        op: 0,
        fieldName: "UserId",
        value: null,
      },
    ],

    form: {
      GeoEventsTypeId: "",
      Note: "",
      Location: [],
      Images: [],
    },
    isOpenAdd: false,
    isLoading: false,
    complaint: null,
    isLoadingSearch: false,
    isLoadingCreate: false,
  },
};

const ComplaintsR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_COMPLAINTS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_COMPLAINTS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_COMPLAINTS_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_COMPLAINTS_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};

export default ComplaintsR;
