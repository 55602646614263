import React, { useCallback, useState } from "react";
import { Row, Col, Form, Card } from "reactstrap";
import { validateForm } from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import {
  UPDATE_AUTH_PROPS,
  UPDATE_LOGIN_PROPS,
} from "../../Redux/Actions/types";
import { AuthOptions } from "../../utils/AuthOptions";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { Phone } from "../../components/Phone/Phone";
import { CustomPassword } from "../../components/CustomPassword/CustomPassword";
import { CustomButton } from "../../components/CustomButton/CustomButton";

const LoginForm = ({ loginObj, history, login, general, country }) => {
  const [loginWith, setLogin] = useState("email");

  const onLogin = (e) => {
    e.preventDefault();
    if (validateForm(loginObj.form) === true) {
      login(loginObj.form, history);
    }
  };

  const updateLogin = useCallback((value) => {
    setLogin(value);
    general(
      [{ prop: "loginObj.form.userName", value: "" }],
      UPDATE_LOGIN_PROPS
    );
  }, []);

  const handleForget = (e) => {
    e.preventDefault();

    if (loginObj.form.userName) {
      if (loginWith === "email") {
        general(
          [{ prop: "forgetForm.email", value: loginObj.form.userName }],
          UPDATE_AUTH_PROPS
        );
      } else {
        general(
          [{ prop: "forgetForm.phone", value: loginObj.form.userName }],
          UPDATE_AUTH_PROPS
        );
      }
    }

    history.push("/forget-password");
  };

  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col xl={6} lg={6} md={6} sm={12} xs={12} className="mx-auto">
          <Card className="create_card sign_card">
            <AuthOptions
              setValue={setLogin}
              value={loginWith}
              callback={() =>
                general(
                  [{ prop: "loginObj.form.userName", value: "" }],
                  UPDATE_LOGIN_PROPS
                )
              }
            />
            <p
              onClick={() => history.push("/")}
              className="text-center mt-4"
              style={{
                color: "#12a9cc",
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: "15px",
                cursor: "pointer",
              }}
            >
              {local.GoToMap}
            </p>

            <Form>
              <Row>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="mx-auto mt-5"
                >
                  {loginWith === "email" ? (
                    <CustomInput
                      icon={true}
                      type="email"
                      value={loginObj.form.userName}
                      className="removed_input_style pass_input"
                      onChange={(value) =>
                        general(
                          [
                            {
                              prop: "loginObj.form.userName",
                              value,
                            },
                          ],
                          UPDATE_LOGIN_PROPS
                        )
                      }
                      label={local.Email}
                    />
                  ) : (
                    <Phone
                      defaultCountry={country}
                      icon={true}
                      value={loginObj.form.userName}
                      onChange={(value) =>
                        general(
                          [{ prop: "loginObj.form.userName", value }],
                          UPDATE_LOGIN_PROPS
                        )
                      }
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="mx-auto mt-4"
                >
                  <CustomPassword
                    value={loginObj.form.password}
                    icon={true}
                    label={local.Password}
                    className="removed_input_style pass_input"
                    onChange={(value) =>
                      general(
                        [
                          {
                            prop: "loginObj.form.password",
                            value,
                          },
                        ],
                        UPDATE_LOGIN_PROPS
                      )
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="mx-auto mt-5"
                >
                  <CustomButton
                    handleClick={onLogin}
                    label={local.SignIn}
                    isLoader={loginObj.isLoading}
                    className="save_btn mx-auto"
                  />
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center mt-5">
          <p className="mobile_signin_text" style={{ color: "#3B3B3B" }}>
            {local.SIGNUPFROM + " "}
            <span
              className="mobile_signin_text cursor bold"
              style={{ color: "#12A9CC" }}
              onClick={(e) => {
                e.preventDefault();
                history.push("/register");
              }}
            >
              {local.HERE}
            </span>
          </p>
        </Col>

        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
          <a
            style={{ color: "#12A9CC", fontSize: "20px" }}
            className="cursor"
            onClick={handleForget}
          >
            {local.ForgetPassword}
          </a>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default LoginForm;
