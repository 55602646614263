import React, { useState } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import { local } from "../../lang/local";
import {
  renderAddButton,
  renderCheckoBox,
  renderMultipleDeleteButton,
  renderTableDropDown,
} from "../../General/TableFunctions";
import { UPDATE_VENUES_PROPS } from "../../Redux/Actions/types";
import PaginationItem from "../../General/PaginationItem";
import { ShareIcon } from "../../General/GeneralFunctions";
import Share from "../../General/Share";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";
import { renderSingleImage, renderName } from "../../General/GeneralFunctions";
import { openProfile, unsetErrorMessage } from "./VenuesFunctions";

const MobileList = ({
  history,
  data,
  DeleteVenue,
  general,
  fetchVenues,
  deleteMultipleVenues,
  fetchVenue,
}) => {
  const [checked, setChecked] = useState([]);

  const deleteItem = (e, item, index) => {
    e.preventDefault();
    DeleteVenue(item.id, index);
  };

  const editItem = (e, item, index) => {
    e.preventDefault();
    unsetErrorMessage(general);

    general(
      [
        {
          prop: "data.form",
          value: {
            Name: {
              en: item.name["en"],
              ar: item.name["ar"],
            },
            Logo: item.logo ? IMAGE_BASE_PATH + item.logo : null,
            Phone: item.phone ? item.phone : "",
          },
        },

        {
          prop: "data.item_index",
          value: index,
        },
        {
          prop: "data.form.id",
          value: item.id,
        },
      ],
      UPDATE_VENUES_PROPS
    );
    history.push(`/dashboard/editvenue/${item.id}`);
  };

  const renderTableBody = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return data.items.map((item, index) => {
          return (
            <tr key={index} className="text-center">
              <th scope="row">
                {renderCheckoBox(
                  item.id,
                  checked,
                  setChecked,
                  "single",
                  "Venues_Delete"
                )}
              </th>
              <td>{renderSingleImage(item.logo, general)}</td>
              <td>{item.id}</td>
              <td>
                <a
                  href=""
                  className="blueLight"
                  onClick={(e) => openProfile(e, general, fetchVenue, item)}
                >
                  {renderName(item.name)}
                </a>
              </td>

              <td>{item.phone}</td>

              <td>
                {item.branches ? (
                  <Share
                    TagItem={ShareIcon}
                    bulk={`https://mapy.world?companyId=${item.id}`}
                  />
                ) : null}
              </td>
              <td>
                {renderTableDropDown(
                  item,
                  index,
                  deleteItem,
                  editItem,
                  "Venues_Delete",
                  "Venues_Update"
                )}
              </td>
            </tr>
          );
        });
      }
    }
  };
  return (
    <React.Fragment>
      {/* <Row className="mt-3 ml-1 mr-1">
        <Col className="text-center maxWidth" background="white" xl={12}>
          <p className="page_title_item" style={leftStyle}>
            {local.Branches}
          </p>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col className="text-center maxWidth" xl={12}> */}
      <Card className="table_card ml-3 mr-3">
        <div className="mt-4">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {renderAddButton(
                local.AddVenue,
                history,
                "/dashboard/addvenue",
                "Venues_Create"
              )}

              {renderMultipleDeleteButton(
                checked,
                setChecked,
                deleteMultipleVenues,
                data.isLoadingDelete,
                "Venues_Delete"
              )}
            </Col>
          </Row>

          <Table responsive hover className="mt-4">
            <thead>
              <tr className="text-center">
                <th>
                  {data.items
                    ? data.items.length > 0
                      ? renderCheckoBox(
                          data.items.map((i) => i.id),
                          checked,
                          setChecked,
                          "all",
                          "Venues_Delete"
                        )
                      : null
                    : null}
                </th>
                <th>{local.Id}</th>
                <th>{local.Name}</th>
                <th>{local.Mobile}</th>
                <th>{local.ShareLink}</th>

                <th>{local.Image}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{renderTableBody()}</tbody>
          </Table>
          <Row>
            <Col
              className="center mt-3 mb-3"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {data.pagination !== null ? (
                <PaginationItem
                  pagination={data.pagination}
                  filterArray={data.filterArray}
                  general={general}
                  fetchData={fetchVenues}
                />
              ) : null}
            </Col>
          </Row>
        </div>
      </Card>
      {/* </Col>
      </Row> */}
    </React.Fragment>
  );
};

export default MobileList;
