import React from 'react';
import { Row, Col, Card } from 'reactstrap';
import { local } from '../../../lang/local';
import { leftStyle } from '../../../General/GeneralStyle';
import { UPDATE_DIRECTION_MENU_PROPS } from '../../../Redux/Actions/types';
import { saveRecentSearch } from '../../../General/GeneralFunctions';
const SearchResult = ({ general, searchResult, type }) => {
  const onClear = () => {
    if (type === 'start_location') {
      general(
        [
          { prop: 'startLocation.searchValue', value: '' },
          { prop: 'startLocation.searchResults', value: [] },
        ],
        UPDATE_DIRECTION_MENU_PROPS
      );
    } else {
      general(
        [
          { prop: 'endLocation.searchValue', value: '' },
          { prop: 'endLocation.searchResults', value: [] },
        ],
        UPDATE_DIRECTION_MENU_PROPS
      );
    }
  };
  const chooseLocation = (e, item) => {
    e.preventDefault();
    saveRecentSearch(item.value[1], item.value[0], item.label);
    if (type === 'start_location') {
      general(
        [
          { prop: 'startLocation.lat', value: item.value[1] },
          { prop: 'startLocation.lng', value: item.value[0] },
          { prop: 'startLocation.title', value: item.label },
          { prop: 'startLocation.gis_id', value: item.data.properties.gid },
          { prop: 'search_direction.isOpen', value: false },
          { prop: 'direction_data.isOpen', value: true },
        ],
        UPDATE_DIRECTION_MENU_PROPS
      );
    } else {
      general(
        [
          { prop: 'endLocation.lat', value: item.value[1] },
          { prop: 'endLocation.lng', value: item.value[0] },
          { prop: 'endLocation.title', value: item.label },
          { prop: 'endLocation.gis_id', value: item.data.properties.gid },
          { prop: 'search_direction.isOpen', value: false },
          { prop: 'direction_data.isOpen', value: true },
        ],
        UPDATE_DIRECTION_MENU_PROPS
      );
    }
  };
  const renderCardItem = () => {
    if (searchResult) {
      if (searchResult.length > 0) {
        return (
          <div>
            <Row>
              <Col className='mt-2'>
                <p className='search_result_title' style={leftStyle}>
                  {local.SearchResult}
                </p>
              </Col>
            </Row>
            <Row>
              {searchResult.map((result, index) => {
                return (
                  <Col
                    className='mt-3'
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    key={index}
                  >
                    <Card
                      className='search_result_card_item cursor'
                      onClick={(e) => chooseLocation(e, result)}
                    >
                      <div className='fullWidthHeight center'>
                        <div>
                          <p className='place_name'>
                            {result.label}
                            <p className='city_name'>
                              {searchResult[0].label.split(',').pop()}
                            </p>
                          </p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>

            <Row>
              <Col className='mt-5 ml-2 mr-2 text-center cursor'>
                <p
                  className='search_result_clear_text cursor'
                  onClick={onClear}
                >
                  {local.CLEAR}
                </p>
              </Col>
            </Row>
          </div>
        );
      }
    }
  };
  return <div className='search_result_container'>{renderCardItem()}</div>;
};

export default SearchResult;
