import React, { useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import mapy from '../../assets/img/auth/mapy.png';
import altawasol from '../../assets/img/auth/altawasol.png';
import {
  getMyLocation,
  // showConfirmMessage,
} from '../../General/GeneralFunctions';
// import { local } from '../../lang/local';
import LoginForm from './LoginForm';
import { connect } from 'react-redux';
import * as actions from '../../Redux/Actions/index';
import {
  // UPDATE_AUTH_PROPS,
  UPDATE_LOGIN_PROPS,
} from '../../Redux/Actions/types';
// import { MAP_USER } from '../../General/StaticKeys';
// import MenuLanguage from '../../General/MenuLanguage';
import EmailModel from '../Auth/EmailModel';
const Login = ({
  getLocationWithIp,
  loginObj,
  history,
  login,
  general,
  country,
  emailModal,
  resendEmailCode,
}) => {
  useEffect(() => {
    // this.check_is_authorize();
    getLocationWithIp();

    getMyLocation(
      [
        { prop: 'loginObj.form.lat', type: 'lat' },
        { prop: 'loginObj.form.lng', type: 'lng' },
      ],
      general,
      UPDATE_LOGIN_PROPS
    );
  }, []);

  return (
    <div className='login_wrapper'>
      <EmailModel
        general={general}
        emailModal={emailModal}
        resendEmailCode={resendEmailCode}
        history={history}
      />
      {/* <MenuLanguage /> */}

      <Container>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='center'>
            <img src={mapy} alt='mapy' width='200' />
          </Col>
        </Row>

        <LoginForm
          loginObj={loginObj}
          history={history}
          login={login}
          general={general}
          country={country}
        />

        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='center mt-4'>
            <img src={altawasol} alt='mapy' />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ LoginR, GeneralR, AuthR }) => {
  return {
    loginObj: LoginR.loginObj,
    country: GeneralR.location.country,
    emailModal: AuthR.emailModal,
  };
};
export default connect(mapStateToProps, actions)(Login);
