import { local } from '../lang/local';
import React, { useCallback } from 'react';

export const AuthOptions = ({ value, setValue, callback }) => {
  const updateLogin = useCallback((value) => {
    setValue(value);

    if (callback) {
      callback();
    }
  }, []);
  return (
    <div style={{ display: 'flex' }}>
      <button
        className={
          value === 'phone'
            ? 'login_switch login_switch_active'
            : 'login_switch login_switch_inactive'
        }
        onClick={(e) => {
          e.preventDefault();
          updateLogin('phone');
        }}
      >
        {local.LoginByMobile}
      </button>

      <div className='vertical_line' />

      <button
        className={
          value === 'email'
            ? 'login_switch login_switch_active'
            : 'login_switch login_switch_inactive'
        }
        onClick={(e) => {
          e.preventDefault();
          updateLogin('email');
        }}
      >
        {local.LoginByEmail}
      </button>
    </div>
  );
};
