import React from "react";
import { Row, Col } from "reactstrap";
import GeneralCard from "../../General/GeneralCard";
import { getLanguage, local } from "../../lang/local";
import { leftStyle } from "../../General/GeneralStyle";
import {
  UPDATE_TICKETS_PROPS,
  UPDATE_GENERAL_PROPS,
} from "../../Redux/Actions/types";
import { renderSingleImage } from "../../General/GeneralFunctions";
import avater2 from "../../assets/img/avater2.svg";
const SupportCard = ({
  history,
  support_tickets,
  general,
  fetchTicketMessages,
  generalUsers,
}) => {
  const goToAdd = (e) => {
    e.preventDefault();

    history.push("/dashboard/addticket");
  };

  const handleReplay = (e, ticket) => {
    e.preventDefault();
    if (ticket.status === true) {
      e.preventDefault();
      general(
        [
          { prop: "data_message.ticket", value: ticket },
          { prop: "data_message.items", value: null },
        ],
        UPDATE_TICKETS_PROPS
      );
    }
    fetchTicketMessages([], 1, ticket.id);

    history.push("/dashboard/tickets");
  };
  const renderSupportCard = () => {
    return (
      <div>
        <Row className="mr-2 ml-2">
          <Col xl={12}>
            <p className="white_color fontSize10" style={leftStyle}>
              {local.LatestSupport}
            </p>
          </Col>
        </Row>

        {support_tickets && support_tickets.length
          ? support_tickets.map((supoort) => {
              if (!supoort.user && generalUsers.length > 0) {
                supoort.user = generalUsers.find(
                  (u) => u.id === supoort.userId
                );
              }
              return (
                <Row className="mt-3 alignItem" key={supoort.id}>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12} className="flex">
                    {supoort.user ? (
                      renderSingleImage(supoort.user.image, general)
                    ) : (
                      <img src={avater2} className="img_post" alt="" />
                    )}

                    <div
                      style={{ marginInlineStart: "10px" }}
                      className="inline_tag alignItem"
                    >
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          general(
                            [
                              {
                                prop: "profileModal",
                                value: {
                                  isOpen: true,
                                  data: supoort.user,
                                  type: "user",
                                },
                              },
                            ],
                            UPDATE_GENERAL_PROPS
                          );
                        }}
                      >
                        <span className="white_color" style={leftStyle}>
                          {supoort.user ? supoort.user.fullName : ""}
                        </span>
                      </a>

                      <p
                        className="commentText"
                        style={{
                          float: getLanguage() === "en" ? "left" : "right",
                          textAlign: getLanguage() === "en" ? "left" : "right",
                          clear: getLanguage() === "en" ? "left" : "right",
                        }}
                      >
                        {supoort.description}
                      </p>
                    </div>

                    <div
                      style={{
                        right: getLanguage() === "en" ? "15px" : null,
                        left: getLanguage() === "ar" ? "15px" : null,

                        position: "absolute",
                      }}
                    >
                      <p
                        className="light_color cursor"
                        onClick={(e) => handleReplay(e, supoort)}
                      >
                        {local.Reply}
                      </p>
                    </div>
                  </Col>
                </Row>
              );
            })
          : null}
      </div>
    );
  };

  return (
    <Col className="center mt-4" xl={4} lg={4} md={6} sm={12} xs={12}>
      <GeneralCard
        title={local.Support}
        buttonItem={{ title: local.OpenSupport, handleClick: goToAdd }}
        count={null}
        titleColor="white_color"
        className="text-center maxWidth"
        background="#002345"
        renderBody={renderSupportCard}
      />
    </Col>
  );
};

export default SupportCard;
