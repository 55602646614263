import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuth } from '../utils/AuthenticateFunctions';

function AuthenticatedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth() ? <Component {...rest} {...props} /> : <Redirect to='/login' />
      }
    />
  );
}

export default AuthenticatedRoute;
