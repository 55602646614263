import {
  UPDATE_USER_CLAIMS_PROPS,
  MERGE_USER_CLAIMS_PROPS,
  DELETE_USER_CLAIMS_PROPS,
  CLEAR_USER_CLAIMS_STATE,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    items: null,
    pagination: null,
    isLoadingDelete: null,
    filterArray: [],
    claimValue: "",
    form: {
      Description: "",
      claimType: "",
      claimValue: "",
      geometry: [],
    },

    errorMessages: {
      Name: "",
      Description: "",
      User: "",
      Location: "",
      ComplaintType: "",
    },
    permissions: [],
    userId: null,
    item_index: null,
    isLoadingCreate: false,
    isLoading: false,
  },
};

const UserClaimsR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_CLAIMS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_USER_CLAIMS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_USER_CLAIMS_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_USER_CLAIMS_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default UserClaimsR;
