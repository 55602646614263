import {
  UPDATE_AUTH_PROPS,
  CLEAR_AUTH_STATE,
  MERGE_AUTH_PROPS,
  DELETE_AUTH_PROPS
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  forgetForm: {
    email: "",
    phone: ""
  },

  resetForm: {
    password: "",
    confirmPassword: "",
    email: "",
    phone: "",
    token: ""
  },

  confirmEmailForm: {
    token: "",
    userId: null
  },
  confirmEmail: "",
  isLoadingConfirmEmail: false,
  isLoadingForget: false,
  isLoadingReset: false,
  isLoadingResendEmail: false,
  emailModal: { isOpen: false, email: "" },
  updateEmailForm: {
    newemail: ""
  },
  isLoadingUpdateEmail: false
};

const AuthR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_AUTH_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_AUTH_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_AUTH_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};

export default AuthR;
