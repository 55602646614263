import { Request, SendRequest } from './api';
import { MAP_USER, USER_CLAIMS, USER_ROLES } from '../../General/StaticKeys';
import {
  UPDATE_AUTH_PROPS,
  UPDATE_GENERAL_PROPS,
  UPDATE_LOGIN_PROPS,
} from './types';
import { showConfirmMessage } from '../../General/GeneralFunctions';
import { getUser } from '../../utils/StorageFunctions';

import { local } from '../../lang/local';
export const login = (formValues, history) => (dispatch) => {
  function updateEmailForm(item) {
    dispatch({
      type: UPDATE_AUTH_PROPS,
      payload: [
        {
          prop: 'updateEmailForm',
          value: {
            newemail: item.email,
          },
        },
      ],
    });
  }
  dispatch({
    type: UPDATE_LOGIN_PROPS,
    payload: [{ prop: 'loginObj.isLoading', value: true }],
  });
  Request()
    .post('/Auth/login', formValues)
    .then((res) => {
      if (res.status === 200) {
        res.data.data.date = new Date();
        localStorage.setItem(MAP_USER, JSON.stringify(res.data));
        if (res.data.data.email) {
          if (res.data.data.emailConfirmed) {
            history.push('/');
          } else {
            updateEmailForm(res.data.data);
            dispatch({
              type: UPDATE_AUTH_PROPS,
              payload: [
                {
                  prop: 'emailModal',
                  value: { isOpen: true, email: res.data.data.email },
                },
              ],
            });

            //send code to email and go to confirm email
          }
        } else {
          updateEmailForm(res.data.data);
          history.push('/update-email');

          //go to update email
        }
      }

      dispatch({
        type: UPDATE_LOGIN_PROPS,
        payload: [{ prop: 'loginObj.isLoading', value: false }],
      });
    })
    .catch((err) => {
      showConfirmMessage(local.PhonePasswordRequired, 'error');
      dispatch({
        type: UPDATE_LOGIN_PROPS,
        payload: [{ prop: 'loginObj.isLoading', value: false }],
      });
      console.log(err);
    });
};

export const logout = (history, notReload) => (dispatch) => {
  localStorage.removeItem(MAP_USER);
  localStorage.removeItem(USER_CLAIMS);
  localStorage.removeItem(USER_ROLES);

  history.push('/login');

  if (notReload === undefined) {
    window.location.reload();
  }

  // SendRequest()
  //   .get("/Auth/logout")
  //   .then((res) => {
  //     if (res.status === 200) {
  //       if (res.data.message === "logged out") {
  //         localStorage.removeItem(MAP_USER);
  //         history.push("/login");
  //       }
  //     }
  //   })
  //   .catch((e) => {
  //     console.log(e);

  //     showConfirmMessage(local.SomethingError, "error");
  //   })
  //   .finally();
};

export const getPermissions = (history) => (dispatch) => {
  let user = getUser()?.data;
  let claims = [];
  SendRequest()
    .get(`/Roles/User/Claims/${user.id}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data.length > 0) {
          claims = claims.concat(res.data.data);
          localStorage.setItem(USER_CLAIMS, JSON.stringify(claims));
        }
      }
      dispatch({
        type: UPDATE_GENERAL_PROPS,
        payload: [{ prop: 'isLoadingClaims', value: true }],
      });
    })
    .catch((e) => {
      dispatch(logout(history, true));
      showConfirmMessage(local.TokenExpired, 'error');

      dispatch({
        type: UPDATE_GENERAL_PROPS,
        payload: [{ prop: 'isLoadingClaims', value: false }],
      });
      console.log(e);
    })
    .finally();
  SendRequest()
    .get(`/Roles/User/${user.id}`)
    .then((res) => {
      if (res.status === 200) {
        history.push('/dashboard');

        if (res.data.data.length > 0) {
          localStorage.setItem(USER_ROLES, JSON.stringify(res.data.data));

          // res.data.data.map((r, i) => {
          //   SendRequest()
          //     .get(`/Roles/GetRoleClaims/${r.id}`)
          //     .then((r) => {
          //       if (r.status === 200) {
          //         if (r.data.data.length > 0) {
          //           claims = claims.concat(r.data.data);
          //         }

          //         if (res.data.data.length - 1 === i) {
          //           localStorage.setItem(USER_CLAIMS, JSON.stringify(claims));
          //         }
          //       }
          //     })
          //     .catch((e) => {})
          //     .finally();
          // });
        }
      }
      dispatch({
        type: UPDATE_GENERAL_PROPS,
        payload: [{ prop: 'isLoadingRoles', value: true }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_GENERAL_PROPS,
        payload: [{ prop: 'isLoadingRoles', value: false }],
      });
      console.log(e);
    })
    .finally();
};
