import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { Col, Row, Card } from 'reactstrap';
import msg_location from '../../assets/img/msg_location.png';
import { IMAGE_BASE_PATH } from '../../General/StaticKeys';
import { UPDATE_COMPLAINTS_PROPS } from '../../Redux/Actions/types';
import { renderName } from '../../General/GeneralFunctions';
const useStyles = makeStyles((theme) => ({
  typography: {
    width: '400px',
  },
}));

export default function ComplaintTypeModel({
  allComplaintTypes,
  general,
  points,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const renderTypes = () => {
    if (allComplaintTypes) {
      let icons = allComplaintTypes.map((comp) => comp.icon);

      return allComplaintTypes.map((type) => {
        return (
          <Col
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className='mt-4'
            key={type.id}
          >
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className='center'>
                <Card
                  className='card_violation_item center cursor'
                  onClick={(e) => {
                    e.preventDefault();
                    general(
                      [
                        { prop: 'data.form.GeoEventsTypeId', value: +type.id },
                        { prop: 'data.isOpenAdd', value: true },
                        {
                          prop: 'data.form.Location',
                          value: [`POINT(${points.lng} ${points.lat})`],
                        },
                      ],
                      UPDATE_COMPLAINTS_PROPS
                    );
                  }}
                >
                  {type.icon ? (
                    <div
                      style={{
                        width: '50px',
                        borderRadius: '100%',
                        height: '50px',
                      }}
                    >
                      <img
                        src={IMAGE_BASE_PATH + type.icon}
                        alt=''
                        className='cursor fullWidthHeight'
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   general(
                        //     [
                        //       {
                        //         prop: "lightbox",
                        //         value: {
                        //           images: icons
                        //             ? icons.map((ic) => IMAGE_BASE_PATH + ic)
                        //             : [],
                        //           type: "multi",
                        //           index: index,
                        //           isOpen: true,
                        //         },
                        //       },
                        //     ],
                        //     UPDATE_GENERAL_PROPS
                        //   );
                        // }}
                      />
                    </div>
                  ) : null}
                </Card>
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className='mt-1 center'
              >
                <p style={{ height: '30px' }}>{renderName(type.name)}</p>
              </Col>
            </Row>
          </Col>
        );
      });
    } else {
    }
  };

  return (
    <React.Fragment>
      <a className='cursor' onClick={handleClick}>
        <div className='msg_icon mb-3'>
          <img src={msg_location} alt='msg' className='block' />
        </div>
      </a>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>
          <div className='fullWidthHeight'>
            <Row className='alignItem mt-2'>{renderTypes()}</Row>
          </div>
        </Typography>
      </Popover>
    </React.Fragment>
  );
}
