import { MAP_USER } from '../General/StaticKeys';

export const isAuth = () => {
  let item = JSON.parse(localStorage.getItem(MAP_USER));

  if (item) {
    if (item.data) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
