import React from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { UPDATE_GENERAL_PROPS } from "../Redux/Actions/types";

const Gallery = ({ lightbox, general }) => {
  if (lightbox.isOpen) {
    if (lightbox.type === "single") {
      return (
        <Lightbox
          image={lightbox.image}
          onClose={() =>
            general(
              [{ prop: "lightbox", value: { image: null, isOpen: false } }],
              UPDATE_GENERAL_PROPS
            )
          }
        />
      );
    } else {
      return (
        <Lightbox
          startIndex={lightbox.index}
          onNavigateImage={(index) =>
            general(
              [{ prop: "lightbox.index", value: index }],
              UPDATE_GENERAL_PROPS
            )
          }
          images={lightbox.images}
          onClose={() =>
            general(
              [
                {
                  prop: "lightbox",
                  value: {
                    images: [],
                    isOpen: false,
                    image: null,
                    type: "single",
                    index: 0,
                  },
                },
              ],
              UPDATE_GENERAL_PROPS
            )
          }
        />
      );
    }
  } else {
    return null;
  }
};
export default Gallery;
