import React, { useEffect, useRef } from "react";
import { Input, Card, Row, Col, Spinner, Button } from "reactstrap";
import web_view_search_icon from "../../assets/img/web_view_search_icon.svg";
import SearchResult from "./SearchResult";
import { getLanguage, local } from "../../lang/local";
import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
import { useDebouncedCallback } from "use-debounce";
import {
  showConfirmMessage,
  convert_to_lat_lng_point,
} from "../GeneralFunctions";

const SearchBox = ({
  mapSearch,
  general,
  action,
  myPosition,
  ipAddress,
  getSocketResult,
  handlePlaceSearch,
  propObj,
  updateBounds,
  nearest_point,
  entries,
  actionType,
}) => {
  const handleNearestBranch = (e) => {
    e.preventDefault();
    if (nearest_point.lat && nearest_point.lng && entries) {
      if (entries.length > 0) {
        let items = entries.map((entry) => entry.locationText);

        if (items) {
          items.map((item, index) => {
            return getSocketResult(
              null,
              {
                start: nearest_point.lat,
                end: convert_to_lat_lng_point(item, "lat"),
              },
              {
                start: nearest_point.lng,
                end: convert_to_lat_lng_point(item, "lng"),
              },
              "distance",
              `data.items.${index}.distance`,
              actionType
            );
          });
        }
      }
    }
  };
  const nearest = () => {
    if (nearest_point !== undefined) {
      return (
        <Col xl={3} className="center mx-auto">
          <Button className="add_btn" onClick={handleNearestBranch}>
            Find Nearest
          </Button>
        </Col>
      );
    }
  };
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevProps = usePrevious({ mapSearch });
  useEffect(() => {
    if (prevProps !== undefined) {
      if (
        prevProps.mapSearch.searchValue !== mapSearch.searchValue &&
        mapSearch.searchValue === ""
      ) {
        general(
          [
            {
              prop: "mapSearch",
              value: {
                searchResults: [],
                searchValue: "",
                type: "autoComplete",
                isLoading: false,
                bounds: null,
                lat: "",
                lng: "",
              },
            },
          ],
          UPDATE_GENERAL_PROPS
        );
        if (propObj && action) {
          general(propObj, action);
        }
      }
    }
  }, [mapSearch.searchValue]);

  const handleSearch = (e) => {
    e.preventDefault();
    general(
      [{ prop: "mapSearch.type", value: "search" }],
      UPDATE_GENERAL_PROPS
    );

    if (mapSearch.searchValue !== "") {
      let lat = myPosition.lat;
      let lng = myPosition.lng;
      if (myPosition.allowLocation === false) {
        lat = ipAddress.lat;
        lng = ipAddress.lng;
      }
      getSocketResult(mapSearch.searchValue, lat, lng, "dashboard_search");
    } else {
      showConfirmMessage(local.SearchValueRequired, "error");
    }
  };

  const debounced = useDebouncedCallback((value) => {
    if (value) {
      let lat = myPosition.lat;
      let lng = myPosition.lng;
      if (myPosition.allowLocation === false) {
        lat = ipAddress.lat;
        lng = ipAddress.lng;
      }
      getSocketResult(value, lat, lng, "dashboard_auto_complete");
    }
  }, 1000);
  return (
    <div
      style={{
        position: "absolute",
        zIndex: "100",
        // direction: "ltr",
        left: getLanguage() === "en" ? "0px" : null,
        right: getLanguage() === "ar" ? "0px" : null,
        borderRadius: "10px",
        width: "400px",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      <Card
        style={{
          position: "relative",
          minWidth: "fit-content",
          width: "400px",
          borderRadius: "0px 0px 46.3647px 46.3647px",
        }}
        className={
          mapSearch.searchResults.length === 0
            ? "web_view_card_search"
            : "web_view_card_search_slide"
        }
      >
        <Row className="mt-2">
          <Col>
            <div className="search_item">
              <img
                src={web_view_search_icon}
                alt="search_icon"
                className="search_icon cursor"
                onClick={handleSearch}
              />
              <Input
                className="web_view_search_input"
                placeholder={local.Search}
                value={mapSearch.searchValue}
                onKeyDown={(e) => (e.key === "Enter" ? handleSearch(e) : null)}
                onChange={(e) => {
                  general(
                    [{ prop: "mapSearch.searchValue", value: e.target.value }],
                    UPDATE_GENERAL_PROPS
                  );
                  debounced(e.target.value);
                }}
              />

              {mapSearch.isLoading === true ? (
                <div>
                  <Spinner className="search_loader" />
                </div>
              ) : null}
            </div>
          </Col>

          {nearest()}
        </Row>

        <SearchResult
          general={general}
          mapSearch={mapSearch}
          handlePlaceSearch={handlePlaceSearch}
          updateBounds={updateBounds}
        />
      </Card>
    </div>
  );
};

export default SearchBox;
