import React from "react";
import { getLanguage } from "../../lang/local";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import strik from "../../assets/img/strik.png";
export const Phone = ({ defaultCountry, icon, value, onChange }) => {
  return (
    <div className="container_add_input mx-auto">
      {icon ? <img src={strik} alt="strik" className="strik_icon" /> : null}
      <PhoneInput
        country={defaultCountry ? defaultCountry.toLowerCase() : "sa"}
        lang={getLanguage() === "ar" ? ar : ""}
        // onlyCountries={["eg", "sa", "ae", "ku", "lb", "om", "bh"]}
        masks={{
          eg: ".. ........",
          sa: ".. ... ....",
          ae: ".. .......",
        }}
        // countryCodeEditable={false}
        value={value}
        onChange={(value) => onChange(value)}
      />
    </div>
  );
};
