import * as React from "react";
import Pagination from "@mui/material/Pagination";
import { removeUnsetValuesFromFilters } from "./GeneralFunctions";
const PaginationItem = ({ pagination, general, fetchData, filterArray }) => {
  const handleChange = (event, value) => {
    let newFilterArr = [...filterArray];
    newFilterArr = removeUnsetValuesFromFilters(newFilterArr);
    fetchData(newFilterArr, value);
  };

  return (
    <Pagination
      count={pagination.totalPages}
      page={pagination.currentPage}
      color="primary"
      onChange={handleChange}
      variant="outlined"
      shape="rounded"
    />
  );
};
export default PaginationItem;
