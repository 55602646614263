import React from "react";
import { Card, Row, Col } from "reactstrap";
// import GeneralMap from "../../General/GeneralMap";
import { countries } from "../../General/countries.js";
import HeatMap from "./HeatMap";
const MapCard = ({ location, usersMap, general, getAllUsersForMap }) => {
  // const filterUsersAccordingToCountry = () => {
  //   if (usersMap.length > 0) {
  //     let avaliableCountries = Array.from(
  //       new Set(
  //         usersMap.map((user) => {
  //           if (user.country) {
  //             return user.country.toUpperCase();
  //           }
  //         })
  //       )
  //     );

  //     let polygons = [];

  //     avaliableCountries.map((c) => {
  //       let item = countries.features.find((country) => country.id === c);
  //       if (item !== undefined) {
  //         polygons.push(item);
  //       }
  //     });

  //     return polygons;
  //   } else {
  //     return [];
  //   }
  // };
  return (
    <div
      className="mt-4"
      data-aos="fade-down"
      data-aos-delay="20"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <Card
        className="map_container"
        style={{
          height: "680px",
          width: "100%",
          // border: "1px solid red",
        }}
      >
        {/* lorem assdfgsd asdfdsajf askdsakll */}
        {/* <Row style={{ height: "680px" }}>
          <Col xl={12} style={{ width: "100%" }}> */}
        <HeatMap
          location={location}
          usersMap={usersMap}
          general={general}
          getAllUsersForMap={getAllUsersForMap}
        />
        {/* </Col> */}

        {/* <Col>asd</Col> */}
        {/* </Row> */}
        {/* <GeneralMap location={location} /> */}
      </Card>
    </div>
  );
  {
    /* <MapContainer {...this.props} height="600px" /> */
  }
};

export default MapCard;
