import React from 'react';
import {
  IMAGE_BASE_PATH,
  MAP_USER,
  DEFAULT_THEME,
  RECENT_SEARCH,
  USER_CLAIMS,
  USER_ROLES,
} from './StaticKeys';
import { numberToArabic } from 'number-to-arabic';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import { HiBell } from 'react-icons/hi';
import { Button, Col, Row } from 'reactstrap';
import { getLanguage, local } from '../lang/local';
import { columnAlignLeft, leftStyle,  } from './GeneralStyle';
import facebook_icon from '../assets/img/facebook_icon.png';
import google_icon from '../assets/img/google_icon.png';
import approve from '../assets/img/approve.svg';
import reject from '../assets/img/reject.svg';
import swal from 'sweetalert';
import subtract from '../assets/img/subtract.png';
import avater2 from '../assets/img/avater2.svg';
import no_photo from '../assets/img/avater2.svg';
import {
  UPDATE_GENERAL_PROPS,
  UPDATE_SEARCH_MENU_PROPS,
  UPDATE_USER_ACCOUNT_PROPS,
  UPDATE_DIRECTION_MENU_PROPS,
} from '../Redux/Actions/types';
import { confirmAlert } from 'react-confirm-alert';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { FiX } from 'react-icons/fi';
import { getUser, renderUserName } from '../utils/StorageFunctions';

export const check_authorization = (history) => {
  let item = JSON.parse(localStorage.getItem(MAP_USER));

  if (item) {
    if (item.data.emailConfirmed === false) {
      return history.push('/login');
    } else {
      if (new Date(item.date).getDate() < new Date().getDate()) {
        localStorage.removeItem(MAP_USER);
        localStorage.removeItem(USER_CLAIMS);
        localStorage.removeItem(USER_ROLES);
        history.push('/login');
        window.location.reload();
      } else {
        return true;
      }
    }

    // return history.push("/");
  } else {
    return history.push('/login');
  }
};



export const DateToArabic = (date1) => {
  if (date1 !== undefined && date1 !== '') {
    let date = new Date(date1);
    date = date.toGMTString();
    let day = date.split(',')[0];
    let result = null;
    if (day === 'Sat') {
      result = 'السبت';
    } else if (day === 'Sun') {
      result = 'الاحد';
    } else if (day === 'Mon') {
      result = 'الاتنين';
    } else if (day === 'Tue') {
      result = 'الثلاثاء';
    } else if (day === 'Wed') {
      result = 'الاربعاء';
    } else if (day === 'Thu') {
      result = 'الخميس';
    } else {
      result = 'الجمعة';
    }

    let month = date.split(',')[1].split(' ')[2];

    let arabicMonth = null;
    if (month === 'Jan') {
      arabicMonth = 'يناير';
    } else if (month === 'Feb') {
      arabicMonth = 'فبراير';
    } else if (month === 'Mar') {
      arabicMonth = 'مارس';
    } else if (month === 'Apr') {
      arabicMonth = 'ابريل';
    } else if (month === 'May') {
      arabicMonth = 'مايو';
    } else if (month === 'June') {
      arabicMonth = 'يونيو';
    } else if (month === 'Jul') {
      arabicMonth = 'يوليو';
    } else if (month === 'Aug') {
      arabicMonth = 'أغسطس';
    } else if (month === 'Sep') {
      arabicMonth = 'سبتمبر';
    } else if (month === 'Oct') {
      arabicMonth = 'اكتوبر';
    } else if (month === 'Nov') {
      arabicMonth = 'نوفمبر';
    } else {
      arabicMonth = 'ديسمبر';
    }
    result =
      result +
      ' ,' +
      numberToArabic(Number(date.split(',')[1].split(' ')[1])) +
      ' ' +
      arabicMonth +
      ' ' +
      numberToArabic(Number(date.split(',')[1].split(' ')[3]));

    return result;
  }
};

export const NotificationIcon = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Badge badgeContent={4} color='error'>
        <HiBell size={25} className='blue_color' />
      </Badge>
    </div>
  );
};

export const getMyLocation = (array_props, general, action, country_prop) => {
  navigator.geolocation.getCurrentPosition(
    function (position) {
      array_props.forEach((p) => {
        if (p.type === 'lat') {
          general(
            [
              { prop: p.prop, value: position.coords.latitude },
              { prop: 'myPosition.allowLocation', value: true },
            ],
            action
          );
        } else {
          general([{ prop: p.prop, value: position.coords.longitude }], action);
        }
      });
    },
    function (error) {
      showError(error);
    }
  );
};

export const renderFacebookButton = () => {
  return (
    <Col xl={6} lg={6} md={6} sm={12} xs={12} className='mx-auto mt-5'>
      <Button className='facebook_btn mx-auto'>
        <img src={facebook_icon} alt='facebook' />
        <p style={{ marginInlineStart: '3px' }}>{local.WITHFACEBOOK}</p>
      </Button>
    </Col>
  );
};
export const renderGooglekButton = () => {
  return (
    <Col xl={6} lg={6} md={6} sm={12} xs={12} className='mx-auto mt-5'>
      <Button className='google_btn mx-auto'>
        <img src={google_icon} alt='google' style={leftStyle} />

        <p style={{ marginInlineStart: '3px' }}> {local.WITHGOOGLE}</p>
      </Button>
    </Col>
  );
};
export const showError = (error) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      console.log(local.UserDenied);
      // showConfirmMessage(local.UserDenied, "error");
      break;
    case error.POSITION_UNAVAILABLE:
      console.log(local.UnavailableLoc);

      // showConfirmMessage(local.UnavailableLoc, "error");

      break;
    case error.TIMEOUT:
      console.log(local.LocationTimedOut);
      // showConfirmMessage(local.LocationTimedOut, "error");

      break;
    case error.UNKNOWN_ERROR:
      console.log(local.UnknownError);
      // showConfirmMessage(local.UnknownError, "error");

      break;
  }
};
export const showConfirmMessage = (title, type) => {
  return (
    <div className={type === 'error' ? 'error_alert' : ''}>
      {swal(title, '', type)}
    </div>
  );
};

export const showSuccessedConfirm = (title) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <p>{title}</p>
          <button
            onClick={() => {
              onClose();
            }}
          >
            {local.OK}
          </button>
        </div>
      );
    },
  });
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (password) => {
  if (password.length > 7) {
    return true;
  } else {
    return false;
  }
  // const re_pass =
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

  // return re_pass.test(String(password).toLowerCase());
};

export const validateForm = (formValues) => {
  try {
    let array = Object.entries(formValues);

    if (
      array.find((a) => a[1] === '' && a[0] !== 'image' && a[0] !== 'email')
    ) {
      showConfirmMessage(local.DataRequired, 'error');
    } else {
      let email = array.find((f) => f[0] === 'email');
      let is_valid_email = true;
      let password = array.find((f) => f[0] === 'password');
      let confirm_password = array.find((f) => f[0] === 'passwordConfirmation');
      let is_valid_password = true;
      // let phone_number = array.find((f) => f[0] === "phoneNumber");
      let is_valid_mobile = true;
      if (email !== undefined) {
        if (validateEmail(email[1]) === false) {
          showConfirmMessage(local.ValidEmail, 'error');
          is_valid_email = false;
        } else {
          is_valid_email = true;
        }
      }
      if (password !== undefined) {
        if (validatePassword(password[1]) === false) {
          showConfirmMessage(local.PasswordValidate, 'error');
          is_valid_password = false;
        } else {
          is_valid_password = true;
        }
      } else if (password !== undefined && confirm_password !== undefined) {
        if (
          validatePassword(password[1]) === true &&
          password[1] !== confirm_password[1]
        ) {
          showConfirmMessage(local.PasswordMatched, 'error');
          is_valid_password = false;
        } else {
          is_valid_password = true;
        }
      }

      // if (phone_number !== undefined) {
      //   if (phone_number[1].length !== 12) {
      //     showConfirmMessage(local.PhoneNumberLength, "error");
      //     is_valid_mobile = false;
      //   } else {
      //     is_valid_mobile = true;
      //   }
      // }
      if (
        is_valid_email === true &&
        is_valid_password === true &&
        is_valid_mobile === true
      ) {
        return true;
      }
    }
  } catch (e) {
    showConfirmMessage(local.SomethingError, 'error');
  }
};

export const renderFormData = (formObj) => {
  let newItems = Object.entries(formObj);
  let formdata = new FormData();
  newItems.forEach((entry) => {
    if (entry[0] === 'image') {
      if (entry[1][0] instanceof File) {
        formdata.append(entry[0], entry[1][0]);
      }
    } else if (entry[0] === 'Images') {
      entry[1].forEach((arr) => {
        formdata.append(entry[0], arr);
      });
    } else if (typeof entry[1] === 'object') {
      if (entry[0] === 'WorkingHours') {
        Object.entries(entry[1]).forEach((d, i) => {
          Object.entries(d[1]).forEach((a) => {
            formdata.append(
              entry[0] + '[' + d[0] + ']' + '[' + a[0] + ']',
              a[1]
            );
          });
        });
      } else {
        if (entry[1]) {
          Object.entries(entry[1]).forEach((ob) => {
            formdata.append(entry[0] + '[' + ob[0] + ']', ob[1]);
          });
        }
      }
    } else if (Array.isArray(entry[1]) && entry[0] !== 'Images') {
      entry[1].forEach((arr, i) => {
        formdata.append(entry[0] + '[' + i + ']', arr);
      });
    } else {
      formdata.append(entry[0], entry[1]);
    }
  });
  for (var pair of formdata.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }

  return formdata;
};

export const removeUnsetValues = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === '' ||
      obj[propName] === [] ||
      obj[propName] === {}
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const convertToFormData = (formObj) => {
  let newFormObject = removeUnsetValues(formObj);
  let newItems = Object.entries(newFormObject);
  let formdata = new FormData();
  newItems.forEach((entry) => {
    if (entry[0] === 'Icon' || entry[0] === 'image' || entry[0] === 'Logo') {
      formdata.append(entry[0], entry[1][0]);
    } else if (
      entry[0] === 'Images' ||
      entry[0] === 'Photos' ||
      entry[0] === 'geometry' ||
      entry[0] === 'Attachments'
    ) {
      if (
        entry[0] === 'Photos' ||
        entry[0] === 'Images' ||
        entry[0] === 'geometry' ||
        entry[0] === 'Attachments'
      ) {
        entry[1].forEach((arr, i) => {
          formdata.append(entry[0], arr);
        });
      } else {
        entry[1].forEach((arr, i) => {
          formdata.append(entry[0] + '[' + i + ']', arr);
        });
      }
    } else if (typeof entry[1] === 'object' && entry[0] !== 'geometry') {
      if (entry[0] === 'WorkingHours') {
        Object.entries(entry[1]).forEach((d, i) => {
          Object.entries(d[1]).forEach((a) => {
            formdata.append(
              entry[0] + '[' + d[0] + ']' + '[' + a[0] + ']',
              a[1]
            );
          });
        });
      } else {
        Object.entries(entry[1]).forEach((obj) => {
          formdata.append(entry[0] + '[' + obj[0] + ']', obj[1]);
        });
      }
    } else if (
      Array.isArray(entry[1]) &&
      entry[0] !== 'Images' &&
      entry[0] !== 'Photos' &&
      entry[0] !== 'geometry' &&
      entry[0] !== 'Attachments'
    ) {
      entry[1].forEach((arr, i) => {
        formdata.append(entry[0] + '[' + i + ']', arr);
      });
    } else {
      formdata.append(entry[0], entry[1]);
    }
  });
  for (var pair of formdata.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }

  return formdata;
};

export const dataURItoBlob = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const convertArrayToObject = (array, label) => {
  const initialValue = {};

  let key;

  if (label === undefined) {
    key = 'day';
  } else {
    key = label;
  }
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const getDate = (date) => {
  try {
    if (getLanguage() === 'en') {
      return new Date(date).toLocaleString('en');
    } else {
      return new Date(date).toLocaleString('ar');
    }
  } catch (e) {
    return new Date();
  }
};

export const getQueryString = (obect) => {
  let query = '';

  Object.keys(obect).forEach(function (key, index) {
    if (index === 0) {
      query += '?';
    } else {
      query += '&';
    }
    query += key + '=' + obect[key];
  });
  return query;
};
export const removeDublicatedValues = (
  name,
  street,
  county,
  region,
  country,
  continent
) => {
  let label = '';
  if (name !== undefined) {
    label += name + ' , ';
  }
  if (street !== undefined) {
    label += street + ' , ';
  }
  if (county !== undefined) {
    label += county + ',';
  }
  if (region !== undefined) {
    label += region + ' , ';
  }
  if (country !== undefined) {
    label += country + ' , ';
  }
  if (continent !== undefined) {
    label += continent;
  }

  let entry = label.split(',');

  let uniqueChars = [...new Set(entry)];

  return uniqueChars.toString();
};

export const checkIsLink = (value) => {
  let url =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (url.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const removeUnsetValuesFromFilters = (filterArray) => {
  return filterArray.filter((item) => item.value !== '' && item.value !== null);
};

export const saveImage = (acceptedFiles, prop, general, action) => {
  let sizeInMB = (acceptedFiles[0].size / (1024 * 1024)).toFixed(2);
  if (+sizeInMB > 1) {
    showConfirmMessage(local.ValidateFileLength2, 'error');
  }

  if (+sizeInMB <= 1) {
    general(
      [
        {
          prop,
          value: acceptedFiles,
        },
      ],
      action
    );
  }
};

export const renderMultipleImages = (images, general, noPhoto, isFull) => {
  if (images) {
    if (images.length > 0) {
      return (
        <img
          src={IMAGE_BASE_PATH + images[0]}
          alt=''
          className={isFull !== undefined ? 'imgFull' : 'img_table cursor'}
          style={{ borderRadius: '100%' }}
          onClick={(e) => {
            e.preventDefault();
            general(
              [
                {
                  prop: 'lightbox',
                  value: {
                    images: images.map((img) => {
                      return { url: IMAGE_BASE_PATH + img };
                    }),
                    type: 'multi',
                    index: 0,

                    isOpen: true,
                  },
                },
              ],
              UPDATE_GENERAL_PROPS
            );
          }}
        />
      );
    } else {
      if (noPhoto !== undefined) {
        return (
          <img
            src={no_photo}
            className={isFull !== undefined ? 'imgFull' : 'img_table'}
            alt=''
          />
        );
      } else {
        return (
          <img
            src={avater2}
            className={isFull !== undefined ? 'imgFull' : 'img_table'}
            alt=''
          />
        );
      }
    }
  } else {
    if (noPhoto !== undefined) {
      return <img src={no_photo} className='img_table' alt='' />;
    } else {
      return <img src={avater2} className='img_table' alt='' />;
    }
  }
};
export const renderSingleImage = (image, general) => {
  return (
    <img
      src={renderImage(image)}
      alt=''
      className='img_table cursor'
      onClick={(e) =>
        image && checkHasExtension(image)
          ? general(
              [
                {
                  prop: 'lightbox',
                  value: {
                    image: IMAGE_BASE_PATH + image,
                    isOpen: true,
                    type: 'single',
                  },
                },
              ],
              UPDATE_GENERAL_PROPS
            )
          : null
      }
    />
  );
};

export const checkHasExtension = (image) => {
  if (image) {
    if (image.split('.')[1] !== undefined) {
      return true;
    }
  }
};

export const renderPermissionItem = (
  item,
  general,
  permissions,
  merge_action,
  update_action,
  delete_action
) => {
  return (
    <div className='center'>
      <div className='round' style={{ width: '30%' }}>
        <input
          type='checkbox'
          id={item.value}
          checked={
            permissions
              ? permissions.find((cl) => cl === item.value)
                ? true
                : false
              : false
          }
          onChange={(e) =>
            e.target.checked === false
              ? permissions.length === 1
                ? general(
                    [
                      {
                        prop: 'data.permissions',
                        value: [],
                      },
                    ],
                    update_action
                  )
                : general(
                    [
                      {
                        prop:
                          'data.permissions.' + permissions.indexOf(item.value),
                      },
                    ],
                    delete_action
                  )
              : general(
                  [
                    {
                      prop: 'data.permissions',
                      value: item.value,
                    },
                  ],
                  merge_action
                )
          }
        />
        <label htmlFor={item.value}></label>
      </div>
      <div
        style={{
          textAlign: getLanguage() === 'ar' ? 'right' : 'left',
          width: '70%',
        }}
      >
        <p className='mt-2' style={{ marginInlineStart: '10px' }}>
          {item.label}
        </p>
      </div>
    </div>
  );
};

export const handleEnglishNameChange = (e, general, action, prop) => {
  let arregex = /[\u0621-\u064A]+/;

  if (arregex.test(e.target.value) === false || e.target.value === '') {
    if (arregex.test(e.target.value) === false && e !== null) {
      general(
        [
          {
            prop: prop,
            value: e.target.value,
          },
        ],
        action
      );
    } else {
      general(
        [
          {
            prop: prop,
            value: '',
          },
        ],
        action
      );
    }
  } else {
    alert(local.EnglishLang);
  }
};

export const handleArabicNameChange = (e, general, action, prop) => {
  let regX = /[^\u0600-\u06FF ]/;
  if (regX.test(e.target.value) === false || e.target.value === '') {
    if (regX.test(e.target.value) === false && e !== null) {
      general(
        [
          {
            prop: prop,
            value: e.target.value,
          },
        ],
        action
      );
    } else {
      general(
        [
          {
            prop: prop,
            value: '',
          },
        ],
        action
      );
    }
  } else {
    alert(local.ArabicLang);
  }
};

export const handleArrawCardClick = (
  rows_collabse,
  index,
  general,
  title,
  type,
  point
) => {
  function handleClick(e) {
    e.preventDefault();
    if (!rows_collabse[index]) {
      general(
        [{ prop: `rows_collabse.${index}`, value: true }],
        UPDATE_GENERAL_PROPS
      );
      if (type !== null && point !== null) {
        general(
          [
            {
              prop: `zoomToShape.${index}`,
              value: { title, index, type, point },
            },
            {
              prop: 'shapeItem',
              value: { title, index, type, point },
            },
          ],
          UPDATE_GENERAL_PROPS
        );
      }
    } else {
      general(
        [{ prop: `rows_collabse.${index}`, value: null }],
        UPDATE_GENERAL_PROPS
      );
      if (type !== null && point !== null) {
        general(
          [
            {
              prop: `zoomToShape.${index}`,
              value: null,
            },
            { prop: 'shapeItem', value: null },
          ],
          UPDATE_GENERAL_PROPS
        );
      }
    }
  }

  function renderRightArrow() {
    return <FiChevronRight size={20} className='cursor arraw_icon' />;
  }

  function renderLeftArrow() {
    return <FiChevronLeft size={20} className='cursor arraw_icon' />;
  }

  if (getLanguage() === 'en') {
    return (
      <div
        className='center arraw_card_container cursor'
        style={{
          left: getLanguage() === 'ar' ? '0px' : null,
          right: getLanguage() === 'en' ? '0px' : null,
          zIndex: '1000000000',
        }}
        onClick={(e) => handleClick(e)}
      >
        {rows_collabse[index] === true ? renderLeftArrow() : renderRightArrow()}
      </div>
    );
  } else {
    return (
      <div
        className='center arraw_card_container cursor'
        style={{
          left: getLanguage() === 'ar' ? '0px' : null,
          right: getLanguage() === 'en' ? '0px' : null,
        }}
        onClick={(e) => handleClick(e)}
      >
        {rows_collabse[index] === true ? renderRightArrow() : renderLeftArrow()}
      </div>
    );
  }
};

export const ShareIcon = () => {
  return (
    <div className='center cursor'>
      <img src={subtract} className='cursor' alt='subtract' />
      {/* <p className="weightFont link">{local.ShareLink}</p> */}
    </div>
  );
};

export const preparePermissions = (claimTypesOptions) => {
  let newClaims = [];
  if (claimTypesOptions && claimTypesOptions.length > 0) {
    claimTypesOptions.map((claim) => {
      var filterdItem = claimTypesOptions.filter(function (s) {
        return s.value.split('_')[0] === claim.value.split('_')[0];
      });

      newClaims.push(filterdItem);
    });
    let uniqueClaims = Array.from(new Set(newClaims.map(JSON.stringify))).map(
      JSON.parse
    );

    let keyValue = [];

    uniqueClaims.map((unique, index) => {
      if (unique[0].value.split('_')[0] === 'RoleBusiness') {
        let UserRole = unique.filter((u) =>
          u.value.split('_')[1].includes('UserRole')
        );

        let UserClaim = unique.filter(
          (u) =>
            u.value.split('_')[1].includes('UserClaims') ||
            u.value.split('_')[1].includes('UserClaim')
        );

        let RoleClaim = unique.filter(
          (u) =>
            u.value.split('_')[1].includes('RoleClaims') ||
            u.value.split('_')[1].includes('RoleClaim')
        );

        let Roles = unique.filter(
          (u) =>
            u.value.split('_')[1].includes('GetRole') ||
            u.value.split('_')[1].includes('GetRoles') ||
            u.value.split('_')[1].includes('Delete') ||
            u.value.split('_')[1].includes('Create') ||
            u.value.split('_')[1].includes('UpdateRole')
        );
        uniqueClaims.push(Roles, UserRole, UserClaim, RoleClaim);

        uniqueClaims.splice(index, 1);
      }
    });
    uniqueClaims = uniqueClaims.filter((f) => f.length > 0);

    uniqueClaims.map((unique) => {
      let key = '';
      if (unique[0].value.split('_')[0] === 'RoleBusiness') {
        if (unique[0].value.split('_')[1].includes('UserRole')) {
          key = 'UserRole';
        } else if (
          unique[0].value.split('_')[1].includes('UserClaims') ||
          unique[0].value.split('_')[1].includes('UserClaim')
        ) {
          key = 'UserClaims';
        } else if (
          unique[0].value.split('_')[1].includes('RoleClaims') ||
          unique[0].value.split('_')[1].includes('RoleClaim')
        ) {
          key = 'RoleClaims';
        } else {
          key = 'Roles';
        }
      } else {
        key = unique[0].value.split('_')[0];
      }

      keyValue.push({ key, value: unique });
    });

    keyValue = keyValue.sort(function (a, b) {
      return b.value.length - a.value.length;
    });
    return keyValue;
  }
};

export const renderClaims = (
  claimTypesOptions,
  permissions,
  form,
  general,
  update_action,
  delete_action,
  merge_action
) => {
  let claims = preparePermissions(claimTypesOptions);

  return preparePermissions(claimTypesOptions).map((item, index) => {
    // if (local[item.key] === undefined) {
    // }

    let values = sortItem(item.value);

    return (
      <Row className='alignItem mt-3' key={index}>
        <Col
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={3}
          className={columnAlignLeft() + ' mx-auto'}
        >
          <p className='bold'>
            {item.key === 'Branches'
              ? local.Branches + '/' + local.Distributors
              : local[item.key]}
          </p>
        </Col>

        <Col xl={9} lg={9} md={9} sm={9} xs={9}>
          <ul className='nav'>
            {values.map((sub, i) => {
              return (
                <li
                  className='mt-3'
                  key={i}
                  style={{
                    listStyle: 'none',
                    display: 'inline-block',
                    width: `20%`,
                  }}
                >
                  <div>
                    <div>
                      <p className='mt-3'> {sub.label} </p>
                    </div>
                    <div className='round2 center'>
                      <input
                        type='checkbox'
                        id={item.key + sub.label + i}
                        onChange={(e) =>
                          e.target.checked === false
                            ? permissions.length === 1
                              ? updatePermission(
                                  general,
                                  update_action,
                                  delete_action,
                                  form.claimValue1
                                )
                              : deletePermission(
                                  general,
                                  permissions,
                                  sub.value,
                                  delete_action
                                )
                            : insertPermission(
                                sub.value,
                                general,
                                update_action,
                                merge_action
                              )
                        }
                      />
                      <label
                        htmlFor={item.key + sub.label + i}
                        style={{
                          left: 'unset',
                          background: 'none',
                          border: 'none',
                          width: '40px',
                          height: '40px',
                        }}
                      >
                        {permissions.find((cl) => cl === sub.value) ? (
                          <div>
                            <img src={approve} className='imgFull' />
                          </div>
                        ) : (
                          <div>
                            <img src={reject} className='imgFull' />
                          </div>
                        )}
                      </label>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    );
  });
};

const updatePermission = (
  general,
  update_action,
  delete_action,
  claimValue1
) => {
  general(
    [
      {
        prop: 'data.permissions',
        value: [],
      },
    ],
    update_action
  );
  if (claimValue1 !== undefined) {
    general(
      [
        {
          prop: 'data.form.claimValue1',
        },
      ],
      delete_action
    );
  }
};

export const sortItem = (item) => {
  let views = ['View', 'view', 'عرض'];
  let adds = ['add', 'Add', 'اضافة'];
  let updates = ['edit', 'Edit', 'update', 'Update', 'تعديل'];
  let deletes = ['remove', 'Remove', 'delete', 'Delete', 'حذف', 'مسح'];
  let keys = [
    'View',
    'view',
    'عرض',
    'add',
    'Add',
    'اضافة',
    'edit',
    'Edit',
    'update',
    'Update',
    'تعديل',
    'remove',
    'Remove',
    'delete',
    'Delete',
    'حذف',
    'مسح',
  ];
  if (item) {
    if (item.length > 0) {
      let sortedItems = [];
      let viewPermission = item.find((entry) =>
        views.find((e) => e === entry.label)
      );

      let addPermission = item.find((entry) =>
        adds.find((e) => e === entry.label)
      );
      let updatePermission = item.find((entry) =>
        updates.find((e) => e === entry.label)
      );
      let deletePermission = item.find((entry) =>
        deletes.find((e) => e === entry.label)
      );
      let restOfItems = item.filter(
        (entry) => !keys.find((e) => e === entry.label)
      );
      if (viewPermission !== undefined) {
        sortedItems.push(viewPermission);
      }
      if (addPermission !== undefined) {
        sortedItems.push(addPermission);
      }
      if (deletePermission !== undefined) {
        sortedItems.push(deletePermission);
      }
      if (updatePermission !== undefined) {
        sortedItems.push(updatePermission);
      }

      if (restOfItems.length > 0) {
        sortedItems = sortedItems.concat(restOfItems);
      }

      return sortedItems;
    }
  }
};

const deletePermission = (general, permissions, value, delete_action) => {
  if (value === 'GeoEventType_Get') {
    general(
      [
        {
          prop: 'data.form.claimValue1',
        },
      ],
      delete_action
    );
  }
  general(
    [
      {
        prop: 'data.permissions.' + permissions.indexOf(value),
      },
    ],
    delete_action
  );
};

const insertPermission = (value, general, update_action, merge_action) => {
  if (value === 'GeoEventType_Get') {
    general(
      [
        {
          prop: 'data.form.claimValue1',
          value: [],
        },
      ],
      update_action
    );
  }
  general(
    [
      {
        prop: 'data.permissions',
        value: value,
      },
    ],
    merge_action
  );
};

//webview functions

export const checkConnection = (history) => {
  var ifConnected = window.navigator.onLine;
  if (ifConnected) {
  } else {
    history.push('/no_internet');
  }
};

export const getZoom = (layer) => {
  if (layer === 'country') {
    return 6;
  }
  if (layer === 'locality') {
    return 8;
  }
  if (layer === 'venue') {
    return 14;
  }
  if (layer === 'region') {
    return 10;
  }
  if (layer === 'disputed') {
    return 4;
  }
};

export const convert_to_well_known_text = (lat, lng) => {
  let point = 'POINT(';
  point = point + lng + ' ' + lat + ')';

  return point;
};

export const convert_to_lat_lng_point = (point_text, type) => {
  if (type === 'lng') {
    return +point_text.split(' ')[1].split('(')[1];
  } else {
    return +point_text.split(' ')[2].split(')')[0];
  }
};

export const getTheme = () => {
  let theme = localStorage.getItem(DEFAULT_THEME);
  if (theme !== null) {
    return theme;
  } else {
    return null;
  }
};

export const getDistance = (p1, p2) => {
  var rad = function (x) {
    return (x * Math.PI) / 180;
  };

  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(p2.lat - p1.lat);
  var dLong = rad(p2.lng - p1.lng);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;

  let km = Math.round(d * 0.001);

  return km;
};

export const saveRecentSearch = (lat, lng, label) => {
  let recend_search_arrays = JSON.parse(localStorage.getItem(RECENT_SEARCH));

  if (recend_search_arrays) {
    if (recend_search_arrays.length === 3) {
      recend_search_arrays.shift();
    }

    if (
      recend_search_arrays.find(
        (r) =>
          r.label !== label &&
          JSON.stringify(r.value) !== JSON.stringify([lng, lat])
      )
    ) {
      recend_search_arrays.push({ label: label, value: [lng, lat] });
      localStorage.setItem(RECENT_SEARCH, JSON.stringify(recend_search_arrays));
    }
  } else {
    let newItems = [];

    newItems.push({ label: label, value: [lng, lat] });
    localStorage.setItem(RECENT_SEARCH, JSON.stringify(newItems));
  }
};


export const renderTabs = (
  image,
  title,
  value,
  setValue,
  general,
  item,
  myPosition,
  ipAddress,
  action
) => {
  return (
    <div
      style={{ marginInline: value !== title ? '3%' : null }}
      className={
        value !== title ? 'search_card_item cursor' : 'search_card_item_active'
      }
      onClick={(e) =>
        handleTab(
          e,
          title,
          setValue,
          general,
          item,
          myPosition,
          ipAddress,
          action
        )
      }
    >
      <div className='search_card_div'>
        <div
          className='center'
          style={{
            height: '50%',
          }}
        >
          <img
            src={image}
            alt='explore_icon'
            className='explore_icon'
            width={title === local.UserName ? '30' : '20'}
            style={{
              height: title === local.UserName ? '30px' : '20',
              borderRadius: title === local.UserName ? '100%' : null,
            }}
          />
        </div>

        <p className='search_card_item_text'>
          {title === local.UserName ? renderUserName() : title}
        </p>
      </div>
    </div>
  );
};

const handleTab = (
  e,
  title,
  setValue,
  general,
  item,
  myPosition,
  ipAddress,
  action
) => {
  e.preventDefault();
  setValue(title);
  if (title === local.UserName) {
    general(
      [{ prop: 'search_data.toViewSearch', value: false }],
      UPDATE_SEARCH_MENU_PROPS
    );

    general(
      [{ prop: 'userAccount.isOpen', value: true }],
      UPDATE_USER_ACCOUNT_PROPS
    );
  }
  if (title === local.Direction) {
    handleDirectionTab(general, item, myPosition, ipAddress);
  }
  if (title === local.Explore) {
    general(
      [{ prop: 'search_data.isSlideDown', value: true }],
      UPDATE_SEARCH_MENU_PROPS
    );
  }

  if (action !== null) {
    action();
  }
};

export const handleDirectionTab = (general, item, myPosition, ipAddress) => {
  if (item.lat === '') {
    let lat = ipAddress.lat;
    let lng = ipAddress.lng;

    if (myPosition.allowLocation === true) {
      lat = myPosition.lat;
      lng = myPosition.lng;
    }

    general(
      [
        { prop: 'startLocation.lat', value: lat },
        { prop: 'startLocation.lng', value: lng },
        { prop: 'startLocation.title', value: local.MyLocation },
      ],
      UPDATE_DIRECTION_MENU_PROPS
    );
  }
  general(
    [{ prop: 'userAccount.isOpen', value: false }],
    UPDATE_USER_ACCOUNT_PROPS
  );
  general(
    [{ prop: 'search_data.toViewSearch', value: false }],
    UPDATE_SEARCH_MENU_PROPS
  );
  general(
    [{ prop: 'direction_data.isOpen', value: true }],
    UPDATE_DIRECTION_MENU_PROPS
  );
};




export const getLatLng = (point) => {
  if (point) {
    let newPoint = point.slice(7, -1).split(' ');
    return {
      lat: +newPoint[1],
      lng: +newPoint[0],
    };
  }
};

export const validateRoutingPoint = (startLocation, endLocation) => {
  if (
    startLocation.lat !== '' &&
    startLocation.lng !== '' &&
    endLocation.lat !== '' &&
    endLocation.lng !== ''
  ) {
    if (
      startLocation.lat === endLocation.lat &&
      startLocation.lng === endLocation.lng
    ) {
      showConfirmMessage(local.SamePointValidation, 'error');
    } else {
      return true;
    }
  }
};

export const getWorkingHours = (WorkingHours) => {
  if (WorkingHours) {
    if (WorkingHours.length > 0) {
      let filterdHours = WorkingHours.filter((w) => w.from || w.to);
      if (filterdHours.length > 0) {
        return filterdHours.map((wk) => wk.day).toString();
      } else {
        return '';
      }
    }
  }
};

export const filterWorkingHours = (WorkingHours) => {
  if (WorkingHours) {
    Object.entries(WorkingHours).map((work) => {
      if (!work[1].from) {
        delete WorkingHours[work[0]].from;
      }
      if (!work[1].to) {
        delete WorkingHours[work[0]].to;
      }
    });

    return WorkingHours;
  }
};

export const removeDuplicateElement = (items) => {
  if (items) {
    if (items.length > 0) {
      let uniqueTotals = items.filter(
        (v, i, a) =>
          a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
      );
      return uniqueTotals;
    }
  }
};

export const renderName = (name) => {
  if (name && name !== {}) {
    if (name[local.lang]) {
      return name[local.lang];
    } else if (name['en'] || name['ar']) {
      if (name['en']) {
        return name['en'];
      } else if (name['ar']) {
        return name['ar'];
      } else {
        return '';
      }
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const renderImage = (image) => {
  if (image !== undefined) {
    if (image) {
      if (checkHasExtension(image) === true) {
        return IMAGE_BASE_PATH + image;
      } else {
        return avater2;
      }
    } else {
      return avater2;
    }
  } else {
    return avater2;
  }
};
export const renderPhotos = (images, prop, general, action) => {
  if (images) {
    let newImages = [];
    images.forEach((img) => {
      if (img instanceof File) {
        newImages.push(URL.createObjectURL(img));
      } else {
        newImages.push(img);
      }
    });

    return newImages.map((image, i) => {
      return (
        <div
          className='img_conteiner_palce'
          style={{ marginInlineStart: '10px' }}
        >
          <FiX
            className='cursor'
            color='red'
            style={{ position: 'absolute', zIndex: '1000' }}
            onClick={(e) => {
              e.preventDefault();
              general([{ prop: prop + i }], action);
            }}
          />
          <img
            style={{ borderRadius: '15px' }}
            src={image}
            alt=''
            className='imgFull cursor'
            onClick={(e) => {
              e.preventDefault();
              general(
                [
                  {
                    prop: 'lightbox',
                    value: {
                      images: newImages,
                      type: 'multi',
                      index: i,
                      isOpen: true,
                    },
                  },
                ],
                UPDATE_GENERAL_PROPS
              );
            }}
          />
        </div>
      );
    });
  }
};

export const renderErrorMessage = (value, message) => {
  if (!value && message) {
    return <span className='error_message'>{message}</span>;
  }
};

export const manipulateDate = (dateTime) => {
  let date = new Date(dateTime);
  date.setDate(date.getDate() + 1);

  return date;
};

export const getDateValue = (dateTime) => {
  if (dateTime) {
    let date = new Date(dateTime);
    date.setDate(date.getDate() - 1);

    return date;
  }
};

export const isMyElement = (id) => {
  let user = getUser();
  if (user?.data) {
    let currentID = user?.data.id;

    if (currentID === id) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

