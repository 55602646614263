import React, { useEffect } from 'react';
import { Col, Row, Card, Spinner } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import { CLEAR_BRANCHES_STATE } from '../../Redux/Actions/types';
// import GeneralMap from "../../General/GeneralMap";

// import { handleSave, onMapClick, renderPhoto } from "./BranchFunctions";
import { isRtl, selectFormStyle } from '../../General/GeneralStyle';
import { getUser } from '../../utils/StorageFunctions';
import { getDefaultValue, promiseOptions } from '../../General/SelectFunctions';
import Gallery from '../../General/Gallery';
import { FormInput } from '../../General/form-input/input';
import { InputDomainSelect } from '../../General/form-input/inputSelect';
import { useFormContext } from 'react-hook-form';
import { InputMapSelect } from '../../General/form-input/inputMapSelect';
import { IMAGE_BASE_PATH } from '../../General/StaticKeys';

const CreateBranchesGroup = ({
  history,
  form,
  isLoadingCreate,
  general,
  location,
  addBranch,
  venuesOptions,
  venueId,
  getVenueForDropDown,
  fetchCompany,
  branches,
  lightbox,
  ...props
}) => {
  useEffect(() => {
    if (getUser() === null) {
      history.push('/login');
    } else {
      getVenueForDropDown(1);
    }
    general([], CLEAR_BRANCHES_STATE);
  }, []);
  const {
    register,
    formState: { errors },
    setError,
    watch,
  } = useFormContext();
  const watchVenue = watch('venueId', false);
  useEffect(() => {
    if (watchVenue) fetchCompany(watchVenue.value);
  }, [watchVenue]);
  useEffect(() => {
    console.log(errors);
  }, [errors]);
  useEffect(() => {
    console.log(
      branches
        ? branches.map((e) => {
            return {
              id: e.id,
              name: e.name.ar,
              Lat: e.locationText.split(' ')[2].split(')')[0],
              Lng: e.locationText.split(' ')[1].split('(')[1],
            };
          })
        : []
    );
  }, [branches]);
  return (
    <div >
      <Gallery lightbox={lightbox} general={general} />

      <Row >
        <Col
          xl={8}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className='mx-auto text-center'
        >
          <h3 className='page_create_title'>{local.AddBranchGroup}</h3>
          <Card className='create_card'>
            <div>
              <Row>
                <Col
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className='mt-3 mx-auto'
                >
                  <FormInput name='name' label='اسم المجموعه'></FormInput>
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className='mt-3 mx-auto'
                >
                  <InputDomainSelect
                    name={'venueId'}
                    label={'الشركه'}
                    key={venueId}
                    placeholder={local.Venues}
                    value={getDefaultValue(venueId, venuesOptions)}
                    defaultOptions={venuesOptions}
                    onInputChange={(value) =>
                      value !== '' ? getVenueForDropDown(null, value) : null
                    }
                    cacheOptions
                    isSearchable={true}
                    loadOptions={() => promiseOptions(venuesOptions)}
                    isClearable={true}
                    isRtl={isRtl()}
                    styles={selectFormStyle}
                  />
                </Col>
              </Row>

              <Row>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className='mt-3 mx-auto'
                >
                  <Card
                    className='mapCard'
                    style={{
                      height: '490px',
                      width: '100%',
                    }}
                  >
                    <InputMapSelect
                      name={'branchIds'}
                      dataList={
                        branches
                          ? branches.map((e) => {
                              return {
                                imgUrl: IMAGE_BASE_PATH + e.logo,
                                id: '' + e.id,
                                name: e.name.ar,
                                lat: e.locationText.split(' ')[2].split(')')[0],
                                lng: e.locationText.split(' ')[1].split('(')[1],
                              };
                            })
                          : []
                      }
                    />
                    {/* <GeneralMap
                            location={location}
                            onMapClick={onMapClick}
                            general={general}
                            point={null}
                            /> */}
                  </Card>
                </Col>
              </Row>

              <Row className='mt-5'>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className='mt-5 mx-auto'
                >
                  <button
                    className='save_btn'
                    // onClick={(e) =>
                    //   handleSave(e, addBranch, form, venueId)
                    // }
                    type='submit'
                  >
                    {isLoadingCreate === true ? <Spinner /> : local.Save}
                  </button>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const GeneralMaptateToProps = ({ BranchesR, GeneralR, VenuesR }) => {
  return {
    form: BranchesR.data.form,
    venueId: BranchesR.data.venueId,
    isLoadingCreate: BranchesR.isLoadingCreate,
    location: GeneralR.location,
    venuesOptions: VenuesR.venuesOptions,
    lightbox: GeneralR.lightbox,
    branches: GeneralR.branches,
  };
};
export default connect(GeneralMaptateToProps, actions)(CreateBranchesGroup);
