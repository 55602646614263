import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Button, Col, Input, Row } from "reactstrap";
import { local } from "../../lang/local";
import { MERGE_TICKETS_PROPS } from "../../Redux/Actions/types";
import Icon_video from "../../assets/img/Icon_video.svg";
import {
  checkIsLink,
  showConfirmMessage,
} from "../../General/GeneralFunctions";

const useStyles = makeStyles((theme) => ({
  typography: {
    width: "400px",
  },
}));

export default function VideoLink({ general }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [value_line, setVideoLink] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (value) => {
    if (checkIsLink(value) === true) {
      setVideoLink(value);
    } else {
      showConfirmMessage(local.InvalidLink, "error");
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="video_link_container">
      <a
        className="cursor fullWidthHeight"
        onClick={handleClick}
        style={{ background: "red !important" }}
      >
        <div className="fullWidthHeight ticket_tab_option">
          <img src={Icon_video} alt="" />
        </div>
      </a>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>
          <div
            style={{
              direction: "ltr",
            }}
            className="fullWidthHeight mt-5 mb-5"
          >
            <Row className="mt-3 ml-2 mr-2">
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="div_location">
                  <Input
                    className="removed_input_style"
                    onChange={(e) => handleInputChange(e.target.value)}
                    value={value_line}
                    placeholder={local.VideoLink}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3 ml-2 mr-2">
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
                <Button
                  className="btn_save"
                  onClick={(e) => {
                    e.preventDefault();
                    general(
                      [
                        {
                          prop: "data_message.form.Attachments",
                          value: value_line,
                        },
                      ],
                      MERGE_TICKETS_PROPS
                    );
                    setVideoLink("");
                    handleClose();
                  }}
                >
                  {local.Save}
                </Button>
              </Col>
            </Row>
          </div>
        </Typography>
      </Popover>
    </div>
  );
}
