import React, { useContext } from 'react'
import { Select } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import strik from "../../assets/img/strik.png";
import AsyncSelect from "react-select/async";

import './style.css'
import { MapSelector } from 'map_select';
let { Option } = Select

export const InputMapSelect = ({ dataList, label, name, DomainName, placeholder, isGray, ...props }) => {
    const { formState: { errors } } = useFormContext();

    return (
        <>
            <div className='container-form-input'>
                <span className="error-message">{errors[name]?.message}</span>
                <Controller
                    name={name}
                    rules={{
                        validate: (e) => {
                            if (!e || e.length === 0) {
                                return "اختر الفروع"
                            }
                        }
                    }}
                    render={({ field }) => {
                        return (
                            <MapSelector
                                dataList={dataList}
                                width="100%"
                                height="500px"
                                {...props}
                                {...field}
                                placeholder={label}
                            />

                        )
                    }
                    }
                />
            </div>
        </>
    )
}
