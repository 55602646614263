import React from "react";
import { Col, Row, Input, Label } from "reactstrap";
import { local } from "../../lang/local";
import avater2 from "../../assets/img/avater2.svg";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";
import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
import { columnAlignLeft } from "../../General/GeneralStyle";
import { getDefaultValue } from "../../General/SelectFunctions";

const RoleClaimProfile = ({ data, general, rolesOptions }) => {
  const handleImageClick = (e) => {
    e.preventDefault();
    if (data.image) {
      general(
        [
          {
            prop: "lightbox",
            value: {
              image: IMAGE_BASE_PATH + data.image,
              isOpen: true,
              type: "single",
            },
          },
        ],
        UPDATE_GENERAL_PROPS
      );
    }
  };
  return data ? (
    <div className="mt-3 mb-3">
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mx-auto mt-3">
          <div className="img_profile_container mx-auto">
            <img
              src={data.image ? IMAGE_BASE_PATH + data.image : avater2}
              className="fullWidthHeight"
              style={{ borderRadius: "100%" }}
              onClick={handleImageClick}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Id}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.id}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Name}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.claimValue}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Role}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={getDefaultValue(data.roleId, rolesOptions)?.label}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.claimType}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={local[data.claimType]}
              disabled={true}
            />
          </div>
        </Col>

        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Description}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.description}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
    </div>
  ) : null;
};

export default RoleClaimProfile;
