import axios from "axios";
import {
  convert_to_lat_lng_point,
  renderName,
} from "../../General/GeneralFunctions";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";
import { getLanguage } from "../../lang/local";
import { SendRequest } from "./api";
import { getSocketResult } from "./MapsActions";

import {
  UPDATE_GENERAL_PROPS,
  UPDATE_LOCATION_MENU_PROPS,
  UPDATE_LOGIN_PROPS,
  UPDATE_REGISTER_PROPS,
  UPDATE_SEARCH_MENU_PROPS,
  UPDATE_USERS_PROPS,
} from "./types";
export const general = (payload, key) => {
  return { type: key, payload };
};

export const getLocationWithIp = () => (dispatch) => {
  axios
    .get(
      "https://api.ipgeolocation.io/ipgeo?apiKey=89d865f29f0c4203a966f4e09ef63d78"
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_LOGIN_PROPS,
          payload: [
            {
              prop: "loginObj.form.lat",
              value: +res.data.latitude,
            },
            {
              prop: "loginObj.form.lng",
              value: +res.data.longitude,
            },
          ],
        });
        dispatch({
          type: UPDATE_GENERAL_PROPS,
          payload: [
            {
              prop: "ipAddress.lat",
              value: +res.data.latitude,
            },
            {
              prop: "ipAddress.lng",
              value: +res.data.longitude,
            },
          ],
        });
        dispatch({
          type: UPDATE_REGISTER_PROPS,
          payload: [
            { prop: "registerObj.form.country", value: res.data.country_code2 },
          ],
        });

        dispatch({
          type: UPDATE_GENERAL_PROPS,
          payload: [
            {
              prop: "location.nearest_location.lat",
              value: +res.data.latitude,
            },
            {
              prop: "location.nearest_location.lng",
              value: +res.data.longitude,
            },
            {
              prop: "location.country",
              value: res.data.country_code2,
            },
          ],
        });
        dispatch({
          type: UPDATE_LOGIN_PROPS,
          payload: [
            {
              prop: "loginObj.form.lat",
              value: +res.data.latitude,
            },
            {
              prop: "loginObj.form.lng",
              value: +res.data.longitude,
            },
          ],
        });
        dispatch({
          type: UPDATE_REGISTER_PROPS,
          payload: [
            { prop: "registerObj.form.country", value: res.data.country_code2 },
          ],
        });
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const fetchCompany =
  (entry, isIndvidual, lat, lng, name) => (dispatch) => {
    let id = isIndvidual !== undefined ? entry.venueId : entry;
    SendRequest()
      .get(`/Venues/${id}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.branches) {
            let venue = {
              name: res.data.data.name,
              id: res.data.data.id,
              logo: IMAGE_BASE_PATH + res.data.data.logo,
            };

            if (isIndvidual !== undefined) {
              dispatch({
                type: UPDATE_LOCATION_MENU_PROPS,
                payload: [
                  {
                    prop: "location_data.image",
                    value: venue.logo,
                  },
                  {
                    prop: "location_data.placeType",
                    value: entry.type,
                  },
                  {
                    prop: "location_data.address",
                    value: name,
                  },
                  {
                    prop: "location_data.icon_type",
                    value: "search",
                  },
                  {
                    prop: "location_data.lat",
                    value: lat,
                  },
                  {
                    prop: "location_data.lng",
                    value: lng,
                  },

                  {
                    prop: "location_data.item_id",
                    value: entry.id,
                  },
                  {
                    prop: "location_data.toViewSideInfo",
                    value: true,
                  },

                  {
                    prop: "location_data.placeType",
                    value: entry.type,
                  },
                ],
              });
              dispatch({
                type: UPDATE_SEARCH_MENU_PROPS,
                payload: [
                  {
                    prop: "search_data.toViewSearch",
                    value: false,
                  },
                ],
              });
            } else {
              dispatch({
                type: UPDATE_GENERAL_PROPS,
                payload: [
                  {
                    prop: "links.venue",
                    value: venue,
                  },
                  {
                    prop: "branches",
                    value: res.data.data.branches,
                  },
                ],
              });
            }
          }
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally();
  };

export const fetchPlace = (id) => (dispatch) => {
  SendRequest()
    .get(`/Contribution/${id}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data) {
          let entry = res.data.data;

          let lat = convert_to_lat_lng_point(entry.geometryText, "lat");
          let lng = convert_to_lat_lng_point(entry.geometryText, "lng");
          let name = renderName(entry.name);
          dispatch(updateItem(entry, lat, lng, name));

          dispatch({
            type: UPDATE_GENERAL_PROPS,
            payload: [
              {
                prop: "links.place",
                value: entry,
              },
            ],
          });
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
export const getBranchOrDistributor =
  (id, prop, currentLat, currentLng) => (dispatch) => {
    SendRequest()
      .post(`/Branch/Get`, [
        {
          op: 0,
          fieldName: "Id",
          value: id,
        },
      ])
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data) {
            if (res.data.data.items) {
              let entry = res.data.data.items[0];
              let lat = convert_to_lat_lng_point(entry.locationText, "lat");
              let lng = convert_to_lat_lng_point(entry.locationText, "lng");
              let name = renderName(entry.name);
              dispatch(fetchCompany(entry, true, lat, lng, name));
              dispatch(getSocketResult(null, lat, lng, "location_data.gis_id"));
              dispatch(
                getSocketResult(
                  null,
                  {
                    start: currentLat,
                    end: lat,
                  },
                  {
                    start: currentLng,
                    end: lng,
                  },
                  "distance"
                )
              );
              dispatch({
                type: UPDATE_GENERAL_PROPS,
                payload: [
                  {
                    prop: `links.${prop}`,
                    value: entry,
                  },
                ],
              });
            }
          }
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally();
  };

export const fetchComplaint = (id) => (dispatch) => {
  SendRequest()
    .post("/GeoEvent/Get", [
      {
        op: 0,
        fieldName: "Id",
        value: id,
      },
    ])
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data.items.length > 0) {
          let entry = res.data.data.items[0];
          let lat = convert_to_lat_lng_point(entry.locationText, "lat");
          let lng = convert_to_lat_lng_point(entry.locationText, "lng");
          dispatch(updateItem(entry, lat, lng, entry.note));

          dispatch({
            type: UPDATE_GENERAL_PROPS,
            payload: [
              {
                prop: "links.complaint",
                value: entry,
              },
            ],
          });
        }
      }
    })
    .catch((e) => {})
    .finally();
};
export const updateItem = (entry, lat, lng, name) => (dispatch) => {
  dispatch({
    type: UPDATE_LOCATION_MENU_PROPS,
    payload: [
      {
        prop: "location_data.address",
        value: name,
      },
      {
        prop: "location_data.icon_type",
        value: "search",
      },
      {
        prop: "location_data.lat",
        value: lat,
      },
      {
        prop: "location_data.lng",
        value: lng,
      },

      {
        prop: "location_data.item_id",
        value: entry.id,
      },
      {
        prop: "location_data.toViewSideInfo",
        value: true,
      },

      {
        prop: "location_data.placeType",
        value: entry.type,
      },
    ],
  });
  dispatch({
    type: UPDATE_SEARCH_MENU_PROPS,
    payload: [
      {
        prop: "search_data.toViewSearch",
        value: false,
      },
    ],
  });
};

export const fetchCountry = (prop, latlngs) => (dispatch) => {
  let langs = getLanguage();
  if (latlngs.length > 0) {
    return latlngs.map((item, index) => {
      if (item) {
        return SendRequest()
          .get(
            `https://apis.mapy.world:5002/api/GeoServices/reverse?size=1&lng=${item.lng}&lat=${item.lat}&lang=${langs}`
          )
          .then((res) => {
            if (res.status === 200) {
              if (res.data.data.features) {
                let properties = res.data.data.features[0].properties;
                // country_a
                dispatch({
                  type: UPDATE_USERS_PROPS,
                  payload: [
                    {
                      prop: prop + index + ".country",
                      value: properties.country,
                    },
                  ],
                });
              }
            }
          })
          .catch((e) => {})
          .finally();
      }
    });
  }
};
