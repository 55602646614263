import { centerMap } from "./MapFunctions";
import { local } from "../../../lang/local";
import { UPDATE_LOCATION_MENU_PROPS } from "../../../Redux/Actions/types";
import {
  getLatLng,
  validateRoutingPoint,
  renderName,
} from "../../../General/GeneralFunctions";
export const updateSearchDirectionType = (
  prevType,
  currentType,
  direction_type
) => {
  if (prevType !== currentType) {
    direction_type = currentType;
  }
};

export const handleSearchResult = (
  prevResult,
  currentResult,
  searchType,
  map,
  history,
  drawSearchMarkers,
  removeMarkers
) => {
  if (prevResult !== currentResult) {
    if (searchType === "search") {
      drawSearchMarkers(currentResult);
      centerMap(currentResult, map, history);
    }
  }
  if (prevResult !== currentResult && currentResult.length === 0) {
    removeMarkers();
  }
};

export const handleBranchesBulkLink = (
  prevBranches,
  currentBranches,
  map,
  history,
  drawSearchMarkers,
  removeMarkers
) => {
  // if (prevBranches !== currentBranches) {
  let newBranches = [];
  currentBranches.map((branch) => {
    if (branch.locationText) {
      let lat = +branch.locationText.split(" ")[2].split(")")[0];
      let lng = +branch.locationText.split(" ")[1].split("(")[1];
      newBranches.push({
        label: branch.name[local.subLang],
        value: [lng, lat],
        type: branch.type,
      });
    }
  });
  drawSearchMarkers(newBranches, "custom");

  // centerMap(newBranches, map, history);
  // }
  if (prevBranches !== currentBranches && currentBranches.length === 0) {
    removeMarkers();
  }
};

export const handleLocationMenu = (
  prevLocation,
  currentLocation,
  zoom,
  location_marker,
  removeMarkers,
  onUpdateMap,
  isOpenAddContribution
) => {
  let lat = currentLocation.lat;
  let lng = currentLocation.lng;
  let toViewSideInfo = currentLocation.toViewSideInfo;
  if (
    prevLocation.toViewSideInfo !== toViewSideInfo &&
    toViewSideInfo === false &&
    isOpenAddContribution === false
  ) {
    location_marker.setPosition(null);
  }

  if (prevLocation.lat !== lat && currentLocation.icon_type === "search") {
    location_marker.setPosition({
      lat: lat,
      lng: lng,
    });

    onUpdateMap(lat, lng, zoom, false, true, "", true);

    // onUpdateMap(lat, lng, zoom, false, true);
  }
  if (
    prevLocation.toViewSideInfo !== toViewSideInfo &&
    toViewSideInfo === true
  ) {
    removeMarkers();
  }
};

export const handleAddContribuation = (
  prevForm,
  currentForm,
  prevLocation,
  curentLocation,
  location_marker,
  general,
  removeMarkers
) => {
  let toViewSideInfo = curentLocation.toViewSideInfo;

  if (
    prevForm.Geometry !== currentForm.Geometry &&
    currentForm.Geometry !== "" &&
    getLatLng(currentForm.Geometry).lat !== curentLocation.lat
  ) {
    let lat = getLatLng(currentForm.Geometry).lat;
    let lng = getLatLng(currentForm.Geometry).lng;

    general(
      [
        { prop: "location_data.lat", value: lat },
        { prop: "location_data.lng", value: lng },
        {
          prop: "location_data.address",
          value: renderName(currentForm.name),
        },
      ],
      UPDATE_LOCATION_MENU_PROPS
    );

    location_marker.setPosition({
      lat: lat,
      lng: lng,
    });
  }
  if (
    prevLocation.toViewSideInfo !== toViewSideInfo &&
    toViewSideInfo === true
  ) {
    removeMarkers();
  }
};

export const handleRouting = (
  prevStLocation,
  stLocation,
  map,
  history,
  prevEnLocation,
  enLocation,
  prevDirectionData,
  currentDirectionData,
  removeLine,
  drawPolylines,
  source_marker,
  destination_marker,
  start_dergable_Marker,
  end_dergable_Marker,
  getSocketResult,
  location_data,
  location_marker,
  onClickPolyLine
) => {
  let prevStResult = prevStLocation.searchResults;
  let currentStResult = stLocation.searchResults;

  let prevEnResult = prevEnLocation.searchResults;
  let currentEnResult = enLocation.searchResults;

  let prevRouting = prevDirectionData.routing;
  let cureentRouting = currentDirectionData.routing;

  if (prevStResult !== currentStResult) {
    if (stLocation.search_type === "search") {
      centerMap(currentStResult, map, history);
    }
  }

  if (prevEnResult !== currentEnResult) {
    if (enLocation.search_type === "search") {
      centerMap(currentEnResult, map, history);
    }
  }

  if (prevRouting !== cureentRouting) {
    if (cureentRouting) {
      if (cureentRouting.length > 0) {
        removeLine();
        drawPolylines();
        zoomToObject(cureentRouting, map);
      }
    }
  }

  if (
    prevDirectionData.isOpen !== currentDirectionData.isOpen &&
    currentDirectionData.isOpen === false
  ) {
    source_marker.setPosition(null);
    destination_marker.setPosition(null);
    start_dergable_Marker.setPosition(null);
    end_dergable_Marker.setPosition(null);
    removeLine();
  }

  if (
    prevStLocation.lat !== stLocation.lat ||
    prevStLocation.lng !== stLocation.lng ||
    prevEnLocation.lat !== enLocation.lat ||
    prevEnLocation.lng !== enLocation.lng
  ) {
    if (validateRoutingPoint(stLocation, enLocation) === true) {
      getSocketResult(
        null,
        {
          start: stLocation.lat,
          end: enLocation.lat,
        },
        {
          start: stLocation.lng,
          end: enLocation.lng,
        },
        "routing"
      );
    }
  }

  if (
    (prevStLocation.lat !== stLocation.lat ||
      prevStLocation.lng !== stLocation.lng) &&
    stLocation.lat !== "" &&
    stLocation.lng !== ""
  ) {
    source_marker.setPosition({
      lat: stLocation.lat,
      lng: stLocation.lng,
    });
  }

  if (
    (prevEnLocation.lat !== enLocation.lat ||
      prevEnLocation.lng !== enLocation.lng) &&
    enLocation.lat !== "" &&
    enLocation.lng !== "" &&
    location_data.toViewSideInfo === false
  ) {
    location_marker.setPosition(null);
    destination_marker.setPosition({
      lat: enLocation.lat,
      lng: enLocation.lng,
    });
  }

  if (
    prevStLocation.dragableMarker.lat !== stLocation.dragableMarker.lat ||
    prevStLocation.dragableMarker.lng !== stLocation.dragableMarker.lng
  ) {
    let lat = stLocation.dragableMarker.lat;
    let lng = stLocation.dragableMarker.lng;
    map.setCenter({ lat: lat, lng: lng });
  }

  if (prevDirectionData.active_class !== currentDirectionData.active_class) {
    onClickPolyLine(currentDirectionData.active_classx);
  }
};

function zoomToObject(obj, map) {
  var bounds = new window.google.maps.LatLngBounds();
  var points = obj[0].geometry.coordinates.map((p) => {
    return { lat: p[1], lng: p[0] };
  });

  for (var n = 0; n < points.length; n++) {
    bounds.extend(points[n]);
  }
  map.fitBounds(bounds);
}
