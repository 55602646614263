import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Card, Col, Row, Button } from "reactstrap";
import star_place from "../../../assets/img/star_place.png";
import heart_place from "../../../assets/img/heart_place.png";
import add_icon_btn from "../../../assets/img/add_icon_btn.png";
import close_icon from "../../../assets/img/close_icon.png";
import "../Places/place.css";
import { local } from "../../../lang/local";

import * as actions from "../../../Redux/Actions/index";
import {
  UPDATE_PLACES_PROPS,
  UPDATE_USER_ACCOUNT_PROPS,
} from "../../../Redux/Actions/types";
import {
  leftStyle,
  rightStyle,
  alignTextRight,
} from "../../../General/GeneralStyle";
import { connect } from "react-redux";
import AddToList from "./AddPlace";
import {
  convert_to_lat_lng_point,
  getDistance,
} from "../../../General/GeneralFunctions";

const useStyles = makeStyles((theme) => ({
  typography: {
    width: "400px",
  },
}));

function Places({
  general,
  places,
  myPosition,
  lists,
  add_place,
  location_data,
  createPlace,
  TagItem,
  ipAddress,
  addListType,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const RenderAddButton = () => {
    return (
      <Row className="mt-4 ml-1 mr-1">
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
          <Button className="add_btn center">
            <img src={add_icon_btn} alt="icon" />
            <p style={{ marginInlineStart: "10px" }}>{local.ADDTO}</p>
          </Button>
        </Col>
      </Row>
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onShowDetails = (e, title, image) => {
    e.preventDefault();
    let registerdPlaces;
    if (places.Registered) {
      if (places.Registered.length > 0) {
        registerdPlaces = places.Registered.filter((p) => p.type === title);

        registerdPlaces.map((item, index) => {
          let my_location_lat;
          let my_location_lng;
          let lat = convert_to_lat_lng_point(item.geometryText, "lat");
          let lng = convert_to_lat_lng_point(item.geometryText, "lng");

          if (myPosition.allowLocation === true) {
            my_location_lat = myPosition.lat;
            my_location_lng = myPosition.lng;
          } else {
            my_location_lat = ipAddress.lat;
            my_location_lng = ipAddress.lng;
          }
          let point1 = { lat: my_location_lat, lng: my_location_lng };
          let point2 = { lat: lat, lng: lng };
          item.distance = getDistance(point1, point2) + " Km";
        });
      }
    }

    general(
      [
        {
          prop: "lists.list_item",
          value: {
            isOpen: true,
            registered_items:
              registerdPlaces === undefined
                ? []
                : registerdPlaces === null
                ? []
                : registerdPlaces,

            title: title,
            icon: image,
          },
        },
      ],
      UPDATE_PLACES_PROPS
    );

    general(
      [
        {
          prop: "userAccount.isOpen",
          value: false,
        },
      ],
      UPDATE_USER_ACCOUNT_PROPS
    );
  };

  const renderList = (image, title, type) => {
    if (places.Registered) {
      if (places.Registered.length > 0) {
        let filterdPlaces = places.Registered.filter((p) => p.type === type);
        return (
          <Row className="mt-4 ml-1 mr-1">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card
                className="card_list cursor"
                onClick={(e) => onShowDetails(e, type, image)}
              >
                <div className="list_card_container flex alignItem fullWidthHeight">
                  <span className="list_card_img_container">
                    <img src={image} alt="" className="imgFull" />
                  </span>
                  <div
                    className="list_card_desc_container"
                    style={alignTextRight}
                  >
                    {title}
                    <p>{filterdPlaces.length + " " + local.Places}</p>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        );
      }
    }
  };

  return (
    <React.Fragment>
      <a className="cursor" onClick={handleClick}>
        <TagItem />
      </a>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>
          <div className="fullWidthHeight">
            <Row className="mt-1 ml-1 mr-1">
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <img
                  src={close_icon}
                  alt="close_icon cursor"
                  style={rightStyle}
                  onClick={handleClose}
                />
                <div style={leftStyle} className="mt-3">
                  <div className="center">
                    <p className="card_list_title">{local.SavedPlaces}</p>
                  </div>
                </div>
              </Col>
            </Row>
            {renderList(star_place, local.FavoriteList, "Favorite list")}
            {renderList(heart_place, local.WantToGo, "Want to go")}

            {location_data.gis_id ? (
              <AddToList
                general={general}
                lists={lists}
                add_place={add_place}
                location_data={location_data}
                createPlace={createPlace}
                TagItem={RenderAddButton}
                addListType={addListType}
              />
            ) : null}
          </div>
        </Typography>
      </Popover>
    </React.Fragment>
  );
}

const mapStateToProps = ({ PlaceR, GeneralR, LocationR }) => {
  return {
    places: PlaceR.places,
    myPosition: GeneralR.myPosition,
    ipAddress: GeneralR.ipAddress,
    lists: PlaceR.lists,
    add_place: PlaceR.add_place,
    location_data: LocationR.location_data,
    addListType: PlaceR.addListType,
  };
};
export default connect(mapStateToProps, actions)(Places);
