import React, { Component } from 'react';
import './support.css';
import { Row, Container } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import PlaceCard from './PlaceCard';
import ReviewCard from './ReviewCard';
import { getPermissions } from '../../utils/Permissions';
import Gallery from '../../General/Gallery';
import { getUser } from '../../utils/StorageFunctions';
import ProfileModal from '../../utils/ProfileModal';
import VenueProfile from '../Venues/VenueProfile';
import { CLEAR_SUPPORT_STATE } from '../../Redux/Actions/types';

class Support extends Component {
  componentDidMount() {
    this.getSupport();
  }

  getSupport = () => {
    const {
      history,
      getStatisticsLayers,
      fetchUnApprovedPlaces,
      fetchTotalPlaces,
      generalUsers,
      general,
    } = this.props;
    general([], CLEAR_SUPPORT_STATE);
    if (getUser() === null) {
      history.push('/login');
    } else {
      if (
        getPermissions(true, true).Support_Role === undefined &&
        getPermissions(true, true).Administrator === undefined
      ) {
        history.push('/');
      } else {
        getStatisticsLayers();
        fetchUnApprovedPlaces(1, [], generalUsers);
        // fetchLastVenues();
        fetchTotalPlaces();
      }
    }
  };
  render() {
    const {
      history,
      data,
      layers,
      general,
      lightbox,
      profileModal,
      generalUsers,
      totalPlaces,
      location,
    } = this.props;
    return (
      <React.Fragment>
        <Gallery lightbox={lightbox} general={general} />
        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={local.VenueProfile}
          Children={VenueProfile}
          location={location}
        />

        <Container>
          <Row className='mt-3'>
            <ReviewCard
              places={data.places}
              general={general}
              history={history}
              users={generalUsers}
            />

            <PlaceCard
              layers={layers}
              history={history}
              totalPlaces={totalPlaces}
            />
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ SupportR, HomeR, GeneralR }) => {
  return {
    data: SupportR.data,
    totalPlaces: SupportR.totalPlaces,
    layers: HomeR.data.layers,
    lightbox: GeneralR.lightbox,
    location: GeneralR.location,
    profileModal: GeneralR.profileModal,
    generalUsers: GeneralR.generalUsers,
  };
};
export default connect(mapStateToProps, actions)(Support);
