import React from "react";
import {
  UPDATE_GENERAL_PROPS,
  UPDATE_VENUES_PROPS,
} from "../../Redux/Actions/types";
import { local } from "../../lang/local";
import { FiX } from "react-icons/fi";
import { confirmAlert } from "react-confirm-alert";

export const unsetErrorMessage = (general) => {
  general(
    [
      {
        prop: "data.errorMessages",
        value: { NameEn: "", NameAr: "", Logo: "" },
      },
    ],
    UPDATE_VENUES_PROPS
  );
};

const validateForm = (form, general) => {
  if (!form.Name.en || !form.Name.ar || !form.Logo) {
    if (!form.Name.en) {
      general(
        [{ prop: "data.errorMessages.NameEn", value: local.NameEnRequired }],
        UPDATE_VENUES_PROPS
      );
    }
    if (!form.Name.ar) {
      general(
        [{ prop: "data.errorMessages.NameAr", value: local.NameArRequired }],
        UPDATE_VENUES_PROPS
      );
    }
    if (!form.Logo) {
      general(
        [{ prop: "data.errorMessages.Logo", value: local.ImageRequired }],
        UPDATE_VENUES_PROPS
      );
    }
  } else {
    unsetErrorMessage(general);
    return true;
  }
};
export const handleSave = (
  e,
  action_type,
  form,
  general,
  fetchLastVenues,
  history
) => {
  e.preventDefault();

  if (validateForm(form, general) === true) {
    let newform = Object.assign({}, form);
    if (newform.Logo !== null) {
      if (!Array.isArray(newform.Logo)) {
        newform.Logo = null;
      }
    }

    if (form.id !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{local.Sure}</h1>
              <p>{local.ConfirmEdit}</p>
              <button onClick={onClose}>{local.No}</button>
              <button
                onClick={() => {
                  action_type(newform, history);
                  onClose();
                }}
              >
                {local.Yes}
              </button>
            </div>
          );
        },
      });
    } else {
      action_type(newform, fetchLastVenues);
    }
  }
};

export const renderPhoto = (image, general) => {
  if (image[0] instanceof File) {
    image = URL.createObjectURL(image[0]);
  }
  return (
    <div style={{ height: "fit-content" }}>
      <FiX
        className="cursor"
        style={{ color: "red" }}
        onClick={(e) => {
          e.preventDefault();
          general(
            [{ prop: "data.form.Logo", value: null }],
            UPDATE_VENUES_PROPS
          );
        }}
      />
      <div
        style={{
          width: "120px",
          height: "120px",
          borderRadius: "22px",
        }}
      >
        <img
          style={{ borderRadius: "22px" }}
          src={image}
          alt=""
          className="imgFull cursor"
          onClick={(e) => {
            e.preventDefault();
            general(
              [
                {
                  prop: "lightbox",
                  value: {
                    image: image,
                    isOpen: true,
                    type: "single",
                  },
                },
              ],
              UPDATE_GENERAL_PROPS
            );
          }}
        />
      </div>
    </div>
  );
};

export const openProfile = (e, general, fetchVenue, item, index) => {
  debugger;
  e.preventDefault();
  general(
    [
      {
        prop: "profileModal",
        value: { isOpen: true, data: item, type: "Company" },
      },
      {
        prop: "companyImage",
        value: item.logo,
      },
    ],
    UPDATE_GENERAL_PROPS
  );
  if (!item.branches) {
    fetchVenue(item.id, "profile", index);
  }
};
