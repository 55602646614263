import React from "react";
import EmojiPicker from "./EmojiPicker";
import icon_send from "../../assets/img/icon_send.png";
import Icon_photo from "../../assets/img/Icon_photo.svg";
import Icon_file from "../../assets/img/Icon_file.svg";
import { local } from "../../lang/local";
import { Row, Col, Input, Form, Spinner, Button } from "reactstrap";
import {
  UPDATE_TICKETS_PROPS,
  MERGE_TICKETS_PROPS,
} from "../../Redux/Actions/types";
import Dropzone from "react-dropzone";
import { showConfirmMessage } from "../../General/GeneralFunctions";
import { FaPlus } from "react-icons/fa";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { FaRegFileAudio } from "react-icons/fa";
import VideoLink from "./VideoLink";
import AudioRecorder from "./AudioRecorder";
import { RiSendPlaneFill } from "react-icons/ri";

const CreateTicketMessage = ({
  general,
  form,
  addTicketMessage,
  ticket,
  isLoading,
}) => {
  const onSaveFile = (acceptedFiles) => {
    let sizeInMB = (acceptedFiles[0].size / (1024 * 1024)).toFixed(2);
    if (+sizeInMB > 10) {
      showConfirmMessage(local.ValidateFileLength, "error");
    }

    if (+sizeInMB <= 10) {
      general(
        [
          {
            prop: "data_message.form.Attachments",
            value: acceptedFiles,
          },
        ],
        MERGE_TICKETS_PROPS
      );
    }
  };
  const renderFileChoosen = (ImageSrc, accept, type) => {
    return (
      <Dropzone onDrop={(acceptedFiles) => onSaveFile(acceptedFiles)}>
        {({ getRootProps, getInputProps, acceptedFiles }) => (
          <div {...getRootProps()} className="fullWidthHeight center">
            <input
              {...getInputProps()}
              multiple={false}
              accept={accept}
              defaultValue={acceptedFiles}
              className="cursor"
            />

            <div className="fullWidthHeight ticket_tab_option center">
              {type === "image" ? (
                <img src={ImageSrc} alt="" />
              ) : (
                <ImageSrc color="#fff" size="22" />
              )}
            </div>
          </div>
        )}
      </Dropzone>
    );
  };
  const onSend = (e) => {
    e.preventDefault();

    if (
      (form.Msg.text !== "" ||
        form.Attachments.length > 0 ||
        form.Attachments.length > 0) &&
      ticket !== null &&
      form.ticketId !== null
    ) {
      addTicketMessage(form, ticket.id);
    }
  };

  const actions = [
    {
      icon: <AudioRecorder general={general} />,
      name: local.Record,
    },

    {
      icon: <VideoLink general={general} />,
      name: local.Video,
    },
    {
      icon: renderFileChoosen(Icon_file, ".doc,.pdf", "image"),
      name: local.Document,
    },
    {
      icon: renderFileChoosen(FaRegFileAudio, "audio/*", "icon"),
      name: local.Audio,
    },

    {
      icon: renderFileChoosen(Icon_photo, "image/*", "image"),
      name: local.Photo,
    },
  ];

  return (
    <Form>
      <Row className="alignItem inline_margin_chat">
        <Col xl={1} lg={1} md={1} sm={1} xs={1}>
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{
              background: "red",
              // position: "absolute",
              // marginTop: "-365px",
            }}
            ariaLabel="SpeedDial basic example"
            sx={{ position: "absolute", bottom: 25, right: 16 }}
            icon={<FaPlus color="#fff" />}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
              />
            ))}
          </SpeedDial>
        </Col>

        <Col className="flex alignItem">
          <Input
            type="textarea"
            className="message_box"
            placeholder={local.TypeHere}
            value={form.Msg.text}
            onChange={(e) => {
              general(
                [{ prop: "data_message.form.Msg.text", value: e.target.value }],
                UPDATE_TICKETS_PROPS
              );
            }}
          />
          <EmojiPicker general={general} input_value={form.Msg.text} />

          <Button
            className="ticket_btn_send center"
            onClick={onSend}
            color="primary"
          >
            {isLoading ? <Spinner size="sm" /> : <RiSendPlaneFill />}
          </Button>
          {/* <img
            src={icon_send}
            alt=""
            width="70"
            className="cursor"
            onClick={onSend}
          /> */}
        </Col>
      </Row>
    </Form>
  );
};

export default CreateTicketMessage;
