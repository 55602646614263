import React, { useState } from 'react';
import { Card, Row, Col } from 'reactstrap';
import { getLanguage, local } from '../../../lang/local';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {
  renderClassInfo,
  renderClassType,
  renderArrawDirectionItem,
  renderDirectionsArrraws,
} from './DirectionFunctions';
import car_active from '../../../assets/img/car_active.png';
import car_inactive from '../../../assets/img/car_inactive.png';
import vector_active from '../../../assets/img/vector_active.png';
import vector_inactive from '../../../assets/img/vector_inactive.png';
import start_icon from '../../../assets/img/start_icon.png';
import end_icon from '../../../assets/img/end_icon.png';
import close_icon from '../../../assets/img/close_icon.png';

import {
  CLEAR_DIRECTION_MENU_STATE,
  UPDATE_DIRECTION_MENU_PROPS,
  UPDATE_SEARCH_MENU_PROPS,
  UPDATE_USER_ACCOUNT_PROPS,
} from '../../../Redux/Actions/types';
import { webAsideCardStyle } from '../../../utils/MapViewStyle';

import {
  handleCloseMenu,
  renderRowHeader,
} from '../../../utils/MapViewFunctions';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/Actions/index';
import { rightLeftProperty } from '../../../General/GeneralStyle';

const DirectionMenu = ({
  general,
  direction_data,
  startLocation,
  endLocation,
  getSocketResult,
}) => {
  const [active, setActive] = useState('car');
  const alignment = () => {
    let align = getLanguage() === 'ar' ? 'right' : 'none';
    return align;
  };

  const onStartDirection = (e) => {
    e.preventDefault();

    general(
      [
        { prop: 'direction_data.isOpen', value: false },
        { prop: 'search_direction.isOpen', value: true },
        { prop: 'search_direction.search_title', value: local.From },
        { prop: 'search_direction.type', value: 'start' },
      ],
      UPDATE_DIRECTION_MENU_PROPS
    );
  };

  const onEndDirection = (e) => {
    e.preventDefault();

    general(
      [
        { prop: 'direction_data.isOpen', value: false },
        { prop: 'search_direction.isOpen', value: true },
        { prop: 'search_direction.search_title', value: local.To },
        { prop: 'search_direction.type', value: 'end' },
      ],
      UPDATE_DIRECTION_MENU_PROPS
    );
  };

  const renderClassess = () => {
    if (direction_data.routing) {
      if (direction_data.routing.length > 0) {
        return (
          <Row className='ml-2 mr-2 mt-4 alignItem'>
            {direction_data.routing.map((line, index) => {
              return renderClassInfo(
                line,
                direction_data.active_class,
                index,
                general,
                direction_data.selecting_routing
              );
            })}
          </Row>
        );
      }
    }
  };

  const closedItems = [
    {
      payload: [{ prop: 'search_data.toViewSearch', value: true }],
      action: UPDATE_SEARCH_MENU_PROPS,
    },

    {
      payload: [{ prop: 'userAccount.isOpen', value: false }],
      action: UPDATE_USER_ACCOUNT_PROPS,
    },

    {
      payload: [],
      action: CLEAR_DIRECTION_MENU_STATE,
    },
  ];

  return (
    <Card className='web_view_card toggle_slider' style={webAsideCardStyle}>
      {window.innerWidth > 760 ? (
        renderRowHeader(closedItems, general)
      ) : (
        <img
          src={close_icon}
          alt='close_icon'
          className='mobile-close-icon'
          style={rightLeftProperty}
          onClick={(e) => handleCloseMenu(e, closedItems, general)}
        />
      )}

      <div className='mt-4'>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Paper sx={{ width: '100%', maxWidth: '100%' }}>
              <MenuList>
                <MenuItem onClick={onStartDirection}>
                  <Typography variant='body2' color='text.secondary'>
                    <div className='img_container_account'>
                      <img src={start_icon} alt='' width='20' height='20' />
                    </div>
                  </Typography>
                  <ListItemText
                    sx={{ textAlign: alignment(), marginInlineStart: '10px' }}
                  >
                    {startLocation.title ? startLocation.title : local.From}
                  </ListItemText>
                </MenuItem>

                <MenuItem onClick={onEndDirection}>
                  <Typography variant='body2' color='text.secondary'>
                    <div className='img_container_account'>
                      <img src={end_icon} alt='' width='20' height='20' />
                    </div>
                  </Typography>
                  <ListItemText
                    sx={{ textAlign: alignment(), marginInlineStart: '10px' }}
                  >
                    {endLocation.title ? endLocation.title : local.To}
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </Paper>
          </Col>
        </Row>

        <Row className='ml-2 mr-2 mt-5 alignItem'>
          {renderClassType(
            car_active,
            car_inactive,
            active,
            setActive,
            'car',
            getSocketResult,
            startLocation,
            endLocation
          )}
          {renderClassType(
            vector_active,
            vector_inactive,
            active,
            setActive,
            'foot',
            getSocketResult,
            startLocation,
            endLocation
          )}
        </Row>
        {renderClassess()}

        {direction_data.selecting_routing !== null ? (
          <Row className='ml-2 mr-2 mt-4'>
            <Col>
              <Card className='arraw_card'>
                {renderArrawDirectionItem(
                  start_icon,
                  direction_data.start_location.instruction
                )}

                {renderDirectionsArrraws(direction_data.selecting_routing)}

                {renderArrawDirectionItem(
                  end_icon,
                  direction_data.end_location.instruction
                )}
              </Card>
            </Col>
          </Row>
        ) : null}
      </div>
    </Card>
  );
};

const mapStateToProps = ({ DirectionR }) => {
  return {
    direction_data: DirectionR.direction_data,
    startLocation: DirectionR.startLocation,
    endLocation: DirectionR.endLocation,
  };
};

export default connect(mapStateToProps, actions)(DirectionMenu);
