import React from "react";
import GeneralCard from "../../General/GeneralCard";
import { Col, Row, Input, Button, Spinner } from "reactstrap";
import { getLanguage, local } from "../../lang/local";
import OpenningHours from "../../General/OpenningHours";
import {
  DELETE_BUSINESS_PROPS,
  MERGE_BUSINESS_PROPS,
  UPDATE_BUSINESS_PROPS,
} from "../../Redux/Actions/types";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import Select from "react-select";
import { getDefaultValue } from "../../General/SelectFunctions";
import { renderBranchesOptions, handleSave } from "../Branches/BranchFunctions";
import { isRtl, selectFormStyle } from "../../General/GeneralStyle";
import Dropzone from "react-dropzone";
import image_icon from "../../assets/img/image_icon.png";

import MapModal from "./MapModal";
import {
  handleEnglishNameChange,
  handleArabicNameChange,
  renderPhotos,
  renderErrorMessage,
} from "../../General/GeneralFunctions";
import strik from "../../assets/img/strik.png";
import { AsyncPaginate } from "react-select-async-paginate";

const AddBranchCard = ({
  general,
  form,
  addBranch,
  isLoadingCreate,
  venueId,
  venuesOptions,
  location,
  errorMessages,
  loadVenueOptions,
}) => {
  const renderAddBranchCard = () => {
    return (
      <>
        <Row>
          <Col className="mt-2" xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="container_add_input">
              <img src={strik} alt="strik" className="strik_icon" />

              <Input
                className="add_input"
                placeholder={local.EnglishName}
                type="text"
                value={form.Name.en}
                onChange={(e) =>
                  handleEnglishNameChange(
                    e,
                    general,
                    UPDATE_BUSINESS_PROPS,
                    "data.form.Name.en"
                  )
                }
              />
            </div>

            {renderErrorMessage(form.Name.en, errorMessages.NameEn)}
          </Col>

          <Col className="mt-4" xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="container_add_input">
              <img src={strik} alt="strik" className="strik_icon" />
              <Input
                className="add_input"
                placeholder={local.ArabicName}
                value={form.Name.ar}
                onChange={(e) =>
                  handleArabicNameChange(
                    e,
                    general,
                    UPDATE_BUSINESS_PROPS,
                    "data.form.Name.ar"
                  )
                }
              />
            </div>
            {renderErrorMessage(form.Name.ar, errorMessages.NameAr)}
          </Col>

          <Col className="mt-4" xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="container_add_input">
              <img src={strik} alt="strik" className="strik_icon" />

              <div className="form_select_container">
                <AsyncPaginate
                  key={venueId}
                  placeholder={local.Venues}
                  value={getDefaultValue(venueId, venuesOptions)}
                  loadOptions={loadVenueOptions}
                  onChange={(e) =>
                    e !== null
                      ? general(
                          [
                            {
                              prop: "data.venueId",
                              value: e.value,
                            },
                          ],
                          UPDATE_BUSINESS_PROPS
                        )
                      : general(
                          [
                            {
                              prop: "data.venueId",
                              value: null,
                            },
                          ],
                          UPDATE_BUSINESS_PROPS
                        )
                  }
                  additional={{
                    page: 1,
                  }}
                  styles={selectFormStyle}
                  isClearable={true}
                  isSearchable={true}
                />
                {/* <AsyncSelect
                  key={venueId}
                  placeholder={local.Venues}
                  value={getDefaultValue(venueId, venuesOptions)}
                  defaultOptions={venuesOptions}
                  cacheOptions
                  loadOptions={promiseOptions(venuesOptions)}
                  onChange={(e) =>
                    e !== null
                      ? general(
                          [
                            {
                              prop: "data.venueId",
                              value: e.value,
                            },
                          ],
                          UPDATE_BUSINESS_PROPS
                        )
                      : general(
                          [
                            {
                              prop: "data.venueId",
                              value: null,
                            },
                          ],
                          UPDATE_BUSINESS_PROPS
                        )
                  }
                  isClearable={true}
                  isRtl={isRtl()}
                  styles={selectFormStyle}
                  isSearchable={true}
                /> */}
              </div>
            </div>
            {renderErrorMessage(venueId, errorMessages.Venue)}
          </Col>

          <Col className="mt-4 maxWidth" xl={12}>
            <div className="container_add_input">
              <img src={strik} alt="strik" className="strik_icon" />
              <div className="form_select_container">
                <Select
                  key={form.Type}
                  placeholder={local.Type}
                  value={getDefaultValue(form.Type, renderBranchesOptions())}
                  options={renderBranchesOptions()}
                  onChange={(e) =>
                    e !== null
                      ? general(
                          [
                            {
                              prop: "data.form.Type",
                              value: e.value,
                            },
                          ],
                          UPDATE_BUSINESS_PROPS
                        )
                      : general(
                          [
                            {
                              prop: "data.form.Type",
                              value: null,
                            },
                          ],
                          UPDATE_BUSINESS_PROPS
                        )
                  }
                  isClearable={false}
                  isRtl={isRtl()}
                  styles={selectFormStyle}
                  isSearchable={true}
                />
              </div>
            </div>

            {form.Type === null ? (
              <span className="error_message">{errorMessages.Type}</span>
            ) : null}
          </Col>

          <Col className="mt-4" xl={12} lg={12} md={12} sm={12} xs={12}>
            <OpenningHours
              general={general}
              WorkingHours={form.WorkingHours}
              action={UPDATE_BUSINESS_PROPS}
            />
          </Col>
          <Col className="mt-4" xl={12} lg={12} md={12} sm={12} xs={12}>
            <MapModal
              location={location}
              general={general}
              point={{ lat: form.Lat, lng: form.Lng }}
              defaulValue={
                form.Lat ? [form.Lat.toString(), form.Lng.toString()] : ""
              }
            />
            {renderErrorMessage(form.Lat, errorMessages.Location)}
          </Col>

          <Col className="mt-4" xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="container_add_input">
              <PhoneInput
                country="sa"
                lang={getLanguage() === "ar" ? ar : ""}
                // onlyCountries={["eg", "sa", "ae", "ku", "lb", "om", "bh", "ae"]}
                masks={{
                  eg: ".. ........",
                  sa: ".. ... ....",
                  ae: ".. .......",
                }}
                // countryCodeEditable={false}
                value={form.Phone}
                onChange={(mobile) =>
                  general(
                    [
                      {
                        prop: "data.form.Phone",
                        value: mobile,
                      },
                    ],
                    UPDATE_BUSINESS_PROPS
                  )
                }
              />
            </div>
          </Col>

          <Col className="mt-4 maxWidth" xl={12}>
            <div className="div_location">
              <div className="ml-1 mr-1 mt-3 mb-3 flex alignItem">
                <Dropzone
                  accept="image/*"
                  multiple
                  onDrop={(acceptedFiles) =>
                    general(
                      [
                        {
                          prop: "data.form.Photos",
                          value: acceptedFiles,
                        },
                      ],
                      MERGE_BUSINESS_PROPS
                    )
                  }
                >
                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <div {...getRootProps()} className="cursor">
                      <input
                        {...getInputProps()}
                        multiple={true}
                        accept="image/*"
                        defaultValue={acceptedFiles}
                      />

                      <div style={{ width: "40px", height: "35px" }}>
                        <img src={image_icon} alt="" className="imgFull" />
                      </div>
                    </div>
                  )}
                </Dropzone>

                {renderPhotos(
                  form.Photos,
                  "data.form.Photos.",
                  general,
                  DELETE_BUSINESS_PROPS
                )}
              </div>
            </div>
          </Col>
        </Row>

        <Row className="ml-1 mr-1">
          <Col className="mt-3 mb-3" xl={12}>
            <Button
              className="save_btn"
              onClick={(e) =>
                handleSave(e, addBranch, form, general, null, venueId)
              }
            >
              {isLoadingCreate === true ? <Spinner /> : local.Save}
            </Button>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Col className="center mt-4" xl={4} lg={4} md={6} sm={12} xs={12}>
      <GeneralCard
        title={local.AddBranch + " / " + local.Distributor}
        count={null}
        titleColor="blue_color"
        className="text-center maxWidth"
        background="white"
        renderBody={renderAddBranchCard}
      />
    </Col>
  );
};

export default AddBranchCard;
