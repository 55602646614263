import { selectFormStyle, isRtl } from '../../General/GeneralStyle';
import Select from 'react-select';
import React from 'react';


export const CustomSelect = ({ label,hasLabel, value, onChange, options ,isMulti}) => {
  return (
    <div
      className='container_add_input mx-auto'
      // style={{ height: '44px' }}
    >
      <div className='form_select_container'>
        <Select
          key={value}
          placeholder={label}
          value={value}
          isSearchable={true}
          options={options}
          onChange={(e) => onChange(e)}
          isClearable={true}
          isRtl={isRtl()}
          styles={selectFormStyle}
          isMulti={isMulti}
        />
      </div>
    </div>
  );
};
