import React, { useEffect } from "react";
import { Spinner, Row, Col, Button, Card, Input } from "reactstrap";
import { columnAlignLeft, selectSearchStyle } from "../../General/GeneralStyle";
import { local } from "../../lang/local";
import { getDefaultValue } from "../../General/SelectFunctions";
import { UPDATE_USER_CLAIMS_PROPS } from "../../Redux/Actions/types";
import { showConfirmMessage } from "../../General/GeneralFunctions";
import { AsyncPaginate } from "react-select-async-paginate";

const UserClaimSearch = ({
  fetchUserClaims,
  usersOptions,
  data,
  general,
  loadUserOptions,
}) => {
  const handleSearch = (e) => {
    e.preventDefault();

    if (data.userId !== null) {
      fetchUserClaims(data.userId, data.claimValue);
    } else {
      showConfirmMessage(local.ChooseUser, "error");
    }
  };

  useEffect(() => {
    if (data.userId === null) {
      general([{ prop: "data.items", value: [] }], UPDATE_USER_CLAIMS_PROPS);
    }
  }, [data.userId]);
  return (
    <Card className="search_card">
      <Row className="alignItem mb-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Row className="alignItem">
            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <div className="search_select_container">
                <AsyncPaginate
                  key={data.userId}
                  placeholder={local.User}
                  loadOptions={loadUserOptions}
                  value={getDefaultValue(data.userId, usersOptions)}
                  onChange={(e) =>
                    e !== null
                      ? general(
                          [{ prop: "data.userId", value: e.value }],
                          UPDATE_USER_CLAIMS_PROPS
                        )
                      : general(
                          [{ prop: "data.userId", value: null }],
                          UPDATE_USER_CLAIMS_PROPS
                        )
                  }
                  additional={{
                    page: 1,
                  }}
                  styles={selectSearchStyle}
                  isClearable={true}
                  isSearchable={true}
                />
                {/* <AsyncSelect
                  placeholder={local.User}
                  isRtl={isRtl()}
                  styles={selectSearchStyle}
                  isSearchable={true}
                  defaultOptions={usersOptions}
                  loadOptions={() => promiseOptions(usersOptions)}
                  cacheOptions
                  isClearable={true}
                  value={getDefaultValue(data.userId, usersOptions)}
                  onChange={(e) =>
                    e !== null
                      ? general(
                          [{ prop: "data.userId", value: e.value }],
                          UPDATE_USER_CLAIMS_PROPS
                        )
                      : general(
                          [{ prop: "data.userId", value: null }],
                          UPDATE_USER_CLAIMS_PROPS
                        )
                  }
                /> */}
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <Input
                className="search_input"
                placeholder={local.Name}
                value={data.claimValue}
                onChange={(e) =>
                  general(
                    [{ prop: "data.claimValue", value: e.target.value }],
                    UPDATE_USER_CLAIMS_PROPS
                  )
                }
              />
            </Col>
            <Col
              xl={3}
              lg={3}
              md={3}
              sm={6}
              xs={12}
              className={columnAlignLeft() + " mt-4"}
            >
              <Button className="search_btn" onClick={handleSearch}>
                {data.isLoading === true ? (
                  <Spinner color="#fff" />
                ) : (
                  local.Search
                )}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default UserClaimSearch;
