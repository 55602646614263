import React, { Component, useEffect } from 'react';
import Formcomponent from '../../General/form';
import { showSuccessedConfirm } from '../../General/GeneralFunctions';
import { local } from '../../lang/local';
import { addGroup } from '../../services/service.branchesGroup';
import CreateBranchesGroup from './CreateBranchesGroup';

export const BranchGroupForm = ({ isEdit, ...props }) => {
  const onAdd = (e, methods) => {
    addGroup(e)
      .then((res) => {
        if (res.status === 200) {
          methods.reset();
          showSuccessedConfirm(local.AddedSuccessfully);
        }
      })
      .catch((e) => {})
      .finally();
  };
  let defValues = {
    name: null,
    venueId: null,
    branchesId: [],
  };

  return (
    <div className='page-container'>
      <Formcomponent defValues={defValues} onsubmit={onAdd}>
        <CreateBranchesGroup {...props} />
      </Formcomponent>
    </div>
  );
};
