import React from "react";
import { Col, Row, Input, Label } from "reactstrap";
import { getLanguage, local } from "../../lang/local";
import avater2 from "../../assets/img/avater2.svg";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";
import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
import { columnAlignLeft } from "../../General/GeneralStyle";
import { renderName } from "../../General/GeneralFunctions";
// import { getDefaultValue } from "../../General/SelectFunctions";

const ComplaintProfile = ({
  data,
  general,
  ComplaintsOptions,
  // usersOptions,
}) => {
  const getType = () => {
    if (ComplaintsOptions) {
      let entry = ComplaintsOptions.items?.find(
        (comp) => comp.id === data.geoEventsTypeId
      );
      if (entry !== undefined) {
        return renderName(entry.name);
      }
    }
  };
  const handleImageClick = (e) => {
    e.preventDefault();
    if (data.images) {
      general(
        [
          {
            prop: "lightbox",
            value: {
              image: IMAGE_BASE_PATH + data.images,
              isOpen: true,
              type: "single",
            },
          },
        ],
        UPDATE_GENERAL_PROPS
      );
    }
  };
  return data ? (
    <div className="mt-3 mb-3">
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mx-auto mt-3">
          <div className="img_profile_container mx-auto">
            <img
              src={data.images ? IMAGE_BASE_PATH + data.images[0] : avater2}
              className="fullWidthHeight"
              style={{ borderRadius: "100%" }}
              onClick={handleImageClick}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Id}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.id}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.UserNote}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.note}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.ComplaintType}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={getType()}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.CreatedIn}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={
                getLanguage() === "en"
                  ? new Date(data.createdAt).toLocaleString("en")
                  : new Date(data.createdAt).toLocaleString("ar")
              }
              disabled={true}
            />
          </div>
        </Col>
        {/* <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.User}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={getDefaultValue(data.userId, usersOptions)?.label}
              disabled={true}
            />
          </div>
        </Col> */}
      </Row>
    </div>
  ) : null;
};

export default ComplaintProfile;
