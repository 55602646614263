import * as React from 'react';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import cont from '../../../assets/img/cont.svg';
import doc from '../../../assets/img/doc.svg';
import logout_icon from '../../../assets/img/logout.svg';
import help from '../../../assets/img/help.svg';
import Share from '../../../General/Share';
import Places from '../Places/Places';
import { local } from '../../../lang/local';
import {
  UPDATE_USER_ACCOUNT_PROPS,
  UPDATE_CONTRIBUTION_PROPS,
} from '../../../Redux/Actions/types';
import saved_places from '../../../assets/img/saved_places.svg';
import share_icon from '../../../assets/img/share_icon.png';
import { FiEdit } from 'react-icons/fi';
import EditEmail from './EditEmail';
export default function FooterCard({ general, logout, history }) {
  const openContribuation = () => {
    general(
      [
        {
          prop: 'data.isOpenAddContribution',
          value: false,
        },
        {
          prop: 'data.isOpen',
          value: true,
        },
      ],
      UPDATE_CONTRIBUTION_PROPS
    );
    general(
      [
        {
          prop: 'userAccount.isOpen',
          value: false,
        },
      ],
      UPDATE_USER_ACCOUNT_PROPS
    );
  };

  return (
    <Paper sx={{ width: '100%', maxWidth: '100%' }}>
      <MenuList>
        <MenuItem onClick={openContribuation}>
          <ListItemIcon>
            <div className='img_container_account'>
              <img src={cont} alt='' className='imgFull' />
            </div>
          </ListItemIcon>
          <ListItemText>{local.MyContributions}</ListItemText>
        </MenuItem>
        <Places
          TagItem={() => {
            return (
              <MenuItem>
                <ListItemIcon>
                  <div className='img_container_account'>
                    <img src={saved_places} alt='' className='imgFull' />
                  </div>
                </ListItemIcon>
                <ListItemText sx={{color:'black'}}>{local.SavedPalces}</ListItemText>
              </MenuItem>
            );
          }}
        />
        <Share
          TagItem={() => {
            return (
              <MenuItem>
                <ListItemIcon>
                  <div className='img_container_account'>
                    <img src={share_icon} alt='' className='imgFull' />
                  </div>
                </ListItemIcon>
                <ListItemText sx={{color:'black'}}>{local.ShareLink}</ListItemText>
              </MenuItem>
            );
          }}
        />
        <MenuItem
          onClick={() => {
            window.location.href = 'https://altawasol.app/ar/about/';
          }}
        >
          <ListItemIcon>
            <div className='img_container_account'>
              <img src={doc} alt='' className='imgFull' />
            </div>
          </ListItemIcon>
          <ListItemText>{local.About}</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <div className='img_container_account'>
              <img src={help} alt='' className='imgFull' />
            </div>
          </ListItemIcon>
          <ListItemText>{local.Help}</ListItemText>
        </MenuItem>

        <EditEmail
          history={history}
          TagItem={() => {
            return (
              <MenuItem>
                <ListItemIcon>
                  <FiEdit color='#12a9cc' size={30} />
                </ListItemIcon>
                <ListItemText sx={{color:'black'}}>{local.EditEmail}</ListItemText>
              </MenuItem>
            );
          }}
        />

        <MenuItem onClick={() => logout(history)}>
          <ListItemIcon>
            <div className='img_container_account'>
              <img src={logout_icon} alt='' className='imgFull' />
            </div>
          </ListItemIcon>
          <ListItemText>{local.Logout}</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  );
}
