import React, { useState } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import { local } from "../../lang/local";
import {
  renderAddButton,
  renderCheckoBox,
  renderMultipleDeleteButton,
  renderTableDropDown,
} from "../../General/TableFunctions";
import {
  UPDATE_DISTRIBUTORS_PROPS,
  UPDATE_GENERAL_PROPS,
} from "../../Redux/Actions/types";
import PaginationItem from "../../General/PaginationItem";
import {
  renderMultipleImages,
  ShareIcon,
  renderName,
} from "../../General/GeneralFunctions";
import {
  renderImages,
  storeWorkingHours,
  unsetErrorMessage,
} from "../Branches/BranchFunctions";
import Share from "../../General/Share";
import { extractLatLng } from "../../General/MapFunctions";
const MobileList = ({
  history,
  data,
  DeleteDistributor,
  general,
  deleteMultipleDistributors,
  fetchDistributors,
}) => {
  const [checked, setChecked] = useState([]);

  const deleteItem = (e, item, index) => {
    e.preventDefault();
    DeleteDistributor(item.id, index);
  };

  const editItem = (e, item, index) => {
    e.preventDefault();
    unsetErrorMessage(general, UPDATE_DISTRIBUTORS_PROPS);

    general(
      [
        {
          prop: "data.form",
          value: {
            Name: { en: item.name["en"], ar: item.name["ar"] },
            WorkingHours: storeWorkingHours(item.workingHours),
            Photos: renderImages(item.photos),
            Phone: item.phone ? item.phone : "",
            Lat: +item.locationText.split(" ")[2].split(")")[0],
            Lng: +item.locationText.split(" ")[1].split("(")[1],
            Type: 1,
          },
        },
        {
          prop: "data.venueId",
          value: item.venueId,
        },
        {
          prop: "data.item_index",
          value: index,
        },
        {
          prop: "data.form.id",
          value: item.id,
        },
      ],
      UPDATE_DISTRIBUTORS_PROPS
    );
    history.push(`/dashboard/editbranch/${item.id}`);
  };

  const renderTableBody = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return data.items.map((item, index) => {
          return (
            <tr key={index} className="text-center">
              <th scope="row">
                {renderCheckoBox(
                  item.id,
                  checked,
                  setChecked,
                  "single",
                  "Branches_Delete"
                )}
              </th>
              <td>{renderMultipleImages(item.photos, general)}</td>
              <td>{item.id}</td>
              <td>
                <a
                  href=""
                  className="blueLight"
                  onClick={(e) => {
                    e.preventDefault();
                    general(
                      [
                        {
                          prop: "profileModal",
                          value: { isOpen: true, data: item },
                        },
                        {
                          prop: "workingDaysModal.data",
                          value: item.workingHours,
                        },
                      ],
                      UPDATE_GENERAL_PROPS
                    );
                  }}
                >
                  {renderName(item.name)}
                </a>
              </td>

              <td>{item.phone}</td>
              <td>
                <Share
                  TagItem={ShareIcon}
                  point={extractLatLng(item.locationText)}
                  type="distributor"
                  type_id={item.id}
                />
              </td>
              <td>
                {item.workingHours !== undefined ? (
                  item.workingHours ? (
                    Object.values(item.workingHours).length > 0 ? (
                      <p
                        className="view_text"
                        onClick={(e) => {
                          e.preventDefault();
                          general(
                            [
                              { prop: "workingDaysModal.isOpen", value: true },
                              {
                                prop: "workingDaysModal.data",
                                value: item.workingHours,
                              },
                            ],
                            UPDATE_GENERAL_PROPS
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {local.View}
                      </p>
                    ) : null
                  ) : null
                ) : null}
              </td>
              <td>
                {renderTableDropDown(
                  item,
                  index,
                  deleteItem,
                  editItem,
                  "Branches_Delete",
                  "Branches_Update"
                )}
              </td>
            </tr>
          );
        });
      }
    }
  };
  return (
    <Card className="table_card ml-3 mr-3">
      <div className="mt-4">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderAddButton(
              local.CreateDistributor,
              history,
              "/dashboard/add_distributor",
              "Branches_Create"
            )}

            {renderMultipleDeleteButton(
              checked,
              setChecked,
              deleteMultipleDistributors,
              data.isLoadingDelete,
              "Branches_Delete"
            )}
          </Col>
        </Row>

        <Table responsive hover className="mt-4">
          <thead>
            <tr className="text-center">
              <th>
                {data.items
                  ? data.items.length > 0
                    ? renderCheckoBox(
                        data.items.map((i) => i.id),
                        checked,
                        setChecked,
                        "all",
                        "Branches_Delete"
                      )
                    : null
                  : null}
              </th>
              <th>{local.Images}</th>
              <th>{local.Id}</th>
              <th>{local.Name}</th>
              <th>{local.Mobile}</th>
              <th>{local.ShareLink}</th>
              <th>{local.OpenningHours}</th>
              <th>{local.PlaceStatus}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </Table>
        <Row>
          <Col
            className="center mt-3 mb-3"
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            {data.pagination ? (
              <PaginationItem
                pagination={data.pagination}
                general={general}
                fetchData={fetchDistributors}
                filterArray={data.filterArray}
              />
            ) : null}
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default MobileList;
