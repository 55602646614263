import React from "react";
import { Card, Row, Col, Button, Input } from "reactstrap";
import { rightStyle } from "../../../General/GeneralStyle";
import { webAsideCardStyle } from '../../../utils/MapViewStyle';
import { getLanguage, local } from "../../../lang/local";
import { FaTimes } from "react-icons/fa";
import { UPDATE_CONTRIBUTION_PROPS } from "../../../Redux/Actions/types";
import Flatpickr from "react-flatpickr";
import { dayOptions, getDefaultValue } from "../../../General/SelectFunctions";
const Hours = ({ general, WorkingHours }) => {
  const onExit = (e) => {
    e.preventDefault();
    general(
      [
        { prop: "hours.isOpen", value: false },
        { prop: "data.isOpenAddContribution", value: true },
      ],
      UPDATE_CONTRIBUTION_PROPS
    );
  };

  const renderDateTimes = () => {
    if (WorkingHours) {
      if (WorkingHours.length > 0) {
        return WorkingHours.map((dt, index) => {
          return (
            <React.Fragment key={index}>
              <Row className="mt-3">
                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                  <div className="container_add_input2">
                    <div className="form_select_container">
                      <Input
                        className="removed_input_style"
                        value={getDefaultValue(dt.day, dayOptions)?.label}
                        disabled={true}
                      />
                    </div>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                  <div className="container_add_input2">
                    <Flatpickr
                      data-enable-time={false}
                      value={dt.from}
                      options={{
                        enableTime: true,
                        altInput: true,
                        noCalendar: true,
                      }}
                      onChange={(date) => {
                        general(
                          [
                            {
                              prop: "data.form.WorkingHours." + index + ".from",
                              value: date[0].toLocaleTimeString(),
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        );
                      }}
                      placeholder={local.From}
                      className="add_input"
                    />
                  </div>
                </Col>

                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                  <div className="container_add_input2">
                    <Flatpickr
                      data-enable-time={false}
                      value={dt.to}
                      options={{
                        enableTime: true,
                        altInput: true,
                        noCalendar: true,
                      }}
                      onChange={(date) => {
                        general(
                          [
                            {
                              prop: "data.form.WorkingHours." + index + ".to",
                              value: date[0].toLocaleTimeString(),
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        );
                      }}
                      placeholder={local.To}
                      className="add_input"
                    />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          );
        });
      }
    }
  };
  return (
    <Card className="web_view_card" style={webAsideCardStyle}>
      <Row className="alignItem center">
        <Col
          xl={7}
          lg={7}
          sm={7}
          xs={7}
          className={getLanguage() === "en" ? "text-left" : "text-right"}
        >
          <div className="flex alignItem">
            <p className="add_place_title">{local.OpeningHours}</p>
          </div>
        </Col>
        <Col xl={5} lg={5} md={5} sm={5} xs={5}>
          <div className="flex alignItem" style={rightStyle}>
            <div className="place_exit_container" onClick={onExit}>
              <FaTimes />
            </div>
          </div>
        </Col>
      </Row>

      {renderDateTimes()}

      <Row className="alignItem center mt-5">
        <Col xl={12} lg={12} sm={12} xs={12}>
          <Button className="direction_btn" onClick={onExit}>
            {local.Done}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Hours;
