import {
  UPDATE_GENERAL_PROPS,
  CLEAR_GENERAL_STATE,
  MERGE_GENERAL_PROPS,
  DELETE_GENERAL_PROPS,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  ipAddress: { lat: 0, lng: 0 },
  myPosition: { lat: "", lng: "", allowLocation: false },
  branches: [],
  nearest_point: { address: "", lat: "", lng: "", layer: "" },

  links: {
    branch: null,
    distributor: null,
    place: null,
    complaint: null,
    venue: null,
  },

  companyImage: null,
  location: {
    mylocation: { lat: "", lng: "" },
    nearest_location: { lat: "", lng: "" },
    country: "",
  },
  isLoadingRoles: null,
  isLoadingClaims: null,
  lightbox: {
    images: [],
    isOpen: false,
    image: null,
    type: "single",
    index: 0,
  },
  zoomToShape: [],
  shapeItem: null,

  rows_collabse: [],

  profileModal: { isOpen: false, data: null, type: "" },

  workingDaysModal: { isOpen: false, data: null },

  isOpenSideBar: true,

  mapSearch: {
    searchResults: [],
    searchValue: "",
    type: "autoComplete",
    isLoading: false,
    bounds: null,
    lat: "",
    lng: "",
    layer: "",
  },

  generalUsers: [],
};

const GeneralR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_GENERAL_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_GENERAL_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_GENERAL_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_GENERAL_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};

export default GeneralR;
