import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Col, Row, Input, Button, Spinner } from "reactstrap";
import * as actions from "../../Redux/Actions/index";
import { connect } from "react-redux";
import {
  DELETE_COMPLAINTS_PROPS,
  MERGE_COMPLAINTS_PROPS,
  UPDATE_COMPLAINTS_PROPS,
} from "../../Redux/Actions/types";
import { getLanguage, local } from "../../lang/local";
import Dropzone from "react-dropzone";
import image_icon from "../../assets/img/image_icon.png";
import {
  renderPhotos,
  showConfirmMessage,
} from "../../General/GeneralFunctions";
const useStyles = makeStyles((theme) => ({
  typography: {
    width: "400px",
  },
}));

function AddComplaintModel({
  form,
  general,
  isLoadingCreate,
  isOpenAdd,
  addComplaint,
}) {
  const classes = useStyles();

  const toggle = () => {
    general(
      [{ prop: "data.isOpenAdd", value: !isOpenAdd }],
      UPDATE_COMPLAINTS_PROPS
    );
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (form.Location.length === 0 && !form.GeoEventsTypeId) {
      if (form.Location.length === 0) {
        showConfirmMessage(local.LocationRequired, "error");
      }
    } else {
      addComplaint(form);
    }
  };
  return (
    <React.Fragment>
      <Popover
        id="simple-popover"
        open={isOpenAdd}
        onClose={toggle}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: getLanguage() === "en" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>
          <div className="fullWidthHeight">
            <h3 className="text-center mt-3">{local.AddComplaint}</h3>
            <Row className="alignItem mt-2">
              <Col className="mt-4" xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="container_add_input">
                  <Input
                    type="text"
                    className="add_input"
                    value={form.Note}
                    placeholder={local.Note}
                    onChange={(e) =>
                      general(
                        [{ prop: "data.form.Note", value: e.target.value }],
                        UPDATE_COMPLAINTS_PROPS
                      )
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row className="alignItem mt-2">
              <Col className="mt-4" xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="div_location">
                  <div className="ml-1 mr-1 mt-3 mb-3 flex alignItem">
                    <Dropzone
                      accept="image/*"
                      multiple
                      onDrop={(acceptedFiles) =>
                        general(
                          [
                            {
                              prop: "data.form.Images",
                              value: acceptedFiles,
                            },
                          ],
                          MERGE_COMPLAINTS_PROPS
                        )
                      }
                    >
                      {({ getRootProps, getInputProps, acceptedFiles }) => (
                        <div {...getRootProps()} className="cursor">
                          <input
                            {...getInputProps()}
                            multiple={true}
                            accept="image/*"
                            defaultValue={acceptedFiles}
                          />

                          <div style={{ width: "40px", height: "35px" }}>
                            <img src={image_icon} alt="" className="imgFull" />
                          </div>
                        </div>
                      )}
                    </Dropzone>

                    {renderPhotos(
                      form.Images,
                      "data.form.Images.",
                      general,
                      DELETE_COMPLAINTS_PROPS
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="mt-4" xl={12} lg={12} md={12} sm={12} xs={12}>
                <Button className="save_btn" onClick={handleSave}>
                  {isLoadingCreate === true ? <Spinner /> : local.Save}
                </Button>
              </Col>
            </Row>
          </div>
        </Typography>
      </Popover>
    </React.Fragment>
  );
}
const mapStateToProps = ({ ComplaintsR }) => {
  return {
    form: ComplaintsR.data.form,
    isLoadingCreate: ComplaintsR.data.isLoadingCreate,
    isOpenAdd: ComplaintsR.data.isOpenAdd,
  };
};
export default connect(mapStateToProps, actions)(AddComplaintModel);
