import React, { Component } from 'react';
import './complaint.css';
import { Col, Row, Card } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import WebList from './WebList';
import MobileList from './MobileList';
import Gallery from '../../General/Gallery';
import { getPoints } from '../../General/MapFunctions';
import { getUser } from '../../utils/StorageFunctions';
import ProfileModal from '../../utils/ProfileModal';
import ComplaintProfile from './ComplaintProfile';
import TooMap from '../../General/TooMap';

import {
  CLEAR_COMPLAINTS_STATE,
  MERGE_COMPLAINTS_PROPS,
  UPDATE_GENERAL_PROPS,
} from '../../Redux/Actions/types';
import ComplaintSearch from './ComplaintSearch';
import { USER_CLAIMS, USER_ROLES } from '../../General/StaticKeys';
import { getPermissions } from '../../utils/Permissions';

class Complaints extends Component {
  componentDidMount() {
    this.props.general([], CLEAR_COMPLAINTS_STATE);
    if (getUser() === null) {
      this.props.history.push('/login');
    } else {
      this.props.general(
        [{ prop: 'rows_collabse', value: [] }],
        UPDATE_GENERAL_PROPS
      );
      this.props.getLocationWithIp();
      this.getTypes();
    }
  }

  getTypes = () => {
    const { fetchComplaints, general } = this.props;

    if (getPermissions(true, true).Administrator !== undefined) {
      fetchComplaints([], 1);
    } else {
      if (getPermissions(true, true).GeoEvent_Get !== undefined) {
        let roles = JSON.parse(localStorage.getItem(USER_ROLES));
        let claims = JSON.parse(localStorage.getItem(USER_CLAIMS));

        if (claims) {
          let entry = claims.find((c) => c.claimType === 'GeoEventType_Get');

          if (entry) {
            let ids = entry?.claimValue.split(',');

            if (ids) {
              general(
                [
                  {
                    prop: 'data.filterArray',
                    value: {
                      op: 3,
                      fieldName: 'Id',
                      value: ids,
                    },
                  },
                ],
                MERGE_COMPLAINTS_PROPS
              );
              let filterArray = [
                {
                  op: 3,
                  fieldName: 'GeoEventsTypeId',
                  value: ids,
                },
              ];

              fetchComplaints(filterArray, 1);
            }
          }
        }
      }
    }
  };

  renderMap = () => {
    const { data, location, general, zoomToShape, shapeItem } = this.props;
    if (data.items) {
      return (
        <Card className='mapCard'>
          <TooMap
            location={location}
            markers_list={getPoints(data.items, 'locationText', 'note')}
            general={general}
            zoomToShape={zoomToShape}
            shapeItem={shapeItem}
          />
          {/* <GeneralMap
            location={location}
            markers_list={getPoints(data.items, "locationText", "note")}
            general={general}
            zoomToShape={zoomToShape}
            shapeItem={shapeItem}
          /> */}
        </Card>
      );
    }
  };
  render() {
    const {
      history,
      data,
      general,
      active_cards,
      fetchComplaints,
      usersOptions,
      loadUserOptions,
      lightbox,
      profileModal,
      ComplaintsOptions,
      rows_collabse,
      getComplaintType,
    } = this.props;
    return (
      <div className='page-container'>
        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={local.ComplaintProfile}
          Children={ComplaintProfile}
          ComplaintsOptions={ComplaintsOptions}
          usersOptions={usersOptions}
        />
        <Gallery lightbox={lightbox} general={general} />

        <Row>
          <Col>
            <ComplaintSearch
              loadUserOptions={loadUserOptions}
              usersOptions={usersOptions}
              filterArray={data.filterArray}
              isLoading={data.isLoadingSearch}
              general={general}
              fetchComplaints={fetchComplaints}
              history={history}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mobile_col'>
            <MobileList
              data={data}
              general={general}
              active_cards={active_cards}
              fetchComplaints={fetchComplaints}
              ComplaintsOptions={ComplaintsOptions}
              getComplaintType={getComplaintType}
            />
          </Col>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ height: '100vh' }}
          >
            <div
              className='table_container'
              style={{
                width:
                  rows_collabse.length > 0
                    ? rows_collabse.find((col) => col === true)
                      ? '98%'
                      : 'fit-content'
                    : 'fit-content',
              }}
            >
              <WebList
                data={data}
                general={general}
                active_cards={active_cards}
                fetchComplaints={fetchComplaints}
                rows_collabse={rows_collabse}
                ComplaintsOptions={ComplaintsOptions}
                getComplaintType={getComplaintType}
              />
            </div>

            {this.renderMap()}
          </Col>
        </Row>
      </div>
    );
  }
}

const GeneralMaptateToProps = ({
  ComplaintsR,
  ComplaintTypeR,
  GeneralR,
  UsersR,
}) => {
  return {
    data: ComplaintsR.data,
    active_cards: ComplaintTypeR.data.active_cards,
    location: GeneralR.location,
    usersOptions: UsersR.usersOptions,
    lightbox: GeneralR.lightbox,
    profileModal: GeneralR.profileModal,
    ComplaintsOptions: ComplaintTypeR.data,
    rows_collabse: GeneralR.rows_collabse,
    zoomToShape: GeneralR.zoomToShape,
    shapeItem: GeneralR.shapeItem,
  };
};
export default connect(GeneralMaptateToProps, actions)(Complaints);
