import { getLanguage } from '../lang/local';

const prepareBorderRadius = () => {
  if (window.innerWidth > 760) {
    if (getLanguage() === 'en') {
      return '0px 35px 35px 0px';
    } else {
      return '35px  0px 35px 0px';
    }
  } else {
    return '0px 0px 0px 0px';
  }
};

const topPreopery=()=>{
  if (window.innerWidth > 760) {
    return '0px'
  }
  else{
    return '10px'
  }
}

export const webAsideCardStyle = {
  left: getLanguage() === 'en' ? '0px' : null,
  direction: getLanguage() === 'en' ? 'ltr' : 'rtl',
  right: getLanguage() === 'ar' ? '0px' : null,
  borderRadius: prepareBorderRadius(),
  top:topPreopery()
};
export const initalSearchCard = {
  left: getLanguage() === 'en' ? '0px' : null,
  direction: getLanguage() === 'en' ? 'ltr' : 'rtl',
  right: getLanguage() === 'ar' ? '0px' : null,
  borderRadius: prepareBorderRadius(),
  top:topPreopery()

};
