import React, { useState } from "react";
import { Row, Col, Card } from "reactstrap";
import { local } from "../../lang/local";
import {
  renderAddButton,
  renderCheckoBox,
  renderMultipleDeleteButton,
  renderTableDropDown,
} from "../../General/TableFunctions";
import {
  UPDATE_BRANCHES_PROPS,
  UPDATE_GENERAL_PROPS,
} from "../../Redux/Actions/types";
import PaginationItem from "../../General/PaginationItem";
import {
  getLatLng,
  handleArrawCardClick,
  renderMultipleImages,
  ShareIcon,
  renderName,
} from "../../General/GeneralFunctions";
import {
  renderImages,
  storeWorkingHours,
  unsetErrorMessage,
} from "./BranchFunctions";
import { extractLatLng } from "../../General/MapFunctions";
import Share from "../../General/Share";

const WebList = ({
  history,
  data,
  DeleteBranch,
  general,
  deleteMultipleBranches,
  fetchBranches,
  rows_collabse,
}) => {
  const [checked, setChecked] = useState([]);

  const deleteItem = (e, item, index) => {
    e.preventDefault();
    DeleteBranch(item.id, index);
  };

  const editItem = (e, item, index) => {
    e.preventDefault();
    unsetErrorMessage(general, UPDATE_BRANCHES_PROPS);

    general(
      [
        {
          prop: "data.form",
          value: {
            Name: { en: item.name["en"], ar: item.name["ar"] },
            WorkingHours: storeWorkingHours(item.workingHours),
            Photos: renderImages(item.photos),
            Phone: item.phone ? item.phone : "",
            Lat: +item.locationText.split(" ")[2].split(")")[0],
            Lng: +item.locationText.split(" ")[1].split("(")[1],
            Type: 0,
          },
        },
        {
          prop: "data.venueId",
          value: item.venueId,
        },
        {
          prop: "data.item_index",
          value: index,
        },
        {
          prop: "data.form.id",
          value: item.id,
        },
      ],
      UPDATE_BRANCHES_PROPS
    );
    history.push(`/dashboard/editbranch/${item.id}`);
  };

  const renderTableBody = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return data.items.map((item, index) => {
          let name = renderName(item.name);

          return (
            <React.Fragment>
              <Card
                className="header_card"
                style={{
                  width: rows_collabse[index] === true ? "100%" : "300px",
                  zIndex: 10,
                }}
                key={index}
              >
                {handleArrawCardClick(
                  rows_collabse,
                  index,
                  general,
                  name,
                  "marker",
                  getLatLng(item.locationText)
                )}
                <div className="flex aligmItem fullWidthHeight">
                  <ul className="parent-container">
                    <li className="child-item center">
                      {renderCheckoBox(
                        item.id,
                        checked,
                        setChecked,
                        "single",
                        "Branches_Delete"
                      )}
                    </li>
                    <li className="child-item center">{item.id}</li>
                    <li className="child-item center">
                      <a
                        href=""
                        className="blueLight"
                        onClick={(e) => {
                          e.preventDefault();
                          general(
                            [
                              {
                                prop: "profileModal",
                                value: { isOpen: true, data: item },
                              },
                              {
                                prop: "workingDaysModal.data",
                                value: item.workingHours,
                              },
                            ],
                            UPDATE_GENERAL_PROPS
                          );
                        }}
                      >
                        {name}
                      </a>
                    </li>
                    <li className="child-item center">
                      {renderMultipleImages(item.photos, general)}
                    </li>

                    {rows_collabse[index] === true ? (
                      <>
                        <li className="child-item center">{item.phone}</li>
                        <li className="child-item center">
                          <Share
                            TagItem={ShareIcon}
                            point={extractLatLng(item.locationText)}
                            type="branch"
                            type_id={item.id}
                          />
                        </li>
                        <li className="child-item center">
                          {item.workingHours !== undefined ? (
                            item.workingHours ? (
                              Object.values(item.workingHours).length > 0 ? (
                                <p
                                  className="view_text"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    general(
                                      [
                                        {
                                          prop: "workingDaysModal.isOpen",
                                          value: true,
                                        },
                                        {
                                          prop: "workingDaysModal.data",
                                          value: item.workingHours,
                                        },
                                      ],
                                      UPDATE_GENERAL_PROPS
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {local.View}
                                </p>
                              ) : null
                            ) : null
                          ) : null}
                        </li>

                        <li className="child-item center">
                          {renderTableDropDown(
                            item,
                            index,
                            deleteItem,
                            editItem,
                            "Branches_Delete",
                            "Branches_Update"
                          )}
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              </Card>
              <hr
                className="border_list_hr"
                style={{
                  width: rows_collabse[index] === true ? "100%" : "300px",
                }}
              />
            </React.Fragment>
          );
        });
      }
    }
  };
  return (
    <React.Fragment>
      <Card
        className="header_card cursor"
        style={{
          width:
            rows_collabse.length > 0
              ? rows_collabse.find((col) => col === true)
                ? "100%"
                : "300px"
              : "300px",
          height: "100px",
          zIndex: 10,
        }}
      >
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderAddButton(
              local.AddBranch,
              history,
              "/dashboard/addbranch",
              "Branches_Create"
            )}

            {renderMultipleDeleteButton(
              checked,
              setChecked,
              deleteMultipleBranches,
              data.isLoadingDelete,
              "Branches_Delete"
            )}
          </Col>
        </Row>
        <div className="flex aligmItem fullWidthHeight">
          <ul class="parent-container">
            <li class="child-item center">
              {data.items
                ? data.items.length > 0
                  ? renderCheckoBox(
                      data.items.map((i) => i.id),
                      checked,
                      setChecked,
                      "all",
                      "Branches_Delete"
                    )
                  : null
                : null}
            </li>
            <li className="child-item center bold">{local.Id}</li>
            <li className="child-item center bold"> {local.Name}</li>
            <li className="child-item center bold"> {local.Images}</li>

            {rows_collabse.length > 0 ? (
              rows_collabse.find((col) => col === true) ? (
                <>
                  <li className="child-item center bold">{local.Mobile}</li>
                  <li className="child-item center bold">{local.ShareLink}</li>

                  <li className="child-item center bold">
                    {local.OpenningHours}
                  </li>
                  <li className="child-item center bold"></li>
                </>
              ) : null
            ) : null}
          </ul>
        </div>
      </Card>
      <hr className="border_list_hr" />
      {renderTableBody()}

      <Card
        className="header_card"
        style={{
          width: "300px",
          zIndex: 10,
        }}
      >
        <div className="center fullWidthHeight mb-4 mt-4">
          {data.pagination ? (
            <PaginationItem
              pagination={data.pagination}
              general={general}
              fetchData={fetchBranches}
              filterArray={data.filterArray}
            />
          ) : null}
        </div>
      </Card>
    </React.Fragment>
  );
};

export default WebList;
