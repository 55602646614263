import {
  UPDATE_ROLES_PROPS,
  MERGE_ROLES_PROPS,
  DELETE_ROLES_PROPS,
  CLEAR_ROLES_STATE,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    items: null,
    pagination: null,
    filterArray: [],
    isLoadingDelete: null,
    form: {
      name: "",
      description: "",
    },
    errorMessages: { Name: "", Description: "" },

    claims: [],

    item_index: null,
    isLoadingCreate: false,
    isLoading: false,
  },
  rolesOptions: [],
};

const RolesR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ROLES_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_ROLES_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_ROLES_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_ROLES_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default RolesR;
