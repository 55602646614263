import React, { Component } from 'react';
import '../Support/support.css';
import './Business.css';
import { Row, Container } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import SupportCard from './SupportCard';
// import ReviewsCard from "./ReviewsCard";
import AddBranchCard from './AddBranchCard';
import {
  CLEAR_BRANCHES_STATE,
  CLEAR_BUSINESS_STATE,
  UPDATE_GENERAL_PROPS,
} from '../../Redux/Actions/types';
import { getMyLocation } from '../../General/GeneralFunctions';
import { getUser } from '../../utils/StorageFunctions';
// import BranchesCard from "./BranchesCard";
// import { getPermissions } from "../../General/Permissions";
import Gallery from '../../General/Gallery';
// import DistributorsCard from "./DistributorsCard";
import ProfileModal from '../../utils/ProfileModal';
import UserProfile from '../Users/UserProfile';
import BranchProfile from '../Branches/BranchProfile';
import DistributorProfile from '../Distributors/DistributorProfile';
import VenuesCard from '../Support/VenuesCard';
import VenueProfile from '../Venues/VenueProfile';
import AddVenueCard from './AddVenueCard';
import BusinessAnalysis from './BusinessAnalysis';

class BusinessAccount extends Component {
  componentDidMount() {
    if (getUser() === null) {
      this.props.history.push('/login');
    } else {
      this.getBusiness();
    }
  }

  getBusiness = () => {
    const {
      general,
      // fetchBranchesVenues,
      fetchSupport,
      generalUsers,
      // fetchCustomerReviews,
      // getReviews,
      fetchLastVenues,
      getReviewCountes,
      getBranchesDistributorsCounter,
    } = this.props;
    general([], CLEAR_BUSINESS_STATE);
    general([], CLEAR_BRANCHES_STATE);

    // fetchBranchesVenues();
    fetchSupport(generalUsers);

    getMyLocation(
      [
        { prop: 'location.mylocation.lat', type: 'lat' },
        { prop: 'location.mylocation.lng', type: 'lng' },
      ],

      general,
      UPDATE_GENERAL_PROPS
    );
    fetchLastVenues();
    getReviewCountes();
    getBranchesDistributorsCounter();

    // if (
    //   getPermissions(true, true).UserBusiness_GetUsers !== undefined ||
    //   getPermissions(true, true).Administrator !== undefined
    // ) {
    //   fetchCustomerReviews();
    // } else {
    //   let userIDs = [getUser().data.id];
    //   getReviews(userIDs);
    // }
  };
  render() {
    const {
      history,
      data,
      general,
      addBranch,
      isLoadingCreate,
      venueId,
      venuesOptions,
      location,
      lightbox,
      fetchTicketMessages,
      generalUsers,
      profileModal,
      workingDaysModal,
      errorMessages,
      loadVenueOptions,
      venues,
      fetchVenue,
      venueForm,
      addVenue,
      isLoadingCreateVenue,
      venueErrorMessages,
      fetchLastVenues,
    } = this.props;
    return (
      <div className='page-container'>
        <Gallery lightbox={lightbox} general={general} />

        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={
            profileModal.type === 'user'
              ? local.UserProfile
              : profileModal.type === 'Company'
              ? local.VenueProfile
              : profileModal.type === 'branch'
              ? local.BranchProfile
              : local.DistributorProfile
          }
          Children={
            profileModal.type === 'user'
              ? UserProfile
              : profileModal.type === 'Company'
              ? VenueProfile
              : profileModal.type === 'branch'
              ? BranchProfile
              : DistributorProfile
          }
          location={location}
          workingDaysModal={workingDaysModal}
        />

        <Container>
          <Row>
            <VenuesCard
              venues={venues}
              general={general}
              history={history}
              fetchVenue={fetchVenue}
            />
            <BusinessAnalysis
              history={history}
              items={[
                {
                  label: local.Branches2,
                  value: data.statistcs.beanches,
                  routeName: '/branches',
                },
                {
                  label: local.Distributors,
                  value: data.statistcs.distributors,
                  routeName: '/distributors',
                },
                {
                  label: local.Reviews,
                  value: data.statistcs.reviews,
                  routeName: '/reviews',
                },
              ]}
            />

            <AddVenueCard
              general={general}
              form={venueForm}
              addVenue={addVenue}
              isLoadingCreate={isLoadingCreateVenue}
              errorMessages={venueErrorMessages}
              fetchLastVenues={fetchLastVenues}
            />
            {/* 
                  <BranchesCard
                    branches={data.branches}
                    history={history}
                    general={general}
                  />
                  <DistributorsCard
                    distributors={data.distributors}
                    history={history}
                    general={general}
                  /> */}
            <AddBranchCard
              general={general}
              form={data.form}
              addBranch={addBranch}
              isLoadingCreate={isLoadingCreate}
              venueId={venueId}
              venuesOptions={venuesOptions}
              location={location}
              errorMessages={errorMessages}
              loadVenueOptions={loadVenueOptions}
            />

            {/* 
                <SearchCard
                  search={search}
                  general={general}
                  location={location}
                  searchPlaces={searchPlaces}
                  getPlacesByLayer={getPlacesByLayer}
                /> */}

            {/* {getPermissions(true, true).reviews !== undefined ||
                  getPermissions(true, true).Administrator !== undefined ? (
                    <ReviewsCard reviews={data.reviews} general={general} />
                  ) : null} */}

            <SupportCard
              history={history}
              support_tickets={data.support_tickets}
              general={general}
              fetchTicketMessages={fetchTicketMessages}
              generalUsers={generalUsers}
            />
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({
  BusinessR,
  BranchesR,
  VenuesR,
  GeneralR,
  SupportR,
}) => {
  return {
    data: BusinessR.data,
    errorMessages: BranchesR.data.errorMessages,
    venueId: BusinessR.data.venueId,
    search: BusinessR.search,
    isLoadingCreate: BranchesR.data.isLoadingCreate,
    venuesOptions: VenuesR.venuesOptions,
    venueForm: VenuesR.data.form,
    isLoadingCreateVenue: VenuesR.data.isLoadingCreate,
    venueErrorMessages: VenuesR.data.errorMessages,
    location: GeneralR.location,
    lightbox: GeneralR.lightbox,
    generalUsers: GeneralR.generalUsers,
    profileModal: GeneralR.profileModal,
    workingDaysModal: GeneralR.workingDaysModal,
    venues: SupportR.data.venues,
  };
};
export default connect(mapStateToProps, actions)(BusinessAccount);
