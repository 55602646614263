import React, { Component } from 'react';
import { Card, Col, Row } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import { getPolygons } from '../../General/MapFunctions';
// import GeneralMap from "../../General/GeneralMap";
import { getUser } from '../../utils/StorageFunctions';
import ProfileModal from '../../utils/ProfileModal';
import UserClaimProfile from './UserClaimProfile';
import WebList from './WebList';
import MobileList from './MobileList';
import {
  CLEAR_USER_CLAIMS_STATE,
  UPDATE_GENERAL_PROPS,
} from '../../Redux/Actions/types';
import UserClaimSearch from './UserClaimSearch';
import TooMap from '../../General/TooMap';
import { getPermissions } from '../../utils/Permissions';

class UserClaims extends Component {
  componentDidMount() {
    this.getUserClaim();
  }

  getUserClaim = () => {
    const { history, general } = this.props;
    general([], CLEAR_USER_CLAIMS_STATE);
    if (getUser() === null) {
      history.push('/login');
    } else {
      if (
        getPermissions(true, true).RoleBusiness_GetUserClaims === undefined &&
        getPermissions(true, true).Administrator === undefined
      ) {
        history.push('/');
      } else {
        general([{ prop: 'rows_collabse', value: [] }], UPDATE_GENERAL_PROPS);

        // this.props.setClimsForUser();
      }
    }
  };

  renderMap = () => {
    const { data, location } = this.props;
    if (data.items) {
      return (
        <Card className='mapCard'>
          <TooMap
            location={location}
            polygons_list={getPolygons(
              data.items,
              'geometryText',
              'claimValue'
            )}
          />
          {/* <GeneralMap
            location={location}
            polygons_list={getPolygons(
              data.items,
              "geometryText",
              "claimValue"
            )}
          /> */}
        </Card>
      );
    }
  };
  render() {
    const {
      history,
      data,
      deleteUserClaim,
      fetchUserClaims,
      usersOptions,
      general,
      deleteMultipleUsersClaims,
      profileModal,
      rows_collabse,
      loadUserOptions,
    } = this.props;
    return (
      <div className='page-container'>
        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={local.UserClaimProfile}
          Children={UserClaimProfile}
          usersOptions={usersOptions}
        />

        <Row>
          <Col>
            <UserClaimSearch
              data={data}
              fetchUserClaims={fetchUserClaims}
              usersOptions={usersOptions}
              general={general}
              loadUserOptions={loadUserOptions}
            />
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mobile_col'>
            <MobileList
              history={history}
              data={data}
              deleteUserClaim={deleteUserClaim}
              general={general}
              deleteMultipleUsersClaims={deleteMultipleUsersClaims}
            />
          </Col>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ height: '100vh' }}
          >
            <div
              className='table_container'
              style={{
                width:
                  rows_collabse.length > 0
                    ? rows_collabse.find((col) => col === true)
                      ? '98%'
                      : 'fit-content'
                    : 'fit-content',
              }}
            >
              <WebList
                history={history}
                data={data}
                deleteUserClaim={deleteUserClaim}
                general={general}
                deleteMultipleUsersClaims={deleteMultipleUsersClaims}
                rows_collabse={rows_collabse}
              />
            </div>

            {this.renderMap()}
          </Col>
        </Row>
      </div>
    );
  }
}

const GeneralMaptateToProps = ({ UserClaimsR, UsersR, GeneralR }) => {
  return {
    data: UserClaimsR.data,
    usersOptions: UsersR.usersOptions,
    location: GeneralR.location,
    profileModal: GeneralR.profileModal,
    rows_collabse: GeneralR.rows_collabse,
  };
};
export default connect(GeneralMaptateToProps, actions)(UserClaims);
