import React, { useEffect } from "react";
import { Spinner, Row, Col, Button, Card, Input } from "reactstrap";
import {
  columnAlignLeft,
  isRtl,
  selectSearchStyle,
} from "../../General/GeneralStyle";
import { local } from "../../lang/local";
import AsyncSelect from "react-select/async";
import { getDefaultValue, promiseOptions } from "../../General/SelectFunctions";
import { UPDATE_ROLES_CLAIMS_PROPS } from "../../Redux/Actions/types";
import { showConfirmMessage } from "../../General/GeneralFunctions";
const RoleClaimSearch = ({ rolesOptions, data, fetchRoelsClaims, general }) => {
  const handleSearch = (e) => {
    e.preventDefault();

    if (data.roleId !== null) {
      fetchRoelsClaims(data.roleId, data.claimValue);
    } else {
      showConfirmMessage(local.ChooseRole, "error");
    }
  };

  useEffect(() => {
    if (data.roleId === null) {
      general([{ prop: "data.items", value: [] }], UPDATE_ROLES_CLAIMS_PROPS);
    }
  }, [data.roleId]);
  return (
    <Card className="search_card">
      <Row className="alignItem mb-4">
        <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
          <div className="search_select_container">
            <AsyncSelect
              placeholder={local.Roles}
              isRtl={isRtl()}
              styles={selectSearchStyle}
              isSearchable={true}
              defaultOptions={rolesOptions}
              loadOptions={() => promiseOptions(rolesOptions)}
              cacheOptions
              isClearable={true}
              value={getDefaultValue(data.roleId, rolesOptions)}
              onChange={(e) =>
                e !== null
                  ? general(
                      [{ prop: "data.roleId", value: e.value }],
                      UPDATE_ROLES_CLAIMS_PROPS
                    )
                  : general(
                      [{ prop: "data.roleId", value: null }],
                      UPDATE_ROLES_CLAIMS_PROPS
                    )
              }
            />
          </div>
        </Col>
        <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
          <Input
            className="search_input"
            placeholder={local.Name}
            value={data.claimValue}
            onChange={(e) =>
              general(
                [{ prop: "data.claimValue", value: e.target.value }],
                UPDATE_ROLES_CLAIMS_PROPS
              )
            }
          />
        </Col>
        <Col
          xl={3}
          lg={3}
          md={3}
          sm={6}
          xs={12}
          className={columnAlignLeft() + " mt-4"}
        >
          <Button className="search_btn" onClick={handleSearch}>
            {data.isLoading === true ? <Spinner color="#fff" /> : local.Search}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default RoleClaimSearch;
