import React, { useEffect } from "react";
import { Col, Row, Card } from "reactstrap";
import { local } from "../lang/local";
import { getUser } from "../utils/StorageFunctions";
import UserProfile from "../Pages/Users/UserProfile";
import * as actions from "../Redux/Actions/index";
import { connect } from "react-redux";
import {
  UPDATE_GENERAL_PROPS,
  UPDATE_USERS_PROPS,
} from "../Redux/Actions/types";
const Profile = ({ history, profileModal, general }) => {
  useEffect(() => {
    general(
      [
        {
          prop: "profileModal",
          value: {
            isOpen: false,
            data: {
              firstName: getUser().data.firstName,
              lastName: getUser().data.lastName,
              userName: getUser().data.userName,
              email: getUser().data.email,
              password: getUser().data.password,
              passwordConfirmation: getUser().data.passwordConfirmation,
              gender: getUser().data.gender,
              birthDate: getUser().data.birthDate,
              country: getUser().data.country,
              phoneNumber: getUser().data.phoneNumber,
              id: getUser().data.id,
            },
          },
        },
      ],
      UPDATE_GENERAL_PROPS
    );
  }, []);

  const handleEdit = (e) => {
    e.preventDefault();

    general([{ prop: "form", value: getUser().data }], UPDATE_USERS_PROPS);
    history.push("/dashboard/edituser");
  };
  return (
    <div className="page-container">
      <Row>
        <Col
          xl={8}
          lg={8}
          md={8}
          sm={12}
          xs={12}
          className="mx-auto text-center"
        >
          <Card className="create_card mt-3">
            <Row>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="mt-5 mx-auto"
              >
                <button className="save_btn" onClick={handleEdit}>
                  {local.EditData}
                </button>
              </Col>
            </Row>
            <UserProfile data={profileModal.data} general={general} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = ({ UsersR, GeneralR }) => {
  return {
    lightbox: GeneralR.lightbox,
    profileModal: GeneralR.profileModal,
  };
};
export default connect(mapStateToProps, actions)(Profile);
