import React, { useState } from "react";
import { Button, Modal, Row, Col, Input } from "reactstrap";
import { MdDateRange } from "react-icons/md";
import { local } from "../lang/local";
import Flatpickr from "react-flatpickr";
import { dayOptions, getDefaultValue } from "./SelectFunctions";
import { getWorkingHours } from "./GeneralFunctions";

const OpenningHours = ({ general, WorkingHours, action }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const renderDateTimes = () => {
    if (WorkingHours && Array.isArray(WorkingHours)) {
      if (WorkingHours.length > 0) {
        return WorkingHours.map((dt, index) => {
          return (
            <React.Fragment key={index}>
              <Row className="mt-3">
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div className="container_add_input2">
                    <div className="form_select_container">
                      <Input
                        className="removed_input_style"
                        value={getDefaultValue(dt.day, dayOptions)?.label}
                        disabled={true}
                      />
                    </div>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div className="container_add_input2">
                    <Flatpickr
                      data-enable-time={false}
                      value={dt.from}
                      options={{
                        enableTime: true,
                        altInput: true,
                        noCalendar: true,
                      }}
                      onChange={(date) => {
                        general(
                          [
                            {
                              prop: "data.form.WorkingHours." + index + ".from",
                              value: new Date(date[0]).toLocaleTimeString(),
                            },
                          ],
                          action
                        );
                      }}
                      placeholder={local.From}
                      className="add_input"
                    />
                  </div>
                </Col>

                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div className="container_add_input2">
                    <Flatpickr
                      data-enable-time={false}
                      value={dt.to}
                      options={{
                        enableTime: true,
                        altInput: true,
                        noCalendar: true,
                      }}
                      onChange={(date) => {
                        general(
                          [
                            {
                              prop: "data.form.WorkingHours." + index + ".to",
                              value: new Date(date[0]).toLocaleTimeString(),
                            },
                          ],
                          action
                        );
                      }}
                      placeholder={local.To}
                      className="add_input"
                    />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          );
        });
      }
    }
  };

  return (
    <>
      <div className="container_add_input mx-auto">
        <MdDateRange
          className="strik_icon2 cursor"
          color="gray"
          size={17}
          onClick={toggle}
        />
        <Input
          className="add_input cursor"
          placeholder={local.OpenningHours}
          defaultValue={getWorkingHours(WorkingHours)}
          value={getWorkingHours(WorkingHours)}
          onClick={toggle}
        />
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div className="ml-3 mr-3 mt-4 mb-4">
          {renderDateTimes()}
          <Row className="mt-3">
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="mt-5 center"
            >
              <Button
                className="save_btn"
                style={{ width: "220px" }}
                onClick={toggle}
              >
                {local.Done}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default OpenningHours;
