import {
  UPDATE_PLACES_PROPS,
  CLEAR_PLACES_STATE,
  MERGE_PLACES_PROPS,
  DELETE_PLACES_PROPS,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  places: {
    Registered: null,
    unRegistered: null,

    isLoading: false,
    isLoadingCreate: false,
    isOpen: false,
    title: "",
    type: "",
    icon: null,
    searchValue: "",
    searchResult: [],
  },

  lists: {
    isOpenSaveList: false,
    isOpenAddList: false,
    list_item: {
      isOpen: false,
      registered_items: [],
      title: "",
      icon: "",
    },
  },
  addListType: { isOpen: false, name: "" },

  add_place: {
    form: {
      name: "",
      info: { placeId: "", address: "" },
      geometry: [],
      type: "",
    },
    isLoading: false,
  },
};

const PlaceR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PLACES_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_PLACES_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_PLACES_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_PLACES_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default PlaceR;
