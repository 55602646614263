import React from "react";
import egy_open from "../../assets/img/egy_open.png";
import eye_close from "../../assets/img/eye_close.png";
export const renderEyePassword = (isShown, setShown) => {
  if (isShown === true) {
    return (
      <img
        onClick={() => setShown(false)}
        alt="egy_open"
        className="eye_pass cursor"
        style={{ marginInlineEnd: "20px" }}
        src={egy_open}
      />
    );
  } else {
    return (
      <img
        onClick={() => setShown(true)}
        src={eye_close}
        alt="egy_open"
        className="eye_pass cursor"
        style={{ marginInlineEnd: "20px" }}
      />
    );
  }
};
