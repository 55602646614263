import React, { Component } from 'react';
import { Card, Col, Row } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import Gallery from '../../General/Gallery';
import { getPoints } from '../../General/MapFunctions';
import { WorkingHoursModal } from '../../General/TableFunctions';
import { getUser } from '../../utils/StorageFunctions';
import ProfileModal from '../../utils/ProfileModal';
import BranchProfile from './BranchProfile';
import BranchSearch from './BranchSearch';
import WebList from './WebList';
import MobileList from './MobileList';
import TooMap from '../../General/TooMap';

import {
  CLEAR_BRANCHES_STATE,
  UPDATE_GENERAL_PROPS,
} from '../../Redux/Actions/types';

class Branches extends Component {
  componentDidMount() {
    this.props.general([], CLEAR_BRANCHES_STATE);

    if (getUser() === null) {
      this.props.history.push('/login');
    } else {
      this.props.general(
        [{ prop: 'rows_collabse', value: [] }],
        UPDATE_GENERAL_PROPS
      );
    }
  }

  handlePlaceSearch = (lat, lng, address, layer) => {
    const { general } = this.props;

    general(
      [{ prop: 'nearest_point', value: { address, lat, lng, layer } }],
      UPDATE_GENERAL_PROPS
    );
  };

  renderMap = () => {
    const {
      data,
      location,
      general,
      zoomToShape,
      shapeItem,
      // mapSearch,
      // ipAddress,
      // getSocketResult,
      // myPosition,
      nearest_point,
    } = this.props;
    if (data.items) {
      return (
        <Card className='mapCard'>
          {/* <SearchBox
            mapSearch={mapSearch}
            general={general}
            action={UPDATE_GENERAL_PROPS}
            propObj={[
              {
                prop: "nearest_point",
                value: { address: "", lat: "", lng: "", layer: "" },
              },
            ]}
            myPosition={myPosition}
            ipAddress={ipAddress}
            getSocketResult={getSocketResult}
            handlePlaceSearch={this.handlePlaceSearch}
            nearest_point={nearest_point}
            entries={data.items}
            actionType={UPDATE_BRANCHES_PROPS}
          /> */}

          <TooMap
            location={location}
            markers_list={getPoints(data.items, 'locationText')}
            general={general}
            zoomToShape={zoomToShape}
            shapeItem={shapeItem}
            nearest_point={nearest_point}
          />
          {/* <GeneralMap
            location={location}
            markers_list={getPoints(data.items, "locationText")}
            general={general}
            zoomToShape={zoomToShape}
            shapeItem={shapeItem}
            nearest_point={nearest_point}
          /> */}
        </Card>
      );
    }
  };
  render() {
    const {
      history,
      data,
      DeleteBranch,
      general,
      deleteMultipleBranches,
      fetchBranches,
      venuesOptions,
      lightbox,
      workingDaysModal,
      profileModal,
      rows_collabse,
      location,
      loadVenueOptions,
    } = this.props;

    return (
      <div className='page-container'>
        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={local.BranchProfile}
          Children={BranchProfile}
          location={location}
          workingDaysModal={workingDaysModal}
        />
        <Gallery lightbox={lightbox} general={general} />
        <WorkingHoursModal
          workingDaysModal={workingDaysModal}
          general={general}
        />

        <ProfileModal
          profileModal={profileModal}
          workingDaysModal={workingDaysModal}
          general={general}
          title={local.BranchProfile}
          Children={BranchProfile}
        />

        <Row>
          <Col>
            <BranchSearch
              filterArray={data.filterArray}
              venuesOptions={venuesOptions}
              fetchBranches={fetchBranches}
              isLoading={data.isLoadingSearch}
              general={general}
              loadVenueOptions={loadVenueOptions}
            />
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mobile_col'>
            <MobileList
              history={history}
              data={data}
              DeleteBranch={DeleteBranch}
              general={general}
              deleteMultipleBranches={deleteMultipleBranches}
              fetchBranches={fetchBranches}
            />
          </Col>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ height: '100vh' }}
          >
            <div
              className='table_container'
              style={{
                width:
                  rows_collabse.length > 0
                    ? rows_collabse.find((col) => col === true)
                      ? '98%'
                      : 'fit-content'
                    : 'fit-content',
              }}
            >
              <WebList
                history={history}
                data={data}
                DeleteBranch={DeleteBranch}
                general={general}
                deleteMultipleBranches={deleteMultipleBranches}
                fetchBranches={fetchBranches}
                rows_collabse={rows_collabse}
              />
            </div>

            {this.renderMap()}
          </Col>
        </Row>
      </div>
    );
  }
}

const GeneralMaptateToProps = ({ BranchesR, VenuesR, GeneralR }) => {
  return {
    data: BranchesR.data,
    venuesOptions: VenuesR.venuesOptions,
    lightbox: GeneralR.lightbox,
    location: GeneralR.location,
    workingDaysModal: GeneralR.workingDaysModal,
    profileModal: GeneralR.profileModal,
    rows_collabse: GeneralR.rows_collabse,
    zoomToShape: GeneralR.zoomToShape,
    shapeItem: GeneralR.shapeItem,
    mapSearch: GeneralR.mapSearch,
    myPosition: GeneralR.myPosition,
    ipAddress: GeneralR.ipAddress,
    nearest_point: GeneralR.nearest_point,
  };
};
export default connect(GeneralMaptateToProps, actions)(Branches);
