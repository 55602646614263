import {
  showConfirmMessage,
  showSuccessedConfirm,
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { SendRequest } from "./api";
import { UPDATE_DIRECTION_MENU_PROPS, UPDATE_PLACES_PROPS } from "./types";

export const createPlace = (PlaceForm) => (dispatch) => {
  dispatch({
    type: UPDATE_PLACES_PROPS,
    payload: [{ prop: "user_Place.isLoading ", value: true }],
  });
  let url = "/UserPlacesUnregistered";

  if (PlaceForm.gisId !== undefined) {
    url = "/UserPlacesRegistered";
  }
  SendRequest()
    .post(url, PlaceForm)
    .then((res) => {
      if (res.status === 200) {
        showSuccessedConfirm(local.AddedSuccessfully);
      }
      if (PlaceForm.gisId !== undefined) {
        dispatch(getRegisteredPlaces(1));
      } else {
        dispatch(getUnRegisterdPlaces(1));
      }
      dispatch({
        type: UPDATE_PLACES_PROPS,
        payload: [{ prop: "user_Place.isLoading ", value: false }],
      });
    })
    .catch((e) => {
      showConfirmMessage(Object.values(e)[2].data.message, "error");

      console.log(e);
      dispatch({
        type: UPDATE_PLACES_PROPS,
        payload: [{ prop: "user_Place.isLoading ", value: false }],
      });
    })
    .finally();
};

export const getRegisteredPlaces = (page) => (dispatch) => {
  SendRequest()
    .post(`/UserPlacesRegistered/Get?page=${page}`, [])
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: UPDATE_PLACES_PROPS,
          payload: [
            {
              prop: "places.Registered",
              value: response.data.data,
            },
          ],
        });
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const getUnRegisterdPlaces = (page) => (dispatch) => {
  SendRequest()
    .post(`/UserPlacesUnregistered/Get?page=${page}`, [])
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data) {
          if (res.data.data.length > 0) {
            let home_data = res.data.data.find((item) => item.name === "Home");
            let work_data = res.data.data.find((item) => item.name === "Work");
            if (home_data !== undefined) {
              dispatch({
                type: UPDATE_DIRECTION_MENU_PROPS,
                payload: [
                  {
                    prop: "search_direction.home_info",
                    value: home_data,
                  },
                ],
              });
            }
            if (work_data !== undefined) {
              dispatch({
                type: UPDATE_DIRECTION_MENU_PROPS,
                payload: [
                  {
                    prop: "search_direction.work_info",
                    value: work_data,
                  },
                ],
              });
            }
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const deleteUnregisterPlace = (id, prop) => (dispatch) => {
  SendRequest()
    .delete(`/UserPlacesUnregistered/${id}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.message === "deleted successfully") {
          dispatch({
            type: UPDATE_DIRECTION_MENU_PROPS,
            payload: [
              {
                prop: prop,
                value: null,
              },
            ],
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};
