import React, { Component } from "react";
import { Col, Row, Card, Input, Form, Spinner, Button } from "reactstrap";
import * as actions from "../../Redux/Actions/index";
import { connect } from "react-redux";
import { getLanguage, local } from "../../lang/local";
import Dropzone from "react-dropzone";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";

import image_icon from "../../assets/img/image_icon.png";
import strik from "../../assets/img/strik.png";
import {
  CLEAR_VENUES_STATE,
  UPDATE_VENUES_PROPS,
} from "../../Redux/Actions/types";
import { handleSave, renderPhoto } from "./VenuesFunctions";
import {
  handleArabicNameChange,
  handleEnglishNameChange,
  renderErrorMessage,
} from "../../General/GeneralFunctions";
import { getUser } from "../../utils/StorageFunctions";

import Gallery from "../../General/Gallery";
class CreateVenue extends Component {
  componentDidMount() {
    if (getUser() === null) {
      this.props.history.push("/login");
    }
    this.props.general([], CLEAR_VENUES_STATE);
  }

  render() {
    const {
      form,
      isLoadingCreate,
      general,
      addVenue,
      lightbox,
      errorMessages,
    } = this.props;
    return (
      <div className="page-container">
        <Gallery lightbox={lightbox} general={general} />

        <Row>
          <Col
            xl={8}
            lg={8}
            md={8}
            sm={12}
            xs={12}
            className="mx-auto text-center"
          >
            <h3 className="page_create_title">{local.AddVenue}</h3>
            <Card className="create_card">
              <Form>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {form.Logo === null ? (
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          general(
                            [
                              {
                                prop: "data.form.Logo",
                                value: acceptedFiles,
                              },
                            ],
                            UPDATE_VENUES_PROPS
                          )
                        }
                      >
                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                          <div
                            {...getRootProps()}
                            className="fullWidthHeight center cursor"
                          >
                            <input
                              {...getInputProps()}
                              multiple={false}
                              accept="image/*"
                              defaultValue={acceptedFiles}
                            />

                            <Col className="text-center">
                              <div>
                                <img src={image_icon} alt="" />
                              </div>
                            </Col>
                          </div>
                        )}
                      </Dropzone>
                    ) : (
                      <div className="center">
                        {renderPhoto(form.Logo, general)}
                      </div>
                    )}
                    {renderErrorMessage(form.Logo, errorMessages.Logo)}
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl={8}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    className="mt-3 mx-auto"
                  >
                    <div className="container_add_input mx-auto">
                      <img src={strik} alt="strik" className="strik_icon" />
                      <Input
                        className="add_input"
                        value={form.Name.en}
                        onChange={(e) =>
                          handleEnglishNameChange(
                            e,
                            general,
                            UPDATE_VENUES_PROPS,
                            "data.form.Name.en"
                          )
                        }
                        placeholder={local.EnglishName}
                      />
                    </div>

                    {renderErrorMessage(form.Name.en, errorMessages.NameEn)}
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl={8}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    className="mt-4 mx-auto"
                  >
                    <div className="container_add_input mx-auto">
                      <img src={strik} alt="strik" className="strik_icon" />
                      <Input
                        className="add_input"
                        value={form.Name.ar}
                        onChange={(e) =>
                          handleArabicNameChange(
                            e,
                            general,
                            UPDATE_VENUES_PROPS,
                            "data.form.Name.ar"
                          )
                        }
                        placeholder={local.ArabicName}
                      />
                    </div>
                    {renderErrorMessage(form.Name.ar, errorMessages.NameAr)}
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl={8}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    className="mt-4 mx-auto"
                  >
                    <div className="container_add_input mx-auto">
                      <PhoneInput
                        lang={getLanguage() === "ar" ? ar : ""}
                        country="sa"
                        // onlyCountries={[
                        //   "eg",
                        //   "sa",
                        //   "ae",
                        //   "ku",
                        //   "lb",
                        //   "om",
                        //   "bh",
                        // ]}
                        masks={{
                          eg: ".. ........",
                          sa: ".. ... ....",
                          ae: ".. .......",
                        }}
                        countryCodeEditable={false}
                        value={form.Phone}
                        onChange={(mobile) =>
                          general(
                            [
                              {
                                prop: "data.form.Phone",
                                value: mobile,
                              },
                            ],
                            UPDATE_VENUES_PROPS
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={8}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    className="mt-5 mx-auto"
                  >
                    <Button
                      color="primary"
                      className="save_btn"
                      onClick={(e) =>
                        handleSave(e, addVenue, form, general, null)
                      }
                    >
                      {isLoadingCreate === true ? <Spinner /> : local.Save}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ VenuesR, GeneralR }) => {
  return {
    form: VenuesR.data.form,
    errorMessages: VenuesR.data.errorMessages,
    isLoadingCreate: VenuesR.isLoadingCreate,
    location: GeneralR.location,
    lightbox: GeneralR.lightbox,
  };
};
export default connect(mapStateToProps, actions)(CreateVenue);
