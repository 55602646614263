import {
  convertToFormData,
  showConfirmMessage,
  showSuccessedConfirm,
  filterWorkingHours,
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { SendRequest } from "./api";
import {
  DELETE_DISTRIBUTORS_PROPS,
  UPDATE_DISTRIBUTORS_PROPS,
  UPDATE_BUSINESS_PROPS,
} from "./types";

export const fetchDistributors = (filterArray, page) => (dispatch) => {
  if (filterArray.length > 1) {
    dispatch({
      type: UPDATE_DISTRIBUTORS_PROPS,
      payload: [{ prop: "data.isLoadingSearch", value: true }],
    });
  }
  dispatch({
    type: UPDATE_DISTRIBUTORS_PROPS,
    payload: [{ prop: "data.isLoading", value: true }],
  });
  SendRequest()
    .post(`/Branch/Get?page=${page}`, filterArray)
    .then((res) => {
      if (res.status === 200) {
        let pagination = Object.assign({}, res.data.data);
        delete pagination.items;
        if (res.data.data.items.length === 0 && filterArray.length > 1) {
          showConfirmMessage(local.NoSearch, "error");
        }
        dispatch({
          type: UPDATE_DISTRIBUTORS_PROPS,
          payload: [
            { prop: "data.items", value: res.data.data.items },
            { prop: "data.pagination", value: pagination },
          ],
        });
      }

      dispatch({
        type: UPDATE_DISTRIBUTORS_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_DISTRIBUTORS_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const addDistributor = (formValues, venueId) => (dispatch) => {
  dispatch({
    type: UPDATE_DISTRIBUTORS_PROPS,
    payload: [{ prop: "isLoadingCreate", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);
  newFormValues.WorkingHours = filterWorkingHours(newFormValues.WorkingHours);

  let formData = convertToFormData(newFormValues);
  SendRequest()
    .post(`/Branch/${venueId}`, formData)
    .then((res) => {
      if (res.status === 200) {
        showSuccessedConfirm(local.AddedSuccessfully);

        dispatch({
          type: UPDATE_DISTRIBUTORS_PROPS,
          payload: [
            {
              prop: "data.form",
              value: {
                Name: { en: "", ar: "" },
                WorkingHours: [
                  { day: "Monday", from: null, to: null },
                  { day: "Tuesday", from: null, to: null },
                  { day: "Wednesday", from: null, to: null },
                  { day: "Thursday", from: null, to: null },
                  { day: "Friday", from: null, to: null },
                  { day: "Saturday", from: null, to: null },
                  { day: "Sunday", from: null, to: null },
                ],
                Photos: [],
                Phone: "",
                Lat: 0,
                Lng: 0,
                Type: 1,
              },
            },

            {
              prop: "venueId",
              value: null,
            },
          ],
        });

        dispatch({
          type: UPDATE_BUSINESS_PROPS,
          payload: [
            {
              prop: "data.form",
              value: {
                Name: { en: "", ar: "" },
                WorkingHours: [
                  { day: "Monday", from: null, to: null },
                  { day: "Tuesday", from: null, to: null },
                  { day: "Wednesday", from: null, to: null },
                  { day: "Thursday", from: null, to: null },
                  { day: "Friday", from: null, to: null },
                  { day: "Saturday", from: null, to: null },
                  { day: "Sunday", from: null, to: null },
                ],
                Photos: [],
                Phone: "",
                Lat: 0,
                Lng: 0,
                Type: null,
              },
            },

            {
              prop: "data.venueId",
              value: null,
            },
          ],
        });
      }
      dispatch({
        type: UPDATE_DISTRIBUTORS_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
    })
    .catch((e) => {
      if (Object.values(e)[2].data) {
        if (Object.values(e)[2].data.message) {
          showConfirmMessage(Object.values(e)[2].data.message, "error");
        }
      }
      dispatch({
        type: UPDATE_DISTRIBUTORS_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const updateDistributor = (formValues, history) => (dispatch) => {
  dispatch({
    type: UPDATE_DISTRIBUTORS_PROPS,
    payload: [{ prop: "isLoadingCreate", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);
  newFormValues.WorkingHours = filterWorkingHours(newFormValues.WorkingHours);

  let formData = convertToFormData(newFormValues);
  SendRequest()
    .put(`/Branch?branchId=${newFormValues.id}`, formData)
    .then((res) => {
      if (res.status === 200) {
        showSuccessedConfirm(local.SuccessfullyUpdated);

        history.push("/dashboard/distributors");
      }
      dispatch({
        type: UPDATE_DISTRIBUTORS_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_DISTRIBUTORS_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const DeleteDistributor = (id, index) => (dispatch) => {
  dispatch({
    type: UPDATE_DISTRIBUTORS_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: null }],
  });
  SendRequest()
    .delete(`Branch?branchId=${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_DISTRIBUTORS_PROPS,
          payload: [
            {
              prop: "data.items." + index,
            },
          ],
        });
        showSuccessedConfirm(local.DeletedSuccessfully);
      }
      dispatch({
        type: UPDATE_DISTRIBUTORS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
    })
    .catch((err) => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_DISTRIBUTORS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
      console.log(err);
    })
    .finally();
};

export const deleteMultipleDistributors = (ids, setChecked) => (dispatch) => {
  dispatch({
    type: UPDATE_DISTRIBUTORS_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: true }],
  });

  SendRequest()
    .delete(`Branch?branchId=${ids[0]}`)
    .then((res) => {
      if (res.status === 200) {
        ids.shift();

        if (ids.length > 0) {
          dispatch(deleteMultipleDistributors(ids, setChecked));
        } else {
          setChecked([]);
          showSuccessedConfirm(local.DeletedSuccessfully);
          dispatch(fetchDistributors([], 1));
        }
      }
      dispatch({
        type: UPDATE_DISTRIBUTORS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
    })
    .catch((err) => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_DISTRIBUTORS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
      console.log(err);
    })
    .finally();
};
