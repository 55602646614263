import React from 'react';
import { Card, Row, Col, Button } from 'reactstrap';
import { rightStyle } from '../../../General/GeneralStyle';
import { webAsideCardStyle } from '../../../utils/MapViewStyle';
import { local } from '../../../lang/local';
import Share from '../../../General/Share';
import { renderRowHeader } from '../../../utils/MapViewFunctions';
import AddPlace from '../Places/AddPlace';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/Actions/index';
import PlaceList from '../Contribution/PlaceList';
import { handleDirection } from './LocationFunctions';
import LocationDetails from './LocationDetails';
const WebLocation = ({
  general,
  closedItems,
  handleAddPlace,
  location_data,
  addPlaceButton,
  EditPlaceButton,
  findContribution,
  contribuationForm,
  editedPlaces,
  isSelected,
  setSelected,
  renderDistance,
  getImages,
  getImagesUrl,
  myPosition,
  ipAddress,
  startLocation,
  endLocation,
  getSocketResult,
  search_direction,
  add_place,
  createPlace,
  addListType,
  RegPlcaeImage,
  ShareImage,
}) => {
  return (
    <Card className='web_view_card' style={webAsideCardStyle}>
      {renderRowHeader(closedItems, general)}

      <div>
        <Row className='mt-5'>
          <Col xl={9} lg={9} md={9} sm={12} xs={12} className='mx-auto'>
            {location_data.icon_type === 'click' ? (
              <div className='flex'>
                {addPlaceButton(location_data.address, handleAddPlace)}

                <PlaceList
                  TagItem={EditPlaceButton}
                  editedPlaces={editedPlaces}
                  findContribution={findContribution}
                  location_data={location_data}
                  contribuationForm={contribuationForm}
                  setSelected={setSelected}
                  isSelected={isSelected}
                  general={general}
                />
              </div>
            ) : null}
          </Col>
          <Col xl={3} className='mx-auto'>
            <p className='distance' style={rightStyle}>
              {renderDistance()}
            </p>
          </Col>
        </Row>

        <Row className='mt-3 ml-1 mr-1'>
          <Col>
            <LocationDetails
              address={location_data.address}
              lat={location_data.lat}
              lng={location_data.lng}
            />
          </Col>
        </Row>
        <Row className='mt-3 ml-1 mr-1'>{getImages()}</Row>

        {location_data.images_names ? (
          location_data.images_names.length > 0 ? (
            <Row>
              <Col className='mt-3 ml-2 mr-2 text-center cursor'>
                <p
                  className='search_result_clear_text cursor'
                  onClick={(e) => {
                    e.preventDefault();
                    getImagesUrl(location_data.images_names, 0);
                  }}
                >
                  {local.More}
                </p>
              </Col>
            </Row>
          ) : null
        ) : null}

        <Row className='mt-4 mb-1 ml-1 mr-1'>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className='mx-auto center'
          >
            <Button
              className='dir_btn'
              onClick={(e) =>
                handleDirection(
                  e,
                  myPosition,
                  ipAddress,
                  location_data,
                  general,
                  getSocketResult
                )
              }
            >
              {local.Directions}
            </Button>

            {location_data.address ? (
              <span style={{ marginInlineStart: '20px' }}>
                <AddPlace
                  general={general}
                  addListType={addListType}
                  add_place={add_place}
                  location_data={location_data}
                  createPlace={createPlace}
                  TagItem={RegPlcaeImage}
                  search_direction={search_direction}
                  startLocation={startLocation}
                  endLocation={endLocation}
                />
              </span>
            ) : null}

            <span style={{ marginInlineStart: '20px' }}>
              <Share TagItem={ShareImage} />
            </span>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

const mapStateToProps = ({
  LocationR,
  GeneralR,
  ContribuationsR,
  PlaceR,
  DirectionR,
}) => {
  return {
    location_data: LocationR.location_data,
    myPosition: GeneralR.myPosition,
    ipAddress: GeneralR.ipAddress,
    lightbox: GeneralR.lightbox,
    editedPlaces: ContribuationsR.editedPlaces,
    contribuationForm: ContribuationsR.data.form,
    add_place: PlaceR.add_place,
    search_direction: DirectionR.search_direction,
    addListType: PlaceR.addListType,
    startLocation: DirectionR.startLocation,
    endLocation: DirectionR.endLocation,
  };
};

export default connect(mapStateToProps, actions)(WebLocation);
