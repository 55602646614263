import { Card, Col, Row } from "reactstrap";
import { UPDATE_DIRECTION_MENU_PROPS } from "../../../Redux/Actions/types";
import React from "react";
import {
  columnAlignRight,
  rightStyle,
  leftStyle,
} from "../../../General/GeneralStyle";
import Info_circle_inactive from "../../../assets/img/Info_circle_inactive.png";
import Info_circle_active from "../../../assets/img/Info_circle_active.png";
import left from "../../../assets/img/directions/left.png";
import slight_left from "../../../assets/img/directions/slight_left.png";
import right from "../../../assets/img/directions/right.png";
import straight from "../../../assets/img/directions/straight.png";
import uturn_left from "../../../assets/img/directions/uturn_left.png";
import slight_right from "../../../assets/img/directions/slight_right.png";
import { local } from "../../../lang/local";
import { convert_to_lat_lng_point } from "../../../General/GeneralFunctions";

export const renderClassInfo = (
  item,
  value,
  type,
  general,
  selecting_routing
) => {
  return (
    <Col
      xl={4}
      lg={4}
      md={4}
      sm={4}
      xs={4}
      className="padding0_margin0 mt-2"
      key={"x" + type}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          selecting_routing !== null
            ? general(
                [
                  { prop: "direction_data.active_class", value: type },
                  { prop: "direction_data.selecting_routing", value: null },
                ],
                UPDATE_DIRECTION_MENU_PROPS
              )
            : general(
                [{ prop: "direction_data.active_class", value: type }],
                UPDATE_DIRECTION_MENU_PROPS
              );
        }}
        className={
          value === type
            ? "class_type_container_active cursor"
            : "class_type_container_inactive cursor"
        }
        style={{ overflow: "auto" }}
      >
        <div>
          <img
            onClick={(e) => {
              e.preventDefault();
              selecting_routing !== null
                ? selecting_routing === item
                  ? general(
                      [
                        { prop: "direction_data.active_class", value: type },
                        {
                          prop: "direction_data.selecting_routing",
                          value: null,
                        },
                        {
                          prop: "direction_data.start_location",
                          value: null,
                        },
                        {
                          prop: "direction_data.end_location",
                          value: null,
                        },
                      ],
                      UPDATE_DIRECTION_MENU_PROPS
                    )
                  : general(
                      [
                        { prop: "direction_data.active_class", value: type },
                        {
                          prop: "direction_data.selecting_routing",
                          value: item,
                        },
                        {
                          prop: "direction_data.start_location",
                          value: item.legs[0].steps[0],
                        },
                        {
                          prop: "direction_data.end_location",
                          value:
                            item.legs[0].steps[item.legs[0].steps.length - 1],
                        },
                      ],
                      UPDATE_DIRECTION_MENU_PROPS
                    )
                : general(
                    [
                      { prop: "direction_data.active_class", value: type },
                      {
                        prop: "direction_data.selecting_routing",
                        value: item,
                      },
                      {
                        prop: "direction_data.start_location",
                        value: item.legs[0].steps[0],
                      },
                      {
                        prop: "direction_data.end_location",
                        value:
                          item.legs[0].steps[item.legs[0].steps.length - 1],
                      },
                    ],
                    UPDATE_DIRECTION_MENU_PROPS
                  );
            }}
            src={value === type ? Info_circle_active : Info_circle_inactive}
            alt="Info_circle cursor"
            // style={rightStyle}
            className="ml-2 mr-2 Info_circle"
          />
        </div>

        <div className="mt-2 ml-2 mr-2">
          <p className={value === type ? "time_active" : "time_inactive"}>
            {Math.round(item.duration / 60) + " min"}
          </p>
        </div>
        <div className="mt-2 ml-2 mr-2">
          <p
            className={value === type ? "distance_active" : "distance_inactive"}
          >
            {Math.round(item.distance * 0.001) + " Km"}
          </p>
        </div>
      </div>
    </Col>
  );
};
const handleRoutingType = (
  e,
  value,
  type,
  setActive,
  getSocketResult,
  startLocation,
  endLocation
) => {
  e.preventDefault();
  if (value !== type) {
    setActive(type);

    getSocketResult(
      null,
      {
        start: startLocation.lat,
        end: endLocation.lat,
      },
      {
        start: startLocation.lng,
        end: endLocation.lng,
      },
      "routing",
      type
    );
  }
};
export const renderClassType = (
  image_active,
  image_inactive,
  value,
  setActive,
  type,
  getSocketResult,
  startLocation,
  endLocation
) => {
  return (
    <Col xl={4} lg={4} md={4} sm={4} xs={4} className="padding0_margin0">
      <div
        onClick={(e) =>
          handleRoutingType(
            e,
            value,
            type,
            setActive,
            getSocketResult,
            startLocation,
            endLocation
          )
        }
        className={
          value === type
            ? "vector_container_active"
            : "vector_container_inactive"
        }
      >
        <img src={value === type ? image_active : image_inactive} alt="img" />
      </div>
    </Col>
  );
};

export const renderArrawDirectionItem = (image, first_text, end_text) => {
  return (
    <Card className="card_direction">
      <Row className="dir_card_row alignItem ml-3 mr-3 mt-4 mb-4">
        <Col xl={2} lg={2} md={2} sm={2} xs={2} className="center">
          <img src={image} alt="" width="30" height="30" />
        </Col>
        <Col xl={9} lg={9} md={9} sm={9} xs={9}>
          <p className="your_location_text">
            {first_text}
            <br />
            {end_text !== undefined ? (
              <span className="loc_dir_distance">{end_text}</span>
            ) : null}
          </p>
        </Col>
      </Row>
    </Card>
  );
};

export const renderDirectionsArrraws = (selecting_routing) => {
  if (selecting_routing) {
    let directions = selecting_routing.legs[0].steps;
    if (directions) {
      if (directions.length > 0) {
        return directions.map((dir, index) => {
          if (index !== directions.length - 1) {
            if (dir.maneuver.modifier === "left") {
              return renderArrawDirectionItem(
                left,
                dir.instruction,
                dir.distance + " m"
              );
            }

            if (dir.maneuver.modifier === "slight left") {
              return renderArrawDirectionItem(
                slight_left,
                dir.instruction,
                dir.distance + " m"
              );
            }
            if (dir.maneuver.modifier === "slight right") {
              return renderArrawDirectionItem(
                slight_right,
                dir.instruction,
                dir.distance + " m"
              );
            }

            if (dir.maneuver.modifier === "right") {
              return renderArrawDirectionItem(
                right,
                dir.instruction,
                dir.distance + " m"
              );
            }
            if (dir.maneuver.modifier === "straight") {
              return renderArrawDirectionItem(
                straight,
                dir.instruction,
                dir.distance + " m"
              );
            }
            if (dir.maneuver.modifier === "uturn") {
              return renderArrawDirectionItem(
                uturn_left,
                dir.instruction,
                dir.distance + " m"
              );
            }
          }
        });
      }
    }
  }
};


export const onChooseLocation = (
  e,
  type,
  value,
  general,
  myPosition,
  ipAddress
) => {
  e.preventDefault();

  let lat = ipAddress.lat;
  let lng = ipAddress.lng;
  if (myPosition.allowLocation === true) {
    lat = myPosition.lat;
    lng = myPosition.lng;
  }

  if (type === "start") {
    if (value === "map") {
      general(
        [
          { prop: "startLocation.dragableMarker.lat", value: lat },
          { prop: "startLocation.dragableMarker.lng", value: lng },
        ],
        UPDATE_DIRECTION_MENU_PROPS
      );
    }

    general(
      [
        { prop: "startLocation.lat", value: lat },
        { prop: "startLocation.lng", value: lng },
        { prop: "startLocation.title", value: local.MyLocation },
        { prop: "search_direction.isOpen", value: false },
        { prop: "direction_data.isOpen", value: true },
      ],
      UPDATE_DIRECTION_MENU_PROPS
    );
  } else {
    if (value === "map") {
      general(
        [
          { prop: "endLocation.dragableMarker.lat", value: lat },
          { prop: "endLocation.dragableMarker.lng", value: lng },
        ],
        UPDATE_DIRECTION_MENU_PROPS
      );
    }

    general(
      [
        { prop: "endLocation.lat", value: lat },
        { prop: "endLocation.lng", value: lng },
        { prop: "endLocation.title", value: local.MyLocation },
        { prop: "search_direction.isOpen", value: false },
        { prop: "direction_data.isOpen", value: true },
      ],
      UPDATE_DIRECTION_MENU_PROPS
    );
  }
};

export const onChooseHomeLocation = (e, type, general, place) => {
  e.preventDefault();

  let lat = convert_to_lat_lng_point(place.geometryText, "lat");
  let lng = convert_to_lat_lng_point(place.geometryText, "lng");

  if (type === "start") {
    general(
      [
        { prop: "startLocation.lat", value: lat },
        { prop: "startLocation.lng", value: lng },
        { prop: "startLocation.title", value: place.name },
        { prop: "search_direction.isOpen", value: false },
        { prop: "direction_data.isOpen", value: true },
      ],
      UPDATE_DIRECTION_MENU_PROPS
    );
  } else {
    general(
      [
        { prop: "endLocation.lat", value: lat },
        { prop: "endLocation.lng", value: lng },
        { prop: "endLocation.title", value: place.name },
        { prop: "search_direction.isOpen", value: false },
        { prop: "direction_data.isOpen", value: true },
      ],
      UPDATE_DIRECTION_MENU_PROPS
    );
  }
};
