import {
  UPDATE_COMPLAINT_TYPE_PROPS,
  CLEAR_COMPLAINT_TYPE_STATE,
  MERGE_COMPLAINT_TYPE_PROPS,
  DELETE_COMPLAINT_TYPE_PROPS,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    items: null,
    pagination: null,
    filterArray: [],
    form: {
      Name: { en: "", ar: "" },
      Icon: null,
    },

    allComplaintTypes: [],

    errorMessages: {
      NameEn: "",
      NameAr: "",
      Icon: "",
    },

    form_label: null,

    isLoadingCreate: false,
    isLoading: false,
    active_cards: [],
  },
  ComplaintTypesOptions: [],
};

const ComplaintTypeR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_COMPLAINT_TYPE_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_COMPLAINT_TYPE_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_COMPLAINT_TYPE_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_COMPLAINT_TYPE_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};

export default ComplaintTypeR;
