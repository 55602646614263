import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Card, Form } from 'reactstrap';
import mapy from '../../assets/img/auth/mapy.png';
import altawasol from '../../assets/img/auth/altawasol.png';
import { showConfirmMessage } from '../../General/GeneralFunctions';
import { local } from '../../lang/local';
import { connect } from 'react-redux';
import * as actions from '../../Redux/Actions/index';
import { UPDATE_AUTH_PROPS } from '../../Redux/Actions/types';
import { check_is_authorize } from './AuthFunctions';
import { AuthOptions } from '../../utils/AuthOptions';
import { Phone } from '../../components/Phone/Phone';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { CustomButton } from '../../components/CustomButton/CustomButton';

const ForgetPassword = ({
  history,
  form,
  forgetPassword,
  isLoading,
  general,
  country,
  getLocationWithIp,
}) => {
  useEffect(() => {
    check_is_authorize(history);

    if (!country) {
      getLocationWithIp();
    }
  }, []);
  const [sendWith, setSend] = useState('email');

  const handleForget = () => {
    if (!form.phone && !form.email) {
      showConfirmMessage(local.PhoneEmailRequired, 'error');
    } else {
      forgetPassword(form, history);
    }
  };

  return (
    <div className='login_wrapper'>
      <Container>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='center'>
            <img src={mapy} alt='mapy' width='200' />
          </Col>
        </Row>
        <Row>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className='mx-auto mt-5'>
            <Card className='create_card sign_card'>
              <AuthOptions
                setValue={setSend}
                value={sendWith}
                callback={null}
              />

              <Form>
                {sendWith === 'phone' ? (
                  <Row>
                    <Col
                      xl={8}
                      lg={8}
                      md={8}
                      sm={12}
                      xs={12}
                      className='mx-auto mt-5'
                    >
                      <Phone
                        defaultCountry={country}
                        icon={true}
                        value={form.phone}
                        onChange={(value) =>
                          general(
                            [{ prop: 'forgetForm.phone', value }],
                            UPDATE_AUTH_PROPS
                          )
                        }
                      />
                    </Col>
                  </Row>
                ) : null}

                {sendWith === 'email' ? (
                  <Row>
                    <Col
                      xl={8}
                      lg={8}
                      md={8}
                      sm={12}
                      xs={12}
                      className='mx-auto mt-5'
                    >
                      <CustomInput
                        label={local.Email}
                        icon={null}
                        type='email'
                        className='add_input'
                        value={form.email}
                        onChange={(value) =>
                          general(
                            [
                              {
                                prop: 'forgetForm.email',
                                value,
                              },
                            ],
                            UPDATE_AUTH_PROPS
                          )
                        }
                      />
                    </Col>
                  </Row>
                ) : null}

                <Row className='ml-3 mr-3 mb-5'>
                  <Col
                    xl={8}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    className='mx-auto mt-5 mb-2'
                  >
                    <CustomButton
                      label={local.SendCode}
                      handleClick={handleForget}
                      className='save_btn mx-auto'
                      isLoader={isLoading}
                    />
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='center mt-5'>
            <p className='mobile_signin_text' style={{ color: '#3B3B3B' }}>
              {local.SIGNFROM + ' '}
              <span
                className='mobile_signin_text cursor bold'
                style={{ color: '#12A9CC' }}
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/login');
                }}
              >
                {local.HERE}
              </span>
            </p>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='center'>
            <img src={altawasol} alt='mapy' />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ AuthR, GeneralR }) => {
  return {
    form: AuthR.forgetForm,
    isLoading: AuthR.isLoadingForget,
    country: GeneralR.location.country,
  };
};
export default connect(mapStateToProps, actions)(ForgetPassword);
