import {
  showConfirmMessage,
  showSuccessedConfirm
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { SendRequest } from "./api";
import { DELETE_USER_ROLES_PROPS, UPDATE_USER_ROLES_PROPS } from "./types";

export const fetchUserRoles = (user_id, isDelete) => dispatch => {
  dispatch({
    type: UPDATE_USER_ROLES_PROPS,
    payload: [{ prop: "data.isLoading", value: true }]
  });
  SendRequest()
    .get(`/Roles/User/${user_id}`)
    .then(res => {
      if (res.status === 200) {
        if (res.data.data.length === 0 && isDelete === undefined) {
          showConfirmMessage(local.NoSearch, "error");
        }
        dispatch({
          type: UPDATE_USER_ROLES_PROPS,
          payload: [{ prop: "data.items", value: res.data.data }]
        });
      }

      dispatch({
        type: UPDATE_USER_ROLES_PROPS,
        payload: [{ prop: "data.isLoading", value: false }]
      });
    })
    .catch(e => {
      dispatch({
        type: UPDATE_USER_ROLES_PROPS,
        payload: [{ prop: "data.isLoading", value: false }]
      });
    })
    .finally();
};

export const addRoleToUser = formValues => async dispatch => {
  dispatch({
    type: UPDATE_USER_ROLES_PROPS,
    payload: [{ prop: "isLoadingCreate", value: true }]
  });

  if (formValues.roles.length > 0 && formValues.users.length > 0) {
    for (let role = 0; role < formValues.roles.length > 0; role++) {
      for (let user = 0; user < formValues.users.length > 0; user++) {
        await SendRequest()
          .post(
            `/Roles/Attach/User/${formValues.roles[role].value}/${formValues.users[user].value}`
          )
          .then(res => {
            if (res.status === 200) {
              if (formValues.roles.length - 1 === role) {
                showSuccessedConfirm(local.AddedSuccessfully);

                dispatch({
                  type: UPDATE_USER_ROLES_PROPS,
                  payload: [
                    {
                      prop: "data.form",
                      value: {
                        users: [],
                        roles: []
                      }
                    }
                  ]
                });
              }
            }
            if (formValues.roles.length - 1 === role) {
              dispatch({
                type: UPDATE_USER_ROLES_PROPS,
                payload: [{ prop: "isLoadingCreate", value: false }]
              });
            }
          })
          .catch(e => {
            dispatch({
              type: UPDATE_USER_ROLES_PROPS,
              payload: [{ prop: "isLoadingCreate", value: false }]
            });
            console.log(e);
          })
          .finally();
      }
    }
  }
};

export const deleteRoleUser = (user_role_id, user_id, index) => dispatch => {
  dispatch({
    type: UPDATE_USER_ROLES_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: null }]
  });

  SendRequest()
    .delete(`/Roles/Detach/User/${user_role_id}/${user_id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_USER_ROLES_PROPS,
          payload: [
            {
              prop: "data.items." + index
            }
          ]
        });
        showSuccessedConfirm(local.DeletedSuccessfully);
      }
      dispatch({
        type: UPDATE_USER_ROLES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
    })
    .catch(err => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_USER_ROLES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
      console.log(err);
    })
    .finally();
};

export const deleteMultipleUsersRoles = (
  ids,
  setChecked,
  user_id
) => dispatch => {
  dispatch({
    type: UPDATE_USER_ROLES_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: true }]
  });

  SendRequest()
    .delete(`/Roles/Detach/User/${ids[0]}/${user_id}`)
    .then(res => {
      if (res.status === 200) {
        ids.shift();

        if (ids.length > 0) {
          dispatch(deleteMultipleUsersRoles(ids, setChecked, user_id));
        } else {
          setChecked([]);
          showSuccessedConfirm(local.DeletedSuccessfully);
          dispatch(fetchUserRoles(user_id, true));
        }
      }
      dispatch({
        type: UPDATE_USER_ROLES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
    })
    .catch(err => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_USER_ROLES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
      console.log(err);
    })
    .finally();
};

export const getRoleByUser = item => dispatch => {
  if (item.length > 0) {
    item.map((user, i) => {
      let newIndex = user.index ? user.index : i;
      SendRequest()
        .get(`/Roles/User/${user.value}`)
        .then(res => {
          if (res.status === 200) {
            dispatch({
              type: UPDATE_USER_ROLES_PROPS,
              payload: [
                {
                  prop: `data.form.users.${newIndex}.roles`,
                  value: res.data.data
                }
              ]
            });
          }
        })
        .catch(e => {})
        .finally();
    });
  }
};
