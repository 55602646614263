import axios from "axios";
import { API_HOST, MAP_USER } from "../../General/StaticKeys";

export const Request = () => {
  return axios.create({
    baseURL: API_HOST,
    headers: {},
  });
};

export const SendRequest = (tokenParm) => {
  let token;
  if (token === undefined) {
    let item = JSON.parse(localStorage.getItem(MAP_USER));
    token = item.data.token.Token;
  } else {
    token = tokenParm;
  }

  return axios.create({
    baseURL: API_HOST,
    headers: {
      Authorization: "Bearer " + token,
      contentType: "application/json",
      'Access-Control-Allow-Origin': "*"
      
    },
  });
};

export const RequestGis = () => {
  return axios.create({
    baseURL: "https://places.altawasol.app:8080/gis",
    headers: {
      key: "BpLnfgDsc2WD8F2q",
      contentType: "application/json",
    },
  });
};
export const RequestImages = () => {
  return axios.create({
    baseURL: "http://en.wikipedia.org/w",
    headers: { "Content-Type": "application/json" },
  });
};
