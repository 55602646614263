import React from "react";
import { Row, Col, Card } from "reactstrap";
import { local } from "../../../lang/local";
import { leftStyle } from "../../../General/GeneralStyle";
import { CLEAR_SEARCH_MENU_STATE } from "../../../Redux/Actions/types";
import { handlePlaceClick } from "./SearchFunctions";
const SearchResult = ({
  general,
  searchResult,
  myPosition,
  getSocketResult,
  search_data,
  ipAddress,
  searchImages,
}) => {
  const onClear = () => {
    general([], CLEAR_SEARCH_MENU_STATE);
  };

  const renderCardItem = () => {
    if (searchResult) {
      if (searchResult.length > 0) {
        return (
          <div>
            <Row>
              {searchResult.map((result, index) => {
                let label = result.label.split(",");

                let desc = label.pop().toString();
                return (
                  <Col
                    className="mt-3"
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    key={index}
                  >
                    <Card
                      className="search_result_card_item center cursor"
                      onClick={(e) =>
                        handlePlaceClick(
                          e,
                          result,
                          general,
                          getSocketResult,
                          myPosition,
                          ipAddress,
                          searchImages
                        )
                      }
                    >
                      <Row className="alignItem fullWidthHeight center">
                        {/* <Col
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className="center"
                        style={{ marginInlineStart: "20px" }}
                      >
                        <img
                          src={fast_food}
                          className="img_search"
                          alt="img_search"
                        />
                      </Col> */}
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="text-center"
                        >
                          <div className="inline_tag">
                            <span className="place_name">
                              {label.toString()}
                            </span>
                            <p className="city_name">{desc}</p>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      }
    }
  };
  return (
    <div className="mt-4 ml-4 mr-4 search_result_container">
      {search_data.type === "search_data" ? (
        <Row>
          <Col className="mt-5 ml-2 mr-2">
            <p className="search_result_title" style={leftStyle}>
              {local.SearchResult}
            </p>
          </Col>
        </Row>
      ) : null}

      {renderCardItem()}

      {searchResult.length > 0 ? (
        <Row>
          <Col className="mt-3 ml-2 mr-2 text-center cursor">
            <p className="search_result_clear_text cursor" onClick={onClear}>
              {local.CLEAR}
            </p>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default SearchResult;
