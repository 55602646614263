import React from "react";
import { Card } from "reactstrap";
import avater2 from "../../assets/img/avater2.svg";
import "aos/dist/aos.css";
import PaginationItem from "../../General/PaginationItem";
import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
import { getLanguage, local } from "../../lang/local";
import { getData, renderComplaintType } from "./ComplaintFunction";
import {
  handleArrawCardClick,
  renderMultipleImages,
  renderSingleImage,
  getLatLng,
  convert_to_lat_lng_point,
} from "../../General/GeneralFunctions";
import situation from "../../assets/img/situation.png";
import Share from "../../General/Share";
import link from "../../assets/img/link.png";
import { extractLatLng } from "../../General/MapFunctions";

const WebList = ({
  data,
  general,
  active_cards,
  fetchComplaints,
  rows_collabse,
  ComplaintsOptions,
  getComplaintType,
}) => {
  const handleNameClick = (e, item) => {
    e.preventDefault();

    if (ComplaintsOptions) {
      let entry = ComplaintsOptions.items?.find(
        (comp) => comp.id === data.geoEventsTypeId
      );
      if (entry === undefined) {
        getComplaintType(item.geoEventsTypeId);
      }
    }
    general(
      [
        {
          prop: "profileModal",
          value: { isOpen: true, data: item },
        },
      ],
      UPDATE_GENERAL_PROPS
    );
  };
  const TagItem = () => {
    return <img src={link} alt="" />;
  };
  const renderTableBody = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return getData(data, active_cards).map((item, index) => {
          return (
            <React.Fragment>
              <Card
                className="header_card"
                style={{
                  width: rows_collabse[index] === true ? "100%" : "300px",
                  zIndex: 10,
                }}
                key={index}
              >
                {handleArrawCardClick(
                  rows_collabse,
                  index,
                  general,
                  item.note,
                  "marker",
                  getLatLng(item.locationText)
                )}

                <div className="flex aligmItem fullWidthHeight">
                  <ul class="parent-container">
                    <li class="child-item center">
                      <span className="tbody_container center">
                        {renderMultipleImages(item.images, general, true)}
                      </span>
                    </li>
                    <li class="child-item center">
                      <a
                        href=""
                        className="blueLight"
                        onClick={(e) => handleNameClick(e, item)}
                      >
                        <span className="tbody_container2 center">
                          <div className="imgFull center">
                            <p className="">{item.note}</p>
                          </div>
                        </span>
                      </a>
                    </li>
                    <li className="child-item center">
                      {renderComplaintType(
                        item.geoEventsTypeId,
                        ComplaintsOptions
                      )}
                    </li>

                    {rows_collabse[index] === true ? (
                      <>
                        <li class="child-item center">
                          <Share
                            TagItem={TagItem}
                            point={extractLatLng(item.locationText)}
                            type="complaint"
                            type_id={item.id}
                          />
                        </li>
                        <li class="child-item center">
                          <a
                            target="_blank"
                            href={`${window.location.origin}/complaint=${
                              item.id
                            }&${convert_to_lat_lng_point(
                              item.locationText,
                              "lat"
                            )},${convert_to_lat_lng_point(
                              item.locationText,
                              "lng"
                            )},15z`}
                          >
                            <img src={situation} alt="" className="cursor" />
                          </a>
                        </li>
                        <li class="child-item center">
                          <span className="tbody_container2 center">
                            <div className="imgFull center">
                              <p className="td_table_text">
                                {getLanguage() === "en"
                                  ? new Date(item.createdAt).toLocaleString(
                                      "en"
                                    )
                                  : new Date(item.createdAt).toLocaleString(
                                      "ar"
                                    )}
                              </p>
                            </div>
                          </span>
                        </li>
                        <li class="child-item center">
                          <span className="tbody_container2 center">
                            <div className="imgFull flex alignItem">
                              {item.user ? (
                                renderSingleImage(item.user.image, general)
                              ) : (
                                <img src={avater2} className="card_img_chat" />
                              )}
                              <p className="td_table_text">
                                {item.user !== null ? item.user.fullName : ""}
                              </p>
                            </div>
                          </span>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              </Card>
              <hr
                className="border_list_hr"
                style={{
                  width: rows_collabse[index] === true ? "100%" : "300px",
                }}
              />
            </React.Fragment>
          );
        });
      }
    }
  };
  return (
    <React.Fragment>
      <Card
        className="header_card cursor"
        style={{
          width:
            rows_collabse.length > 0
              ? rows_collabse.find((col) => col === true)
                ? "100%"
                : "300px"
              : "300px",
          height: "100px",
          zIndex: 10,
        }}
      >
        <div className="flex aligmItem fullWidthHeight">
          <ul class="parent-container">
            <li className="child-item center bold">{local.Images}</li>
            <li className="child-item center bold"> {local.UserNote}</li>
            <li className="child-item center bold"> {local.ComplaintType}</li>
            {rows_collabse.length > 0 ? (
              rows_collabse.find((col) => col === true) ? (
                <>
                  <li className="child-item center bold">{local.ShareLink}</li>
                  <li className="child-item center bold">{local.Location}</li>
                  <li className="child-item center bold">{local.Time}</li>
                  <li className="child-item center bold">{local.User}</li>
                </>
              ) : null
            ) : null}
          </ul>
        </div>
      </Card>
      <hr className="border_list_hr" />
      {renderTableBody()}

      <Card
        className="header_card"
        style={{
          width: "300px",
          zIndex: 10,
        }}
      >
        <div className="center fullWidthHeight mt-4 mb-4">
          {data.pagination ? (
            <PaginationItem
              pagination={data.pagination}
              general={general}
              fetchData={fetchComplaints}
              filterArray={data.filterArray}
            />
          ) : null}
        </div>
      </Card>
    </React.Fragment>
  );
};

export default WebList;
