import React, { useEffect ,useState,useRef } from 'react'
import { useForm, FormProvider } from "react-hook-form";

//import { DevTool } from '@hookform/devtools';

export const ValidationContext = React.createContext();


export default function Formcomponent({defValues,children,onsubmit,...props}) {
    const methods = useForm({
         delayError: 500,
         defaultValues:defValues
    })
    const onSubmit = data => { onsubmit(data,methods) };
    return (
        <FormProvider {...methods} > 
        <form {...props} onSubmit={methods.handleSubmit(onSubmit)}>
            {children}
        </form>
        {/* <DevTool control={methods.control} />  */}
      </FormProvider>
    )
}
