import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { getLanguage, local, toogleLanguage } from "../lang/local";

const MenuLanguage = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const onLang = (e, lang) => {
    e.preventDefault();
    if (getLanguage() !== lang) {
      toogleLanguage(lang);
    }
  };

  return (
    <div className="fullWidth mt-3 ml-3 mr-3">
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        style={{
          float: getLanguage() === "en" ? "left" : "right",
          position: "absolute",
          zIndex: "1000",
        }}
      >
        <DropdownToggle className="lang_btn">{local.Language}</DropdownToggle>
        <DropdownMenu
          modifiers={{
            setMaxHeight: {
              enabled: true,
              order: 890,
              fn: (data) => {
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    overflow: "auto",
                    maxHeight: "100px",
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "2px",
                    textAlign: "center",
                  },
                };
              },
            },
          }}
        >
          <DropdownItem
            active={getLanguage() === "en" ? false : true}
            className="mt-2"
            onClick={(e) => onLang(e, "ar")}
          >
            {local.Arabic}
          </DropdownItem>
          <DropdownItem
            active={getLanguage() === "en" ? true : false}
            className="mt-2 mb-2"
            onClick={(e) => onLang(e, "en")}
          >
            {local.English}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default MenuLanguage;
