import { MAP_USER } from "../General/StaticKeys";
import { local } from "../lang/local";

export const getUser = () => {
  let item = JSON.parse(localStorage.getItem(MAP_USER));

  if (item) {
    return item;
  } else {
    return null;
  }
};
export const renderUserName = () => {
  let user = getUser();
  if (user !== null) {
    return user.data.fullName;
  } else {
    return local.UserName;
  }
};
