import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Card, Col, Row, Input, Spinner } from "reactstrap";
import "./place.css";
import { local } from "../../../lang/local";
import close_icon from "../../../assets/img/close_icon.png";
import blue_true from "../../../assets/img/blue_true.png";
import plus_red from "../../../assets/img/plus_red.png";
import CheckBoxItem from "./CheckBoxItem";
import {
  UPDATE_DIRECTION_MENU_PROPS,
  UPDATE_PLACES_PROPS,
} from "../../../Redux/Actions/types";
import {
  leftStyle,
  rightStyle,
  leftPropStyle,
} from "../../../General/GeneralStyle";
import {
  convert_to_well_known_text,
  showConfirmMessage,
} from "../../../General/GeneralFunctions";
import { _lists } from "../../../General/_lists";
const useStyles = makeStyles((theme) => ({
  typography: {
    width: "400px",
  },
}));

const AddPlace = ({
  general,
  add_place,
  location_data,
  createPlace,
  TagItem,
  title,
  type_name,
  type,
  search_direction,
  startLocation,
  endLocation,
  addListType,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [check, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, []);

  const onDone = (e) => {
    e.preventDefault();
    if (add_place.form.type === "") {
      showConfirmMessage(local.SelectType, "error");
    } else if (add_place.form.geometry.length === 0) {
      showConfirmMessage(local.SelectOnMap, "error");
    } else {
      createPlace(add_place.form);
    }
  };

  const updateForm = (type, name, geometry, item) => {
    if (type === "register") {
      general(
        [
          {
            prop: "add_place.form",
            value: {
              name: name,
              gisId: location_data.gis_id,
              info: { address: name },
              geometry: [geometry],
              type: "",
            },
          },
        ],
        UPDATE_PLACES_PROPS
      );
    } else {
      general(
        [
          {
            prop: "add_place.form",
            value: {
              name: name,
              info: { placeId: item.gis_id, address: item.title },
              geometry: [geometry],
              type: "Labeled",
            },
          },
        ],
        UPDATE_PLACES_PROPS
      );
    }
  };

  const handleClick = (event) => {
    if (search_direction !== undefined) {
      general(
        [{ prop: "search_direction.isAddHomeWork", value: true }],
        UPDATE_DIRECTION_MENU_PROPS
      );
      if (search_direction.type === "") {
        updateForm(
          "register",
          location_data.address,
          convert_to_well_known_text(location_data.lat, location_data.lng)
        );
      } else {
        if (search_direction.type === "start") {
          if (startLocation.lat === "" && startLocation.lng === "") {
            showConfirmMessage(local.SelectOnMap, "error");
          } else {
            updateForm(
              location_data.icon_type,
              type_name,
              convert_to_well_known_text(startLocation.lat, startLocation.lng),
              startLocation
            );
          }
        } else {
          if (endLocation.lat === "" && endLocation.lng === "") {
            showConfirmMessage(local.SelectOnMap, "error");
          } else {
            updateForm(
              location_data.icon_type,
              type_name,
              convert_to_well_known_text(endLocation.lat, endLocation.lng),
              endLocation
            );
          }
        }
      }
    } else {
      updateForm(
        location_data.icon_type,
        location_data.address,
        convert_to_well_known_text(location_data.lat, location_data.lng)
      );
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    general(
      [{ prop: "search_direction.isAddHomeWork", value: false }],
      UPDATE_DIRECTION_MENU_PROPS
    );
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const renderList = (index, image, label, value) => {
    return (
      <Row className="mt-4 ml-1 mr-1" key={index}>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card className="card_list">
            <div className="list_card_container flex alignItem fullWidthHeight">
              {image !== null ? (
                <span className="list_card_img_container">
                  <img src={image} alt="" className="imgFull" />
                </span>
              ) : null}
              <div className="list_card_desc_container" s>
                {label}
              </div>
              <span
                className="list_card_checkbox_container"
                style={leftPropStyle}
              >
                <CheckBoxItem
                  general={general}
                  title={value}
                  check={check}
                  setChecked={setChecked}
                />
              </span>
            </div>
          </Card>
        </Col>
      </Row>
    );
  };

  const renderLists = () => {
    // Labeled
    if (type_name === undefined) {
      return _lists.map((list, index) => {
        return renderList(index, list.image, list.label, list.value);
      });
    } else {
      return renderList(1, null, type, type);
    }
  };

  const addAnotherList = () => {
    return (
      <Row className="mt-4 ml-1 mr-1">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card className="card_list">
            <div className="list_card_container flex alignItem fullWidthHeight">
              <span className="list_card_img_container">
                <img src={plus_red} alt="" className="imgFull" />
              </span>

              <Input
                placeholder={local.AddAnotherList}
                className="list_input"
                value={addListType.name}
                onChange={(e) =>
                  general(
                    [
                      {
                        prop: "addListType.name",
                        value: e.target.value,
                      },
                      {
                        prop: "add_place.form.type",
                        value: e.target.value,
                      },
                    ],
                    UPDATE_PLACES_PROPS
                  )
                }
              />
              {addListType.name !== "" ? (
                <span className="list_card_img_container2">
                  <img src={blue_true} alt="" className="imgFull" />
                </span>
              ) : null}
            </div>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <React.Fragment>
      <a className="cursor" onClick={handleClick}>
        <TagItem title={title} />
      </a>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>
          <div className="fullWidthHeight">
            <Row className="mt-1 ml-1 mr-1">
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <img
                  src={close_icon}
                  alt="close_icon cursor"
                  style={rightStyle}
                  onClick={handleClose}
                />
                <div style={leftStyle} className="mt-3">
                  <div className="center">
                    <p className="card_list_title">{local.AddToList}</p>
                  </div>
                </div>
              </Col>
            </Row>
            {renderLists()}
            {addAnotherList()}

            <div
              className="fullWidth center"
              style={{
                position: "absolute",
                bottom: "15px",
              }}
            >
              <p className="done_btn cursor" onClick={onDone}>
                {add_place.isLoading === true ? (
                  <Spinner color="#fff" />
                ) : (
                  local.Done
                )}
              </p>
            </div>
          </div>
        </Typography>
      </Popover>
    </React.Fragment>
  );
};
export default AddPlace;
