import React from 'react';
import { Card, Row, Col, Button } from 'reactstrap';
import {
  leftStyle,
  rightStyle,
  buttonPlaceStyle,
} from '../../../General/GeneralStyle';
import { webAsideCardStyle } from '../../../utils/MapViewStyle';
import close_icon from '../../../assets/img/close_icon.png';
import send from '../../../assets/img/send.png';
import {
  UPDATE_PLACES_PROPS,
  UPDATE_LOCATION_MENU_PROPS,
  UPDATE_USER_ACCOUNT_PROPS,
} from '../../../Redux/Actions/types';
import { local } from '../../../lang/local';
import { convert_to_lat_lng_point } from '../../../General/GeneralFunctions';
import InfiniteScroll from 'react-infinite-scroll-component';
import Share from '../../../General/Share';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/Actions/index';
import { handlePlaceClick } from '../SearchMenu/SearchFunctions';

const PlaceList = ({
  general,
  lists,
  searchImages,
  getSocketResult,
  myPosition,
  ipAddress,
}) => {
  const closeInfo = (e) => {
    e.preventDefault();
    general(
      [
        {
          prop: 'userAccount.isOpen',
          value: true,
        },
      ],
      UPDATE_USER_ACCOUNT_PROPS
    );
    general(
      [
        {
          prop: 'lists.list_item.isOpen',
          value: false,
        },
      ],
      UPDATE_PLACES_PROPS
    );
  };
  const RenderShareImage = () => {
    return <span className='share_text'>{local.Share}</span>;
  };

  const handleCardClick = (e, place) => {
    let lat = convert_to_lat_lng_point(place.geometryText, 'lat');
    let lng = convert_to_lat_lng_point(place.geometryText, 'lng');
    let item = {
      label: place.info.address,
      value: [lng, lat],
      data: { properties: { gid: place.gisId } },
    };

    handlePlaceClick(
      e,
      item,
      general,
      getSocketResult,
      myPosition,
      ipAddress,
      searchImages
    );
  };
  const renderPlaces = () => {
    if (lists.list_item.registered_items) {
      if (lists.list_item.registered_items.length > 0) {
        return lists.list_item.registered_items.map((item, index) => {
          let point = {
            lat: convert_to_lat_lng_point(item.geometryText, 'lat'),
            lng: convert_to_lat_lng_point(item.geometryText, 'lng'),
          };
          return (
            <Row className='mt-4' key={index}>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card
                  className='place_card cursor'
                  onClick={(e) => handleCardClick(e, item)}
                >
                  <div className='fullWidthHeight place_container alignItem flex'>
                    <span>
                      <Share TagItem={RenderShareImage} point={point} />
                    </span>
                    {item.name}

                    <Button
                      className='btn_place center'
                      style={buttonPlaceStyle}
                      onClick={(e) => {
                        e.preventDefault();
                        general(
                          [
                            { prop: 'location_data.address', value: item.name },
                            {
                              prop: 'location_data.lat',
                              value: convert_to_lat_lng_point(
                                item.geometryText,
                                'lat'
                              ),
                            },
                            {
                              prop: 'location_data.lng',
                              value: convert_to_lat_lng_point(
                                item.geometryText,
                                'lng'
                              ),
                            },
                            {
                              prop: 'location_data.icon_type',
                              value: 'search',
                            },
                          ],
                          UPDATE_LOCATION_MENU_PROPS
                        );
                      }}
                    >
                      {item.distance}
                      <span style={{ width: '17px' }}>
                        <img src={send} alt='send' className='imgFull' />
                      </span>
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          );
        });
      }
    }
  };
  return (
    <Card className='web_view_card' style={webAsideCardStyle}>
      <div style={{ height: '100%', overflowY: 'scroll' }}>
        <InfiniteScroll dataLength={lists.list_item.registered_items.length}>
          <Row className='mt-3'>
            <Col>
              <img
                src={close_icon}
                alt='close_icon cursor'
                style={rightStyle}
                onClick={(e) => closeInfo(e)}
              />
              <div style={leftStyle} className='mt-5'>
                <div className='center'>
                  {lists.list_item.icon !== '' ? (
                    <span>
                      <img
                        src={lists.list_item.icon}
                        alt=''
                        className='imgFull'
                      />
                    </span>
                  ) : null}

                  <p
                    className='card_list_title'
                    style={{ marginInlineStart: '10px' }}
                  >
                    {lists.list_item.title}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          {renderPlaces()}
        </InfiniteScroll>
      </div>
    </Card>
  );
};

const mapStateToPorps = ({ PlaceR, GeneralR }) => {
  return {
    lists: PlaceR.lists,
    myPosition: GeneralR.myPosition,
    ipAddress: GeneralR.ipAddress,
  };
};
export default connect(mapStateToPorps, actions)(PlaceList);
