import React from "react";
import { Col, Row, Input, Label } from "reactstrap";
import { local } from "../../lang/local";
import { columnAlignLeft } from "../../General/GeneralStyle";

const UserRoleProfile = ({ data, usersOptions }) => {
  return data ? (
    <div className="mt-3 mb-3">
      <Row>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Id}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.id}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Name}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.name}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Description}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.description}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
    </div>
  ) : null;
};

export default UserRoleProfile;
