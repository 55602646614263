import {
  UPDATE_CONTRIBUTION_PROPS,
  MERGE_CONTRIBUTION_PROPS,
  DELETE_CONTRIBUTION_PROPS,
  CLEAR_CONTRIBUTION_STATE,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  layersOptions: [],
  data: {
    isOpen: false,
    items: null,
    photos: [],
    pagination: null,
    watiningPlace: null,
    isLoadingDelete: null,
    isOpenAddContribution: false,
    form: {
      Geometry: "",
      ContributionName: { en: "", ar: "" },
      Description: { key: "" },
      Images: [],
      Layer: "",
      PhoneNumber: "",
      WorkingHours: [
        { day: "Monday", from: null, to: null },
        { day: "Tuesday", from: null, to: null },
        { day: "Wednesday", from: null, to: null },
        { day: "Thursday", from: null, to: null },
        { day: "Friday", from: null, to: null },
        { day: "Saturday", from: null, to: null },
        { day: "Sunday", from: null, to: null },
      ],
      Categories: [],
      Website: "",
      Facebook: "",
      Instgram: "",
      Twitter: "",
      Email: "",
      Snapshat: "",
      Whatsapp: "",
    },
    errorMessages: { Name: "", Layer: "", Location: "" },

    exists_places: [],
    isLoadingCONTRIBUTION: false,

    filterArray: [
      {
        op: 3,
        fieldName: "",
        value: "",
      },
      {
        op: 0,
        fieldName: "Osm@PhoneNumber",
        value: "",
      },
      {
        op: 3,
        fieldName: "Layer",
        value: null,
      },
      {
        op: 0,
        fieldName: "User@Id",
        value: null,
      },
    ],
    item_index: null,
    isLoadingCreate: false,
    isLoading: false,
    isLoadingSearch: false,
  },
  added_places: {
    isOpen: false,
    items: [],
  },
  hours: { isOpen: false },
  editedPlaces: {
    isLoading: false,
    items: [],
  },
};

const ContribuationsR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CONTRIBUTION_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_CONTRIBUTION_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_CONTRIBUTION_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_CONTRIBUTION_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default ContribuationsR;
