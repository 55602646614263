import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "reactstrap";
import webview_explore_icon from "../../../assets/img/webview_explore_icon.svg";
import webview_dir_icon from "../../../assets/img/webview_dir_icon.svg";
import webview_avter from "../../../assets/img/webview_avter.svg";
import dashboard from "../../../assets/img/dashboard.svg";
import {
  webAsideCardStyle,
  initalSearchCard,
} from "../../../utils/MapViewStyle";
import { local } from "../../../lang/local";
import {
  CLEAR_LOCATION_MENU_STATE,
  CLEAR_SEARCH_MENU_STATE,
  UPDATE_SEARCH_MENU_PROPS,
} from "../../../Redux/Actions/types";
import SearchResult from "./SearchResult";
import { useDebouncedCallback } from "use-debounce";
import RecentSearch from "./RecentSearch";
import { connect } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  showConfirmMessage,
  renderTabs,
  check_authorization,
} from "../../../General/GeneralFunctions";
import { IMAGE_BASE_PATH } from "../../../General/StaticKeys";
import { WebViewSearch } from "../../../components/WebViewSearch/WebViewSearch";
import { getUser } from "../../../utils/StorageFunctions";

const SearchMenu = ({
  search_data,
  general,
  getSocketResult,
  myPosition,
  searchResult,
  direction_data,
  ipAddress,
  searchImages,
  location_data,
  getPermissions,
  history,
}) => {
  const [active, setActive] = useState("");

  const onKey = () => {
    if (search_data.value === "" && search_data.isSlideDown === true) {
      general([], CLEAR_SEARCH_MENU_STATE);
      general([], CLEAR_LOCATION_MENU_STATE);
    }
    if (search_data.value !== "" && search_data.isSlideDown === false) {
      general(
        [{ prop: "search_data.isSlideDown", value: true }],
        UPDATE_SEARCH_MENU_PROPS
      );
    }
  };

  useEffect(() => {
    onKey();
  }, [search_data.value]);

  const fetchPermissions = () => {
    if (check_authorization(history) === true) {
      getPermissions(history);
      history.push("/welcome");
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    general(
      [{ prop: "search_data.type", value: "search" }],
      UPDATE_SEARCH_MENU_PROPS
    );

    if (search_data.value !== "") {
      let lat = myPosition.lat;
      let lng = myPosition.lng;
      if (myPosition.allowLocation === false) {
        lat = ipAddress.lat;
        lng = ipAddress.lng;
      }
      getSocketResult(search_data.value, lat, lng, "search");
    } else {
      showConfirmMessage(local.SearchValueRequired, "error");
    }
  };

  const debounced = useDebouncedCallback((value) => {
    if (value) {
      let lat = myPosition.lat;
      let lng = myPosition.lng;
      if (myPosition.allowLocation === false) {
        lat = ipAddress.lat;
        lng = ipAddress.lng;
      }
      getSocketResult(value, lat, lng, "auto_complete");
    }
  }, 1000);

  const getImage = () => {
    let user = getUser();
    if (user) {
      if (user.data.image) {
        return IMAGE_BASE_PATH + user.data.image;
      } else {
        return webview_avter;
      }
    } else {
      return webview_avter;
    }
  };
  return (
    <Card
      className={
        search_data.isSlideDown === false
          ? "inital_web_view_card_search"
          : "web_view_card"
      }
      style={
        search_data.isSlideDown === false ? initalSearchCard : webAsideCardStyle
      }
    >
      <div>
        <Row
          className={
            search_data.isSlideDown === false
              ? "alignItem fullWidthHeight"
              : "alignItem"
          }
        >
          <Col
            xl={search_data.isSlideDown === false ? 6 : 12}
            lg={search_data.isSlideDown === false ? 6 : 12}
            md={search_data.isSlideDown === false ? 6 : 12}
            sm={12}
            xs={12}
          >
            <WebViewSearch
              isSlideDown={search_data.isSlideDown}
              onClick={handleSearch}
              onChange={(value) => {
                general(
                  [{ prop: "search_data.value", value }],
                  UPDATE_SEARCH_MENU_PROPS
                );
                debounced(value);
              }}
              isLoading={search_data.isLoading}
              value={search_data.value}
            />
          </Col>

          <Col
            className="padding0 column_option mt-2"
            xl={search_data.isSlideDown === false ? 6 : 12}
            lg={search_data.isSlideDown === false ? 6 : 12}
            md={search_data.isSlideDown === false ? 6 : 12}
            sm={12}
            xs={12}
          >
            {renderTabs(
              webview_explore_icon,
              local.Explore,
              active,
              setActive,
              general,
              null,
              myPosition,
              ipAddress,
              null
            )}

            {renderTabs(
              webview_dir_icon,
              local.Direction,
              active,
              setActive,
              general,
              location_data,
              myPosition,
              ipAddress,
              null
            )}

            {renderTabs(
              getImage(),
              local.UserName,
              active,
              setActive,
              general,
              null,
              myPosition,
              ipAddress,
              null
            )}

            {renderTabs(
              dashboard,
              local.Dashboard,
              active,
              setActive,
              general,
              null,
              myPosition,
              ipAddress,
              fetchPermissions
            )}
          </Col>
          {search_data.isSlideDown === true ? (
            <React.Fragment>
              <SearchResult
                general={general}
                searchResult={searchResult}
                myPosition={myPosition}
                getSocketResult={getSocketResult}
                search_data={search_data}
                ipAddress={ipAddress}
                searchImages={searchImages}
              />

              <RecentSearch
                general={general}
                ipAddress={ipAddress}
                myPosition={myPosition}
                getSocketResult={getSocketResult}
                searchImages={searchImages}
              />
            </React.Fragment>
          ) : null}
        </Row>
      </div>
    </Card>
  );
};

const mapStateToProps = ({ SearchMenuR, GeneralR, DirectionR, LocationR }) => {
  return {
    search_data: SearchMenuR.search_data,
    ipAddress: GeneralR.ipAddress,
    myPosition: GeneralR.myPosition,
    searchResult: SearchMenuR.searchResult,
    direction_data: DirectionR.direction_data,
    location_data: LocationR.location_data,
  };
};

export default connect(mapStateToProps, actions)(SearchMenu);
