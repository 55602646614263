import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import icon_emoij from "../../assets/img/icon_emoij.png";
import Picker from "react-emojipicker";
import { UPDATE_TICKETS_PROPS } from "../../Redux/Actions/types";

const EmojiPicker = ({ general, input_value }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onEmojiClick = (event) => {
    input_value = input_value + event.unicode;
    general(
      [{ prop: "data_message.form.Msg.text", value: input_value }],
      UPDATE_TICKETS_PROPS
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <img src={icon_emoij} className="imgFull cursor" onClick={handleClick} />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography>
          <Picker onEmojiSelected={onEmojiClick} />
        </Typography>
      </Popover>
    </div>
  );
};

export default EmojiPicker;
