import React from 'react';
import { Input } from 'reactstrap';
import strik from '../../assets/img/strik.png';

export const CustomInput = ({
  icon,
  type,
  value,
  className,
  onChange,
  label,
}) => {
  return (
    <div className='container_add_input mx-auto'>
      {icon ? <img src={strik} alt='strik' className='strik_icon' /> : null}
      <Input
        type={type}
        placeholder={label}
        className={className}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {type === 'email' ? (
        <input type='email' style={{ position: 'absolute', zIndex: '-1' }} />
      ) : null}
    </div>
  );
};
