import React, { useEffect } from "react";
import { connect } from "react-redux";
import { showConfirmMessage } from "../General/GeneralFunctions";
import { getPermissions } from "../utils/Permissions";
import { local } from "../lang/local";
import * as actions from "../Redux/Actions/index";
const Welcome = ({ isLoadingRoles, isLoadingClaims, history }) => {
  const goToPage = (roles, claims) => {
    let permissions = getPermissions(roles, claims);
    if (permissions !== undefined) {
      if (permissions.Administrator !== undefined) {
        history.push("/dashboard/home");
      } else {
        if (permissions.Home_Role !== undefined) {
          history.push("/dashboard/home");
        } else {
          let page_view = Object.values(permissions)
            .reverse()
            .find((x) => x.type === "view" || x.type === "add");
          if (page_view !== undefined) {
            history.push(page_view.route);
          }
        }
      }
    } else {
      history.push("/");

      showConfirmMessage(local.NoControlPanel, "error");
    }
  };

  useEffect(() => {
    if (isLoadingRoles !== null && isLoadingClaims !== null) {
      if (isLoadingRoles === true && isLoadingClaims === true) {
        goToPage(true, true);
      } else if (isLoadingRoles === true && isLoadingClaims === false) {
        goToPage(true, false);
      } else if (isLoadingRoles === false && isLoadingClaims === true) {
        goToPage(false, true);
      }
    } else {
      goToPage(true, true);
    }
  }, [isLoadingRoles, isLoadingClaims]);
  return <div className="center fullWidthHeight"></div>;
};
const mapStateToProps = ({ GeneralR }) => {
  return {
    isLoadingRoles: GeneralR.isLoadingRoles,
    isLoadingClaims: GeneralR.isLoadingClaims,
  };
};
export default connect(mapStateToProps, actions)(Welcome);
