import {
  convertToFormData,
  renderName,
  showConfirmMessage,
  showSuccessedConfirm,
} from "../../General/GeneralFunctions";
import { SendRequest } from "./api";
import {
  DELETE_COMPLAINT_TYPE_PROPS,
  MERGE_COMPLAINT_TYPE_PROPS,
  UPDATE_COMPLAINT_TYPE_PROPS,
} from "./types";
import { local } from "../../lang/local";

export const fetchComplaintType = (filterArray, page) => (dispatch) => {
  dispatch({
    type: UPDATE_COMPLAINT_TYPE_PROPS,
    payload: [{ prop: "data.isLoading", value: true }],
  });
  let url = "/GeoEventType/Get";

  if (page !== undefined) {
    url += `?page=${page}`;
  }
  SendRequest()
    .post(url, filterArray)
    .then((res) => {
      if (res.status === 200) {
        let pagination = Object.assign({}, res.data.data);
        delete pagination.items;
        dispatch({
          type: UPDATE_COMPLAINT_TYPE_PROPS,
          payload: [
            { prop: "data.items", value: res.data.data.items },
            { prop: "data.pagination", value: pagination },
          ],
        });
      }

      dispatch({
        type: UPDATE_COMPLAINT_TYPE_PROPS,
        payload: [{ prop: "data.isLoading", value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_COMPLAINT_TYPE_PROPS,
        payload: [{ prop: "data.isLoading", value: false }],
      });
    })
    .finally();
};
export const updateComplaintType =
  (formValues, type, history) => (dispatch) => {
    dispatch({
      type: UPDATE_COMPLAINT_TYPE_PROPS,
      payload: [{ prop: "data.isLoadingUpdate", value: true }],
    });
    let newFormValues = Object.assign({}, formValues);

    let url = "";
    if (formValues.id === undefined) {
      url = "/GeoEventType";
    } else {
      url = "GeoEventType/Update/" + newFormValues.id;
    }
    let formData = convertToFormData(newFormValues);
    SendRequest()
      .post(url, formData)
      .then((response) => {
        if (response.status === 200) {
          if (type === "create") {
            dispatch({
              type: UPDATE_COMPLAINT_TYPE_PROPS,
              payload: [
                {
                  prop: "data.form",
                  value: {
                    Name: { en: "", ar: "" },
                    Icon: null,
                  },
                },
              ],
            });

            showSuccessedConfirm(local.AddedSuccessfully);
          } else {
            showSuccessedConfirm(local.SuccessfullyUpdated);

            history.push("/dashboard/complaints");
          }
        }
        dispatch({
          type: UPDATE_COMPLAINT_TYPE_PROPS,
          payload: [{ prop: "data.isLoadingUpdate", value: false }],
        });
      })
      .catch((e) => {
        console.log(e);
        if (Object.values(e)[2] !== undefined) {
          if (Object.values(e)[2].data !== undefined) {
            showConfirmMessage(Object.values(e)[2].data.message, "error");
          }
        }
        dispatch({
          type: UPDATE_COMPLAINT_TYPE_PROPS,
          payload: [{ prop: "data.isLoadingUpdate", value: false }],
        });
      })
      .finally();
  };

export const DeleteComplaintType = (id, index) => (dispatch) => {
  SendRequest()
    .post(`/GeoEventType/Delete/${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_COMPLAINT_TYPE_PROPS,
          payload: [{ prop: "data.items." + index }],
        });
        showSuccessedConfirm(local.DeletedSuccessfully);
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const fetchComplaint = (id) => (dispatch) => {
  SendRequest()
    .get(`/GeoEventType/${id}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data) {
          let item = res.data.data;
          let enName = !item.name ? "" : item.name.en ? item.name.en : "";
          let arName = !item.name ? "" : item.name.ar ? item.name.ar : "";

          let Icon = res.data.data.icon ? res.data.data.icon : null;

          dispatch({
            type: UPDATE_COMPLAINT_TYPE_PROPS,
            payload: [
              {
                prop: "data.form",
                value: {
                  Name: { en: enName, ar: arName },
                  Icon,
                  id,
                },
              },
            ],
          });
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const getComplaintTypeForDropDown =
  (filterArray, page, name) => (dispatch) => {
    let url = "/GeoEventType/Get";
    if (page) {
      url += `?page=${page}`;
    }
    let arregex = /[\u0600-\u06FF]/;

    let lang = "";
    if (arregex.test(name) === true) {
      lang = "ar";
    } else {
      lang = "en";
    }
    if (name !== undefined) {
      filterArray.push({
        op: 0,
        fieldName: `Name@${lang}`,
        value: name,
      });
    }
    SendRequest()
      .post(url, filterArray)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.items) {
            let ComplaintTypes = [];
            res.data.data.items.map((comp) => {
              ComplaintTypes.push({
                label: renderName(comp.name),
                value: comp.id,
              });
            });
            dispatch({
              type: UPDATE_COMPLAINT_TYPE_PROPS,
              payload: [
                { prop: "ComplaintTypesOptions", value: ComplaintTypes },
              ],
            });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally();
  };

export const loadComplaintOptions =
  (search, loadedOptions, { page }) =>
  async (dispatch) => {
    let newFilterArray = [];
    let ComplaintTypes = [];
    let hasMore = false;
    let url = "/GeoEventType/Get";
    let arregex = /[\u0600-\u06FF]/;

    let lang = "";
    if (arregex.test(search) === true) {
      lang = "ar";
    } else {
      lang = "en";
    }
    if (search) {
      newFilterArray.push({
        op: 0,
        fieldName: `Name@${lang}`,
        value: search,
      });
    } else {
      if (page !== null) {
        url = url + `?page=${page}`;
      }
    }

    await SendRequest()
      .post(url, newFilterArray)
      .then((res) => {
        if (res.status === 200) {
          res.data.data.items.map((comp) => {
            ComplaintTypes.push({
              label: renderName(comp.name),
              value: comp.id,
            });
          });
          if (res.data.data.currentPage < res.data.data.totalPages) {
            hasMore = true;
          }

          if (page === 1) {
            dispatch({
              type: UPDATE_COMPLAINT_TYPE_PROPS,
              payload: [
                { prop: "ComplaintTypesOptions", value: ComplaintTypes },
              ],
            });
          } else {
            dispatch({
              type: MERGE_COMPLAINT_TYPE_PROPS,
              payload: [
                { prop: "ComplaintTypesOptions", value: ComplaintTypes },
              ],
            });
          }
        }
      })
      .catch((e) => {})
      .finally();
    return {
      options: ComplaintTypes,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

export const getComplaintType = (id) => (dispatch) => {
  SendRequest()
    .get(`https://apis.mapy.world:5002/api/GeoEventType/${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: MERGE_COMPLAINT_TYPE_PROPS,
          payload: [{ prop: "data.items", value: res.data.data }],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const fetchAllComplaintTypes = (page) => (dispatch) => {
  SendRequest()
    .post(`/GeoEventType/Get?page=${page}`, [])
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data.currentPage < res.data.data.totalPages) {
          dispatch(fetchAllComplaintTypes(res.data.data.currentPage + 1));
        }
        if (page === 1) {
          dispatch({
            type: UPDATE_COMPLAINT_TYPE_PROPS,
            payload: [
              { prop: "data.allComplaintTypes", value: res.data.data.items },
            ],
          });
        } else {
          dispatch({
            type: MERGE_COMPLAINT_TYPE_PROPS,
            payload: [
              { prop: "data.allComplaintTypes", value: res.data.data.items },
            ],
          });
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
