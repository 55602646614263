import React, { useState } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { local } from "../../lang/local";
import Icon_more from "../../assets/img/Icon_more.png";

const ChatMenu = ({ unsetMessages }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      style={{ marginInlineStart: "15px" }}
    >
      <DropdownToggle
        tag="a"
        className="nav-link_chat"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <img src={Icon_more} alt="" className="chat_file_btn" />
      </DropdownToggle>
      <DropdownMenu className="table_dropdown_menu">
        <DropdownItem
          onClick={(e) => {
            e.preventDefault();
            unsetMessages(e);
          }}
          className="cursor"
        >
          {local.Delete}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ChatMenu;
