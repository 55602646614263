import React, { useState } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import { local } from "../../lang/local";
import {
  renderAddButton,
  renderCheckoBox,
  renderMultipleDeleteButton,
  renderTableDropDown,
} from "../../General/TableFunctions";

import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
const TableList = ({
  history,
  data,
  deleteRoleUser,
  general,
  deleteMultipleUsersRoles,
}) => {
  const [checked, setChecked] = useState([]);

  const deleteItem = (e, item, index) => {
    e.preventDefault();
    deleteRoleUser(item.id, 7, index);
  };

  const renderTableBody = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return data.items.map((item, index) => {
          return (
            <tr key={index}>
              <th scope="row">
                {renderCheckoBox(
                  item.id,
                  checked,
                  setChecked,
                  "single",
                  "RoleBusiness_DetachUserRole"
                )}
              </th>
              <td>{item.id}</td>
              <td>
                <a
                  href=""
                  className="blueLight cursor"
                  onClick={(e) => {
                    e.preventDefault();
                    general(
                      [
                        {
                          prop: "profileModal",
                          value: { isOpen: true, data: item },
                        },
                      ],
                      UPDATE_GENERAL_PROPS
                    );
                  }}
                >
                  {item.name}
                </a>
              </td>
              <td>{item.description}</td>
              <td>
                {renderTableDropDown(
                  item,
                  index,
                  deleteItem,
                  null,
                  "RoleBusiness_DetachUserRole",
                  null
                )}
              </td>
            </tr>
          );
        });
      }
    }
  };
  return (
    <Card className="table_card">
      <div className="mt-4 mb-5">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderAddButton(
              local.AddRoleUser,
              history,
              "/dashboard/attach_user_roles",
              "RoleBusiness_AttachUserRole"
            )}

            {renderMultipleDeleteButton(
              checked,
              setChecked,
              deleteMultipleUsersRoles,
              data.isLoadingDelete,
              "RoleBusiness_DetachUserClaim",
              data.userId
            )}
          </Col>
        </Row>

        <Table responsive hover className="mt-4">
          <thead>
            <tr>
              <th>
                {data.items
                  ? data.items.length > 0
                    ? renderCheckoBox(
                        data.items.map((i) => i.id),
                        checked,
                        setChecked,
                        "all",
                        "RoleBusiness_DetachUserRole"
                      )
                    : null
                  : null}
              </th>
              <th>{local.Id}</th>
              <th>{local.Name}</th>
              <th>{local.Description}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </Table>
      </div>
    </Card>
  );
};

export default TableList;
