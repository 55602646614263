import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Card, Input, Form, Spinner } from "reactstrap";
import * as actions from "../../Redux/Actions/index";
import { connect } from "react-redux";
import { getLanguage, local } from "../../lang/local";
import Dropzone from "react-dropzone";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import image_icon from "../../assets/img/image_icon.png";
import strik from "../../assets/img/strik.png";
import OpenningHours from "../../General/OpenningHours";
import Select from "react-select";

import {
  CLEAR_CONTRIBUTION_STATE,
  UPDATE_CONTRIBUTION_PROPS,
  MERGE_CONTRIBUTION_PROPS,
  CLEAR_GENERAL_STATE,
  DELETE_CONTRIBUTION_PROPS,
} from "../../Redux/Actions/types";
import { getDefaultLayer } from "../../General/SelectFunctions";
import PlaceList, {
  handleSave,
  onMapClick,
  onSelectLayer,
} from "./ContribuationFunctions";
import { isRtl, selectFormStyle } from "../../General/GeneralStyle";
import Gallery from "../../General/Gallery";
import {
  handleArabicNameChange,
  handleEnglishNameChange,
  saveImage,
  renderPhotos,
  renderErrorMessage,
} from "../../General/GeneralFunctions";
import { getUser } from "../../utils/StorageFunctions";
import SearchBox from "../../General/MapSearch/SearchBox";
import { extractLatLng } from "../../General/MapFunctions";
import TooMap from "../../General/TooMap";

const CreateContribuation = ({
  history,
  form,
  isLoadingCreate,
  general,
  location,
  addContribution,
  findContribution,
  exists_places,
  getLayers,
  layersOptions,
  lightbox,
  isLoadingPlaces,
  mapSearch,
  myPosition,
  ipAddress,
  getSocketResult,
  errorMessages,
}) => {
  const [open, setOpen] = useState(false);
  const [isSelected, setSelected] = useState(null);
  const [newPoint, setPoint] = useState(null);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevProps = usePrevious({ exists_places });
  useEffect(() => {
    if (getUser() === null) {
      history.push("/login");
    } else {
      getLayers();
      if (layersOptions.length === 0) {
        getLayers();
      }
      general([], CLEAR_GENERAL_STATE);

      general([], CLEAR_CONTRIBUTION_STATE);
    }
  }, []);

  useEffect(() => {
    if (prevProps !== undefined) {
      if (
        prevProps.exists_places !== exists_places &&
        exists_places.length > 0
      ) {
        setSelected(null);
        setPoint(null);
        setOpen(true);
      }
      if (
        prevProps.exists_places !== exists_places &&
        exists_places.length === 0
      ) {
        setPoint(null);
      }
    }
  }, [exists_places]);

  const handlePlaceSearch = (lat, lng, label) => {
    onMapClick(lat, lng, general, findContribution, form);

    if (getLanguage() === "en") {
      if (form.ContributionName.en === "") {
        general(
          [{ prop: "data.form.ContributionName.en", value: label }],
          UPDATE_CONTRIBUTION_PROPS
        );
      }
    } else {
      if (form.ContributionName.ar === "") {
        general(
          [{ prop: "data.form.ContributionName.ar", value: label }],
          UPDATE_CONTRIBUTION_PROPS
        );
      }
    }
  };

  return (
    <div className="page-container">
      <Gallery lightbox={lightbox} general={general} />
      <PlaceList
        open={open}
        setOpen={setOpen}
        exists_places={exists_places}
        general={general}
        isSelected={isSelected}
        setSelected={setSelected}
        setPoint={setPoint}
      />

      <Row>
        <Col
          xl={10}
          lg={10}
          md={10}
          sm={12}
          xs={12}
          className="mx-auto text-center"
        >
          <h3 className="page_create_title">{local.AddPlace}</h3>

          <Card className="create_card">
            <Form>
              <div className="center mt-3" style={{ width: "100%" }}>
                {!form.ContributionName.ar &&
                !form.ContributionName.en &&
                errorMessages.Name ? (
                  <span className="error_message">{errorMessages.Name}</span>
                ) : null}
              </div>
              <Row>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className=" mt-3 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <img src={strik} alt="strik" className="strik_icon" />
                    <Input
                      className="add_input"
                      value={form.ContributionName.en}
                      onChange={(e) =>
                        handleEnglishNameChange(
                          e,
                          general,
                          UPDATE_CONTRIBUTION_PROPS,
                          "data.form.ContributionName.en"
                        )
                      }
                      placeholder={local.EnglishName}
                    />
                  </div>
                </Col>

                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <img src={strik} alt="strik" className="strik_icon" />
                    <Input
                      className="add_input"
                      value={form.ContributionName.ar}
                      onChange={(e) =>
                        handleArabicNameChange(
                          e,
                          general,
                          UPDATE_CONTRIBUTION_PROPS,
                          "data.form.ContributionName.ar"
                        )
                      }
                      placeholder={local.ArabicName}
                    />
                  </div>
                </Col>

                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <img src={strik} alt="strik" className="strik_icon" />
                    <div className="form_select_container">
                      <Select
                        key={form.Layer}
                        placeholder={local.Layer}
                        value={getDefaultLayer(form.Layer, layersOptions)}
                        options={layersOptions}
                        onChange={(e) => onSelectLayer(e, general)}
                        isClearable={true}
                        isRtl={isRtl()}
                        styles={selectFormStyle}
                        isSearchable={true}
                      />
                    </div>
                  </div>

                  {renderErrorMessage(form.Layer, errorMessages.Layer)}
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <OpenningHours
                    general={general}
                    WorkingHours={form.WorkingHours}
                    action={UPDATE_CONTRIBUTION_PROPS}
                  />
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <Input
                      className="add_input"
                      placeholder={local.Website}
                      value={form.Website}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "data.form.Website",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        )
                      }
                    />
                  </div>
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <Input
                      className="add_input"
                      placeholder={local.Facebook}
                      value={form.Facebook}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "data.form.Facebook",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        )
                      }
                    />
                  </div>
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <Input
                      className="add_input"
                      placeholder={local.Instgram}
                      value={form.Instgram}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "data.form.Instgram",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        )
                      }
                    />
                  </div>
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <Input
                      className="add_input"
                      placeholder={local.Twitter}
                      value={form.Twitter}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "data.form.Twitter",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        )
                      }
                    />
                  </div>
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <Input
                      className="add_input"
                      placeholder={local.Snapshat}
                      value={form.Snapshat}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "data.form.Snapshat",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        )
                      }
                    />
                  </div>
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <Input
                      className="add_input"
                      placeholder={local.WhatsApp}
                      value={form.Whatsapp}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "data.form.Whatsapp",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        )
                      }
                    />
                  </div>
                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <Input
                      className="add_input"
                      placeholder={local.Email}
                      value={form.Email}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "data.form.Email",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        )
                      }
                    />
                  </div>
                </Col>

                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <PhoneInput
                      lang={getLanguage() === "ar" ? ar : ""}
                      // onlyCountries={[
                      //   "eg",
                      //   "sa",
                      //   "ae",
                      //   "ku",
                      //   "lb",
                      //   "om",
                      //   "bh",
                      //   "ae",
                      // ]}
                      masks={{
                        eg: ".. ........",
                        sa: ".. ... ....",
                        ae: ".. .......",
                      }}
                      country="sa"
                      // countryCodeEditable={false}
                      value={form.PhoneNumber}
                      onChange={(mobile) =>
                        general(
                          [
                            {
                              prop: "data.form.PhoneNumber",
                              value: mobile,
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        )
                      }
                    />
                  </div>
                </Col>

                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <Input
                      rows="3"
                      className="add_input"
                      placeholder={local.Address}
                      value={form.Description.key}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "data.form.Description.key",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        )
                      }
                    />
                  </div>
                </Col>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <Card
                    className="img_card_container mx-auto"
                    style={{ width: "100%" }}
                  >
                    <Row className="fullWidthHeight">
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="alignItem flex"
                      >
                        <Dropzone
                          accept="image/*"
                          multiple
                          onDrop={(acceptedFiles) =>
                            saveImage(
                              acceptedFiles,
                              "data.form.Images",
                              general,
                              MERGE_CONTRIBUTION_PROPS
                            )
                          }
                        >
                          {({ getRootProps, getInputProps, acceptedFiles }) => (
                            <div {...getRootProps()} className="cursor">
                              <input
                                {...getInputProps()}
                                multiple={true}
                                accept="image/*"
                                defaultValue={acceptedFiles}
                              />

                              <div className="img_conteiner_palce">
                                <img
                                  src={image_icon}
                                  alt=""
                                  className="imgFull"
                                />
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        {renderPhotos(
                          form.Images,
                          "data.form.Images.",
                          general,
                          DELETE_CONTRIBUTION_PROPS
                        )}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="mt-3 mx-auto"
                >
                  <Card
                    className="mapCard"
                    style={{
                      height: "400px",
                      width: "100%",
                    }}
                  >
                    <SearchBox
                      mapSearch={mapSearch}
                      general={general}
                      action={UPDATE_CONTRIBUTION_PROPS}
                      propObj={[{ prop: "data.form.Geometry", value: "" }]}
                      myPosition={myPosition}
                      ipAddress={ipAddress}
                      getSocketResult={getSocketResult}
                      handlePlaceSearch={handlePlaceSearch}
                    />
                    <TooMap
                      location={location}
                      onMapClick={onMapClick}
                      general={general}
                      point={extractLatLng(form.Geometry)}
                      place_point={newPoint}
                      action={findContribution}
                      action_parameter={form}
                    />
                    {/* 
                          <GeneralMap
                            location={location}
                            onMapClick={onMapClick}
                            general={general}
                            point={extractLatLng(form.Geometry)}
                            place_point={newPoint}
                            action={findContribution}
                            action_parameter={form}
                          /> */}
                  </Card>
                  {(form.Geometry.length === 0 || !form.Geometry) &&
                  errorMessages.Location ? (
                    <span className="error_message">
                      {errorMessages.Location}
                    </span>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className="mt-5 mx-auto"
                >
                  <button
                    disabled={isLoadingPlaces === true ? true : false}
                    className="save_btn"
                    onClick={(e) =>
                      handleSave(e, addContribution, form, general)
                    }
                  >
                    {isLoadingCreate === true ? <Spinner /> : local.Save}
                  </button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const GeneralMaptateToProps = ({ ContribuationsR, GeneralR }) => {
  return {
    form: ContribuationsR.data.form,
    errorMessages: ContribuationsR.data.errorMessages,
    exists_places: ContribuationsR.data.exists_places,
    isLoadingCreate: ContribuationsR.isLoadingCreate,
    isLoadingPlaces: ContribuationsR.data.isLoadingPlaces,
    location: GeneralR.location,
    layersOptions: ContribuationsR.layersOptions,
    lightbox: GeneralR.lightbox,
    mapSearch: GeneralR.mapSearch,
    myPosition: GeneralR.myPosition,
    ipAddress: GeneralR.ipAddress,
  };
};
export default connect(GeneralMaptateToProps, actions)(CreateContribuation);
