import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import TableList from './TableList';
import { getUser } from '../../utils/StorageFunctions';
import ProfileModal from '../../utils/ProfileModal';
import UserRoleProfile from './UserRoleProfile';
import { getPermissions } from '../../utils/Permissions';
import UserRoleSearch from './UserRoleSearch';
import { CLEAR_USER_ROLES_STATE } from '../../Redux/Actions/types';

class UserClaims extends Component {
  componentDidMount() {
    this.getUserRole();
  }

  getUserRole = () => {
    const { history, general } = this.props;
    general([], CLEAR_USER_ROLES_STATE);
    if (getUser() === null) {
      history.push('/login');
    } else {
      if (
        getPermissions(true, true).RoleBusiness_GetUserRole === undefined &&
        getPermissions(true, true).Administrator === undefined
      ) {
        history.push('/');
      }
    }
  };
  render() {
    const {
      history,
      data,
      deleteRoleUser,
      fetchUserRoles,
      general,
      usersOptions,
      deleteMultipleUsersRoles,
      profileModal,
      loadUserOptions,
    } = this.props;
    return (
      <div className='page-container'>
        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={local.UserRoleProfile}
          Children={UserRoleProfile}
          usersOptions={usersOptions}
        />

        <Row>
          <Col>
            <UserRoleSearch
              fetchUserRoles={fetchUserRoles}
              general={general}
              usersOptions={usersOptions}
              data={data}
              loadUserOptions={loadUserOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col className='mt-4'>
            <TableList
              history={history}
              data={data}
              deleteRoleUser={deleteRoleUser}
              general={general}
              deleteMultipleUsersRoles={deleteMultipleUsersRoles}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ UserRolesR, UsersR, GeneralR }) => {
  return {
    data: UserRolesR.data,
    usersOptions: UsersR.usersOptions,
    profileModal: GeneralR.profileModal,
  };
};
export default connect(mapStateToProps, actions)(UserClaims);
