import React from "react";
import { UPDATE_DISTRIBUTORS_PROPS } from "../../Redux/Actions/types";
import { convertArrayToObject } from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { confirmAlert } from "react-confirm-alert";
import { validateForm } from "../Branches/BranchFunctions";

export const onMapClick = (lat, lng, general) => {
  general(
    [
      { prop: "data.form.Lat", value: lat },
      { prop: "data.form.Lng", value: lng },
    ],
    UPDATE_DISTRIBUTORS_PROPS
  );
};

export const handleSave = (e, action_type, form, general, venueId, history) => {
  e.preventDefault();
  if (
    validateForm(form, venueId, general, UPDATE_DISTRIBUTORS_PROPS) === true
  ) {
    let newform = Object.assign({}, form);
    if (form.WorkingHours.length > 0) {
      let newOpenningHours = convertArrayToObject(form.WorkingHours);
      newform.WorkingHours = newOpenningHours;
    }
    if (newform.Photos) {
      if (newform.Photos.length > 0) {
        if (newform.Photos.find((p) => !p[0] instanceof File)) {
          delete newform.Photos;
        }
      }
    }

    // if (newform.id !== undefined) {
    //   if (newform.Photos.length > 0) {
    //     if (newform.Photos > 1) {
    //       let entry = newform.Photos.filter((f) => Array.isArray(f));
    //       if (entry.length > 0) {
    //         newform.Photos = entry;
    //       } else {
    //         delete newform.Photos;
    //       }
    //     }
    //   } else {
    //     if (!newform.Photos[0] instanceof File) {
    //       delete newform.Photos;
    //     }
    //   }
    // }
    if (newform.id === undefined) {
      action_type(newform, venueId, history);
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{local.Sure}</h1>
              <p>{local.ConfirmEdit}</p>
              <button onClick={onClose}>{local.No}</button>
              <button
                onClick={() => {
                  action_type(newform, history);
                  onClose();
                }}
              >
                {local.Yes}
              </button>
            </div>
          );
        },
      });
    }
  }
};
