import React, { useState } from "react";
import { Button, Row, Col, Input, Card } from "reactstrap";
import { local } from "../../lang/local";
import union from "../../assets/img/union.png";
import { onMapClick } from "./BusinessFunctions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TooMap from "../../General/TooMap";
import strik from "../../assets/img/strik.png";

const MapModal = ({ general, location, point, defaulValue }) => {
  const [modal, setModal] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const toggle = () => setModal(!modal);

  return (
    <>
      <div className="container_add_input cursor" onClick={toggle}>
        <img src={strik} alt="strik" className="strik_icon" />
        <Input
          placeholder={local.LocateMap}
          className="add_input"
          value={defaulValue}
        />
        <span>
          <img src={union} style={{ marginInlineEnd: "10px" }} alt="" />
        </span>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={fullScreen}
        open={modal}
        onClose={toggle}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="profile_card">
          <DialogTitle id="responsive-dialog-title center">
            <h3 className="text-center mt-3">{local.SelectOnMap}</h3>
          </DialogTitle>
          <DialogContent>
            <div className="ml-3 mr-3 mt-4 mb-4">
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Card
                    className="map_container"
                    style={{
                      height: "500px",
                      width: "100%",
                    }}
                  >
                    <TooMap
                      location={location}
                      onMapClick={onMapClick}
                      general={general}
                      point={point.Lat !== null ? point : null}
                    />
                    {/* <GeneralMap
                      location={location}
                      onMapClick={onMapClick}
                      general={general}
                      point={point.Lat !== null ? point : null}
                    /> */}
                  </Card>
                </Col>
              </Row>
            </div>
          </DialogContent>
          <Row className="mt-3">
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="mt-5 center"
            >
              <Button
                className="save_btn"
                style={{ width: "220px" }}
                onClick={toggle}
              >
                {local.Done}
              </Button>
            </Col>
          </Row>
        </div>
      </Dialog>
    </>
  );
};

export default MapModal;
