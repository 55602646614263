import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Mapy } from "mapy_sdk/lib";
import { getInitLocation, replaceUrl, updateUrl } from "../Maps/MapFunctions";
import zoom_location from "../../../assets/img/zoom_location.png";

import {
  UPDATE_DIRECTION_MENU_PROPS,
  UPDATE_LOCATION_MENU_PROPS,
  UPDATE_SEARCH_MENU_PROPS,
} from "../../../Redux/Actions/types";
import start_icon from "../../../assets/img/start_icon.png";
import icon_zoom from "../../../assets/img/icon_zoom.svg";
import marker_icon from "../../../assets/img/marker.png";
import { addMarker, removeMarker, addCustomMarker } from "./MapyFunctions";
import { handleLocationMenu, handleRouting } from "./MapyComponent";
import {
  handleBranchesBulkLink,
  handleSearchResult,
  updateSearchDirectionType,
  handleAddContribuation,
} from "../Maps/MapComponent";
import ComplaintTypeModel from "../../ComplaintType/ComplaintTypeModel";
let map = new Mapy();
let direction_type = "";

// onBaseLayerClick

var location_marker;
let source_marker;
let destination_marker;
let start_dergable_Marker;
let end_dergable_Marker;
let zoomed_marker;
let markers_results = [];

const MapyView = (props) => {
  const {
    search_data,
    direction_data,
    location_data,
    history,
    general,
    getSocketResult,
    myPosition,
    searchResult,
    search_direction,
    ipAddress,
    startLocation,
    endLocation,
    addContribuation,
    fetchCompany,
    branches,
    venue,
    fetchPlace,
    getBranchOrDistributor,
    fetchComplaint,
    isOpenAddContribution,
    allComplaintTypes,
  } = props;

  const [mapOption, setMapOption] = useState({
    lat: "",
    lng: "",
    zoom: "",
    feature: "",
  });
  useEffect(() => {
    getInitLocation(initializeMap, ipAddress);
  }, []);
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevProps = usePrevious({
    searchResult,
    location_data,
    direction_data,
    search_direction,
    startLocation,
    endLocation,
    addContribuation,
    branches,
    mapOption,
  });

  useLayoutEffect(() => {
    return () => {
      // Your code here.
      map.removeBaseLayer();
      map.popMap();
    };
  }, []);
  const onGetLocation = (e) => {
    e.preventDefault();

    removeMarker(location_marker, map, "location");

    let lat = ipAddress.lat;
    let lng = ipAddress.lng;

    if (myPosition.allowLocation === true) {
      lat = myPosition.lat;
      lng = myPosition.lng;
    }
    let point = { lat: lat, lng: lng };

    general(
      [
        { prop: "startLocation.lat", value: lat },
        { prop: "startLocation.lng", value: lng },
      ],
      UPDATE_DIRECTION_MENU_PROPS
    );
    debugger;
    // map.setCenter(point);
    // map.setZoom(18);

    if (direction_data.isOpen === true) {
      source_marker = true;
      addMarker(map, point, start_icon, [19, 20], "source", true);
    } else {
      zoomed_marker = true;
      addMarker(map, point, icon_zoom, [28, 43], "zoomed", true);
    }
  };
  useEffect(() => {
    if (prevProps !== undefined) {
      updateSearchDirectionType(
        prevProps.search_direction.type,
        search_direction.type,
        direction_type
      );
    }
  }, [search_direction]);

  useEffect(() => {
    if (prevProps !== undefined) {
      handleSearchResult(
        prevProps.searchResult,
        searchResult,
        search_data.type,
        map,
        history,
        drawSearchMarkers,
        removeMarkers
      );
    }
  }, [searchResult]);

  useEffect(() => {
    if (prevProps !== undefined) {
      handleLocationMenu(
        prevProps.location_data,
        location_data,
        search_data.defaultZoom,
        location_marker,
        removeMarkers,
        onUpdateMap,
        isOpenAddContribution,
        map
      );
    }
  }, [location_data]);

  useEffect(() => {
    if (prevProps !== undefined) {
      handleRouting(
        prevProps.startLocation,
        startLocation,
        map,
        history,
        prevProps.endLocation,
        endLocation,
        prevProps.direction_data,
        direction_data,
        source_marker,
        destination_marker,
        start_dergable_Marker,
        end_dergable_Marker,
        getSocketResult,
        location_data,
        location_marker
      );
    }
  }, [direction_data, startLocation, endLocation]);

  useEffect(() => {
    if (prevProps !== undefined) {
      handleBranchesBulkLink(
        prevProps.branches,
        branches,
        map,
        history,
        drawSearchMarkers,
        removeMarkers
      );
    }
  }, [branches]);

  useEffect(() => {
    if (prevProps !== undefined) {
      handleAddContribuation(
        prevProps.addContribuation.form,
        addContribuation.form,
        prevProps.location_data,
        location_data,
        location_marker,
        general,
        removeMarkers,
        map
      );
    }
  }, [addContribuation]);

  useEffect(() => {
    if (prevProps !== undefined) {
      if (
        JSON.stringify(prevProps.mapOption) !== JSON.stringify(mapOption) &&
        mapOption.lat
      ) {
        manipulateMapClick();
      }
    }
  }, [mapOption]);

  const manipulateMapClick = () => {
    if (mapOption.feature !== undefined) {
      general(
        [
          {
            prop: "location_data.address",
            value: mapOption.feature.properties.name,
          },
        ],
        UPDATE_LOCATION_MENU_PROPS
      );
      onUpdateMap(
        mapOption.lat,
        mapOption.lng,
        mapOption.zoom,
        false,
        false,
        search_direction.type,
        true,
        true,
        true
      );
    } else {
      onUpdateMap(
        mapOption.lat,
        mapOption.lng,
        mapOption.zoom,
        true,
        false,
        search_direction.type,
        true,
        undefined,
        true
      );
    }
  };

  const drawSearchMarkers = (searchPlaces, type) => {
    markers_results = [];
    if (searchPlaces.length > 0) {
      searchPlaces.map((s, index) => {
        markers_results.push("location" + index);
        if (type === undefined) {
          addMarker(
            map,
            [s.value[1], s.value[0]],
            marker_icon,
            [28, 43],
            "location" + index,
            false,
            s.label,
            s.type
          );
        } else {
          addCustomMarker(
            map,
            [s.value[1], s.value[0]],
            venue.logo,
            [28, 43],
            "location" + index,
            false,
            s.label,
            s.type,
            onUpdateMap,
            general
          );
        }
      });
      // map.zoomToFeatures([{ id: "branches", type: ZoomableShapes.group }]);
    }
  };

  function removeMarkers() {
    if (markers_results.length > 0) {
      for (let i = 0; i < markers_results.length; i++) {
        if (markers_results[i]) {
          map.deleteMarker(markers_results[i]);
        }
      }
    }
    markers_results = [];
  }

  const initializeMap = (lat, lng) => {
    let entry = updateUrl(
      lat,
      lng,
      15,
      history,
      fetchCompany,
      fetchPlace,
      getBranchOrDistributor,
      fetchComplaint
    );
    debugger;
    const defaultOptions = {
      //   scrollWheelZoom: true,
      fullscreencontrol: false,
      maxZoom: 18,
      zoom: entry.zoom,
      center: {
        lat: entry.lat,
        lng: entry.lng,
      },
    };

    map.initMap(document.getElementById("map"), defaultOptions);
    if (entry.lat === lat && entry.lng === lng) {
      zoomed_marker = true;
      addMarker(map, { lat, lng }, icon_zoom, [28, 43], "zoomed", true);
    } else {
      onUpdateMap(
        entry.lat,
        entry.lng,
        entry.zoom,
        true,
        false,
        "",
        true,
        undefined,
        undefined
      );
    }

    map.onBaseLayerClick = function (e) {
      let lat = e.leaflet_event.latlng.lat;
      let lng = e.leaflet_event.latlng.lng;
      let zoom = e.leaflet_event.target._zoom;
      general(
        [
          { prop: "location_data.icon_type", value: "click" },
          { prop: "location_data.placeType", value: "place" },
        ],
        UPDATE_LOCATION_MENU_PROPS
      );

      setMapOption({ ...mapOption, lat, lng, zoom, feature: e.feature });
    };

    map.onZoomChange = (e) => {
      let zoom = e.target._zoom;
      let lat = e.sourceTarget._lastCenter.lat;
      let lng = e.sourceTarget._lastCenter.lng;
      // if (window.location.pathname.split('=').length === 1 && zoom <= 18) {
      replaceUrl(history, lat, lng, zoom);
      // }
    };
  };

  const onUpdateMap = (
    lat,
    lng,
    zoom,
    get_address,
    update_map_zoom,
    type,
    get_distance,
    toDrawMarker,
    toUpdateMap
  ) => {
    removeMarker(zoomed_marker, map, "zoomed");

    if (toUpdateMap === undefined) {
      debugger;
      map.setCenter({ lat: lat, lng: lng });
    }

    if (update_map_zoom === true) {
      map.setZoom(zoom);
      general(
        [{ prop: "search_data.defaultZoom", value: zoom }],
        UPDATE_SEARCH_MENU_PROPS
      );
    }
    if (type === "") {
      if (toDrawMarker === undefined) {
        location_marker = true;
        addMarker(
          map,
          { lat: lat, lng: lng },
          marker_icon,
          [28, 43],
          "location",
          true
        );
      } else {
        removeMarker(location_marker, map, "location");
      }

      general(
        [{ prop: "search_data.toViewSearch", value: false }],
        UPDATE_SEARCH_MENU_PROPS
      );
      general(
        [
          { prop: "location_data.lat", value: lat },
          { prop: "location_data.lng", value: lng },
          { prop: "location_data.toViewSideInfo", value: true },
        ],
        UPDATE_LOCATION_MENU_PROPS
      );

      if (get_address === true) {
        // if (get_address !== undefined) {
        getSocketResult(null, lat, lng, "location_data.address");
        // }
      }

      if (get_distance !== undefined) {
        getSocketResult(
          null,
          {
            start:
              myPosition.allowLocation === true
                ? myPosition.lat
                : ipAddress.lat,
            end: lat,
          },
          {
            start:
              myPosition.allowLocation === true
                ? myPosition.lng
                : ipAddress.lng,
            end: lng,
          },
          "distance"
        );
      }
    } else {
      if (type === "start") {
        general(
          [
            { prop: "startLocation.lat", value: lat },
            { prop: "startLocation.lng", value: lng },
          ],
          UPDATE_DIRECTION_MENU_PROPS
        );

        getSocketResult(null, lat, lng, "startLocation.title");
      } else {
        general(
          [
            { prop: "endLocation.lat", value: lat },
            { prop: "endLocation.lng", value: lng },
          ],
          UPDATE_DIRECTION_MENU_PROPS
        );
        getSocketResult(null, lat, lng, "endLocation.title");
      }
      if (search_direction.isAddHomeWork === false) {
        general(
          [
            { prop: "search_direction.isOpen", value: false },
            { prop: "direction_data.isOpen", value: true },
          ],
          UPDATE_DIRECTION_MENU_PROPS
        );
      }
    }
  };
  const getPoints = () => {
    let lat;
    let lng;

    if (location_data.lat && location_data.lng) {
      lat = location_data.lat;
      lng = location_data.lng;
    } else {
      if (myPosition.allowLocation === true) {
        lat = myPosition.lat;
        lng = myPosition.lng;
      } else {
        lat = ipAddress.lat;
        lng = ipAddress.lng;
      }
    }

    return { lat, lng };
  };
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        id="map"
        style={{
          width: "100%",
          height: "100vh",
        }}
      >
        <div className="map_icon">
          <ComplaintTypeModel
            points={getPoints()}
            allComplaintTypes={allComplaintTypes}
            general={general}
          />

          <div className="zoom_icon cursor mb-5" onClick={onGetLocation}>
            <img src={zoom_location} alt="zoom" className="block" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MapyView;
