import { local } from "../lang/local";

export const renderCustomIcon = (imgUrl, companyIcon, title) => {
  let hasTitle = true;
  if (title !== undefined) {
    if (!title) {
      hasTitle = false;
    }
  } else {
    hasTitle = false;
  }

  if (hasTitle === true) {
    return (
      "student-div-icon",
      `<div class="custom_marker_container">
<div class="">
<img src="${companyIcon}" class="custom_marker"/>
<img src="${imgUrl}" class="marker_tab"/>
</div>
<div class="custom_icon_label">${title}</div>
</div>`
    );
  } else {
    return (
      "student-div-icon",
      `<div class="custom_marker_container">
<div class="">
<img src="${companyIcon}" class="custom_marker"/>
<img src="${imgUrl}" class="marker_tab"/>
</div>
</div>`
    );
  }
};

export const centerMap = (items, map) => {
  if (items.length > 0 && map !== null) {
    let points = items.map((item) => {
      return { lat: item[1], lng: item[0] };
    });

    if (points.length > 0) {
      const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
      const centerLat = arrAvg(points.map((c) => c.lat));
      const centerLng = arrAvg(points.map((c) => c.lng));

      if (isNaN(centerLng) || isNaN(centerLat)) {
        return null;
      } else {
        map.setCenter({ lat: centerLat, lng: centerLng });
      }
    }
  }
};

export const replaceUrl = (history, lat, lng, zoom) => {
  history.replace({
    pathname: `/@${lat},${lng},${zoom}z`,
  });
};

export const getPoints = (items, key, title, type) => {
  if (items) {
    let name = "";
    let array_of_points = items.map((item) => {
      if (title !== undefined) {
        if (item[title]) {
          name = item[title];
        } else {
          name = "";
        }
      } else {
        if (item.name[local.lang]) {
          name = item.name[local.lang];
        } else {
          name = "";
        }
      }
      return {
        point: item[key] ? extractLatLng(item[key]) : null,
        name,
        id: item.id,
        type: type !== undefined ? item.type : null,
      };
    });

    if (array_of_points) {
      return array_of_points;
    }
  }
};

function getLatLng(point) {
  let newPoint = point.replace(/[\(\)']+/g, "");
  var wkPoint = newPoint.replace(/^\D+/g, "");

  newPoint = wkPoint.split(" ");

  let latlng = newPoint.filter((p) => +p);

  return { lat: +latlng[1], lng: +latlng[0] };
}
export const extractLatLng = (point) => {
  try {
    if (typeof point === "string") {
      if (point !== "") {
        let entry = point.split("POINT")[1].split(" ");

        let lat;
        let lng;
        if (entry.length === 3) {
          lat = +point.split("POINT")[1].split(" ")[2].split(")")[0];
          lng = +point.split("POINT")[1].split(" ")[1].split("(")[1];
        } else {
          lat = +point.split("POINT")[1].split(" ")[1].split(")")[0];
          lng = +point.split("POINT")[1].split(" ")[0].split("(")[1];
        }

        return { lat, lng };
      } else {
        return point;
      }
    } else if (Array.isArray(point)) {
      if (point.length > 0) {
        return getLatLng(point[0]);
      } else {
        return null;
      }
    } else {
      return point;
    }
  } catch (e) {
    return point;
  }
};

export const getPolygons = (items, key, title) => {
  if (items) {
    if (items.length > 0) {
      let polygons = [];
      let array_of_points = items.map((item) => {
        return { point: item[key], title: item[title] };
      });
      array_of_points.map((item) => {
        if (item.point.includes("MULTIPOLYGON")) {
          item.point
            .split("MULTIPOLYGON")[1]
            .split("))")
            .map((sp, index) => {
              if (sp !== "") {
                if (index === 0) {
                  polygons.push({
                    poly: sp.split("(((")[1].split(","),
                    title: item.title,
                  });
                } else {
                  polygons.push({
                    poly: sp.split("((")[1].split(","),
                    title: item.title,
                  });
                }
              }
            });
        } else {
          polygons.push({
            poly: item.point
              .split("POLYGON")[1]
              .split("((")[1]
              .split(")))")[0]
              .split(","),
            title: item.title,
          });
        }
      });

      let newPolygons = [];

      polygons.map((it) => {
        let newPoly = it.poly.map((p, i) => {
          if (i === 0) {
            return { lat: +p.split(" ")[1], lng: +p.split(" ")[0] };
          } else {
            return { lat: +p.split(" ")[2], lng: +p.split(" ")[1] };
          }
        });

        newPolygons.push({ polygon: newPoly, title: it.title });
      });
      var filterdPolygon = [
        ...new Set(newPolygons.map(({ title }) => title)),
      ].map((e) => newPolygons.find(({ title }) => title == e));

      return filterdPolygon;
    }
  }
};
