import React, { useState } from 'react';
import { Input } from 'reactstrap';
import strik from '../../assets/img/strik.png';
import egy_open from '../../assets/img/egy_open.png';
import eye_close from '../../assets/img/eye_close.png';
export const CustomPassword = ({ value, icon, label, onChange, className }) => {
  const [showPassword, setShowPassword] = useState(false);

  const renderEyePassword = (isShown, setShown) => {
    if (isShown === true) {
      return (
        <img
          onClick={() => setShown(false)}
          alt='egy_open'
          className='eye_pass cursor'
          style={{ marginInlineEnd: '20px' }}
          src={egy_open}
        />
      );
    } else {
      return (
        <img
          onClick={() => setShown(true)}
          src={eye_close}
          alt='egy_open'
          className='eye_pass cursor'
          style={{ marginInlineEnd: '20px' }}
        />
      );
    }
  };

  return (
    <div className='container_add_input mx-auto'>
      <input type='password' style={{ position: 'absolute', zIndex: '-1' }} />
      {icon ? <img src={strik} alt='strik' className='strik_icon' /> : null}
      <Input
        type={showPassword === true ? 'text' : 'password'}
        placeholder={label}
        className={className}
        // 'removed_input_style pass_input'
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {renderEyePassword(showPassword, setShowPassword)}
    </div>
  );
};
