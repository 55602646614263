import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import chat_active from '../../assets/img/chat_active.png';
import chat_inactive from '../../assets/img/chat_inactive.png';
import calendar_active from '../../assets/img/calendar_active.png';
import calendar_inactive from '../../assets/img/calendar_inactive.png';
import { local } from '../../lang/local';
import { TicketCard } from './TicketCard';
import SearchBox from './SearchBox';
import { Row, Col } from 'reactstrap';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: '100%',height:'100%' }}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabItem({
  data,
  DeleteTicket,
  general,
  history,
  ticketId,
  fetchTicketMessages,
  filterMessageArray,
  data_message,
  closeTicket,
  fetchTickets,
  generalUsers,
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.transparent',
        display: 'flex',
        height: '100vh',
        overflow: 'scroll',
      }}
    >
      <Tabs
        // allowScrollButtonsMobile={true}
        centered={true}
        // indicatorColor="red"
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab
          label={local.Tickets}
          icon={<img src={value === 0 ? chat_active : chat_inactive} alt='' />}
          {...a11yProps(0)}
        />
        <Tab
          label={local.Archived}
          icon={
            <img
              src={value === 1 ? calendar_active : calendar_inactive}
              alt=''
            />
          }
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mx-auto'>
            <SearchBox
              fetchTickets={fetchTickets}
              general={general}
              filterArray={data.filterArray}
              generalUsers={generalUsers}
            />
          </Col>
        </Row>

        <Row>
          {data.items
            ? data.items.map((item, index) => {
                if (!item.user && generalUsers.length > 0) {
                  item.user = generalUsers.find((u) => u.id === item.userId);
                }
                if (item.status === true) {
                  return (
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className='mx-auto'
                      key={index}
                    >
                      <TicketCard
                        item={item}
                        DeleteTicket={DeleteTicket}
                        index={index}
                        general={general}
                        history={history}
                        ticketId={ticketId}
                        fetchTicketMessages={fetchTicketMessages}
                        filterMessageArray={filterMessageArray}
                        data_message={data_message}
                        closeTicket={closeTicket}
                        generalUsers={generalUsers}
                      />
                    </Col>
                  );
                }
              })
            : null}
        </Row>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Row>
          <Col xl={12} lg={112} md={112} sm={12} xs={12} className='mx-auto'>
            <SearchBox
              fetchTickets={fetchTickets}
              general={general}
              filterArray={data.filterArray}
              generalUsers={generalUsers}
            />
          </Col>
        </Row>
        <Row>
          {data.items !== null
            ? data.items.map((item, index) => {
                if (!item.user && generalUsers.length > 0) {
                  item.user = generalUsers.find((u) => u.id === item.userId);
                }
                if (item.status === false) {
                  return (
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className='mx-auto'
                      key={index}
                    >
                      <TicketCard
                        item={item}
                        DeleteTicket={DeleteTicket}
                        index={index}
                        general={general}
                        history={history}
                        ticketId={ticketId}
                        fetchTicketMessages={fetchTicketMessages}
                        filterMessageArray={filterMessageArray}
                        data_message={data_message}
                        closeTicket={closeTicket}
                      />
                    </Col>
                  );
                }
              })
            : null}
        </Row>
      </TabPanel>
    </Box>
  );
}
export default TabItem;
