import React from "react";
import { Spinner, Row, Col, Button, Input, Card } from "reactstrap";
import {
  columnAlignLeft,
  isRtl,
  selectSearchStyle,
} from "../../General/GeneralStyle";
import { getLanguage, local } from "../../lang/local";
import { getDefaultValue } from "../../General/SelectFunctions";
import { UPDATE_DISTRIBUTORS_PROPS } from "../../Redux/Actions/types";
import {
  removeUnsetValuesFromFilters,
  showConfirmMessage,
} from "../../General/GeneralFunctions";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import { AsyncPaginate } from "react-select-async-paginate";

const DistributorSearch = ({
  venuesOptions,
  loadVenueOptions,
  filterArray,
  general,
  isLoading,
  fetchDistributors,
}) => {
  const handleSearch = (e) => {
    e.preventDefault();

    if (
      filterArray.find((f, i) => i !== 0 && f.value !== "" && f.value !== null)
    ) {
      let newFilterArr = [...filterArray];
      if (!newFilterArr[3].value) {
        showConfirmMessage(local.SelectCompany, "error");
      } else {
        newFilterArr = removeUnsetValuesFromFilters(newFilterArr);
        fetchDistributors(newFilterArr, 1);
      }
    } else {
      showConfirmMessage(local.AnyDataRequired, "error");
    }
  };

  const onChangeName = (value) => {
    let arregex = /[\u0600-\u06FF]/;

    let lang = "en";
    if (arregex.test(value) === true) {
      lang = "ar";
    } else {
      lang = "en";
    }

    general(
      [
        { prop: "data.filterArray." + 1 + ".value", value: value },
        {
          prop: "data.filterArray." + 1 + ".fieldName",
          value: `Name@${lang}`,
        },
      ],
      UPDATE_DISTRIBUTORS_PROPS
    );
  };

  return (
    <Card className="search_card">
      <Row className="mt-1 mb-3">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Row className="alignItem">
            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <Input
                className="search_input"
                placeholder={local.Name}
                value={filterArray[1].value}
                onChange={(e) => onChangeName(e.target.value)}
              />
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              {/* <Input className="search_input" placeholder="Name" /> */}
              <div className="search_phone_container">
                <PhoneInput
                  country="sa"
                  lang={getLanguage() === "ar" ? ar : ""}
                  // onlyCountries={["eg", "sa", "ku", "lb", "om", "bh", "ae"]}
                  masks={{
                    eg: ".. ........",
                    sa: ".. ... ....",
                    ae: ".. .......",
                  }}
                  countryCodeEditable={false}
                  value={filterArray[2].value}
                  onChange={(phone) =>
                    general(
                      [
                        {
                          prop: "data.filterArray." + 2 + ".value",
                          value: phone,
                        },
                      ],
                      UPDATE_DISTRIBUTORS_PROPS
                    )
                  }
                />
              </div>
            </Col>

            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <div className="search_select_container">
                <AsyncPaginate
                  key={filterArray[3].value}
                  placeholder={local.Venues}
                  value={getDefaultValue(filterArray[3].value, venuesOptions)}
                  loadOptions={loadVenueOptions}
                  onChange={(e) =>
                    e !== null
                      ? general(
                          [
                            {
                              prop: "data.filterArray." + 3 + ".value",
                              value: +e.value,
                            },
                          ],
                          UPDATE_DISTRIBUTORS_PROPS
                        )
                      : general(
                          [
                            {
                              prop: "data.filterArray." + 3 + ".value",
                              value: null,
                            },
                          ],
                          UPDATE_DISTRIBUTORS_PROPS
                        )
                  }
                  additional={{
                    page: 1,
                  }}
                  styles={selectSearchStyle}
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
            </Col>
            <Col
              xl={3}
              lg={3}
              md={3}
              sm={6}
              xs={12}
              className={columnAlignLeft() + " mt-4"}
            >
              <Button
                disabled={isLoading}
                className="search_btn"
                onClick={handleSearch}
              >
                {isLoading === true ? <Spinner color="#fff" /> : local.Search}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default DistributorSearch;
