import {
  UPDATE_DIRECTION_MENU_PROPS,
  UPDATE_LOCATION_MENU_PROPS,
} from "../../../Redux/Actions/types";
import { centerMap } from "../Maps/MapFunctions";
import start_icon from "../../../assets/img/start_icon.png";
import end_icon from "../../../assets/img/end_icon.png";
import { renderCustomIcon } from "../../../General/MapFunctions";
import branch from "../../../assets/img/branch.svg";
import distributor from "../../../assets/img/distributor.svg";
import { local } from "../../../lang/local";

export const addMarker = (map, location, imgUrl, imgSize, id, focus, title) => {
  map.AddMarker(
    {
      imgUrl,
      location,
      imgSize,
      title: title !== undefined ? title : "",
      popup: title !== undefined ? `<h5>${title}</h5>` : null,
      //   properties: {
      //     name: "smsm",
      //     cloud: "skdhfs",
      //   },
      onClick: (id, properties) => {
        console.log("Marker", id, properties);
      },

      // iconAnchor: iconPostion.bottom_center,
      dragable: false,
      groupName: "branches",
    },

    id,
    focus
  );
};

export const addCustomMarker = (
  map,
  location,
  companyIcon,
  imgSize,
  id,
  focus,
  title,
  type,
  onUpdateMap,
  general
) => {
  let imageUrl = type === 0 ? branch : distributor;

  map.AddMarker(
    {
      divIcon: {
        className: "student-div-icon",
        html: renderCustomIcon(imageUrl, companyIcon, title),
        iconSize: imgSize,
        iconAnchor: [14, 26],
      },
      location,
      title: type === 0 ? local.Branch : local.Distributor,
      popup: `<h5>${type === 0 ? local.Branch : local.Distributor}</h5>`,

      onClick: (id, properties) => {
        general(
          [
            { prop: "location_data.icon_type", value: "click" },
            { prop: "location_data.address", value: title },
          ],
          UPDATE_LOCATION_MENU_PROPS
        );

        onUpdateMap(location[0], location[1], 12, false, false, "", true, true);
      },

      dragable: true,
    },

    id,
    focus
  );
};
export const removeMarker = (item, map, id) => {
  try {
    if (item !== undefined) {
      if (item !== null) {
        item = null;
        map.deleteMarker(id);
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const removePolylines = (map, routing) => {
  map.deletePolyline("startLine");
  map.deletePolyline("endLine");
  if (routing.length > 0) {
    return routing.map((route, i) => {
      return map.deletePolyline(route.geometry.type + +i);
    });
  } else {
    map.deleteAllPolylines();
  }
};

export const onClickPolyLine = (
  map,
  index,
  routing,
  general,
  startLocation,
  endLocation,
  history
) => {
  drawSrartEndLines(
    map,
    startLocation,
    endLocation,
    routing,
    index,
    general,
    history
  );
  if (routing.length > 0) {
    routing.map((route, i) => {
      let line = route.geometry.coordinates.map((coord) => {
        return { lat: coord[1], lng: coord[0] };
      });
      let color = "#B0DEE8";
      if (+i === index) {
        color = "#12A9CC";

        general(
          [
            { prop: "direction_data.active_class", value: index },
            { prop: "direction_data.selecting_routing", value: route },
            {
              prop: "direction_data.start_location",
              value: route.legs[0].steps[0],
            },
            {
              prop: "direction_data.end_location",
              value: route.legs[0].steps[route.legs[0].steps.length - 1],
            },
          ],
          UPDATE_DIRECTION_MENU_PROPS
        );
      }

      addPolyLine(
        map,
        route.geometry.type + +i,
        color,
        3,
        3,
        general,
        line,
        routing,
        +i,
        startLocation,
        endLocation,
        history
      );
    });
  }
};

export const addPolylines = (
  map,
  direction_data,
  startLocation,
  endLocation,
  general,
  history,
  index
) => {
  // removeMarker(map, "zoomed");
  if (direction_data.routing.length > 0) {
    drawSrartEndLines(
      map,
      startLocation,
      endLocation,
      direction_data.routing,
      index,
      general,
      history
    );
    direction_data.routing.map((dir, i) => {
      let line = dir.geometry.coordinates.map((coord) => {
        return { lat: coord[1], lng: coord[0] };
      });
      let color;
      if (+i === index) {
        color = "#12A9CC";
      } else {
        color = "#B0DEE8";
      }

      addPolyLine(
        map,
        dir.geometry.type + +i,
        color,
        4,
        4,
        general,
        line,
        direction_data.routing,
        +i,
        startLocation,
        endLocation,
        history
      );
    });

    // removeMarker(map, "location");
    // removeMarker(map, "startDragable");
    // removeMarker(map, "endDragable");
  }
};
const drawSrartEndLines = (
  map,
  startLocation,
  endLocation,
  routing,
  index,
  general,
  history
) => {
  let startPos = {
    lat: startLocation.lat,
    lng: startLocation.lng,
  };
  let endPos = {
    lat: endLocation.lat,
    lng: endLocation.lng,
  };
  if (routing[index]) {
    let startRoutePoint = routing[index].geometry.coordinates[0];
    let last_index = routing[index].geometry.coordinates.length - 1;
    let endRoutePoint = routing[index].geometry.coordinates[last_index];
    let startLine = [];
    let endLine = [];

    startLine.push(startPos, {
      lat: startRoutePoint[1],
      lng: startRoutePoint[0],
    });
    endLine.push(endPos, {
      lat: endRoutePoint[1],
      lng: endRoutePoint[0],
    });
    addPolyLine(
      map,
      "startLine",
      "#000000",
      1,
      1,
      general,
      startLine,
      null,
      null
    );

    addMarker(map, startPos, start_icon, [19, 20], "source", true);
    addMarker(map, endPos, end_icon, [19, 20], "destination", false);

    addPolyLine(map, "endLine", "#000000", 1, 1, general, endLine, null, null);
    let newPosition = [];
    newPosition.push(
      { value: [startPos.lng, startPos.lat] },
      { value: [startRoutePoint[0], startRoutePoint[1]] },
      { value: [endPos.lng, endPos.lat] },
      { value: [endRoutePoint[0], endRoutePoint[1]] }
    );
    centerMap(newPosition, map, history);
  }
};

export const addPolyLine = (
  map,
  id,
  color,
  weight,
  Opacity,
  general,
  path,
  routing,
  index,
  startLocation,
  endLocation,
  history
) => {
  map.AddPolyline(
    {
      // groupName: "smsm",
      boundaries: path,
      color: color,
      fillOpacity: Opacity,
      weight: weight,
      properties: {
        preporites: routing !== null ? routing[index] : null,
      },
      onClick: (id, properties) => {
        if (properties !== null) {
          onClickPolyLine(
            map,
            index,
            routing,
            general,
            startLocation,
            endLocation,
            history
          );
        }
      },
    },
    id,
    true
  );
};
