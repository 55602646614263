import { UPDATE_BUSINESS_PROPS } from "../../Redux/Actions/types";

export const onMapClick = (lat, lng, general) => {
  general(
    [
      { prop: "data.form.Lat", value: lat },
      { prop: "data.form.Lng", value: lng },
    ],
    UPDATE_BUSINESS_PROPS
  );
};
