import {
  showConfirmMessage,
  showSuccessedConfirm
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { SendRequest } from "./api";
import {
  DELETE_ROLES_PROPS,
  UPDATE_GENERAL_PROPS,
  UPDATE_ROLES_PROPS
} from "./types";

export const fetchRoles = () => dispatch => {
  dispatch({
    type: UPDATE_ROLES_PROPS,
    payload: [{ prop: "data.isLoading", value: true }]
  });
  SendRequest()
    .get("/Roles")
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_ROLES_PROPS,
          payload: [{ prop: "data.items", value: res.data.data }]
        });
      }

      dispatch({
        type: UPDATE_ROLES_PROPS,
        payload: [{ prop: "data.isLoading", value: false }]
      });
    })
    .catch(e => {
      dispatch({
        type: UPDATE_ROLES_PROPS,
        payload: [{ prop: "data.isLoading", value: false }]
      });
    })
    .finally();
};

export const addRole = formValues => dispatch => {
  dispatch({
    type: UPDATE_ROLES_PROPS,
    payload: [{ prop: "data.isLoadingCreate", value: true }]
  });

  let newFormValues = Object.assign({}, formValues);
  SendRequest()
    .post("/Roles", newFormValues)
    .then(res => {
      if (res.status === 200) {
        showSuccessedConfirm(local.AddedSuccessfully);

        dispatch({
          type: UPDATE_ROLES_PROPS,
          payload: [
            {
              prop: "data.form",
              value: {
                name: "",
                description: ""
              }
            }
          ]
        });
      }
      dispatch({
        type: UPDATE_ROLES_PROPS,
        payload: [{ prop: "data.isLoadingCreate", value: false }]
      });
    })
    .catch(e => {
      dispatch({
        type: UPDATE_ROLES_PROPS,
        payload: [{ prop: "data.isLoadingCreate", value: false }]
      });
      if (Object.values(e)[2].data.data) {
        return Object.values(Object.values(e)[2].data.data).map(err => {
          showConfirmMessage(err[0], "error");
        });
      }

      console.log(e);
    })
    .finally();
};

export const updateRole = (formValues, history) => dispatch => {
  dispatch({
    type: UPDATE_ROLES_PROPS,
    payload: [{ prop: "data.isLoadingCreate", value: true }]
  });

  let newFormValues = Object.assign({}, formValues);
  SendRequest()
    .put(`/Roles/${newFormValues.id}`, newFormValues)
    .then(res => {
      if (res.status === 200) {
        showSuccessedConfirm(local.SuccessfullyUpdated);

        history.push("/dashboard/roles");
      }
      dispatch({
        type: UPDATE_ROLES_PROPS,
        payload: [{ prop: "data.isLoadingCreate", value: false }]
      });
    })
    .catch(e => {
      dispatch({
        type: UPDATE_ROLES_PROPS,
        payload: [{ prop: "data.isLoadingCreate", value: false }]
      });
      console.log(e);
    })
    .finally();
};

export const DeleteRole = (id, index) => dispatch => {
  dispatch({
    type: UPDATE_ROLES_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: null }]
  });
  SendRequest()
    .delete(`/Roles/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(fetchRoles());
        dispatch({
          type: DELETE_ROLES_PROPS,
          payload: [
            {
              prop: "data.items." + index
            }
          ]
        });
        showSuccessedConfirm(local.DeletedSuccessfully);
      }
      dispatch({
        type: UPDATE_ROLES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
    })
    .catch(err => {
      if (Object.values(err)[2] !== undefined) {
        if (local[Object.values(err)[2].data.message]) {
          showConfirmMessage(
            local[Object.values(err)[2].data.message],
            "error"
          );
        } else {
          showConfirmMessage(Object.values(err)[2].data.message, "error");
        }
      }
      dispatch({
        type: UPDATE_ROLES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
      console.log(err);
    })
    .finally();
};

export const getRolesForDropDown = () => dispatch => {
  SendRequest()
    .get("/Roles")
    .then(res => {
      if (res.status === 200) {
        let newRoles = [];
        res.data.data.forEach(role => {
          newRoles.push({
            label: role.name,
            value: role.id
          });
        });

        dispatch({
          type: UPDATE_ROLES_PROPS,
          payload: [{ prop: "rolesOptions", value: newRoles }]
        });
      }
    })
    .catch(e => {})
    .finally();
};

export const deleteMultipleRoles = (ids, setChecked) => dispatch => {
  dispatch({
    type: UPDATE_ROLES_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: true }]
  });

  SendRequest()
    .delete(`/Roles/${ids[0]}`)
    .then(res => {
      if (res.status === 200) {
        ids.shift();

        if (ids.length > 0) {
          dispatch(deleteMultipleRoles(ids, setChecked));
        } else {
          setChecked([]);
          showSuccessedConfirm(local.DeletedSuccessfully);
          dispatch(fetchRoles());
        }
      }
      dispatch({
        type: UPDATE_ROLES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
    })
    .catch(err => {
      if (Object.values(err)[2].data) {
        if (Object.values(err)[2].data.message) {
          showConfirmMessage(Object.values(err)[2].data.message, "error");
        }
      }
      dispatch({
        type: UPDATE_ROLES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
      console.log(err);
    })
    .finally();
};
export const fetchRole = id => dispatch => {
  SendRequest()
    .get(`/Roles/${id}`)
    .then(res => {
      if (res.status === 200) {
        if (res.data.data) {
          let item = res.data.data;
          dispatch(getClaimsByRole(item, true));

          dispatch({
            type: UPDATE_ROLES_PROPS,
            payload: [
              {
                prop: "data.form",
                value: {
                  name: item.name,
                  description: item.description,
                  id
                }
              }
            ]
          });
        }
      }
    })
    .catch(e => {
      console.log(e);
    })
    .finally();
};

export const getClaimsByRole = (item, isEdit) => dispatch => {
  SendRequest()
    .get(`/Roles/GetRoleClaims/${item.id}`)
    .then(res => {
      if (res.status === 200) {
        if (isEdit === undefined) {
          let permissions = [];

          res.data.data.map(r => {
            permissions.push({ label: local[r.claimType], value: r.claimType });
          });

          item.claims = permissions;

          dispatch({
            type: UPDATE_GENERAL_PROPS,
            payload: [
              {
                prop: "profileModal",
                value: { isOpen: true, data: item }
              }
            ]
          });
        } else {
          let claims = res.data.data.map(c => {
            return {
              value: c.claimType,
              id: c.id,
              label: local[c.claimType]
            };
          });
          dispatch({
            type: UPDATE_ROLES_PROPS,
            payload: [
              {
                prop: "data.claims",
                value: claims
              }
            ]
          });
        }
      }
    })
    .catch(e => {})
    .finally();
};
