import React from "react";
import { Col } from "reactstrap";
import GeneralCard from "../../General/GeneralCard";
import { local } from "../../lang/local";

const BusinessAnalysis = ({ history, items }) => {
  const renderAnalysisCard = () => {
    return (
      <div
        className="dashboardUsersAnalysisCard-container"
        style={{ background: "transparent", boxShadow: "none" }}
      >
        <div
          className="dashboardUsersAnalysisCard-title"
          style={{ visibility: "hidden" }}
        >
          {local.Branches + "testtest"}
        </div>

        <div className="circleContainer">
          <div
            className="circle cursor"
            id="firstCircle"
            onClick={(e) => {
              e.preventDefault();
              history.push(items[0].routeName);
            }}
            data-aos="flip-down"
            data-aos-delay="20"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div className="firstCircle_title_text cursor">
              {items[0].label}
            </div>
            <div style={{ fontWeight: "bold" }}>{items[0].value}</div>
          </div>

          <div
            className="circle cursor"
            id="secondCircle"
            onClick={(e) => {
              e.preventDefault();
              history.push(items[1].routeName);
            }}
            data-aos="flip-down"
            data-aos-delay="20"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div className="secondCircle_title_text"> {items[1].label}</div>
            <div style={{ fontWeight: "bold" }}>{items[1].value}</div>
          </div>

          <div
            className="circle cursor"
            id="thirdCircle"
            onClick={(e) => {
              e.preventDefault();
              history.push(items[2].routeName);
            }}
            data-aos="flip-up"
            data-aos-delay="20"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div className="thirdCircle_title_text"> {items[2].label}</div>
            <div style={{ fontWeight: "bold" }}>{items[2].value}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Col className="center" xl={4} lg={4} md={6} sm={12} xs={12}>
      <GeneralCard
        title={local.BusinessAnalysis}
        count={null}
        titleColor="blue_color"
        className="text-center maxWidth"
        background="#fff"
        renderBody={renderAnalysisCard}
        tobeCenter={true}
      />
    </Col>
  );
};

export default BusinessAnalysis;
