import {
  showConfirmMessage,
  showSuccessedConfirm
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { SendRequest } from "./api";
import { DELETE_USER_CLAIMS_PROPS, UPDATE_USER_CLAIMS_PROPS } from "./types";

export const fetchUserClaims = (user_id, claimValue, isDelete) => dispatch => {
  dispatch({
    type: UPDATE_USER_CLAIMS_PROPS,
    payload: [{ prop: "data.isLoading", value: true }]
  });
  SendRequest()
    .get(`/Roles/User/Claims/${user_id}`)
    .then(res => {
      if (res.status === 200) {
        if (res.data.data.length === 0 && isDelete === undefined) {
          showConfirmMessage(local.NoSearch, "error");
        }

        if (claimValue && res.data.data) {
          res.data.data = res.data.data.filter(claim => {
            if (
              claim.claimValue === claimValue ||
              claim.claimValue.includes(claimValue) ||
              claim.claimValue.toUpperCase() === claimValue ||
              claim.claimValue.toLowerCase() === claimValue
            ) {
              return claim;
            }
          });
        }
        dispatch({
          type: UPDATE_USER_CLAIMS_PROPS,
          payload: [{ prop: "data.items", value: res.data.data }]
        });
      }

      dispatch({
        type: UPDATE_USER_CLAIMS_PROPS,
        payload: [{ prop: "data.isLoading", value: false }]
      });
    })
    .catch(e => {
      dispatch({
        type: UPDATE_USER_CLAIMS_PROPS,
        payload: [{ prop: "data.isLoading", value: false }]
      });
    })
    .finally();
};

export const addUserClaim = (formValues, userId, permissions) => dispatch => {
  dispatch({
    type: UPDATE_USER_CLAIMS_PROPS,
    payload: [{ prop: "isLoadingCreate", value: true }]
  });

  return permissions.map((per, index) => {
    var newFormValues = Object.assign({}, formValues);
    newFormValues.claimType = per;
    if (per === "GeoEventType_Get") {
      newFormValues.claimValue = newFormValues.claimValue1
        .map(p => p.value)
        .toString();
      delete newFormValues.claimValue1;
    }
    if (per !== "GeoEventType_Get") {
      delete newFormValues.claimValue1;
    }
    SendRequest()
      .post(`/Roles/Attach/User/Claim/${userId}`, newFormValues)
      .then(res => {
        if (res.status === 200) {
          if (index === permissions.length - 1) {
            showSuccessedConfirm(local.AddedSuccessfully);

            dispatch({
              type: UPDATE_USER_CLAIMS_PROPS,
              payload: [
                {
                  prop: "data.form",
                  value: {
                    Description: "",
                    claimType: "",
                    claimValue: "",
                    geometry: [],
                    claimValue1: null
                  }
                },
                {
                  prop: "data.permissions",
                  value: []
                },
                {
                  prop: "data.userId",
                  value: null
                }
              ]
            });
          }
        }

        if (index === permissions.length - 1) {
          dispatch({
            type: UPDATE_USER_CLAIMS_PROPS,
            payload: [{ prop: "isLoadingCreate", value: false }]
          });
        }
      })
      .catch(e => {
        dispatch({
          type: UPDATE_USER_CLAIMS_PROPS,
          payload: [{ prop: "isLoadingCreate", value: false }]
        });
        console.log(e);
      })
      .finally();
  });
};

export const deleteUserClaim = (user_claim_id, user_id, index) => dispatch => {
  dispatch({
    type: UPDATE_USER_CLAIMS_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: null }]
  });
  SendRequest()
    .delete(`/Roles/Detach/User/Claim/${user_id}/${user_claim_id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_USER_CLAIMS_PROPS,
          payload: [
            {
              prop: "data.items." + index
            }
          ]
        });
        showSuccessedConfirm(local.DeletedSuccessfully);
      }
      dispatch({
        type: UPDATE_USER_CLAIMS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
    })
    .catch(err => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_USER_CLAIMS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
      console.log(err);
    })
    .finally();
};

export const deleteMultipleUsersClaims = (
  ids,
  setChecked,
  user_id
) => dispatch => {
  dispatch({
    type: UPDATE_USER_CLAIMS_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: true }]
  });

  SendRequest()
    .delete(`/Roles/Detach/User/Claim/${user_id}/${ids[0]}`)
    .then(res => {
      if (res.status === 200) {
        ids.shift();

        if (ids.length > 0) {
          dispatch(deleteMultipleUsersClaims(ids, setChecked, user_id));
        } else {
          setChecked([]);
          showSuccessedConfirm(local.DeletedSuccessfully);
          dispatch(fetchUserClaims(user_id, undefined, true));
        }
      }
      dispatch({
        type: UPDATE_USER_CLAIMS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
    })
    .catch(err => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_USER_CLAIMS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
      console.log(err);
    })
    .finally();
};
