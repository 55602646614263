import React from "react";
import { Card, Row, Col, Button } from "reactstrap";
import { getLanguage, local } from "../../lang/local";
import file_text from "../../assets/img/file_text.png";
import image from "../../assets/img/image.png";
import { leftStyle } from "../../General/GeneralStyle";
import { getUser } from '../../utils/StorageFunctions';

import avater2 from "../../assets/img/avater2.svg";
import Moment from "react-moment";
import "moment-timezone";
import {
  getLocation,
  renderTicketMenu,
  unsetErrorMessage,
} from "./TicketFunctions";
import {
  UPDATE_TICKETS_PROPS,
  UPDATE_GENERAL_PROPS,
} from "../../Redux/Actions/types";

export const TicketCard = ({
  item,
  index,
  DeleteTicket,
  general,
  history,
  ticketId,
  fetchTicketMessages,
  filterMessageArray,
  data_message,
  closeTicket,
}) => {
  const handleCardClick = (e) => {
    if (item.status === true) {
      e.preventDefault();
      general(
        [
          { prop: "data_message.ticket", value: item },
          { prop: "data_message.items", value: null },
        ],
        UPDATE_TICKETS_PROPS
      );
    }
    fetchTicketMessages(filterMessageArray, 1, item.id);
  };
  const deleteItem = (e, item, i) => {
    e.preventDefault();
    DeleteTicket(item.id, i);
  };

  const editItem = (e, element, index, type) => {
    e.preventDefault();
    unsetErrorMessage(general);
    let form = {
      title: element.title,
      description: element.description,
      status: element.status,
      sector: element.sector,
      location: getLocation(element.location),
      id: element.id,
    };

    if (type === "update") {
      general(
        [
          {
            prop: "data.form",
            value: form,
          },

          {
            prop: "data.item_index",
            value: index,
          },
        ],
        UPDATE_TICKETS_PROPS
      );

      history.push(`/dashboard/editticket/${item.id}`);
    } else {
      let newForm = Object.assign({}, form);
      newForm.status = false;
      closeTicket(newForm, index);
    }
  };

  const checkTicketUser = () => {
    let user = getUser();
    if (user && item.user) {
      if (user.data.id === item.user.id) {
        return renderTicketMenu(item, index, deleteItem, editItem);
      }
    }
  };
  return (
    <Card
      className="ticket_card cursor mt-4"
      style={{
        background:
          item.status === true
            ? "white"
            : "linear-gradient(96.78deg, #979797 0%, #545454 100%)",

        border: data_message.ticket
          ? data_message.ticket.id === item.id
            ? "1px solid #12a9cc"
            : "none"
          : "none",
      }}
      onClick={handleCardClick}
    >
      <div
        style={{
          position: "absolute",
          top: "0px",
          right: getLanguage() === "en" ? "0px" : null,
          left: getLanguage() === "en" ? null : "0px",
          width: "fit-content",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          className="date_color_chat mt-1"
          style={{
            color: item.status === true ? "#707c97" : "white",
            direction: "ltr",
            marginInlineStart: "10px",
          }}
        >
          <Moment fromNow>{item.created}</Moment>
        </span>

        {checkTicketUser()}
      </div>
      <Row className="ml-1 mr-1 mt-3 alignItem">
        <Col xl={1} lg={1} md={2} sm={2} xs={2}>
          <img
            src={
              item.user
                ? item.user.image
                  ? item.user.image
                  : avater2
                : avater2
            }
            className="card_img_chat"
            alt=""
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={6} xs={6} className="ml-4 mr-4">
          <div style={leftStyle} className="inline_tag">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                general(
                  [
                    {
                      prop: "profileModal",
                      value: { isOpen: true, data: item.user },
                    },
                  ],
                  UPDATE_GENERAL_PROPS
                );
              }}
            >
              <span
                className="blue_color weightFont chat_title"
                style={leftStyle}
              >
                {item.user ? item.user.fullName : ""}
              </span>
            </a>
            <p
              className="chat_desc mt-1"
              style={{
                clear: getLanguage() === "en" ? "left" : "right",
                float: getLanguage() === "en" ? "left" : "right",
                textAlign: getLanguage() === "en" ? "left" : "right",
                color: item.status === true ? "#2a8bf2" : "white",
              }}
            >
              {item.title}
            </p>
          </div>
        </Col>
      </Row>

      <Row className="mt-4 alignItem">
        <Col xl={10} lg={10} md={10} sm={10} xs={10} className="alignItem">
          <p
            className="chat_card_text mr-3 ml-3"
            style={{
              color: item.status === true ? "#707c97" : "white",
              textAlign: getLanguage() === "en" ? "left" : "right",
            }}
          >
            {item.description}
          </p>
        </Col>
        {item.status === true ? (
          <Col>
            <center>
              <div className="circleChat">
                {item.id === ticketId ? data_message.general_Files_Count : 0}
              </div>
            </center>
          </Col>
        ) : null}
      </Row>
      <Row className="ml-1 mr-1 mt-5 mb-3 alignItem">
        <Col className="text-right">
          {item.status === true ? (
            <Button className="file_btn" color="light">
              <div>
                <img src={file_text} alt="" />
                <p className="file_text">
                  {item.id === ticketId
                    ? data_message.filesCount + " " + local.Files
                    : 0}
                </p>
              </div>
            </Button>
          ) : null}
          &nbsp;
          <Button
            className="img_btn"
            color="danger"
            style={{
              background:
                item.status === true
                  ? "rgba(255, 51, 102, 0.25)"
                  : "rgba(255, 255, 255, 0.25)",
            }}
          >
            <div>
              {item.status === true ? <img src={image} alt="" /> : null}
              <p
                className="image_text"
                style={{ color: item.status === true ? "#ff3366" : "white" }}
              >
                {item.id === ticketId
                  ? data_message.imagesCount + " " + local.Photo
                  : 0}
              </p>
            </div>
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
