import { data } from "./data";
const flipArray = (array) => {
  return array.map((e) => {
    if (e[0] && !Array.isArray(e[0])) {
      return [e[1], e[0]];
    } else {
      return flipArray(e);
    }
  });
};

let currentHover = "";
let currentClicked = null;
let countries_ids = [];

export const drawPolygons = (
  map,
  normalColor,
  hoverColor,
  selectcolor,
  onClick,
  onhover,
  general,
  getAllUsersForMap
) => {
  //let map = new MapClass();
  let drawPolygon = (id, properties, coordinates, color, state = "normal") => {
    map.AddPolygon(
      {
        groupName: "countryPolygon",
        boundaries: coordinates,
        color: color.color,
        fillColor: color.fillColor,
        fillOpacity: color.fillOpacity,
        weight: 1,
        properties: properties,
        onClick: (id, properties) => {
          let poly = "Polygon((";
          properties.geometry.coordinates[0].map((point, index) => {
            if (properties.geometry.coordinates[0].length - 1 === index) {
              poly += point[1] + " " + point[0] + "))";
            }

            if (properties.geometry.coordinates[0].length - 1 !== index) {
              poly += point[1] + " " + point[0] + ",";
            }
          });

          if (countries_ids.length > 0) {
            if (!countries_ids.find((um) => um === properties.id)) {
              getAllUsersForMap(1, poly, properties.id);
              countries_ids.push(properties.id);
            }
          } else {
            getAllUsersForMap(1, poly, properties.id);
            countries_ids.push(properties.id);
          }
          // general(
          //   [
          //     {
          //       prop: "features",
          //       value: properties,
          //     },
          //     {
          //       prop: "search.geometry",
          //       value: properties.geometry,
          //     },
          //   ],
          //   UPDATE_STATISTIC_PROPS
          // );
          if (state === "hoverd" || state === "normal") {
            map.deletePolygon("selectedCountry");
            if (currentClicked)
              drawPolygon(
                id,
                currentClicked,
                currentClicked.geometry.coordinates,
                normalColor,
                "normal"
              );

            currentClicked = properties;
            drawPolygon(
              "selectedCountry",
              properties,
              coordinates,
              selectcolor,
              "clicked"
            );
            console.log("click");
            if (onClick) onClick(id, properties);
          }
        },
        onMouseOver: (id, properties) => {
          if (state === "normal") {
            drawPolygon(
              "hovered",
              properties,
              coordinates,
              hoverColor,
              "hoverd"
            );
            console.log("hover");
            if (onhover) onhover(id, properties);
          }
        },
        onMouseOut: (id, properties) => {
          if (state === "hoverd") {
            map.deletePolygon("hovered");
            drawPolygon(id, properties, coordinates, normalColor, "normal");
            console.log("out");
          }
        },
      },
      id
    );
  };
  data.features.forEach((ele) => {
    let coordinates = flipArray(ele.geometry.coordinates);
    // ele.geometry.coordinates = coordinates;
    ele.swip = true;

    drawPolygon(ele.id, ele, coordinates, normalColor);
  });
};
