import {
  UPDATE_REVIEWS_PROPS,
  MERGE_REVIEWS_PROPS,
  DELETE_REVIEWS_PROPS,
  CLEAR_REVIEWS_STATE
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    items: null,
    pagination: null,
    isLoadingSearch: false,
    filterArray: [
      {
        op: 3,
        fieldName: "UserId",
        value: []
      },

      {
        op: 0,
        fieldName: "Rating",
        value: null
      }
    ],
    selectedUsers: [],
    isLoading: false
  }
};

const ReviewsR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_REVIEWS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_REVIEWS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_REVIEWS_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_REVIEWS_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default ReviewsR;
