import React, { Component } from 'react';
import './reviews.css';
import { Col, Row } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import ReviewsList from './ReviewsList';
import Gallery from '../../General/Gallery';
import { getUser } from '../../utils/StorageFunctions';

import ReviewSearch from './ReviewSearch';
import { getPermissions } from '../../utils/Permissions';
import { CLEAR_REVIEWS_STATE } from '../../Redux/Actions/types';

class Reviews extends Component {
  componentDidMount() {
    this.getReivew();
  }

  getReivew = () => {
    const { general, history, fetchReviews } = this.props;
    general([], CLEAR_REVIEWS_STATE);
    let user = getUser();

    if (user === null) {
      history.push('/login');
    } else {
      fetchReviews([], 1);
    }
  };
  render() {
    const {
      history,
      data,
      general,
      lightbox,
      fetchReviews,
      usersOptions,
      loadUserOptions,
      deleteReview,
    } = this.props;
    return (
      <div
        className='page-container'
        style={{ background: 'rgba(255, 255, 255,0.4)' }}
      >
        <Gallery lightbox={lightbox} general={general} />

        <div>
          {getPermissions(true, true).UserBusiness_GetUsers !== undefined ||
          getPermissions(true, true).Administrator !== undefined ? (
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <ReviewSearch
                  filterArray={data.filterArray}
                  general={general}
                  isLoading={data.isLoadingSearch}
                  fetchReviews={fetchReviews}
                  history={history}
                  data={data}
                  usersOptions={usersOptions}
                  loadUserOptions={loadUserOptions}
                  selectedUsers={data.selectedUsers}
                />
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mx-auto'>
              <ReviewsList
                data={data}
                general={general}
                deleteReview={deleteReview}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ReviewsR, GeneralR, UsersR }) => {
  return {
    data: ReviewsR.data,
    lightbox: GeneralR.lightbox,
    usersOptions: UsersR.usersOptions,
  };
};
export default connect(mapStateToProps, actions)(Reviews);
