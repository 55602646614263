import React from "react";
import { Col, Row, Input, Label } from "reactstrap";
import { getLanguage, local } from "../../lang/local";
import avater2 from "../../assets/img/avater2.svg";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";
import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
import { columnAlignLeft } from "../../General/GeneralStyle";
import { renderImage } from "../../General/GeneralFunctions";
import Moment from "react-moment";
import "moment/locale/ar";

const UserProfile = ({ data, general }) => {
  const handleImageClick = (e) => {
    e.preventDefault();
    if (data.image) {
      general(
        [
          {
            prop: "lightbox",
            value: {
              image: IMAGE_BASE_PATH + data.image,
              isOpen: true,
              type: "single",
            },
          },
        ],
        UPDATE_GENERAL_PROPS
      );
    }
  };
  return data ? (
    <div className="mt-3 mb-3">
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mx-auto mt-3">
          <div className="img_profile_container mx-auto">
            <img
              src={renderImage(data.image, general, avater2, true)}
              className="fullWidthHeight"
              style={{ borderRadius: "100%" }}
              onClick={handleImageClick}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Id}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.id}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.FirstName}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.firstName}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.LastName}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.lastName}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Mobile}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.phoneNumber}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Gender}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.gender}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Email}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.email}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.BirthDay}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={
                getLanguage() === "en"
                  ? new Date(data.birthDate).toLocaleDateString("en")
                  : new Date(data.birthDate).toLocaleDateString("ar")
              }
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.JoinDate}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={
                getLanguage() === "en"
                  ? new Date(data.joinDate).toLocaleDateString("en")
                  : new Date(data.joinDate).toLocaleDateString("ar")
              }
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        {/* <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Status}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={
                data.activated === false ? local.InActive : local.Activated
              }
              disabled={true}
            />
          </div>
        </Col> */}

        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3 text-center`}
        >
          <Label className="bold">{local.ActivationCode}</Label>

          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.code !== undefined ? data.code : ""}
              disabled={true}
            />
          </div>

          <span className="text-center">
            {data.expireDate !== undefined ? (
              <React.Fragment>
                <span>{local.ActivationExpire}</span>
                <Moment fromNow ago local="ar">
                  {new Date(data.expireDate)}
                </Moment>
              </React.Fragment>
            ) : (
              ""
            )}
          </span>
        </Col>
      </Row>
    </div>
  ) : null;
};

export default UserProfile;
