import React, { useState } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import { local } from "../../lang/local";
import {
  renderAddButton,
  renderCheckoBox,
  renderMultipleDeleteButton,
  renderTableDropDown,
} from "../../General/TableFunctions";
import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
import { getClaimTitle } from "../RolesClaims/RolesClaimsFunctions";

const MobileList = ({
  history,
  data,
  deleteUserClaim,
  general,
  deleteMultipleUsersClaims,
}) => {
  const [checked, setChecked] = useState([]);

  const deleteItem = (e, item, index) => {
    e.preventDefault();
    deleteUserClaim(item.id, item.userId, index);
  };

  const renderTableBody = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return data.items.map((item, index) => {
          return (
            <tr key={index}>
              <th scope="row">
                {renderCheckoBox(
                  item.id,
                  checked,
                  setChecked,
                  "single",
                  "RoleBusiness_DetachUserClaim"
                )}
              </th>
              <td>{item.id}</td>
              {/* <td>
                <a
                  href=""
                  className="blueLight"
                  onClick={(e) => {
                    e.preventDefault();
                    general(
                      [
                        {
                          prop: "profileModal",
                          value: { isOpen: true, data: item },
                        },
                      ],
                      UPDATE_GENERAL_PROPS
                    );
                  }}
                >
                  {item.claimValue}
                </a>
              </td> */}
              <td>{item.description}</td>
              <td> {getClaimTitle(item.claimType)}</td>
              <td>
                {renderTableDropDown(
                  item,
                  index,
                  deleteItem,
                  null,
                  "RoleBusiness_DetachUserClaim",
                  null
                )}
              </td>
            </tr>
          );
        });
      }
    }
  };
  return (
    <React.Fragment>
      <Card className="table_card ml-3 mr-3">
        <div className="mt-4">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {renderAddButton(
                local.AddUserClaim,
                history,
                "/dashboard/attach_user_claims",
                "RoleBusiness_AttachUserClaim"
              )}

              {renderMultipleDeleteButton(
                checked,
                setChecked,
                deleteMultipleUsersClaims,
                data.isLoadingDelete,
                "RoleBusiness_DetachUserClaim",
                data.userId
              )}
            </Col>
          </Row>

          <Table responsive hover className="mt-4">
            <thead>
              <tr>
                <th>
                  {data.items
                    ? data.items.length > 0
                      ? renderCheckoBox(
                          data.items.map((i) => i.id),
                          checked,
                          setChecked,
                          "all",
                          "RoleBusiness_DetachUserClaim"
                        )
                      : null
                    : null}
                </th>
                <th>{local.Id}</th>
                {/* <th>{local.Name}</th> */}
                <th>{local.Description}</th>
                <th>{local.claimType}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{renderTableBody()}</tbody>
          </Table>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default MobileList;
