import { getGeneralUsers } from ".";
import { getLocation } from "../../Pages/Tickets/TicketFunctions";
import {
  convertToFormData,
  removeDuplicateElement,
  showConfirmMessage,
  showSuccessedConfirm,
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { SendRequest } from "./api";
import {
  DELETE_TICKETS_PROPS,
  MERGE_TICKETS_PROPS,
  UPDATE_TICKETS_PROPS,
} from "./types";

export const fetchTickets = (filterArray, page, generalUsers) => (dispatch) => {
  if (filterArray.length > 0) {
    dispatch({
      type: UPDATE_TICKETS_PROPS,
      payload: [{ prop: "data.isLoadingSearch", value: true }],
    });
  }
  dispatch({
    type: UPDATE_TICKETS_PROPS,
    payload: [{ prop: "data.isLoading", value: true }],
  });
  SendRequest()
    .post(`/Ticket/Get?page=${page}`, filterArray)
    .then((res) => {
      if (res.status === 200) {
        let pagination = Object.assign({}, res.data.data);
        delete pagination.items;
        if (res.data.data.items) {
          let users = res.data.data.items.map((u) => u.userId);
          let uniqueTotals = removeDuplicateElement(users);
          dispatch(getGeneralUsers(uniqueTotals, generalUsers));
        }
        res.data.data.items.forEach((da) => {
          let date = new Date(da.created);
          date.setHours(date.getHours() + 2);
          da.created = date.toISOString();
        });

        dispatch({
          type: UPDATE_TICKETS_PROPS,
          payload: [
            { prop: "data.items", value: res.data.data.items },
            { prop: "data.pagination", value: pagination },
          ],
        });
      }

      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const addTicket = (formValues) => (dispatch) => {
  dispatch({
    type: UPDATE_TICKETS_PROPS,
    payload: [{ prop: "isLoadingCreate", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);
  SendRequest()
    .post("/Ticket", newFormValues)
    .then((res) => {
      if (res.status === 200) {
        showSuccessedConfirm(local.AddedSuccessfully);

        dispatch({
          type: UPDATE_TICKETS_PROPS,
          payload: [
            {
              prop: "data.form",
              value: {
                title: "",
                description: "",
                status: true,
                sector: 0,
                location: "",
              },
            },
          ],
        });
      }
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
    })
    .catch((e) => {
      if (Object.values(e)[2] !== undefined) {
        if (Object.values(e)[2].data !== undefined) {
          Object.values(Object.values(e)[2].data.data).forEach((err) => {
            showConfirmMessage(err[0], "error");
          });
        }
      }

      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const updateTicket = (formValues, history) => (dispatch) => {
  dispatch({
    type: UPDATE_TICKETS_PROPS,
    payload: [{ prop: "isLoadingCreate", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);
  SendRequest()
    .put(`/Ticket/${newFormValues.id}`, newFormValues)
    .then((res) => {
      if (res.status === 200) {
        showSuccessedConfirm(local.SuccessfullyUpdated);

        history.push("/dashboard/tickets");
      }
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const DeleteTicket = (id, index) => (dispatch) => {
  dispatch({
    type: UPDATE_TICKETS_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: null }],
  });
  SendRequest()
    .delete(`Ticket/${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_TICKETS_PROPS,
          payload: [
            {
              prop: "data.items." + index,
            },
          ],
        });
        showSuccessedConfirm(local.DeletedSuccessfully);
      }
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
    })
    .catch((err) => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
      console.log(err);
    })
    .finally();
};

export const fetchTicketMessages =
  (filterArray, page, ticid, type) => (dispatch) => {
    dispatch({
      type: UPDATE_TICKETS_PROPS,
      payload: [{ prop: "data_message.isLoading", value: true }],
    });
    SendRequest()
      .post(`/TicketMessages/${ticid}?page=${page}`, filterArray)
      .then((res) => {
        if (res.status === 200) {
          let pagination = Object.assign({}, res.data.data);
          delete pagination.items;

          res.data.data.items.forEach((da) => {
            let date = new Date(da.created);
            date.setHours(date.getHours() + 2);
            da.created = date.toISOString();
          });

          let total_of_files = 0;
          let count_of_photos = 0;
          let count_of_files = 0;
          total_of_files = res.data.data.items
            .map((item) => item.attachments.length)
            .reduce((pv, cv) => pv + cv, 0);

          count_of_photos = res.data.data.items
            .map(
              (item) =>
                item.attachments.filter(
                  (at) =>
                    at.includes("png") ||
                    at.includes("jpg") ||
                    at.includes("jpeg") ||
                    at.includes("gif") ||
                    at.includes("ico") ||
                    at.includes("icon")
                ).length
            )
            .reduce((pv, cv) => pv + cv, 0);

          count_of_files = res.data.data.items
            .map(
              (item) =>
                item.attachments.filter(
                  (at) =>
                    at.includes("pdf") ||
                    at.includes("audio") ||
                    at.includes("video") ||
                    at.includes("excel") ||
                    at.includes("txt")
                ).length
            )
            .reduce((pv, cv) => pv + cv, 0);
          dispatch({
            type: UPDATE_TICKETS_PROPS,
            payload: [
              {
                prop: "data_message.general_Files_Count",
                value: total_of_files,
              },
              { prop: "data_message.imagesCount", value: count_of_photos },
              { prop: "data_message.filesCount", value: count_of_files },

              { prop: "data_message.pagination", value: pagination },
            ],
          });
          if (type === undefined) {
            dispatch({
              type: UPDATE_TICKETS_PROPS,
              payload: [
                {
                  prop: "data_message.items",
                  value: res.data.data.items,
                },
              ],
            });
          } else {
            dispatch({
              type: MERGE_TICKETS_PROPS,
              payload: [
                {
                  prop: "data_message.items",
                  value: res.data.data.items,
                },
              ],
            });
          }
        }

        dispatch({
          type: UPDATE_TICKETS_PROPS,
          payload: [{ prop: "data_message.isLoading", value: false }],
        });
      })
      .catch((e) => {
        dispatch({
          type: UPDATE_TICKETS_PROPS,
          payload: [{ prop: "data_message.isLoading", value: false }],
        });
      })
      .finally();
  };

export const addTicketMessage = (formValues, ticketId) => (dispatch) => {
  dispatch({
    type: UPDATE_TICKETS_PROPS,
    payload: [{ prop: "data_message.isLoadingCreate", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);
  let formData = convertToFormData(newFormValues);

  SendRequest()
    .post(`/TicketMessages?ticketId=${ticketId}`, formData)
    .then((res) => {
      if (res.status >= 199 && res.status <= 299) {
        dispatch(fetchTicketMessages([], 1, ticketId));
        // dispatch({
        //   type: MERGE_TICKETS_PROPS,
        //   payload: [
        //     {
        //       prop: "data_message.items",
        //       value: res.data.data,
        //     },
        //   ],
        // });
        dispatch({
          type: UPDATE_TICKETS_PROPS,
          payload: [
            {
              prop: "data_message.form",
              value: {
                Msg: { text: "" },
                Attachments: [],
              },
            },
          ],
        });
      }
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "data_message.isLoadingCreate", value: false }],
      });
    })
    .catch((e) => {
      if (Object.values(e)[2] !== undefined) {
        if (Object.values(e)[2].data !== undefined) {
          showConfirmMessage(Object.values(e)[2].data.message, "error");
        }
      }
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "data_message.isLoadingCreate", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const editTicketMessage = (formValues) => (dispatch) => {
  dispatch({
    type: UPDATE_TICKETS_PROPS,
    payload: [{ prop: "data_message.isLoadingCreate", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);
  let formData = convertToFormData(newFormValues);

  SendRequest()
    .put(`/TicketMessages/${formValues.id}`, formData)
    .then((res) => {
      if (res.status >= 199 && res.status <= 299) {
        showSuccessedConfirm(local.SuccessfullyUpdated);

        dispatch({
          type: UPDATE_TICKETS_PROPS,
          payload: [
            {
              prop: "data_message.isOpen",
              value: false,
            },
            {
              prop: "data_message.form",
              value: {
                Msg: { text: "" },
                Attachments: [],
              },
            },
          ],
        });
      }
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "data_message.isLoadingCreate", value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "data_message.isLoadingCreate", value: false }],
      });
      console.log(e);
    })
    .finally();
};
export const DeleteTicketMessage = (id, index) => (dispatch) => {
  dispatch({
    type: UPDATE_TICKETS_PROPS,
    payload: [{ prop: "data_message.isLoadingDelete", value: null }],
  });
  SendRequest()
    .delete(`/TicketMessages/${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_TICKETS_PROPS,
          payload: [
            {
              prop: "data_message.items." + index,
            },
          ],
        });
      }
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "data_message.isLoadingDelete", value: null }],
      });
    })
    .catch((err) => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "data_message.isLoadingDelete", value: null }],
      });
      console.log(err);
    })
    .finally();
};

export const deleteAllMessages = (ids, ticketId) => (dispatch) => {
  dispatch({
    type: UPDATE_TICKETS_PROPS,
    payload: [{ prop: "data_message.isLoadingDelete", value: true }],
  });

  SendRequest()
    .delete(`/TicketMessages/${ids[0]}`)
    .then((res) => {
      if (res.status === 200) {
        ids.shift();

        if (ids.length > 0) {
          dispatch(deleteAllMessages(ids, ticketId));
        } else {
          dispatch(fetchTicketMessages([], 1, ticketId));
          showSuccessedConfirm(local.DeletedSuccessfully);
        }
      }
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "data_message.isLoadingDelete", value: null }],
      });
    })
    .catch((err) => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_TICKETS_PROPS,
        payload: [{ prop: "data_message.isLoadingDelete", value: null }],
      });
      console.log(err);
    })
    .finally();
};

export const closeTicket = (formValues, index) => (dispatch) => {
  let newFormValues = Object.assign({}, formValues);
  SendRequest()
    .put(`/Ticket/${newFormValues.id}`, newFormValues)
    .then((res) => {
      if (res.status === 200) {
        showSuccessedConfirm(local.TicketClosed);

        dispatch({
          type: UPDATE_TICKETS_PROPS,
          payload: [{ prop: "data.items." + index + ".status", value: false }],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const fetchTicket = (id) => (dispatch) => {
  SendRequest()
    .get(`/Ticket/${id}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data) {
          let item = res.data.data;

          dispatch({
            type: UPDATE_TICKETS_PROPS,
            payload: [
              {
                prop: "data.form",
                value: {
                  title: item.title,
                  description: item.description,
                  status: item.status,
                  sector: item.sector,
                  location: getLocation(item.location),
                  id: item.id,
                },
              },
            ],
          });
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
