import React, { Component } from "react";
import { Row, Card, Col } from "reactstrap";
import { local } from "../../lang/local";
import { FaPlus } from "react-icons/fa";
import { connect } from "react-redux";
import * as actions from "../../Redux/Actions/index";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";
import { FiX, FiEdit2 } from "react-icons/fi";
import {
  MERGE_COMPLAINT_TYPE_PROPS,
  DELETE_COMPLAINT_TYPE_PROPS,
  UPDATE_COMPLAINTS_PROPS,
  UPDATE_COMPLAINT_TYPE_PROPS,
  UPDATE_GENERAL_PROPS,
  CLEAR_COMPLAINT_TYPE_STATE,
} from "../../Redux/Actions/types";
import { leftProperty } from "../../General/GeneralStyle";
import { getPermissions } from "../../utils/Permissions";
import { renderName } from "../../General/GeneralFunctions";
import { confirmAlert } from "react-confirm-alert";

import { USER_CLAIMS, USER_ROLES } from "../../General/StaticKeys";

class ComplaintTypes extends Component {
  componentDidMount() {
    this.getTypes();
  }

  getTypes = () => {
    const { fetchComplaintType, general } = this.props;
    general([], CLEAR_COMPLAINT_TYPE_STATE);

    if (getPermissions(true, true).Administrator !== undefined) {
      fetchComplaintType([]);
    } else {
      if (getPermissions(true, true).GeoEventType_Get !== undefined) {
        let roles = JSON.parse(localStorage.getItem(USER_ROLES));
        let claims = JSON.parse(localStorage.getItem(USER_CLAIMS));
        if (claims) {
          let entry = claims.find((c) => c.claimType === "GeoEventType_Get");
          let ids = entry.claimValue.split(",");

          if (ids) {
            let filterArray = [
              {
                op: 3,
                fieldName: "Id",
                value: ids,
              },
            ];

            fetchComplaintType(filterArray);
          }
        }
      } else {
        return null;
      }
    }
  };

  renderActiveCards = (value) => {
    const { data } = this.props;

    if (data.active_cards.length === 0) {
      return false;
    } else {
      let item = data.active_cards.find((v) => v === value);
      if (item !== undefined) {
        return true;
      } else {
        return false;
      }
    }
  };

  addActiveCards = (value) => {
    const { data, general } = this.props;

    general([{ prop: "data.complaint", value: null }], UPDATE_COMPLAINTS_PROPS);
    function merge() {
      general(
        [{ prop: "data.active_cards", value: value }],
        MERGE_COMPLAINT_TYPE_PROPS
      );
    }
    if (data.active_cards.length === 0) {
      merge();
    } else {
      let find_item = data.active_cards.find((f) => f === value);
      if (find_item === undefined) {
        merge();
      } else {
        let index = data.active_cards.indexOf(find_item);
        general(
          [{ prop: "data.active_cards." + index }],
          DELETE_COMPLAINT_TYPE_PROPS
        );
      }
    }
  };

  onEdit = (e, item) => {
    const { general, history } = this.props;
    e.preventDefault();
    let image = null;
    if (item.icon) {
      image = IMAGE_BASE_PATH + item.icon;
    }
    general(
      [
        {
          prop: "data.form",
          value: { Name: item.name, Icon: image, id: item.id },
        },
      ],
      UPDATE_COMPLAINT_TYPE_PROPS
    );
    history.push(`/dashboard/edit_complaint_type/${item.id}`);
  };

  renderCardItem = (item, index, icons) => {
    const { general, DeleteComplaintType } = this.props;
    return (
      <Col xl={1} lg={2} md={2} sm={4} xs={6} className="center mx-auto">
        <div
          data-aos="fade-down"
          data-aos-delay="20"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <Card
            className={
              this.renderActiveCards(item.id) === true
                ? "mt-4 mb-4 card_violation_item_active center cursor"
                : "mt-4 mb-4 card_violation_item center cursor"
            }
          >
            {/* {getPermissions(true, true).GeoEventType_Delete !== undefined ||
          getPermissions(true, true).Administrator !== undefined ? ( */}
            <FiX
              className="remove_comp_icon"
              style={leftProperty}
              onClick={(e) =>
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="custom-ui">
                        <h1>{local.Sure}</h1>
                        <p>{local.ConfirmEdit}</p>
                        <button onClick={onClose}>{local.No}</button>
                        <button
                          onClick={() => {
                            e.preventDefault();
                            DeleteComplaintType(item.id, index);
                          }}
                        >
                          {local.Yes}
                        </button>
                      </div>
                    );
                  },
                })
              }
            />

            {/* // ) : null} */}
            {item.icon ? (
              <div
                style={{ width: "50px", height: "50px", borderRadius: "100%" }}
              >
                <img
                  src={IMAGE_BASE_PATH + item.icon}
                  alt=""
                  className="cursor fullWidthHeight"
                  onClick={(e) => {
                    e.preventDefault();
                    general(
                      [
                        {
                          prop: "lightbox",
                          value: {
                            images: icons
                              ? icons.map((ic) => IMAGE_BASE_PATH + ic)
                              : [],
                            type: "multi",
                            index: index,
                            isOpen: true,
                          },
                        },
                      ],
                      UPDATE_GENERAL_PROPS
                    );
                  }}
                />
              </div>
            ) : null}
          </Card>

          <p
            className={
              this.renderActiveCards(item.id) === true
                ? "card_violation_item_p_active mb-5 alignItem"
                : "card_violation_item_p mb-5"
            }
          >
            <a
              className="cursor blueLight"
              href=""
              onClick={(e) => {
                e.preventDefault();
                this.addActiveCards(item.id);
              }}
            >
              {renderName(item.name)}
            </a>

            {getPermissions(true, true).GeoEventType_Update !== undefined ||
            getPermissions(true, true).Administrator !== undefined ? (
              this.renderActiveCards(item.id) === true ? (
                <FiEdit2
                  className="edit_icon_type"
                  onClick={(e) => this.onEdit(e, item)}
                />
              ) : null
            ) : null}
          </p>
        </div>
      </Col>
    );
  };

  renderCreateComplaintType = (title) => {
    const { history, general } = this.props;

    return (
      <Col xl={1} lg={2} md={2} sm={4} xs={6} className="center mx-auto">
        <div
          data-aos="fade-down"
          data-aos-delay="20"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <Card
            className="mt-4 mb-4 card_violation_item center cursor"
            onClick={(e) => {
              e.preventDefault();
              general(
                [
                  {
                    prop: "data.form",
                    value: {
                      Name: { en: "", ar: "" },
                      Icon: null,
                    },
                  },
                ],
                UPDATE_COMPLAINT_TYPE_PROPS
              );
              history.push("/dashboard/add_complaint_type");
            }}
          >
            <FaPlus className="icon_violation_card" />
          </Card>

          <p className="card_violation_item_p mb-5">{title}</p>
        </div>
      </Col>
    );
  };

  renderItems = () => {
    const { data } = this.props;

    if (data.items) {
      let icons = data.items.map((comp) => comp.icon);
      return data.items.map((comp, index) => {
        return this.renderCardItem(comp, index, icons);
      });
    }
  };

  preparePages = () => {
    const { data } = this.props;

    if (data.pagination !== null) {
      let pages = [];
      for (let i = 1; i <= data.pagination.totalPages; i++) {
        pages.push(
          <Row className="center" style={{ marginInline: "20px" }}>
            {getPermissions(true, true).GeoEventType_Create !== undefined ||
            getPermissions(true, true).Administrator !== undefined
              ? this.renderCreateComplaintType(local.AddAnother)
              : null}
            {this.renderItems()}
          </Row>
        );
      }

      return pages;
    }
  };
  getPage = (e, page) => {
    e.preventDefault();
    const { fetchComplaintType, general } = this.props;
    general(
      [
        { prop: "data.items", value: null },
        { prop: "data.pagination.currentPage", value: page },
      ],
      UPDATE_COMPLAINT_TYPE_PROPS
    );
    fetchComplaintType([], page);
  };
  renderPagination = () => {
    const { data } = this.props;
    let items = [];

    if (data.pagination) {
      for (let t = 1; t <= data.pagination.totalPages; t++) {
        items.push(t);
      }
    }
    if (items.length > 0) {
      return items.map((item, index) => {
        return (
          <div
            key={index}
            className="center cursor comp_paginate_item"
            onClick={(e) =>
              item !== data.pagination.currentPage
                ? this.getPage(e, item)
                : null
            }
          >
            {item === data.pagination.currentPage ? (
              <span className="comp_paginate_child"></span>
            ) : null}
          </div>
        );
      });
    }
  };

  render() {
    const { data, general, fetchComplaintType } = this.props;
    return (
      <React.Fragment>
        <Col className="center">
          {/* <Carousel
          Pages={this.preparePages}
          pagination={data.pagination}
          general={general}
          fetchComplaintType={fetchComplaintType}
        /> */}
          <Row>
            {this.renderCreateComplaintType(local.AddAnother)}
            {this.renderItems()}
          </Row>
        </Col>
        <Col
          className="center mb-3"
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ direction: "ltr" }}
        >
          {this.renderPagination()}
        </Col>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ ComplaintTypeR }) => {
  return { data: ComplaintTypeR.data };
};
export default connect(mapStateToProps, actions)(ComplaintTypes);
