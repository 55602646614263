import React, { useState } from "react";
import { local } from "../../lang/local";
import chat_search from "../../assets/img/chat_search.svg";
import {
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDebouncedCallback } from "use-debounce";
import { UPDATE_TICKETS_PROPS } from "../../Redux/Actions/types";
import { removeUnsetValuesFromFilters } from "../../General/GeneralFunctions";

const SearchBox = ({ fetchTickets, general, filterArray, generalUsers }) => {
  const [label, setLabel] = useState(local.All);

  const getSearchResult = (value) => {
    let newFilterArr = [...filterArray];
    if (value !== undefined) {
      newFilterArr[1].value = value;
    }

    newFilterArr = removeUnsetValuesFromFilters(newFilterArr);
    fetchTickets(newFilterArr, 1, generalUsers);
  };
  const debounced = useDebouncedCallback((value) => {
    if (value) {
      getSearchResult();
    }
  }, 1000);
  const renderMenuItem = (title, inital_value) => {
    return (
      <DropdownItem
        tag="span"
        active={inital_value === filterArray[1].value ? true : false}
        className="cursor weightFont"
        onClick={(e) => {
          e.preventDefault();
          general(
            [
              {
                prop: "data.filterArray." + 1 + ".value",
                value: inital_value,
              },
            ],
            UPDATE_TICKETS_PROPS
          );
          setLabel(title);
          getSearchResult(inital_value);
        }}
      >
        {title}
      </DropdownItem>
    );
  };

  return (
    <div className="chat_search">
      <img src={chat_search} className="chat_search_img" alt="" />

      <Input
        className="chat_search_input"
        placeholder={local.Title}
        value={filterArray[0].value}
        onChange={(e) => {
          general(
            [
              {
                prop: "data.filterArray." + 0 + ".value",
                value: e.target.value,
              },
            ],
            UPDATE_TICKETS_PROPS
          );
          debounced(e.target.value);
        }}
      />
      <UncontrolledDropdown setActiveFromChild>
        <DropdownToggle
          tag="span"
          className="nav-link cursor"
          caret
          style={{ color: "gray" }}
        >
          {label}
        </DropdownToggle>
        <DropdownMenu className="table_dropdown_menu">
          {renderMenuItem(local.All, null)}
          {renderMenuItem(local.Business, 0)}
          {renderMenuItem(local.Gis, 1)}
          {renderMenuItem(local.Mobile, 2)}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default SearchBox;
