import React, { useState } from "react";
import { Row, Col, Card, Table } from "reactstrap";
import { getLanguage, local } from "../../lang/local";
import PaginationItem from "../../General/PaginationItem";
import { getDate, renderSingleImage } from "../../General/GeneralFunctions";
import UserSearch from "./UserSearch";
import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
import approve from "../../assets/img/approve.svg";
import reject from "../../assets/img/reject.svg";
const TableList = ({
  data,
  general,
  fetchUsers,
  getEmailCode,
  userStatistics,
  GetStatUsers,
}) => {
  const [type, setType] = useState(1);
  let options = [
    {
      label: getLanguage() === "en" ? "All" : "الكل",
      value: 1,
    },
    {
      label: getLanguage() === "en" ? "Guests" : "مستخدمين غير مسجلين",
      value: 2,
    },
    {
      label: getLanguage() === "en" ? "Users" : "مستخدمين مسجلين",
      value: 3,
    },
  ];
  const getCountry = (country) => {
    if (local[country] === undefined) {
      return country;
    } else {
      return local[country];
    }
  };

  const openProfile = (e, item, index) => {
    e.preventDefault();
    if (item.email) {
      getEmailCode(item.email, index);
    }
    general(
      [
        {
          prop: "profileModal",
          value: { isOpen: true, data: item },
        },
      ],
      UPDATE_GENERAL_PROPS
    );
  };
  const renderTableBody = () => {
    if (data.items) {
      if (data.items.length > 0) {
        const users_guests = data.guests
          ? type === 1
            ? data.guests.concat(data.items)
            : type === 2
            ? data.guests
            : data.items
          : data.items;

        let new_users_guests = data.items;
        if (data.pagination.currentPage === 1) {
          new_users_guests = users_guests;
        }
        return new_users_guests.map((item, index) => {
          return (
            <tr key={index} className="text-center">
              <td>{renderSingleImage(item.image, general)}</td>
              <td>{item.id}</td>
              <td className={item.fullName === "Guest" ? "redColor" : ""}>
                {item.fullName}
              </td>
              <td>
                <a
                  href=""
                  className={"blueLight"}
                  onClick={(e) => openProfile(e, item, index)}
                >
                  {item.email}
                </a>
              </td>
              <td>{item.phoneNumber}</td>
              <td>{getCountry(item.country)}</td>
              <td>{getDate(item.joinDate)}</td>
              <td style={{ width: "100px" }}>
                {item.phoneNumberConfirmed !== undefined &&
                item.emailConfirmed !== undefined ? (
                  <React.Fragment>
                    <div className="container_status center">
                      <div style={{ width: "100px" }} className="center">
                        <span>{local.Mobile}</span>
                      </div>
                      <div style={{ width: "100px" }} className="center">
                        <span>{local.Email}</span>
                      </div>
                    </div>

                    <div
                      className="container_status center"
                      style={{
                        background: "#E6E6E6",
                        boxShadow:
                          "0px 2.97015px 2.97015px rgba(0, 0, 0, 0.25)",
                        borderRadius: "20.0485px",
                        border: "0.37px solid #12A9CC",
                      }}
                    >
                      <div style={{ width: "100px" }} className="center">
                        <button disabled={true} className="selected_btn_status">
                          {item.phoneNumberConfirmed === true ? (
                            <img src={approve} fill="red" className="imgFull" />
                          ) : (
                            <img src={reject} fill="red" className="imgFull" />
                          )}
                        </button>
                      </div>

                      <div style={{ width: "100px" }} className="center">
                        <button className="selected_btn_status" disabled={true}>
                          {item.emailConfirmed === true ? (
                            <img src={approve} fill="red" className="imgFull" />
                          ) : (
                            <img src={reject} fill="red" className="imgFull" />
                          )}
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
              </td>
            </tr>
          );
        });
      }
    }
  };

  const rednerActivitionInfoTable = () => {
    return (
      <div>
        <Table striped responsive className="mt-3">
          <thead>
            <tr className="text-center">
              <th>{local.NumberofActiveUsersEmail}</th>
              <th>{local.NumberofInActiveUsersEmail}</th>
              <th>{local.NumberofActiveUsersPhone}</th>
              <th>{local.NumberofInActiveUsersPhone}</th>
              <th>{local.Guests}</th>
              <th>{local.Total}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td style={{ color: "red" }} className="weightFont">
                {userStatistics.activeByEmail}
              </td>
              <td style={{ color: "red" }} className="weightFont">
                {userStatistics.inActiveByEmail}
              </td>
              <td style={{ color: "blue" }} className="weightFont">
                {userStatistics.activeByPhone}
              </td>
              <td style={{ color: "blue" }} className="weightFont">
                {userStatistics.inActiveByPhone}
              </td>
              <td style={{ color: "blue" }} className="weightFont">
                {data.guests ? data.guests.length : 0}
              </td>
              <th scope="row">
                {userStatistics.total && data.guests
                  ? +userStatistics.total + data.guests.length
                  : 0}
              </th>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };
  return (
    <Card className="table_card mb-3">
      <div>
        <UserSearch
          general={general}
          filterArray={data.filterArray}
          fetchUsers={fetchUsers}
          GetStatUsers={GetStatUsers}
          userType={type}
          setType={setType}
          userTypes={options}
        />
        {rednerActivitionInfoTable()}

        <Table responsive hover className="mt-4">
          <thead>
            <tr className="text-center">
              <th>{local.Images}</th>
              <th>{local.Id}</th>
              <th>{local.FullName}</th>
              <th>{local.Email}</th>
              <th>{local.Mobile}</th>
              <th>{local.Country}</th>
              <th>{local.JoinDate}</th>
              <th>{local.Activation}</th>
            </tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </Table>
        <Row>
          <Col className="center mb-3" xl={12} lg={12} md={12} sm={12} xs={12}>
            {data.pagination !== null && (type === 1 || type === 3) ? (
              <PaginationItem
                pagination={data.pagination}
                filterArray={data.filterArray}
                general={general}
                fetchData={fetchUsers}
              />
            ) : null}
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default TableList;
