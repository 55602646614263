import React from "react";
import { UPDATE_BRANCHES_PROPS } from "../../Redux/Actions/types";
import { convertArrayToObject } from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { confirmAlert } from "react-confirm-alert";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";

export const renderBranchesOptions = () => {
  return [
    { label: local.Branch, value: 0 },
    { label: local.Shop, value: 1 },
  ];
};

export const onMapClick = (lat, lng, general) => {
  general(
    [
      { prop: "data.form.Lat", value: lat },
      { prop: "data.form.Lng", value: lng },
    ],
    UPDATE_BRANCHES_PROPS
  );
};

export const unsetErrorMessage = (general, action) => {
  general(
    [
      {
        prop: "data.errorMessages",
        value: { NameEn: "", NameAr: "", Venue: "", Location: "", Type: "" },
      },
    ],
    action
  );
};

export const validateForm = (form, venueId, general, action) => {
  if (
    !form.Name.en ||
    !form.Name.ar ||
    !form.Lng ||
    !form.Lat ||
    !venueId ||
    form.Type === null
  ) {
    window.scrollTo(0, 0);

    if (!venueId) {
      general(
        [{ prop: "data.errorMessages.Venue", value: local.SelectCompany }],
        action
      );
    }
    if (!form.Name.en) {
      general(
        [{ prop: "data.errorMessages.NameEn", value: local.NameEnRequired }],
        action
      );
    }
    if (!form.Name.ar) {
      general(
        [{ prop: "data.errorMessages.NameAr", value: local.NameArRequired }],
        action
      );
    }
    if (!form.Lat && !form.Lng) {
      general(
        [
          {
            prop: "data.errorMessages.Location",
            value: local.LocationRequired,
          },
        ],
        action
      );
    }

    if (form.Typ === null) {
      general(
        [{ prop: "data.errorMessages.Type", value: local.SelectType }],
        action
      );
    }
  } else {
    unsetErrorMessage(general, action);
    return true;
  }
};

export const handleSave = (
  e,
  action_type,
  form,
  general,
  callback,
  venueId,
  history
) => {
  e.preventDefault();
  if (validateForm(form, venueId, general, UPDATE_BRANCHES_PROPS) === true) {
    let newform = Object.assign({}, form);
    if (form.WorkingHours.length > 0) {
      let newOpenningHours = convertArrayToObject(form.WorkingHours);
      newform.WorkingHours = newOpenningHours;
    }

    if (newform.Photos) {
      if (newform.Photos.length > 0) {
        if (newform.Photos.find((p) => !p[0] instanceof File)) {
          delete newform.Photos;
        }
      }
    }

    // if (newform.id !== undefined) {
    //   if (newform.Photos.length > 0) {
    //     if (newform.Photos > 1) {
    //       let entry = newform.Photos.filter((f) => Array.isArray(f));
    //       if (entry.length > 0) {
    //         newform.Photos = entry;
    //       } else {
    //         delete newform.Photos;
    //       }
    //     }
    //   } else {
    //     if (!newform.Photos[0] instanceof File) {
    //       delete newform.Photos;
    //     }
    //   }
    // }

    if (newform.id === undefined) {
      action_type(newform, venueId, callback);
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{local.Sure}</h1>
              <p>{local.ConfirmEdit}</p>
              <button onClick={onClose}>{local.No}</button>
              <button
                onClick={() => {
                  action_type(newform, history);
                  onClose();
                }}
              >
                {local.Yes}
              </button>
            </div>
          );
        },
      });
    }
  }
};

export const storeWorkingHours = (WorkingHours) => {
  if (!WorkingHours) {
    return [];
  } else {
    let items = Object.entries(WorkingHours).map((work) => {
      return {
        day: work[0],
        from: work[1] ? work[1].from : "",
        to: work[1] ? work[1].to : "",
      };
    });

    return items;
  }
};

export const renderImages = (images) => {
  if (images) {
    let newImages = [];

    images.map((image) => {
      newImages.push(IMAGE_BASE_PATH + image);
    });

    return newImages;
  } else {
    return [];
  }
};
