import React, { useState } from "react";
import { Card, Table } from "reactstrap";
import { local } from "../../lang/local";
import {
  renderAddButton,
  renderCheckoBox,
  renderMultipleDeleteButton,
  renderTableDropDown,
} from "../../General/TableFunctions";

import { UPDATE_ROLES_PROPS } from "../../Redux/Actions/types";

const TableList = ({
  history,
  data,
  DeleteRole,
  general,
  deleteMultipleRoles,
  getClaimsByRole,
}) => {
  const [checked, setChecked] = useState([]);

  const deleteItem = (e, item, index) => {
    e.preventDefault();
    DeleteRole(item.id, index);
  };

  const editItem = (e, item, index) => {
    e.preventDefault();

    getClaimsByRole(item, true);
    general(
      [
        {
          prop: "data.form",
          value: {
            name: item.name,
            description: item.description,
          },
        },

        {
          prop: "data.item_index",
          value: index,
        },
        {
          prop: "data.form.id",
          value: item.id,
        },
      ],
      UPDATE_ROLES_PROPS
    );
    history.push(`/dashboard/editrole/${item.id}`);
  };

  const renderTableBody = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return data.items.map((item, index) => {
          return (
            <tr key={index} className="text-center">
              <th scope="row">
                {renderCheckoBox(
                  item.id,
                  checked,
                  setChecked,
                  "single",
                  "RoleBusiness_Delete"
                )}
              </th>

              <td>{item.id}</td>
              <td>
                <a
                  href=""
                  className="blueLight"
                  onClick={(e) => {
                    e.preventDefault();
                    getClaimsByRole(item);
                  }}
                >
                  {item.name}
                </a>
              </td>
              <td>{item.description}</td>
              <td>
                {renderTableDropDown(
                  item,
                  index,
                  deleteItem,
                  editItem,
                  "RoleBusiness_Delete",
                  "RoleBusiness_UpdateRole"
                )}
              </td>
            </tr>
          );
        });
      }
    }
  };
  return (
    <Card className="table_card">
      <div className="mt-4 ml-4 mr-4 mb-5">
        {renderAddButton(
          local.AddRole,
          history,
          "/dashboard/addrole",
          "RoleBusiness_Create"
        )}

        {renderMultipleDeleteButton(
          checked,
          setChecked,
          deleteMultipleRoles,
          data.isLoadingDelete,
          "RoleBusiness_Delete"
        )}

        <Table responsive hover className="mt-4">
          <thead>
            <tr className="text-center">
              <th>
                {data.items
                  ? data.items.length > 0
                    ? renderCheckoBox(
                        data.items.map((i) => i.id),
                        checked,
                        setChecked,
                        "all",
                        "RoleBusiness_Delete"
                      )
                    : null
                  : null}
              </th>
              <th>{local.Id}</th>
              <th>{local.Name}</th>
              <th>{local.Description}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </Table>
      </div>
    </Card>
  );
};

export default TableList;
