import {
  UPDATE_BRANCHES_PROPS,
  MERGE_BRANCHES_PROPS,
  DELETE_BRANCHES_PROPS,
  CLEAR_BRANCHES_STATE,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    items: null,
    pagination: null,
    isLoadingDelete: null,
    form: {
      Name: { en: "", en: "" },
      WorkingHours: [
        { day: "Monday", from: null, to: null },
        { day: "Tuesday", from: null, to: null },
        { day: "Wednesday", from: null, to: null },
        { day: "Thursday", from: null, to: null },
        { day: "Friday", from: null, to: null },
        { day: "Saturday", from: null, to: null },
        { day: "Sunday", from: null, to: null },
      ],
      Photos: [],
      Phone: "",
      Lat: null,
      Lng: null,
      Type: 0,
    },
    errorMessages: {
      NameEn: "",
      NameAr: "",
      Venue: "",
      Location: "",
      Type: "",
    },

    filterArray: [
      {
        op: 0,
        fieldName: "Type",
        value: 0,
      },
      {
        op: 3,
        fieldName: "",
        value: "",
      },
      {
        op: 3,
        fieldName: "Phone",
        value: "",
      },

      {
        op: 0,
        fieldName: "VenueId",
        value: null,
      },
    ],
    venueId: null,
    item_index: null,
    isLoadingCreate: false,
    isLoading: false,
    isLoadingSearch: false,
  },
};

const BranchesR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_BRANCHES_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_BRANCHES_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_BRANCHES_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_BRANCHES_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default BranchesR;
