import React, { useState } from "react";
import { Row, Col, Card } from "reactstrap";
import { local } from "../../lang/local";
import {
  renderAddButton,
  renderCheckoBox,
  renderMultipleDeleteButton,
  renderTableDropDown,
} from "../../General/TableFunctions";
import { UPDATE_VENUES_PROPS } from "../../Redux/Actions/types";
import PaginationItem from "../../General/PaginationItem";
import {
  handleArrawCardClick,
  isMyElement,
  ShareIcon,
} from "../../General/GeneralFunctions";
import Share from "../../General/Share";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";
import { renderSingleImage, renderName } from "../../General/GeneralFunctions";
import { openProfile, unsetErrorMessage } from "./VenuesFunctions";

const WebList = ({
  history,
  data,
  DeleteVenue,
  general,
  fetchVenues,
  deleteMultipleVenues,
  rows_collabse,
  fetchVenue,
}) => {
  const [checked, setChecked] = useState([]);

  const deleteItem = (e, item, index) => {
    e.preventDefault();
    DeleteVenue(item.id, index);
  };

  const editItem = (e, item, index) => {
    e.preventDefault();
    unsetErrorMessage(general);
    general(
      [
        {
          prop: "data.form",
          value: {
            Name: {
              en: item.name["en"],
              ar: item.name["ar"],
            },
            Logo: item.logo ? IMAGE_BASE_PATH + item.logo : null,
            Phone: item.phone ? item.phone : "",
          },
        },

        {
          prop: "data.item_index",
          value: index,
        },
        {
          prop: "data.form.id",
          value: item.id,
        },
      ],
      UPDATE_VENUES_PROPS
    );
    history.push(`/dashboard/editvenue/${item.id}`);
  };

  const renderTableBody = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return data.items.map((item, index) => {
          return (
            <React.Fragment>
              <Card
                className="header_card"
                style={{
                  width: rows_collabse[index] === true ? "100%" : "300px",
                  zIndex: 10,
                }}
                key={index}
              >
                {handleArrawCardClick(rows_collabse, index, general)}
                <div className="flex aligmItem fullWidthHeight">
                  <ul className="parent-container">
                    <li className="child-item center">
                      {renderCheckoBox(
                        item.id,
                        checked,
                        setChecked,
                        "single",
                        "Venues_Delete"
                      )}
                    </li>
                    <li className="child-item center">{item.id} </li>
                    <li className="child-item center">
                      <a
                        href=""
                        className="blueLight"
                        onClick={(e) =>
                          openProfile(e, general, fetchVenue, item, index)
                        }
                      >
                        {renderName(item.name)}
                      </a>
                    </li>

                    {rows_collabse[index] === true ? (
                      <>
                        <li className="child-item center">{item.phone} </li>
                        <li className="child-item center">
                          {item.branches ? (
                            <Share
                              TagItem={ShareIcon}
                              bulk={`https://mapy.world?companyId=${item.id}`}
                            />
                          ) : null}
                        </li>

                        <li className="child-item center">
                          {renderSingleImage(item.logo, general)}
                        </li>

                        <li className="child-item center">
                          {isMyElement(item.userId)
                            ? renderTableDropDown(
                                item,
                                index,
                                deleteItem,
                                editItem,
                                "Venues_Delete",
                                "Venues_Update"
                              )
                            : null}
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              </Card>
              <hr
                className="border_list_hr"
                style={{
                  width: rows_collabse[index] === true ? "100%" : "300px",
                }}
              />
            </React.Fragment>
          );
        });
      }
    }
  };
  return (
    <React.Fragment>
      <Card
        className="header_card cursor"
        style={{
          width:
            rows_collabse.length > 0
              ? rows_collabse.find((col) => col === true)
                ? "100%"
                : "300px"
              : "300px",
          height: "100px",
          zIndex: 10,
        }}
      >
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderAddButton(
              local.AddVenue,
              history,
              "/dashboard/addvenue",
              "Venues_Create"
            )}

            {renderMultipleDeleteButton(
              checked,
              setChecked,
              deleteMultipleVenues,
              data.isLoadingDelete,
              "Venues_Delete"
            )}
          </Col>
        </Row>
        <div className="flex aligmItem fullWidthHeight">
          <ul className="parent-container">
            <li className="child-item center">
              {data.items
                ? data.items.length > 0
                  ? renderCheckoBox(
                      data.items.map((i) => i.id),
                      checked,
                      setChecked,
                      "all",
                      "Venues_Delete"
                    )
                  : null
                : null}
            </li>
            <li className="child-item center bold">{local.Id}</li>
            <li className="child-item center bold"> {local.Name}</li>

            {rows_collabse.length > 0 ? (
              rows_collabse.find((col) => col === true) ? (
                <>
                  <li className="child-item center bold">{local.Mobile}</li>
                  <li className="child-item center bold">{local.ShareLink}</li>
                  <li className="child-item center bold"> {local.Image}</li>

                  <li className="child-item center bold"></li>
                </>
              ) : null
            ) : null}
          </ul>
        </div>
      </Card>
      <hr className="border_list_hr" />
      {renderTableBody()}

      <Card
        className="header_card"
        style={{
          width: "300px",
          zIndex: 10,
        }}
      >
        <div className="center fullWidthHeight mt-4 mb-4">
          {data.pagination !== null ? (
            <PaginationItem
              pagination={data.pagination}
              filterArray={data.filterArray}
              general={general}
              fetchData={fetchVenues}
            />
          ) : null}
        </div>
      </Card>
    </React.Fragment>
  );
};

export default WebList;
