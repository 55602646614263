import React, { useEffect } from "react";
import "./web.css";
import * as actions from "../../../Redux/Actions/index";
import { connect } from "react-redux";
import SearchMenu from "../SearchMenu/SearchMenu";
import LocationMenu from "../LocationMenu/LocationMenu";
import UserMenu from "../UserMenu/UserMenu";
import DirectionMenu from "../DirectionMenu/DirectionMenu";
import SearchDirection from "../DirectionMenu/SearchDirection";
import {
  check_authorization,
  getMyLocation,
} from "../../../General/GeneralFunctions";
// import Maps from "../Maps/Maps";
import { UPDATE_GENERAL_PROPS } from "../../../Redux/Actions/types";
import Hours from "../Contribution/Hours";
import Contribution from "../Contribution/Contribution";
import PlaceList from "../Places/PlaceList";
import AddContribution from "../Contribution/AddContribution";
import MapyView from "../MapyView/MapyView";
import AddComplaintModel from "../../Complaints/AddComplaintModel";
import MobileView from "./MobileView/MobileView";
import { getUser } from "../../../utils/StorageFunctions";

const WebView = ({
  general,
  fetchContribtions,
  getLocationWithIp,
  getRegisteredPlaces,
  getUnRegisterdPlaces,
  fetchAllComplaintTypes,
  search_data,
  userAccount,
  search_direction,
  history,
  lists,
  direction_data,
  contribution,
  hours,
  location_data,
  getSocketResult,
  myPosition,
  searchResult,
  ipAddress,
  startLocation,
  endLocation,
  addContribuation,
  branches,
  fetchCompany,
  fetchPlace,
  getBranchOrDistributor,
  fetchComplaint,
  venue,
  allComplaintTypes,
}) => {
  useEffect(() => {
    initiComponent();
  }, []);

  const initiComponent = () => {
    getMyLocation(
      [
        { prop: "myPosition.lat", type: "lat" },
        { prop: "myPosition.lng", type: "lng" },
      ],
      general,
      UPDATE_GENERAL_PROPS
    );
    if (getUser()) {
      fetchAllComplaintTypes(1);
      getLocationWithIp();

      fetchContribtions([], 1);
      getRegisteredPlaces(1);
      getUnRegisterdPlaces(1);
    }
  };

  const renderCards = () => {
    if (search_data.toViewSearch === true) {
      return <SearchMenu history={history} />;
    }

    if (location_data.toViewSideInfo === true) {
      return <LocationMenu />;
    }

    if (userAccount.isOpen === true) {
      return <UserMenu history={history} />;
    }

    if (direction_data.isOpen === true) {
      return <DirectionMenu />;
    }

    if (search_direction.isOpen === true) {
      return <SearchDirection />;
    }

    if (contribution.isOpenAddContribution === true) {
      return <AddContribution history={history} />;
    }

    if (hours.isOpen === true) {
      return (
        <Hours
          general={general}
          WorkingHours={contribution.form.WorkingHours}
        />
      );
    }
    if (contribution.isOpen === true) {
      return <Contribution />;
    }

    if (lists.list_item.isOpen === true) {
      return <PlaceList />;
    }
  };

  return (
    <div className="page-container">
      <AddComplaintModel />

      {window.innerWidth > 760 ? (
        <React.Fragment>{renderCards()}</React.Fragment>
      ) : (
        <div className="mobile_view_container">
          <MobileView search_data={search_data}>{renderCards()}</MobileView>
        </div>
      )}
      {/* 
      {ipAddress.lat !== 0 ? (
        <Maps
          search_data={search_data}
          location_data={location_data}
          direction_data={direction_data}
          myPosition={myPosition}
          ipAddress={ipAddress}
          history={history}
          searchResult={searchResult}
          search_direction={search_direction}
          general={general}
          getSocketResult={getSocketResult}
          startLocation={startLocation}
          endLocation={endLocation}
          addContribuation={addContribuation}
          fetchCompany={fetchCompany}
          branches={branches}
          fetchPlace={fetchPlace}
          getBranchOrDistributor={getBranchOrDistributor}
          fetchComplaint={fetchComplaint}
          isOpenAddContribution={contribution.isOpenAddContribution}
        /> */}

      <MapyView
        search_data={search_data}
        location_data={location_data}
        direction_data={direction_data}
        myPosition={myPosition}
        ipAddress={ipAddress}
        history={history}
        searchResult={searchResult}
        search_direction={search_direction}
        general={general}
        getSocketResult={getSocketResult}
        startLocation={startLocation}
        endLocation={endLocation}
        addContribuation={addContribuation}
        fetchCompany={fetchCompany}
        branches={branches}
        venue={venue}
        fetchPlace={fetchPlace}
        getBranchOrDistributor={getBranchOrDistributor}
        fetchComplaint={fetchComplaint}
        isOpenAddContribution={contribution.isOpenAddContribution}
        allComplaintTypes={allComplaintTypes}
      />
      {/* ) : (
        false
      )} */}
    </div>
  );
};

const mapStateToProps = ({
  PlaceR,
  GeneralR,
  SearchMenuR,
  LocationR,
  UserAccountR,
  DirectionR,
  ContribuationsR,
  ComplaintTypeR,
}) => {
  return {
    search_data: SearchMenuR.search_data,
    searchResult: SearchMenuR.searchResult,
    ipAddress: GeneralR.ipAddress,
    myPosition: GeneralR.myPosition,
    branches: GeneralR.branches,
    venue: GeneralR.links.venue,
    location_data: LocationR.location_data,
    userAccount: UserAccountR.userAccount,
    direction_data: DirectionR.direction_data,
    search_direction: DirectionR.search_direction,
    startLocation: DirectionR.startLocation,
    endLocation: DirectionR.endLocation,
    contribution: ContribuationsR.data,
    hours: ContribuationsR.hours,
    lists: PlaceR.lists,
    allComplaintTypes: ComplaintTypeR.data.allComplaintTypes,
  };
};
export default connect(mapStateToProps, actions)(WebView);
