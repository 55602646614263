import { combineReducers } from "redux";
import HomeR from "./HomeR";
import LoginR from "./LoginR";
import UsersR from "./UsersR";
import ContribuationsR from "./ContribuationsR";
import RegisterR from "./RegisterR";
import ConfirmSmsR from "./ConfirmSmsR";
import ComplaintTypeR from "./ComplaintTypeR";
import ComplaintsR from "./ComplaintsR";
import GeneralR from "./GeneralR";
import VenuesR from "./VenuesR";
import BranchesR from "./BranchesR";
import TicketsR from "./TicketsR";
import RolesR from "./RolesR";
import RolesClaimsR from "./RolesClaimsR";
import UserClaimsR from "./UserClaimsR";
import UserRolesR from "./UserRolesR";
import ReviewsR from "./ReviewsR";
import BusinessR from "./BusinessR";
import SupportR from "./SupportR";
import DistributorsR from "./DistributorsR";
import SearchMenuR from "./SearchMenuR";
import LocationR from "./LocationR";
import DirectionR from "./DirectionR";
import UserAccountR from "./UserAccountR";
import PlaceR from "./PlaceR";
import AuthR from "./AuthR";

export default combineReducers({
  HomeR,
  LoginR,
  RegisterR,
  ConfirmSmsR,
  ComplaintTypeR,
  ComplaintsR,
  GeneralR,
  ContribuationsR,
  UsersR,
  VenuesR,
  BranchesR,
  TicketsR,
  RolesR,
  RolesClaimsR,
  UserClaimsR,
  UserRolesR,
  ReviewsR,
  BusinessR,
  SupportR,
  DistributorsR,
  SearchMenuR,
  LocationR,
  DirectionR,
  UserAccountR,
  PlaceR,
  AuthR,
});
