import React, { Component } from "react";
import "./home.css";
import { Col, Row } from "reactstrap";
import * as actions from "../../Redux/Actions/index";
import { connect } from "react-redux";
import DOWNLOAD_ICON_BLACK from "../../assets/img/download_black.png";
import DOWNLOAD_ICON_WHITE from "../../assets/img/download_white.png";
import DOWNLOAD_LINK_BLACK from "../../assets/img/download_icon_black.png";
import DOWNLOAD_LINK_WHITE from "../../assets/img/download_icon_white.png";
import { local } from "../../lang/local";
import { check_authorization } from "../../General/GeneralFunctions";
import DashboardUsersAnalysisCard from "./DashboardUsersAnalysisCard/DashboardUsersAnalysisCard";
import DoughnutChartCard from "./DoughnutChartCard/DoughnutChartCard";
import { renderAccountCard } from "./HomeFunction";
import { getPermissions } from "../../utils/Permissions";
import InfoCard from "./InfoCard/InfoCard";
import {
  UPDATE_USERS_PROPS,
  CLEAR_Home_STATE,
} from "../../Redux/Actions/types";
import MapCard from "./MapCard";
import ProfileModal from "../../utils/ProfileModal";
import UserProfile from "../Users/UserProfile";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date().toLocaleString(),
    };
  }
  componentDidMount() {
    this.fetechData();
  }
  componentWillUnmount() {
    this.props.general([], CLEAR_Home_STATE);
  }
  fetechData = () => {
    const {
      history,
      getActiveUsers,
      getInActiveUsers,
      getSystemUsers,
      getTotalRequests,
      getStatisticsLayers,
      getAllUsersForMap,
      getTotalGuests,
    } = this.props;
    this.props.general([], CLEAR_Home_STATE);

    if (check_authorization(history) === true) {
      if (
        getPermissions(true, true).Home_Role === undefined &&
        getPermissions(true, true).Administrator === undefined
      ) {
        history.push("/");
      } else {
        this.intervalID = setInterval(() => this.tick(), 1000);
        if (
          getPermissions(true, true).UserBusiness_GetUsers !== undefined ||
          getPermissions(true, true).Administrator !== undefined
        ) {
          // getAllUsersForMap(1);
          getActiveUsers();
          getInActiveUsers();
          getSystemUsers();
        }
        getTotalGuests();
        getTotalRequests();
        getStatisticsLayers();
      }
    }
  };
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      time: new Date().toLocaleString(),
    });
  }
  getActiveUsers = (e) => {
    e.preventDefault();

    const { history, general } = this.props;
    general(
      [{ prop: `data.filterArray.${3}.value`, value: true }],
      UPDATE_USERS_PROPS
    );

    history.push("/dashboard/users");
  };
  getInActiveUsers = (e) => {
    e.preventDefault();
    const { history, general } = this.props;

    general(
      [{ prop: `data.filterArray.${3}.value`, value: false }],
      UPDATE_USERS_PROPS
    );

    history.push("/dashboard/users");
  };

  render() {
    const {
      history,
      data,
      location,
      profileModal,
      general,
      getAllUsersForMap,
    } = this.props;
    return (
      <React.Fragment>
        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={local.UserProfile}
          Children={UserProfile}
        />

        <Row>
          <Col xl={1} lg={1} md={12} sm={12} xs={12} className="maxWidth">
            <Row>
              <Col>
                <InfoCard
                  downloadIcon={DOWNLOAD_ICON_WHITE}
                  downloadLink={DOWNLOAD_LINK_WHITE}
                  bgColor="#002245"
                  textColor="white"
                  infoNumber={data.user_system.guests}
                  infoText={local.Guests}
                  handleClick={null}
                />
              </Col>
              <Col>
                <InfoCard
                  downloadIcon={DOWNLOAD_ICON_BLACK}
                  downloadLink={DOWNLOAD_LINK_BLACK}
                  bgColor="white"
                  textColor="black"
                  infoNumber={
                    +data.user_system.users + +data.user_system.guests
                  }
                  infoText={local.TotalDownloads}
                  pathName={null}
                />
              </Col>

              {getPermissions(true, true).UserBusiness_GetUsers !== undefined ||
              getPermissions(true, true).Administrator !== undefined ? (
                <>
                  <Col className="stat_home_col">
                    <InfoCard
                      downloadIcon={DOWNLOAD_ICON_WHITE}
                      downloadLink={DOWNLOAD_LINK_WHITE}
                      bgColor="#002245"
                      textColor="white"
                      infoNumber={data.active_users}
                      infoText={local.ActiveUsers}
                      history={history}
                      handleClick={this.getActiveUsers}
                    />
                  </Col>
                  <Col>
                    <InfoCard
                      downloadIcon={DOWNLOAD_ICON_WHITE}
                      downloadLink={DOWNLOAD_LINK_WHITE}
                      bgColor="#12A9CC"
                      textColor="white"
                      infoNumber={data.inactive_users}
                      infoText={local.InActiveUsers}
                      handleClick={this.getInActiveUsers}
                    />
                  </Col>
                </>
              ) : null}
            </Row>
          </Col>

          <Col
            xl={1}
            lg={1}
            md={12}
            sm={12}
            xs={12}
            className="maxWidth padding0_margin0"
          >
            <Row>
              <Col>
                <DashboardUsersAnalysisCard
                  history={history}
                  items={[
                    {
                      label: local.Users,
                      value: +data.user_system.users + +data.user_system.guests,
                      routeName: "/users",
                    },
                    {
                      label: local.Venues,
                      value: data.user_system.venues,
                      routeName: "/venues",
                    },
                    {
                      label: local.Support,
                      value: data.user_system.tickets,
                      routeName: "/tickets",
                    },
                  ]}
                  title={local.DashboardUsers}
                />
              </Col>
              {/* {getPermissions(true, true).Contribution_Get !== undefined ||
                  getPermissions(true, true).Administrator !== undefined ? ( */}
              <Col className="stat_home_col">
                <DoughnutChartCard layers={data.layers} />
              </Col>
              {/* ) : null} */}
              {/* <Col>
                      <TransportationCard />
                  </Col> */}
            </Row>
          </Col>
          <Col>
            {renderAccountCard(this.state.time)}
            {/* <Row>
                  <Col
                    className="maxWidth mt-3 padding0_margin0"
                    xl={3}
                    lg={3}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                   {renderStatCard()}
                  </Col>
                  <Col
                    className="mt-3 padding0_margin0 st_card_count"
                    xl={3}
                    lg={3}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                   {renderStatCard()}
                  </Col>
                </Row> */}

            <MapCard
              location={location}
              usersMap={data.usersMap}
              general={general}
              getAllUsersForMap={getAllUsersForMap}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const GeneralMaptateToProps = ({ HomeR, GeneralR }) => {
  return {
    data: HomeR.data,
    location: GeneralR.location,
    profileModal: GeneralR.profileModal,
  };
};
export default connect(GeneralMaptateToProps, actions)(Home);
