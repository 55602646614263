import {
  showSuccessedConfirm,
  removeUnsetValuesFromFilters,
  showConfirmMessage,
  removeUnsetValues,
  getLatLng,
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { SendRequest } from "./api";
import { fetchCountry } from "./GeneralAction";
import {
  MERGE_GENERAL_PROPS,
  MERGE_USERS_PROPS,
  UPDATE_GENERAL_PROPS,
  UPDATE_USERS_PROPS,
} from "./types";

export const fetchUsers = (filterArray, page) => (dispatch) => {
  if (filterArray.length > 0) {
    dispatch({
      type: UPDATE_USERS_PROPS,
      payload: [{ prop: "data.isLoadingSearch", value: true }],
    });
  }
  dispatch({
    type: UPDATE_USERS_PROPS,
    payload: [{ prop: "data.isLoading", value: true }],
  });
  filterArray = removeUnsetValuesFromFilters(filterArray);
  SendRequest()
    .post(`/User/Get?page=${page}`, filterArray)
    .then((res) => {
      if (res.status === 200) {
        let pagination = Object.assign({}, res.data.data);
        delete pagination.items;

        dispatch({
          type: UPDATE_USERS_PROPS,
          payload: [
            { prop: "userStatistics.total", value: pagination.totalItems },
            { prop: "data.items", value: res.data.data.items },
            { prop: "data.pagination", value: pagination },
          ],
        });
      }

      dispatch({
        type: UPDATE_USERS_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_USERS_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const fetchGuests = (filterArray, page) => async (dispatch) => {
  if (filterArray.length > 0) {
    dispatch({
      type: UPDATE_USERS_PROPS,
      payload: [{ prop: "data.isLoadingSearch", value: true }],
    });
  }
  dispatch({
    type: UPDATE_USERS_PROPS,
    payload: [{ prop: "data.isLoading", value: true }],
  });
  filterArray = removeUnsetValuesFromFilters(filterArray);
  SendRequest()
    .post(`/Guests/Get?page=${page}`, filterArray)
    .then((res) => {
      if (res.status === 200) {
        let pagination = Object.assign({}, res.data.data);
        delete pagination.items;
        let guests = [];
        res.data.data.items.map(async (item) => {
          let latlng = item.lastSeenText ? getLatLng(item.lastSeenText) : null;
          guests.push({
            image: null,
            entry: item,
            id: item.id,
            fullName: "Guest",
            email: "",
            phoneNumber: "",
            country: "",
            joinDate: item.time,
            latlng,
          });
        });
        dispatch({
          type: UPDATE_USERS_PROPS,
          payload: [{ prop: "data.guests", value: guests }],
        });
        let latlngs = guests.map((gue) => gue.latlng);
        dispatch(fetchCountry("data.guests.", latlngs));
      }

      dispatch({
        type: UPDATE_USERS_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_USERS_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const changeUserStatus = (id, index, type) => (dispatch) => {
  let url = "/User";

  if (type === true) {
    url = url + `/Activate/${id}`;
  } else {
    url = url + `/DeActivate/${id}`;
  }
  SendRequest()
    .post(url)
    .then((res) => {
      if (res.status === 200) {
        if (type === true) {
          showSuccessedConfirm(local.ConfirmedSuccessfully);
        } else {
          showSuccessedConfirm(local.AccountDeactivated);
        }
        dispatch({
          type: UPDATE_USERS_PROPS,
          payload: [
            { prop: "data.items." + index + ".activated", value: type },
          ],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

// export const getUsersForDropDown = (page, searchValue) => (dispatch) => {
//   let newFilterArray = [];

//   let url = "/User/Get";
//   if (page !== null) {
//     url = url + `?page=${page}`;
//   }
//   if (searchValue !== undefined) {
//     newFilterArray = [
//       {
//         op: 4,
//         fieldName: "FirstName",
//         value: searchValue,
//       },
//     ];
//   }
//   SendRequest()
//     .post(url, newFilterArray)
//     .then((res) => {
//       if (res.status === 200) {
//         let newUsers = [];
//         res.data.data.items.forEach((user) => {
//           newUsers.push({
//             label: user.fullName,
//             value: user.id,
//           });
//         });

//         dispatch({
//           type: UPDATE_USERS_PROPS,
//           payload: [{ prop: "usersOptions", value: newUsers }],
//         });
//       }
//     })
//     .catch((e) => {})
//     .finally();
// };

export const loadUserOptions =
  (search, loadedOptions, { page }, isActive) =>
  async (dispatch) => {
    let newFilterArray = [];
    let newUsers = [];
    let hasMore = false;
    let url = "/User/Get";

    if (search) {
      newFilterArray.push({
        op: 3,
        fieldName: "FirstName",
        value: search,
      });
    } else {
      if (page !== null) {
        url = url + `?page=${page}`;
      }
    }

    await SendRequest()
      .post(url, newFilterArray)
      .then((res) => {
        if (res.status === 200) {
          let items =
            isActive === undefined
              ? res.data.data.items
              : res.data.data.items.filter(
                  (u) =>
                    u.emailConfirmed === true || u.phoneNumberConfirmed === true
                );
          items.forEach((user) => {
            newUsers.push({
              label: user.fullName,
              value: user.id,
            });
          });
          if (res.data.data.currentPage < res.data.data.totalPages) {
            hasMore = true;
          }

          if (page === 1) {
            dispatch({
              type: UPDATE_USERS_PROPS,
              payload: [{ prop: "usersOptions", value: newUsers }],
            });
          } else {
            dispatch({
              type: MERGE_USERS_PROPS,
              payload: [{ prop: "usersOptions", value: newUsers }],
            });
          }
        }
      })
      .catch((e) => {})
      .finally();
    return {
      options: newUsers,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

export const getGeneralUsers = (userIDs, generalUsers) => (dispatch) => {
  let filteredIds = [];
  if (generalUsers.length > 0) {
    userIDs.map((id) => {
      if (!generalUsers.find((gu) => gu.id === id)) {
        filteredIds.push(id);
      }
    });
  } else {
    filteredIds = userIDs;
  }

  if (filteredIds.length > 0) {
    let filterArray = [
      {
        op: 3,
        fieldName: "Id",
        value: filteredIds,
      },
    ];

    SendRequest()
      .post(`/User/Get`, filterArray)
      .then((res) => {
        if (res.status === 200) {
          if (generalUsers.length > 0) {
            dispatch({
              type: MERGE_GENERAL_PROPS,
              payload: [{ prop: "generalUsers", value: res.data.data.items }],
            });
          } else {
            dispatch({
              type: UPDATE_GENERAL_PROPS,
              payload: [{ prop: "generalUsers", value: res.data.data.items }],
            });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally();
  }
};

export const getEmailCode = (email, index) => (dispatch) => {
  SendRequest()
    .get(`/User/email?phone=${email}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data) {
          dispatch({
            type: UPDATE_GENERAL_PROPS,
            payload: [
              {
                prop: `profileModal.data.code`,
                value: res.data.data.code,
              },

              {
                prop: `profileModal.data.expireDate`,
                value: res.data.data.expireDate,
              },
            ],
          });
          dispatch({
            type: UPDATE_USERS_PROPS,
            payload: [
              {
                prop: `data.items.${index}.code`,
                value: res.data.data.code,
              },

              {
                prop: `data.items.${index}.expireDate`,
                value: res.data.data.expireDate,
              },
            ],
          });
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const GetStatUsers = (newFilterArray) => (dispatch) => {
  let filterArray = [
    {
      op: 0,
      fieldName: "phoneNumberConfirmed",
      value: true,
    },
    {
      op: 0,
      fieldName: "EmailConfirmed",
      value: true,
    },
    {
      op: 0,
      fieldName: "phoneNumberConfirmed",
      value: false,
    },
    {
      op: 0,
      fieldName: "EmailConfirmed",
      value: false,
    },
  ];

  filterArray.map((entry) => {
    let prop = "";
    if (entry.fieldName === "EmailConfirmed") {
      if (entry.value === true) {
        prop = "userStatistics.activeByEmail";
      } else {
        prop = "userStatistics.inActiveByEmail";
      }
    } else {
      if (entry.value === true) {
        prop = "userStatistics.activeByPhone";
      } else {
        prop = "userStatistics.inActiveByPhone";
      }
    }

    let filter = [entry];
    if (newFilterArray !== undefined) {
      if (newFilterArray.length > 0) {
        newFilterArray.map((f) => {
          filter.push(f);
        });
      }
    }
    return SendRequest()
      .post("/User/Get", filter)
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: UPDATE_USERS_PROPS,
            payload: [
              {
                prop,
                value: res.data.data.totalItems,
              },
            ],
          });
        }
      })
      .catch((e) => {})
      .finally();
  });
};

export const updateUser = (form, history) => (dispatch) => {
  let newFormValues = Object.assign({}, form);
  newFormValues = removeUnsetValues(newFormValues);
  // let formData = renderFormData(newFormValues);

  dispatch({
    type: UPDATE_USERS_PROPS,
    payload: [{ prop: "isLoadingCreate", value: true }],
  });
  SendRequest()
    .put("/User/Update", newFormValues)
    .then((res) => {
      if (res.status === 200) {
        showSuccessedConfirm(local.SuccessfullyUpdated);
        history.push("/dashboard/profile");
      }

      dispatch({
        type: UPDATE_USERS_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_USERS_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
      if (Object.values(err)[2].data) {
        // if (Object.values(err)[2].data.title) {
        //   showConfirmMessage(Object.values(err)[2].data.title, "error");
        // }

        Object.values(Object.values(err)[2].data.data).map((e) => {
          showConfirmMessage(e[0], "error");
        });
      }

      console.log(err);
    })
    .finally();
};
