import { Card, Row, Col } from "reactstrap";
import React from "react";
import avater2 from "../../assets/img/avater2.svg";
import Icon_attach from "../../assets/img/Icon_attach.png";
import { rightStyle } from "../../General/GeneralStyle";
import { getLanguage, local } from "../../lang/local";
import { checkIsLink, renderImage } from "../../General/GeneralFunctions";
import { getUser } from "../../utils/StorageFunctions";

import { rightDirection, leftDirection } from "../../General/GeneralStyle";
import Moment from "react-moment";
import { FiX } from "react-icons/fi";

import MessageMenu from "./MessageMenu";
import InfiniteScroll from "react-infinite-scroll-component";
import { FiFile } from "react-icons/fi";
import CreateTicketMessage from "./CreateTicketMessage";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";
import ChatMenu from "./ChatMenu";
import {
  DELETE_TICKETS_PROPS,
  UPDATE_GENERAL_PROPS,
} from "../../Redux/Actions/types";
const TicketBox = ({
  data_message,
  general,
  addTicketMessage,
  DeleteTicketMessage,
  fetchTicketMessages,
  deleteAllMessages,
}) => {
  const renderMessages = () => {
    let user = getUser();

    return data_message.items.map((item, index) => {
      if (user.data.id !== item.agentId) {
        return (
          <Row className="alignItem ml-1 mr-1 mt-4" key={index}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="flex alignItem">
                {item.agent !== null ? (
                  <p className="my_message_box_card mt-3 mb-3">
                    {item.agent.firstName}
                  </p>
                ) : null}
                <img
                  src={item.agent ? renderImage(item.agent.image) : avater2}
                  className="client_img"
                  alt="client"
                />

                {item.msg.text ? (
                  <Card className="card_client_message">
                    <p className="client_message_box_card ml-3 mr-3 mt-3 mb-3">
                      {item.msg.text}
                    </p>
                  </Card>
                ) : null}
                {item.attachments === null ? null : item.attachments.length ===
                  0 ? null : (
                  <p className="client_message_box_card ml-3 mr-3 mt-3 mb-3">
                    {renderGraphics(item.attachments)}
                  </p>
                )}

                <div style={{ marginInlineStart: "10px" }}>
                  <p className="date_chat">
                    <Moment fromNow locale="ar">
                      {new Date(item.created)}
                    </Moment>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row className="alignItem ml-1 mr-1 mt-4" key={index}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} style={rightDirection}>
              <div className="flex alignItem">
                {/* <IoCheckmarkDoneOutline /> */}
                {item.msg.text ? (
                  <Card className="card_my_message">
                    <p
                      className="my_message_box_card ml-3 mr-3 mt-1 mb-1"
                      style={{ fontSize: "14px" }}
                    >
                      {item.msg.text}
                    </p>
                  </Card>
                ) : null}
                {item.attachments === null ? null : item.attachments.length ===
                  0 ? null : (
                  <p className="client_message_box_card ml-3 mr-3 mt-3 mb-3">
                    {renderGraphics(item.attachments)}
                  </p>
                )}
                <MessageMenu
                  DeleteTicketMessage={DeleteTicketMessage}
                  message={item}
                  index={index}
                  general={general}
                />
                <div style={{ marginInlineStart: "10px" }}>
                  <p className="date_chat" style={leftDirection}>
                    <Moment fromNow locale="ar">
                      {new Date(item.created)}
                    </Moment>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        );
      }
    });
  };

  const renderRemoveIcon = (index) => {
    return (
      <FiX
        className="remove_icon_search"
        style={{ color: "red", position: "absolute", zIndex: "1000" }}
        onClick={(e) => {
          e.preventDefault();
          general(
            [
              {
                prop: `data_message.form.Attachments.` + index,
              },
            ],
            DELETE_TICKETS_PROPS
          );
        }}
      />
    );
  };

  const renderGraphics = (Attachments) => {
    return Attachments.map((attach, index) => {
      if (attach instanceof File) {
        if (attach.type.includes("image")) {
          return (
            <Card key={index} className="form_chat_card">
              {renderRemoveIcon(index)}
              <a
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  general(
                    [
                      {
                        prop: "lightbox",
                        value: {
                          image: URL.createObjectURL(attach),
                          isOpen: true,
                          type: "single",
                        },
                      },
                    ],
                    UPDATE_GENERAL_PROPS
                  );
                }}
                className="imgFull cursor"
                rel="noreferrer"
              >
                <img
                  src={URL.createObjectURL(attach)}
                  className="imgFull borderR10"
                  alt=""
                />
              </a>
            </Card>
          );
        } else if (
          attach.type.includes("audio") ||
          attach.type.includes("blob")
        ) {
          return (
            <audio controls className="chat_box_file" key={index}>
              <source src={URL.createObjectURL(attach)} type={attach.type} />
            </audio>
          );
        } else {
          return (
            <a
              key={index}
              href={URL.createObjectURL(attach)}
              className="chat_box_file"
              target="_blank"
              rel="noreferrer"
            >
              {renderRemoveIcon(index)}
              <FiFile size={30} color="#12a9cc" />
            </a>
          );
        }
      } else {
        if (
          attach.includes("png") ||
          attach.includes("jpg") ||
          attach.includes("jpeg") ||
          attach.includes("ico") ||
          attach.includes("icon" || attach.includes("gif"))
        ) {
          return (
            <Card key={index} className="form_chat_card">
              <a
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  general(
                    [
                      {
                        prop: "lightbox",
                        value: {
                          image: IMAGE_BASE_PATH + attach,
                          isOpen: true,
                          type: "single",
                        },
                      },
                    ],
                    UPDATE_GENERAL_PROPS
                  );
                }}
                className="imgFull cursor"
                rel="noreferrer"
              >
                <img
                  src={IMAGE_BASE_PATH + attach}
                  className="imgFull borderR10"
                  alt=""
                />
              </a>
            </Card>
          );
        } else if (attach.includes("audio")) {
          return (
            <audio controls className="chat_box_file" key={index}>
              <source src={attach} />
            </audio>
          );
        } else {
          return (
            <a
              key={index}
              href={
                checkIsLink(attach) === true ? attach : IMAGE_BASE_PATH + attach
              }
              className="chat_box_file"
              target="_blank"
              rel="noreferrer"
            >
              <FiFile size={30} color="#12a9cc" />
            </a>
          );
        }
      }
    });
  };

  const unsetMessages = (e) => {
    e.preventDefault();

    if (data_message.items.length > 0 && data_message.ticket !== null) {
      let ids = data_message.items.map((e) => e.id);

      deleteAllMessages(ids, data_message.ticket.id);
    }
  };
  return (
    <Card className="card_box borderR10 mt-4">
      <div className="fullWidthHeight borderR10">
        <Row className="alignItem chat_header_row">
          <Col xl={6} lg={6} md={6} sm={6} xs={6} className="flex alignItem">
            {data_message.ticket ? (
              <React.Fragment>
                <img
                  src={
                    data_message.ticket.user
                      ? renderImage(data_message.ticket.user.image)
                      : avater2
                  }
                  className={
                    getLanguage() === "en"
                      ? "img_post ml-3 mr-1"
                      : "img_post mr-3 ml-1"
                  }
                  alt=""
                />

                <div
                  className="inline_tag"
                  style={{ marginInlineStart: "15px", textAlign: "right" }}
                >
                  <span className="blue_color post_name text-right">
                    {data_message.ticket.user
                      ? data_message.ticket.user.fullName
                      : ""}
                  </span>
                  <p className="nickname_post">{data_message.ticket.title}</p>
                </div>
              </React.Fragment>
            ) : null}
          </Col>

          <Col xl={6} lg={6} md={6} sm={6} xs={6} className="mb-4 mt-4">
            <p style={rightStyle} className="flex alignItem">
              <div className="chat_file_btn">
                <img src={Icon_attach} alt="" className="imgFull" />
              </div>

              {/* {getPermissions(true, true).TicketMessage_Delete !== undefined ||
              getPermissions(true, true).Administrator !== undefined ? ( */}

              {data_message.items ? (
                <ChatMenu unsetMessages={unsetMessages} />
              ) : null}
              {/* // ) : null} */}
            </p>
          </Col>
        </Row>
        <hr className="chat_box_horizental_line" />
        <Row className="alignItem">
          <Col>
            <Card className="chat_body_card" id="scrollableDiv">
              {data_message.items === null ? (
                <div className="fullWidthHeight center">
                  <p className="weight"> {local.NoMessageAvaliable}</p>
                </div>
              ) : data_message.items.length === 0 ? (
                <div className="fullWidthHeight center">
                  <p className="weight"> {local.NoMessageAvaliable}</p>
                </div>
              ) : (
                <InfiniteScroll
                  scrollableTarget="scrollableDiv"
                  dataLength={data_message.items.length}
                  style={{ display: "flex", flexDirection: "column-reverse" }}
                  hasMore={
                    data_message.pagination.currentPage ===
                    data_message.pagination.totalPages
                      ? false
                      : true
                  }
                  next={() =>
                    fetchTicketMessages(
                      data_message.filterArray,
                      data_message.pagination.currentPage + 1,
                      data_message.ticket.id,
                      "merge"
                    )
                  }
                  loader={<h4>{local.Loading}...</h4>}
                  inverse={true}
                  endMessage={null}
                >
                  {renderMessages()}
                </InfiniteScroll>
              )}
            </Card>
          </Col>
        </Row>
        <hr className="hor_line inline_margin_chat" />
        {/* 
        {getPermissions(true, true).TicketMessage_Create !== undefined ||
        getPermissions(true, true).Administrator !== undefined ? ( */}
        <CreateTicketMessage
          general={general}
          form={data_message.form}
          message_inputs={data_message.message_inputs}
          ticket={data_message.ticket}
          isLoading={data_message.isLoadingCreate}
          addTicketMessage={addTicketMessage}
        />

        {data_message.form.Attachments.length > 0 ? (
          <Row className="mb-2">
            <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>

            <Col
              className="flex alignItem"
              style={{ wordBreak: "break-all", flexWrap: "wrap" }}
            >
              {renderGraphics(data_message.form.Attachments)}
            </Col>
          </Row>
        ) : null}
      </div>
    </Card>
  );
};

export default TicketBox;
