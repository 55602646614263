import React, { Component } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import mapy from "../../../assets/img/auth/mapy.png";
import altawasol from "../../../assets/img/auth/altawasol.png";
import { local } from "../../../lang/local";
import ConfirmEmailForm from "./ConfirmEmailForm";
import { connect } from "react-redux";
import * as actions from "../../../Redux/Actions/index";
import {
  CLEAR_AUTH_STATE,
  UPDATE_AUTH_PROPS,
} from "../../../Redux/Actions/types";
import { MAP_USER } from "../../../General/StaticKeys";
import CountUp from "react-countup";

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      duration: 100,
      start: 10,
      end: 0,
    };
  }
  onResend = (e) => {
    const { resendEmailCode, confirmEmail } = this.props;
    e.preventDefault();
    resendEmailCode(confirmEmail);
    this.setState({ duration: 100, start: 10, end: 0 });
  };
  check_is_authorize = () => {
    const { history, general } = this.props;
    let item = JSON.parse(localStorage.getItem(MAP_USER));

    if (item) {
      if (item.data !== undefined) {
        if (item.data.emailConfirmed === true) {
          history.push("/login");
        } else {
          general(
            [
              {
                prop: "confirmEmail",
                value: item.data.email,
              },
              {
                prop: "confirmEmailForm.userId",
                value: item.data.id,
              },
            ],
            UPDATE_AUTH_PROPS
          );
        }
      } else {
        if (item.emailConfirmed === true) {
          history.push("/login");
        } else {
          general(
            [
              {
                prop: "confirmEmail",
                value: item.email,
              },
              {
                prop: "confirmEmailForm.userId",
                value: item.id,
              },
            ],
            UPDATE_AUTH_PROPS
          );
        }
      }
    } else {
      history.push("/login");
    }
  };
  componentDidMount() {
    this.props.general([], CLEAR_AUTH_STATE);
    this.check_is_authorize();
  }
  render() {
    const {
      general,
      form,
      isLoading,
      history,
      isLoadingResendEmail,
      confirmByEmail,
    } = this.props;
    return (
      <div className="login_wrapper">
        <Container>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
              <img src={mapy} alt="mapy" />
            </Col>
          </Row>

          <Row>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="center mt-3"
            >
              <p className="mobile_signup_text">{local.EmailCode}</p>
            </Col>
          </Row>
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12} className="mx-auto">
              <ConfirmEmailForm
                form={form}
                isLoading={isLoading}
                general={general}
                history={history}
                confirmByEmail={confirmByEmail}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="center mt-5"
            >
              <div className="center resend_container">
                <Button
                  disabled={isLoadingResendEmail === true ? false : true}
                  className="resend_btn"
                  onClick={this.onResend}
                >
                  {local.ResendCode}
                </Button>
                <CountUp
                  start={this.state.start}
                  end={this.state.end}
                  redraw={isLoadingResendEmail}
                  duration={this.state.duration}
                  onEnd={() => {
                    general(
                      [{ prop: "isLoadingResendEmail", value: true }],
                      UPDATE_AUTH_PROPS
                    );
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="center mt-5"
            >
              <img src={altawasol} alt="mapy" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ AuthR }) => {
  return {
    form: AuthR.confirmEmailForm,
    isLoading: AuthR.isLoadingConfirmEmail,
    confirmEmail: AuthR.confirmEmail,
    isLoadingResendEmail: AuthR.isLoadingResendEmail,
  };
};

export default connect(mapStateToProps, actions)(ConfirmEmail);
