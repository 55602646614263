import React, { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { ValidationContext } from '../form';
import strik from "../../assets/img/strik.png";

import './style.css'
import { Input } from 'reactstrap';
export const FormInput = ({ label, data, isGray, type, name, ...props }) => {
    const { register, formState: { errors }, setError, watch } = useFormContext();
    const watchShowAge = watch("select", false); // you can supply default value as second argument
    useEffect(() => {
    }, [watchShowAge])
    return (
        <>
            <div className='container-form-input'>
                <div className="container_add_input mx-auto">

                    <img src={strik} alt="strik" className="strik_icon" />
                    <input
                        className="add_input form-control"
                        placeholder={label}
                        {...register(name, { required: "ادخل اسم المجموعه" },)}
                    />
                </div>
                <span className="error-message">{errors[name]?.message}</span>
            </div>
            {/* </div>
        <div className="FormInput-container">
            <div className={isGray ? "FormInput-label FormInput-label-gray" : "FormInput-label"}>
                {label}
            </div>
            <input type={type ? type : 'text'}
                {...register(name)}
                className="FormInput-data"
                />
        </div> */}
        </>
    )
}