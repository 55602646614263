import React from "react";
import { rightStyle } from "../../../General/GeneralStyle";
import { getPermissions } from "../../../utils/Permissions";
import { local } from "../../../lang/local";
import "./DashboardUsersAnalysisCard.css";
const DashboardUsersAnalysisCard = ({ items, history }) => {
  return (
    <div
      className="dashboardUsersAnalysisCard-container"
      data-aos="fade-up"
      data-aos-delay="20"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <div className="dashboardUsersAnalysisCard-title" style={rightStyle}>
        {local.DashboardUsers}
      </div>

      <div className="circleContainer">
        {getPermissions(true, true).UserBusiness_GetUsers !== undefined ||
        getPermissions(true, true).Administrator !== undefined ? (
          <div
            className="circle cursor"
            id="firstCircle"
            onClick={(e) => {
              e.preventDefault();
              history.push(items[0].routeName);
            }}
            data-aos="flip-down"
            data-aos-delay="20"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div className="firstCircle_title_text cursor">
              {items[0].label}
            </div>
            <div style={{ fontWeight: "bold" }}>{items[0].value}</div>
          </div>
        ) : null}
        {/* 
        {getPermissions(true, true).Venues_Get !== undefined ||
        getPermissions(true, true).Administrator !== undefined ? ( */}
        <div
          className="circle cursor"
          id="secondCircle"
          onClick={(e) => {
            e.preventDefault();
            history.push(items[1].routeName);
          }}
          data-aos="flip-down"
          data-aos-delay="20"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div className="secondCircle_title_text"> {items[1].label}</div>
          <div style={{ fontWeight: "bold" }}>{items[1].value}</div>
        </div>
        {/* ) : null} */}

        {/* {getPermissions(true, true).Ticket_Get !== undefined ||
        getPermissions(true, true).Administrator !== undefined ? ( */}
        <div
          className="circle cursor"
          id="thirdCircle"
          onClick={(e) => {
            e.preventDefault();
            history.push(items[2].routeName);
          }}
          data-aos="flip-up"
          data-aos-delay="20"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div className="thirdCircle_title_text"> {items[2].label}</div>
          <div style={{ fontWeight: "bold" }}>{items[2].value}</div>
        </div>
        {/* ) : null} */}
      </div>
    </div>
  );
};

export default DashboardUsersAnalysisCard;
