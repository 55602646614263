import React from "react";
import { Spinner, Row, Col, Button, Card } from "reactstrap";
import { columnAlignLeft, selectSearchStyle } from "../../General/GeneralStyle";
import { local } from "../../lang/local";
import { UPDATE_REVIEWS_PROPS } from "../../Redux/Actions/types";
import {
  removeUnsetValuesFromFilters,
  showConfirmMessage
} from "../../General/GeneralFunctions";
import { getUser } from '../../utils/StorageFunctions';
import { AsyncPaginate } from "react-select-async-paginate";
import Select from "react-select";
import { getDefaultValue } from "../../General/SelectFunctions";

const ReviewSearch = ({
  loadUserOptions,
  filterArray,
  general,
  isLoading,
  fetchReviews,
  selectedUsers
}) => {
  const ratingOptions = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 }
  ];
  const handleSearch = e => {
    e.preventDefault();
    if (
      filterArray[0].value.length > 0 &&
      filterArray[0].value.find(v => v !== getUser().data.id)
    ) {
      let newFilterArr = [...filterArray];

      newFilterArr = removeUnsetValuesFromFilters(newFilterArr);
      fetchReviews(newFilterArr, 1);
    } else {
      showConfirmMessage(local.AnyDataRequired, "error");
    }
  };

  const handleSelectUser = e => {
    if (e) {
      let entry = [];
      entry = e.map(v => v.value);
      general(
        [
          {
            prop: "data.selectedUsers",
            value: e
          },
          {
            prop: "data.filterArray.0.value",
            value: entry
          }
        ],
        UPDATE_REVIEWS_PROPS
      );
    } else {
      general(
        [
          {
            prop: "data.selectedUsers",
            value: []
          },
          {
            prop: "data.filterArray." + 0 + ".value",
            value: []
          }
        ],
        UPDATE_REVIEWS_PROPS
      );
    }
  };

  return (
    <Card className="search_card">
      <Row className="mt-2 mb-3">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Row className="alignItem">
            <Col xl={4} lg={4} md={4} sm={12} xs={12} className="mt-4">
              <div className="search_select_container">
                <AsyncPaginate
                  key={selectedUsers}
                  placeholder={local.User}
                  value={selectedUsers}
                  loadOptions={loadUserOptions}
                  onChange={e => handleSelectUser(e)}
                  additional={{
                    page: 1
                  }}
                  styles={selectSearchStyle}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={true}
                />
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={12} xs={12} className="mt-4">
              <div className="search_select_container">
                <Select
                  key={filterArray[1].value}
                  placeholder={local.Rating}
                  options={ratingOptions}
                  value={getDefaultValue(filterArray[1].value, ratingOptions)}
                  onChange={e =>
                    e
                      ? general(
                          [
                            {
                              prop: "data.filterArray." + 1 + ".value",
                              value: e.value
                            }
                          ],
                          UPDATE_REVIEWS_PROPS
                        )
                      : general(
                          [
                            {
                              prop: "data.filterArray." + 1 + ".value",
                              value: null
                            }
                          ],
                          UPDATE_REVIEWS_PROPS
                        )
                  }
                  styles={selectSearchStyle}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                />
              </div>
            </Col>

            <Col
              xl={3}
              lg={3}
              md={3}
              sm={12}
              xs={12}
              className={columnAlignLeft() + " mt-4"}
            >
              <Button
                disabled={isLoading}
                className="search_btn"
                onClick={handleSearch}
              >
                {isLoading === true ? <Spinner color="#fff" /> : local.Search}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ReviewSearch;
