import React, { useState } from "react";
import { Row, Col, Card } from "reactstrap";
import { local } from "../../lang/local";
import {
  renderAddButton,
  renderCheckoBox,
  renderMultipleDeleteButton,
  renderTableDropDown,
} from "../../General/TableFunctions";
import { handleArrawCardClick } from "../../General/GeneralFunctions";
import { getClaimTitle } from "../RolesClaims/RolesClaimsFunctions";
const WebList = ({
  history,
  data,
  deleteUserClaim,
  general,
  deleteMultipleUsersClaims,
  rows_collabse,
}) => {
  const [checked, setChecked] = useState([]);

  const deleteItem = (e, item, index) => {
    e.preventDefault();
    deleteUserClaim(item.id, item.userId, index);
  };
  const renderTableBody = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return data.items.map((item, index) => {
          return (
            <React.Fragment>
              <Card
                className="header_card"
                style={{
                  width: rows_collabse[index] === true ? "100%" : "300px",
                  zIndex: 10,
                }}
                key={index}
              >
                {handleArrawCardClick(
                  rows_collabse,
                  index,
                  general,
                  null,
                  null,
                  null
                )}
                <div className="flex aligmItem fullWidthHeight">
                  <ul class="parent-container">
                    <li class="child-item center">
                      {renderCheckoBox(
                        item.id,
                        checked,
                        setChecked,
                        "single",
                        "RoleBusiness_DetachUserClaim"
                      )}
                    </li>
                    <li class="child-item center">{item.id}</li>
                    {/* <li class="child-item center">
                      <a
                        href=""
                        className="blueLight"
                        onClick={(e) => {
                          e.preventDefault();
                          general(
                            [
                              {
                                prop: "profileModal",
                                value: { isOpen: true, data: item },
                              },
                            ],
                            UPDATE_GENERAL_PROPS
                          );
                        }}
                      >
                        {item.claimValue}
                      </a>
                    </li> */}

                    {rows_collabse[index] === true ? (
                      <>
                        <li class="child-item center">{item.description}</li>
                        <li class="child-item center">
                          {getClaimTitle(item.claimType)}
                        </li>

                        <li class="child-item center">
                          {renderTableDropDown(
                            item,
                            index,
                            deleteItem,
                            null,
                            "RoleBusiness_DetachUserClaim",
                            null
                          )}
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              </Card>
              <hr
                className="border_list_hr"
                style={{
                  width: rows_collabse[index] === true ? "100%" : "300px",
                }}
              />
            </React.Fragment>
          );
        });
      }
    }
  };
  return (
    <React.Fragment>
      <Card
        className="header_card cursor"
        style={{
          width:
            rows_collabse.length > 0
              ? rows_collabse.find((col) => col === true)
                ? "100%"
                : "300px"
              : "300px",
          height: "100px",
          zIndex: 10,
        }}
      >
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderAddButton(
              local.AddUserClaim,
              history,
              "/dashboard/attach_user_claims",
              "RoleBusiness_AttachUserClaim"
            )}

            {renderMultipleDeleteButton(
              checked,
              setChecked,
              deleteMultipleUsersClaims,
              data.isLoadingDelete,
              "RoleBusiness_DetachUserClaim",
              data.userId
            )}
          </Col>
        </Row>
        <div className="flex aligmItem fullWidthHeight">
          <ul class="parent-container">
            <li class="child-item center ">
              {data.items
                ? data.items.length > 0
                  ? renderCheckoBox(
                      data.items.map((i) => i.id),
                      checked,
                      setChecked,
                      "all",
                      "RoleBusiness_DetachUserClaim"
                    )
                  : null
                : null}
            </li>
            <li class="child-item center bold">{local.Id}</li>
            {/* <li class="child-item center bold"> {local.Name}</li> */}

            {rows_collabse.length > 0 ? (
              rows_collabse.find((col) => col === true) ? (
                <>
                  <li class="child-item center bold"> {local.Description}</li>

                  <li class="child-item center bold">{local.claimType}</li>
                  <li class="child-item center bold"></li>
                </>
              ) : null
            ) : null}
          </ul>
        </div>
      </Card>
      <hr className="border_list_hr" />
      {renderTableBody()}
    </React.Fragment>
  );
};

export default WebList;
