import {
  UPDATE_VENUES_PROPS,
  MERGE_VENUES_PROPS,
  DELETE_VENUES_PROPS,
  CLEAR_VENUES_STATE,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    items: null,
    pagination: null,
    filterArray: [
      {
        op: 3,
        fieldName: "",
        value: "",
      },
      {
        op: 3,
        fieldName: "Phone",
        value: "",
      },

      {
        op: 0,
        fieldName: "UserId",
        value: null,
      },
    ],
    isLoadingDelete: null,
    form: {
      Name: { en: "", ar: "" },
      Logo: null,
      Phone: "",
    },

    errorMessages: { NameEn: "", NameAr: "", Logo: "" },
    item_index: null,
    isLoadingCreate: false,
    isLoading: false,
    isLoadingSearch: false,
  },
  venuesOptions: [],
};

const VenuesR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_VENUES_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_VENUES_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_VENUES_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_VENUES_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default VenuesR;
