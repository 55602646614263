import {
  showConfirmMessage,
  showSuccessedConfirm,
} from '../../General/GeneralFunctions';
import { getUser } from '../../utils/StorageFunctions';
import { local } from '../../lang/local';
import { UPDATE_CONFIRM_SMS_PROPS } from './types';
import { Request, SendRequest } from './api';
import { MAP_USER } from '../../General/StaticKeys';
import { UPDATE_AUTH_PROPS } from './types';

export const confirmByPhone = (formValues, history) => (dispatch) => {
  dispatch({
    type: UPDATE_CONFIRM_SMS_PROPS,
    payload: [{ prop: 'confirm_Sms_Obj.isLoading', value: true }],
  });
  Request()
    .post('/Auth/ConfirmSms', formValues)
    .then((res) => {
      if (res.status === 200) {
        let user = getUser();
        user.phoneNumberConfirmed = true;
        localStorage.setItem(MAP_USER, JSON.stringify(user));
        showSuccessedConfirm(local.ConfirmedSuccessfully);

        history.push('/login');
      }
      dispatch({
        type: UPDATE_CONFIRM_SMS_PROPS,
        payload: [{ prop: 'confirm_Sms_Obj.isLoading', value: false }],
      });
    })
    .catch((e) => {
      showConfirmMessage(local.SomethingError, 'error');
      dispatch({
        type: UPDATE_CONFIRM_SMS_PROPS,
        payload: [{ prop: 'confirm_Sms_Obj.isLoading', value: false }],
      });
    })
    .finally();
};

export const resendSmsCode = (phoneNumber) => (dispatch) => {
  Request()
    .post('/Auth/Resend/Sms?phoneNumber=' + phoneNumber)
    .then((res) => {
      if (res.status > 199 && res.status <= 205) {
        showSuccessedConfirm(local.SmsSent);

        dispatch({
          type: UPDATE_CONFIRM_SMS_PROPS,
          payload: [{ prop: 'isLoadingResend', value: false }],
        });
      }
    })
    .catch((e) => {
      showConfirmMessage(Object.values(e)[2].data.data.user, 'error');
    })
    .finally();
};

export const forgetPassword = (form, history) => (dispatch) => {
  dispatch({
    type: UPDATE_AUTH_PROPS,
    payload: [{ prop: 'isLoadingForget', value: true }],
  });

  let query = '';
  if (form.phone) {
    query = `phone=${form.phone}`;
  }

  if (form.email) {
    if (form.phone) {
      query += `&email=${form.email}`;
    } else {
      query = `email=${form.email}`;
    }
  }
  Request()
    .get(`/Auth/ForgotPassword?${query}`)
    .then((res) => {
      if (res.status === 200 && res.data.message === 'sent successfully') {
        let item = { phoneNumber: form.phone, email: form.email };
        localStorage.setItem(MAP_USER, JSON.stringify(item));
        dispatch({
          type: UPDATE_AUTH_PROPS,
          payload: [
            {
              prop: 'resetForm.phone',
              value: form.phone ? form.phone : '',
            },

            {
              prop: 'resetForm.email',
              value: form.email ? form.email : '',
            },
          ],
        });

        showSuccessedConfirm(local.CodeSentSuccessfully);

        history.push('/reset-password');
      }
      dispatch({
        type: UPDATE_AUTH_PROPS,
        payload: [{ prop: 'isLoadingForget', value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_AUTH_PROPS,
        payload: [{ prop: 'isLoadingForget', value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const resetPassword = (form, history) => (dispatch) => {
  dispatch({
    type: UPDATE_AUTH_PROPS,
    payload: [{ prop: 'isLoadingReset', value: true }],
  });

  Request()
    .post('/Auth/ResetPassword', form)
    .then((res) => {
      if (res.status === 200) {
        let item = { phoneNumber: form.phone, email: form.email };
        localStorage.setItem(MAP_USER, JSON.stringify(item));

        showSuccessedConfirm(local.PasswordReset);

        history.push('/login');
      }
      dispatch({
        type: UPDATE_AUTH_PROPS,
        payload: [{ prop: 'isLoadingReset', value: false }],
      });
    })
    .catch((e) => {
      if (Object.values(e)[2].data) {
        if (Object.values(e)[2].data.data) {
          showConfirmMessage(local.ExpiredCode, 'error');
        }
      }
      dispatch({
        type: UPDATE_AUTH_PROPS,
        payload: [{ prop: 'isLoadingReset', value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const confirmByEmail = (formValues, history) => (dispatch) => {
  dispatch({
    type: UPDATE_AUTH_PROPS,
    payload: [{ prop: 'isLoadingConfirmEmail', value: true }],
  });
  Request()
    .post('/Auth/ConfirmEmail', formValues)
    .then((res) => {
      if (res.status === 200) {
        let user = getUser();
        user.data.emailConfirmed = true;
        localStorage.setItem(MAP_USER, JSON.stringify(user));
        showSuccessedConfirm(local.ConfirmedSuccessfully);

        history.push('/login');
      }
      dispatch({
        type: UPDATE_AUTH_PROPS,
        payload: [{ prop: 'isLoadingConfirmEmail', value: false }],
      });
    })
    .catch((e) => {
      showConfirmMessage(local.SomethingError, 'error');
      dispatch({
        type: UPDATE_AUTH_PROPS,
        payload: [{ prop: 'isLoadingConfirmEmail', value: false }],
      });
    })
    .finally();
};

export const resendEmailCode = (email) => (dispatch) => {
  Request()
    .post(`/Auth/Send/Confirm/Email?email=${email}`)
    .then((res) => {
      if (res.status > 199 && res.status <= 205) {
        showSuccessedConfirm(local.SmsSent);
        dispatch({
          type: UPDATE_AUTH_PROPS,
          payload: [{ prop: 'isLoadingResendEmail', value: false }],
        });
      }
    })
    .catch((e) => {
      showConfirmMessage(Object.values(e)[2].data.data.user, 'error');
    })
    .finally();
};

export const updateEmail = (newemail, history) => (dispatch) => {
  dispatch({
    type: UPDATE_AUTH_PROPS,
    payload: [{ prop: 'isLoadingUpdateEmail', value: true }],
  });

  SendRequest()
    .post(`/Auth/change/Email?newemail=${newemail}`)
    .then((res) => {
      if (res.status === 200) {
        history.push('/confirm_email');
      }

      dispatch({
        type: UPDATE_AUTH_PROPS,
        payload: [
          {
            prop: 'updateEmailForm',
            value: {
              newemail: '',
            },
          },
        ],
      });
      dispatch({
        type: UPDATE_AUTH_PROPS,
        payload: [{ prop: 'isLoadingUpdateEmail', value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_AUTH_PROPS,
        payload: [{ prop: 'isLoadingUpdateEmail', value: false }],
      });
      console.log(e);
    })
    .finally();
};

// export const vaerifiyByEmail = (body, history) => (dispatch) => {
//   dispatch({
//     type: UPDATE_LOGIN_PROPS,
//     payload: [{ prop: "verificationObj.isLoading", value: true }],
//   });
//   Request()
//     .post("Auth/ConfirmEmail", body)
//     .then((res) => {
//       if (res.status === 200) {
//         history.push("/enter_phone_number");
//       }
//       dispatch({
//         type: UPDATE_LOGIN_PROPS,
//         payload: [{ prop: "verificationObj.isLoading", value: false }],
//       });
//     })
//     .catch((e) => {
//       dispatch({
//         type: UPDATE_LOGIN_PROPS,
//         payload: [{ prop: "verificationObj.isLoading", value: false }],
//       });
//       try {
//         let message = JSON.parse(Object.values(e)[1].responseText).errors.email;
//         showConfirmMessage(message, "error");
//         history.push("/enter_phone_number");
//       } catch (err) {
//         showConfirmMessage(local.SomethingError, "error");
//         history.push("/enter_phone_number");
//       }
//     })
//     .finally();
// };
