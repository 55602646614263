import React from "react";
import { Card, Table, Row, Col } from "reactstrap";
import avater2 from "../../assets/img/avater2.svg";
import "aos/dist/aos.css";
import PaginationItem from "../../General/PaginationItem";
import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
import { getLanguage, local } from "../../lang/local";
import { getData, renderComplaintType } from "./ComplaintFunction";
import {
  convert_to_lat_lng_point,
  renderMultipleImages,
  renderSingleImage,
} from "../../General/GeneralFunctions";
import Share from "../../General/Share";
import situation from "../../assets/img/situation.png";
import { extractLatLng } from "../../General/MapFunctions";
import link from "../../assets/img/link.png";

const TagItem = () => {
  return <img src={link} alt="" />;
};
const MobileList = ({
  data,
  general,
  active_cards,
  fetchComplaints,
  ComplaintsOptions,
  getComplaintType,
}) => {
  const handleNameClick = (e, item) => {
    e.preventDefault();

    if (ComplaintsOptions) {
      let entry = ComplaintsOptions.items?.find(
        (comp) => comp.id === data.geoEventsTypeId
      );
      if (entry === undefined) {
        getComplaintType(item.geoEventsTypeId);
      }
    }
    general(
      [
        {
          prop: "profileModal",
          value: { isOpen: true, data: item },
        },
      ],
      UPDATE_GENERAL_PROPS
    );
  };
  const renderTableBody = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return getData(data, active_cards).map((item, index) => {
          return (
            <tr key={index} className="text-center">
              <td>
                <span className="tbody_container center">
                  {renderMultipleImages(item.images, general, true)}
                </span>
              </td>
              <td>
                <a
                  href=""
                  className="blueLight"
                  onClick={(e) => handleNameClick(e, item)}
                >
                  <span className="tbody_container2 center">
                    <div className="imgFull center">
                      <p className="">{item.note}</p>
                    </div>
                  </span>
                </a>
              </td>
              <td> {renderComplaintType(item.id, ComplaintsOptions)}</td>

              <td>
                <Share
                  TagItem={TagItem}
                  point={extractLatLng(item.locationText)}
                  type="complaint"
                  type_id={item.id}
                />
              </td>
              <td>
                <a
                  target="_blank"
                  href={`${window.location.origin}/complaint=${
                    item.id
                  }&${convert_to_lat_lng_point(
                    item.locationText,
                    "lat"
                  )},${convert_to_lat_lng_point(item.locationText, "lng")},15z`}
                >
                  <img src={situation} alt="" className="cursor" />
                </a>
              </td>

              <td>
                <span className="tbody_container2 center">
                  <div className="imgFull center">
                    <p className="td_table_text">
                      {getLanguage() === "en"
                        ? new Date(item.createdAt).toLocaleString("en")
                        : new Date(item.createdAt).toLocaleString("ar")}
                    </p>
                  </div>
                </span>
              </td>
              <td>
                <span className="tbody_container2 center">
                  <div className="imgFull flex alignItem">
                    {item.user ? (
                      renderSingleImage(item.user.image, general)
                    ) : (
                      <img src={avater2} className="card_img_chat" />
                    )}
                    <p className="td_table_text">
                      {item.user !== null ? item.user.fullName : ""}
                    </p>
                  </div>
                </span>
              </td>
            </tr>
          );
        });
      }
    }
  };
  return (
    <Card className="table_card">
      <div className="mt-4">
        <Table responsive hover className="mt-4">
          <thead>
            <tr className="text-center">
              <th>{local.Images}</th>
              <th>{local.UserNote}</th>
              <th>{local.ComplaintType}</th>
              <th>{local.ShareLink}</th>
              <th>{local.Location}</th>
              <th>{local.Time}</th>
              <th>{local.User}</th>
            </tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </Table>
        <Row>
          <Col
            className="center mt-3 mb-3"
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            {data.pagination ? (
              <PaginationItem
                pagination={data.pagination}
                general={general}
                fetchData={fetchComplaints}
                filterArray={data.filterArray}
              />
            ) : null}
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default MobileList;
