import { Input,Spinner } from 'reactstrap';
import './webViewSearch.css';
import web_view_search_icon from '../../assets/img/web_view_search_icon.svg';
import React from 'react';
import { local } from '../../lang/local';
export const WebViewSearch = ({
  isSlideDown,
  onClick,
  onChange,
  isLoading,value
}) => {
  return (
    <div className={isSlideDown ? 'search_container mt-3' : 'search_container'}>
      <img
        src={web_view_search_icon}
        alt=''
        className='search_icon cursor'
        onClick={onClick}
      />
      <Input
        className='removed_input_style'
        placeholder={local.Search}
        value={value}
        onKeyDown={(e) => (e.key === 'Enter' ? onClick(e) : null)}
        onChange={(e) => onChange(e.target.value)}
      />

      {isLoading ? (
        <div>
          <Spinner className='search_loader' />
        </div>
      ) : null}
    </div>
  );
};
