import {
  UPDATE_SEARCH_MENU_PROPS,
  CLEAR_SEARCH_MENU_STATE,
  MERGE_SEARCH_MENU_PROPS,
  DELETE_SEARCH_MENU_PROPS,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  search_data: {
    type: "auto_complete",
    value: "",
    isSlideDown: false,
    toViewSearch: true,
    isLoading: false,
    defaultZoom: 11,
    map_lat: 26.8448206,
    map_lng: 35.3699203,
    polygon: [],
  },
  searchResult: [],
};

const SearchMenuR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_MENU_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_SEARCH_MENU_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_SEARCH_MENU_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_SEARCH_MENU_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default SearchMenuR;
