import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Col, Input, Row, Button } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  TwitterShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  PinterestShareButton,
} from "react-share";
import {
  FaFacebookF,
  FaFacebookMessenger,
  FaLinkedin,
  FaPinterest,
  FaTelegram,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { local } from "../lang/local";
import { showSuccessedConfirm } from "./GeneralFunctions";
const useStyles = makeStyles((theme) => ({
  typography: {
    width: "400px",
  },
}));

export default function Share({ TagItem, point, bulk, type, type_id }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const renderUrl = () => {
    if (bulk === undefined) {
      if (point === undefined) {
        return window.location.href;
      } else {
        return (
          window.location.origin +
          `/${type}=${type_id}&` +
          point.lat +
          "," +
          point.lng +
          "," +
          "15z"
        );
      }
    } else {
      return bulk;
    }
  };

  return (
    <React.Fragment>
      <a className="cursor" onClick={handleClick}>
        <TagItem />
      </a>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>
          <div
            style={{
              direction: "ltr",
              padding: "15px",
            }}
            className="fullWidthHeight"
          >
            <Row className="alignItem mt-4">
              <Col xl={9} lg={9} md={9} sm={12} xs={12} className="mt-3">
                <div className="div_location">
                  <Input className="removed_input_style" value={renderUrl()} />
                </div>
              </Col>
              <Col
                className="padding0_margin0 mt-3"
                xl={3}
                lg={3}
                md={3}
                sm={12}
                xs={12}
              >
                <CopyToClipboard text={renderUrl()}>
                  <Button
                    style={{ width: "100%" }}
                    className="btn_save cursor"
                    onClick={(e) => {
                      e.preventDefault();

                      showSuccessedConfirm(local.DoneCopy);
                    }}
                  >
                    {local.CopyLink}
                  </Button>
                </CopyToClipboard>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="center"
                style={{ justifyContent: "space-evenly" }}
              >
                <FacebookShareButton
                  url={window.location.href}
                  quote={window.location.href}
                  className="share_icon"
                >
                  <FaFacebookF size={22} color="#4267B2" />
                </FacebookShareButton>
                <FacebookMessengerShareButton
                  url={window.location.href}
                  quote={window.location.href}
                  className="share_icon"
                >
                  <FaFacebookMessenger
                    size={22}
                    color="#00C6FF"
                    className="share_icon"
                  />
                </FacebookMessengerShareButton>
                <TwitterShareButton
                  url={window.location.href}
                  quote={window.location.href}
                  className="share_icon"
                >
                  <FaTwitter size={22} color="#00acee" />
                </TwitterShareButton>

                <WhatsappShareButton
                  url={window.location.href}
                  quote={window.location.href}
                  className="share_icon"
                >
                  <FaWhatsapp size={22} color="#25D366" />
                </WhatsappShareButton>

                <TelegramShareButton
                  url={window.location.href}
                  quote={window.location.href}
                  className="share_icon"
                >
                  <FaTelegram size={22} color="#0088cc" />
                </TelegramShareButton>

                <LinkedinShareButton
                  url={window.location.href}
                  quote={window.location.href}
                  className="share_icon"
                >
                  <FaLinkedin size={22} color="#0077b5" />
                </LinkedinShareButton>

                <PinterestShareButton
                  url={window.location.href}
                  quote={window.location.href}
                  className="share_icon"
                >
                  <FaPinterest size={22} color="#E60023" />
                </PinterestShareButton>
              </Col>
            </Row>
          </div>
        </Typography>
      </Popover>
    </React.Fragment>
  );
}
