import React, { useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import mapy from '../../assets/img/auth/mapy.png';
import altawasol from '../../assets/img/auth/altawasol.png';
// import {
//   renderFacebookButton,
//   renderGooglekButton,
// } from "../../General/GeneralFunctions";
import { local } from '../../lang/local';
import RegisterForm from './RegisterForm';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { CLEAR_REGISTER_STATE } from '../../Redux/Actions/types';
// import MenuLanguage from "../../General/MenuLanguage";
const Register = ({
  general,
  registerObj,
  history,
  register,
  getLocationWithIp,
}) => {
  useEffect(() => {
    // this.props.register();
    general([], CLEAR_REGISTER_STATE);
    getLocationWithIp();
  }, []);

  return (
    <div className='login_wrapper'>
      {/* <MenuLanguage /> */}

      <Container>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='center'>
            <center>
              <img src={mapy} alt='mapy' width='200' />
            </center>
          </Col>
        </Row>

        <Row>
          <Col xl={8} lg={8} md={8} sm={12} xs={12} className='mx-auto mt-1'>
            <RegisterForm
              registerObj={registerObj}
              general={general}
              register={register}
              history={history}
            />
          </Col>
        </Row>
        {/* <Row>
                <Col xl={6} lg={6} md={6} sm={12} xs={12} className="mx-auto">
                  <Row>
                    {renderFacebookButton()}
                    {renderGooglekButton()}
                  </Row>
                </Col>
              </Row> */}
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='center mt-4'>
            <p className='mobile_signin_text' style={{ color: '#3B3B3B' }}>
              {local.SIGNFROM + ' '}
              <span
                className='mobile_signin_text cursor bold'
                style={{ color: '#12A9CC' }}
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/login');
                }}
              >
                {local.HERE}
              </span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='center mt-2'>
            <img src={altawasol} alt='mapy' />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const mapStateToProps = ({ RegisterR }) => {
  return { registerObj: RegisterR.registerObj };
};
export default connect(mapStateToProps, actions)(Register);
