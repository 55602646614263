import React from "react";
import { Row, Col, Card } from "reactstrap";
import { local } from "../../../lang/local";
import { alignTextRight, leftStyle } from "../../../General/GeneralStyle";
import { RECENT_SEARCH } from "../../../General/StaticKeys";
import time_circle from "../../../assets/img/time_circle.svg";
import { handlePlaceClick } from "./SearchFunctions";

const RecentSearch = ({
  general,
  myPosition,
  getSocketResult,
  ipAddress,
  searchImages,
}) => {
  const onClear = () => {
    localStorage.removeItem(RECENT_SEARCH);
  };

  const renderRecentSearch = () => {
    let item = JSON.parse(localStorage.getItem(RECENT_SEARCH));
    if (item !== null) {
      return item;
    }
  };

  const renderCardItem = (searchResult) => {
    if (searchResult) {
      if (searchResult.length > 0) {
        return (
          <div>
            <Row>
              {searchResult.map((result, index) => {
                return (
                  <Col
                    className="mt-3"
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    key={index}
                  >
                    <Card
                      className="search_result_card_item center cursor"
                      onClick={(e) =>
                        handlePlaceClick(
                          e,
                          result,
                          general,
                          getSocketResult,
                          myPosition,
                          ipAddress,
                          searchImages
                        )
                      }
                    >
                      {/* <Row className="fullWidthHeight alignItem flex">
                      <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                        <img src={time_circle} alt="time_circle" />
                      </Col>

                      <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                        <div style={alignTextRight}>
                          <p className="place_dir_text">
                            <span style={leftStyle}>{result.label}</span>
                            <br />
                            <div>
                              <span
                                className="place_dir_desc"
                                style={leftStyle}
                              >
                                {result.label.split(",").pop()}
                              </span>
                              <Button
                                style={rightStyle}
                                className="place_btn_card flex"
                              >
                                <img src={add_icon_btn} alt="add_icon_btn" />
                                <p className="add_text_btn">{local.ADDTO}</p>
                              </Button>
                            </div>
                          </p>
                        </div>
                      </Col>
                    </Row> */}
                      <Row className="fullWidthHeight alignItem">
                        <Col
                          xl={2}
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          style={{ marginInlineStart: "5px" }}
                        >
                          <img src={time_circle} alt="time_circle" />
                        </Col>
                        <Col
                          xl={9}
                          lg={9}
                          md={9}
                          sm={9}
                          xs={9}
                          className="flex alignItem"
                        >
                          <div style={alignTextRight}>
                            <span className="place_name">{result.label}</span>
                            <p className="city_name">
                              {result.label.split(",").pop()}
                            </p>
                          </div>
                          {/* <a
                          style={{
                            position: "absolute",
                            left: getLanguage() === "ar" ? "0px" : null,
                            right: getLanguage() === "en" ? "0px" : null,
                            bottom: "0px",
                            color: "#12a9cc",
                          }}
                          className="flex"
                        >
                          <p>{local.ADDTO}</p>
                        </a> */}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      }
    }
  };
  return renderRecentSearch() !== undefined ? (
    <div className="mt-4 search_result_container">
      <Row>
        <Col className="ml-2 mr-2">
          <p className="search_result_title" style={leftStyle}>
            {local.RECENTSEARCH}
          </p>
        </Col>
      </Row>
      {renderCardItem(renderRecentSearch())}

      {renderRecentSearch().length > 0 ? (
        <Row>
          <Col className="mt-3 text-center cursor">
            <p className="search_result_clear_text cursor" onClick={onClear}>
              {local.CLEAR}
            </p>
          </Col>
        </Row>
      ) : null}
    </div>
  ) : null;
};

export default RecentSearch;
