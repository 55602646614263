import { getLanguage } from "../lang/local";

export const filterItems = (options) => {
  try {
    return options.filter((i) => i.label.toLowerCase());
  } catch (e) {
    return [];
  }
};

export const promiseOptions = (options) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterItems(options));
    }, 1000);
  });

export const getDefaultValue = (id, options) => {
  if (id !== null && id !== "" && options.length > 0) {
    let item = options.find((op) => op.value === id || op.label === id);
    if (item !== undefined) {
      return item;
    } else {
      return null;
    }
  }
};
export const getDefaultLayer = (id, options) => {
  if (id !== null && id !== "" && options.length > 0) {
    let item = options.find((op) => op.layer === id);

    if (item !== undefined) {
      return item;
    }
  }
};

export const dayOptions = [
  { value: "Monday", label: getLanguage() === "en" ? "Monday" : "الاثنين" },
  { value: "Tuesday", label: getLanguage() === "en" ? "Tuesday" : "الثلاثاء" },
  {
    value: "Wednesday",
    label: getLanguage() === "en" ? "Wednesday" : "الاربعاء",
  },
  { value: "Thursday", label: getLanguage() === "en" ? "Thursday" : "الخميس" },
  { value: "Friday", label: getLanguage() === "en" ? "Friday" : "الجمعة" },
  { value: "Saturday", label: getLanguage() === "en" ? "Saturday" : "السبت" },
  { value: "Sunday", label: getLanguage() === "en" ? "Sunday" : "الاحد" },
];
