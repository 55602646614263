import React from 'react';
import { Button, Spinner } from 'reactstrap';

export const CustomButton = ({ handleClick, label, isLoader, className }) => {
  return (
    <Button className={className} onClick={handleClick}>
      {isLoader ? <Spinner /> : label}
    </Button>
  );
};
