import {
  UPDATE_CONFIRM_SMS_PROPS,
  CLEAR_CONFIRM_SMS_STATE,
  MERGE_CONFIRM_SMS_PROPS,
  DELETE_CONFIRM_SMS_PROPS,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  confirm_Sms_Obj: {
    form: {
      code: "",
      phoneNumber: "",
    },
    isLoading: false,
  },
  isLoadingResend: null,
};

const ConfirmSmsR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CONFIRM_SMS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_CONFIRM_SMS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_CONFIRM_SMS_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_CONFIRM_SMS_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};

export default ConfirmSmsR;
