import React, { useEffect, useRef } from 'react';
import { Mapy } from 'mapy_sdk/lib';
import { showConfirmMessage } from './GeneralFunctions';
import { centerMap, renderCustomIcon } from './MapFunctions';
import { local } from '../lang/local';
import { iconPostion } from 'mapy_sdk/lib/mapyTypes';
import marker_icon from '../assets/img/marker.png';
import branch from '../assets/img/branch.svg';
import distributor from '../assets/img/distributor.svg';
import { polygon as convertToPolygon } from '@turf/helpers';
import booleanWithin from '@turf/boolean-within';
let map = new Mapy();
let storedPolygons = [];
let markers = [];
let polygonIds = 1;

const TooMap = (props) => {
  const {
    location,
    general,
    markers_list,
    zoomToShape,
    shapeItem,
    polygons_list,
    point,
    IsDrawing,
    onMapClick,
    action,
    action_parameter,
    place_point,
    polygon_prop,
    mapSearch,
    nearest_point,
    companyIcon,
  } = props;

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevProps = usePrevious({
    markers_list,
    zoomToShape,
    shapeItem,
    point,
    place_point,
    mapSearch,
    nearest_point,
    polygons_list,
  });

  useEffect(() => {
    markers = [];
    storedPolygons = [];
    polygonIds = 1;
    loadMap();
  }, []);

  useEffect(() => {
    return () => {
      map.popMap();
    };
  }, [props.current]);

  useEffect(() => {
    if (prevProps !== undefined) {
      if (
        JSON.stringify(prevProps.polygons_list) !==
          JSON.stringify(polygons_list) &&
        polygons_list
      ) {
        renderPolygons();
      }
    }
  }, [polygons_list]);

  useEffect(() => {
    if (prevProps !== undefined) {
      if (prevProps.shapeItem !== shapeItem && shapeItem) {
        if (shapeItem.point.lat !== 0 && shapeItem.point.lng !== 0) {
          let id = markers[shapeItem.index];

          drawMarker(id, shapeItem.point, [28, 43], true, shapeItem.title);
          map.setCenter(shapeItem.point);

          setTimeout(() => {
            map.setZoom(18);
          }, 1000);
        }
      }
      // if (prevProps.zoomToShape.length !== zoomToShape.length) {
      //   if (zoomToShape.length > 0) {
      //     // updateMarkers();
      //   }
      // }
    }
  }, [zoomToShape, shapeItem]);
  useEffect(() => {
    if (prevProps !== undefined && point !== undefined) {
      if (JSON.stringify(prevProps.point) !== JSON.stringify(point) && point) {
        if (point.lat !== 0 && point.lng !== 0) {
          drawMarker('location', point, [28, 43], true);
          // map.setCenter(point);
          // map.setZoom(15);
        }
      }
      if (JSON.stringify(prevProps.point) !== JSON.stringify(point) && !point) {
        removeMarker(map, 'location');
      }
    }
  }, [point]);
  useEffect(() => {
    if (prevProps !== undefined && place_point !== undefined) {
      if (prevProps.place_point !== place_point && place_point) {
        drawMarker('location', place_point, [28, 43], true);
        map.setCenter(place_point);
        map.setZoom(15);
      } else {
        removeMarker(map, 'location');
      }
    }
  }, [place_point]);
  useEffect(() => {
    if (prevProps !== undefined) {
      if (
        JSON.stringify(prevProps.markers_list) !==
          JSON.stringify(markers_list) &&
        markers_list.length > 0
      ) {
        removeMarkers();

        renderMarkers();
      }
    }
  }, [markers_list]);

  useEffect(() => {
    if (prevProps !== undefined && polygon_prop !== undefined) {
      if (
        prevProps.polygon_prop !== polygon_prop &&
        polygon_prop.length === 0
      ) {
        removePolygons();
      }
    }
  }, [polygon_prop]);
  const removeMarker = (map, id) => {
    try {
      map.deleteMarker(id);
    } catch (e) {
      console.log(e);
    }
  };

  const removePolygons = () => {
    if (storedPolygons.length > 0) {
      try {
        storedPolygons.map((id) => {
          map.deletePolygon(id);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  // function drawRectangle() {
  //   rectangle = new window.google.maps.Rectangle({
  //     strokeColor: "#FF0000",
  //     strokeOpacity: 0.8,
  //     strokeWeight: 1,
  //     fillColor: "#FF0000",
  //     fillOpacity: 0.3,
  //     map,
  //     bounds: mapSearch.bounds,
  //   });
  // }

  // useEffect(() => {
  //   if (prevProps !== undefined && mapSearch !== undefined) {
  //     if (
  //       prevProps.mapSearch.lat !== mapSearch.lat &&
  //       mapSearch.lat &&
  //       mapSearch.bounds
  //     ) {
  //       if (mapSearch.lat !== 0 && mapSearch.lng !== 0) {
  //         console.log(mapSearch.bounds);

  //         map.setCenter({ lat: mapSearch.lat, lng: mapSearch.lng });
  //         map.setZoom(getZoom(mapSearch.layer));
  //         drawRectangle();
  //       }
  //     }
  //     if (
  //       prevProps.mapSearch.bounds !== mapSearch.bounds &&
  //       !mapSearch.bounds
  //     ) {
  //       rectangle.setBounds(null);
  //     }
  //   }
  // }, [mapSearch]);

  // useEffect(() => {
  //   if (prevProps !== undefined && mapSearch !== nearest_point) {
  //     if (
  //       prevProps.nearest_point.lat !== nearest_point.lat &&
  //       nearest_point.lat
  //     ) {
  //       if (nearest_point.lat !== 0 && nearest_point.lng !== 0) {
  //         map.setCenter({ lat: nearest_point.lat, lng: nearest_point.lng });
  //         map.setZoom(getZoom(nearest_point.layer));
  //         drawActiveMarker(true);
  //       }
  //     }
  //     if (
  //       prevProps.nearest_point.lat !== nearest_point.lat &&
  //       !nearest_point.lat
  //     ) {
  //       drawActiveMarker(false);
  //     }
  //   }
  // }, [nearest_point]);

  const loadMap = () => {
    let getLocationPromise = new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            let lat = position.coords.latitude;
            let long = position.coords.longitude;
            resolve({ latitude: lat, longitude: long });
          },
          function (error) {
            reject({
              latitude: location.nearest_location.lat,
              longitude: location.nearest_location.lng,
            });
          }
        );
      } else {
        showConfirmMessage(
          "your browser doesn't support geolocation API",
          'error'
        );
        reject("your browser doesn't support geolocation API");
      }
    });

    getLocationPromise
      .then((location) => {
        initializeMap(location.latitude, location.longitude);
      })
      .catch((err) => {
        initializeMap(err.latitude, err.longitude);
      });
  };

  const initializeMap = (lat, lng) => {
    const defaultOptions = {
      //   scrollWheelZoom: true,
      fullscreencontrol: false,
      maxZoom: 18,
      center: {
        lat: lat?lat:26.573613,
        lng: lng?lng:30.068867,
      },
      zoom: 3,
    };
    map.initMap(document.getElementById('map'), defaultOptions);

    map.addOnMapClick((LatLng, properites) => {
      let lat = LatLng.lat;
      let lng = LatLng.lng;
      let zoom = properites.target._zoom;

      if (onMapClick !== undefined && IsDrawing === undefined) {
        if (action === undefined) {
          onMapClick(lat, lng, general);
        } else {
          onMapClick(lat, lng, general, action, action_parameter);
        }

        map.setCenter({ lat, lng });
        // map.setZoom(zoom);
      }
    });
    if (map) {
      if (point !== undefined) {
        if (point) {
          if (point.lat) {
            drawMarker('location', point, [28, 43], true);
            map.setCenter(point);
            map.setZoom(18);
          }
        }
      }
      if (IsDrawing) {
        initDrawingManager();
      } else {
        renderMarkers();
        renderPolygons();
      }
    }
  };

  const drawPolygon = (id, points) => {
    map.AddPolygon(
      {
        // groupName: "smsm",
        boundaries: points,
        color: '#12a9cc',
        fillColor: '#12a9cc',
        fillOpacity: 0.8,
        weight: 2,
        properties: {},
        // popup: `<h5>${poly.title}</h5>`,
        // onClick: (id, properties) => {

        // },
      },
      id,
      false
    );
  };
  const initDrawingManager = () => {
    map.addDrawPlugin(
      function Add(entry) {
        console.log(entry);
        let points = entry.layer._latlngs[0];
        polygonIds += polygonIds;
        drawPolygon('drawing_marker' + polygonIds, points);
        let item = manipulatePolygon(points);
        storedPolygons.push('drawing_marker' + polygonIds);

        onMapClick(item, general);

        map.enableEditToFeatures([{ id: 'drawing_marker', type: 1 }]);
      },
      function Edit(entry) {
        let points = Object.values(entry.layers._layers)[0].editing.latlngs[0];
        let item = manipulatePolygon(points);
        onMapClick(item, general);
      },
      function Delete(entry) {},
      'bottomright'
    );
  };

  const manipulatePolygon = (newPolygon) => {
    let points = newPolygon.map((p) => {
      return [p.lng, p.lat];
    });

    points.push([points[0][0], points[0][1]]);

    if (storedPolygons.length === 0) {
      let polygon = 'POLYGON (';
      polygon += preparePolygon(points, 'single') + ')';
      storedPolygons.push(points);

      return polygon;
    } else {
      let newLine = convertToPolygon([points]);
      if (
        storedPolygons.find(
          (p) =>
            booleanWithin(convertToPolygon([p]), newLine) === true ||
            booleanWithin(newLine, convertToPolygon([p])) === true
        )
      ) {
        showConfirmMessage(local.PolygonInsideError, 'error');
        newPolygon.clear();
      } else {
        storedPolygons.push(points);

        let polygons = storedPolygons.map((poly) => {
          return preparePolygon(poly, 'multi');
        });
        let item = 'MULTIPOLYGON (' + polygons.toString() + ')';
        return item;
      }
    }
  };

  const preparePolygon = (poly, type) => {
    let item = '(';
    if (type === 'multi') {
      item = '((';
    }
    poly.map((point, index) => {
      if (index === poly.length - 1) {
        if (type === 'single') {
          item +=
            point[0] +
            ' ' +
            point[1] +
            ',' +
            poly[0][0] +
            ' ' +
            poly[0][1] +
            ')';
        } else {
          item +=
            point[0] +
            ' ' +
            point[1] +
            ',' +
            poly[0][0] +
            ' ' +
            poly[0][1] +
            '))';
        }
      } else {
        item += point[0] + ' ' + point[1] + ',';
      }
    });

    return item;
  };

  const removeMarkers = () => {
    if (markers.length > 0) {
      markers.map((mark) => {
        return removeMarker(map, mark);
      });
    }
    markers = [];
  };

  const renderMarkers = () => {
    if (markers_list !== undefined) {
      if (markers_list) {
        let points = markers_list.filter((i) => {
          return [+i.point.lat, +i.point.lng];
        });
        centerMap(points, map);

        return markers_list.map((item, index) => {
          let lat = +item.point.lat;
          let lng = +item.point.lng;
          if (lat !== 0 && lng !== 0) {
            points.push([lng, lat]);

            var desc = '';
            let isCustom = false;

            if (item.type === undefined) {
              desc = '';
              isCustom = false;
            } else {
              if (item.type === null) {
                desc = '';
                isCustom = false;
              } else if (item.type === 0) {
                desc = local.Branch;
                isCustom = true;
              } else {
                desc = local.Distributor;
                isCustom = true;
              }
            }
            markers.push('list' + item.id);

            if (isCustom === true) {
              drawCustomMarker(
                'list' + item.id,
                [+item.point.lat, +item.point.lng],
                false,
                desc,
                item.type
              );
            } else {
              drawMarker(
                'list' + item.id,
                [+item.point.lat, +item.point.lng],
                [28, 43],
                false,
                item.name
              );
            }
          }
        });
      }
    }
  };

  const drawCustomMarker = (id, location, focus, title, type) => {
    let image = type === 0 ? branch : distributor;
    return map.AddMarker(
      {
        divIcon: {
          className: 'student-div-icon',
          html: renderCustomIcon(image, companyIcon, title),
          iconSize: [28, 43],
          iconAnchor: [14, 26],
        },
        location,
        title: title,
        popup: `<h5>${title}</h5>`,

        // onClick: (id, properties) => {},

        dragable: false,
      },

      id,
      focus
    );
  };
  const drawMarker = (id, newPoint, imgSize, focus, title) => {
    map.AddMarker(
      {
        imgUrl: marker_icon,
        location: newPoint,
        imgSize: imgSize,
        title: title === undefined ? null : title,
        popup: title === undefined ? null : `<h5>${title}</h5>`,
        iconAnchor: iconPostion.bottom_center,
        dragable: false,
      },

      id,
      focus
    );
  };

  const renderPolygons = () => {
    if (polygons_list !== undefined) {
      if (polygons_list) {
        return polygons_list.map((poly, i) => {
          map.AddPolygon(
            {
              // groupName: "smsm",
              boundaries: poly.polygon,
              color: '#12a9cc',
              fillColor: '#12a9cc',
              fillOpacity: 0.8,
              weight: 2,
              properties: {},
              popup: `<h5>${poly.title}</h5>`,
              // onClick: (id, properties) => {

              // },
            },
            'polygon ' + poly.id,
            false
          );
        });
      }
    }
  };

  // function drawActiveMarker(isDrawing) {
  //   if (isDrawing === true) {
  //     if (!myLocation) {
  //       try {
  //         let content = "<p>" + nearest_point.address + "<br />";

  //         const infowindow = new window.google.maps.InfoWindow({
  //           content,
  //         });
  //         myLocation = new window.google.maps.Marker({
  //           position: { lat: nearest_point.lat, lng: nearest_point.lng },
  //           map,
  //           icon: active_marker,
  //           title: nearest_point.address,
  //         });
  //         myLocation.addListener("click", () => {
  //           infowindow.open({
  //             anchor: myLocation,
  //             map,
  //             shouldFocus: true,
  //           });
  //         });
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     } else {
  //       myLocation.setPosition({
  //         lat: nearest_point.lat,
  //         lng: nearest_point.lng,
  //       });
  //     }
  //   } else {
  //     myLocation.setPosition(null);
  //   }
  // }

  return (
    <div
      className='map_canves'
      id='map'
      style={{ height: '100%', width: '100%', direction: 'ltr' }}
    />
  );
};

export default TooMap;
