import {
  UPDATE_USER_ACCOUNT_PROPS,
  CLEAR_USER_ACCOUNT_STATE,
  MERGE_USER_ACCOUNT_PROPS,
  DELETE_USER_ACCOUNT_PROPS,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  userAccount: {
    isOpen: false,
  },
};

const UserAccountR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_ACCOUNT_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_USER_ACCOUNT_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_USER_ACCOUNT_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_USER_ACCOUNT_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default UserAccountR;
