import React from 'react';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import loc_icon from '../../../assets/img/loc_icon.png';
import points_icon from '../../../assets/img/points_icon.png';

import { getLanguage } from '../../../lang/local';
export default function LocationDetails({ address, lat, lng }) {
  const alignment = () => {
    let align = getLanguage() === 'ar' ? 'right' : 'none';
    return align;
  };

  return (
    <Paper sx={{ width: 320, maxWidth: '100%' }}>
      <MenuList>
        <MenuItem sx={{ wordBreak: 'break-word', textOverflow: 'clip' }}>
          <Typography variant='body2' color='text.secondary'>
            <img src={loc_icon} alt='' className='cursor' />
          </Typography>
          <ListItemText
            sx={{
              textAlign: alignment(),
              marginInlineStart: '10px',
              wordBreak: 'wrap',
              overflow: 'hidden',
            }}
          >
            {address ? address : ''}
          </ListItemText>
        </MenuItem>

        <MenuItem sx={{ wordBreak: 'break-word', textOverflow: 'clip' }}>
          <Typography variant='body2' color='text.secondary'>
            <img src={points_icon} alt='' className='cursor' />
          </Typography>
          <ListItemText
            sx={{
              textAlign: alignment(),
              marginInlineStart: '10px',
              wordBreak: 'wrap',
              overflow: 'hidden',
            }}
          >
            {lat ? lat : ''}
            <br />
            {lng ? lng : ''}
          </ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  );
}
