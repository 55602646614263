import { local } from '../../../lang/local';

import {
  UPDATE_DIRECTION_MENU_PROPS,
  UPDATE_LOCATION_MENU_PROPS,
  UPDATE_USER_ACCOUNT_PROPS,
} from '../../../Redux/Actions/types';

export const handleDirection = (
  e,
  myPosition,
  ipAddress,
  location_data,
  general,
  getSocketResult
) => {
  e.preventDefault();

  general(
    [{ prop: 'location_data.toViewSideInfo', value: false }],
    UPDATE_LOCATION_MENU_PROPS
  );

  general(
    [{ prop: 'userAccount.isOpen', value: false }],
    UPDATE_USER_ACCOUNT_PROPS
  );

  general(
    [
      { prop: 'startLocation.title', value: local.MyLocation },
      {
        prop: 'startLocation.lat',
        value:
          myPosition.allowLocation === true ? myPosition.lat : ipAddress.lat,
      },
      {
        prop: 'startLocation.lng',
        value:
          myPosition.allowLocation === true ? myPosition.lng : ipAddress.lng,
      },
      { prop: 'endLocation.title', value: location_data.address },
      { prop: 'endLocation.lat', value: location_data.lat },
      { prop: 'endLocation.lng', value: location_data.lng },
      { prop: 'direction_data.isOpen', value: true },
    ],
    UPDATE_DIRECTION_MENU_PROPS
  );
  getSocketResult(
    null,
    {
      start: myPosition.allowLocation === true ? myPosition.lat : ipAddress.lat,
      end: location_data.lat,
    },
    {
      start: myPosition.allowLocation === true ? myPosition.lng : ipAddress.lng,
      end: location_data.lng,
    },
    'routing',
    'car'
  );
};

