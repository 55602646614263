import React, { useEffect, useRef } from "react";
import { Mapy } from "mapy_sdk/lib";
import { showConfirmMessage } from "../../General/GeneralFunctions";
import marker_icon from "../../assets/img/marker.png";
import { iconPostion } from "mapy_sdk/lib/mapyTypes";
import { extractLatLng } from "../../General/MapFunctions";
import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
import { drawPolygons } from "./DrawPolygons";
import { getPermissions } from "../../utils/Permissions";
import { USER_CLAIMS } from "../../General/StaticKeys";
import { local } from "../../lang/local";

let map = new Mapy();

let mapPolygons = [];
let countries_ids = [];

const HeatMap = (props) => {
  const { location, general, usersMap, getAllUsersForMap } = props;
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevProps = usePrevious({ mapPolygons, usersMap });

  useEffect(() => {
    mapPolygons = [];
    countries_ids = [];
    loadMap();
  }, []);

  // useEffect(() => {
  //   return () => {
  //     if (map) {
  //       map.popMap();
  //     }
  //   };
  // }, [props.current]);

  useEffect(() => {
    try {
      return () => {
        map.removeBaseLayer();
      };
    } catch (e) {}
  }, [props.current]);
  useEffect(() => {
    if (prevProps !== undefined) {
      if (
        JSON.stringify(prevProps.mapPolygons) !== JSON.stringify(mapPolygons) &&
        mapPolygons.length > 0
      ) {
        drawsubPolygons();
      }
    }
  }, [mapPolygons]);
  useEffect(() => {
    if (prevProps !== undefined) {
      if (
        prevProps.usersMap.length !== usersMap.length &&
        usersMap.length > 0
      ) {
        drawMarkers();
      }
    }
  }, [usersMap]);

  const flipArray = (array) => {
    return array.map((e) => {
      if (e[0] && !Array.isArray(e[0])) {
        return [e[1], e[0]];
      } else {
        return flipArray(e);
      }
    });
  };

  const loadMap = () => {
    let getLocationPromise = new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            let lat = position.coords.latitude;
            let long = position.coords.longitude;
            resolve({ latitude: lat, longitude: long });
          },
          function (error) {
            initializeMap(
              location.nearest_location.lat,
              location.nearest_location.lng
            );
          }
        );
      } else {
        showConfirmMessage(
          "your browser doesn't support geolocation API",
          "error"
        );
        reject("your browser doesn't support geolocation API");
      }
    });

    getLocationPromise
      .then((location) => {
        initializeMap(location.latitude, location.longitude);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initializeMap = (lat, lng) => {
    const defaultOptions = {
      //   scrollWheelZoom: true,
      fullscreencontrol: false,
      maxZoom: 18,
      center: {
        lat: lat,
        lng: lng,
      },
      zoom: 3,
    };

    map.initMap(document.getElementById("map"), defaultOptions);

    // map.addOnMapClick((LatLng, properites) => {
    //   let lat = LatLng.lat;
    //   let lng = LatLng.lng;
    //   let zoom = properites.target._zoom;

    //   map.setCenter({ lat, lng });
    //   // map.setZoom(zoom);
    // });
    if (map) {
      let permission = getPermissions(false, true).UserBusiness_GetUsers;

      if (getPermissions(true, true).Administrator !== undefined) {
        drawPolygons(
          map,
          {
            fillColor: "lightgray",
            color: "lightgray",
          },
          {
            fillColor: "rgba(18, 169, 204,0.5)",
            color: "rgba(18, 169, 204,0.5)",
          },
          { fillColor: "#12a9cc", color: "#12a9cc" },
          null,
          null,
          general,
          getAllUsersForMap
        );
      } else if (permission !== undefined) {
        let claims = JSON.parse(localStorage.getItem(USER_CLAIMS));

        let item = claims.filter((c) => c.claimType === permission.value);

        if (item.length > 0) {
          item.map((t) => {
            let newPoint = t.geometryText.replace(/[\(\)']+/g, "");
            var wkPoint = newPoint.replace(/^\D+/g, "");
            wkPoint = wkPoint.split(",");

            let value = [];

            if (wkPoint) {
              wkPoint.map((p, index) => {
                let lat = 0;
                let lng = 0;
                if (index === 0) {
                  lat = p.split(" ")[1];
                  lng = p.split(" ")[0];
                } else {
                  p = p.split(" ");
                  p = p.filter((c) => c);

                  lat = p[1];
                  lng = p[0];
                }

                value.push([lat, lng]);
              });
            }
            mapPolygons.push({
              geometryText: t.geometryText,
              label: t.claimValue,
              value,
            });
          });
        }
      }

      if (mapPolygons.length > 0) {
        drawsubPolygons();
      }

      if (usersMap.length > 0) {
        drawMarkers();
      }
    }
  };

  const drawMarkers = () => {
    let clusterName = "heat" + new Date().toISOString();
    if (usersMap.length > 0) {
      return usersMap.map((user) => {
        if (user.lastSeenText) {
          let point = extractLatLng(user.lastSeenText);
          if (point.lat && point.lng) {
            return map.AddMarker(
              {
                clusterName: clusterName,
                imgUrl: marker_icon,
                location: point,
                imgSize: [28, 43],
                title: user.fullName,
                popup: `<h5>${user.fullName}</h5>`,
                iconAnchor: iconPostion.bottom_center,
                dragable: false,
                onClick: (id) => {
                  general(
                    [
                      {
                        prop: "profileModal",
                        value: { isOpen: true, data: user },
                      },
                    ],
                    UPDATE_GENERAL_PROPS
                  );
                },
              },

              user.firstName + user.id,
              false
            );
          }
        }
      });
    }
  };
  const drawsubPolygons = () => {
    return mapPolygons.map((country) => {
      return map.AddPolygon(
        {
          groupName: "countries",
          boundaries: country.value,
          color: "rgba(18, 169, 204, 0.5)",
          fillColor: "rgba(18, 169, 204, 0.5)",
          fillOpacity: 0.8,
          weight: 2,
          properties: { points: country.value },
          popup: `<div><h5>${country.label}</h5></div>`,
          onClick: (id, properties) => {
            debugger;
            let poly = "Polygon((";
            properties.points.map((point, index) => {
              if (properties.points.length - 1 === index) {
                poly += point[1] + " " + point[0] + "))";
              }

              if (properties.points.length - 1 !== index) {
                poly += point[1] + " " + point[0] + ",";
              }
            });
            if (countries_ids.length > 0) {
              if (!countries_ids.find((um) => um === id)) {
                getAllUsersForMap(1, poly, id);
                countries_ids.push(properties.id);
              }
            } else {
              getAllUsersForMap(1, poly, id);
              countries_ids.push(id);
            }
          },
        },
        country.label,
        false
      );
    });
  };
  return (
    <div
      className="map_canves"
      id="map"
      style={{
        height: "100%",
        width: "100%",
        direction: "ltr",
      }}
    />
  );
};

export default HeatMap;
