import {
  UPDATE_LOGIN_PROPS,
  CLEAR_LOGIN_STATE,
  MERGE_LOGIN_PROPS,
  DELETE_LOGIN_PROPS,
} from '../Actions/types';
import * as general from './methods';

const INITIAL_STATE = {
  // form: {
  //   userName: "201097933502",
  //   lat: "30",
  //   lng: "31",
  //   password: "123456789",
  // },
  // form: {
  //   userName: "user1",
  //   lat: "30",
  //   lng: "31",
  //   password: "P@ssword123",
  // },

  loginObj: {
    form: {
      userName: '',
      lat: '',
      lng: '',
      password: '',
    },
    isLoading: false,
  },
};

const LoginR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LOGIN_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_LOGIN_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_LOGIN_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_LOGIN_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};

export default LoginR;
