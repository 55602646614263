import { numberToArabic } from "number-to-arabic";
import React from "react";
import { Card, Col, Row, Button } from "reactstrap";
import { leftStyle } from "./GeneralStyle";
import { getLanguage } from "../lang/local";

const GeneralCard = (props) => {
  const {
    background,
    renderBody,
    title,
    count,
    titleColor,
    image,
    buttonItem,
    handleClick,
    tobeCenter,
  } = props;

  const renderCardValue = () => {
    if (image !== undefined) {
      return (
        <span className={titleColor}>
          {typeof count === "number"
            ? getLanguage() === "en"
              ? count
              : numberToArabic(count)
            : count}
        </span>
      );
    }
    if (buttonItem !== undefined) {
      return (
        <Button
          style={leftStyle}
          className="card_add_btn cursor center"
          onClick={(e) =>
            buttonItem.handleClick !== undefined
              ? buttonItem.handleClick(e)
              : null
          }
        >
          <span className="card_add_text">{buttonItem.title}</span>
        </Button>
      );
    } else {
      return (
        <span className={titleColor}>
          {typeof count === "number"
            ? getLanguage() === "en"
              ? count
              : numberToArabic(count)
            : count}
        </span>
      );
    }
  };
  return (
    <Card
      className="support_card"
      style={{ background }}
      data-aos="zoom-in"
      data-aos-delay="20"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <Row className="mt-2 alignItem ml-2 mr-2">
        <Col
          className="mx-auto flex"
          style={{ justifyContent: "space-between" }}
        >
          <p
            className={"cursor " + titleColor}
            onClick={handleClick !== undefined ? handleClick : null}
          >
            {title + " "}

            {image !== undefined ? <img src={image} alt="" /> : null}
          </p>
          {renderCardValue()}
        </Col>
      </Row>
      {renderBody !== null ? (
        <div className={tobeCenter === undefined ? "" : "center"}>
          {renderBody()}
        </div>
      ) : null}
    </Card>
  );
};

export default GeneralCard;
