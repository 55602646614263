import React, { useState, useEffect } from "react";
import { Col, Row, Card, Input, Form, Spinner } from "reactstrap";
import * as actions from "../../Redux/Actions/index";
import { connect } from "react-redux";
import { getLanguage, local } from "../../lang/local";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import strik from "../../assets/img/strik.png";
import { UPDATE_USERS_PROPS } from "../../Redux/Actions/types";
import Gallery from "../../General/Gallery";
import { renderEyePassword } from "../Register/RegisterFunction";
import Select from "react-select";
import { getDefaultValue } from "../../General/SelectFunctions";
import { isRtl, selectFormStyle } from "../../General/GeneralStyle";
import { getUser } from "../../utils/StorageFunctions";

const EditUser = ({
  history,
  form,
  isLoadingCreate,
  general,
  lightbox,
  updateUser,
}) => {
  useEffect(() => {
    if (!form.id) {
      general(
        [
          {
            prop: "form",
            value: {
              firstName: getUser().data.firstName,
              lastName: getUser().data.lastName,
              userName: getUser().data.userName,
              email: getUser().data.email,
              password: getUser().data.password,
              passwordConfirmation: getUser().data.passwordConfirmation,
              gender: getUser().data.gender,
              birthDate: getUser().data.birthDate,
              country: getUser().data.country,
              phoneNumber: getUser().data.phoneNumber,
              id: getUser().data.id,
            },
          },
        ],
        UPDATE_USERS_PROPS
      );
    }
  }, []);
  const Options = [
    { label: getLanguage() === "en" ? "Male" : "ذكر", value: "male" },
    { label: getLanguage() === "en" ? "Female" : "انثي", value: "female" },
  ];

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  //   componentDidMount() {
  //     this.getVenue();
  //   }

  //   getVenue = () => {
  //     const { fetchVenue, history, form } = this.props;
  //     let items = history.location.pathname.split("/");
  //     let id = +items.find((i) => !isNaN(i) && i !== "");
  //     if (id) {
  //       if (form.id === undefined) {
  //         fetchVenue(id);
  //       }
  //     } else {
  //       history.push("/dashboard/venues");
  //     }
  //   };

  return (
    <div className="page-container">
      <Gallery lightbox={lightbox} general={general} />

      <Row>
        <Col
          xl={8}
          lg={10}
          md={10}
          sm={12}
          xs={12}
          className="mx-auto text-center"
        >
          <h3 className="page_create_title">{local.EditData}</h3>

          <Card className="create_card mt-3">
            <Form className="mb-5 mt-4">
              {/* <Row>
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="mt-5"
                    >
                      {!form.image ? (
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            general(
                              [
                                {
                                  prop: "form.image",
                                  value: acceptedFiles,
                                },
                              ],
                              UPDATE_USERS_PROPS
                            )
                          }
                        >
                          {({ getRootProps, getInputProps, acceptedFiles }) => (
                            <div
                              {...getRootProps()}
                              className="fullWidthHeight center cursor"
                            >
                              <input
                                {...getInputProps()}
                                multiple={false}
                                accept="image/*"
                                defaultValue={acceptedFiles}
                              />

                              <Col className="text-center">
                                <div>
                                  <img src={image_icon} alt="" />
                                </div>
                              </Col>
                            </div>
                          )}
                        </Dropzone>
                      ) : (
                        <div className="center">
                          {renderPhoto(form.image, general)}
                        </div>
                      )}
                    </Col>
                  </Row> */}
              <Row>
                <Col
                  xl={5}
                  lg={5}
                  md={6}
                  sm={12}
                  xs={12}
                  className="mt-5 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <img src={strik} alt="strik" className="strik_icon" />
                    <Input
                      className="add_input"
                      value={form.firstName}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "form.firstName",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_USERS_PROPS
                        )
                      }
                      placeholder={local.FirstName}
                    />
                  </div>
                </Col>

                <Col
                  xl={5}
                  lg={5}
                  md={6}
                  sm={12}
                  xs={12}
                  className="mt-5 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <img src={strik} alt="strik" className="strik_icon" />
                    <Input
                      className="add_input"
                      value={form.lastName}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "form.lastName",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_USERS_PROPS
                        )
                      }
                      placeholder={local.LastName}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col
                  xl={5}
                  lg={5}
                  md={6}
                  sm={12}
                  xs={12}
                  className="mt-5 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <img src={strik} alt="strik" className="strik_icon" />
                    <Input
                      className="add_input"
                      value={form.email}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "form.email",
                              value: e.target.value,
                            },
                            {
                              prop: "form.userName",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_USERS_PROPS
                        )
                      }
                      placeholder={local.Email}
                    />
                  </div>
                </Col>
                <Col
                  xl={5}
                  lg={5}
                  md={6}
                  sm={12}
                  xs={12}
                  className="mt-5 mx-auto"
                >
                  <div className="container_add_input mx-auto">
                    <PhoneInput
                      lang={getLanguage() === "ar" ? ar : ""}
                      // onlyCountries={[
                      //   "eg",
                      //   "sa",
                      //   "ae",
                      //   "ku",
                      //   "lb",
                      //   "om",
                      //   "bh",
                      // ]}
                      masks={{
                        eg: ".. ........",
                        sa: ".. ... ....",
                        ae: ".. .......",
                      }}
                      country="sa"
                      countryCodeEditable={false}
                      value={form.phoneNumber}
                      onChange={(e, country) =>
                        general(
                          [
                            {
                              prop: "form.phoneNumber",
                              value: e,
                            },
                            {
                              prop: "form.country",
                              value: country.countryCode,
                            },
                          ],
                          UPDATE_USERS_PROPS
                        )
                      }
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col
                  xl={5}
                  lg={5}
                  md={6}
                  sm={12}
                  xs={12}
                  className="mt-5 mx-auto"
                >
                  <div
                    className="container_add_input mx-auto"
                    style={{ height: "44px" }}
                  >
                    <img src={strik} alt="strik" className="strik_icon" />
                    <input
                      type="password"
                      style={{ position: "absolute", zIndex: "-1" }}
                    />
                    <Input
                      type={showPassword === true ? "text" : "password"}
                      className="add_input"
                      placeholder={local.Password}
                      value={form.password}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "form.password",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_USERS_PROPS
                        )
                      }
                    />
                    {renderEyePassword(showPassword, setShowPassword)}
                  </div>
                </Col>

                <Col
                  xl={5}
                  lg={5}
                  md={6}
                  sm={12}
                  xs={12}
                  className="mt-5 mx-auto"
                >
                  <div
                    className="container_add_input mx-auto"
                    style={{ height: "44px" }}
                  >
                    <img src={strik} alt="strik" className="strik_icon" />
                    <input
                      type="password"
                      style={{ position: "absolute", zIndex: "-1" }}
                    />
                    <Input
                      type={showConfirmPassword === true ? "text" : "password"}
                      className="add_input"
                      placeholder={local.ConfirmPassword}
                      value={form.passwordConfirmation}
                      onChange={(e) =>
                        general(
                          [
                            {
                              prop: "form.passwordConfirmation",
                              value: e.target.value,
                            },
                          ],
                          UPDATE_USERS_PROPS
                        )
                      }
                    />
                    {renderEyePassword(
                      showConfirmPassword,
                      setShowConfirmPassword
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col
                  xl={5}
                  lg={5}
                  md={6}
                  sm={12}
                  xs={12}
                  className="mt-5 mx-auto"
                >
                  <div
                    className="container_add_input mx-auto"
                    style={{ height: "44px" }}
                  >
                    <div className="input_container date_container">
                      <Flatpickr
                        data-enable-time={false}
                        options={{
                          maxDate: "2002-01-01",
                          enableTime: false,
                          altInput: true,
                          allowInput: true,
                        }}
                        value={form.birthDate}
                        onChange={(date) =>
                          general(
                            [
                              {
                                prop: "form.birthDate",
                                value: date[0],
                              },
                            ],
                            UPDATE_USERS_PROPS
                          )
                        }
                        placeholder={local.BirthDay}
                      />
                    </div>
                  </div>
                </Col>
                <Col
                  xl={5}
                  lg={5}
                  md={6}
                  sm={12}
                  xs={12}
                  className="mt-5 mx-auto"
                >
                  <div
                    className="container_add_input mx-auto"
                    style={{ height: "44px" }}
                  >
                    <div className="form_select_container">
                      <Select
                        key={form.gender}
                        placeholder={local.Gender}
                        value={getDefaultValue(form.gender, Options)}
                        isSearchable={true}
                        options={Options}
                        onChange={(e) =>
                          e !== null
                            ? general(
                                [
                                  {
                                    prop: "form.gender",
                                    value: e.value,
                                  },
                                ],
                                UPDATE_USERS_PROPS
                              )
                            : general(
                                [
                                  {
                                    prop: "form.gender",
                                    value: null,
                                  },
                                ],
                                UPDATE_USERS_PROPS
                              )
                        }
                        isClearable={true}
                        isRtl={isRtl()}
                        styles={selectFormStyle}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col
                  xl={5}
                  lg={5}
                  md={6}
                  sm={12}
                  xs={12}
                  className="mt-5 mx-auto"
                ></Col>
                <Col
                  xl={5}
                  lg={5}
                  md={6}
                  sm={12}
                  xs={12}
                  className="mt-5 mx-auto"
                ></Col>
              </Row>

              <Row>
                <Col
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="mt-5 mx-auto"
                >
                  <button
                    className="save_btn"
                    onClick={(e) => {
                      e.preventDefault();
                      updateUser(form, history);
                    }}
                  >
                    {isLoadingCreate === true ? <Spinner /> : local.Save}
                  </button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ UsersR, GeneralR }) => {
  return {
    form: UsersR.form,
    isLoadingCreate: UsersR.isLoadingCreate,
    location: GeneralR.location,
    lightbox: GeneralR.lightbox,
  };
};
export default connect(mapStateToProps, actions)(EditUser);
