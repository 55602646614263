import React from "react";
import { Col, Row, Input, Label, Card } from "reactstrap";
import { local } from "../../lang/local";
import { columnAlignLeft } from "../../General/GeneralStyle";
import { preparePermissions, sortItem } from "../../General/GeneralFunctions";
import { getPermissionIcon } from "./RolesFunctions";

const RoleProfile = ({ data }) => {
  const renderClaims = () => {
    {
      if (data.claims.length > 0) {
        return preparePermissions(data.claims).map((claim) => {
          let values = sortItem(claim.value);
          return (
            <Row className="mt-2">
              <Col
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
                className={columnAlignLeft() + " mx-auto"}
              >
                <p className="bold mt-5">
                  {claim.key === "Branches"
                    ? local.Branches + "/" + local.Distributors
                    : local[claim.key]}
                </p>
              </Col>

              <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                <ul className="nav">
                  {values.map((sub, i) => {
                    if (sub.label === "Add Complaint Type") {
                    }
                    if (sub.label === "Accept/Reject Place") {
                    }
                    return (
                      <li
                        className="mt-5"
                        key={i}
                        style={{
                          listStyle: "none",
                          display: "inline-block",
                          width: `20%`,
                        }}
                      >
                        <Card className="center ml-1 mr-1 permission_card">
                          {getPermissionIcon(sub.label)}
                          <span> {sub.label} </span>
                        </Card>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          );
        });
      }
    }
  };
  return data ? (
    <div className="mt-3 mb-3">
      <Row>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Id}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.id}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Name}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.name}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Description}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.description}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      {renderClaims()}
      {/* {data.claims.length > 0 ? (
          data.claims.map((claim) => {
            return (
              <Col xl={3} lg={3} md={3} sm={4} xs={4} className="mt-3">
                <Card className="permission_card center">
                  <span className="text-center weightFont">
                    {getClaimTitle(claim.claimType)}
                  </span>
                </Card>
              </Col>
            );
          })
        ) : (
          <Col className="text-center weightFont mt-3" style={{ color: "red" }}>
            {local.NoClaims}
          </Col>
        )} */}
    </div>
  ) : null;
};

export default RoleProfile;
