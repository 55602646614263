import React from "react";
import { Row, Col } from "reactstrap";
import GeneralCard from "../../General/GeneralCard";
import { local } from "../../lang/local";
import { leftStyle } from "../../General/GeneralStyle";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";
import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
import { renderName, renderImage } from "../../General/GeneralFunctions";
import { openProfile } from "../Venues/VenuesFunctions";
const VenuesCard = ({ venues, general, history, fetchVenue }) => {
  const handleTitleClick = (e) => {
    e.preventDefault();
    history.push("/dashboard/venues");
  };
  const renderVenuesItems = () => {
    if (venues.items) {
      if (venues.items.length > 0) {
        let logos = venues.items.map((l) => {
          return IMAGE_BASE_PATH + l.logo;
        });
        return venues.items.map((venue, index) => {
          return (
            <Col
              key={index}
              className="mt-3"
              xl={3}
              lg={3}
              md={3}
              sm={3}
              xs={3}
            >
              <img
                src={renderImage(venue.logo)}
                onClick={(e) => {
                  e.preventDefault();
                  general(
                    [
                      {
                        prop: "lightbox",
                        value: {
                          images: logos,
                          type: "multi",
                          index: index,

                          isOpen: true,
                        },
                      },
                    ],
                    UPDATE_GENERAL_PROPS
                  );
                }}
                className="comp_account_img cursor"
              />
              <a
                href=""
                className="blueLight"
                onClick={(e) =>
                  openProfile(e, general, fetchVenue, venue, index)
                }
              >
                <p className="white_color">{renderName(venue.name)}</p>
              </a>
            </Col>
          );
        });
      }
    }
  };
  const renderCompanyAccountCard = () => {
    return (
      <div>
        <Row className="ml-1 mr-1">
          <Col xl={12} className="ml-2 mr-2">
            <p className="weightFont white_color" style={leftStyle}>
              {local.Newlyjoined}
            </p>
          </Col>
        </Row>

        <Row className="ml-1 mr-1 mt-5">{renderVenuesItems()}</Row>
      </div>
    );
  };
  return (
    <Col className="center" xl={4} lg={4} md={6} sm={12} xs={12}>
      <GeneralCard
        title={local.CompanyAccount}
        count={venues.count}
        titleColor="white_color"
        className="text-center maxWidth"
        background="#002345"
        renderBody={renderCompanyAccountCard}
        handleClick={handleTitleClick}
      />
    </Col>
  );
};

export default VenuesCard;
