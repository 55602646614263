import React from "react";
import { local } from "../../lang/local";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { HiDotsVertical } from "react-icons/hi";
import { confirmAlert } from "react-confirm-alert";
import { UPDATE_TICKETS_PROPS } from "../../Redux/Actions/types";

export const unsetErrorMessage = (general) => {
  general(
    [
      {
        prop: "data.errorMessages",
        value: { Title: "", Description: "", Sector: "", Location: "" },
      },
    ],
    UPDATE_TICKETS_PROPS
  );
};

export const validateForm = (form, general) => {
  if (
    !form.description ||
    !form.title ||
    form.sector === null ||
    form.location.length === 0
  ) {
    window.scrollTo(0, 0);
    if (!form.title) {
      general(
        [
          {
            prop: "data.errorMessages.Title",
            value: local.TitleRequired,
          },
        ],
        UPDATE_TICKETS_PROPS
      );
    }
    if (form.sector === null) {
      general(
        [
          {
            prop: "data.errorMessages.Sector",
            value: local.SectorRequired,
          },
        ],
        UPDATE_TICKETS_PROPS
      );
    }

    if (!form.description) {
      general(
        [
          {
            prop: "data.errorMessages.Description",
            value: local.DescriptionRequired,
          },
        ],
        UPDATE_TICKETS_PROPS
      );
    }
    if (form.location.length === 0) {
      general(
        [
          {
            prop: "data.errorMessages.Location",
            value: local.LocationRequired,
          },
        ],
        UPDATE_TICKETS_PROPS
      );
    }
  } else {
    unsetErrorMessage(general);
    return true;
  }
};
export const handleSaveTicket = (e, actionType, form, general, history) => {
  e.preventDefault();

  if (validateForm(form, general) === true) {
    actionType(form, history);
  }
};

export const renderSectorOptions = () => {
  return [
    { label: local.Business, value: 0 },
    { label: local.Gis, value: 1 },
    { label: local.Mobile, value: 2 },
  ];
};

export const renderTicketMenu = (
  item,
  index,
  deleteItem,
  editItem,
  deletePermission,
  editPermission
) => {
  return (
    <UncontrolledDropdown setActiveFromChild>
      <DropdownToggle tag="span" className="nav-link table_list_dropdown" caret>
        <HiDotsVertical size={20} style={{ color: "#272D3B" }} />
      </DropdownToggle>
      <DropdownMenu className="table_dropdown_menu">
        {editPermission !== null && item.status === true ? (
          // getPermissions(true, true).Ticket_Delete !== undefined ||
          // getPermissions(true, true).Administrator !== undefined ? (
          editItem !== null ? (
            <DropdownItem
              tag="a"
              className="cursor weightFont"
              onClick={(e) => editItem(e, item, index, "update")}
            >
              {local.Edit}
            </DropdownItem>
          ) : null
        ) : // ) : null
        null}

        {deletePermission !== null ? (
          // getPermissions(true, true).Ticket_Update !== undefined ||
          // getPermissions(true, true).Administrator !== undefined ? (
          deleteItem !== null ? (
            <DropdownItem
              tag="a"
              className="cursor weightFont"
              onClick={(e) =>
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="custom-ui">
                        <h1>{local.Sure}</h1>
                        <p>{local.ConfirmEdit}</p>
                        <button onClick={onClose}>{local.No}</button>
                        <button
                          onClick={() => {
                            deleteItem(e, item, index);
                            onClose();
                          }}
                        >
                          {local.Yes}
                        </button>
                      </div>
                    );
                  },
                })
              }
            >
              {local.Delete}
            </DropdownItem>
          ) : null
        ) : // ) : null
        null}

        {editPermission !== null && item.status === true ? (
          // getPermissions(true, true).Ticket_Update !== undefined ||
          // getPermissions(true, true).Administrator !== undefined ? (
          deleteItem !== null ? (
            <DropdownItem
              tag="a"
              className="cursor weightFont"
              onClick={(e) => editItem(e, item, index, "close")}
            >
              {local.Close}
            </DropdownItem>
          ) : null
        ) : // ) : null
        null}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export const getLocation = (location) => {
  if (location.includes("POINT")) {
    return [location];
  } else {
    return [];
  }
};
