import {
  validateRoutingPoint,
  getLatLng,
  renderName,
} from "../../../General/GeneralFunctions";
import { general } from "../../../Redux/Actions";
import { centerMap } from "../Maps/MapFunctions";
import marker_icon from "../../../assets/img/marker.png";
import start_icon from "../../../assets/img/start_icon.png";
import end_icon from "../../../assets/img/end_icon.png";
import { UPDATE_LOCATION_MENU_PROPS } from "../../../Redux/Actions/types";

import {
  addCustomMarker,
  addMarker,
  addPolylines,
  onClickPolyLine,
  removeMarker,
  removePolylines,
} from "./MapyFunctions";

export const handleRouting = (
  prevStLocation,
  stLocation,
  map,
  history,
  prevEnLocation,
  enLocation,
  prevDirection,
  currentDirection,
  source_marker,
  destination_marker,
  start_dergable_Marker,
  end_dergable_Marker,
  getSocketResult,
  location_data,
  location_marker
) => {
  let prevStResult = prevStLocation.searchResults;
  let currentStResult = stLocation.searchResults;

  let prevEnResult = prevEnLocation.searchResults;
  let currentEnResult = enLocation.searchResults;

  let prevRouting = prevDirection.routing;
  let cureentRouting = currentDirection.routing;

  if (prevStResult !== currentStResult) {
    if (stLocation.search_type === "search") {
      centerMap(currentStResult, map, history);
    }
  }

  if (prevEnResult !== currentEnResult) {
    if (enLocation.search_type === "search") {
      centerMap(currentEnResult, map, history);
    }
  }

  if (prevRouting !== cureentRouting) {
    if (cureentRouting) {
      if (cureentRouting.length > 0) {
        removePolylines(map, currentDirection.routing);

        addPolylines(
          map,
          currentDirection,
          stLocation,
          enLocation,
          general,
          history,
          0
        );
        map.zoomToFeature("Polyline", "startLine");
        // startLine
        // map.
        // zoomToObject(cureentRouting, map);
      }
    }
  }

  if (
    prevDirection.isOpen !== currentDirection.isOpen &&
    currentDirection.isOpen === false
  ) {
    source_marker = true;
    destination_marker = true;
    removeMarker(source_marker, map, "source");
    removeMarker(destination_marker, map, "destination");
    removeMarker(start_dergable_Marker, map, "start_dergable");
    removeMarker(end_dergable_Marker, map, "end_dergable");
    removePolylines(map, currentDirection.routing);
  }

  if (
    prevStLocation.lat !== stLocation.lat ||
    prevStLocation.lng !== stLocation.lng ||
    prevEnLocation.lat !== enLocation.lat ||
    prevEnLocation.lng !== enLocation.lng
  ) {
    if (validateRoutingPoint(stLocation, enLocation) === true) {
      getSocketResult(
        null,
        {
          start: stLocation.lat,
          end: enLocation.lat,
        },
        {
          start: stLocation.lng,
          end: enLocation.lng,
        },
        "routing",
        "car"
      );
    }
  }

  if (
    (prevStLocation.lat !== stLocation.lat ||
      prevStLocation.lng !== stLocation.lng) &&
    stLocation.lat !== "" &&
    stLocation.lng !== "" &&
    currentDirection.isOpen
  ) {
    addMarker(
      map,
      {
        lat: stLocation.lat,
        lng: stLocation.lng,
      },
      start_icon,
      [19, 20],
      "source",
      true
    );
  }

  if (
    (prevEnLocation.lat !== enLocation.lat ||
      prevEnLocation.lng !== enLocation.lng) &&
    enLocation.lat !== "" &&
    enLocation.lng !== "" &&
    location_data.toViewSideInfo === false
  ) {
    // removeMarker(location_marker, map, "location");

    addMarker(
      map,
      {
        lat: enLocation.lat,
        lng: enLocation.lng,
      },
      end_icon,
      [19, 19],
      "destination",
      true
    );
  }

  if (
    prevStLocation.dragableMarker.lat !== stLocation.dragableMarker.lat ||
    prevStLocation.dragableMarker.lng !== stLocation.dragableMarker.lng
  ) {
    let lat = stLocation.dragableMarker.lat;
    let lng = stLocation.dragableMarker.lng;
    map.setCenter({ lat: lat, lng: lng });
  }

  if (prevDirection.active_class !== currentDirection.active_class) {
    onClickPolyLine(
      map,
      currentDirection.active_class,
      currentDirection.routing,
      general,
      stLocation,
      enLocation,
      history
    );
  }
};
export const handleLocationMenu = (
  prevLocation,
  currentLocation,
  zoom,
  location_marker,
  removeMarkers,
  onUpdateMap,
  isOpenAddContribution,
  map
) => {
  let lat = currentLocation.lat;
  let lng = currentLocation.lng;
  let toViewSideInfo = currentLocation.toViewSideInfo;
  if (
    prevLocation.toViewSideInfo !== toViewSideInfo &&
    toViewSideInfo === false &&
    isOpenAddContribution === false
  ) {
    removeMarker(location_marker, map, "location");
  }

  if (prevLocation.lat !== lat && currentLocation.icon_type === "search") {
    location_marker = true;

    if (currentLocation.placeType === 0 || currentLocation.placeType === 1) {
      addCustomMarker(
        map,
        [lat, lng],
        currentLocation.image,
        [28, 43],
        "location",
        true,
        currentLocation.address,
        currentLocation.placeType,
        onUpdateMap,
        general
      );
    } else {
      addMarker(
        map,
        {
          lat,
          lng,
        },
        marker_icon,
        [28, 43],
        "location",
        true
      );

      onUpdateMap(lat, lng, zoom, false, true, "", true);
    }
  }
  if (
    prevLocation.toViewSideInfo !== toViewSideInfo &&
    toViewSideInfo === true &&
    window.location.pathname
      .split(",")
      .find((a) => (a !== "company") === undefined)
  ) {
    removeMarkers();
  }
};

export const handleAddContribuation = (
  prevForm,
  currentForm,
  prevLocation,
  curentLocation,
  location_marker,
  general,
  removeMarkers,
  map
) => {
  let toViewSideInfo = curentLocation.toViewSideInfo;

  if (
    prevForm.Geometry !== currentForm.Geometry &&
    currentForm.Geometry !== "" &&
    getLatLng(currentForm.Geometry).lat !== curentLocation.lat
  ) {
    let lat = getLatLng(currentForm.Geometry).lat;
    let lng = getLatLng(currentForm.Geometry).lng;

    general(
      [
        { prop: "location_data.lat", value: lat },
        { prop: "location_data.lng", value: lng },
        {
          prop: "location_data.address",
          value: renderName(currentForm.name),
        },
      ],
      UPDATE_LOCATION_MENU_PROPS
    );

    removeMarker(location_marker, map, "location");
  }
  if (
    prevLocation.toViewSideInfo !== toViewSideInfo &&
    toViewSideInfo === true
  ) {
    removeMarkers();
  }
};
