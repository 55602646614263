import React, { useEffect, useState } from "react";
import { Row, Col, Input, Spinner, Label, Card } from "reactstrap";
import { getLanguage, local } from "../../../lang/local";
import strik from "../../../assets/img/strik.png";
import { MdDateRange } from "react-icons/md";
import image_icon from "../../../assets/img/image_icon.png";

import {
  UPDATE_LOCATION_MENU_PROPS,
  UPDATE_CONTRIBUTION_PROPS,
  MERGE_CONTRIBUTION_PROPS,
  DELETE_CONTRIBUTION_PROPS,
} from "../../../Redux/Actions/types";
import {
  getWorkingHours,
  handleEnglishNameChange,
  saveImage,
  renderPhotos,
  renderErrorMessage,
} from "../../../General/GeneralFunctions";
import { renderRowHeader } from "../../../utils/MapViewFunctions";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import Dropzone from "react-dropzone";
import { webAsideCardStyle } from "../../../utils/MapViewStyle";
import {
  isRtl,
  selectFormStyle,
  alignTextRight,
} from "../../../General/GeneralStyle";
import * as actions from "../../../Redux/Actions/index";
import { connect } from "react-redux";
import "./contribuation.css";
import Select from "react-select";
import { onSelectLayer } from "../../Contribuations/ContribuationFunctions";
import { getDefaultLayer } from "../../../General/SelectFunctions";
import { handleSave } from "../../Contribuations/ContribuationFunctions";
import Gallery from "../../../General/Gallery";
const AddContribution = ({
  general,
  form,
  addContribution,
  layersOptions,
  getLayers,
  isLoadingCreate,
  history,
  lightbox,
  errorMessages,
}) => {
  const [viewSocial, setSocial] = useState(false);
  useEffect(() => {
    getLayers();
  }, []);

  const openWorkingHours = (e) => {
    e.preventDefault();
    general(
      [
        { prop: "hours.isOpen", value: true },
        {
          prop: "data.isOpenAddContribution.isOpen",
          value: false,
        },
      ],
      UPDATE_CONTRIBUTION_PROPS
    );
  };

  return (
    <Card className="web_view_card" style={webAsideCardStyle}>
      <Gallery lightbox={lightbox} general={general} />

      {renderRowHeader(
        [
          {
            payload: [{ prop: "location_data.toViewSideInfo", value: true }],
            action: UPDATE_LOCATION_MENU_PROPS,
          },

          {
            payload: [
              { prop: "data.isOpen", value: false },
              { prop: "data.isOpenAddContribution", value: false },
            ],
            action: UPDATE_CONTRIBUTION_PROPS,
          },
        ],
        general,
        local.AddNewPlace
      )}
      <Row className="mt-3">
        <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
          <Label className="mr-1 ml-1">{local.EnglishName}</Label>
          <div className="container_add_input mx-auto">
            <img src={strik} alt="strik" className="strik_icon" />
            <Input
              className="add_input"
              value={form.ContributionName.en}
              onChange={(e) =>
                handleEnglishNameChange(
                  e,
                  general,
                  UPDATE_CONTRIBUTION_PROPS,
                  "data.form.ContributionName.en"
                )
              }
              placeholder={local.EnglishName}
            />
          </div>
          {!form.ContributionName.ar &&
          !form.ContributionName.en &&
          errorMessages.Name ? (
            <span className="error_message">{errorMessages.Name}</span>
          ) : null}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} sm={12} md={12} xs={12} style={alignTextRight}>
          <Label className="mr-1 ml-1">{local.ArabicName}</Label>
          <div className="container_add_input mx-auto">
            <img src={strik} alt="strik" className="strik_icon" />

            <Input
              className="add_input"
              placeholder={local.AddName}
              value={form.ContributionName.ar}
              onChange={(e) =>
                general(
                  [
                    {
                      prop: "data.form.ContributionName.ar",
                      value: e.target.value,
                    },
                  ],
                  UPDATE_CONTRIBUTION_PROPS
                )
              }
            />
          </div>
          {!form.ContributionName.ar &&
          !form.ContributionName.en &&
          errorMessages.Name ? (
            <span className="error_message">{errorMessages.Name}</span>
          ) : null}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
          <Label className="mr-1 ml-1">{local.Category}</Label>

          <div className="container_add_input mx-auto">
            <img src={strik} alt="strik" className="strik_icon" />

            <div className="form_select_container">
              <Select
                key={form.Layer}
                placeholder={local.Layer}
                value={getDefaultLayer(form.Layer, layersOptions)}
                options={layersOptions}
                onChange={(e) => onSelectLayer(e, general)}
                isClearable={true}
                isRtl={isRtl}
                styles={selectFormStyle}
                isSearchable={true}
              />
            </div>
          </div>
          {renderErrorMessage(form.Layer, errorMessages.Layer)}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
          <Label className="mr-1 ml-1">{local.Mobile}</Label>
          <div className="container_add_input mx-auto">
            <PhoneInput
              lang={getLanguage() === "ar" ? ar : ""}
              // onlyCountries={["eg", "sa", "ae", "ku", "lb", "om", "bh"]}
              masks={{
                eg: ".. ........",
                sa: ".. ... ....",
                ae: ".. .......",
              }}
              country={"sa"}
              enableSearch={true}
              enableAreaCodes={false}
              prefix={""}
              value={form.PhoneNumber}
              onChange={(e) =>
                general(
                  [{ prop: "data.form.PhoneNumber", value: e }],
                  UPDATE_CONTRIBUTION_PROPS
                )
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
          <Label>{local.WebSite}</Label>
          <div className="container_add_input mx-auto">
            <Input
              className="add_input"
              placeholder={local.WebSite}
              value={form.Website}
              onChange={(e) =>
                general(
                  [
                    {
                      prop: "form.Website",
                      value: e.target.value,
                    },
                  ],
                  UPDATE_CONTRIBUTION_PROPS
                )
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
          <div>
            <Label>{local.OpeningHours}</Label>
            <div className="container_add_input mx-auto">
              <MdDateRange
                className="strik_icon2 cursor"
                color="gray"
                size={17}
                onClick={openWorkingHours}
              />
              <Input
                className="add_input cursor"
                placeholder={local.OpenningHours}
                defaultValue={getWorkingHours(form.WorkingHours)}
                value={getWorkingHours(form.WorkingHours)}
                onClick={openWorkingHours}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
          <Label>{local.Description}</Label>
          <div className="container_add_input mx-auto">
            <Input
              className="add_input"
              placeholder={local.Description}
              value={form.Description.key}
              onChange={(e) =>
                general(
                  [
                    {
                      prop: "data.form.Description.key",
                      value: e.target.value,
                    },
                  ],
                  UPDATE_CONTRIBUTION_PROPS
                )
              }
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
          <a
            href
            style={{
              color: "#12a9cc",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.preventDefault();
              setSocial(!viewSocial);
            }}
          >
            {local.AddSocial}
          </a>
        </Col>
      </Row>

      {viewSocial ? (
        <React.Fragment>
          <Row className="mt-4">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
              <Label>{local.Facebook}</Label>
              <div className="container_add_input mx-auto">
                <Input
                  className="add_input"
                  placeholder={local.Facebook}
                  value={form.Facebook}
                  onChange={(e) =>
                    general(
                      [
                        {
                          prop: "data.form.Facebook",
                          value: e.target.value,
                        },
                      ],
                      UPDATE_CONTRIBUTION_PROPS
                    )
                  }
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
              <Label>{local.Instgram}</Label>
              <div className="container_add_input mx-auto">
                <Input
                  className="add_input"
                  placeholder={local.Instgram}
                  value={form.Instgram}
                  onChange={(e) =>
                    general(
                      [
                        {
                          prop: "data.form.Instgram",
                          value: e.target.value,
                        },
                      ],
                      UPDATE_CONTRIBUTION_PROPS
                    )
                  }
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
              <Label>{local.Snapshat}</Label>
              <div className="container_add_input mx-auto">
                <Input
                  className="add_input"
                  placeholder={local.Snapshat}
                  value={form.Snapshat}
                  onChange={(e) =>
                    general(
                      [
                        {
                          prop: "data.form.Snapshat",
                          value: e.target.value,
                        },
                      ],
                      UPDATE_CONTRIBUTION_PROPS
                    )
                  }
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
              <Label>{local.WhatsApp}</Label>
              <div className="container_add_input mx-auto">
                <Input
                  className="add_input"
                  placeholder={local.WhatsApp}
                  value={form.Whatsapp}
                  onChange={(e) =>
                    general(
                      [
                        {
                          prop: "data.form.Whatsapp",
                          value: e.target.value,
                        },
                      ],
                      UPDATE_CONTRIBUTION_PROPS
                    )
                  }
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
              <Label>{local.Email}</Label>
              <div className="container_add_input mx-auto">
                <Input
                  className="add_input"
                  placeholder={local.Email}
                  value={form.Email}
                  onChange={(e) =>
                    general(
                      [
                        {
                          prop: "data.form.Email",
                          value: e.target.value,
                        },
                      ],
                      UPDATE_CONTRIBUTION_PROPS
                    )
                  }
                />
              </div>
            </Col>
          </Row>
        </React.Fragment>
      ) : null}

      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12} style={alignTextRight}>
          <Label>{local.UploadImages}</Label>

          <Card
            className="img_card_container mx-auto"
            style={{ width: "100%" }}
          >
            <Row className="fullWidthHeight">
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="alignItem flex"
              >
                <Dropzone
                  accept="image/*"
                  multiple
                  onDrop={(acceptedFiles) =>
                    saveImage(
                      acceptedFiles,
                      "data.form.Images",
                      general,
                      MERGE_CONTRIBUTION_PROPS
                    )
                  }
                >
                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <div {...getRootProps()} className="cursor">
                      <input
                        {...getInputProps()}
                        multiple={true}
                        accept="image/*"
                        defaultValue={acceptedFiles}
                      />

                      <div className="img_conteiner_palce">
                        <img src={image_icon} alt="" className="imgFull" />
                      </div>
                    </div>
                  )}
                </Dropzone>

                {renderPhotos(
                  form.Images,
                  "data.form.Images.",
                  general,
                  DELETE_CONTRIBUTION_PROPS
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="center mt-5 mb-5">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <button
            onClick={(e) =>
              handleSave(e, addContribution, form, general, history)
            }
            className="save_btn"
          >
            {isLoadingCreate === true ? (
              <Spinner color="#fff" />
            ) : (
              <p>{local.AddPlace}</p>
            )}
          </button>
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = ({ ContribuationsR, GeneralR }) => {
  return {
    form: ContribuationsR.data.form,
    errorMessages: ContribuationsR.data.errorMessages,
    isLoadingCreate: ContribuationsR.data.isLoadingCreate,
    layersOptions: ContribuationsR.layersOptions,
    myPosition: GeneralR.myPosition,
    lightbox: GeneralR.lightbox,
  };
};

export default connect(mapStateToProps, actions)(AddContribution);
