import React, { Component } from 'react';
import { Card, Col, Row } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import Gallery from '../../General/Gallery';
import { getPoints } from '../../General/MapFunctions';
import { WorkingHoursModal } from '../../General/TableFunctions';
import DistributorProfile from './DistributorProfile';
import ProfileModal from '../../utils/ProfileModal';
import DistributorSearch from './DistributorSearch';
import WebList from './WebList';
import MobileList from './MobileList';
import { UPDATE_GENERAL_PROPS } from '../../Redux/Actions/types';
import { getUser } from '../../utils/StorageFunctions';
import TooMap from '../../General/TooMap';

class Distributors extends Component {
  componentDidMount() {
    if (getUser() === null) {
      this.props.history.push('/login');
    } else {
      this.props.general(
        [{ prop: 'rows_collabse', value: [] }],
        UPDATE_GENERAL_PROPS
      );
    }
  }
  renderMap = () => {
    const { data, location, general, zoomToShape, shapeItem } = this.props;
    if (data.items) {
      return (
        <Card className='mapCard'>
          <TooMap
            location={location}
            markers_list={getPoints(data.items, 'locationText')}
            general={general}
            zoomToShape={zoomToShape}
            shapeItem={shapeItem}
          />
          {/* <GeneralMap
            location={location}
            markers_list={getPoints(data.items, "locationText")}
            general={general}
            zoomToShape={zoomToShape}
            shapeItem={shapeItem}
          /> */}
        </Card>
      );
    }
  };
  render() {
    const {
      history,
      data,
      DeleteDistributor,
      general,
      deleteMultipleDistributors,
      fetchDistributors,
      venuesOptions,
      loadVenueOptions,
      lightbox,
      workingDaysModal,
      profileModal,
      rows_collabse,
    } = this.props;
    return (
      <div className='page-container'>
        <Gallery lightbox={lightbox} general={general} />
        <WorkingHoursModal
          workingDaysModal={workingDaysModal}
          general={general}
        />

        <ProfileModal
          profileModal={profileModal}
          workingDaysModal={workingDaysModal}
          general={general}
          title={local.BranchProfile}
          Children={DistributorProfile}
        />

        <Row>
          <Col>
            <DistributorSearch
              filterArray={data.filterArray}
              venuesOptions={venuesOptions}
              fetchDistributors={fetchDistributors}
              isLoading={data.isLoadingSearch}
              general={general}
              loadVenueOptions={loadVenueOptions}
            />
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mobile_col'>
            <MobileList
              history={history}
              data={data}
              DeleteDistributor={DeleteDistributor}
              general={general}
              deleteMultipleDistributors={deleteMultipleDistributors}
              fetchDistributors={fetchDistributors}
            />
          </Col>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ height: '100vh' }}
          >
            <div
              className='table_container'
              style={{
                width:
                  rows_collabse.length > 0
                    ? rows_collabse.find((col) => col === true)
                      ? '98%'
                      : 'fit-content'
                    : 'fit-content',
              }}
            >
              <WebList
                history={history}
                data={data}
                DeleteDistributor={DeleteDistributor}
                general={general}
                deleteMultipleDistributors={deleteMultipleDistributors}
                fetchDistributors={fetchDistributors}
                rows_collabse={rows_collabse}
              />
            </div>

            {this.renderMap()}
          </Col>
        </Row>
      </div>
    );
  }
}

const GeneralMaptateToProps = ({ DistributorsR, VenuesR, GeneralR }) => {
  return {
    data: DistributorsR.data,
    venuesOptions: VenuesR.venuesOptions,
    lightbox: GeneralR.lightbox,
    location: GeneralR.location,
    workingDaysModal: GeneralR.workingDaysModal,
    profileModal: GeneralR.profileModal,
    rows_collabse: GeneralR.rows_collabse,
    zoomToShape: GeneralR.zoomToShape,
    shapeItem: GeneralR.shapeItem,
  };
};
export default connect(GeneralMaptateToProps, actions)(Distributors);
