export const ar = {
  lang: "ar",
  Clang: "Ar",
  Settings: " الاعدادات ",
  Language2: "انجليزي",
  Home: "الرئيسية",
  Support: "الدعم الفني",
  Markting: "التسويق",
  Users: "المستخدمين",
  Places: "الاماكن",
  ThisMonth: "هذا الشهر",
  Welcome: "مرحبا",
  LastLogin: "من اخر تسجيل دخول ليك علي النظام",
  newPlace: "مكان جديد",
  GoToMap: "عرض الخريطة",
  Added: "تم اضافة",
  newUser: "مستخدم",
  Registered: "تم تسجيل",
  Admin: "المسئول",
  Map: "خرائط مابي",
  DailyDownload: "تحميل يومي",
  MonthlyDownload: "تحميل شهري",
  CurrentUsers: "عدد المستخدمين الحاليين",
  InActivePlaces: "اماكن مضافة قيد التفعيل",
  RegisterPlaces: "اماكن التسجيل",
  AddedPlaces: "اماكن مضافة",
  ActivationCodeInArraive: "لم يصل كود التفعيل",
  SuggestedPlaces: "اماكن مقترح تعديلها",
  UsersApp: "مستحدمين التطبيق",
  AddUser: "اضافة مستخدم",
  UnderReview: "اماكن تحت المراجعة",
  ManagementTeam: "فرق الادارة",
  FastAction: "اجراءات سريعة",
  MoreOneUser: "يجب ان تختار اكثر من مستخدم",
  Activation: "التفعيل",
  Stopping: "ايقاف",
  SerialNumber: "الرقم المسلسل",
  Photo: "الصورة",
  Name: "الاسم",
  Mobile: "الهاتف",
  Email: "البريد الالكتروني",
  email: "البريد الالكتروني",
  Country: "الدولة",
  HoppingTimes: "مرات التنقل",
  NofPlacesAdded: "عدد الاماكن المضافة",
  Status: "الحالة",
  Login: "تسجيل الدخول",
  UserName: "اسم المستخدم",
  Password: "الرقم السري",
  NuUser: "عدد المستخدمين",
  PlacesNumber: "عدد الاماكن",
  DailyDownloads: "تحميل يومى",
  MonthlyDownloads: "تحميل شهرى",
  SupportRequest: "طلب دعم",
  DashboardUsers: "مستخدمين النظام",
  Restaurants: "مطاعم",
  TotalPlaces: "اجمالي الاماكن",
  TotalUsers: "اجمالي المستخدمين",
  MostShared: "الاكثر مشاركة علي التطبيق",
  PlacesReview: "الأماكن منتظرة المراجعة",
  PeopleSupport: "دعم الأفراد",
  Reply: "الرد",
  CompanySupport: "دعم الشركات",
  CompanyAccount: "حسابات الشركة",
  Newlyjoined: "المنضم حديثا",
  TypeHere: "اكتب هنا",
  Tickets: "الشكاوي",
  RecentChats: "الدردشات الأخيرة",
  CreateTicket: "اضافة شكوي",
  Archived: "المورشف",
  Active: "مفعل",
  InActive: "غير مفعل",
  BusinessAccount: "الحساب التجاري",
  RegisteredBranches: "الفروع المسجلة",
  RegisteredDistributors: "الموزعين المسجلين",
  BranchName: "اسم الفرع",
  ShareLink: "مشاركةرابط",
  ShowOnMap: "عرض علي الخريطة",
  ShareBulkLink: "مشاركة رابط",
  AddBranch: "اضافة فرع",
  BranchesGroup: "مجموعة الفروع",
  AddBranchGroup: "إضافة مجموعة فروع",
  BranchId: "رقم الفرع",
  Address: "العنوان",
  LocateMap: "تحديد علي الخريطة",
  Save: "حفظ",
  CategorySearch: "البحث في الفئة",
  Resturant: "مطعم",
  Cafe: "كافيه",
  SuperMarket: "سوبرماركت",
  Pharmacy: "صيدلية",
  CustomerReviews: "تقييمات العملاء",
  OpenSupport: "فتح طلب دعم",
  LatestSupport: "أخر طلب دعم",
  AddNew: "اضافة جديد",
  Explore: "استشكف",
  Direction: "اتجاهات",
  RECENTSEARCH: "البحث الاخير",
  CLEAR: "حذف",
  PLACENAME: "اسم المكان",
  CITYNAME: "اسم المدينة",
  Violations: "البلاغات",
  Location: "الموقع",
  UserNote: "ملاحظات المستخدم",
  Time: "الوقت",
  User: "المستخدم",
  SlowTraffic: "ازدحام مروري",
  SpeedRadar: "ردار السرعة",
  Accident: "حادثة",
  BrokenVehicle: "تصادم سيارات",
  Closed: "طريق مغلق",
  WorkingArea: "منطقة العمل",
  Danger: "خطر",
  MAPCHAT: "ارسال رسالة",
  ObjectRoad: "عائق علي الطريق",
  HELP: "طلب المساعدة",
  MAPError: "اتجاه خاطي",
  Language: "اللغة",
  WITHFACEBOOK: "الدخول عن طريق الفيس بوك",
  WITHGOOGLE: "الدخول عن طريق جيميل",
  MOBILESIGNUP: "أو الاشتراك عبر الموبايل",
  MOBILESIGNIN: "أو الدخول عبر الموبايل",
  SIGNFROM: "أو سجل الدخول من",
  SIGNFROM1: "سجل الدخول من",
  SIGNUPFROM: "أواشترك من",
  SIGNUPFROM1: "اشترك من",
  HERE: "هنا",
  UserDenied: "رفض المستخدم طلب تحديد الموقع الجغرافي",
  UnavailableLoc: "معلومات الموقع غير متوفرة",
  LocationTimedOut: "انتهت مهلة طلب الحصول على موقع المستخدم",
  UnknownError: "حدث خطأ غير معروف",
  LoadMore: "تحميل المزيد",
  ConfirmPassword: "تأكيد كلمة المرور",
  SignUp: "اشتراك",
  SignIn: "دخول",
  Arabic: "عربي",
  English: "انجليزي",
  AccountCreated: "تم انشاء الحساب بنجاح",
  Menu: "القائمة",
  City: "المدينة",
  EnterUserNamePassword: "من فضلك ادخل اسم المستخدم وكلمة المرور",
  EnterUserName: "من فضلك ادخل اسم المستخدم",
  EnterPassword: "من فضلك ادخل كلمة المرور",
  CreatedIn: "تاريخ الاضافة",
  Logout: "تسجيل الخروج",
  FirstName: "الاسم الاول",
  LastName: "الاسم الاخير",
  BirthDay: "عيد الميلاد",
  Gender: "النوع",
  Male: "ذكر",
  Female: "انثي",
  DataRequired: "ادخل جميع المعلومات",
  ValidEmail: "ادخل بريد اللكتروني صحيح",
  PasswordMatched: "تاكيد كلمة المرور لا تساوي كلمة المرور",
  PhoneNumberLength: "رقم الموبايل يجب ان يساوي 12 رقم",
  SomethingError: "شي ما خطا",
  PasswordValidate: "يجب أن تتكون كلمة المرور الخاصة بك من 8 ارقام على الأقل",
  MobileNumberCode: "ادخل الرمز المرسل الي رقم الموبايل",
  EmailCode: "ادخل الرمز المرسل الي البريد الالكتروني",
  Code: "الرمز",
  EnterEmail: "ادخل البريد الالكتروني",
  EnterPhoneNumber: "ادخل رقم الموبايل",
  SkipNow: "تخطي الان",
  EnterEmaiOrPhone: "ادخل البريد الالكتروني او رقم التليفون",
  ResetYourPassword: "إعادة تعيين كلمة المرور",
  SelectImage: "اختار الصورة",
  Venues: "الشركات",
  AddVenue: "اضافة شركة",
  EditVenue: "تعديل بيانات الشركة",
  Id: "الرقم",
  Image: "الصورة",
  Roles: "المسؤليات",
  AddRole: "اضافة مسؤلية",
  Description: "الوصف",
  Permissions: "الصلاحيات",
  ArabicName: "الاسم باللغة العربية",
  Claims: "صلاحيات",
  AddClaim: "اضافة صلاحية",
  AddPlace: "اضافة مكان",
  OpenningHours: "ساعات العمل",
  Category: "الفئة",
  Done: "حفظ",
  Monday: "الاثنين",
  Tuesday: "الثلاثاء",
  Wednesday: "الاربعاء",
  Thursday: "الخميس",
  Friday: "الجمعة",
  Saturday: "السبت",
  Sunday: "الاحد",
  AddDateTime: "اضافة تاريخ",
  From: "من",
  To: "الي",
  VerifiyByPhone: "حسابك غير مفعل",
  ConfirmAccount: "تفعيل الحساب",
  ResendCode: "اعادة ارساال الكود",
  SmsSent: "تم اعادة ارسال الكود بنجاح",
  ConfirmedSuccessfully: "تم تفعيل الحساب بنجاح",
  AddAnother: "اضافةاخر",
  AddComplaintType: "اضافة نوع بلاغ",
  ComplaintType: "نوع البلاغ",
  AddComplaint: "اضافة بلاغ",
  FullNameRequired: "ادخل الاسم باللغة العربية والانجليزية",
  AddedSuccessfully: "تم الاضافة بنجاح",
  SuccessfullyUpdated: "تم التعديل بنجاح",
  NoName: "بدون اسم",
  DeletedSuccessfully: "تم الحذف بنجاح",
  Images: "الصور",
  Layer: "التصنيف",
  Categories: "الفئات",
  PlaceStatus: "حالة المكان",
  Pedning: "معلق",
  Approved: "تم الموافقة عليه",
  NotApproved: "لم يتم تم الموافقة عليه",
  Edit: "تعديل",
  Delete: "حذف",
  View: "عرض",
  Day: "اليوم",
  Hide: "اخفاء",
  Accept: "قبول",
  Reject: "رفض",
  PlaceAdded: "تمت الموافقة علي اضافة المكان",
  PlaceRejected: "تم رفض اضافة المكان",
  EnglishName: "الاسم باللغة الانجليزية",
  DataRequiredValidation: "ادخل البيانات المطلوبة",
  Branches: "فروع الشركات",
  BranchType: "نوع الفرع",
  Branch: "فرع",
  Shop: "موزع",
  UpdateBranch: "تعديل بيانات الفرع",
  AddTicket: "اضافة شكوي",
  Title: "العنوان",
  ApprovedPlace: "تم الموافقة علي اضافة المكان",
  RefusedSuccessfully: "تم رفض اضافة المكان",
  FullName: "الاسم بالكامل",
  JoinDate: "تاريخ الانضمام",
  Activated: "مفعل",
  NotActivated: "غير مفعل",
  Deactivate: "الغاء التفعيل",
  AccountDeactivated: "تم الغاء تفعيل الحساب",
  Sector: "المستفيد",
  Business: "ادارة اعمال",
  Gis: "ادارة نظم المعلومات الجغرافية",
  AddedBy: "اضافة بواسطة",
  TicketBusiness: "هذه الشكوي بنظام الاعمال",
  TicketGis: "هذه الشكوي خاصة بنظام الملاحة العالمية",
  TicketMobile: "هذه الشكوي خاصة بنظام تطبيقات الموبايل",
  EditTicket: "تعديل بيانات الشكوي",
  claimType: "نوع الاذن",
  RolesClaims: "اذونات المسؤليات",
  AddRoleClaim: "اعطاء اذن لمسؤلية",
  UsersClaims: "اذونات المستخدمين",
  AddUserClaim: "اعطاء اذن للمستخدم",
  UsersRoles: "مسؤليات المستخدمين",
  AddRoleUser: "اعطاء مسؤلية للمستخدم",
  Responsibilities: "المسؤليات",
  Search: "بحث",
  ChooseUser: "اختار المستخدم اولا",
  ChooseRole: "اختار المسؤلية اولا",
  EditPlace: "تعديل بيانات المكان",
  ChooseList: "اختار من القائمة",
  InActiveUsers: "المستخدمين غير النشطاء",
  Hoispital: "مستشفي",
  Bank: "بنك",
  Groceries: "سوبر ماركت",
  Reviews: "التقييمات",
  NoReviewsFound: "لا توجد مراجعات",
  Loading: "جاري التحميل",
  SeaarchValueRequired: "ادخل كلمة البحث",
  All: "الكل",
  AllTimes: "كل الأوقات",
  CurrentMonth: "الشهر الحالي",
  Last15Days: "اخر 15 يوما",
  PreviousMonth: "الشهر السابق",
  ValidateFileLength: "حجم الملف يجب ان يكون اقل من او يساوي 10 ميجا",
  ValidateFileLength2: "حجم الملف يجب ان يكون اقل من او يساوي 1 ميجا",
  EditTicketMessage: "تعديل الرسالة",
  Audio: "ملف صوتي",
  Document: "مستند",
  Video: "ملف فديو",
  VideoLink: "رابط الفديو",
  InvalidLink: "ادخل رابط صحيح",
  Files: "ملفات",
  Record: "تسجيل",
  DeleteAllMessages: "مسح جميع الرسائل",

  //start claims
  GeoEvent: "البلاغات",
  GeoEvent_Get: "عرض",
  ReviewsBusiness: "تقييمات الاماكن",
  Contribution_Delete: "حذف",
  Contribution_Create: "اضافة",
  Contribution_Update: "تعديل",
  Contribution_Confirm: "قبول/رفض المكان",
  GeoEventType_Delete: "حذف",
  GeoEventType_Create: "اضافة",
  GeoEventType_Update: "تعديل",
  ReviewsBusiness_Delete: "حذف",
  ReviewsBusiness_Create: "اضافة",
  ReviewsBusiness_Update: "تعديل",
  Branches_Create: "اضافة",
  Branches_Update: "تعديل",
  Branches_Delete: "حذف",
  Venues_Delete: "حذف",
  Venues_Create: "اضافة",
  Venues_Update: "تعديل",

  Ticket: "الشكوي",
  Ticket_Create: "اضافة",
  Ticket_Delete: "حذف",
  Ticket_Update: "تعديل",
  TicketMessage_Create: "اضافة",
  TicketMessage_Delete: "حذف",
  TicketMessage_Update: "تعديل",
  Contribution_Get: "عرض",
  Contribution: "الاماكن",
  Venues_Get: "عرض",
  Branches_Get: "عرض",
  Ticket_Get: "عرض",
  GeoEventType_Get: "عرض",
  GeoEventType: "انواع البلاغات",
  UserBusiness: "المستخدمين",
  UserBusiness_ActivateUser: "تنشيط",
  UserBusiness_DeActivateUser: "حظر",
  UserBusiness_GetUsers: "عرض",
  TicketMessage_GetTicketMessages: "عرض",
  TicketMessage: "رسائل الدعم الفني",
  RoleBusiness_GetRoles: "عرض",
  RoleBusiness_GetRole: "عرض",
  RoleBusiness_Create: "اضافة",
  RoleClaims: "اذونات المسؤليات",
  RoleBusiness_AttachRoleClaim: "اضافة",
  RoleBusiness_GetUserRoles: "عرض",
  UserRole: "مسؤليات المستخدمين",
  RoleBusiness_AttachUserRole: "اضافة",
  RoleBusiness_GetUserClaims: "عرض",
  UserClaims: "اذونات المستخدمين",
  RoleBusiness_AttachUserClaim: "اضافة",
  RoleBusiness_Delete: "حذف",
  Branches_GetById: "الملف الشخصي",
  RoleBusiness_UpdateRole: "تعديل",
  RoleBusiness_DetachRoleClaim: "حذف",
  RoleBusiness_DetachUserClaim: "حذف",
  RoleBusiness_DetachUserRole: "حذف",
  Venues_GetById: "الملف الشخصي",
  DoNotHavePermission: "ليس لديك اذن للوصول لهذه المعلومات",

  //end claims

  NoMessageAvaliable: "لا يوجد رسائل متاحة",
  NoControlPanel: "ليس لديك اي اذونات لتستخدم خدمات لوحة التحكم",
  AnyDataRequired: "أدخل بيانات البحث",
  NoSearch: "لم يتم العثور على نتائج البحث",
  Date: "التاريخ",
  TicketClosed: "تم إغلاق الشكوي بنجاح",
  Close: "اغلاق",
  PolygonInsideError: "لا يمكن إضافة مضلع داخل مضلع آخر",
  ChoosePlace: "اختار المكان",
  ContinueAdding: "الاستمرار كإضافة مكان جديد",
  "96- restaurant": "مطاعم",
  bank: "بنوك",
  "56- food": "متاجر البقالة",
  hospital: "مستشفيات",
  cafe: "المقاهي",
  CopyLink: "انسخ الرابط",
  Shopping: "التسوق",
  EditBranch: "تعديل بيانات الفرع",
  Sure: "هل أنت متأكد",
  No: "لا",
  Yes: "نعم",
  ActivateUser: "انك تريد تفعيل هذا المستخدم",
  DeactivateUser: "انك تريد الغاء تفعيل هذا المستخدم",
  ConfirmEdit: "انك تريد تعديل هذا العنصر",
  ConfirmDelete: "تريد حذف العناصر المحددة",
  Distributors: "الموزعين",
  CreateDistributor: "اضافة موزع",
  EditDistributor: "تعديل بيانات الموزع",
  ConfirmAcceptPlace: "هل انت متاكد انك تريد قبول هذا المكان",
  ConfirmRejectPlace: "هل انت متاكد انك تريد رفض هذا المكان",
  Distributor: "موزع",
  OK: "موافق",
  EditRole: "تعديل بيانات المسؤلية",
  UpdateComplaintType: "تعديل نوع البلاغ",
  ViewVenues: "عرض الشركات",
  ViewDistributors: "عرض الموزعين",
  ViewBranches: "عرض فروع الشركات",
  ViewPlaces: "عرض الاماكن",
  ViewRoles: "عرض المسؤليات",
  UserProfile: "الملف الشخصي للمستخدم",
  VenueProfile: "الملف الشخصي للشركة",
  BranchProfile: "الملف الشخصي للفرع",
  DistributorProfile: "الملف الشخصي للموزع",
  PlaceProfile: "الملف الشخصي للمكان",
  RoleProfile: "الملف الشخصي للمسئولية",
  RoleClaimProfile: "الملف الشخصي لاذونات المسؤليات",
  UserClaimProfile: "الملف الشخصي لاذونات المستخدمين",
  UserRoleProfile: "الملف الشخصي لمسؤليات المستخدمين",
  ComplaintProfile: "الملف الشخصي للشكاوي",
  facebook: "الفيس بوك",
  whatsapp: "الواتس اب",
  instgram: "الانستجرام",
  snapshat: "سناب شات",
  wheelchair: "الويل شير",
  Role: "المسؤلية",
  EnglishLang: "مسموح اللغةالانجليزية فقط",
  ArabicLang: "مسموح اللغة العربية فقط",
  restaurant: "مطاعم",
  shopping_mall: "تسوق",
  Type: "النوع",
  Facebook: "الفيس بوك",
  Instgram: "الانستجرام",
  Twitter: "تويتر",
  Snapshat: "سناب شات",
  ChooseAll: "اختر الكل",
  AddSocial: "اضافة وسائل التواصل",
  PhonePasswordRequired: "رقم الموبايل او الرقم السري غير موجود",
  ActiveUsers: "مستخدم فعال",
  MarkedLocation: "موقع مميز",
  ADDPLACE: "اضافة مكان",
  EDITPLACE: "تعديل المكان",
  Directions: "اتجاهات",
  DarkMode: "الوضع المظلم",
  LightMode: "الوضع العادي",
  FollowSystem: "اتبع النظام",
  DistanceUnits: "وحدة المسافة",
  Metrics: "المقياس",
  MyContributions: "اشتراكاتي",
  SavedPalces: "الأماكن المحفوظة",
  Share: "مشاركة",
  About: "عنا",
  Help: "مساعدة",
  MyLocation: "موقعي",
  Destination: "الواجهة",
  More: "المزيد...",
  YourLocation: "موقعك",
  EndLocation: "موقع النهاية",
  START: "بداية",
  AddWork: "اضافة عمل",
  SelectOnMap: "اختار من الخريطة",
  Work: "العمل",
  ADDTO: "اضافة الي",
  ChooseDestination: "اختر وجهتك",
  NoConnection: "لا يوجد اتصال بالإنترنت",
  NoConnectionDesc:
    "اتصال الإنترنت الخاص بك غير متوفر حاليًا ، يرجى التحقق أو المحاولة مرة أخرى.",
  TryAgain: "حاول مجددا",
  NoLocation: "لا يوجد موقع مسموح به",
  NoLocationDesc: "يجب ان تسمح للمتصفح بتحديد موقعك",
  SearchValueRequired: "ادخل كلمة البحث",
  SamePointValidation:
    "نقطة البداية ونقطة الوصول في نفس المكان برجاء تغيير اي منهم",
  SearchResult: "نتائج البحث",
  Pharmacies: "صيدليات",
  ATMs: "صرافة",
  Gas: "محطة بنزين",
  CarServices: "تصليح سيارات",
  DoneCopy: "تم النسخ بنجاح",
  CheckEmail: "لقد تم ارسال رابط التفعيل الي بريدك الالكتروني",
  activateAccount: "انتظر... جاري تنشيط حسابك",
  PhoneNumberRequired: "ادخل رقم التليفون",
  HomeLocation: "عنوان المنزل",
  AddHome: "اضافة موقع المنزل",
  AddName: "اضافة اسم",
  AddNewPlace: "اضافة مكان جديد",
  WebSite: "الموقع الالكتروني",
  OpeningHours: "الاوقات المتاحة",
  UploadImages: "الصور",
  SavedPlaces: "الاماكن المحفوظة",
  FavoriteList: "القائمة المفضلة",
  WantToGo: "اريد الذهاب",
  AddToList: "أضف إلى القائمة",
  AddAnotherList: "اضف قائمة اخري",
  SelectType: "اختار نوع المكان",
  PlacesContribtion: "تساعد أماكنك الجميع في التنقل بسهولة",
  Opinions: "آراؤك تساعد الآخرين على تحديد وجهتهم",
  ReplacedUserName: "الموبايل",
  subLang: "ar",
  RegisteredPlaces: "الاماكن المسجلة",
  UnRegisteredPlaces: "الاماكن الغير المسجلة",
  ForgetPassword: "نسيت كلمة المرور",
  Remove: "حذف",
  NumberTransfers: "عدد التحويلات",
  users: "المستخدمين",
  ReviewPlaces: "مراجعة الأماكن",
  ComplaintsSystem: "نظام الشكاوي",
  RatingsReview: "مراجعة التقييمات",
  EnterLink: "ادخل الرابط",
  NotUrl: "يجب ادخال رابط موقع صحيح",
  useDefault: "اضافة بواسطة الافتراضي",
  useCustom: "اضافة غير افتراضي",
  Dashboard: "لوحة التحكم",
  WhatsApp: "الواتس اب",
  ImageRequired: "الصورة مطلوبة",
  SelectCompany: "اختار الشركة",
  TokenExpired: "لقد تم انتهاء صلاحية دخولك,من فضلك قم بتسجيل الدخول مرة اخري",
  NameEnRequired: "الاسم باللغة الانجليزية مطلوب",
  NameArRequired: "الاسم باللغة العربية مطلوب",
  LocationRequired: "الموقع مطلوب",
  NameEnORArRequired: "الاسم باللغة العربية او الانجليزية مطلوب",
  SelectLayer: "اختار الفئة",
  NameRequired: "الاسم مطلوب",
  DescriptionRequired: "الوصف مطلوب",
  DescriptionRequired: "الوصف مطلوب",
  RoleRequired: "اختار المسؤلية",
  ClaimRequired: "يجب ان تختار الاذونات",
  SelectComplaintType: "اختار نوع البلاغ",
  SelectUser: "اختار المستخدم",
  TitleRequired: "العنوان مطلوب",
  SelectSector: "اختار النوع",
  Year: "العام",
  Month: "الشهر",
  Hour: "الساعة",
  Minute: "دقيقة",
  Second: "ثانية",
  UsersCounts: "عدد المستخدمين",
  Km: "كيلومتر",
  DetachRoleClaim: "اذونات المسؤليات",
  AttachRoleClaim: "اذونات المسؤليات",
  DetachUserClaim: "اذونات المستخدمين",
  UpdateRole: "المسؤليات",
  AttachUserClaim: "اذونات المستخدمين",
  GetUserClaims: "اذونات المستخدمين",
  RoleBusinessDelete: "حذف المسؤلية",
  DetachUserRole: "مسؤليات المستخدمين",
  AttachUserRole: "مسؤليات المستخدمين",
  GetUserRole: "مسؤليات المستخدمين",
  GetRoles: "المسؤليات",
  GetRole: "المسؤليات",
  RoleBusinessCreate: "اضافة مسؤلية",
  Note: "ملاحظات",
  Mile: "ميل",
  BusinessAnalysis: "احصائيات الحساب التجاري",
  Branches2: "الفروع",
  NoClaims: "لا يوجد اذونات لهذه المسؤلية",
  ActivationCode: "كود التفعيل",
  PhoneEmailRequired: "رقم الموبايل او البريد الالكتروني مطلوب",
  CodeSentSuccessfully: "تم ارسال الكود بنجاح",
  CodeRequired: "ادخل كود التفعيل",
  ConfirmPasswordRequired: "تاكيد كلمة المرور مطلوبة",
  ConfirmationCode: "كود التفعيل",
  PasswordReset: "تم اعادة تعيين الرقم السري بنجاح",
  ActiveByMobile: "التفعيل بواسطة الموبايل",
  ActiveByEmail: "التفعيل بواسطة البريد الالكتروني",
  InActiveByMobile: "غير المفعل بواسطة الموبايل",
  InActiveByEmail: "غير المفعل بواسطة البريد الالكتروني",
  LoginByMobile: "الدخول بواسطة الموبايل",
  LoginByEmail: "الدخول بواسطة البريد الالكتروني",
  EditEmailCode: "تعديل البريد الالكتروني او قم بارسال الكود",
  EditEmail: "تعديل البريد الالكتروني",
  SendCode: "ارسال الكود",
  NumberofActiveUsersEmail: "المفعلين بواسطة البريد الالكتروني",
  NumberofActiveUsersPhone: "المفعلين بواسطة الموبايل",
  NumberofInActiveUsersEmail: "الغير مفعلين بواسطة البريد الالكتروني",
  NumberofInActiveUsersPhone: "الغير مفعلين بواسطة الموبايل",
  Total: "المجموع",
  CountryCode: "كود الدولة مثال ( eg )",
  Profile: "الملف الشخصي",
  ActivationExpire: "كود التفعيل سوف ينتهي في غضون ",
  EditData: "تعديل البيانات",
  ByMobile: "بواسطة الموبايل",
  ByEmail: "بواسطة البريد الالكتروني",
  ThisPermission: "لا يمكن تكرر هذا الاذن ",
  cannotbeRepeated: "مع المستخدم ",
  FirstNameLength: "يجب أن يكون طول الاسم الأول مساويًا أو أكبر من 3",
  LastNameLength: "يجب أن يكون طول الاسم الاخير مساويًا أو أكبر من 3",
  FirstAndLastNameLength:
    "يجب أن يكون طول الاسم الاول و الاخير مساويًا أو أكبر من 3",
  ExpiredCode: "رمز غير صالح أو منتهي الصلاحية",
  "role has users attached to it":
    "لا يمكن حذف هذه المسئولية لانها مرتبطة بمستخدم",
  Rating: "التقيمم",
  Options: "الخيارات",
  Add: "اضافة",
  Guests: "مستخدم غير مسجل",
  TotalDownloads: "اجمالي التحميلات",
  //countries
  eg: "مصر",
  Eg: "مصر",
  EG: "مصر",
  Egypt: "مصر",
  sa: "السعودية",
  Sa: "السعودية",
  SA: "السعودية",
  lb: "لبنان",
  LB: "لبنان",
  Lb: "لبنان",
  jo: "الأردن",
  Jo: "الأردن",
  JO: "الأردن",
  us: "الولايات المتحدة",
  Us: "الولايات المتحدة",
  US: "الولايات المتحدة",
};
