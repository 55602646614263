import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style/generalStyle.css";
import "./assets/style/mobile.css";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Register from "./Pages/Register/Register";
import Login from "./Pages/Login/Login";
import { changeLanguage } from "./lang/local";
// import ConfirmSms from './pages/Auth/ConfirmSms/ConfirmSms';
import WebView from "./Pages/MapComponents/MapView/WebView";
import ForgetPassword from "./Pages/Auth/ForgetPassword";
import ResetPassword from "./Pages/Auth/ResetPassword";
import ConfirmEmail from "./Pages/Auth/ConfirmEmail/ConfirmEmail";
import UpdateEmail from "./Pages/Auth/UpdateEmail";
import AuthenticatedRoute from "./routing/AuthenticatedRoute";
import Layout from "./routing/Layout/Layout";

/* This is for Redux Development Tool */

class App extends Component {
  constructor(props) {
    super(props);
    changeLanguage();
  }
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/login"
          name="Login"
          render={(props) => <Login {...props} />}
        />

        <Route
          exact
          path="/register"
          name="Register"
          render={(props) => <Register {...props} />}
        />
        <Route
          exact
          path="/confirm_email"
          name="ConfirmEmail"
          render={(props) => <ConfirmEmail {...props} />}
        />

        <Route
          exact
          path="/forget-password"
          name="ForgetPassword"
          render={(props) => <ForgetPassword {...props} />}
        />
        <Route
          exact
          path="/reset-password"
          name="ResetPassword"
          render={(props) => <ResetPassword {...props} />}
        />

        <Route
          exact
          path="/update-email"
          name="UpdateEmail"
          render={(props) => <UpdateEmail {...props} />}
        />
        <AuthenticatedRoute path="/dashboard" component={Layout} />

        <Route
          // exact
          path="/"
          name="WebView"
          render={(props) => <WebView {...props} />}
        />

        {/* <Route
          exact
          path='/confirm_sms'
          name='ConfirmSms'
          render={(props) => <ConfirmSms {...props} />}
        /> */}
      </Switch>
    );
  }
}

export default App;
