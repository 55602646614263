import {
  showConfirmMessage,
  showSuccessedConfirm,
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { SendRequest } from "./api";
import { DELETE_REVIEWS_PROPS, UPDATE_REVIEWS_PROPS } from "./types";
import { getUser } from '../../utils/StorageFunctions';


export const fetchReviews = (filterArray, page) => (dispatch) => {
  if (
    filterArray.length > 0 &&
    filterArray[0].value.find((v) => v !== getUser().data.id)
  ) {
    dispatch({
      type: UPDATE_REVIEWS_PROPS,
      payload: [{ prop: "data.isLoadingSearch", value: true }],
    });
  }
  dispatch({
    type: UPDATE_REVIEWS_PROPS,
    payload: [{ prop: "data.isLoading", value: true }],
  });
  SendRequest()
    .post(`/Review/Get?page=${page}`, filterArray)
    .then((res) => {
      if (res.status === 200) {
        if (
          res.data.data.items.length === 0 &&
          filterArray[0].value.find((v) => v !== getUser().data.id)
        ) {
          showConfirmMessage(local.NoSearch, "error");
        }
        let pagination = Object.assign({}, res.data.data);
        delete pagination.items;
        dispatch({
          type: UPDATE_REVIEWS_PROPS,
          payload: [
            { prop: "data.items", value: res.data.data.items },
            { prop: "data.pagination", value: pagination },
          ],
        });
      }

      dispatch({
        type: UPDATE_REVIEWS_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_REVIEWS_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const deleteReview = (id, index) => (dispatch) => {
  SendRequest()
    .delete(`/Review/${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_REVIEWS_PROPS,
          payload: [{ prop: `data.items.${index}` }],
        });
        showSuccessedConfirm(local.DeletedSuccessfully);
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
