import React, { Component } from 'react';
import { Col, Row, Card, Input, Form, Spinner } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { getLanguage, local } from '../../lang/local';
import strik from '../../assets/img/strik.png';
import {
  DELETE_ROLES_CLAIMS_PROPS,
  MERGE_ROLES_CLAIMS_PROPS,
  UPDATE_ROLES_CLAIMS_PROPS,
  CLEAR_GENERAL_STATE,
  UPDATE_ROLES_PROPS,
} from '../../Redux/Actions/types';
// import GeneralMap from "../../General/General/Map";
import {
  handleSave,
  onChangeComplaintType,
  onChangeSelectAll,
  onMapClick,
} from './RolesClaimsFunctions';
import { isRtl, selectFormStyle } from '../../General/GeneralStyle';
import { getDefaultValue, promiseOptions } from '../../General/SelectFunctions';
import AsyncSelect from 'react-select/async';
import {
  renderClaims,
  renderErrorMessage,
} from '../../General/GeneralFunctions';
import { getUser } from '../../utils/StorageFunctions';
// import SearchBox from "../../General/MapSearch/SearchBox";
import TooMap from '../../General/TooMap';
import { AsyncPaginate } from 'react-select-async-paginate';

class CreateRoleClaim extends Component {
  componentDidMount() {
    if (getUser() === null) {
      this.props.history.push('/login');
    } else {
      this.props.getClaimsTypeForDropDown();
      this.props.getRolesForDropDown();
      this.props.getComplaintTypeForDropDown([], 1);
    }
    this.props.general([], CLEAR_GENERAL_STATE);
  }
  handleRoleSelect = (e) => {
    const { general } = this.props;
    if (e) {
      general(
        [
          {
            prop: 'data.roleId',
            value: e.value,
          },
        ],
        UPDATE_ROLES_CLAIMS_PROPS
      );
    } else {
      general(
        [
          {
            prop: 'data.claims',
            value: [],
          },
        ],
        UPDATE_ROLES_PROPS
      );
      general(
        [
          {
            prop: 'data.roleId',
            value: null,
          },
        ],
        UPDATE_ROLES_CLAIMS_PROPS
      );
    }
  };
  render() {
    const {
      history,
      form,
      isLoadingCreate,
      general,
      location,
      addRoleClaim,
      rolesOptions,
      roleId,
      claimTypesOptions,
      permissions,
      loadComplaintOptions,
      mapSearch,
      // myPosition,
      // ipAddress,
      // getSocketResult,
      errorMessages,
      ComplaintTypesOptions,
    } = this.props;
    return (
      <div className='page-container'>
        <Row>
          <Col
            xl={8}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className='mx-auto text-center'
          >
            <h3 className='page_create_title'>{local.AddRoleClaim}</h3>
            <Card className='create_card'>
              <Form>
                <Row>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className='mt-3 mx-auto'
                  >
                    <div className='container_add_input mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <Input
                        className='add_input'
                        value={form.claimValue}
                        onChange={(e) =>
                          general(
                            [
                              {
                                prop: 'data.form.claimValue',
                                value: e.target.value,
                              },
                            ],
                            UPDATE_ROLES_CLAIMS_PROPS
                          )
                        }
                        placeholder={local.Name}
                      />
                    </div>
                    {renderErrorMessage(form.claimValue, errorMessages.Name)}
                  </Col>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className='mt-3 mx-auto'
                  >
                    <div className='container_add_input mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <div className='form_select_container'>
                        <AsyncSelect
                          key={roleId}
                          placeholder={local.Roles}
                          value={getDefaultValue(roleId, rolesOptions)}
                          defaultOptions={rolesOptions}
                          cacheOptions
                          loadOptions={() => promiseOptions(rolesOptions)}
                          onChange={this.handleRoleSelect}
                          isClearable={true}
                          isRtl={isRtl()}
                          styles={selectFormStyle}
                          isSearchable={true}
                        />
                      </div>
                    </div>

                    {renderErrorMessage(roleId, errorMessages.Role)}
                  </Col>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className='mt-3 mx-auto'
                  >
                    <div className='container_add_input mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <Input
                        className='add_input'
                        value={form.Description}
                        onChange={(e) =>
                          general(
                            [
                              {
                                prop: 'data.form.Description',
                                value: e.target.value,
                              },
                            ],
                            UPDATE_ROLES_CLAIMS_PROPS
                          )
                        }
                        placeholder={local.Description}
                      />
                    </div>

                    {renderErrorMessage(
                      form.Description,
                      errorMessages.Description
                    )}
                  </Col>

                  {permissions ? (
                    permissions.find((per) => per === 'GeoEventType_Get') &&
                    form.claimValue1 !== undefined ? (
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className='mt-3 mx-auto'
                      >
                        <div className='container_add_input mx-auto'>
                          <img src={strik} alt='strik' className='strik_icon' />
                          <div className='form_select_container'>
                            <AsyncPaginate
                              key={form.claimValue1}
                              placeholder={local.ComplaintType}
                              value={form.claimValue1}
                              loadOptions={loadComplaintOptions}
                              onChange={(e) =>
                                onChangeComplaintType(
                                  e,
                                  general,
                                  UPDATE_ROLES_CLAIMS_PROPS
                                )
                              }
                              additional={{
                                page: 1,
                              }}
                              styles={selectFormStyle}
                              isClearable={true}
                              isSearchable={true}
                              isMulti={true}
                            />
                          </div>
                        </div>
                        {form.claimValue1.length === 0 &&
                        ComplaintTypesOptions.length > 0 &&
                        errorMessages.ComplaintType ? (
                          <span className='error_message'>
                            {errorMessages.ComplaintType}
                          </span>
                        ) : null}
                      </Col>
                    ) : null
                  ) : null}
                </Row>

                {claimTypesOptions && claimTypesOptions.length > 0 ? (
                  <Row className='alignItem'>
                    <Col xl={12} className='mx-auto'>
                      <Row>
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className='mx-auto mt-3 center'
                        >
                          <div>
                            <div className='round ml-3 mr-3'>
                              <input
                                type='checkbox'
                                id='all'
                                checked={
                                  permissions.length ===
                                    claimTypesOptions.length &&
                                  permissions.find((d) => d !== null)
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  onChangeSelectAll(
                                    e,
                                    claimTypesOptions,
                                    general,
                                    UPDATE_ROLES_CLAIMS_PROPS,
                                    DELETE_ROLES_CLAIMS_PROPS
                                  )
                                }
                              />
                              <label htmlFor='all'></label>
                            </div>

                            <p className='mt-2'>{local.ChooseAll}</p>
                          </div>
                        </Col>
                      </Row>

                      {renderClaims(
                        claimTypesOptions,
                        permissions,
                        form,
                        general,
                        UPDATE_ROLES_CLAIMS_PROPS,
                        DELETE_ROLES_CLAIMS_PROPS,
                        MERGE_ROLES_CLAIMS_PROPS
                      )}
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className='mt-5 mx-auto'
                  >
                    <Card
                      className='mapCard'
                      style={{
                        height: '490px',
                        width: '100%',
                      }}
                    >
                      {/* <SearchBox
                              mapSearch={mapSearch}
                              general={general}
                              action={UPDATE_ROLES_CLAIMS_PROPS}
                              propObj={[
                                { prop: "data.form.geometry", value: [] },
                              ]}
                              myPosition={myPosition}
                              ipAddress={ipAddress}
                              getSocketResult={getSocketResult}
                              updateBounds={true}
                            /> */}
                      <TooMap
                        location={location}
                        onMapClick={onMapClick}
                        general={general}
                        point={null}
                        IsDrawing={true}
                        polygon_prop={form.geometry}
                        mapSearch={mapSearch}
                      />
                      {/* <GeneralMap
                              location={location}
                              onMapClick={onMapClick}
                              general={general}
                              point={null}
                              IsDrawing={true}
                              polygon_prop={form.geometry}
                              mapSearch={mapSearch}
                            /> */}
                    </Card>
                    {form.geometry.length === 0 && errorMessages.Location ? (
                      <span className='error_message'>
                        {errorMessages.Location}
                      </span>
                    ) : null}
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className='mt-5 mx-auto'
                  >
                    <button
                      className='save_btn'
                      onClick={(e) =>
                        handleSave(
                          e,
                          addRoleClaim,
                          form,
                          general,
                          roleId,
                          permissions,
                          ComplaintTypesOptions
                        )
                      }
                    >
                      {isLoadingCreate === true ? <Spinner /> : local.Save}
                    </button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const GeneralMaptateToProps = ({
  RolesClaimsR,
  GeneralR,
  RolesR,
  ComplaintTypeR,
}) => {
  return {
    form: RolesClaimsR.data.form,
    errorMessages: RolesClaimsR.data.errorMessages,
    permissions: RolesClaimsR.data.permissions,
    claimTypesOptions: RolesClaimsR.claimTypesOptions,
    roleId: RolesClaimsR.data.roleId,
    isLoadingCreate: RolesClaimsR.isLoadingCreate,
    location: GeneralR.location,
    rolesOptions: RolesR.rolesOptions,
    ComplaintTypesOptions: ComplaintTypeR.ComplaintTypesOptions,
    mapSearch: GeneralR.mapSearch,
    myPosition: GeneralR.myPosition,
    ipAddress: GeneralR.ipAddress,
  };
};
export default connect(GeneralMaptateToProps, actions)(CreateRoleClaim);
