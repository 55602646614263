import {
  UPDATE_BUSINESS_PROPS,
  MERGE_BUSINESS_PROPS,
  DELETE_BUSINESS_PROPS,
  CLEAR_BUSINESS_STATE,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    statistcs: { beanches: 0, distributors: 0, reviews: 0 },
    support_tickets: null,
    reviews: null,
    branches: null,
    distributors: null,
    filterArray: [],
    isLoading: false,
    form: {
      Name: { en: "", ar: "" },
      WorkingHours: [
        { day: "Monday", from: "", to: "" },
        { day: "Tuesday", from: "", to: "" },
        { day: "Wednesday", from: "", to: "" },
        { day: "Thursday", from: "", to: "" },
        { day: "Friday", from: "", to: "" },
        { day: "Saturday", from: "", to: "" },
        { day: "Sunday", from: "", to: "" },
      ],
      Photos: [],
      Phone: "",
      Lat: null,
      Lng: null,
      Type: null,
    },

    venueId: null,
  },

  search: {
    value: "",
    results: [],
    type: "autocomplete",
    isLoading: false,
  },
};

const BusinessR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_BUSINESS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_BUSINESS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_BUSINESS_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_BUSINESS_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default BusinessR;
