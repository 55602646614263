import { showConfirmMessage } from "../../../General/GeneralFunctions";

export const centerMap = (items, map, history, type) => {
  if (items.length > 0 && map !== null) {
    let points = items.map((item) => {
      return { lat: item.value[1], lng: item.value[0] };
    });

    if (points.length > 0) {
      const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
      const centerLat = arrAvg(points.map((c) => c.lat));
      const centerLng = arrAvg(points.map((c) => c.lng));

      if (isNaN(centerLng) || isNaN(centerLat)) {
        return null;
      } else {
        if (type === undefined) {
          map.setCenter({ lat: centerLat, lng: centerLng });
        }
        map.setZoom(10);
        replaceUrl(history, centerLat, centerLng, 10);
      }
    }
  }
};

export const replaceUrl = (history, lat, lng, zoom) => {
  history.replace({
    pathname: `/@${lat},${lng},${zoom}z`,
  });
};

export const updateUrl = (
  lat,
  lng,
  zoom,
  history,
  fetchCompany,
  fetchPlace,
  getBranchOrDistributor,
  fetchComplaint
) => {
  try {
    if (history.location.pathname.includes("@") === true) {
      let lat_lng_zoom = history.location.pathname.split("@")[1];

      if (lat_lng_zoom.split(",").length === 3) {
        let newLat = +lat_lng_zoom.split(",")[0];
        let newLng = +lat_lng_zoom.split(",")[1];

        let newZoom = +lat_lng_zoom
          .split(",")
          .find((n) => n.includes("z"))
          .match(/\d+/)[0];

        if (newZoom && newLat) {
          if (+newLat !== lat || +newLng !== lng || +newZoom !== zoom) {
            return { lat: newLat, lng: newLng, zoom: newZoom };
          } else {
            replaceUrl(history, lat, lng, zoom);
            return { lat, lng, zoom };
          }
        } else {
          return replaceUrl(history, lat, lng, zoom);
        }
      } else {
        return replaceUrl(history, lat, lng, zoom);
      }
    } else {
      if (history.location.pathname === "/") {
        replaceUrl(history, lat, lng, zoom);
        return { lat, lng, zoom };
      } else {
        getData(
          history,
          fetchCompany,
          fetchPlace,
          getBranchOrDistributor,
          fetchComplaint,
          lat,
          lng,
          zoom
        );
      }
    }
  } catch (err) {
    return replaceUrl(history, lat, lng, zoom);
  }
};

const getData = (
  history,
  fetchCompany,
  fetchPlace,
  getBranchOrDistributor,
  fetchComplaint,
  lat,
  lng,
  zoom
) => {
  let path = history.location.pathname.split("/")[1].split("=");

  if (path[0]) {
    let id = +path[1].split("&")[0];
    if (path[0] === "distributor" || (path[0] === "branch" && id)) {
      getBranchOrDistributor(id, path[0], lat, lng);
    } else if (path[0] === "place") {
      fetchPlace(id, path[0]);
    } else if (path[0] === "complaint") {
      fetchComplaint(id, path[0]);
    } else if (path[0] === "company" && id) {
      fetchCompany(id);
    }
  } else {
    return replaceUrl(history, lat, lng, zoom);
  }
};

export const getInitLocation = (initMap, ipAddress) => {
  debugger;
  let getLocationPromise = new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          let lat = position.coords.latitude;
          let long = position.coords.longitude;

          resolve({ latitude: lat, longitude: long });
        },
        function (error) {
          reject({ latitude: ipAddress.lat, longitude: ipAddress.lng });
        }
      );
    } else {
      showConfirmMessage(
        "your browser doesn't support geolocation API",
        "error"
      );
      // reject({ latitude: ipAddress.lat, longitude: ipAddress.lng });
    }
  });

  getLocationPromise
    .then((location) => {
      initMap(location.latitude, location.longitude);
    })
    .catch((err) => {
      initMap(err.latitude, err.longitude);

      console.log(err);
    });
};
