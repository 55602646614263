import { convertToFormData, showSuccessedConfirm } from "../General/GeneralFunctions";
import { local } from "../lang/local";
import { SendRequest } from "../Redux/Actions/api";

const beforAdd = (formValues) => {
  let tempBranchesId = formValues.branchIds?.map(ele => ele.id)
  formValues.branchIds = tempBranchesId
  formValues.venueId = formValues.venueId.value
}
export const addGroup = (formValues) => {

  debugger
  beforAdd(formValues)
  let newFormValues = Object.assign({}, formValues);
  let formData = convertToFormData(newFormValues);
 return  SendRequest()
    .post("/Group", formValues)
};