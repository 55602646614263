import React from "react";
import { Spinner, Row, Col, Button, Card } from "reactstrap";
import { columnAlignLeft, selectSearchStyle } from "../../General/GeneralStyle";
import { local } from "../../lang/local";
import { getDefaultValue } from "../../General/SelectFunctions";
import { UPDATE_COMPLAINTS_PROPS } from "../../Redux/Actions/types";
import {
  getDateValue,
  manipulateDate,
  removeUnsetValuesFromFilters,
  showConfirmMessage,
} from "../../General/GeneralFunctions";
import Flatpickr from "react-flatpickr";
import ComplaintTypes from "../ComplaintType/ComplaintTypes";
import { getPermissions } from "../../utils/Permissions";
import { FiX } from "react-icons/fi";
import { AsyncPaginate } from "react-select-async-paginate";

const ComplaintSearch = ({
  usersOptions,
  loadUserOptions,
  filterArray,
  general,
  isLoading,
  fetchComplaints,
  history,
}) => {
  const handleSearch = (e) => {
    e.preventDefault();

    if (filterArray.find((f) => f.value !== "" && f.value !== null)) {
      let newFilterArr = [...filterArray];

      newFilterArr = removeUnsetValuesFromFilters(newFilterArr);
      fetchComplaints(newFilterArr, 1);
    } else {
      showConfirmMessage(local.AnyDataRequired, "error");
    }
  };

  return (
    <Card className="search_card">
      <Row className="mt-1 mb-3">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Row className="alignItem">
            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <div className="search_date_container center">
                <Flatpickr
                  placeholder={local.From}
                  data-enable-time={false}
                  value={getDateValue(filterArray[0].value)}
                  onChange={(date) =>
                    general(
                      [
                        {
                          prop: "data.filterArray." + 0 + ".value",
                          value: manipulateDate(date[0]),
                        },
                      ],
                      UPDATE_COMPLAINTS_PROPS
                    )
                  }
                  options={{
                    enableTime: false,
                    altInput: true,
                    noCalendar: false,
                    maxDate: new Date(),
                  }}
                  className="search_input"
                />
                {filterArray[0].value ? (
                  <FiX
                    className="remove_icon_search"
                    onClick={(e) => {
                      e.preventDefault();
                      general(
                        [
                          {
                            prop: "data.filterArray." + 0 + ".value",
                            value: "",
                          },
                        ],
                        UPDATE_COMPLAINTS_PROPS
                      );
                    }}
                  />
                ) : null}
              </div>
            </Col>

            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <div className="search_date_container center">
                <Flatpickr
                  placeholder={local.To}
                  data-enable-time={false}
                  value={getDateValue(filterArray[1].value)}
                  onChange={(date) =>
                    general(
                      [
                        {
                          prop: "data.filterArray." + 1 + ".value",
                          value: manipulateDate(date[0]),
                        },
                      ],
                      UPDATE_COMPLAINTS_PROPS
                    )
                  }
                  options={{
                    enableTime: false,
                    altInput: true,
                    noCalendar: false,
                    maxDate: new Date(),
                  }}
                  className="search_input"
                />
                {filterArray[1].value ? (
                  <FiX
                    className="remove_icon_search"
                    onClick={(e) => {
                      e.preventDefault();
                      general(
                        [
                          {
                            prop: "data.filterArray." + 1 + ".value",
                            value: "",
                          },
                        ],
                        UPDATE_COMPLAINTS_PROPS
                      );
                    }}
                  />
                ) : null}
              </div>
            </Col>

            {getPermissions(true, true).UserBusiness_GetUsers !== undefined ||
            getPermissions(true, true).Administrator !== undefined ? (
              <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
                <div className="search_select_container">
                  <AsyncPaginate
                    key={filterArray[2].value}
                    placeholder={local.User}
                    value={getDefaultValue(filterArray[2].value, usersOptions)}
                    loadOptions={loadUserOptions}
                    onChange={(e) =>
                      e !== null
                        ? general(
                            [
                              {
                                prop: "data.filterArray." + 2 + ".value",
                                value: +e.value,
                              },
                            ],
                            UPDATE_COMPLAINTS_PROPS
                          )
                        : general(
                            [
                              {
                                prop: "data.filterArray." + 2 + ".value",
                                value: null,
                              },
                            ],
                            UPDATE_COMPLAINTS_PROPS
                          )
                    }
                    additional={{
                      page: 1,
                    }}
                    styles={selectSearchStyle}
                    isClearable={true}
                    isSearchable={true}
                  />
                </div>
              </Col>
            ) : null}

            <Col
              xl={3}
              lg={3}
              md={3}
              sm={6}
              xs={12}
              className={columnAlignLeft() + " mt-4"}
            >
              <Button
                disabled={isLoading}
                className="search_btn"
                onClick={handleSearch}
              >
                {isLoading === true ? <Spinner color="#fff" /> : local.Search}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {getPermissions(true, true).GeoEventType_Get !== undefined ||
        getPermissions(true, true).Administrator !== undefined ? (
          <ComplaintTypes history={history} />
        ) : null}
      </Row>
    </Card>
  );
};

export default ComplaintSearch;
