import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import * as actions from "../../Redux/Actions/index";
import { connect } from "react-redux";
import { local } from "../../lang/local";
import TableList from "./TableList";
import Gallery from "../../General/Gallery";
import { getUser } from "../../utils/StorageFunctions";
import ProfileModal from "../../utils/ProfileModal";
import UserProfile from "./UserProfile";
import { CLEAR_USERS_STATE } from "../../Redux/Actions/types";
class Users extends Component {
  componentDidMount() {
    this.props.general([], CLEAR_USERS_STATE);
    if (getUser() === null) {
      this.props.history.push("/login");
    } else {
      this.props.GetStatUsers();
      this.props.fetchUsers(this.props.data.filterArray, 1);
      this.props.fetchGuests(this.props.data.filterArray, 1);
    }
  }
  render() {
    const {
      history,
      data,
      DeletePlace,
      general,
      changeUserStatus,
      fetchUsers,
      lightbox,
      profileModal,
      getEmailCode,
      userStatistics,
      GetStatUsers,
    } = this.props;
    return (
      <div className="page-container">
        <Gallery lightbox={lightbox} general={general} />
        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={local.UserProfile}
          Children={UserProfile}
        />

        <Row>
          <Col>
            <TableList
              history={history}
              data={data}
              DeletePlace={DeletePlace}
              general={general}
              fetchUsers={fetchUsers}
              getEmailCode={getEmailCode}
              userStatistics={userStatistics}
              GetStatUsers={GetStatUsers}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ UsersR, GeneralR }) => {
  return {
    data: UsersR.data,
    lightbox: GeneralR.lightbox,
    profileModal: GeneralR.profileModal,
    userStatistics: UsersR.userStatistics,
    userTypes: UsersR.userTypes,
    userType: UsersR.userType,
  };
};
export default connect(mapStateToProps, actions)(Users);
