import { UPDATE_USER_CLAIMS_PROPS } from "../../Redux/Actions/types";
import { local } from "../../lang/local";
import { validateForm } from "../RolesClaims/RolesClaimsFunctions";

export const onMapClick = (polygon, general) => {
  general(
    [{ prop: "data.form.geometry", value: [polygon] }],
    UPDATE_USER_CLAIMS_PROPS
  );
};

export const handleSave = (
  e,
  action_type,
  form,
  general,
  userId,
  permissions,
  ComplaintTypesOptions
) => {
  e.preventDefault();
  if (
    validateForm(
      form,
      userId,
      local.SelectUser,
      permissions,
      general,
      UPDATE_USER_CLAIMS_PROPS,
      ComplaintTypesOptions
    ) === true
  ) {
    let newform = Object.assign({}, form);

    action_type(newform, userId, permissions);
  }
};
