import {
  UPDATE_HOME_PROPS,
  CLEAR_Home_STATE,
  MERGE_HOME_PROPS,
  DELETE_HOME_PROPS,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    active_users: 0,
    inactive_users: 0,
    tickets: 0,
    layers: [],
    user_system: { users: 0, guests: 0, tickets: 0, venues: 0 },
    usersMap: [],
    countries: [],
  },
};

const HomeR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_HOME_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_HOME_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_HOME_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_Home_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};

export default HomeR;
