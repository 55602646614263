import React from "react";
import { Row, Col, Form, Input, Button, Spinner, Card } from "reactstrap";
import { local } from "../../../lang/local";
import { UPDATE_AUTH_PROPS } from "../../../Redux/Actions/types";
import { showConfirmMessage } from "../../../General/GeneralFunctions";
import strik from "../../../assets/img/strik.png";

const   ConfirmEmailForm = ({
  general,
  form,
  history,
  isLoading,
  confirmByEmail,
}) => {
  const onConfirm = (e) => {
    e.preventDefault();

    if (form.token && form.userId) {
      confirmByEmail(form, history);
    } else {
      showConfirmMessage(local.DataRequired, "error");
    }
  };
  return (
    <Card className="create_card sign_card">
      <Form>
        <Row>
          <Col xl={12} lg={12} md={8} sm={12} xs={12} className="mx-auto">
            <div
              className="container_add_input mx-auto"
              style={{ height: "44px" }}
            >
              <img src={strik} alt="strik" className="strik_icon" />
              <Input
                className="add_input"
                placeholder={local.Code}
                value={form.token}
                onChange={(e) =>
                  general(
                    [
                      {
                        prop: "confirmEmailForm.token",
                        value: e.target.value,
                      },
                    ],
                    UPDATE_AUTH_PROPS
                  )
                }
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="center mt-5"
          >
            <Button
              className="save_btn"
              style={{ width: "100%", margin: "0px" }}
              onClick={onConfirm}
            >
              {isLoading === true ? (
                <Spinner color="#fff" />
              ) : (
                local.ConfirmAccount
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ConfirmEmailForm;
