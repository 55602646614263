import React, { useEffect } from 'react';
import { Card, Row, Col, Input } from 'reactstrap';
import { getLanguage, local } from '../../../lang/local';
import { onChooseHomeLocation, onChooseLocation } from './DirectionFunctions';
import search_direction_icon from '../../../assets/img/search_direction_icon.png';
import home from '../../../assets/img/home.png';
import work from '../../../assets/img/work.png';
import my_location from '../../../assets/img/my_location.png';
import { rightLeftProperty } from '../../../General/GeneralStyle';
import { webAsideCardStyle } from '../../../utils/MapViewStyle';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {
  handleCloseMenu,
  renderRowHeader,
} from '../../../utils/MapViewFunctions';
import * as actions from '../../../Redux/Actions/index';
import SearchResult from './SearchResult';
import {
  UPDATE_DIRECTION_MENU_PROPS,
  UPDATE_SEARCH_MENU_PROPS,
  UPDATE_USER_ACCOUNT_PROPS,
} from '../../../Redux/Actions/types';
import { useDebounce } from 'use-debounce';
import RecentSearch from '../SearchMenu/RecentSearch';
import { connect } from 'react-redux';
import AddPlace from '../Places/AddPlace';
import { HiOutlinePlus } from 'react-icons/hi';
import close_icon from '../../../assets/img/close_icon.png';
import { ListItemIcon } from '@material-ui/core';

const SearchDirection = ({
  startLocation,
  endLocation,
  ipAddress,
  search_direction,
  general,
  getSocketResult,
  myPosition,
  location_data,
  createPlace,
  add_place,
  search_data,
  addListType,
  searchImages,
  deleteUnregisterPlace,
}) => {
  const [from_value] = useDebounce(startLocation.searchValue, 500);
  const [to_value] = useDebounce(endLocation.searchValue, 500);
  const alignment = () => {
    let align = getLanguage() === 'ar' ? 'right' : 'none';
    return align;
  };
  const rendrAddIcon = ({ title }) => {
    return (
      <div
        className='flex alignItem cursor'
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <p className='search_direction_text2'>
          <HiOutlinePlus size={15} color='#1ea7ca' />
          {title}
        </p>
      </div>
    );
  };

  useEffect(() => {
    let lat = myPosition.lat;
    let lng = myPosition.lng;
    if (myPosition.allowLocation === false) {
      lat = ipAddress.lat;
      lng = ipAddress.lng;
    }

    if (from_value) {
      getSocketResult(from_value, lat, lng, 'start_location_autoComplete');
    }
    if (to_value) {
      getSocketResult(to_value, lat, lng, 'end_Location_autoComplete');
    }
  }, [from_value, to_value]);

  const onChangeInput = (value) => {
    if (search_direction.type === 'start') {
      general(
        [
          { prop: 'startLocation.searchValue', value: value },
          {
            prop: 'startLocation.search_type',
            value: 'auto_complete',
          },
        ],
        UPDATE_DIRECTION_MENU_PROPS
      );
    } else {
      general(
        [
          { prop: 'endLocation.searchValue', value: value },
          {
            prop: 'endLocation.search_type',
            value: 'auto_complete',
          },
        ],
        UPDATE_DIRECTION_MENU_PROPS
      );
    }
  };

  const onSearch = (e) => {
    e.preventDefault();

    let lat = myPosition.lat;
    let lng = myPosition.lng;
    if (myPosition.allowLocation === false) {
      lat = ipAddress.lat;
      lng = ipAddress.lng;
    }

    if (search_direction.type === 'start') {
      general(
        [
          {
            prop: 'startLocation.search_type',
            value: 'search',
          },
        ],
        UPDATE_DIRECTION_MENU_PROPS
      );
      getSocketResult(
        startLocation.searchValue,
        lat,
        lng,
        'start_location_Search'
      );
    } else {
      general(
        [
          {
            prop: 'endLocation.search_type',
            value: 'search',
          },
        ],
        UPDATE_DIRECTION_MENU_PROPS
      );
      getSocketResult(endLocation, lat, lng, 'end_Location_Search');
    }
  };

  const renderSearchResult = () => {
    if (search_direction.type === 'start') {
      if (startLocation.searchResults.length > 0) {
        return (
          <SearchResult
            general={general}
            type={'start_location'}
            searchResult={startLocation.searchResults}
          />
        );
      }
    } else {
      if (endLocation.searchResults.length > 0) {
        return (
          <SearchResult
            general={general}
            type={'end_location'}
            searchResult={endLocation.searchResults}
          />
        );
      }
    }
  };

  const closedItems = [
    {
      payload: [{ prop: 'search_direction.type', value: '' }],
      action: UPDATE_DIRECTION_MENU_PROPS,
    },

    {
      payload: [{ prop: 'userAccount.isOpen', value: false }],
      action: UPDATE_USER_ACCOUNT_PROPS,
    },

    {
      payload: [{ prop: 'search_data.toViewSearch', value: true }],
      action: UPDATE_SEARCH_MENU_PROPS,
    },
  ];
  return (
    <Card className='web_view_card' style={webAsideCardStyle}>
      {window.innerWidth > 760 ? (
        renderRowHeader(closedItems, general)
      ) : (
        <img
          src={close_icon}
          alt='close_icon'
          className='mobile-close-icon'
          style={rightLeftProperty}
          onClick={(e) => handleCloseMenu(e, closedItems, general)}
        />
      )}

      <div>
        <Row>
          <Col>
            <div className='search_direction_div'>
              <div className='img_container_account'>
                <img
                  src={search_direction_icon}
                  alt=''
                  className='search_dir_icon cursor'
                  onClick={onSearch}
                />
              </div>
              <Input
                className='removed_input_style'
                placeholder={search_direction.search_title}
                value={
                  search_direction.type === 'start'
                    ? startLocation.searchValue
                    : endLocation.searchValue
                }
                onChange={(e) => onChangeInput(e.target.value)}
              />
            </div>
          </Col>
        </Row>

        {renderSearchResult()}

        <Row className='mt-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Paper sx={{ width: '100%', maxWidth: '100%' }}>
              <MenuList>
                <MenuItem
                  sx={{ wordBreak: 'break-word', textOverflow: 'clip' }}
                >
                  <Typography variant='body2' color='text.secondary'>
                    <img src={home} alt='' className='cursor' />
                  </Typography>
                  <ListItemText
                    sx={{
                      textAlign: alignment(),
                      marginInlineStart: '10px',
                      wordBreak: 'wrap',
                      overflow: 'hidden',
                    }}
                  >
                    <p>
                      {local.HomeLocation}
                      {search_direction.home_info ? (
                        <div className='flex alignItem'>
                          <a
                            className='lighBlueColor cursor'
                            onClick={(e) =>
                              onChooseHomeLocation(
                                e,
                                search_direction.type,
                                general,
                                search_direction.home_info
                              )
                            }
                          >
                            {search_direction.home_info.info.address}
                          </a>
                        </div>
                      ) : null}
                    </p>
                  </ListItemText>
                  <ListItemIcon>
                    {search_direction.home_info ? (
                      <a
                        className='loc_link'
                        onClick={(e) => {
                          e.preventDefault();
                          deleteUnregisterPlace(
                            search_direction.home_info.id,
                            'search_direction.home_info'
                          );
                        }}
                      >
                        {local.Remove}
                      </a>
                    ) : (
                      <AddPlace
                        general={general}
                        add_place={add_place}
                        location_data={location_data}
                        createPlace={createPlace}
                        TagItem={rendrAddIcon}
                        title={local.Add}
                        type_name='Home'
                        type={'Labeled'}
                        search_direction={search_direction}
                        addListType={addListType}
                        startLocation={startLocation}
                        endLocation={endLocation}
                      />
                    )}
                  </ListItemIcon>
                </MenuItem>

                <MenuItem
                  sx={{ wordBreak: 'break-word', textOverflow: 'clip' }}
                >
                  <Typography variant='body2' color='text.secondary'>
                    <img src={work} alt='' className='cursor' />
                  </Typography>
                  <ListItemText
                    sx={{
                      textAlign: alignment(),
                      marginInlineStart: '10px',
                      wordBreak: 'wrap',
                      overflow: 'hidden',
                    }}
                  >
                    <p>
                      {local.Work}
                      {search_direction.work_info ? (
                        <div className='flex alignItem'>
                          <a
                            className='lighBlueColor cursor'
                            onClick={(e) =>
                              onChooseHomeLocation(
                                e,
                                search_direction.type,
                                general,
                                search_direction.work_info
                              )
                            }
                          >
                            {search_direction.work_info.info.address}
                          </a>
                        </div>
                      ) : null}
                    </p>
                  </ListItemText>
                  <ListItemIcon>
                    {search_direction.work_info ? (
                      <a
                        className='loc_link'
                        onClick={(e) => {
                          e.preventDefault();
                          deleteUnregisterPlace(
                            search_direction.work_info.id,
                            'search_direction.work_info'
                          );
                        }}
                      >
                        {local.Remove}
                      </a>
                    ) : (
                      <AddPlace
                        general={general}
                        add_place={add_place}
                        location_data={location_data}
                        createPlace={createPlace}
                        TagItem={rendrAddIcon}
                        title={local.AddWork}
                        type_name='Work'
                        type={'Labeled'}
                        search_direction={search_direction}
                        addListType={addListType}
                        startLocation={startLocation}
                        endLocation={endLocation}
                      />
                    )}
                  </ListItemIcon>
                </MenuItem>
              </MenuList>
            </Paper>
          </Col>
        </Row>

        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Paper sx={{ width: '100%', maxWidth: '100%' }}>
              <MenuList>
                <MenuItem
                  onClick={(e) =>
                    onChooseLocation(
                      e,
                      search_direction.type,
                      'location',
                      general,
                      myPosition,
                      ipAddress
                    )
                  }
                  sx={{ wordBreak: 'break-word', textOverflow: 'clip' }}
                >
                  <Typography variant='body2' color='text.secondary'>
                    <img src={my_location} alt='' className='cursor' />
                  </Typography>
                  <ListItemText
                    sx={{
                      textAlign: alignment(),
                      marginInlineStart: '10px',
                      wordBreak: 'wrap',
                      overflow: 'hidden',
                    }}
                  >
                    {local.YourLocation}
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </Paper>
          </Col>
        </Row>

        <RecentSearch
          general={general}
          myPosition={myPosition}
          getSocketResult={getSocketResult}
          search_data={search_data}
          searchImages={searchImages}
        />
      </div>
    </Card>
  );
};

const mapStateToProps = ({
  DirectionR,
  GeneralR,
  LocationR,
  SearchMenuR,
  PlaceR,
}) => {
  return {
    startLocation: DirectionR.startLocation,
    endLocation: DirectionR.endLocation,
    search_direction: DirectionR.search_direction,
    ipAddress: GeneralR.ipAddress,
    myPosition: GeneralR.myPosition,
    location_data: LocationR.location_data,
    search_data: SearchMenuR.search_data,
    add_place: PlaceR.add_place,
    addListType: PlaceR.addListType,
  };
};

export default connect(mapStateToProps, actions)(SearchDirection);
