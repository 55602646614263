import React, { Component } from 'react';
import { Col, Row, Card, Input, Form, Spinner,  } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import strik from '../../assets/img/strik.png';
import {
  UPDATE_ROLES_PROPS,
} from '../../Redux/Actions/types';
import { getPermissionIcon, handleSave } from './RolesFunctions';
import {
  preparePermissions,
  renderErrorMessage,
  sortItem,
} from '../../General/GeneralFunctions';
import { FiX } from 'react-icons/fi';
import { getPermissions } from '../../utils/Permissions';
import { confirmAlert } from 'react-confirm-alert';
import { columnAlignLeft,  } from '../../General/GeneralStyle';

class EditRole extends Component {
  componentDidMount() {
    this.getRole();
  }

  getRole = () => {
    const { fetchRole, history } = this.props;
    let items = history.location.pathname.split('/');
    let id = +items.find((i) => !isNaN(i) && i !== '');
    if (id) {
      fetchRole(id);
    } else {
      history.push('/roles');
    }
  };

  renderClaims = () => {
    const { claims, form, deleteRoleClaim } = this.props;
    if (claims.length > 0) {
      return preparePermissions(claims).map((claim, index) => {
        let values = sortItem(claim.value);
        return (
          <Row className='mt-2'>
            <Col
              xl={3}
              lg={3}
              md={3}
              sm={3}
              xs={3}
              className={columnAlignLeft() + ' mx-auto'}
            >
              <p className='bold mt-5'>
                {claim.key === 'Branches'
                  ? local.Branches + '/' + local.Distributors
                  : local[claim.key]}
              </p>
            </Col>

            <Col xl={9} lg={9} md={9} sm={9} xs={9}>
              <ul className='nav'>
                {values.map((sub, i) => {
                  if (sub.label === 'Add Complaint Type') {
                  }
                  if (sub.label === 'Accept/Reject Place') {
                  }
                  return (
                    <li
                      className='mt-5'
                      key={i}
                      style={{
                        listStyle: 'none',
                        display: 'inline-block',
                        width: `20%`,
                      }}
                    >
                      <Card className='center ml-1 mr-1 permission_card'>
                        {getPermissionIcon(sub.label)}
                        <span> {sub.label} </span>

                        {getPermissions(true, true)
                          .RoleBusiness_DetachRoleClaim !== undefined ||
                        getPermissions(true, true).Administrator !==
                          undefined ? (
                          <span className='remove_icon2'>
                            <FiX
                              color='red'
                              size={20}
                              onClick={(e) => {
                                confirmAlert({
                                  customUI: ({ onClose }) => {
                                    return (
                                      <div className='custom-ui'>
                                        <h1>{local.Sure}</h1>
                                        <p>{local.ConfirmEdit}</p>
                                        <button onClick={onClose}>
                                          {local.No}
                                        </button>
                                        <button
                                          onClick={() => {
                                            deleteRoleClaim(
                                              sub.id,
                                              form.id,
                                              index,
                                              true,
                                              claims
                                            );
                                            onClose();
                                          }}
                                        >
                                          {local.Yes}
                                        </button>
                                      </div>
                                    );
                                  },
                                });
                              }}
                            />
                          </span>
                        ) : null}
                      </Card>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        );
      });
    }
  };
  render() {
    const {
      history,
      form,
      isLoadingCreate,
      general,
      updateRole,
      errorMessages,
    } = this.props;
    return (
      <div className='page-container'>
        <Row>
          <Col
            xl={8}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className='mx-auto text-center'
          >
            <h3 className='page_create_title'>{local.AddRole}</h3>
            <Card className='create_card'>
              <Form>
                <Row>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    className='mt-3 mx-auto'
                  >
                    <div className='container_add_input ticket_input_form mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <Input
                        className='add_input'
                        value={form.name}
                        onChange={(e) =>
                          general(
                            [
                              {
                                prop: 'data.form.name',
                                value: e.target.value,
                              },
                            ],
                            UPDATE_ROLES_PROPS
                          )
                        }
                        placeholder={local.Name}
                      />
                    </div>
                    {renderErrorMessage(form.name, errorMessages.Name)}
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    className='mt-4 mx-auto'
                  >
                    <div className='container_add_input ticket_input_form mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <Input
                        className='add_input'
                        value={form.description}
                        onChange={(e) =>
                          general(
                            [
                              {
                                prop: 'data.form.description',
                                value: e.target.value,
                              },
                            ],
                            UPDATE_ROLES_PROPS
                          )
                        }
                        placeholder={local.Description}
                      />
                    </div>
                    {renderErrorMessage(
                      form.description,
                      errorMessages.Description
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    className='mt-5 mx-auto'
                  >
                    <button
                      className='save_btn'
                      onClick={(e) =>
                        handleSave(e, updateRole, form, general, history)
                      }
                    >
                      {isLoadingCreate === true ? <Spinner /> : local.Save}
                    </button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ RolesR, GeneralR }) => {
  return {
    form: RolesR.data.form,
    claims: RolesR.data.claims,
    errorMessages: RolesR.data.errorMessages,
    isLoadingCreate: RolesR.data.isLoadingCreate,
    location: GeneralR.location,
  };
};
export default connect(mapStateToProps, actions)(EditRole);
