import {
  UPDATE_REGISTER_PROPS,
  MERGE_REGISTER_PROPS,
  DELETE_REGISTER_PROPS,
  CLEAR_REGISTER_STATE,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  registerObj: {
    isLoading: false,

    form: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      gender: null,
      birthDate: null,
      country: "",
      image: "",
      phoneNumber: "",
    },
  },

  verificationObj: {
    isLoading: false,
  },
};

const RegisterR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_REGISTER_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_REGISTER_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_REGISTER_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_REGISTER_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};

export default RegisterR;
