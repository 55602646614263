import React, { useState } from 'react';
import { Card, Col } from 'reactstrap';
import './location.css';
import { getLanguage, local } from '../../../lang/local';
import add_icon_btn from '../../../assets/img/add_icon_btn.png';
import star_icon from '../../../assets/img/star_icon.png';
import share_icon from '../../../assets/img/share_icon.png';
import {
  UPDATE_CONTRIBUTION_PROPS,
  UPDATE_GENERAL_PROPS,
  UPDATE_LOCATION_MENU_PROPS,
  UPDATE_SEARCH_MENU_PROPS,
  UPDATE_USER_ACCOUNT_PROPS,
} from '../../../Redux/Actions/types';
import { numberToArabic } from 'number-to-arabic';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/Actions/index';
import Gallery from '../../../General/Gallery';
import { convert_to_well_known_text } from '../../../General/GeneralFunctions';
import MobileLocation from './MobileLocation';
import WebLocation from './WebLocation';
const LocationMenu = (props) => {
  const { location_data, general, lightbox } = props;
  const [isSelected, setSelected] = useState(null);
  const handleAddPlace = (e) => {
    let arregex = /[\u0600-\u06FF]/;
    e.preventDefault();

    if (arregex.test(location_data.address) === true) {
      general(
        [
          { prop: 'data.form.ContributionName.en', value: '' },
          {
            prop: 'data.form.ContributionName.ar',
            value: location_data.address,
          },
        ],
        UPDATE_CONTRIBUTION_PROPS
      );
    } else {
      general(
        [
          { prop: 'data.form.ContributionName.ar', value: '' },
          {
            prop: 'data.form.ContributionName.en',
            value: location_data.address,
          },
        ],
        UPDATE_CONTRIBUTION_PROPS
      );
    }
    general(
      [{ prop: 'location_data.toViewSideInfo', value: false }],
      UPDATE_LOCATION_MENU_PROPS
    );

    general(
      [{ prop: 'userAccount.isOpen', value: false }],
      UPDATE_USER_ACCOUNT_PROPS
    );
    general(
      [
        { prop: 'data.isOpenAddContribution', value: true },
        {
          prop: 'data.form.Geometry',
          value: convert_to_well_known_text(
            location_data.lat,
            location_data.lng
          ),
        },
      ],
      UPDATE_CONTRIBUTION_PROPS
    );
  };
  const renderDistance = () => {
    if (getLanguage() === 'en') {
      return location_data.distance;
    } else {
      try {
        var num = location_data.distance.match(/\d+/)[0];

        return numberToArabic(num) + ' كم ';
      } catch (e) {
        console.log(e);
      }
    }
  };

  const RegPlcaeImage = () => {
    return <img src={star_icon} alt='star_icon' className='loc_img' />;
  };

  const ShareImage = () => {
    return <img src={share_icon} alt='share_icon' className='loc_img' />;
  };

  const getImages = () => {
    if (location_data.images) {
      if (location_data.images.length > 0) {
        return location_data.images.map((image, i) => {
          return (
            <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mt-4' key={i}>
              <Card className='img_place_card'>
                <img
                  src={image.src}
                  alt=''
                  className='imgFull cursor'
                  onClick={(e) => {
                    e.preventDefault();
                    general(
                      [
                        {
                          prop: 'lightbox',
                          value: {
                            images: location_data.images,
                            type: 'multi',
                            index: i,
                            isOpen: true,
                          },
                        },
                      ],
                      UPDATE_GENERAL_PROPS
                    );
                  }}
                />
              </Card>
            </Col>
          );
        });
      }
    }
  };

  const addPlaceButton = (address, onClick) => {
    return (
      <button
        className='location_btn'
        onClick={onClick}
        disabled={address ? false : true}
      >
        <img src={add_icon_btn} alt='' />
        {local.AddPlace}
      </button>
    );
  };

  const EditPlaceButton = () => {
    return (
      <button
        style={{ marginInlineStart: '5px' }}
        className='location_btn'
        disabled={location_data.address ? false : true}
      >
        <img src={add_icon_btn} alt='icon' />
        {local.EDITPLACE}
      </button>
    );
  };
  return (
    <React.Fragment>
      <Gallery lightbox={lightbox} general={general} />

      {window.innerWidth > 760 ? (
        <WebLocation
          {...props}
          general={general}
          closedItems={[
            {
              payload: [
                { prop: 'location_data.images', value: [] },
                { prop: 'location_data.images_names', value: [] },
                { prop: 'location_data.toViewSideInfo', value: false },
              ],
              action: UPDATE_LOCATION_MENU_PROPS,
            },

            {
              payload: [{ prop: 'search_data.toViewSearch', value: true }],
              action: UPDATE_SEARCH_MENU_PROPS,
            },
          ]}
          handleAddPlace={handleAddPlace}
          addPlaceButton={addPlaceButton}
          EditPlaceButton={EditPlaceButton}
          isSelected={isSelected}
          setSelected={setSelected}
          renderDistance={renderDistance}
          getImages={getImages}
          RegPlcaeImage={RegPlcaeImage}
          ShareImage={ShareImage}
        />
      ) : (
        <MobileLocation
          {...props}
          general={general}
          closedItems={{
            payload: [
              { prop: 'location_data.images', value: [] },
              { prop: 'location_data.images_names', value: [] },
              { prop: 'location_data.toViewSideInfo', value: false },
            ],
            action: UPDATE_LOCATION_MENU_PROPS,
          }}
          handleAddPlace={handleAddPlace}
          addPlaceButton={addPlaceButton}
          EditPlaceButton={EditPlaceButton}
          isSelected={isSelected}
          setSelected={setSelected}
          renderDistance={renderDistance}
          getImages={getImages}
          RegPlcaeImage={RegPlcaeImage}
          ShareImage={ShareImage}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({
  LocationR,
  GeneralR,
  ContribuationsR,
  PlaceR,
  DirectionR,
}) => {
  return {
    location_data: LocationR.location_data,
    myPosition: GeneralR.myPosition,
    ipAddress: GeneralR.ipAddress,
    lightbox: GeneralR.lightbox,
    editedPlaces: ContribuationsR.editedPlaces,
    contribuationForm: ContribuationsR.data.form,
    add_place: PlaceR.add_place,
    search_direction: DirectionR.search_direction,
    addListType: PlaceR.addListType,
    startLocation: DirectionR.startLocation,
    endLocation: DirectionR.endLocation,
  };
};

export default connect(mapStateToProps, actions)(LocationMenu);
