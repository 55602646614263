import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Button, Col, Row } from "reactstrap";
import { local } from "../../lang/local";
import { MERGE_TICKETS_PROPS } from "../../Redux/Actions/types";
import { MdRecordVoiceOver } from "react-icons/md";
import Recorder from "react-mp3-recorder";
import blobToBuffer from "blob-to-buffer";

const useStyles = makeStyles((theme) => ({
  typography: {
    minWidth: "400px",
  },
}));

export default function AudioRecorder({ general }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [record, setRecord] = useState(null);
  const [file, setFile] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onData = (blob) => {
    blobToBuffer(blob, (err, buffer) => {
      if (err) {
        console.error(err);
        return;
      }

      console.log("recording", blob);

      if (record) {
        window.URL.revokeObjectURL(record);
      }

      const myFile = new File([blob], `audio.${blob.type.split("/")[1]}`, {
        type: blob.type,
      });
      setFile(myFile);

      setRecord(window.URL.createObjectURL(blob));
    });
  };

  const _onRecordingError = (err) => {
    console.log("error recording", err);

    if (record) {
      window.URL.revokeObjectURL(record);
    }
    setFile(null);
    setRecord(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSave = (e) => {
    if (file !== null) {
      e.preventDefault();
      general(
        [
          {
            prop: "data_message.form.Attachments",
            value: file,
          },
        ],
        MERGE_TICKETS_PROPS
      );
      setFile(null);
      setRecord(null);
    }
    handleClose();
  };

  return (
    <div className="video_link_container">
      <a
        className="cursor fullWidthHeight"
        onClick={handleClick}
        style={{ background: "red !important" }}
      >
        <div className="fullWidthHeight ticket_tab_option">
          <MdRecordVoiceOver color="#fff" size="22" />
        </div>
      </a>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>
          <div
            style={{
              direction: "ltr",
            }}
            className="fullWidthHeight mt-5 mb-5"
          >
            <Row className="mt-3 ml-2 mr-2">
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
                <Recorder
                  onRecordingComplete={onData}
                  onRecordingError={_onRecordingError}
                />
              </Col>
            </Row>
            {record ? (
              <Row className="mt-3 ml-2 mr-2">
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
                  <audio controls>
                    <source src={record} type="audio/ogg" />
                    Your browser does not support the audio tag.
                  </audio>
                </Col>
              </Row>
            ) : null}
            <Row className="mt-3 ml-2 mr-2">
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
                <Button className="btn_save" onClick={handleSave}>
                  {local.Save}
                </Button>
              </Col>
            </Row>
          </div>
        </Typography>
      </Popover>
    </div>
  );
}
