import React from "react";
import { Row, Col, Card } from "reactstrap";
import { local } from "../../lang/local";
import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
import { leftStyle } from "../GeneralStyle";
const SearchResult = ({
  mapSearch,
  handlePlaceSearch,
  general,
  updateBounds,
}) => {
  const handlePlaceClick = (e, item) => {
    if (item) {
      e.preventDefault();
      if (updateBounds === undefined) {
        handlePlaceSearch(
          item.value[1],
          item.value[0],
          item.label,
          item.data.properties.layer
        );
      }
      let lat = "";
      let lng = "";
      let bounds = null;
      let layer = "";

      if (updateBounds !== undefined && item.data.bbox) {
        lat = item.value[1];
        lng = item.value[0];
        bounds = {
          north: item.data.bbox[0],
          south: item.data.bbox[1],
          east: item.data.bbox[2],
          west: item.data.bbox[3],
        };
        layer = item.data.properties.layer;
      }
      general(
        [
          {
            prop: "mapSearch",
            value: {
              searchResults: [],
              searchValue: item.label,
              type: "autoComplete",
              isLoading: false,
              bounds,
              lat,
              lng,
              layer,
            },
          },
        ],
        UPDATE_GENERAL_PROPS
      );
    }
  };
  const renderCardItem = () => {
    if (mapSearch.searchResults) {
      if (mapSearch.searchResults.length > 0) {
        return (
          <Row>
            {mapSearch.searchResults.map((result, index) => {
              let label = result.label.split(",");

              let desc = label.pop().toString();
              return (
                <Col
                  className="mt-3"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  key={index}
                >
                  <Card
                    className="search_result_card_item center cursor"
                    onClick={(e) => handlePlaceClick(e, result)}
                  >
                    <Row className="alignItem center">
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="text-center"
                      >
                        <div className="inline_tag">
                          <span className="place_name">{label.toString()}</span>
                          <p className="city_name">{desc}</p>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
        );
      }
    }
  };
  return (
    <div className="mt-4 ml-4 mr-4 search_result_container">
      {mapSearch.type === "search_data" ? (
        <Row>
          <Col className="mt-5 ml-2 mr-2">
            <p className="search_result_title" style={leftStyle}>
              {local.SearchResult}
            </p>
          </Col>
        </Row>
      ) : null}

      {renderCardItem()}
    </div>
  );
};

export default SearchResult;
