import React from "react";
import { UPDATE_CONTRIBUTION_PROPS } from "../../Redux/Actions/types";
import {
  convertArrayToObject,
  showConfirmMessage,
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Col, Row, Button } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";

export const onSelectLayer = (e, general) => {
  if (e !== null) {
    general(
      [
        {
          prop: "data.form.Layer",
          value: e.layer,
        },
        {
          prop: "data.form.Categories",
          value: e.categories,
        },
      ],
      UPDATE_CONTRIBUTION_PROPS
    );
  } else {
    general(
      [
        {
          prop: "data.form.Layer",
          value: "",
        },
        {
          prop: "data.form.Categories",
          value: [],
        },
      ],
      UPDATE_CONTRIBUTION_PROPS
    );
  }
};

export const onMapClick = (lat, lng, general, action, form) => {
  general(
    [
      {
        prop: "data.isLoadingPlaces",
        value: false,
      },
      {
        prop: "data.exists_places",
        value: [],
      },
      {
        prop: "data.form.Geometry",
        value: "POINT(" + lng + " " + lat + ")",
      },
    ],
    UPDATE_CONTRIBUTION_PROPS
  );
  if (action !== undefined) {
    action(lat, lng, form);
  }
};

export const unsetErrorMessage = (general) => {
  general(
    [
      {
        prop: "data.errorMessages",
        value: { Name: "", Layer: "", Location: "" },
      },
    ],
    UPDATE_CONTRIBUTION_PROPS
  );
};

export const validateForm = (form, general) => {
  if (
    (!form.ContributionName.en && !form.ContributionName.ar) ||
    !form.Layer ||
    form.Geometry.length === 0 ||
    !form.Geometry
  ) {
    window.scrollTo(0, 0);

    if (!form.ContributionName.en && !form.ContributionName.ar) {
      general(
        [
          {
            prop: "data.errorMessages.Name",
            value: local.NameEnORArRequired,
          },
        ],
        UPDATE_CONTRIBUTION_PROPS
      );
    }

    if (form.Geometry.length === 0 || !form.Geometry) {
      general(
        [
          {
            prop: "data.errorMessages.Location",
            value: local.LocationRequired,
          },
        ],
        UPDATE_CONTRIBUTION_PROPS
      );
    }

    if (!form.Layer) {
      general(
        [{ prop: "data.errorMessages.Layer", value: local.SelectLayer }],
        UPDATE_CONTRIBUTION_PROPS
      );
    }
  } else {
    unsetErrorMessage(general);
    return true;
  }
};

export const handleSave = (e, action_type, form, general, history) => {
  e.preventDefault();
  if (validateForm(form, general) === true) {
    let newform = Object.assign({}, form);
    if (form.WorkingHours.length > 0) {
      let newOpenningHours = convertArrayToObject(form.WorkingHours);
      newform.WorkingHours = newOpenningHours;
    }

    if (newform.id === undefined) {
      action_type(newform, history);
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{local.Sure}</h1>
              <p>{local.ConfirmEdit}</p>
              <button onClick={onClose}>{local.No}</button>
              <button
                onClick={() => {
                  action_type(newform, history);
                  onClose();
                }}
              >
                {local.Yes}
              </button>
            </div>
          );
        },
      });
    }
  }
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PlaceList({
  setOpen,
  open,
  exists_places,
  general,
  setSelected,
  isSelected,
  setPoint,
}) {
  const handleClose = () => {
    if (isSelected !== null) {
      let place = exists_places[isSelected];

      if (place !== undefined) {
        let lng = +place.Geometry.replace(/^\D+/g, "").split(" ")[0];
        let lat = +place.Geometry.replace(/^\D+/g, "")
          .split(" ")[1]
          .split(")")[0];

        setPoint({ lat, lng });

        general(
          [{ prop: "data.form", value: place }],
          UPDATE_CONTRIBUTION_PROPS
        );
      }
    }
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle className="center">{local.ChoosePlace}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Row>
              <Col
                className="flex alignItem"
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <div className="round">
                  <input
                    type="checkbox"
                    id="checkbox-1"
                    checked={isSelected === -1 ? true : false}
                    onChange={(e) =>
                      e.target.checked === true
                        ? setSelected(-1)
                        : setSelected(null)
                    }
                  />
                  <label htmlFor="checkbox-1"></label>
                </div>
                <div className="mt-2">
                  <p
                    style={{
                      marginInlineStart: "10px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {local.ContinueAdding}
                  </p>
                </div>
              </Col>
            </Row>
            {exists_places
              ? exists_places.map((place, index) => {
                  return (
                    <Row key={index}>
                      <Col
                        className="mt-2 flex alignItem"
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <div className="round">
                          <input
                            type="checkbox"
                            id={"checkbox" + +index}
                            checked={isSelected === index ? true : false}
                            onChange={(e) =>
                              e.target.checked === true
                                ? setSelected(index)
                                : setSelected(null)
                            }
                          />
                          <label htmlFor={"checkbox" + +index}></label>
                        </div>
                        <div className="mt-2">
                          <p
                            style={{
                              marginInlineStart: "10px",
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            {place.ContributionName[local.lang]}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  );
                })
              : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="center mx-auto">
          <Button onClick={handleClose} className="mb-3 mt-3 modal_btn">
            {local.Save}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
