import {
  showConfirmMessage,
  showSuccessedConfirm
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { SendRequest } from "./api";
import {
  DELETE_ROLES_CLAIMS_PROPS,
  DELETE_ROLES_PROPS,
  UPDATE_ROLES_CLAIMS_PROPS
} from "./types";

export const fetchRoelsClaims = (roleId, claimValue, isDelete) => dispatch => {
  dispatch({
    type: UPDATE_ROLES_CLAIMS_PROPS,
    payload: [{ prop: "data.isLoading", value: true }]
  });
  SendRequest()
    .get(`/Roles/GetRoleClaims/${roleId}`)
    .then(res => {
      if (res.status === 200) {
        if (res.data.data.length === 0 && isDelete === undefined) {
          showConfirmMessage(local.NoSearch, "error");
        }

        if (claimValue && res.data.data) {
          res.data.data = res.data.data.filter(claim => {
            if (
              claim.claimValue === claimValue ||
              claim.claimValue.includes(claimValue) ||
              claim.claimValue.toUpperCase() === claimValue ||
              claim.claimValue.toLowerCase() === claimValue
            ) {
              return claim;
            }
          });
        }
        dispatch({
          type: UPDATE_ROLES_CLAIMS_PROPS,
          payload: [{ prop: "data.items", value: res.data.data }]
        });
      }

      dispatch({
        type: UPDATE_ROLES_CLAIMS_PROPS,
        payload: [{ prop: "data.isLoading", value: false }]
      });
    })
    .catch(e => {
      dispatch({
        type: UPDATE_ROLES_CLAIMS_PROPS,
        payload: [{ prop: "data.isLoading", value: false }]
      });
    })
    .finally();
};

export const addRoleClaim = (formValues, roleId, permissions) => dispatch => {
  dispatch({
    type: UPDATE_ROLES_CLAIMS_PROPS,
    payload: [{ prop: "isLoadingCreate", value: true }]
  });

  return permissions.map((per, i) => {
    var newFormValues = Object.assign({}, formValues);
    newFormValues.claimType = per;
    if (per === "GeoEventType_Get") {
      newFormValues.claimValue = newFormValues.claimValue1
        .map(p => p.value)
        .toString();
      delete newFormValues.claimValue1;
    }
    if (per !== "GeoEventType_Get") {
      delete newFormValues.claimValue1;
    }
    SendRequest()
      .post(`Roles/Attach/Claim/${roleId}`, newFormValues)
      .then(res => {
        if (res.status === 200) {
          if (i === permissions.length - 1) {
            showSuccessedConfirm(local.AddedSuccessfully);

            dispatch({
              type: UPDATE_ROLES_CLAIMS_PROPS,
              payload: [
                {
                  prop: "data.form",
                  value: {
                    Description: "",
                    claimType: "",
                    claimValue: "",
                    geometry: [],
                    claimValue1: null
                  }
                },
                {
                  prop: "data.permissions",
                  value: []
                },
                {
                  prop: "data.roleId",
                  value: null
                }
              ]
            });
          }
        }

        if (i === permissions.length - 1) {
          dispatch({
            type: UPDATE_ROLES_CLAIMS_PROPS,
            payload: [{ prop: "isLoadingCreate", value: false }]
          });
        }
      })
      .catch(e => {
        dispatch({
          type: UPDATE_ROLES_CLAIMS_PROPS,
          payload: [{ prop: "isLoadingCreate", value: false }]
        });
        console.log(e);
      })
      .finally();
  });
};

export const deleteRoleClaim = (
  role_claim_id,
  role_id,
  index,
  isEdit,
  claims
) => dispatch => {
  dispatch({
    type: UPDATE_ROLES_CLAIMS_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: null }]
  });
  SendRequest()
    .delete(`/Roles/Detach/Claim/${role_id}/${role_claim_id}`)
    .then(res => {
      if (res.status === 200) {
        if (isEdit === undefined) {
          dispatch({
            type: DELETE_ROLES_CLAIMS_PROPS,
            payload: [
              {
                prop: "data.items." + index
              }
            ]
          });
        } else {
          let item = claims.find((c, i) => c.id === role_claim_id);
          let newIndex = claims.indexOf(item);
          dispatch({
            type: DELETE_ROLES_PROPS,
            payload: [
              {
                prop: "data.claims." + newIndex
              }
            ]
          });
        }

        showSuccessedConfirm(local.DeletedSuccessfully);
      }
      dispatch({
        type: UPDATE_ROLES_CLAIMS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
    })
    .catch(err => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_ROLES_CLAIMS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
      console.log(err);
    })
    .finally();
};

export const deleteMultipleRolesClaims = (
  ids,
  setChecked,
  roleId
) => dispatch => {
  dispatch({
    type: UPDATE_ROLES_CLAIMS_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: true }]
  });

  SendRequest()
    .delete(`/Roles/Detach/Claim/${roleId}/${ids[0]}`)
    .then(res => {
      if (res.status === 200) {
        ids.shift();

        if (ids.length > 0) {
          dispatch(deleteMultipleRolesClaims(ids, setChecked, roleId));
        } else {
          setChecked([]);
          showSuccessedConfirm(local.DeletedSuccessfully);

          dispatch(fetchRoelsClaims(roleId, undefined, true));
        }
      }
      dispatch({
        type: UPDATE_ROLES_CLAIMS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
    })
    .catch(err => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_ROLES_CLAIMS_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }]
      });
      console.log(err);
    })
    .finally();
};

export const getClaimsTypeForDropDown = () => dispatch => {
  SendRequest()
    .get("/Roles/claims")
    .then(res => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          let newTypes = [];

          res.data.forEach(d => {
            if (
              d !== "Contribution_GetContributions" &&
              d !== "AnswersBusiness_Delete" &&
              d !== "AnswersBusiness_Create" &&
              d !== "AnswersBusiness_Update" &&
              d !== "AnswersBusiness_GetAnswers" &&
              d !== "Device_Delete" &&
              d !== "Device_Create" &&
              d !== "Device_Update" &&
              d !== "Device_GetDevices" &&
              d !== "GeoEventType_GetGeoEventsType" &&
              d !== "QuestionsBusiness_Delete" &&
              d !== "QuestionsBusiness_GetQuestions" &&
              d !== "QuestionsBusiness_Create" &&
              d !== "QuestionsBusiness_Update" &&
              d !== "ReviewsBusiness_GetReviews" &&
              d !== "UserPlacesRegistered_Delete" &&
              d !== "UserPlacesRegistered_Create" &&
              d !== "UserPlacesRegistered_Update" &&
              d !== "UserPlacesRegistered_GetUserPlaceRegistered" &&
              d !== "UserPlacesUnRegistered_Delete" &&
              d !== "UserPlacesUnRegistered_Create" &&
              d !== "Ticket_GetTicket" &&
              d !== "UserPlacesUnRegistered_GetUserPlaceUnregistered" &&
              d !== "UserBusiness_GetUser" &&
              d !== "UserPlacesUnRegistered_Update" &&
              d !== "UserBusiness_UsersCountByRoles" &&
              d !== "Venues_VenuesCount" &&
              d !== "Venues_MyVenues" &&
              d !== "UserBusiness_UpdateUser" &&
              d !== "RoleBusiness_InRole" &&
              d !== "RoleBusiness_GetUsersRole" &&
              d !== "UserBusiness_GetActivationCode" &&
              d !== "GeoEvent_Update" &&
              d !== "GeoEvent_Delete" &&
              d !== "GeoEvent_GetGeoEvent"
            ) {
              if (local[d] === undefined) {
              }
              newTypes.push({
                label: local[d] === undefined ? d : local[d],
                value: d
              });
            }
          });
          dispatch({
            type: UPDATE_ROLES_CLAIMS_PROPS,
            payload: [{ prop: "claimTypesOptions", value: newTypes }]
          });
        }
      }
    })
    .catch(e => {
      console.log(e);
    })
    .finally();
};

export const setClimsForUser = () => dispatch => {
  SendRequest()
    .get("/Roles/claims")
    .then(res => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          let newTypes = [];

          res.data.forEach(d => {
            newTypes.push({
              value: d
            });
          });

          newTypes.forEach(t => {
            let form = {
              Description: t.value,
              claimType: t.value,
              claimValue: t.value,
              geometry: [
                "POLYGON ((31.441953384730542 30.062574593514263,31.39457484469148 29.94126787940349,31.575162613246167 29.938292814566143,31.597135269496167 30.06198031458582,31.441953384730542 30.062574593514263))"
              ]
            };
            SendRequest()
              .post(`/Roles/Attach/User/Claim/90`, form)
              .then(res => {
                if (res.status === 200) {
                }
              })
              .catch(e => {
                console.log(e);
              })
              .finally();
          });
        }
      }
    })
    .catch(e => {
      console.log(e);
    })
    .finally();
};
