import React from "react";
import GeneralCard from "../../General/GeneralCard";
import { Col, Row, Input, Button, Spinner } from "reactstrap";
import { getLanguage, local } from "../../lang/local";
import { UPDATE_VENUES_PROPS } from "../../Redux/Actions/types";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import Dropzone from "react-dropzone";
import image_icon from "../../assets/img/image_icon.png";

import {
  handleEnglishNameChange,
  handleArabicNameChange,
  renderErrorMessage,
} from "../../General/GeneralFunctions";
import strik from "../../assets/img/strik.png";
import { handleSave, renderPhoto } from "../Venues/VenuesFunctions";

const AddVenueCard = ({
  general,
  form,
  addVenue,
  isLoadingCreate,
  errorMessages,
  fetchLastVenues,
}) => {
  const renderAddVenueCard = () => {
    return (
      <>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {form.Logo === null ? (
              <Dropzone
                onDrop={(acceptedFiles) =>
                  general(
                    [
                      {
                        prop: "data.form.Logo",
                        value: acceptedFiles,
                      },
                    ],
                    UPDATE_VENUES_PROPS
                  )
                }
              >
                {({ getRootProps, getInputProps, acceptedFiles }) => (
                  <div
                    {...getRootProps()}
                    className="fullWidthHeight center cursor"
                  >
                    <input
                      {...getInputProps()}
                      multiple={false}
                      accept="image/*"
                      defaultValue={acceptedFiles}
                    />

                    <Col className="text-center">
                      <div>
                        <img
                          src={image_icon}
                          alt=""
                          style={{ width: "80px", height: "80px" }}
                        />
                      </div>
                    </Col>
                  </div>
                )}
              </Dropzone>
            ) : (
              <div className="center">{renderPhoto(form.Logo, general)}</div>
            )}
            {renderErrorMessage(form.Logo, errorMessages.Logo)}
          </Col>

          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-4">
            <div className="container_add_input mx-auto">
              <img src={strik} alt="strik" className="strik_icon" />
              <Input
                className="add_input"
                value={form.Name.en}
                onChange={(e) =>
                  handleEnglishNameChange(
                    e,
                    general,
                    UPDATE_VENUES_PROPS,
                    "data.form.Name.en"
                  )
                }
                placeholder={local.EnglishName}
              />
            </div>

            {renderErrorMessage(form.Name.en, errorMessages.NameEn)}
          </Col>

          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-4">
            <div className="container_add_input mx-auto">
              <img src={strik} alt="strik" className="strik_icon" />
              <Input
                className="add_input"
                value={form.Name.ar}
                onChange={(e) =>
                  handleArabicNameChange(
                    e,
                    general,
                    UPDATE_VENUES_PROPS,
                    "data.form.Name.ar"
                  )
                }
                placeholder={local.ArabicName}
              />
            </div>
            {renderErrorMessage(form.Name.ar, errorMessages.NameAr)}
          </Col>

          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-4">
            <div className="container_add_input mx-auto">
              <PhoneInput
                country="sa"
                lang={getLanguage() === "ar" ? ar : ""}
                // onlyCountries={["eg", "sa", "ae", "ku", "lb", "om", "bh", "ae"]}
                masks={{
                  eg: ".. ........",
                  sa: ".. ... ....",
                  ae: ".. .......",
                }}
                countryCodeEditable={false}
                value={form.Phone}
                onChange={(mobile) =>
                  general(
                    [
                      {
                        prop: "data.form.Phone",
                        value: mobile,
                      },
                    ],
                    UPDATE_VENUES_PROPS
                  )
                }
              />
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-4 mb-3">
            <Button
              className="save_btn"
              onClick={(e) =>
                handleSave(e, addVenue, form, general, fetchLastVenues)
              }
            >
              {isLoadingCreate === true ? <Spinner /> : local.Save}
            </Button>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Col className="center mt-4" xl={4} lg={4} md={6} sm={12} xs={12}>
      <GeneralCard
        title={local.AddVenue}
        count={null}
        titleColor="blue_color"
        className="text-center maxWidth"
        background="white"
        renderBody={renderAddVenueCard}
      />
    </Col>
  );
};

export default AddVenueCard;
