import {
  UPDATE_USER_ROLES_PROPS,
  MERGE_USER_ROLES_PROPS,
  DELETE_USER_ROLES_PROPS,
  CLEAR_USER_ROLES_STATE,
} from "../Actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  data: {
    items: null,
    pagination: null,
    isLoadingDelete: null,
    filterArray: [],
    form: {
      users: [],
      roles: [],
    },

    errorMessages: {
      User: "",
      Role: "",
    },
    userId: 1,
    item_index: null,
    isLoadingCreate: true,
    isLoading: false,
  },
};

const UserRolesR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_ROLES_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_USER_ROLES_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_USER_ROLES_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_USER_ROLES_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
export default UserRolesR;
