import { local } from "../../lang/local";
import { UPDATE_USER_ROLES_PROPS } from "../../Redux/Actions/types";
import { showConfirmMessage } from "../../General/GeneralFunctions";

export const unsetErrorMessage = (general) => {
  general(
    [
      {
        prop: "data.errorMessages",
        value: { User: "", Role: "" },
      },
    ],
    UPDATE_USER_ROLES_PROPS
  );
};

export const validateForm = (form, general) => {
  var errors = [];

  if (form.users.length === 0 || form.roles.length === 0) {
    window.scrollTo(0, 0);
    if (form.users.length === 0) {
      general(
        [
          {
            prop: "data.errorMessages.User",
            value: local.SelectUser,
          },
        ],
        UPDATE_USER_ROLES_PROPS
      );
    }
    if (form.roles.length === 0) {
      general(
        [
          {
            prop: "data.errorMessages.Role",
            value: local.SelectLayer,
          },
        ],
        UPDATE_USER_ROLES_PROPS
      );
    }
  } else {
    form.roles.map((role) => {
      let entry = form.users.find((f) =>
        f.roles.find((r) => r.id === role.value)
      );

      if (entry !== undefined) {
        errors.push({ user: entry.label, roles: entry.roles });
      }
    });

    if (errors.length > 0) {
      errors.map((err) => {
        showConfirmMessage(
          local.ThisPermission +
            err.roles.map((role) => role.name).toString() +
            local.cannotbeRepeated +
            err.user,
          "error"
        );
      });
    }

    // form.users.map((u) => {
    //   if (u.roles.length > 0) {
    //     let entry = u.roles.filter((u) =>
    //       form.roles.find((role) => role.id === u.value)
    //     );
    //     debugger;

    //     if (entry.length > 0) {
    //       errors.push({ user: u.label, roles: entry });
    //     }
    //     if (errors.length > 0) {
    //       errors.map((err) => {
    //         showConfirmMessage(
    //           local.ThisPermission +
    //             err.roles.map((role) => role.name).toString() +
    //             local.cannotbeRepeated +
    //             err.user,
    //           "error"
    //         );
    //       });
    //     }
    //   }
    // });
    if (errors.length === 0) {
      unsetErrorMessage(general);
      return true;
    }
  }
};

export const handleSave = (e, action_type, form, general) => {
  e.preventDefault();

  if (validateForm(form, general) === true) {
    let newform = Object.assign({}, form);

    action_type(newform);
  }
};
