import React from "react";
import { Col } from "reactstrap";
import { USER_CLAIMS, USER_ROLES } from "../General/StaticKeys";

export const renderPermissions = (
  permissions,
  general,
  value,
  prop,
  action
) => {
  if (permissions !== null) {
    if (permissions.length > 0) {
      return permissions.forEach((per, index) => {
        return (
          <Col className="mt-5 center" key={index}>
            <div style={{ width: "200px" }} className="alignItem">
              <p className="permission_box_title">{per.label}</p>
            </div>
            <div>
              <div className="container" key={index}>
                <div className="round">
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={per.value === value ? true : false}
                    onChange={e =>
                      general([{ prop, value: per.value }], action)
                    }
                  />
                  <label htmlFor="checkbox"></label>
                </div>
              </div>
            </div>
          </Col>
        );
      });
    }
  }
};

const convertPermissionToObject = array => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item.value]: item
    };
  }, initialValue);
};

export const getPermissions = (hasRole, hasClaim) => {
  let roles = null;
  let claims = null;
  let newClamis = [];
  let newRoles = [];

  if (hasRole === true) {
    roles = JSON.parse(localStorage.getItem(USER_ROLES));
  }
  if (hasClaim === true) {
    claims = JSON.parse(localStorage.getItem(USER_CLAIMS));
  }

  if (claims !== undefined) {
    if (claims) {
      if (Array.isArray(claims) && claims.length > 0) {
        claims.forEach(claim => {
          if (claim.claimType === "Contribution_Get") {
            newClamis.push({
              value: claim.claimType,
              route: "/places",
              type: "view"
            });
          }

          if (claim.claimType === "Contribution_Create") {
            newClamis.push({
              value: claim.claimType,
              route: "/addplace",
              type: "add"
            });
          }
          if (claim.claimType === "Venues_Create") {
            newClamis.push({
              value: claim.claimType,
              route: "/addvenue",
              type: "add"
            });
          }

          if (claim.claimType === "Ticket_Get") {
            newClamis.push({ value: claim.claimType, route: "/tickets" });
          }

          if (claim.claimType === "GeoEventType_Create") {
            newClamis.push({
              value: claim.claimType,
              route: "/add_complaint_type",
              type: "add"
            });
          }

          if (claim.claimType === "Branches_Get") {
            newClamis.push(
              { value: claim.claimType, route: "/branches" },
              { value: claim.claimType, route: "/distributors" }
            );
          }

          if (claim.claimType === "GeoEvent_Get") {
            newClamis.push({
              value: "Geo_Event",
              route: "/complaints",
              type: "view"
            });
          }

          if (claim.claimType === "Ticket_Create") {
            newClamis.push({ value: claim.claimType, route: "/addticket" });
          }
          if (claim.claimType === "Branches_Create") {
            newClamis.push(
              { value: claim.claimType, route: "/addbranch" },
              { value: claim.claimType, route: "/add_distributor" }
            );
          }
          if (claim.claimType === "Venues_Get") {
            newClamis.push({ value: claim.claimType, route: "/venues" });
          }
          if (claim.claimType === "GetUsers") {
            newClamis.push({ value: claim.claimType, route: "/users" });
          }
          if (claim.claimType === "RoleBusiness_GetRoles") {
            newClamis.push({ value: claim.claimType, route: "/roles" });
          }
          if (claim.claimType === "RoleBusiness_Create") {
            newClamis.push({ value: claim.claimType, route: "/addrole" });
          }
          if (claim.claimType === "RoleBusiness_AttachRoleClaim") {
            newClamis.push({
              value: claim.claimType,
              route: "/add/roles/attach/claims"
            });
          }
          if (claim.claimType === "RoleBusiness_GetUserRole") {
            newClamis.push({
              value: claim.claimType,
              route: "/users/roles"
            });
          }

          if (claim.claimType === "RoleBusiness_AttachUserRole") {
            newClamis.push({
              value: claim.claimType,
              route: "/add/user/attach/roles"
            });
          }

          if (claim.claimType === "RoleBusiness_GetUserClaims") {
            newClamis.push({
              value: claim.claimType,
              route: "/users/claims"
            });
          }
          if (claim.claimType === "RoleBusiness_AttachUserClaim") {
            newClamis.push({
              value: claim.claimType,
              route: "/add/user/attach/claims"
            });
          }
          if (claim.claimType === "Support_Role") {
            newClamis.push({
              value: "support",
              route: "/support",
              type: "view"
            });
          }
          if (claim.claimType === "Home_Role") {
            newClamis.push({ value: "home", route: "/", type: "view" });
          } else {
            newClamis.push({ value: claim.claimType });
          }
        });
      }
    }
  }

  if (roles !== undefined) {
    if (roles !== null) {
      if (Array.isArray(roles) && roles.length > 0) {
        roles.forEach(role => {
          if (role.name === "Administrator") {
            newRoles.push({ value: role.name, route: "/" });
          } else {
            newRoles.push({ value: role.name });
          }
        });
      }
    }
  }

  let permissions = [];
  if (newClamis.length > 0) {
    permissions = permissions.concat(newClamis);
  }

  if (newRoles.length > 0) {
    permissions = permissions.concat(newRoles);
  }
  permissions.push(
    { value: "reviews", route: "/reviews", type: "view" },
    { value: "business_account", route: "/business_account", type: "view" }
  );
  if (permissions.length > 0) {
    return convertPermissionToObject(permissions);
  }
};

//rest of permissions

// AnswersBusiness_Create has no permission

// AnswersBusiness_GetAnswers has no permission
// Device_Create
// Device_GetDevices

// QuestionsBusiness_Create has no permission
// QuestionsBusiness_GetQuestions has no permission
// ReviewsBusiness_Create has no permission

// UserPlacesRegistered_Create
// UserPlacesRegistered_GetUserPlaceRegistered
// UserPlacesUnRegistered_Create
// UserPlacesUnRegistered_GetUserPlaceUnregistered
