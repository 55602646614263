import React from "react";
import { Row, Col, Card } from "reactstrap";
import GeneralCard from "../../General/GeneralCard";
import SwitchItem from "../../General/SwitchItem";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { local } from "../../lang/local";
import avater2 from "../../assets/img/avater2.svg";
import plus from "../../assets/img/plus_icon.png";
import { renderImage, renderName } from "../../General/GeneralFunctions";
import { UPDATE_PLACES_PROPS } from "../../Redux/Actions/types";
const ReviewCard = ({ places, general, history, users }) => {
  const preparePlaces = (arrays) => {
    if (arrays) {
      if (arrays.length > 0) {
        let items = [];
        let totalUsers = arrays.map((t) => t.userId);

        let uniqueUsers = totalUsers.filter(
          (v, i, a) =>
            a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        );
        uniqueUsers.forEach((usr) => {
          let entry = arrays.find((arr) => arr.userId === usr);
          let user = null;
          if (entry) {
            user = entry.user;
          }
          let places = arrays.filter((arr) => arr.userId === usr);

          if (places.length > 0) {
            items.push({ places, user });
          }
        });

        return items;
      }
    }
  };
  const UserNotificationBadge = ({ item }) => {
    if (!item.user) {
      if (users.length > 0) {
        let entry = users.find((u) => u.id === item.places[0].userId);
        if (entry !== undefined) {
          item.user = entry;
        }
      }
    }
    const useStyles = makeStyles((theme) => ({
      shape: {
        width: "47px",
        height: "47px",
        border: "none",
      },
      shapeCircle: {
        borderRadius: "100%",
        border: "none",
      },
    }));

    const classes = useStyles();

    return (
      <div>
        <Badge
          color="error"
          overlap="circle"
          badgeContent={item.places.length}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <div className={clsx(classes.shape, classes.shapeCircle)}>
            <center>
              <div className="img_review_container">
                <img
                  className="imgFull"
                  style={{ borderRadius: "100%" }}
                  src={!item.user ? avater2 : renderImage(item.user.image)}
                />
              </div>
            </center>
          </div>
        </Badge>
        <p className="blue_color">
          {item.user === null ? "" : item.user.firstName}
        </p>
      </div>
    );
  };

  const PlaceNotificationBadge = ({ title }) => {
    const useStyles = makeStyles((theme) => ({
      shape: {
        background: "#002345",
        width: "35px",
        height: "35px",
        borderRadius: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      shapeCircle: {
        borderRadius: "50%",
      },
    }));

    const classes = useStyles();

    return (
      <div className={classes.root}>
        <Badge
          badgeContent={<img src={plus} />}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <div className={classes.shape}>
            <p style={{ fontSize: "10px" }}>{title}</p>
          </div>
        </Badge>
      </div>
    );
  };

  const handleCardClick = (e, item) => {
    e.preventDefault();
    if (item) {
      general(
        [{ prop: "data.watiningPlace", value: item.places }],
        UPDATE_PLACES_PROPS
      );
    }

    history.push("/dashboard/places");
  };
  const renderReviewPlaces = () => {
    if (places.items) {
      if (places.items.length > 0) {
        let newPlaces = preparePlaces(places.items);

        return (
          <div>
            {newPlaces.map((item, index) => {
              return (
                <Row key={index}>
                  <Col xl={12}>
                    <Card
                      className="place_review_card cursor"
                      style={{ height: "90px" }}
                      onClick={(e) => handleCardClick(e, item)}
                    >
                      <Row className="alignItem place_card_div">
                        <Col
                          xl={3}
                          lg={3}
                          md={3}
                          sm={3}
                          xs={3}
                          className="center"
                        >
                          <UserNotificationBadge item={item} />
                        </Col>

                        {item.places.map((p, x) => {
                          if (x <= 3) {
                            return (
                              <Col
                                key={p.id}
                                xl={2}
                                lg={2}
                                md={2}
                                sm={2}
                                xs={2}
                                className="center mt-1"
                              >
                                <PlaceNotificationBadge
                                  title={renderName(p.name)}
                                />
                              </Col>
                            );
                          }
                        })}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              );
            })}
          </div>
        );
      }
    }
  };

  return (
    <Col className="center mt-4" xl={4} lg={4} md={6} sm={12} xs={12}>
      <GeneralCard
        title={local.PlacesReview}
        count={places.count}
        titleColor="blue_color"
        className="text-center maxWidth"
        background="white"
        renderBody={renderReviewPlaces}
        handleClick={handleCardClick}
      />
    </Col>
  );
};

export default ReviewCard;
