import { getVenueForDropDown } from ".";
import {
  renderImages,
  storeWorkingHours,
} from "../../Pages/Branches/BranchFunctions";
import {
  convertToFormData,
  filterWorkingHours,
  showConfirmMessage,
  showSuccessedConfirm,
} from "../../General/GeneralFunctions";
import { local } from "../../lang/local";
import { SendRequest } from "./api";
import {
  DELETE_BRANCHES_PROPS,
  UPDATE_BRANCHES_PROPS,
  UPDATE_BUSINESS_PROPS,
  UPDATE_DISTRIBUTORS_PROPS,
} from "./types";

export const fetchBranches = (filterArray, page) => (dispatch) => {
  if (filterArray.length > 1) {
    dispatch({
      type: UPDATE_BRANCHES_PROPS,
      payload: [{ prop: "data.isLoadingSearch", value: true }],
    });
  }
  dispatch({
    type: UPDATE_BRANCHES_PROPS,
    payload: [{ prop: "data.isLoading", value: true }],
  });
  SendRequest()
    .post(`/Branch/Get?page=${page}`, filterArray)
    .then((res) => {
      if (res.status === 200) {
        let pagination = Object.assign({}, res.data.data);
        delete pagination.items;
        if (res.data.data.items.length === 0 && filterArray.length > 1) {
          showConfirmMessage(local.NoSearch, "error");
        }
        dispatch({
          type: UPDATE_BRANCHES_PROPS,
          payload: [
            { prop: "data.items", value: res.data.data.items },
            { prop: "data.pagination", value: pagination },
          ],
        });
      }

      dispatch({
        type: UPDATE_BRANCHES_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_BRANCHES_PROPS,
        payload: [
          { prop: "data.isLoading", value: false },
          { prop: "data.isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const addBranch = (formValues, venueId, callback) => (dispatch) => {
  dispatch({
    type: UPDATE_BRANCHES_PROPS,
    payload: [{ prop: "isLoadingCreate", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);
  newFormValues.WorkingHours = filterWorkingHours(newFormValues.WorkingHours);

  let formData = convertToFormData(newFormValues);
  SendRequest()
    .post(`/Branch/${venueId}`, formData)
    .then((res) => {
      if (res.status === 200) {
        if (callback) {
          callback();
        }
        showSuccessedConfirm(local.AddedSuccessfully);
        dispatch({
          type: UPDATE_BRANCHES_PROPS,
          payload: [
            {
              prop: "data.form",
              value: {
                Name: { en: "", ar: "" },
                WorkingHours: [
                  { day: "Monday", from: null, to: null },
                  { day: "Tuesday", from: null, to: null },
                  { day: "Wednesday", from: null, to: null },
                  { day: "Thursday", from: null, to: null },
                  { day: "Friday", from: null, to: null },
                  { day: "Saturday", from: null, to: null },
                  { day: "Sunday", from: null, to: null },
                ],
                Photos: [],
                Phone: "",
                Lat: 0,
                Lng: 0,
                Type: 0,
              },
            },

            {
              prop: "data.venueId",
              value: null,
            },
          ],
        });

        dispatch({
          type: UPDATE_BUSINESS_PROPS,
          payload: [
            {
              prop: "data.form",
              value: {
                Name: { en: "", ar: "" },
                WorkingHours: [
                  { day: "Monday", from: null, to: null },
                  { day: "Tuesday", from: null, to: null },
                  { day: "Wednesday", from: null, to: null },
                  { day: "Thursday", from: null, to: null },
                  { day: "Friday", from: null, to: null },
                  { day: "Saturday", from: null, to: null },
                  { day: "Sunday", from: null, to: null },
                ],
                Photos: [],
                Phone: "",
                Lat: 0,
                Lng: 0,
                Type: 0,
              },
            },

            {
              prop: "data.venueId",
              value: null,
            },
          ],
        });
      }
      dispatch({
        type: UPDATE_BRANCHES_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
    })
    .catch((e) => {
      if (Object.values(e)[2].data) {
        if (Object.values(e)[2].data.message) {
          showConfirmMessage(Object.values(e)[2].data.message, "error");
        }
      }
      dispatch({
        type: UPDATE_BRANCHES_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const updateBranch = (formValues, history) => (dispatch) => {
  dispatch({
    type: UPDATE_BRANCHES_PROPS,
    payload: [{ prop: "isLoadingCreate", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);
  newFormValues.WorkingHours = filterWorkingHours(newFormValues.WorkingHours);
  let formData = convertToFormData(newFormValues);
  SendRequest()
    .put(`/Branch?branchId=${newFormValues.id}`, formData)
    .then((res) => {
      if (res.status === 200) {
        showSuccessedConfirm(local.SuccessfullyUpdated);

        history.push("/dashboard/branches");
      }
      dispatch({
        type: UPDATE_BRANCHES_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_BRANCHES_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const DeleteBranch = (id, index) => (dispatch) => {
  dispatch({
    type: UPDATE_BRANCHES_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: null }],
  });
  SendRequest()
    .delete(`Branch?branchId=${id}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_BRANCHES_PROPS,
          payload: [
            {
              prop: "data.items." + index,
            },
          ],
        });
        showSuccessedConfirm(local.DeletedSuccessfully);
      }
      dispatch({
        type: UPDATE_BRANCHES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
    })
    .catch((err) => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_BRANCHES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
      console.log(err);
    })
    .finally();
};

export const deleteMultipleBranches = (ids, setChecked) => (dispatch) => {
  dispatch({
    type: UPDATE_BRANCHES_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: true }],
  });

  SendRequest()
    .delete(`Branch?branchId=${ids[0]}`)
    .then((res) => {
      if (res.status === 200) {
        ids.shift();

        if (ids.length > 0) {
          dispatch(deleteMultipleBranches(ids, setChecked));
        } else {
          setChecked([]);
          showSuccessedConfirm(local.DeletedSuccessfully);

          dispatch(fetchBranches([], 1));
        }
      }
      dispatch({
        type: UPDATE_BRANCHES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
    })
    .catch((err) => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_BRANCHES_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
      console.log(err);
    })
    .finally();
};

export const fetchBranchOrDistributor = (id, type) => (dispatch) => {
  SendRequest()
    .post(`/Branch/Get`, [
      {
        op: 0,
        fieldName: "Id",
        value: id,
      },
    ])
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data) {
          if (res.data.data.items) {
            let item = res.data.data.items[0];
            let enName = item.name ? (item.name.en ? item.name.en : "") : "";
            let arName = item.name ? (item.name.ar ? item.name.ar : "") : "";
            let phone = item.phone ? item.phone : "";
            dispatch(getVenueForDropDown(null, item.venueId, "id"));
            dispatch({
              type:
                type === 0 ? UPDATE_BRANCHES_PROPS : UPDATE_DISTRIBUTORS_PROPS,
              payload: [
                {
                  prop: "data.form",
                  value: {
                    Name: { en: enName, ar: arName },
                    WorkingHours: storeWorkingHours(item.workingHours),
                    Photos: renderImages(item.photos),
                    Phone: phone,
                    Lat: +item.locationText.split(" ")[2].split(")")[0],
                    Lng: +item.locationText.split(" ")[1].split("(")[1],
                    Type: item.type,
                    id,
                  },
                },

                {
                  prop: "data.venueId",
                  value: item.venueId,
                },
              ],
            });
          }
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
