import React from "react";
import { Spinner, Row, Col, Button, Input } from "reactstrap";
import {
  columnAlignLeft,
  isRtl,
  selectSearchStyle,
} from "../../General/GeneralStyle";
import { getLanguage, local } from "../../lang/local";
import Select from "react-select";
import { UPDATE_USERS_PROPS } from "../../Redux/Actions/types";
import {
  removeUnsetValuesFromFilters,
  showConfirmMessage,
} from "../../General/GeneralFunctions";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import { getDefaultValue } from "../../General/SelectFunctions";
const UserSearch = ({
  filterArray,
  general,
  isLoading,
  fetchUsers,
  GetStatUsers,
  userType,
  setType,
  userTypes,
}) => {
  const ActiviationOptions = [
    {
      label: local.ActiveByMobile,
      value: 0,
      title: "phoneNumberConfirmed",
      isActive: true,
    },
    {
      label: local.ActiveByEmail,
      value: 1,
      title: "EmailConfirmed",
      isActive: true,
    },
    {
      label: local.InActiveByMobile,
      value: 2,
      title: "phoneNumberConfirmed",
      isActive: false,
    },
    {
      label: local.InActiveByEmail,
      value: 3,
      title: "EmailConfirmed",
      isActive: false,
    },
  ];

  const handleSearch = (e) => {
    e.preventDefault();

    if (filterArray.find((f) => f.value !== "" && f.value !== null)) {
      let newFilterArr = [...filterArray];

      newFilterArr = removeUnsetValuesFromFilters(newFilterArr);
      GetStatUsers(newFilterArr);
      fetchUsers(newFilterArr, 1);
    } else {
      showConfirmMessage(local.AnyDataRequired, "error");
    }
  };
  const getDefaultActive = () => {
    if (filterArray[3].value !== null) {
      let item = ActiviationOptions.find(
        (e) =>
          e.isActive === filterArray[3].value &&
          e.title === filterArray[3].fieldName
      );
      if (item) {
        debugger;

        return item;
      } else {
        return null;
      }
    }
  };
  return (
    <div>
      <Row className="center">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Row className="alignItem">
            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <Input
                className="search_input"
                placeholder={local.Name}
                value={filterArray[0].value}
                onChange={(e) =>
                  general(
                    [
                      {
                        prop: "data.filterArray." + 0 + ".value",
                        value: e.target.value,
                      },
                    ],
                    UPDATE_USERS_PROPS
                  )
                }
              />
            </Col>

            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <Input
                className="search_input"
                placeholder={local.Email}
                value={filterArray[4].value}
                onChange={(e) =>
                  general(
                    [
                      {
                        prop: "data.filterArray." + 4 + ".value",
                        value: e.target.value,
                      },
                    ],
                    UPDATE_USERS_PROPS
                  )
                }
              />
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <Input
                className="search_input"
                placeholder={local.CountryCode}
                value={filterArray[1].value}
                onChange={(e) =>
                  general(
                    [
                      {
                        prop: "data.filterArray." + 1 + ".value",
                        value: e.target.value,
                      },
                    ],
                    UPDATE_USERS_PROPS
                  )
                }
              />
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              {/* <Input className="search_input" placeholder="Name" /> */}
              <div className="search_phone_container">
                <PhoneInput
                  lang={getLanguage() === "ar" ? ar : ""}
                  country="sa"
                  // onlyCountries={[
                  //   "eg",
                  //   "sa",
                  //   "ae",
                  //   "ku",
                  //   "lb",
                  //   "om",
                  //   "bh",
                  //   "ae",
                  // ]}
                  masks={{
                    eg: ".. ........",
                    sa: ".. ... ....",
                    ae: ".. .......",
                  }}
                  countryCodeEditable={false}
                  value={filterArray[2].value}
                  onChange={(phone) =>
                    general(
                      [
                        {
                          prop: "data.filterArray." + 2 + ".value",
                          value: phone,
                        },
                      ],
                      UPDATE_USERS_PROPS
                    )
                  }
                />
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <div className="search_select_container">
                <Select
                  key={filterArray[3].value}
                  placeholder={local.Activation}
                  isRtl={isRtl()}
                  styles={selectSearchStyle}
                  isSearchable={true}
                  options={ActiviationOptions}
                  cacheOptions
                  isClearable={true}
                  value={getDefaultActive()}
                  onChange={(e) =>
                    e
                      ? general(
                          [
                            {
                              prop: "data.filterArray." + 3 + ".value",
                              value: e.isActive,
                            },
                            {
                              prop: "data.filterArray." + 3 + ".fieldName",
                              value: e.title,
                            },
                          ],
                          UPDATE_USERS_PROPS
                        )
                      : general(
                          [
                            {
                              prop: "data.filterArray." + 3 + ".value",
                              value: null,
                            },
                            {
                              prop: "data.filterArray." + 3 + ".fieldName",
                              value: null,
                            },
                          ],
                          UPDATE_USERS_PROPS
                        )
                  }
                />
              </div>
            </Col>

            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <div className="search_select_container">
                <Select
                  key={userType}
                  placeholder={local.Activation}
                  isRtl={isRtl()}
                  styles={selectSearchStyle}
                  isSearchable={true}
                  options={userTypes}
                  cacheOptions
                  value={getDefaultValue(userType, userTypes)}
                  isClearable={false}
                  onChange={(e) => (e ? setType(e.value) : setType(null))}
                />
              </div>
            </Col>

            <Col
              xl={3}
              lg={3}
              md={3}
              sm={6}
              xs={12}
              className={columnAlignLeft() + " mt-4"}
            >
              <Button
                disabled={isLoading}
                className="search_btn"
                onClick={handleSearch}
              >
                {isLoading === true ? <Spinner color="#fff" /> : local.Search}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default UserSearch;
