import React from "react";
import { Spinner, Row, Col, Button, Input, Card } from "reactstrap";
import {
  columnAlignLeft,
  isRtl,
  selectSearchStyle,
} from "../../General/GeneralStyle";
import { getLanguage, local } from "../../lang/local";
import Select from "react-select";
import {
  getDefaultValue,
  getDefaultLayer,
} from "../../General/SelectFunctions";
import { UPDATE_CONTRIBUTION_PROPS } from "../../Redux/Actions/types";
import {
  removeUnsetValuesFromFilters,
  showConfirmMessage,
} from "../../General/GeneralFunctions";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import { getPermissions } from "../../utils/Permissions";
import { AsyncPaginate } from "react-select-async-paginate";

const ContribuationSearch = ({
  usersOptions,
  filterArray,
  general,
  isLoading,
  fetchContribtions,
  layersOptions,
  loadUserOptions,
}) => {
  const handleSearch = (e) => {
    e.preventDefault();

    if (filterArray.find((f) => f.value !== "" && f.value !== null)) {
      let newFilterArr = [...filterArray];

      newFilterArr = removeUnsetValuesFromFilters(newFilterArr);
      fetchContribtions(newFilterArr, 1);
    } else {
      showConfirmMessage(local.AnyDataRequired, "error");
    }
  };

  const onChangeName = (value) => {
    let arregex = /[\u0600-\u06FF]/;

    let lang = "en";
    if (arregex.test(value) === true) {
      lang = "ar";
    } else {
      lang = "en";
    }

    general(
      [
        { prop: "data.filterArray." + 0 + ".value", value: value },
        {
          prop: "data.filterArray." + 0 + ".fieldName",
          value: `Name@${lang}`,
        },
      ],
      UPDATE_CONTRIBUTION_PROPS
    );
  };

  return (
    <Card className="search_card">
      <Row className="mt-2 mb-3">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Row className="alignItem">
            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <Input
                className="search_input"
                placeholder={local.Name}
                value={filterArray[0].value}
                onChange={(e) => onChangeName(e.target.value)}
              />
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              {/* <Input className="search_input" placeholder="Name" /> */}
              <div className="search_phone_container">
                <PhoneInput
                  country="sa"
                  lang={getLanguage() === "ar" ? ar : ""}
                  // onlyCountries={[
                  //   "eg",
                  //   "sa",
                  //   "ae",
                  //   "ku",
                  //   "lb",
                  //   "om",
                  //   "bh",
                  //   "ae",
                  // ]}
                  masks={{
                    eg: ".. ........",
                    sa: ".. ... ....",
                    ae: ".. .......",
                  }}
                  countryCodeEditable={false}
                  value={filterArray[1].value}
                  onChange={(phone) =>
                    general(
                      [
                        {
                          prop: "data.filterArray." + 1 + ".value",
                          value: phone,
                        },
                      ],
                      UPDATE_CONTRIBUTION_PROPS
                    )
                  }
                />
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
              <div className="search_select_container">
                <Select
                  key={filterArray[2].value}
                  placeholder={local.Layer}
                  isRtl={isRtl()}
                  styles={selectSearchStyle}
                  isSearchable={true}
                  options={layersOptions}
                  cacheOptions
                  isClearable={true}
                  value={getDefaultLayer(filterArray[2].value, layersOptions)}
                  onChange={(e) =>
                    e !== null
                      ? general(
                          [
                            {
                              prop: "data.filterArray." + 2 + ".value",
                              value: e.value.act_layers[0],
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        )
                      : general(
                          [
                            {
                              prop: "data.filterArray." + 2 + ".value",
                              value: null,
                            },
                          ],
                          UPDATE_CONTRIBUTION_PROPS
                        )
                  }
                />
              </div>
            </Col>

            {getPermissions(true, true).UserBusiness_GetUsers !== undefined ||
            getPermissions(true, true).Administrator !== undefined ? (
              <Col xl={3} lg={3} md={3} sm={6} xs={12} className="mt-4">
                <div className="search_select_container">
                  <AsyncPaginate
                    key={filterArray[3].value}
                    placeholder={local.User}
                    value={getDefaultValue(filterArray[3].value, usersOptions)}
                    loadOptions={loadUserOptions}
                    onChange={(e) =>
                      e !== null
                        ? general(
                            [
                              {
                                prop: "data.filterArray." + 3 + ".value",
                                value: +e.value,
                              },
                            ],
                            UPDATE_CONTRIBUTION_PROPS
                          )
                        : general(
                            [
                              {
                                prop: "data.filterArray." + 3 + ".value",
                                value: null,
                              },
                            ],
                            UPDATE_CONTRIBUTION_PROPS
                          )
                    }
                    additional={{
                      page: 1,
                    }}
                    styles={selectSearchStyle}
                    isClearable={true}
                    isSearchable={true}
                  />
                  {/* <AsyncSelect
                    key={filterArray[3].value}
                    placeholder={local.User}
                    isRtl={isRtl()}
                    styles={selectSearchStyle}
                    isSearchable={true}
                    defaultOptions={usersOptions}
                    loadOptions={() => promiseOptions(usersOptions)}
                    onInputChange={(e) => (e !== "" ? debounced(e) : null)}
                    cacheOptions
                    isClearable={true}
                    value={getDefaultValue(filterArray[3].value, usersOptions)}
                    onChange={(e) =>
                      e !== null
                        ? general(
                            [
                              {
                                prop: "data.filterArray." + 3 + ".value",
                                value: +e.value,
                              },
                            ],
                            UPDATE_CONTRIBUTION_PROPS
                          )
                        : general(
                            [
                              {
                                prop: "data.filterArray." + 3 + ".value",
                                value: null,
                              },
                            ],
                            UPDATE_CONTRIBUTION_PROPS
                          )
                    }
                  /> */}
                </div>
              </Col>
            ) : null}
            <Col
              xl={3}
              lg={3}
              md={3}
              sm={6}
              xs={12}
              className={columnAlignLeft() + " mt-4"}
            >
              <Button
                disabled={isLoading}
                className="search_btn"
                onClick={handleSearch}
              >
                {isLoading === true ? <Spinner color="#fff" /> : local.Search}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ContribuationSearch;
