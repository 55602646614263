import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import { leftStyle, rightStyle } from '../../../General/GeneralStyle';
import { webAsideCardStyle } from '../../../utils/MapViewStyle';
import close_icon from '../../../assets/img/close_icon.png';
import {
  UPDATE_CONTRIBUTION_PROPS,
  UPDATE_USER_ACCOUNT_PROPS,
} from '../../../Redux/Actions/types';
import { getLanguage, local } from '../../../lang/local';
import cont_icon from '../../../assets/img/cont_icon.png';
import PaginationItem from '../../../General/PaginationItem';
import {
  convert_to_lat_lng_point,
  renderName,
} from '../../../General/GeneralFunctions';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/Actions/index';
import { handlePlaceClick } from '../SearchMenu/SearchFunctions';
import { IMAGE_BASE_PATH } from '../../../General/StaticKeys';
const Contribution = ({
  data,
  general,
  fetchContribtions,
  ipAddress,
  getSocketResult,
  myPosition,
  searchImages,
}) => {
  const closeInfo = (e) => {
    e.preventDefault();
    general(
      [
        {
          prop: 'data.isOpen',
          value: false,
        },
      ],
      UPDATE_CONTRIBUTION_PROPS
    );
    general(
      [
        {
          prop: 'userAccount.isOpen',
          value: true,
        },
      ],
      UPDATE_USER_ACCOUNT_PROPS
    );
  };

  const handleCardClick = (e, place) => {
    general(
      [
        {
          prop: 'data.isOpen',
          value: false,
        },
      ],
      UPDATE_CONTRIBUTION_PROPS
    );
    let lat = convert_to_lat_lng_point(place.geometryText, 'lat');
    let lng = convert_to_lat_lng_point(place.geometryText, 'lng');
    let item = {
      value: [lng, lat],
      label: place.name[local.subLang],
      data: { properties: { gid: place.gisId } },
      layer: place.layer,
    };
    handlePlaceClick(
      e,
      item,
      general,
      getSocketResult,
      myPosition,
      ipAddress,
      searchImages
    );
  };
  const renderPlaces = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return data.items.map((item) => {
          let image = '';
          if (item.images) {
            let entry = item.images.find((img) => img);
            if (entry) {
              image = IMAGE_BASE_PATH + entry;
            } else {
              image = cont_icon;
            }
          } else {
            image = cont_icon;
          }
          return (
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mt-3'>
              <Card
                className='cont_card cursor'
                onClick={(e) => handleCardClick(e, item)}
              >
                <div className='fullWidthHeight flex alignItem'>
                  <span className='img_cont'>
                    <img src={image} className='imgFull' alt='' />
                  </span>
                  <div
                    className='desc_cont'
                    style={{
                      textAlign: getLanguage() === 'ar' ? 'right' : 'left',
                    }}
                  >
                    <p className='desc_cont_overview1'>
                      {renderName(item.name)}
                      <p className='desc_cont_overview2'>
                        {item.osm.description.key}
                      </p>
                    </p>
                  </div>
                </div>
              </Card>
            </Col>
          );
        });
      }
    }
  };

  return (
    <Card className='web_view_card' style={webAsideCardStyle}>
      <Row>
        <Col>
          <img
            src={close_icon}
            alt='close_icon cursor'
            style={rightStyle}
            onClick={(e) => closeInfo(e)}
          />
          <div style={leftStyle}>
            <div className='center'>
              <p
                className='card_list_title'
                style={{ marginInlineStart: '10px' }}
              >
                {local.Contribution}
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>{renderPlaces()}</Row>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mt-3'>
          {data.pagination !== null ? (
            <PaginationItem
              pagination={data.pagination}
              filterArray={data.filterArray}
              general={general}
              fetchData={fetchContribtions}
            />
          ) : null}
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = ({ ContribuationsR, GeneralR }) => {
  return {
    data: ContribuationsR.data,
    ipAddress: GeneralR.ipAddress,
    myPosition: GeneralR.myPosition,
  };
};
export default connect(mapStateToProps, actions)(Contribution);
