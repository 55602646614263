import React, { useState } from "react";
import { Button, Row, Col, Input, Card, Spinner } from "reactstrap";
import { local } from "../../lang/local";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  DELETE_TICKETS_PROPS,
  MERGE_TICKETS_PROPS,
  UPDATE_GENERAL_PROPS,
  UPDATE_TICKETS_PROPS,
} from "../../Redux/Actions/types";
import {
  checkIsLink,
  saveImage,
  showConfirmMessage,
} from "../../General/GeneralFunctions";
import Dropzone from "react-dropzone";
import { FaRegImage, FaLink } from "react-icons/fa";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";
import { FiX } from "react-icons/fi";

const EditTicketMessage = ({ data_message, general, editTicketMessage }) => {
  const { form, isOpen } = data_message;
  const [link, setLink] = useState("");

  const close = () => {
    general(
      [{ prop: "data_message.isOpen", value: false }],
      UPDATE_TICKETS_PROPS
    );
  };

  const handleSave = (e) => {
    e.preventDefault();

    editTicketMessage(form);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleLankSave = (e) => {
    e.preventDefault();

    if (link) {
      if (checkIsLink(link) === true) {
        general(
          [{ prop: "data_message.form.Attachments", value: link }],
          MERGE_TICKETS_PROPS
        );
        setLink("");
      } else {
        showConfirmMessage(local.NotUrl, "error");
      }
    } else {
      showConfirmMessage(local.EnterLink, "error");
    }
  };

  const renderRemove = (index) => {
    return (
      <div className="remove_icon center">
        <FiX
          color="white"
          size={30}
          style={{ zIndex: "10000", cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            general(
              [
                {
                  prop: "data_message.form.Attachments." + index,
                },
              ],
              DELETE_TICKETS_PROPS
            );
          }}
        />
      </div>
    );
  };

  const renderAttachments = () => {
    if (data_message.form.Attachments) {
      if (data_message.form.Attachments.length > 0) {
        return (
          <Row className="center mx-auto">
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="mx-auto flex alignItem mt-3"
            >
              {data_message.form.Attachments.map((attach, index) => {
                if (attach instanceof File) {
                  if (attach.type.includes("image")) {
                    return (
                      <Card key={index} className="preview_card_message">
                        {renderRemove(index)}
                        <a
                          key={index}
                          // href={URL.createObjectURL(attach)}
                          className="imgFull cursor"
                          rel="noreferrer"
                          onClick={(e) => {
                            e.preventDefault();
                            general(
                              [
                                {
                                  prop: "lightbox",
                                  value: {
                                    image: URL.createObjectURL(attach),
                                    isOpen: true,
                                    type: "single",
                                  },
                                },
                              ],
                              UPDATE_GENERAL_PROPS
                            );
                          }}
                        >
                          <img
                            src={URL.createObjectURL(attach)}
                            className="imgFull borderR10"
                            alt=""
                          />
                        </a>
                      </Card>
                    );
                  } else if (
                    attach.type.includes("audio") ||
                    attach.type.includes("blob")
                  ) {
                    return (
                      <Card className="preview_card_message">
                        {renderRemove(index)}
                        <audio controls className="chat_box_file" key={index}>
                          <source
                            src={URL.createObjectURL(attach)}
                            type={attach.type}
                          />
                        </audio>
                      </Card>
                    );
                  } else {
                    return (
                      <Card className="preview_card_message">
                        {renderRemove(index)}
                        <a
                          key={index}
                          href={URL.createObjectURL(attach)}
                          className="chat_box_file"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {attach.name}
                        </a>
                      </Card>
                    );
                  }
                } else {
                  if (
                    attach.includes("png") ||
                    attach.includes("jpg") ||
                    attach.includes("jpeg") ||
                    attach.includes("ico") ||
                    attach.includes("icon" || attach.includes("gif"))
                  ) {
                    return (
                      <Card key={index} className="preview_card_message">
                        {renderRemove(index)}

                        <a
                          key={index}
                          onClick={(e) => {
                            e.preventDefault();
                            general(
                              [
                                {
                                  prop: "lightbox",
                                  value: {
                                    image: IMAGE_BASE_PATH + attach,
                                    isOpen: true,
                                    type: "single",
                                  },
                                },
                              ],
                              UPDATE_GENERAL_PROPS
                            );
                          }}
                          className="imgFull cursor"
                          rel="noreferrer"
                        >
                          <img
                            src={IMAGE_BASE_PATH + attach}
                            className="imgFull borderR10"
                            alt=""
                          />
                        </a>
                      </Card>
                    );
                  } else if (attach.includes("audio")) {
                    return (
                      <Card className="preview_card_message">
                        {renderRemove(index)}

                        <audio controls className="chat_box_file" key={index}>
                          <source src={attach} />
                        </audio>
                      </Card>
                    );
                  } else {
                    return (
                      <Card className="preview_card_message">
                        {renderRemove(index)}
                        <a
                          key={index}
                          href={
                            checkIsLink(attach) === true
                              ? attach
                              : IMAGE_BASE_PATH + attach
                          }
                          className="chat_box_file"
                          target="_blank"
                        >
                          {attach}
                        </a>
                      </Card>
                    );
                  }
                }
              })}
            </Col>
          </Row>
        );
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={fullScreen}
        open={isOpen}
        // onClose={toggle}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="profile_card">
          <DialogTitle id="responsive-dialog-title center">
            <h3 className="text-center mt-3">{local.EditTicketMessage}</h3>
          </DialogTitle>
          <DialogContent>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Row>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="mx-auto"
                  >
                    <div
                      className="container_add_input mx-auto"
                      style={{ height: "fit-content" }}
                    >
                      <Input
                        rows={10}
                        type="textarea"
                        className="add_input"
                        placeholder={local.TicketMessage}
                        value={form.Msg.text}
                        onChange={(e) =>
                          general(
                            [
                              {
                                prop: "data_message.form.Msg.text",
                                value: e.target.value,
                              },
                            ],
                            UPDATE_TICKETS_PROPS
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="mx-auto mt-4"
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        saveImage(
                          acceptedFiles,
                          "data_message.form.Attachments",
                          general,
                          MERGE_TICKETS_PROPS
                        )
                      }
                    >
                      {({ getRootProps, getInputProps, acceptedFiles }) => (
                        <div
                          {...getRootProps()}
                          className="fullWidthHeight center cursor"
                        >
                          <input
                            {...getInputProps()}
                            multiple={false}
                            accept="image/*,audio/*,.doc,.pdf"
                            defaultValue={acceptedFiles}
                          />

                          <div
                            className="container_add_input flex alignItem cursor"
                            style={{ width: "100%" }}
                          >
                            <Input
                              className="add_input cursor"
                              placeholder={
                                local.Photo +
                                "/" +
                                local.Files +
                                "/" +
                                local.Audio
                              }
                              disabled={true}
                            />
                            <FaRegImage
                              size={30}
                              style={{ marginInlineEnd: "10px" }}
                              color="#12a9cc"
                            />
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                </Row>

                <Row>
                  <Col xl={10} lg={10} md={10} sm={10} xs={10} className="mt-4">
                    <div className="container_add_input mx-auto">
                      <Input
                        className="add_input"
                        placeholder={local.VideoLink}
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                      />

                      <FaLink
                        size={25}
                        style={{ marginInlineEnd: "10px" }}
                        color="#12a9cc"
                      />
                    </div>
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="mt-4">
                    <Button
                      onClick={handleLankSave}
                      className="save_btn mx-auto"
                    >
                      {local.Save}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            {renderAttachments()}
          </DialogContent>
          <Row className="center">
            <Col
              xl={5}
              lg={5}
              md={5}
              sm={11}
              xs={11}
              className="mx-auto center mt-3 mb-3"
            >
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Button onClick={handleSave} className="save_btn mx-auto">
                  {data_message.isLoadingCreate ? <Spinner /> : local.Save}
                </Button>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Button onClick={close} className="close_btn mx-auto">
                  {local.Close}
                </Button>
              </Col>
            </Col>
          </Row>
        </div>
      </Dialog>
    </div>
  );
};

export default EditTicketMessage;
