export const CLEAR_STATE = "CLEAR_STATE";

// // Home
export const UPDATE_HOME_PROPS = "UPDATE_HOME_PROPS";
export const MERGE_HOME_PROPS = "MERGE_HOME_PROPS";
export const DELETE_HOME_PROPS = "DELETE_HOME_PROPS";
export const CLEAR_Home_STATE = "CLEAR_Home_STATE";

// // LOGIN
export const UPDATE_LOGIN_PROPS = "UPDATE_LOGIN_PROPS";
export const MERGE_LOGIN_PROPS = "MERGE_LOGIN_PROPS";
export const DELETE_LOGIN_PROPS = "DELETE_LOGIN_PROPS";
export const CLEAR_LOGIN_STATE = "CLEAR_LOGIN_STATE";

// // REGISTER
export const UPDATE_REGISTER_PROPS = "UPDATE_REGISTER_PROPS";
export const MERGE_REGISTER_PROPS = "MERGE_REGISTER_PROPS";
export const DELETE_REGISTER_PROPS = "DELETE_REGISTER_PROPS";
export const CLEAR_REGISTER_STATE = "CLEAR_REGISTER_STATE";

// // PLACES
export const UPDATE_PLACES_PROPS = "UPDATE_PLACES_PROPS";
export const MERGE_PLACES_PROPS = "MERGE_PLACES_PROPS";
export const DELETE_PLACES_PROPS = "DELETE_PLACES_PROPS";
export const CLEAR_PLACES_STATE = "CLEAR_PLACES_STATE";

// // CONFIRM_SMS
export const UPDATE_CONFIRM_SMS_PROPS = "UPDATE_CONFIRM_SMS_PROPS";
export const MERGE_CONFIRM_SMS_PROPS = "MERGE_CONFIRM_SMS_PROPS";
export const DELETE_CONFIRM_SMS_PROPS = "DELETE_CONFIRM_SMS_PROPS";
export const CLEAR_CONFIRM_SMS_STATE = "CLEAR_CONFIRM_SMS_STATE";

// COMPLAINT_TYPE
export const UPDATE_COMPLAINT_TYPE_PROPS = "UPDATE_COMPLAINT_TYPE_PROPS";
export const MERGE_COMPLAINT_TYPE_PROPS = "MERGE_COMPLAINT_TYPE_PROPS";
export const DELETE_COMPLAINT_TYPE_PROPS = "DELETE_COMPLAINT_TYPE_PROPS";
export const CLEAR_COMPLAINT_TYPE_STATE = "CLEAR_COMPLAINT_TYPE_STATE";

// COMPLAINTS
export const UPDATE_COMPLAINTS_PROPS = "UPDATE_COMPLAINTS_PROPS";
export const MERGE_COMPLAINTS_PROPS = "MERGE_COMPLAINTS_PROPS";
export const DELETE_COMPLAINTS_PROPS = "DELETE_COMPLAINTS_PROPS";
export const CLEAR_COMPLAINTS_STATE = "CLEAR_COMPLAINTS_STATE";

// GENERAL
export const UPDATE_GENERAL_PROPS = "UPDATE_GENERAL_PROPS";
export const MERGE_GENERAL_PROPS = "MERGE_GENERAL_PROPS";
export const DELETE_GENERAL_PROPS = "DELETE_GENERAL_PROPS";
export const CLEAR_GENERAL_STATE = "CLEAR_GENERAL_STATE";

// USERS
export const UPDATE_USERS_PROPS = "UPDATE_USERS_PROPS";
export const MERGE_USERS_PROPS = "MERGE_USERS_PROPS";
export const DELETE_USERS_PROPS = "DELETE_USERS_PROPS";
export const CLEAR_USERS_STATE = "CLEAR_USERS_STATE";

// VENUES
export const UPDATE_VENUES_PROPS = "UPDATE_VENUES_PROPS";
export const MERGE_VENUES_PROPS = "MERGE_VENUES_PROPS";
export const DELETE_VENUES_PROPS = "DELETE_VENUES_PROPS";
export const CLEAR_VENUES_STATE = "CLEAR_VENUES_STATE";

// BRANCHES
export const UPDATE_BRANCHES_PROPS = "UPDATE_BRANCHES_PROPS";
export const MERGE_BRANCHES_PROPS = "MERGE_BRANCHES_PROPS";
export const DELETE_BRANCHES_PROPS = "DELETE_BRANCHES_PROPS";
export const CLEAR_BRANCHES_STATE = "CLEAR_BRANCHES_STATE";

// DISTRIBUTORS
export const UPDATE_DISTRIBUTORS_PROPS = "UPDATE_DISTRIBUTORS_PROPS";
export const MERGE_DISTRIBUTORS_PROPS = "MERGE_DISTRIBUTORS_PROPS";
export const DELETE_DISTRIBUTORS_PROPS = "DELETE_DISTRIBUTORS_PROPS";
export const CLEAR_DISTRIBUTORS_STATE = "CLEAR_DISTRIBUTORS_STATE";

// // TICKETS
export const UPDATE_TICKETS_PROPS = "UPDATE_TICKETS_PROPS";
export const MERGE_TICKETS_PROPS = "MERGE_TICKETS_PROPS";
export const DELETE_TICKETS_PROPS = "DELETE_TICKETS_PROPS";
export const CLEAR_TICKETS_STATE = "CLEAR_TICKETS_STATE";

// // ROLES
export const UPDATE_ROLES_PROPS = "UPDATE_ROLES_PROPS";
export const MERGE_ROLES_PROPS = "MERGE_ROLES_PROPS";
export const DELETE_ROLES_PROPS = "DELETE_ROLES_PROPS";
export const CLEAR_ROLES_STATE = "CLEAR_ROLES_STATE";

// // ROLES_CLAIMS
export const UPDATE_ROLES_CLAIMS_PROPS = "UPDATE_ROLES_CLAIMS_PROPS";
export const MERGE_ROLES_CLAIMS_PROPS = "MERGE_ROLES_CLAIMS_PROPS";
export const DELETE_ROLES_CLAIMS_PROPS = "DELETE_ROLES_CLAIMS_PROPS";
export const CLEAR_ROLES_CLAIMS_STATE = "CLEAR_ROLES_CLAIMS_STATE";

// // USER_CLAIMS
export const UPDATE_USER_CLAIMS_PROPS = "UPDATE_USER_CLAIMS_PROPS";
export const MERGE_USER_CLAIMS_PROPS = "MERGE_USER_CLAIMS_PROPS";
export const DELETE_USER_CLAIMS_PROPS = "DELETE_USER_CLAIMS_PROPS";
export const CLEAR_USER_CLAIMS_STATE = "CLEAR_USER_CLAIMS_STATE";

// // USER_ROLES
export const UPDATE_USER_ROLES_PROPS = "UPDATE_USER_ROLES_PROPS";
export const MERGE_USER_ROLES_PROPS = "MERGE_USER_ROLES_PROPS";
export const DELETE_USER_ROLES_PROPS = "DELETE_USER_ROLES_PROPS";
export const CLEAR_USER_ROLES_STATE = "CLEAR_USER_ROLES_STATE";

// // REVIEWS
export const UPDATE_REVIEWS_PROPS = "UPDATE_REVIEWS_PROPS";
export const MERGE_REVIEWS_PROPS = "MERGE_REVIEWS_PROPS";
export const DELETE_REVIEWS_PROPS = "DELETE_REVIEWS_PROPS";
export const CLEAR_REVIEWS_STATE = "CLEAR_REVIEWS_STATE";

// // BUSINESS
export const UPDATE_BUSINESS_PROPS = "UPDATE_BUSINESS_PROPS";
export const MERGE_BUSINESS_PROPS = "MERGE_BUSINESS_PROPS";
export const DELETE_BUSINESS_PROPS = "DELETE_BUSINESS_PROPS";
export const CLEAR_BUSINESS_STATE = "CLEAR_BUSINESS_STATE";

// // SUPPORT
export const UPDATE_SUPPORT_PROPS = "UPDATE_SUPPORT_PROPS";
export const MERGE_SUPPORT_PROPS = "MERGE_SUPPORT_PROPS";
export const DELETE_SUPPORT_PROPS = "DELETE_SUPPORT_PROPS";
export const CLEAR_SUPPORT_STATE = "CLEAR_SUPPORT_STATE";

// // WEB_VIEW
export const UPDATE_WEB_VIEW_PROPS = "UPDATE_WEB_VIEW_PROPS";
export const MERGE_WEB_VIEW_PROPS = "MERGE_WEB_VIEW_PROPS";
export const DELETE_WEB_VIEW_PROPS = "DELETE_WEB_VIEW_PROPS";
export const CLEAR_WEB_VIEW_STATE = "CLEAR_WEB_VIEW_STATE";

// // SEARCH_MENU
export const UPDATE_SEARCH_MENU_PROPS = "UPDATE_SEARCH_MENU_PROPS";
export const MERGE_SEARCH_MENU_PROPS = "MERGE_SEARCH_MENU_PROPS";
export const DELETE_SEARCH_MENU_PROPS = "DELETE_SEARCH_MENU_PROPS";
export const CLEAR_SEARCH_MENU_STATE = "CLEAR_SEARCH_MENU_STATE";

// // LOCATION_MENU
export const UPDATE_LOCATION_MENU_PROPS = "UPDATE_LOCATION_MENU_PROPS";
export const MERGE_LOCATION_MENU_PROPS = "MERGE_LOCATION_MENU_PROPS";
export const DELETE_LOCATION_MENU_PROPS = "DELETE_LOCATION_MENU_PROPS";
export const CLEAR_LOCATION_MENU_STATE = "CLEAR_LOCATION_MENU_STATE";

// // DIRECTION_MENU
export const UPDATE_DIRECTION_MENU_PROPS = "UPDATE_DIRECTION_MENU_PROPS";
export const MERGE_DIRECTION_MENU_PROPS = "MERGE_DIRECTION_MENU_PROPS";
export const DELETE_DIRECTION_MENU_PROPS = "DELETE_DIRECTION_MENU_PROPS";
export const CLEAR_DIRECTION_MENU_STATE = "CLEAR_DIRECTION_MENU_STATE";

// // USERACCOUNT
export const UPDATE_USER_ACCOUNT_PROPS = "UPDATE_USER_ACCOUNT_PROPS";
export const MERGE_USER_ACCOUNT_PROPS = "MERGE_USER_ACCOUNT_PROPS";
export const DELETE_USER_ACCOUNT_PROPS = "DELETE_USER_ACCOUNT_PROPS";
export const CLEAR_USER_ACCOUNT_STATE = "CLEAR_USER_ACCOUNT_STATE";

// // CONTRIBUTION
export const UPDATE_CONTRIBUTION_PROPS = "UPDATE_CONTRIBUTION_PROPS";
export const MERGE_CONTRIBUTION_PROPS = "MERGE_CONTRIBUTION_PROPS";
export const DELETE_CONTRIBUTION_PROPS = "DELETE_CONTRIBUTION_PROPS";
export const CLEAR_CONTRIBUTION_STATE = "CLEAR_CONTRIBUTION_STATE";

// // AUTH
export const UPDATE_AUTH_PROPS = "UPDATE_AUTH_PROPS";
export const MERGE_AUTH_PROPS = "MERGE_AUTH_PROPS";
export const DELETE_AUTH_PROPS = "DELETE_AUTH_PROPS";
export const CLEAR_AUTH_STATE = "CLEAR_AUTH_STATE";
