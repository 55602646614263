import React, { Component } from 'react';
import { Col, Row, Card, Input, Form, Button, Spinner } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import Dropzone from 'react-dropzone';
import image_icon from '../../assets/img/image_icon.png';
import add_img_plus from '../../assets/img/add_img_plus.png';
import { FiX } from 'react-icons/fi';
import strik from '../../assets/img/strik.png';
import {
  UPDATE_COMPLAINT_TYPE_PROPS,
  UPDATE_GENERAL_PROPS,
} from '../../Redux/Actions/types';
import {
  handleEnglishNameChange,
  handleArabicNameChange,
  renderErrorMessage,
} from '../../General/GeneralFunctions';
import { getUser } from '../../utils/StorageFunctions';
import Gallery from '../../General/Gallery';
class AddComplaintType extends Component {
  componentDidMount() {
    this.onInailize();
  }
  onInailize = () => {
    const { history, data, fetchComplaint } = this.props;
    if (getUser() === null) {
      history.push('/login');
    } else {
      if (history.location.pathname.split('_')[0] === '/edit') {
        let items = history.location.pathname.split('/');

        let id = +items.find((i) => !isNaN(i) && i !== '');
        if (id) {
          fetchComplaint(id);
        } else {
          history.push('/dashboard/complaints');
        }
      }
    }
  };

  unsetErrorMessage = () => {
    const { general } = this.props;

    general(
      [
        {
          prop: 'data.errorMessages',
          value: { NameEn: '', NameAr: '', Icon: '' },
        },
      ],
      UPDATE_COMPLAINT_TYPE_PROPS
    );
  };

  validateForm = () => {
    const { data, general } = this.props;
    if (!data.form.Name.en || !data.form.Name.ar || !data.form.Icon) {
      window.scrollTo(0, 0);
      if (!data.form.Name.en) {
        general(
          [
            {
              prop: 'data.errorMessages.NameEn',
              value: local.NameEnRequired,
            },
          ],
          UPDATE_COMPLAINT_TYPE_PROPS
        );
      }
      if (!data.form.Name.ar) {
        general(
          [
            {
              prop: 'data.errorMessages.NameAr',
              value: local.NameArRequired,
            },
          ],
          UPDATE_COMPLAINT_TYPE_PROPS
        );
      }
      if (!data.form.Icon) {
        general(
          [
            {
              prop: 'data.errorMessages.Icon',
              value: local.ImageRequired,
            },
          ],
          UPDATE_COMPLAINT_TYPE_PROPS
        );
      }
    } else {
      this.unsetErrorMessage();
      return true;
    }
  };

  onSave = (e) => {
    const { data, updateComplaintType, history } = this.props;
    e.preventDefault();

    if (this.validateForm() === true) {
      if (history.location.pathname.split('_')[0] === '/edit') {
        updateComplaintType(data.form, 'update', history);
      } else {
        updateComplaintType(data.form, 'create', history);
      }
    }
  };

  previewPhoto = () => {
    let image;
    const { data, general } = this.props;
    if (data.form.Icon) {
      if (Array.isArray(data.form.Icon)) {
        image = URL.createObjectURL(data.form.Icon[0]);
      } else {
        image = data.form.Icon;
      }
      return (
        <div style={{ height: 'fit-content' }}>
          <FiX
            className='cursor'
            style={{ color: 'red' }}
            onClick={(e) => {
              e.preventDefault();
              general(
                [
                  {
                    prop: 'data.form.Icon',
                    value: null,
                  },
                ],
                UPDATE_COMPLAINT_TYPE_PROPS
              );
            }}
          />
          <div
            style={{
              width: '120px',
              height: '120px',
              borderRadius: '22px',
            }}
          >
            <img
              style={{ borderRadius: '22px' }}
              src={image}
              alt=''
              className='imgFull cursor'
              onClick={(e) => {
                e.preventDefault();
                general(
                  [
                    {
                      prop: 'lightbox',
                      value: {
                        image,
                        isOpen: true,
                        type: 'single',
                      },
                    },
                  ],
                  UPDATE_GENERAL_PROPS
                );
              }}
            />
          </div>
        </div>
      );
    }
  };
  render() {
    const { data, general, lightbox } = this.props;
    return (
      <div className='page-container'>
        <Gallery lightbox={lightbox} general={general} />

        <Row>
          <Col
            xl={8}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className='mx-auto text-center'
          >
            <h3 className='page_create_title'>
              {data.form.id
                ? local.UpdateComplaintType
                : local.AddComplaintType}
            </h3>

            <Card className='create_card'>
              <Form>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {!data.form.Icon ? (
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          general(
                            [
                              {
                                prop: 'data.form.Icon',
                                value: acceptedFiles,
                              },
                            ],
                            UPDATE_COMPLAINT_TYPE_PROPS
                          );
                        }}
                      >
                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                          <div
                            {...getRootProps()}
                            className='fullWidthHeight center cursor'
                          >
                            <input
                              {...getInputProps()}
                              multiple={false}
                              accept='image/*'
                              defaultValue={acceptedFiles}
                            />

                            <Col className='text-center'>
                              <div>
                                <img
                                  src={add_img_plus}
                                  alt=''
                                  className='add_img'
                                />

                                <img src={image_icon} alt='' />
                              </div>
                            </Col>
                          </div>
                        )}
                      </Dropzone>
                    ) : (
                      <div className='center'> {this.previewPhoto()}</div>
                    )}

                    {renderErrorMessage(
                      data.form.Icon,
                      data.errorMessages.Icon
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    className='mt-4 mx-auto'
                  >
                    <div className='container_add_input mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <Input
                        className='add_input'
                        placeholder={local.EnglishName}
                        value={data.form.Name.en}
                        onChange={(e) =>
                          handleEnglishNameChange(
                            e,
                            general,
                            UPDATE_COMPLAINT_TYPE_PROPS,
                            'data.form.Name.en'
                          )
                        }
                      />
                    </div>
                    {renderErrorMessage(
                      data.form.Name.en,
                      data.errorMessages.NameEn
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    className='mt-4 mx-auto'
                  >
                    <div className='container_add_input mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <Input
                        className='add_input'
                        placeholder={local.ArabicName}
                        value={data.form.Name.ar}
                        onChange={(e) =>
                          handleArabicNameChange(
                            e,
                            general,
                            UPDATE_COMPLAINT_TYPE_PROPS,
                            'data.form.Name.ar'
                          )
                        }
                      />
                    </div>
                    {renderErrorMessage(
                      data.form.Name.ar,
                      data.errorMessages.NameAr
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    className='mt-5 mx-auto'
                  >
                    <Button onClick={this.onSave} className='save_btn'>
                      {data.isLoadingUpdate === true ? (
                        <Spinner color='#fff' />
                      ) : (
                        local.Save
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ ComplaintTypeR, GeneralR }) => {
  return {
    data: ComplaintTypeR.data,
    lightbox: GeneralR.lightbox,
  };
};
export default connect(mapStateToProps, actions)(AddComplaintType);
