import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Button, Col, Row } from "reactstrap";
import { local } from "../../../lang/local";
import { renderName } from "../../../General/GeneralFunctions";
import {
  UPDATE_CONTRIBUTION_PROPS,
  UPDATE_LOCATION_MENU_PROPS,
} from "../../../Redux/Actions/types";
const useStyles = makeStyles((theme) => ({
  typography: {
    width: "400px",
  },
}));

export default function PlaceList({
  TagItem,
  editedPlaces,
  findContribution,
  location_data,
  contribuationForm,
  setSelected,
  isSelected,
  general,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    findContribution(location_data.lat, location_data.lng, contribuationForm);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (isSelected !== null) {
      let place = editedPlaces.items[isSelected];
      if (place !== undefined) {
        let lng = +place.Geometry.replace(/^\D+/g, "").split(" ")[0];
        let lat = +place.Geometry.replace(/^\D+/g, "")
          .split(" ")[1]
          .split(")")[0];

        general(
          [{ prop: "data.form", value: place }],
          UPDATE_CONTRIBUTION_PROPS
        );
      }
    }
    general(
      [{ prop: "location_data.toViewSideInfo", value: false }],
      UPDATE_LOCATION_MENU_PROPS
    );
    general(
      [{ prop: "data.isOpenAddContribution", value: true }],
      UPDATE_CONTRIBUTION_PROPS
    );
    handleClose();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const renderPlaces = () => {
    if (editedPlaces.items) {
      if (editedPlaces.items.length > 0) {
        return editedPlaces.items.map((place, index) => {
          return (
            <Row key={index}>
              <Col
                className="mt-4 ml-2 mr-2 flex alignItem"
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <div className="round">
                  <input
                    type="checkbox"
                    id={"checkbox" + +index}
                    checked={isSelected === index ? true : false}
                    onChange={(e) =>
                      e.target.checked === true
                        ? setSelected(index)
                        : setSelected(null)
                    }
                  />
                  <label htmlFor={"checkbox" + +index}></label>
                </div>
                <div>
                  <p
                    style={{
                      marginInlineStart: "10px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {renderName(place.ContributionName)}
                  </p>
                </div>
              </Col>
            </Row>
          );
        });
      }
    }
  };

  return (
    <React.Fragment>
      <a className="cursor" onClick={handleClick}>
        <TagItem />
      </a>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>
          <div className="fullWidthHeight">
            <React.Fragment>
              <Row>
                <Col
                  className="mt-4 ml-2 mr-2 flex alignItem"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <div className="round">
                    <input
                      type="checkbox"
                      id="checkbox-1"
                      checked={isSelected === -1 ? true : false}
                      onChange={(e) =>
                        e.target.checked === true
                          ? setSelected(-1)
                          : setSelected(null)
                      }
                    />
                    <label htmlFor="checkbox-1"></label>
                  </div>
                  <div className="mt-2">
                    <p
                      style={{
                        marginInlineStart: "10px",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {local.ContinueAdding}
                    </p>
                  </div>
                </Col>
              </Row>
              {renderPlaces()}
              <Row>
                <Col
                  className="mt-4 center"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Button
                    onClick={handleSave}
                    className="add_btn"
                    style={{ width: "100px" }}
                  >
                    {local.Save}
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          </div>
        </Typography>
      </Popover>
    </React.Fragment>
  );
}
