import React from 'react';
import { Row, Col, Form, Button, Spinner, Card } from 'reactstrap';
import { local, getLanguage } from '../../lang/local';
import { UPDATE_REGISTER_PROPS } from '../../Redux/Actions/types';
import Dropzone from 'react-dropzone';
import { BsCardImage } from 'react-icons/bs';
import { FiXSquare } from 'react-icons/fi';
import {
  showConfirmMessage,
  validateEmail,
  validatePassword,
} from '../../General/GeneralFunctions';
import { leftProperty } from '../../General/GeneralStyle';

import { getDefaultValue } from '../../General/SelectFunctions';
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { CustomSelect } from '../../components/CustomSelect/CustomSelect';
import { CustomDateTime } from '../../components/CustomDateTime/CustomDateTime';
import { Phone } from '../../components/Phone/Phone';
import { CustomPassword } from '../../components/CustomPassword/CustomPassword';
import { CustomButton } from '../../components/CustomButton/CustomButton';

const RegisterForm = ({ registerObj, general, register, history }) => {
  const validateForm = () => {
    let formValues = Object.assign({}, registerObj.form);

    if (
      (!formValues.email && !formValues.phoneNumber) ||
      !formValues.firstName ||
      !formValues.lastName ||
      !formValues.password ||
      !formValues.passwordConfirmation
    ) {
      showConfirmMessage(local.DataRequired, 'error');
    }
    if (formValues.email) {
      if (validateEmail(formValues.email) === false) {
        showConfirmMessage(local.ValidEmail, 'error');
        return false;
      }
    }
    if (formValues.password) {
      if (validatePassword(formValues.password) === false) {
        showConfirmMessage(local.PasswordValidate, 'error');
        return false;
      }
    }

    if (
      (formValues.email || formValues.phoneNumber) &&
      formValues.firstName &&
      formValues.lastName &&
      formValues.password &&
      formValues.passwordConfirmation
    ) {
      if (formValues.firstName.length < 3 && formValues.lastName.length < 3) {
        showConfirmMessage(local.FirstAndLastNameLength, 'error');
        return false;
      }
      if (formValues.firstName.length < 3) {
        showConfirmMessage(local.FirstNameLength, 'error');
        return false;
      }

      if (formValues.lastName.length < 3) {
        showConfirmMessage(local.LastNameLength, 'error');
        return false;
      }

      if (formValues.password !== formValues.passwordConfirmation) {
        showConfirmMessage(local.PasswordMatched, 'error');
      } else {
        return true;
      }
    }
  };
  const Options = [
    { label: getLanguage() === 'en' ? 'Male' : 'ذكر', value: 'male' },
    { label: getLanguage() === 'en' ? 'Female' : 'انثي', value: 'female' },
  ];

  const onSign = (e) => {
    e.preventDefault();
    let newFormValue = Object.assign({}, registerObj.form);

    if (newFormValue.birthDate) {
      let newBirthDay = newFormValue.birthDate[0].setDate(
        newFormValue.birthDate[0].getDate() + 1
      );
      newBirthDay = new Date(newBirthDay).toISOString().split('T')[0];
      newFormValue.birthDate = newBirthDay;
    }
    if (newFormValue.phoneNumber) {
      newFormValue.userName = newFormValue.phoneNumber;
    }

    if (newFormValue.email) {
      newFormValue.userName = newFormValue.email;
    }
    if (validateForm(newFormValue) === true) {
      register(newFormValue, history);
    }
  };

  const renderPhotoCard = () => {
    return (
      <Card className='dropzone_card mx-auto'>
        <Dropzone
          onDrop={(acceptedFiles) =>
            general(
              [{ prop: 'registerObj.form.image', value: acceptedFiles }],
              UPDATE_REGISTER_PROPS
            )
          }
        >
          {({ getRootProps, getInputProps, acceptedFiles }) => (
            <div {...getRootProps()} className='fullWidthHeight center cursor'>
              <input
                {...getInputProps()}
                multiple={false}
                accept='image/*'
                defaultValue={acceptedFiles}
              />

              <Col className='text-center'>
                <div>
                  <BsCardImage className='dropzone_logo' />
                  <p>{local.SelectImage}</p>
                </div>
              </Col>
            </div>
          )}
        </Dropzone>
      </Card>
    );
  };

  const previewPhoto = () => {
    if (registerObj.form.image !== '') {
      try {
        let image = URL.createObjectURL(registerObj.form.image[0]);

        return (
          <Card className='dropzone_card mx-auto'>
            <span
              className='cursor img_remove_icon'
              style={leftProperty}
              onClick={(e) => {
                e.preventDefault();
                general(
                  [{ prop: 'registerObj.form.image', value: '' }],
                  UPDATE_REGISTER_PROPS
                );
              }}
            >
              <FiXSquare />
            </span>
            <img src={image} className='imgFull' alt='' />
          </Card>
        );
      } catch (e) {
        showConfirmMessage(local.SomethingError, 'error');
      }
    }
  };

  return (
    <Card className='create_card sign_card'>
      <Form>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mx-auto'>
            {registerObj.form.image !== '' ? previewPhoto() : renderPhotoCard()}
          </Col>
        </Row>
        <Row>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className='mx-auto mt-2'>
            <CustomInput
              icon={true}
              type='text'
              value={registerObj.form.firstName}
              className='add_input'
              onChange={(value) =>
                general(
                  [
                    {
                      prop: 'registerObj.form.firstName',
                      value,
                    },
                  ],
                  UPDATE_REGISTER_PROPS
                )
              }
              label={local.FirstName}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className='mx-auto mt-2'>
            <CustomInput
              icon={true}
              type='text'
              value={registerObj.form.lastName}
              className='add_input'
              onChange={(value) =>
                general(
                  [
                    {
                      prop: 'registerObj.form.lastName',
                      value,
                    },
                  ],
                  UPDATE_REGISTER_PROPS
                )
              }
              label={local.LastName}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className='mx-auto mt-3'>
            <CustomSelect
              label={local.Gender}
              hasLabel={false}
              value={getDefaultValue(registerObj.form.gender, Options)}
              onChange={(e) =>
                e !== null
                  ? general(
                      [
                        {
                          prop: 'registerObj.form.gender',
                          value: e.value,
                        },
                      ],
                      UPDATE_REGISTER_PROPS
                    )
                  : general(
                      [
                        {
                          prop: 'registerObj.form.gender',
                          value: null,
                        },
                      ],
                      UPDATE_REGISTER_PROPS
                    )
              }
              options={Options}
              isMulti={false}
            />
          </Col>

          <Col xl={6} lg={6} md={6} sm={12} xs={12} className='mx-auto mt-3'>
            <CustomDateTime
              label={local.BirthDay}
              hasLabel={false}
              value={registerObj.form.birthDate}
              options={{
                maxDate: '2002-01-01',
                enableTime: false,
                altInput: true,
                allowInput: true,
              }}
              onChange={(date) =>
                general(
                  [
                    {
                      prop: 'registerObj.form.birthDate',
                      value: date,
                    },
                  ],
                  UPDATE_REGISTER_PROPS
                )
              }
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className='mx-auto mt-3'>
            <Phone
              defaultCountry={registerObj.form.country.toLowerCase()}
              icon={true}
              value={registerObj.form.phoneNumber}
              onChange={(e, country) =>
                general(
                  [
                    { prop: 'registerObj.form.phoneNumber', value: e },
                    {
                      prop: 'registerObj.form.country',
                      value: country.countryCode,
                    },
                  ],
                  UPDATE_REGISTER_PROPS
                )
              }
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className='mx-auto mt-3'>
            <CustomInput
              icon={true}
              type='email'
              value={registerObj.form.email}
              className='add_input'
              onChange={(e) =>
                general(
                  [
                    {
                      prop: 'registerObj.form.userName',
                      value: e,
                    },
                    {
                      prop: 'registerObj.form.email',
                      value: e.target.value,
                    },
                  ],
                  UPDATE_REGISTER_PROPS
                )
              }
              label={local.Email}
            />
          </Col>

          <Col xl={6} lg={6} md={6} sm={12} xs={12} className='mx-auto mt-3'>
            <CustomPassword
              value={registerObj.form.password}
              icon={true}
              className='add_input'
              label={local.Password}
              onChange={(value) =>
                general(
                  [
                    {
                      prop: 'registerObj.form.password',
                      value: value,
                    },
                  ],
                  UPDATE_REGISTER_PROPS
                )
              }
            />
          </Col>

          <Col xl={6} lg={6} md={6} sm={12} xs={12} className='mx-auto mt-3'>
            <CustomPassword
              value={registerObj.form.passwordConfirmation}
              className='add_input'
              icon={true}
              label={local.ConfirmPassword}
              onChange={(value) =>
                general(
                  [
                    {
                      prop: 'registerObj.form.passwordConfirmation',
                      value: value,
                    },
                  ],
                  UPDATE_REGISTER_PROPS
                )
              }
            />
          </Col>
        </Row>

        <Row>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className='center mt-4'
          >
            <CustomButton
              handleClick={onSign}
              label={local.SignUp}
              isLoader={registerObj.isLoading}
              className='save_btn'
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default RegisterForm;
