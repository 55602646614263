import React from "react";
import { FiCheck } from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import { UPDATE_PLACES_PROPS } from "../../../Redux/Actions/types";

const CheckBoxItem = ({ general, title, check, setChecked }) => {
  const onChecked = (e) => {
    if (e === true) {
      setChecked(title);

      general(
        [{ prop: "add_place.form.type", value: title }],
        UPDATE_PLACES_PROPS
      );
    } else {
      setChecked(false);
      general(
        [{ prop: "add_place.form.type", value: "" }],
        UPDATE_PLACES_PROPS
      );
    }
  };
  return (
    <Checkbox
      icon={<FiCheck />}
      containerClassName="check_item"
      name="my-input"
      borderColor="rgba(255, 255, 255, 0.2)"
      checked={check === title ? true : false}
      onChange={(e) => onChecked(e)}
    />
  );
};

export default CheckBoxItem;
