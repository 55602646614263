import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { local } from "../../lang/local";
import { Col, Row, Button } from "reactstrap";
import { UPDATE_AUTH_PROPS } from "../../Redux/Actions/types";
import { getUser } from '../../utils/StorageFunctions';
import { FiX } from "react-icons/fi";
import { leftRightProperty } from "../../General/GeneralStyle";
import { MAP_USER } from "../../General/StaticKeys";
const EmailModel = ({ general, emailModal, resendEmailCode, history }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const toggle = () => {
    general(
      [{ prop: "emailModal.isOpen", value: !emailModal.isOpen }],
      UPDATE_AUTH_PROPS
    );
  };

  const handleSendCode = e => {
    e.preventDefault();
    resendEmailCode(emailModal.email);
    toggle();
    history.push("/confirm_email");
  };

  const handleEmailUpdate = e => {
    e.preventDefault();
    let user = getUser();
    if (user) {
      general(
        [
          {
            prop: "updateEmailForm",
            value: {
              newemail: user.data.email
            }
          }
        ],
        UPDATE_AUTH_PROPS
      );
    }

    toggle();
    history.push("/update-email");
  };

  const onClose = e => {
    e.preventDefault();
    localStorage.removeItem(MAP_USER);
    toggle();
  };
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={fullScreen}
        open={emailModal.isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <div
          className="modal_close"
          style={leftRightProperty}
          onClick={onClose}
        >
          <FiX className="icon" />
        </div>
        <div className="profile_card">
          <DialogTitle id="responsive-dialog-title center">
            <h3 className="text-center mt-3">{local.EditEmailCode}</h3>
          </DialogTitle>
          <DialogContent>
            <Row>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="mx-auto text-center mt-3 mb-3"
              >
                <p>{emailModal.email}</p>
              </Col>
            </Row>
            <Row>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="mx-auto mt-3 mb-3"
              >
                <Button onClick={handleSendCode} className="save_btn mx-auto">
                  {local.SendCode}
                </Button>
              </Col>

              <Col
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="mx-auto mt-3 mb-3"
              >
                <Button
                  onClick={handleEmailUpdate}
                  className="save_btn mx-auto"
                >
                  {local.EditEmail}
                </Button>
              </Col>
            </Row>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default EmailModel;
