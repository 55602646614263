import React from "react";
import { Card, Col, Row } from "reactstrap";
import { getLanguage, local } from "../../lang/local";
import {  renderImage } from "../../General/GeneralFunctions";
import { getUser } from '../../utils/StorageFunctions';
import avater2 from "../../assets/img/avater2.svg";
import place from "../../assets/img/place.png";
import { numberToArabic } from "number-to-arabic";

export const renderAccountCard = (time) => {
  return (
    <div
      className="account_card"
      data-aos="fade-down"
      data-aos-delay="20"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <Row className="alignItem ml-1 mr-1">
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <center>
            <h4
              className="weightFont mt-2"
              data-aos="flip-down"
              data-aos-delay="20"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-anchor-placement="top-center"
            >
              {getUser() !== null
                ? local.Welcome + " " + getUser().data.firstName
                : local.Welcome}
            </h4>

            <div
              data-aos="flip-down"
              data-aos-delay="20"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-anchor-placement="top-center"
              style={{ width: "70px", height: "70px", borderRadius: "10px" }}
              className="mb-2"
            >
              <img
                className="imgFull"
                style={{ borderRadius: "10px" }}
                src={getUser() ? renderImage(getUser().data.image) : avater2}
              />
            </div>
          </center>
        </Col>

        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <center>
            {renderdateTimeCountDown(time)}

            {/* <img
              src={acoount_img}
              alt=""
              style={{ width: "120px", height: "120px" }}
            /> */}
          </center>
        </Col>
      </Row>
    </div>
  );
};

const renderdateTimeCountDown = (time) => {
  let year = new Date(time).getFullYear();
  let month = new Date(time).getMonth() + 1;
  let day = new Date(time).getDate();
  let hours = new Date(time).getHours();
  let minutes = new Date(time).getMinutes();
  let seconds = new Date(time).getSeconds();

  return (
    <div className="flex" style={{ justifyContent: "space-evenly" }}>
      <div
        className="time_box center"
        data-aos="fade-left"
        data-aos-delay="20"
        data-aos-duration="500"
        data-aos-easing="ease-in-out"
        data-aos-anchor-placement="top-center"
      >
        <p>
          <span>{getLanguage() === "ar" ? numberToArabic(day) : day}</span>
          <p className="time_tile">{local.Day}</p>
        </p>
      </div>

      <div
        className="time_box center"
        data-aos="fade-left"
        data-aos-delay="20"
        data-aos-duration="500"
        data-aos-easing="ease-in-out"
        data-aos-anchor-placement="top-center"
      >
        <p>
          <span>{getLanguage() === "ar" ? numberToArabic(month) : month}</span>
          <p className="time_tile">{local.Month}</p>
        </p>
      </div>

      <div
        className="time_box center"
        data-aos="fade-left"
        data-aos-delay="20"
        data-aos-duration="500"
        data-aos-easing="ease-in-out"
        data-aos-anchor-placement="top-center"
      >
        <p>
          <span>{getLanguage() === "ar" ? numberToArabic(year) : year}</span>
          <p className="time_tile">{local.Year}</p>
        </p>
      </div>
      <div
        className="time_box center"
        data-aos="fade-left"
        data-aos-delay="20"
        data-aos-duration="500"
        data-aos-easing="ease-in-out"
        data-aos-anchor-placement="top-center"
      >
        <p>
          <span>
            {getLanguage() === "ar" ? numberToArabic(seconds) : seconds}
          </span>
          <p className="time_tile">{local.Second}</p>
        </p>
      </div>
      <div
        className="time_box center"
        data-aos="fade-left"
        data-aos-delay="20"
        data-aos-duration="500"
        data-aos-easing="ease-in-out"
        data-aos-anchor-placement="top-center"
      >
        <p>
          <span>
            {getLanguage() === "ar" ? numberToArabic(minutes) : minutes}
          </span>
          <p className="time_tile">{local.Minute}</p>
        </p>
      </div>
      <div
        className="time_box center"
        data-aos="fade-left"
        data-aos-delay="20"
        data-aos-duration="500"
        data-aos-easing="ease-in-out"
        data-aos-anchor-placement="top-center"
      >
        <p>
          <span>{getLanguage() === "ar" ? numberToArabic(hours) : hours}</span>
          <p className="time_tile">{local.Hour}</p>
        </p>
      </div>
    </div>
  );
};

export const renderStatCard = () => {
  return (
    <Card className="lastcard">
      <Row className="alignItem">
        <Col lg={12}>
          <div
            className={
              getLanguage() === "en"
                ? "ml-3 mt-4 leftFloat"
                : "mr-3 mt-4 rightFloat"
            }
          >
            <p className="text-left">{local.ActivationCodeInArraive}</p>
            <p className="text-left weightFont">200</p>
          </div>

          <div
            className={
              getLanguage() === "en" ? "mr-3 rightFloat" : "ml-3 leftFloat"
            }
          >
            <img src={place} className="mt-5" alt="" />
          </div>
        </Col>
      </Row>
    </Card>
  );
};
