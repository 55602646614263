import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Col, Row, Button, Input, Spinner } from 'reactstrap';
import { FiX } from 'react-icons/fi';
import { leftRightProperty } from '../../../General/GeneralStyle';
import { local } from '../../../lang/local';
import { UPDATE_AUTH_PROPS } from '../../../Redux/Actions/types';
import * as actions from '../../../Redux/Actions/index';
import { connect } from 'react-redux';
import { showConfirmMessage } from '../../../General/GeneralFunctions';
import { getUser } from '../../../utils/StorageFunctions';

const EditEmail = ({
  TagItem,
  updateEmailForm,
  isLoadingUpdateEmail,
  general,
  updateEmail,
  history,
}) => {
  useEffect(() => {
    general(
      [{ prop: 'updateEmailForm.newemail', value: getUser().data.email }],
      UPDATE_AUTH_PROPS
    );
  }, []);
  const [isOpen, setOpen] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleSave = (e) => {
    e.preventDefault();

    if (updateEmailForm.newemail) {
      updateEmail(updateEmailForm.newemail, history);
    } else {
      showConfirmMessage(local.EnterEmail, 'error');
    }
  };

  const toggle = () => {
    setOpen(false);
    // general(
    //   [{ prop: "profileModal.isOpen", value: !profileModal.isOpen }],
    //   UPDATE_GENERAL_PROPS
    // );
    // if (profileModal.isOpen === false) {
    //   general(
    //     [{ prop: "profileModal.data", value: null }],
    //     UPDATE_GENERAL_PROPS
    //   );
    // }
  };

  return (
    <React.Fragment>
      <a className='cursor' onClick={() => setOpen(true)}>
        <TagItem />
      </a>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        fullScreen={fullScreen}
        open={isOpen}
        onClose={toggle}
        aria-labelledby='responsive-dialog-title'
        scroll='body'
      >
        <div className='modal_close' style={leftRightProperty} onClick={toggle}>
          <FiX className='icon' />
        </div>
        <div className='profile_card'>
          <DialogTitle id='responsive-dialog-title center'>
            <h3 className='text-center mt-3'>{local.EditEmail}</h3>
          </DialogTitle>
          <DialogContent>
            <Row>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className='mx-auto mt-3'
              ></Col>
            </Row>
          </DialogContent>

          <Row>
            <Col
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              className='mx-auto mt-3 mb-3'
            >
              <div className='container_add_input mx-auto'>
                <Input
                  className='add_input'
                  value={updateEmailForm.newemail}
                  onChange={(e) =>
                    general(
                      [
                        {
                          prop: 'updateEmailForm.newemail',
                          value: e.target.value,
                        },
                      ],
                      UPDATE_AUTH_PROPS
                    )
                  }
                  placeholder={local.Email}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              className='mx-auto mt-3 mb-3'
            >
              <Button onClick={handleSave} className='save_btn mx-auto'>
                {isLoadingUpdateEmail === true ? <Spinner /> : local.Save}
              </Button>
            </Col>
          </Row>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = ({ AuthR }) => {
  return {
    updateEmailForm: AuthR.updateEmailForm,
    isLoadingUpdateEmail: AuthR.isLoadingUpdateEmail,
  };
};
export default connect(mapStateToProps, actions)(EditEmail);
