import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './ticket.css';
import TicketList from './TicketList';
import TicketBox from './TicketBox';
import { connect } from 'react-redux';
import * as actions from '../../Redux/Actions/index';
import { getUser } from '../../utils/StorageFunctions';
import Gallery from '../../General/Gallery';
import { CLEAR_TICKETS_STATE } from '../../Redux/Actions/types';
import EditTicketMessage from './EditTicketMessage';
import UserProfile from '../Users/UserProfile';
import ProfileModal from '../../utils/ProfileModal';
import { local } from '../../lang/local';
class Tickets extends Component {
  componentDidMount() {
    this.props.general([], CLEAR_TICKETS_STATE);
    if (getUser() === null) {
      this.props.history.push('/login');
    } else {
      this.props.general([], CLEAR_TICKETS_STATE);
      this.props.fetchTickets([], 1, this.props.generalUsers);
    }
  }
  render() {
    const {
      history,
      general,
      data,
      DeleteTicket,
      data_message,
      addTicketMessage,
      fetchTicketMessages,
      DeleteTicketMessage,
      deleteAllMessages,
      closeTicket,
      fetchTickets,
      lightbox,
      editTicketMessage,
      generalUsers,
      profileModal,
    } = this.props;
    return (
      <div className='page-container'>
        <Gallery lightbox={lightbox} general={general} />
        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={local.UserProfile}
          Children={UserProfile}
        />
        <EditTicketMessage
          data_message={data_message}
          general={general}
          editTicketMessage={editTicketMessage}
        />

        <Row>
          <Col className='mt-4 mx-auto' xl={6} lg={6} md={12} sm={12} xs={12}>
            <TicketList
              history={history}
              data={data}
              DeleteTicket={DeleteTicket}
              general={general}
              ticketId={
                data_message.ticket !== null ? data_message.ticket.id : null
              }
              fetchTicketMessages={fetchTicketMessages}
              filterMessageArray={data_message.filterArray}
              data_message={data_message}
              closeTicket={closeTicket}
              fetchTickets={fetchTickets}
              generalUsers={generalUsers}
            />
          </Col>
          <Col className='mt-4 mx-auto' xl={6} lg={6} md={12} sm={12} xs={12}>
            {/* {getPermissions(true, true).TicketMessage_GetTicketMessages !==
                  undefined ||
                getPermissions(true, true).Administrator !== undefined ? ( */}
            <TicketBox
              general={general}
              data_message={data_message}
              addTicketMessage={addTicketMessage}
              DeleteTicketMessage={DeleteTicketMessage}
              fetchTicketMessages={fetchTicketMessages}
              deleteAllMessages={deleteAllMessages}
            />
            {/* // ) : null} */}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ TicketsR, GeneralR }) => {
  return {
    data: TicketsR.data,
    data_message: TicketsR.data_message,
    lightbox: GeneralR.lightbox,
    generalUsers: GeneralR.generalUsers,
    profileModal: GeneralR.profileModal,
  };
};

export default connect(mapStateToProps, actions)(Tickets);
