import React, { useContext } from 'react'
import { Select } from 'antd'
import { Controller, useFormContext } from 'react-hook-form'
import strik from "../../assets/img/strik.png";
import AsyncSelect from "react-select/async";

import './style.css'
let { Option } = Select

export const InputDomainSelect = ({ label, name, DomainName, placeholder, isGray, ...props }) => {
    const { formState: { errors } } = useFormContext();

    return (
        <>
            <div className='container-form-input'>

                <div className="container_add_input mx-auto">
                    <img src={strik} alt="strik" className="strik_icon" />
                    <div className="form_select_container">
                        <Controller
                            name={name}
                            rules={{ required: "اختر الشركه" }}
                            render={({ field }) => {
                                return (
                                    <AsyncSelect
                                        {...props}
                                        {...field}
                                        placeholder={label}

                                    />
                                )
                            }
                            }
                        />
                    </div>
                </div>
                <span className="error-message">{errors[name]?.message}</span>
            </div>
            {/* <div className="FormInput-container">
                {label && <div className={isGray ? "FormInput-label FormInput-label-gray" : "FormInput-label"}>
                    {label}
                </div>}
                <Controller
                    {...props}
                    render={({ field }) => {
                        return (
                            <Select
                                className="FormInput-data"
                                {...field}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }

                                placeholder={placeholder} >
                                {data && data[DomainName].CodeValue.map((e, i) => <Option key={e.code} value={i}>{e.name}</Option>)}
                            </Select>
                        )
                    }
                    }
                />
            </div> */}
        </>
    )
}
