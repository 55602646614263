import React from "react";
import { local, getLanguage } from "../../lang/local";
import GeneralCard from "../../General/GeneralCard";
import { Row, Col, Card } from "reactstrap";
import { rightStyle } from "../../General/GeneralStyle";
import drive from "../../assets/img/drive.png";
import hosp from "../../assets/img/hosp.png";
import rest from "../../assets/img/rest.png";
const PlaceCard = ({ layers, history, totalPlaces }) => {
  const handleTitleClick = (e) => {
    e.preventDefault();
    history.push("/dashboard/places");
  };
  const renderCardItems = () => {
    if (layers) {
      if (layers.length > 0) {
        return layers.map((layer, index) => {
          return (
            <Col xl={12} key={index}>
              <Card className="support_place_card">
                <div className="place_card_div">
                  <Col
                    className={
                      getLanguage() === "en"
                        ? "text-left ml-3 mr-3"
                        : "text-right ml-3 mr-3"
                    }
                  >
                    <p className="weightFont">{local[layer.title]}</p>
                    <p className="weightFont">{layer.count}</p>
                  </Col>
                  <Col className="ml-3 mr-3">
                    <img
                      src={
                        layer.count >= 0 && layer.count <= 500
                          ? hosp
                          : layer.count > 500 && layer.count <= 1000
                          ? drive
                          : rest
                      }
                      width="54.35"
                      height="17.17"
                      style={rightStyle}
                      alt=""
                    />
                  </Col>
                </div>
              </Card>
            </Col>
          );
        });
      }
    }
  };
  const renderPlaceCard = () => {
    return <Row>{renderCardItems()}</Row>;
  };
  return (
    <Col className="center mt-4" xl={4} lg={4} md={6} sm={12} xs={12}>
      <GeneralCard
        title={local.TotalPlaces}
        count={totalPlaces}
        titleColor="blue_color"
        className="text-center maxWidth"
        background="white"
        renderBody={renderPlaceCard}
        handleClick={handleTitleClick}
      />
    </Col>
  );
};

export default PlaceCard;
