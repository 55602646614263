import { removeDuplicateElement } from "../../General/GeneralFunctions";
import { SendRequest } from "./api";
import { MERGE_SUPPORT_PROPS, UPDATE_SUPPORT_PROPS } from "./types";
import { getGeneralUsers } from "./UsersActions";

export const fetchLastVenues = () => (dispatch) => {
  SendRequest()
    .post("/Venues/Get?page=1", [])
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_SUPPORT_PROPS,
          payload: [
            { prop: "data.venues.count", value: res.data.data.totalItems },
            { prop: "data.venues.items", value: res.data.data.items },
          ],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const fetchUnApprovedPlaces =
  (page, allUsers, generalUsers) => (dispatch) => {
    let filter_array = [
      {
        op: 0,
        fieldName: "Approved",
        value: null,
      },
    ];
    SendRequest()
      .post(`/Contribution/Get?page=${page}`, filter_array)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.items.length > 0) {
            dispatch({
              type: MERGE_SUPPORT_PROPS,
              payload: [
                { prop: "data.places.items", value: res.data.data.items },
              ],
            });
          }
          if (page === 1) {
            dispatch({
              type: UPDATE_SUPPORT_PROPS,
              payload: [
                { prop: "data.places.count", value: res.data.data.totalItems },
              ],
            });
          }

          if (
            res.data.data.currentPage < res.data.data.totalPages &&
            res.data.data.currentPage <= 5
          ) {
            let users = [];

            if (page === 1) {
              users = res.data.data.items.map((p) => p.userId);
            } else {
              users = allUsers.concat(res.data.data.items.map((p) => p.userId));
            }
            dispatch(
              fetchUnApprovedPlaces(
                res.data.data.currentPage + 1,
                users,
                generalUsers
              )
            );
          } else {
            let cureentUsers = res.data.data.items.map((p) => p.userId);

            let total = allUsers.concat(cureentUsers);
            let uniqueTotals = removeDuplicateElement(total);

            if (uniqueTotals.length > 0) {
              dispatch(getGeneralUsers(uniqueTotals, generalUsers));
            }
          }
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally();
  };

export const fetchTotalPlaces = () => (dispatch) => {
  SendRequest()
    .post("/Contribution/Get", [])
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_SUPPORT_PROPS,
          payload: [{ prop: "totalPlaces", value: res.data.data.totalItems }],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
