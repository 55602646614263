import React from 'react';
import { Row, Col, Card } from 'reactstrap';
import Rating from '@mui/material/Rating';
import avater2 from '../../assets/img/avater2.svg';
import { getLanguage, local } from '../../lang/local';
import { renderSingleImage } from '../../General/GeneralFunctions';
import { getUser } from '../../utils/StorageFunctions';
import { FiX } from 'react-icons/fi';
import { confirmAlert } from 'react-confirm-alert';

const ReviewsList = ({ data, general, deleteReview }) => {
  const handleDelete = (e, id, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>{local.Sure}</h1>
            <p>{local.ConfirmDelete}</p>
            <button onClick={onClose}>{local.No}</button>
            <button
              onClick={() => {
                deleteReview(id, index);
                onClose();
              }}
            >
              {local.Yes}
            </button>
          </div>
        );
      },
    });
  };
  const renderCard = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return data.items.map((item, index) => {
          return (
            <Col xl={4} className='mt-3' key={item.id}>
              <Card className='review_card'>
                <Row>
                  {item.userId === getUser().data.id ? (
                    <div
                      style={{
                        top: '5px',
                        left: '22px',
                        position: 'absolute',
                      }}
                    >
                      <a
                        className='cursor'
                        onClick={(e) => handleDelete(e, item.id, index)}
                        style={{
                          color: 'red',
                        }}
                      >
                        <FiX color={'red'} size={25} />
                      </a>
                    </div>
                  ) : null}

                  <Col xl={3} lg={3} md={3} sm={3} xs={3} className='center'>
                    <div className='img_container text-center'>
                      <p className='review_card_name primary_color'>
                        {item.user ? item.user.fullName : null}
                      </p>

                      {item.user ? (
                        renderSingleImage(item.user.image, general)
                      ) : (
                        <img src={avater2} alt='img' />
                      )}
                    </div>
                  </Col>
                  <Col xl={5} lg={5} md={5} sm={5} xs={5} className='mx-auto'>
                    <span
                      className='title'
                      style={{
                        textAlign: getLanguage() === 'ar' ? 'right' : 'left',
                        float: getLanguage() === 'ar' ? 'right' : 'left',
                      }}
                    >
                      {item.title}
                    </span>
                    <p
                      className='desc'
                      style={{
                        float: getLanguage() === 'en' ? 'left' : 'right',
                        textAlign: getLanguage() === 'en' ? 'left' : 'right',
                      }}
                    >
                      {item.detail}
                    </p>
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={4} xs={4} className='mx-auto'>
                    <Rating
                      name='simple-controlled'
                      value={item.rating}
                      readOnly
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          );
        });
      }
    }
  };

  return <Row className='mt-3'>{renderCard()}</Row>;
};

export default ReviewsList;
