import {
  UPDATE_LOCATION_MENU_PROPS,
  CLEAR_LOCATION_MENU_STATE,
  MERGE_LOCATION_MENU_PROPS,
  DELETE_LOCATION_MENU_PROPS,
} from "../Actions/types";
import * as LOCATION from "./methods";

const INITIAL_STATE = {
  location_data: {
    toViewSideInfo: false,
    lat: "",
    lng: "",
    address: "",
    distance: "0 Km",
    icon_type: "click",
    gis_id: null,
    images_names: [],
    images: [],
    item_id: null,
    placeType: "",
    image: null,
  },
};

const LocationR = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LOCATION_MENU_PROPS: {
      return LOCATION.updateProps(state, action);
    }
    case MERGE_LOCATION_MENU_PROPS: {
      return LOCATION.mergeProps(state, action);
    }
    case DELETE_LOCATION_MENU_PROPS: {
      return LOCATION.deleteProps(state, action);
    }
    case CLEAR_LOCATION_MENU_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};

export default LocationR;
