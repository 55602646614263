import Flatpickr from 'react-flatpickr';
import React from 'react';

export const CustomDateTime = ({
  label,
  hasLabel,
  value,
  options,
  onChange,
}) => {
  return (
    <div
      className='container_add_input mx-auto'
      // style={{ height: '44px' }}
    >
      <div className='input_container date_container'>
        <Flatpickr
          data-enable-time={false}
          options={options}
          value={value}
          onChange={e=>onChange(e)}
          placeholder={label}
        />
      </div>
    </div>
  );
};
