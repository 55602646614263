import React, { useState } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import more_horizontal from "../../assets/img/more_horizontal.svg";
import { local } from "../../lang/local";
import { UPDATE_TICKETS_PROPS } from "../../Redux/Actions/types";

const MessageMenu = ({ DeleteTicketMessage, message, index, general }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onEdit = (e) => {
    e.preventDefault();
    general(
      [
        { prop: "data_message.form.Msg.text", value: message.msg.text },
        { prop: "data_message.form.id", value: message.id },
        { prop: "data_message.form.Attachments", value: message.attachments },
        { prop: "data_message.isOpen", value: true },
      ],
      UPDATE_TICKETS_PROPS
    );
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      style={{ marginInlineStart: "15px" }}
    >
      <DropdownToggle
        tag="a"
        className="nav-link_chat"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <img src={more_horizontal} alt="" className="cursor" />
      </DropdownToggle>
      <DropdownMenu className="table_dropdown_menu">
        <DropdownItem onClick={onEdit} className="cursor">
          {local.Edit}
        </DropdownItem>

        <DropdownItem
          onClick={(e) => {
            e.preventDefault();
            DeleteTicketMessage(message.id, index);
          }}
          className="cursor"
        >
          {local.Delete}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MessageMenu;
