import { renderName } from "../../General/GeneralFunctions";
export const getData = (data, active_cards) => {
  let items = data.items;
  if (active_cards) {
    if (active_cards.length === 0) {
      return items;
    } else {
      let filtered_items = [];

      active_cards.map((element) => {
        let entry = items.filter((item) => item.geoEventsTypeId === element);
        filtered_items = filtered_items.concat(entry);
      });

      return filtered_items;
    }
  }
};

export const renderComplaintType = (id, ComplaintsOptions) => {
  if (ComplaintsOptions) {
    if (ComplaintsOptions.items) {
      let entry = ComplaintsOptions.items.find((c) => c.id === id);
      if (entry !== undefined) {
        return renderName(entry.name);
      }
    }
  }
};
