
import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import reducers from "./Reducers/index";
export const store = createStore(
    reducers,
    {},
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? compose(
          // To dispatch our calls asynchronously
          applyMiddleware(ReduxThunk),
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      : compose(applyMiddleware(ReduxThunk))
  );