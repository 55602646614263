import React from "react";

import "./InfoCard.css";
import { getLanguage } from "../../../lang/local";

const InfoCard = ({
  downloadIcon,
  downloadLink,
  bgColor,
  textColor,
  infoNumber,
  infoText,
  handleClick,
}) => {
  const downloadIconLocalizedPosition =
    getLanguage() === "en" ? { right: 0 } : { left: 0 };
  const downloadLinkLocalizedPosition =
    getLanguage() === "en" ? { left: 0 } : { right: 0 };
  return (
    <div
      className="info-card-container cursor"
      style={{ backgroundColor: bgColor, color: textColor }}
      onClick={handleClick ? handleClick : null}
      data-aos="fade-down"
      data-aos-delay="20"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <img
        src={downloadIcon}
        alt=""
        className="info-card-download-icon"
        style={{ ...downloadIconLocalizedPosition }}
      />

      <div className="info-card-text-container">
        <div className="large-font">{infoNumber}</div>
        <div className="medium-font">{infoText}</div>
      </div>

      <img
        src={downloadLink}
        style={downloadLinkLocalizedPosition}
        alt=""
        className="info-card-download-link"
      />
    </div>
  );
};

export default InfoCard;
