import {
  getQueryString,
  removeDublicatedValues,
  showConfirmMessage,
} from "../../General/GeneralFunctions";
import { getLanguage, local } from "../../lang/local";
import { RequestImages } from "./api";
import {
  UPDATE_DIRECTION_MENU_PROPS,
  UPDATE_LOCATION_MENU_PROPS,
  UPDATE_PLACES_PROPS,
  UPDATE_SEARCH_MENU_PROPS,
  DELETE_LOCATION_MENU_PROPS,
  MERGE_LOCATION_MENU_PROPS,
  UPDATE_GENERAL_PROPS,
} from "./types";

let socketMessage;

export const openSocket = () => {
  try {
    socketMessage = new WebSocket("wss://places.altawasol.app:8080/connect");

    socketMessage.addEventListener("open", function (event) {
      console.log("socket is Open");
    });

    socketMessage.addEventListener("message", function (event) {
      if (event.data === "hello") {
        socketMessage.send(
          JSON.stringify({
            key: "BpLnfgDsc2WD8F2q",
            channels: "234234",
          })
        );
      }
    });

    socketMessage.addEventListener("close", function (event) {
      // console.log(
      //   "socket is closed, try to reconnect again",
      //   "please wait ..."
      // );
      console.log("socket is closed due to some problems, please reload page");

      // openSocket();
    });
  } catch (e) {
    showConfirmMessage(
      "Error With Socket Connect,Please check your Socket is Able to connect to it",
      "error"
    );
  }
};
openSocket();

export const getSocketResult =
  (query, lat, lng, type, prop, action) => (dispatch) => {
    let arregex = /[\u0600-\u06FF]/;
    let lang = "en";
    if (arregex.test(query) === true) {
      lang = "ar";
    } else {
      lang = "en";
    }

    let body = {};

    if (type === "auto_complete" || type === "auto_complete_place") {
      dispatch({
        type: UPDATE_SEARCH_MENU_PROPS,
        payload: [
          {
            prop: "search_data.type",
            value: "auto_complete",
          },
        ],
      });
    }
    if (
      type === "auto_complete" ||
      type === "start_location_autoComplete" ||
      type === "end_Location_autoComplete" ||
      type === "auto_complete_place" ||
      type === "dashboard_auto_complete"
    ) {
      if (type === "auto_complete") {
        dispatch({
          type: UPDATE_SEARCH_MENU_PROPS,
          payload: [
            {
              prop: "search_data.isLoading",
              value: true,
            },
          ],
        });
      }

      if (type === "dashboard_auto_complete") {
        dispatch({
          type: UPDATE_GENERAL_PROPS,
          payload: [
            {
              prop: "mapSearch.isLoading",
              value: true,
            },
          ],
        });
      }
      body = {
        uuid: type,
        action: "autocomplete",
        point: [lng, lat],
        lang: lang,
        query: query,
        radius: 250,
        limit: 40,
      };
    }

    if (
      type === "search" ||
      type === "start_location_Search" ||
      type === "end_Location_Search" ||
      type === "search_place" ||
      type === "dashboard_search"
    ) {
      if (type === "dashboard_search") {
        dispatch({
          type: UPDATE_GENERAL_PROPS,
          payload: [
            {
              prop: "mapSearch.isLoading",
              value: true,
            },
          ],
        });
      }
      if (type === "search_place") {
        dispatch({
          type: UPDATE_PLACES_PROPS,
          payload: [
            {
              prop: "places.isLoading",
              value: true,
            },
          ],
        });
      } else {
        dispatch({
          type: UPDATE_SEARCH_MENU_PROPS,
          payload: [
            {
              prop: "search_data.isLoading",
              value: true,
            },
          ],
        });
      }
      body = {
        uuid: type,
        action: "search",
        point: [lng, lat],
        lang: lang,
        radius: null,
        limit: 40,
        query: query,
        geom: "point",
      };
    }

    if (
      type === "location_data.address" ||
      type === "startLocation.title" ||
      type === "endLocation.title" ||
      type === "location_data.gis_id"
    ) {
      let l = "en";
      if (getLanguage() === "ar") {
        l = "ar";
      }
      body = {
        uuid: type,
        action: "reverse",
        point: [lng, lat],
        lang: l,
        radius: 10,
      };
    }

    if (type === "routing") {
      dispatch({
        type: UPDATE_DIRECTION_MENU_PROPS,
        payload: [
          {
            prop: "direction_data.waytoGo",
            value: true,
          },
        ],
      });
      body = {
        uuid: "routing",
        action: "route",
        waypoints: [
          [lng.start, lat.start],
          [lng.end, lat.end],
        ],
        overview: "full",
        lang: "en",
        steps: true,
        annotations: true,
        alternatives: true,
        foot: prop === "car" ? false : true,
      };
    }

    if (type === "distance" || type === "distance_place") {
      body = {
        uuid: type,
        action: "matrix",
        waypoints: [
          [lng.start, lat.start],
          [lng.end, lat.end],
        ],
        lang: "en",
      };
    }

    if (socketMessage !== undefined) {
      if (socketMessage.readyState !== 0) {
        socketMessage.addEventListener("message", function (event) {
          event.preventDefault();

          let entry = JSON.parse(event.data);
          let places = [];

          if (
            entry.uuid === "auto_complete" ||
            entry.uuid === "search" ||
            entry.uuid === "start_location_autoComplete" ||
            entry.uuid === "end_Location_autoComplete" ||
            entry.uuid === "start_location_Search" ||
            entry.uuid === "end_Location_Search" ||
            entry.uuid === "search_place" ||
            entry.uuid === "auto_complete_place" ||
            entry.uuid === "dashboard_auto_complete" ||
            entry.uuid === "dashboard_search"
          ) {
            if (entry.msg) {
              if (entry.msg.features !== undefined) {
                if (entry.msg.features) {
                  dispatch({
                    type: UPDATE_SEARCH_MENU_PROPS,
                    payload: [
                      {
                        prop: "search_data.isLoading",
                        value: false,
                      },
                    ],
                  });
                  if (entry.msg.features.length > 0) {
                    entry.msg.features.forEach((place) => {
                      places.push({
                        label: removeDublicatedValues(
                          place.properties.name,
                          place.properties.street,
                          place.properties.county,
                          place.properties.region,
                          place.properties.country,
                          place.properties.continent
                        ),
                        value: place.geometry.coordinates,
                        layer: place.properties.layer,
                        data: place,
                      });
                    });

                    if (
                      entry.uuid === "dashboard_auto_complete" ||
                      entry.uuid === "dashboard_search"
                    ) {
                      if (places.length > 0) {
                        return dispatch({
                          type: UPDATE_GENERAL_PROPS,
                          payload: [
                            {
                              prop: "mapSearch.searchResults",
                              value: places,
                            },
                            {
                              prop: "mapSearch.isLoading",
                              value: false,
                            },
                          ],
                        });
                      }
                    }

                    if (
                      entry.uuid === "auto_complete_place" ||
                      entry.uuid === "search_place"
                    ) {
                      if (places.length > 0) {
                        return dispatch({
                          type: UPDATE_PLACES_PROPS,
                          payload: [
                            {
                              prop: "places.searchResult",
                              value: places,
                            },
                            {
                              prop: "places.isLoading",
                              value: false,
                            },
                          ],
                        });
                      }
                    }
                    if (
                      entry.uuid === "auto_complete" ||
                      entry.uuid === "search"
                    ) {
                      if (places.length > 0) {
                        return dispatch({
                          type: UPDATE_SEARCH_MENU_PROPS,
                          payload: [
                            {
                              prop: "searchResult",
                              value: places,
                            },
                          ],
                        });
                      }
                    }
                    if (
                      entry.uuid === "start_location_autoComplete" ||
                      entry.uuid === "start_location_Search"
                    ) {
                      return dispatch({
                        type: UPDATE_DIRECTION_MENU_PROPS,
                        payload: [
                          {
                            prop: "startLocation.searchResults",
                            value: places,
                          },
                        ],
                      });
                    }

                    if (
                      entry.uuid === "end_Location_autoComplete" ||
                      entry.uuid === "end_Location_Search"
                    ) {
                      return dispatch({
                        type: UPDATE_DIRECTION_MENU_PROPS,
                        payload: [
                          {
                            prop: "endLocation.searchResults",
                            value: places,
                          },
                        ],
                      });
                    }
                  } else {
                    if (
                      entry.uuid === "dashboard_auto_complete" ||
                      entry.uuid === "dashboard_search"
                    ) {
                      dispatch({
                        type: UPDATE_GENERAL_PROPS,
                        payload: [
                          {
                            prop: "mapSearch.isLoading",
                            value: false,
                          },
                        ],
                      });
                    }
                    showConfirmMessage(local.NoSearch, "error");
                  }
                }
              }
            }
          }
          if (
            entry.uuid === "location_data.address" ||
            entry.uuid === "startLocation.title" ||
            entry.uuid === "endLocation.title" ||
            "location_data.gis_id"
          ) {
            if (entry.msg) {
              if (entry.msg.features) {
                if (entry.msg.features.length > 0) {
                  let title = entry.msg.features[0].properties;

                  if (entry.uuid === "location_data.gis_id") {
                    return dispatch({
                      type: UPDATE_LOCATION_MENU_PROPS,
                      payload: [
                        {
                          prop: "location_data.gis_id",
                          value: entry.msg.features[0].properties.gid,
                        },
                      ],
                    });
                  } else if (entry.uuid === "location_data.address") {
                    return dispatch({
                      type: UPDATE_LOCATION_MENU_PROPS,
                      payload: [
                        {
                          prop: entry.uuid,
                          value: removeDublicatedValues(
                            title.name,
                            title.street,
                            title.county,
                            title.region,
                            title.country,
                            title.continent
                          ),
                        },

                        {
                          prop: "location_data.gis_id",
                          value: entry.msg.features[0].properties.gid,
                        },
                      ],
                    });
                  } else {
                    let gis = entry.uuid.split(".")[0] + "." + "gis_id";
                    return dispatch({
                      type: UPDATE_DIRECTION_MENU_PROPS,
                      payload: [
                        {
                          prop: entry.uuid,
                          value: removeDublicatedValues(
                            title.name,
                            title.street,
                            title.county,
                            title.region,
                            title.country,
                            title.continent
                          ),
                        },
                        {
                          prop: gis,
                          value: entry.msg.features[0].properties.gid,
                        },
                      ],
                    });
                  }
                }
              }
            } else {
            }
          }

          if (entry.uuid === "distance" || entry.uuid === "distance_place") {
            if (entry.msg !== null) {
              if (entry.msg.distances !== null) {
                if (entry.msg.distances.length > 0) {
                  if (action !== undefined) {
                    return dispatch({
                      type: action,
                      payload: [
                        {
                          prop: prop,
                          value:
                            Math.round(entry.msg.distances[0][1] * 0.001) +
                            " Km",
                        },
                      ],
                    });
                  } else {
                    if (entry.uuid === "distance_place") {
                      return dispatch({
                        type: UPDATE_PLACES_PROPS,
                        payload: [
                          {
                            prop: prop,
                            value:
                              Math.round(entry.msg.distances[0][1] * 0.001) +
                              " Km",
                          },
                        ],
                      });
                    } else {
                      return dispatch({
                        type: UPDATE_LOCATION_MENU_PROPS,
                        payload: [
                          {
                            prop: "location_data.distance",
                            value:
                              Math.round(entry.msg.distances[0][1] * 0.001) +
                              " Km",
                          },
                        ],
                      });
                    }
                  }
                }
              }
            }
          }

          if (entry.uuid === "routing") {
            dispatch({
              type: UPDATE_DIRECTION_MENU_PROPS,
              payload: [
                {
                  prop: "direction_data.isLoading",
                  value: false,
                },
              ],
            });
            if (entry.msg !== null) {
              if (entry.msg.routes !== undefined) {
                if (entry.msg.routes !== null) {
                  dispatch({
                    type: UPDATE_DIRECTION_MENU_PROPS,
                    payload: [
                      {
                        prop: "direction_data.routing",
                        value: entry.msg.routes,
                      },
                    ],
                  });
                }
              }
            }
          }
        });

        socketMessage.send(JSON.stringify(body));
      }
    }
  };

export const searchImages = (query) => (dispatch) => {
  let param = { action: "opensearch", search: query, format: "json" };
  RequestImages()
    .get(`/api.php${getQueryString(param)}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data) {
          dispatch(parseImages(res.data[0]));
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
export const parseImages = (name) => (dispatch) => {
  let param = { action: "parse", page: name, format: "json", prop: "images" };
  RequestImages()
    .get(`/api.php${getQueryString(param)}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data) {
          if (res.data.parse.images) {
            let filterd_images = res.data.parse.images.filter(
              (img) => !img.includes(".ogg")
            );
            dispatch({
              type: UPDATE_LOCATION_MENU_PROPS,
              payload: [
                {
                  prop: "location_data.images_names",
                  value: filterd_images,
                },
              ],
            });
            dispatch(getImagesUrl(filterd_images, 0));
          }
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
export const getImagesUrl = (images, index) => (dispatch) => {
  let param = {
    action: "query",
    prop: "imageinfo",
    iiprop: "url",
    titles: `File:${images[index]}`,
    continue: "",
    format: "json",
  };

  dispatch({
    type: DELETE_LOCATION_MENU_PROPS,
    payload: [
      {
        prop: `location_data.images_names.${index}`,
      },
    ],
  });
  RequestImages()
    .get(`/api.php${getQueryString(param)}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.query) {
          if (res.data.query.pages) {
            images.shift();

            dispatch({
              type: MERGE_LOCATION_MENU_PROPS,
              payload: [
                {
                  prop: "location_data.images",
                  value: Object.values(res.data.query.pages)[0].imageinfo[0]
                    .url,
                },
              ],
            });
            // }

            if (images.length > 0 && index <= 3) {
              dispatch(getImagesUrl(images, index + 1));
            }
          }
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
