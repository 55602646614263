import { UPDATE_ROLES_CLAIMS_PROPS } from "../../Redux/Actions/types";
import { showConfirmMessage } from "../../General/GeneralFunctions";
import { local } from "../../lang/local";

export const onMapClick = (polygon, general) => {
  general(
    [{ prop: "data.form.geometry", value: [polygon] }],
    UPDATE_ROLES_CLAIMS_PROPS
  );
};

export const unsetErrorMessage = (general, action, id) => {
  if (id === local.SelectUser) {
    general(
      [
        {
          prop: "data.errorMessages",
          value: { Name: "", Description: "", User: "", Location: "" }
        }
      ],
      action
    );
  } else {
    general(
      [
        {
          prop: "data.errorMessages",
          value: { Name: "", Description: "", Role: "", Location: "" }
        }
      ],
      action
    );
  }
};

export const validateForm = (
  form,
  Id,
  SelectId,
  permissions,
  general,
  action,
  ComplaintTypesOptions
) => {
  let hasClaimValue =
    form.claimValue1 === undefined
      ? true
      : form.claimValue1.length === 0 && ComplaintTypesOptions.length > 0
      ? false
      : true;
  if (
    !form.Description ||
    permissions.length === 0 ||
    !form.claimValue ||
    form.geometry.length === 0 ||
    hasClaimValue === false ||
    !Id
  ) {
    window.scrollTo(0, 0);
    if (form.geometry.length === 0) {
      general(
        [
          {
            prop: "data.errorMessages.Location",
            value: local.LocationRequired
          }
        ],
        action
      );
    }
    if (!form.claimValue) {
      general(
        [
          {
            prop: "data.errorMessages.Name",
            value: local.NameRequired
          }
        ],
        action
      );
    }

    if (!form.Description) {
      general(
        [
          {
            prop: "data.errorMessages.Description",
            value: local.DescriptionRequired
          }
        ],
        action
      );
    }

    if (!Id) {
      general(
        [
          {
            prop:
              SelectId === local.SelectUser
                ? "data.errorMessages.User"
                : "data.errorMessages.Role",
            value: SelectId
          }
        ],
        action
      );
    }

    if (hasClaimValue === false) {
      general(
        [
          {
            prop: "data.errorMessages.ComplaintType",
            value: local.SelectComplaintType
          }
        ],
        action
      );
    }
    if (permissions.length === 0) {
      showConfirmMessage(local.ClaimRequired, "error");
    }
  } else {
    unsetErrorMessage(general, action, SelectId);
    return true;
  }
};

export const handleSave = (
  e,
  action_type,
  form,
  general,
  roleId,
  permissions,
  ComplaintTypesOptions
) => {
  e.preventDefault();

  if (
    validateForm(
      form,
      roleId,
      local.RoleRequired,
      permissions,
      general,
      UPDATE_ROLES_CLAIMS_PROPS,
      ComplaintTypesOptions
    ) === true
  ) {
    let newform = Object.assign({}, form);

    action_type(newform, roleId, permissions);
  }
};

export const onChangeComplaintType = (e, general, action) => {
  if (e) {
    general(
      [
        {
          prop: "data.form.claimValue1",
          value: e
        }
      ],
      action
    );
  } else {
    general(
      [
        {
          prop: "data.form.claimValue1",
          value: []
        }
      ],
      action
    );
  }
};

export const onChangeSelectAll = (
  e,
  claimTypesOptions,
  general,
  update_action,
  delete_action
) => {
  if (e.target.checked === false) {
    general(
      [
        {
          prop: "data.form.claimValue1"
        }
      ],
      delete_action
    );
    general(
      [
        {
          prop: "data.permissions",
          value: []
        }
      ],
      update_action
    );
  } else {
    general(
      [
        {
          prop: "data.form.claimValue1",
          value: []
        },
        {
          prop: "data.permissions",
          value:
            claimTypesOptions && claimTypesOptions.length > 0
              ? claimTypesOptions.map(claim => claim.value)
              : []
        }
      ],
      update_action
    );
  }
};

export const getClaimTitle = title => {
  let itemArr = title.split("_");
  if (itemArr[0] === "RoleBusiness") {
    if (itemArr[1] === "Delete") {
      return local.RoleBusinessDelete;
    } else if (itemArr[1] === "Create") {
      return local.RoleBusinessCreate;
    } else {
      if (local[itemArr[1]] === undefined) {
      }
      return local[title] + " " + local[itemArr[1]];
    }
  } else {
    if (local[title] && local[itemArr[0]]) {
      return local[title] + " " + local[itemArr[0]];
    } else {
      debugger;
      return title + " " + itemArr[0];
    }
  }
};
