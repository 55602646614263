import React from "react";
import { Col, Row, Input, Label, Card, Table } from "reactstrap";
import { local } from "../../lang/local";
import { IMAGE_BASE_PATH } from "../../General/StaticKeys";
import { UPDATE_GENERAL_PROPS } from "../../Redux/Actions/types";
import { columnAlignLeft, rightStyle } from "../../General/GeneralStyle";
import Share from "../../General/Share";
import { extractLatLng, getPoints } from "../../General/MapFunctions";
import { renderImage, ShareIcon } from "../../General/GeneralFunctions";
import TooMap from "../../General/TooMap";
const VenueProfile = ({ data, general, location }) => {
  const handleImageClick = (e) => {
    e.preventDefault();
    if (data.logo) {
      general(
        [
          {
            prop: "lightbox",
            value: {
              image: IMAGE_BASE_PATH + data.logo,
              isOpen: true,
              type: "single",
            },
          },
        ],
        UPDATE_GENERAL_PROPS
      );
    }
  };

  const renderBranchesBody = () => {
    if (data.branches) {
      if (data.branches.length > 0) {
        return data.branches.map((branch, index) => {
          return (
            <tr key={index} className="text-center">
              <td>{branch.id}</td>
              <td> {branch.name !== {} ? branch.name[local.lang] : null}</td>
              <td>{branch.type === 0 ? local.Branch : local.Distributor}</td>

              <td>
                <Share
                  TagItem={ShareIcon}
                  point={extractLatLng(branch.locationText)}
                  type={branch.type === 0 ? "branch" : "distributor"}
                  type_id={branch.id}
                />
              </td>
            </tr>
          );
        });
      }
    }
  };

  return data ? (
    <div className="mt-3 mb-3">
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mx-auto mt-3">
          <div className="img_profile_container mx-auto">
            <img
              src={renderImage(data.logo)}
              className="fullWidthHeight"
              style={{ borderRadius: "100%" }}
              onClick={handleImageClick}
              alt=""
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Id}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.id}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.EnglishName}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.name?.en}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.ArabicName}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.name?.ar}
              disabled={true}
            />
          </div>
        </Col>
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`${columnAlignLeft()} mx-auto mt-3`}
        >
          <Label className="bold">{local.Mobile}</Label>
          <div className="container_add_input mx-auto mt-1">
            <Input
              className="add_input normal"
              value={data.phone}
              disabled={true}
            />
          </div>
        </Col>

        {data.branches ? (
          <React.Fragment>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${columnAlignLeft()} mx-auto mt-3`}
            >
              <Label className="bold">{local.Branches}</Label>

              <Table responsive hover className="mt-2">
                <thead>
                  <tr className="text-center">
                    <th>{local.Id}</th>
                    <th>{local.Name}</th>
                    <th>{local.Type}</th>
                    <th>{local.ShareLink}</th>
                  </tr>
                </thead>
                <tbody>{renderBranchesBody()}</tbody>
              </Table>
              <Label className="bold flex alignItem" style={rightStyle}>
                <Share
                  TagItem={ShareIcon}
                  bulk={window.location.origin + `/company=${data.id}`}
                />
                <p style={{ marginInlineStart: "10px" }}>
                  {local.ShareBulkLink}
                </p>
              </Label>
            </Col>

            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="mt-5 mx-auto"
            >
              <Card
                className="mapCard"
                style={{
                  height: "490px",
                  width: "100%",
                  borderRadius: "10px",
                }}
              >
                <TooMap
                  companyIcon={IMAGE_BASE_PATH + data.logo}
                  location={location}
                  // onMapClick={null}
                  general={general}
                  markers_list={getPoints(
                    data.branches,
                    "locationText",
                    undefined,
                    "branch"
                  )}
                />
              </Card>
            </Col>
          </React.Fragment>
        ) : null}
      </Row>
    </div>
  ) : null;
};

export default VenueProfile;
