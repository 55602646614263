import React, { Component } from 'react';
import { Card, Col, Row } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import WebList from './WebList';
import MobileList from './MobileList';
// import GeneralMap from "../../General/GeneralMap";
import { getPolygons } from '../../General/MapFunctions';
import { getUser } from '../../utils/StorageFunctions';
import ProfileModal from '../../utils/ProfileModal';
import RoleClaimProfile from './RoleClaimProfile';
import RoleClaimSearch from './RoleClaimSearch';
import {
  CLEAR_ROLES_CLAIMS_STATE,
  UPDATE_GENERAL_PROPS,
} from '../../Redux/Actions/types';
import TooMap from '../../General/TooMap';
import { getPermissions } from '../../utils/Permissions';

class RolesClaims extends Component {
  componentDidMount() {
    this.getRoleClaim();
  }

  getRoleClaim = () => {
    const { history, general, getRolesForDropDown } = this.props;
    general([], CLEAR_ROLES_CLAIMS_STATE);
    if (getUser() === null) {
      history.push('/login');
    } else {
      if (
        getPermissions(true, true).RoleBusiness_GetRoles === undefined &&
        getPermissions(true, true).Administrator === undefined
      ) {
        history.push('/');
      } else {
        general([{ prop: 'rows_collabse', value: [] }], UPDATE_GENERAL_PROPS);

        getRolesForDropDown();
      }
    }
  };

  renderMap = () => {
    const { data, location, general } = this.props;
    if (data.items) {
      if (data.items.length > 0) {
        return (
          <Card className='mapCard'>
            <TooMap
              location={location}
              general={general}
              polygons_list={getPolygons(
                data.items,
                'geometryText',
                'claimValue'
              )}
            />
            {/* <GeneralMap
              location={location}
              general={general}
              polygons_list={getPolygons(
                data.items,
                "geometryText",
                "claimValue"
              )}
            /> */}
          </Card>
        );
      }
    }
  };
  render() {
    const {
      history,
      data,
      deleteRoleClaim,
      rolesOptions,
      fetchRoelsClaims,
      general,
      deleteMultipleRolesClaims,
      profileModal,
      rows_collabse,
    } = this.props;
    return (
      <div className='page-container'>
        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={local.RoleClaimProfile}
          Children={RoleClaimProfile}
          rolesOptions={rolesOptions}
        />

        <Row>
          <Col>
            <RoleClaimSearch
              rolesOptions={rolesOptions}
              data={data}
              fetchRoelsClaims={fetchRoelsClaims}
              general={general}
            />
          </Col>
        </Row>

        {data.items ? (
          data.items.length > 0 ? (
            <Row className='mt-4'>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className='mobile_col'
              >
                <MobileList
                  history={history}
                  data={data}
                  deleteRoleClaim={deleteRoleClaim}
                  general={general}
                  deleteMultipleRolesClaims={deleteMultipleRolesClaims}
                />
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ height: '100vh' }}
              >
                <div
                  className='table_container'
                  style={{
                    width:
                      rows_collabse.length > 0
                        ? rows_collabse.find((col) => col === true)
                          ? '98%'
                          : 'fit-content'
                        : 'fit-content',
                  }}
                >
                  <WebList
                    history={history}
                    data={data}
                    deleteRoleClaim={deleteRoleClaim}
                    general={general}
                    deleteMultipleRolesClaims={deleteMultipleRolesClaims}
                    rows_collabse={rows_collabse}
                  />
                </div>

                {this.renderMap()}
              </Col>
            </Row>
          ) : null
        ) : null}
      </div>
    );
  }
}

const GeneralMaptateToProps = ({ RolesClaimsR, RolesR, GeneralR }) => {
  return {
    data: RolesClaimsR.data,
    rolesOptions: RolesR.rolesOptions,
    location: GeneralR.location,
    profileModal: GeneralR.profileModal,
    rows_collabse: GeneralR.rows_collabse,
  };
};
export default connect(GeneralMaptateToProps, actions)(RolesClaims);
