import React, { Component } from 'react';
import { Col, Row, Card } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import MobileList from './MobileList';
import WebList from './WebList';
import Gallery from '../../General/Gallery';
import { getUser } from '../../utils/StorageFunctions';
import ProfileModal from '../../utils/ProfileModal';
import VenueProfile from './VenueProfile';
// import GeneralMap from '../../General/GeneralMap';
import TooMap from '../../General/TooMap';
import { getPoints } from '../../General/MapFunctions';
import VenuesSearch from './VenuesSearch';
import { IMAGE_BASE_PATH } from '../../General/StaticKeys';
import no_photo from '../../assets/img/avater2.svg';
import { CLEAR_VENUES_STATE } from '../../Redux/Actions/types';
class Venues extends Component {
  componentDidMount() {
    this.props.general([], CLEAR_VENUES_STATE);
    if (getUser() === null) {
      this.props.history.push('/login');
    } else {
      this.props.fetchVenues([], 1);
    }
  }

  renderMap = () => {
    const { data, location, profileModal, general, companyImage } = this.props;
    if (data.items) {
      if (data.items.length > 0) {
        let branches = [];
        data.items.map((item) => {
          if (item.branches) {
            let entry = getPoints(
              item.branches,
              'locationText',
              undefined,
              'branch'
            );
            entry.forEach((element) => {
              branches.push(element);
            });
          }
        });

        if (profileModal.isOpen === false) {
          return (
            <Card className='mapCard'>
              <TooMap
                location={location}
                markers_list={branches ? branches : null}
                general={general}
                companyIcon={
                  companyImage ? IMAGE_BASE_PATH + companyImage : no_photo
                }
              />
              {/* <GeneralMap
                location={location}
                markers_list={branches ? branches : null}
                general={general}
                companyPhoto={
                  companyImage ? IMAGE_BASE_PATH + companyImage : no_photo
                }
              /> */}
            </Card>
          );
        }
      }
    }
  };

  render() {
    const {
      history,
      data,
      DeleteVenue,
      general,
      deleteMultipleVenues,
      fetchVenues,
      usersOptions,
      lightbox,
      profileModal,
      rows_collabse,
      location,
      fetchVenue,
      loadUserOptions,
    } = this.props;
    return (
      <div className='page-container'>
        <Gallery lightbox={lightbox} general={general} />

        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={local.VenueProfile}
          Children={VenueProfile}
          location={location}
        />

        <Row>
          <Col>
            <VenuesSearch
              filterArray={data.filterArray}
              usersOptions={usersOptions}
              general={general}
              fetchVenues={fetchVenues}
              isLoading={data.isLoadingSearch}
              loadUserOptions={loadUserOptions}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mobile_col'>
            <MobileList
              history={history}
              data={data}
              DeleteVenue={DeleteVenue}
              general={general}
              deleteMultipleVenues={deleteMultipleVenues}
              fetchVenues={fetchVenues}
              fetchVenue={fetchVenue}
            />
          </Col>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ height: '100vh' }}
          >
            <div
              className='table_container'
              style={{
                width:
                  rows_collabse.length > 0
                    ? rows_collabse.find((col) => col === true)
                      ? '98%'
                      : 'fit-content'
                    : 'fit-content',
              }}
            >
              <WebList
                history={history}
                data={data}
                DeleteVenue={DeleteVenue}
                general={general}
                deleteMultipleVenues={deleteMultipleVenues}
                fetchVenues={fetchVenues}
                rows_collabse={rows_collabse}
                fetchVenue={fetchVenue}
              />
            </div>

            {this.renderMap()}
          </Col>
        </Row>
      </div>
    );
  }
}

const GeneralMaptateToProps = ({ VenuesR, UsersR, GeneralR }) => {
  return {
    data: VenuesR.data,
    usersOptions: UsersR.usersOptions,
    lightbox: GeneralR.lightbox,
    profileModal: GeneralR.profileModal,
    location: GeneralR.location,
    rows_collabse: GeneralR.rows_collabse,
    companyImage: GeneralR.companyImage,
  };
};
export default connect(GeneralMaptateToProps, actions)(Venues);
