import React from "react";
import Home from "../Pages/Home/Home";
import Users from "../Pages/Users/Users";
import Support from "../Pages/Support/Support";
import BusinessAccount from "../Pages/BusinessAccount/BusinessAccount";
import Venues from "../Pages/Venues/Venues";
import CreateVenue from "../Pages/Venues/CreateVenue";
import EditVenue from "../Pages/Venues/EditVenue";
import Branches from "../Pages/Branches/Branches";
import CreateBranch from "../Pages/Branches/CreateBranch";
import EditBranch from "../Pages/Branches/EditBranch";
import { BranchGroupForm } from "../Pages/BranchesGroup/form";
import Distributors from "../Pages/Distributors/Distributors";
import CreateDistributor from "../Pages/Distributors/CreateDistributor";
import EditDistributor from "../Pages/Distributors/EditDistributor";
import Welcome from "../Pages/Welcome";
import EditUser from "../Pages/Users/EditUser";
import Profile from "../Pages/Profile";
import Contribuations from "../Pages/Contribuations/Contribuations";
import CreateContribuation from "../Pages/Contribuations/CreateContribuation";
import EditContribuation from "../Pages/Contribuations/EditContribuation";
import Tickets from "../Pages/Tickets/Tickets";
import CreateTicket from "../Pages/Tickets/CreateTicket";
import EditTicket from "../Pages/Tickets/EditTicket";
import Complaints from "../Pages/Complaints/Complaints";
import AddComplaintType from "../Pages/ComplaintType/AddComplaintType";
import Reviews from "../Pages/Reviews/Reviews";
import Roles from "../Pages/Roles/Roles";
import CreateRole from "../Pages/Roles/CreateRole";
import EditRole from "../Pages/Roles/EditRole";
import RolesClaims from "../Pages/RolesClaims/RolesClaims";
import CreateRoleClaim from "../Pages/RolesClaims/CreateRoleClaim";
import UserClaims from "../Pages/UserClaims/UserClaims";
import CreateUserClaim from "../Pages/UserClaims/CreateUserClaim";
import UserRoles from "../Pages/UserRoles/UserRoles";
import CreateUserRole from "../Pages/UserRoles/CreateUserRole";
import { ReactComponent as HomeLogo } from "../assets/img/sidebar/home.svg";
import { ReactComponent as UserLogo } from "../assets/img/sidebar/user.svg";
import { ReactComponent as SupportLogo } from "../assets/img/sidebar/support.svg";
import { ReactComponent as PlacesLogo } from "../assets/img/sidebar/places.svg";
import { ReactComponent as MarktingLogo } from "../assets/img/sidebar/markting.svg";
import { ReactComponent as TicketLogo } from "../assets/img/sidebar/ticket.svg";
import { ReactComponent as ComplaintLogo } from "../assets/img/sidebar/complaint.svg";
import { ReactComponent as RoleLogo } from "../assets/img/sidebar/roles.svg";
import { ReactComponent as ReviewsLogo } from "../assets/img/sidebar/reviews.svg";

const routes = [
  {
    component: Welcome,
    name: "Welcome",
    path: "/dashboard",
    key: "/dashboard",
  },

  {
    component: Home,
    name: "Home",
    path: "/dashboard/home",
    toAside: true,
    key: "home",
    icon: <HomeLogo />,
    Permissions: ["Home_Role", "Administrator"],
  },

  {
    component: Users,
    name: "Users",
    path: "/dashboard/users",
    toAside: true,
    key: "users",
    icon: <UserLogo />,
  },
  {
    component: EditUser,
    name: "EditUser",
    path: "/dashboard/edituser",
    toAside: false,
  },
  {
    component: Profile,
    name: "Profile",
    path: "/dashboard/profile",
    toAside: false,
  },
  {
    component: Support,
    name: "Support",
    path: "/dashboard/support",
    toAside: true,
    key: "support",
    icon: <SupportLogo />,
    Permissions: ["Support_Role", "Administrator"],
  },
  {
    component: Contribuations,
    name: "Places",
    path: "/dashboard/places",
    toAside: true,
    key: "places",
    children: true,
    icon: <PlacesLogo />,
  },
  {
    component: CreateContribuation,
    name: "AddPlace",
    path: "/dashboard/addplace",
    toAside: true,
    key: "addplace",
    parent: "places",
  },
  {
    component: EditContribuation,
    name: "EditPlace",
    path: "/dashboard/editplace/:id",
    toAside: false,
  },
  {
    component: BusinessAccount,
    name: "BusinessAccount",
    path: "/dashboard/business_account",
    toAside: true,
    key: "business_account",
    children: true,
    icon: <MarktingLogo />,
  },
  {
    component: Venues,
    name: "Venues",
    path: "/dashboard/venues",
    toAside: true,
    key: "venues",
    parent: "business_account",
  },
  {
    component: CreateVenue,
    name: "AddVenue",
    path: "/dashboard/addvenue",
    toAside: true,
    key: "addvenue",
    parent: "business_account",
  },
  {
    component: EditVenue,
    name: "EditVenue",
    path: "/dashboard/editvenue/:id",
    toAside: false,
    parent: "business_account",
  },
  {
    component: Branches,
    name: "Branches",
    path: "/dashboard/branches",
    toAside: true,
    key: "branches",
    parent: "business_account",
  },
  {
    component: CreateBranch,
    name: "AddBranch",
    path: "/dashboard/addbranch",
    toAside: true,
    key: "addbranch",
    parent: "business_account",
  },
  {
    component: EditBranch,
    name: "EditBranch",
    path: "/dashboard/editbranch/:id",
    toAside: false,
    parent: "business_account",
  },
  {
    component: BranchGroupForm,
    name: "BranchesGroup",
    path: "/dashboard/addBranchesGroup",
    toAside: true,
    key: "addBranchesGroup",
    parent: "business_account",
  },
  {
    component: Distributors,
    name: "Distributors",
    path: "/dashboard/distributors",
    toAside: true,
    key: "distributors",
    parent: "business_account",
  },
  {
    component: CreateDistributor,
    name: "CreateDistributor",
    path: "/dashboard/add_distributor",
    toAside: true,
    key: "add_distributor",
    parent: "business_account",
  },
  {
    component: EditDistributor,
    name: "EditDistributor",
    path: "/dashboard/editdistributor/:id",
    toAside: false,
    parent: "business_account",
  },

  {
    component: Tickets,
    name: "Tickets",
    path: "/dashboard/tickets",
    toAside: true,
    key: "tickets",
    children: true,
    icon: <TicketLogo />,
  },
  {
    component: CreateTicket,
    name: "CreateTicket",
    path: "/dashboard/addticket",
    toAside: true,
    key: "addticket",
    parent: "tickets",
  },
  {
    component: EditTicket,
    name: "EditTicket",
    path: "/dashboard/editticket/:id",
    toAside: false,
  },

  {
    component: Complaints,
    name: "Violations",
    path: "/dashboard/complaints",
    toAside: true,
    key: "complaints",
    children: true,
    icon: <ComplaintLogo />,
    Permissions: ["GeoEvent_Get", "Administrator", "GeoEventType_Create"],
  },

  {
    component: AddComplaintType,
    name: "AddComplaintType",
    path: "/dashboard/add_complaint_type",
    toAside: true,
    key: "add_complaint_type",
    parent: "complaints",
    Permissions: ["GeoEventType_Create", "Administrator"],
  },
  {
    component: AddComplaintType,
    name: "EditComplaintType",
    path: "/dashboard/edit_complaint_type/:id",
    toAside: false,
  },

  {
    component: Roles,
    name: "Roles",
    path: "/dashboard/roles",
    toAside: true,
    key: "roles",
    children: true,
    icon: <RoleLogo />,
    Permissions: [
      "RoleBusiness_GetRoles",
      "Administrator",
      "RoleBusiness_Create",
    ],
  },
  {
    component: CreateRole,
    name: "AddRole",
    path: "/dashboard/addrole",
    toAside: true,
    key: "createRole",
    parent: "roles",
    Permissions: ["RoleBusiness_Create", "Administrator"],
  },
  {
    component: EditRole,
    name: "EditRole",
    path: "/dashboard/editrole/:id",
    toAside: false,
  },

  {
    component: RolesClaims,
    name: "RoleClaims",
    path: "/dashboard/roles_claims",
    toAside: true,
    key: "roles_claims",
    children: true,
    icon: <RoleLogo />,
    Permissions: [
      "RoleBusiness_GetRoles",
      "Administrator",
      "RoleBusiness_AttachRoleClaim",
    ],
  },
  {
    component: CreateRoleClaim,
    name: "AddRoleClaim",
    path: "/dashboard/attach_roles_claims",
    toAside: true,
    key: "attach_roles_claims",
    parent: "roles_claims",
    Permissions: ["RoleBusiness_AttachRoleClaim", "Administrator"],
  },

  {
    component: UserClaims,
    name: "UserClaims",
    path: "/dashboard/users_claims",
    toAside: true,
    key: "users_claims",
    children: true,
    icon: <RoleLogo />,
    Permissions: [
      "RoleBusiness_GetUserClaims",
      "Administrator",
      "RoleBusiness_AttachUserClaim",
    ],
  },
  {
    component: CreateUserClaim,
    name: "AddUserClaim",
    path: "/dashboard/attach_user_claims",
    toAside: true,
    key: "attach_user_claims",
    parent: "users_claims",
    Permissions: ["RoleBusiness_AttachUserClaim", "Administrator"],
  },

  {
    component: UserRoles,
    name: "GetUserRole",
    path: "/dashboard/users_roles",
    toAside: true,
    key: "users_roles",
    children: true,
    icon: <RoleLogo />,
    Permissions: [
      "RoleBusiness_GetUserRole",
      "Administrator",
      "RoleBusiness_AttachUserRole",
    ],
  },
  {
    component: CreateUserRole,
    name: "UserRole",
    path: "/dashboard/attach_user_roles",
    toAside: true,
    key: "attach_user_roles",
    parent: "users_roles",
    Permissions: ["RoleBusiness_AttachUserRole", "Administrator"],
  },

  {
    component: Reviews,
    name: "Reviews",
    path: "/dashboard/reviews",
    toAside: true,
    key: "reviews",
    icon: <ReviewsLogo />,
  },
];

export default routes;
