import React, { Component } from 'react';
import { Col, Row, Card } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import WebList from './WebList';
import Gallery from '../../General/Gallery';
import TooMap from '../../General/TooMap';
import { getPoints } from '../../General/MapFunctions';
import { WorkingHoursModal } from '../../General/TableFunctions';
import {
  CLEAR_CONTRIBUTION_STATE,
  UPDATE_CONTRIBUTION_PROPS,
} from '../../Redux/Actions/types';
import { getUser } from '../../utils/StorageFunctions';
import ProfileModal from '../../utils/ProfileModal';
import ContribuationProfile from './ContribuationProfile';
import ContribuationSearch from './ContribuationSearch';
import MobileList from './MobileList';
import { UPDATE_GENERAL_PROPS } from '../../Redux/Actions/types';

class Contribuations extends Component {
  componentDidMount() {
    this.props.general([], CLEAR_CONTRIBUTION_STATE);
    if (getUser() === null) {
      this.props.history.push('/login');
    } else {
      this.props.general(
        [{ prop: 'rows_collabse', value: [] }],
        UPDATE_GENERAL_PROPS
      );
      if (this.props.layersOptions.length === 0) {
        this.props.getLayers();
      }

      if (!this.props.data.watiningPlace) {
        this.props.fetchContribtions([], 1, this.props.generalUsers);
      } else {
        this.props.general(
          [{ prop: 'data.items', value: this.props.data.watiningPlace }],
          UPDATE_CONTRIBUTION_PROPS
        );
      }
    }
  }

  renderMap = () => {
    const { data, location, general, zoomToShape, shapeItem } = this.props;
    if (data.items) {
      return (
        <Card className='mapCard'>
          <TooMap
            location={location}
            markers_list={getPoints(data.items, 'geometryText')}
            general={general}
            zoomToShape={zoomToShape}
            shapeItem={shapeItem}
          />
          {/* <GeneralMap
            location={location}
            markers_list={getPoints(data.items, "geometryText")}
            general={general}
            zoomToShape={zoomToShape}
            shapeItem={shapeItem}
          /> */}
        </Card>
      );
    }
  };

  render() {
    const {
      history,
      data,
      deleteContribution,
      general,
      changePlaceStatus,
      deleteMultipleContributions,
      fetchContribtions,
      usersOptions,
      loadUserOptions,
      layersOptions,
      lightbox,
      workingDaysModal,
      profileModal,
      rows_collabse,
      generalUsers,
    } = this.props;
    return (
      <div className='page-container'>
        <Gallery lightbox={lightbox} general={general} />
        <WorkingHoursModal
          workingDaysModal={workingDaysModal}
          general={general}
        />
        <ProfileModal
          profileModal={profileModal}
          general={general}
          title={local.ContribuationProfile}
          Children={ContribuationProfile}
          workingDaysModal={workingDaysModal}
        />

        <Row>
          <Col>
            <ContribuationSearch
              filterArray={data.filterArray}
              general={general}
              isLoading={data.isLoadingSearch}
              fetchContribtions={fetchContribtions}
              layersOptions={layersOptions}
              history={history}
              data={data}
              deleteMultipleContributions={deleteMultipleContributions}
              usersOptions={usersOptions}
              loadUserOptions={loadUserOptions}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mobile_col'>
            <MobileList
              history={history}
              data={data}
              deleteMultipleContributions={deleteMultipleContributions}
              changePlaceStatus={changePlaceStatus}
              deleteContribution={deleteContribution}
              general={general}
              fetchContribtions={fetchContribtions}
              layersOptions={layersOptions}
              rows_collabse={rows_collabse}
              generalUsers={generalUsers}
            />
          </Col>
          <Col
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ height: '100vh' }}
          >
            <div
              className='table_container'
              style={{
                width:
                  rows_collabse.length > 0
                    ? rows_collabse.find((col) => col === true)
                      ? '98%'
                      : 'fit-content'
                    : 'fit-content',
              }}
            >
              <WebList
                history={history}
                data={data}
                deleteMultipleContributions={deleteMultipleContributions}
                changePlaceStatus={changePlaceStatus}
                deleteContribution={deleteContribution}
                general={general}
                fetchContribtions={fetchContribtions}
                rows_collabse={rows_collabse}
                generalUsers={generalUsers}
              />
            </div>

            {this.renderMap()}
          </Col>
        </Row>
      </div>
    );
  }
}

const GeneralMaptateToProps = ({ ContribuationsR, UsersR, GeneralR }) => {
  return {
    data: ContribuationsR.data,
    layersOptions: ContribuationsR.layersOptions,
    usersOptions: UsersR.usersOptions,
    lightbox: GeneralR.lightbox,
    location: GeneralR.location,
    workingDaysModal: GeneralR.workingDaysModal,
    profileModal: GeneralR.profileModal,
    rows_collabse: GeneralR.rows_collabse,
    zoomToShape: GeneralR.zoomToShape,
    shapeItem: GeneralR.shapeItem,
    generalUsers: GeneralR.generalUsers,
  };
};
export default connect(GeneralMaptateToProps, actions)(Contribuations);
