import { SendRequest } from "./api";
import { MERGE_HOME_PROPS, UPDATE_HOME_PROPS } from "./types";

export const getAllUsersForMap = (page, poly, id) => (dispatch) => {
  let filterArray = [
    {
      op: 3,
      fieldName: "lastSeen",
      value: poly,
    },
  ];
  SendRequest()
    .post(`/User/Get?page=${page}`, filterArray)
    .then((res) => {
      if (res.status === 200) {
        // debugger;
        res.data.data.items.map((r) => {
          // if (!r.lastSeenText && r.country) {
          //   let country = r.country.toLowerCase();
          // }
        });
        if (page === 0) {
          dispatch({
            type: MERGE_HOME_PROPS,
            payload: [
              {
                prop: "data.countries",
                value: id,
              },
            ],
          });
        }
        dispatch({
          type: MERGE_HOME_PROPS,
          payload: [
            {
              prop: "data.usersMap",
              value: res.data.data.items,
            },
          ],
        });
        if (res.data.data.currentPage < res.data.data.totalPages) {
          dispatch(getAllUsersForMap(page + 1, poly));
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const getActiveUsers = () => (dispatch) => {
  SendRequest()
    .post("/User/Get", [
      {
        op: 0,
        fieldName: "EmailConfirmed",
        value: true,
      },
    ])
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_HOME_PROPS,
          payload: [
            {
              prop: "data.active_users",
              value: res.data.data.totalItems,
            },
          ],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
export const getInActiveUsers = () => (dispatch) => {
  SendRequest()
    .post("/User/Get", [
      {
        op: 0,
        fieldName: "EmailConfirmed",
        value: false,
      },
    ])
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_HOME_PROPS,
          payload: [
            {
              prop: "data.inactive_users",
              value: res.data.data.totalItems,
            },
          ],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
export const getTotalRequests = () => (dispatch) => {
  SendRequest()
    .post("/Ticket/Get", [])
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_HOME_PROPS,
          payload: [
            {
              prop: "data.tickets",
              value: res.data.data.totalItems,
            },
            {
              prop: "data.user_system.tickets",
              value: res.data.data.totalItems,
            },
          ],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const getStatisticsLayers = () => (dispatch) => {
  dispatch({
    type: UPDATE_HOME_PROPS,
    payload: [
      {
        prop: "data.layers",
        value: [],
      },
    ],
  });
  let layers = [
    [
      {
        op: 0,
        fieldName: "Layer",
        value: "cafe",
      },
    ],
    [
      {
        op: 0,
        fieldName: "Layer",
        value: "restaurant",
      },
    ],
    [
      {
        op: 0,
        fieldName: "Layer",
        value: "hospital",
      },
    ],
    [
      {
        op: 0,
        fieldName: "Layer",
        value: "bank",
      },
    ],
    [
      {
        op: 0,
        fieldName: "Layer",
        value: "shopping_mall",
      },
    ],
  ];

  layers.forEach((element) => {
    SendRequest()
      .post("/Contribution/Get", element)
      .then((res) => {
        if (res.status === 200) {
          let item = {
            title: element[0].value,
            count: res.data.data.totalItems,
          };

          dispatch({
            type: MERGE_HOME_PROPS,
            payload: [
              {
                prop: "data.layers",
                value: item,
              },
            ],
          });
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally();
  });
};

export const getSystemUsers = () => (dispatch) => {
  SendRequest()
    .post("/User/Get", [])
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_HOME_PROPS,
          payload: [
            {
              prop: "data.user_system.users",
              value: res.data.data.totalItems,
            },
          ],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
  SendRequest()
    .post("/Venues/Get", [])
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_HOME_PROPS,
          payload: [
            {
              prop: "data.user_system.venues",
              value: res.data.data.totalItems,
            },
          ],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const getTotalGuests = () => (dispatch) => {
  SendRequest()
    .post("/Guests/Get", [])
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_HOME_PROPS,
          payload: [
            {
              prop: "data.user_system.guests",
              value: res.data.data.totalItems,
            },
          ],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
