import React, { Component } from "react";
import { Col, Row, Card, Form, Spinner, Input } from "reactstrap";
import * as actions from "../../Redux/Actions/index";
import { connect } from "react-redux";
import { getLanguage, local } from "../../lang/local";
import Dropzone from "react-dropzone";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import image_icon from "../../assets/img/image_icon.png";
import strik from "../../assets/img/strik.png";
import OpenningHours from "../../General/OpenningHours";
import {
  CLEAR_BRANCHES_STATE,
  CLEAR_GENERAL_STATE,
  DELETE_BRANCHES_PROPS,
  MERGE_BRANCHES_PROPS,
  UPDATE_BRANCHES_PROPS,
} from "../../Redux/Actions/types";
// import GeneralMap from "../../General/GeneralMap";

import { handleSave, onMapClick } from "./BranchFunctions";
import { selectFormStyle } from "../../General/GeneralStyle";
import { AsyncPaginate } from "react-select-async-paginate";

import {
  handleArabicNameChange,
  handleEnglishNameChange,
  saveImage,
  renderPhotos,
  renderErrorMessage,
} from "../../General/GeneralFunctions";
import { getUser } from "../../utils/StorageFunctions";
import { getDefaultValue } from "../../General/SelectFunctions";
import Gallery from "../../General/Gallery";
import SearchBox from "../../General/MapSearch/SearchBox";
import TooMap from "../../General/TooMap";
class CreateBranch extends Component {
  componentDidMount() {
    if (getUser() === null) {
      this.props.history.push("/login");
    } else {
      this.props.general([], CLEAR_GENERAL_STATE);
      this.props.general([], CLEAR_BRANCHES_STATE);
    }
  }

  handlePlaceSearch = (lat, lng, label) => {
    const { general, form } = this.props;
    onMapClick(lat, lng, general);

    if (getLanguage() === "en") {
      if (form.Name.en === "") {
        general(
          [{ prop: "data.form.Name.en", value: label }],
          UPDATE_BRANCHES_PROPS
        );
      }
    } else {
      if (form.Name.ar === "") {
        general(
          [{ prop: "data.form.Name.ar", value: label }],
          UPDATE_BRANCHES_PROPS
        );
      }
    }
  };
  render() {
    const {
      form,
      isLoadingCreate,
      general,
      location,
      addBranch,
      venuesOptions,
      venueId,
      lightbox,
      mapSearch,
      myPosition,
      ipAddress,
      getSocketResult,
      errorMessages,
      loadVenueOptions,
    } = this.props;

    return (
      <div className="page-container">
        <Gallery lightbox={lightbox} general={general} />
        <Row>
          <Col
            xl={10}
            lg={10}
            md={10}
            sm={12}
            xs={12}
            className="mx-auto text-center"
          >
            <h3 className="page_create_title">{local.AddBranch}</h3>
            <Card className="create_card">
              <Form>
                <Row>
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className="mt-3 mx-auto"
                  >
                    <div className="container_add_input mx-auto">
                      <img src={strik} alt="strik" className="strik_icon" />
                      <Input
                        className="add_input"
                        value={form.Name.en}
                        onChange={(e) =>
                          handleEnglishNameChange(
                            e,
                            general,
                            UPDATE_BRANCHES_PROPS,
                            "data.form.Name.en"
                          )
                        }
                        placeholder={local.EnglishName}
                      />
                    </div>
                    {renderErrorMessage(form.Name.en, errorMessages.NameEn)}
                  </Col>

                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className="mt-3 mx-auto"
                  >
                    <div className="container_add_input mx-auto">
                      <img src={strik} alt="strik" className="strik_icon" />
                      <Input
                        className="add_input"
                        value={form.Name.ar}
                        onChange={(e) =>
                          handleArabicNameChange(
                            e,
                            general,
                            UPDATE_BRANCHES_PROPS,
                            "data.form.Name.ar"
                          )
                        }
                        placeholder={local.ArabicName}
                      />
                    </div>
                    {renderErrorMessage(form.Name.ar, errorMessages.NameAr)}
                  </Col>

                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className="mt-3 mx-auto"
                  >
                    <div className="container_add_input mx-auto">
                      <PhoneInput
                        country="sa"
                        lang={getLanguage() === "ar" ? ar : ""}
                        // onlyCountries={[
                        //   "eg",
                        //   "sa",
                        //   "ae",
                        //   "ku",
                        //   "lb",
                        //   "om",
                        //   "bh",
                        //   "ae",
                        // ]}
                        masks={{
                          eg: ".. ........",
                          sa: ".. ... ....",
                          ae: ".. .......",
                        }}
                        countryCodeEditable={false}
                        value={form.Phone}
                        onChange={(mobile) =>
                          general(
                            [
                              {
                                prop: "data.form.Phone",
                                value: mobile,
                              },
                            ],
                            UPDATE_BRANCHES_PROPS
                          )
                        }
                      />
                    </div>
                  </Col>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="mt-3 mx-auto"
                  >
                    <div className="container_add_input mx-auto">
                      <img src={strik} alt="strik" className="strik_icon" />
                      <div className="form_select_container">
                        <AsyncPaginate
                          key={venueId}
                          placeholder={local.Venues}
                          value={getDefaultValue(venueId, venuesOptions)}
                          loadOptions={loadVenueOptions}
                          onChange={(e) =>
                            e !== null
                              ? general(
                                  [
                                    {
                                      prop: "data.venueId",
                                      value: e.value,
                                    },
                                  ],
                                  UPDATE_BRANCHES_PROPS
                                )
                              : general(
                                  [
                                    {
                                      prop: "data.venueId",
                                      value: null,
                                    },
                                  ],
                                  UPDATE_BRANCHES_PROPS
                                )
                          }
                          additional={{
                            page: 1,
                          }}
                          styles={selectFormStyle}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                    </div>
                    {renderErrorMessage(venueId, errorMessages.Venue)}
                  </Col>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="mt-3 mx-auto"
                  >
                    <OpenningHours
                      general={general}
                      WorkingH
                      WorkingHours={form.WorkingHours}
                      action={UPDATE_BRANCHES_PROPS}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="mt-3 mx-auto"
                  >
                    <Card
                      className="img_card_container"
                      style={{ width: "100%" }}
                    >
                      <Row className="fullWidthHeight">
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="alignItem flex"
                        >
                          <Dropzone
                            accept="image/*"
                            multiple
                            onDrop={(acceptedFiles) =>
                              saveImage(
                                acceptedFiles,
                                "data.form.Photos",
                                general,
                                MERGE_BRANCHES_PROPS
                              )
                            }
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              acceptedFiles,
                            }) => (
                              <div {...getRootProps()} className="cursor">
                                <input
                                  {...getInputProps()}
                                  multiple={true}
                                  accept="image/*"
                                  defaultValue={acceptedFiles}
                                />

                                <div className="img_conteiner_palce">
                                  <img
                                    src={image_icon}
                                    alt=""
                                    className="imgFull"
                                  />
                                </div>
                              </div>
                            )}
                          </Dropzone>

                          {renderPhotos(
                            form.Photos,
                            "data.form.Photos.",
                            general,
                            DELETE_BRANCHES_PROPS
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="mt-3 mx-auto"
                  >
                    <Card
                      className="mapCard"
                      style={{
                        height: "400px",
                      }}
                    >
                      <SearchBox
                        mapSearch={mapSearch}
                        general={general}
                        action={UPDATE_BRANCHES_PROPS}
                        propObj={[
                          { prop: "data.form.Lat", value: "" },
                          { prop: "data.form.Lng", value: "" },
                        ]}
                        myPosition={myPosition}
                        ipAddress={ipAddress}
                        getSocketResult={getSocketResult}
                        handlePlaceSearch={this.handlePlaceSearch}
                      />
                      <TooMap
                        location={location}
                        onMapClick={onMapClick}
                        general={general}
                        point={
                          form.Lat ? { lat: form.Lat, lng: form.Lng } : null
                        }
                      />
                      {/* <GeneralMap
                              location={location}
                              onMapClick={onMapClick}
                              general={general}
                              point={{ lat: form.Lat, lng: form.Lng }}
                            /> */}
                    </Card>
                    {renderErrorMessage(form.Lat, errorMessages.Location)}
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className="mt-5 mx-auto"
                  >
                    <button
                      className="save_btn"
                      onClick={(e) =>
                        handleSave(e, addBranch, form, general, null, venueId)
                      }
                    >
                      {isLoadingCreate === true ? <Spinner /> : local.Save}
                    </button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const GeneralMaptateToProps = ({ BranchesR, GeneralR, VenuesR }) => {
  return {
    form: BranchesR.data.form,
    errorMessages: BranchesR.data.errorMessages,
    venueId: BranchesR.data.venueId,
    isLoadingCreate: BranchesR.isLoadingCreate,
    location: GeneralR.location,
    venuesOptions: VenuesR.venuesOptions,
    lightbox: GeneralR.lightbox,
    mapSearch: GeneralR.mapSearch,
    myPosition: GeneralR.myPosition,
    ipAddress: GeneralR.ipAddress,
  };
};
export default connect(GeneralMaptateToProps, actions)(CreateBranch);
