import React, { Component } from 'react';
import { Col, Row, Card, Form, Spinner } from 'reactstrap';
import * as actions from '../../Redux/Actions/index';
import { connect } from 'react-redux';
import { local } from '../../lang/local';
import strik from '../../assets/img/strik.png';
import {
  CLEAR_USER_CLAIMS_STATE,
  UPDATE_USER_ROLES_PROPS,
} from '../../Redux/Actions/types';
import { handleSave } from './UserRolesFunctions';
import { isRtl, selectFormStyle } from '../../General/GeneralStyle';
import AsyncSelect from 'react-select/async';
import { renderErrorMessage } from '../../General/GeneralFunctions';
import { getUser } from '../../utils/StorageFunctions';
import { AsyncPaginate } from 'react-select-async-paginate';

class CreateUserRole extends Component {
  componentDidMount() {
    if (getUser() === null) {
      this.props.history.push('/login');
    } else {
      this.props.getRolesForDropDown();
    }
    this.props.general([], CLEAR_USER_CLAIMS_STATE);
  }

  haandleSelectUser = (e) => {
    const { general, getRoleByUser } = this.props;
    if (e) {
      getRoleByUser(e);

      general(
        [
          {
            prop: 'data.form.users',
            value: e,
          },
        ],
        UPDATE_USER_ROLES_PROPS
      );
    } else {
      general(
        [
          {
            prop: 'data.form.users',
            value: [],
          },
        ],
        UPDATE_USER_ROLES_PROPS
      );
    }
  };

  filterItems = (inputValue) => {
    const { rolesOptions } = this.props;
    if (rolesOptions.length > 0) {
      return rolesOptions.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  };

  promiseRoles = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterItems(inputValue));
      }, 1000);
    });
  render() {
    const {
      history,
      form,
      isLoadingCreate,
      general,
      addRoleToUser,
      rolesOptions,
      loadUserOptions,
      errorMessages,
    } = this.props;
    return (
      <div className='page-container'>
        <Row>
          <Col
            xl={8}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className='mx-auto text-center'
          >
            <h3 className='page_create_title'>{local.AddRoleUser}</h3>
            <Card className='create_card'>
              <Form>
                <Row>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    className='mt-3 mx-auto'
                  >
                    <div className='container_add_input mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <div className='form_select_container'>
                        <AsyncSelect
                          key={form.roles}
                          isMulti={true}
                          placeholder={local.Roles}
                          value={form.roles}
                          defaultOptions={rolesOptions}
                          cacheOptions
                          loadOptions={this.promiseRoles}
                          onChange={(e) =>
                            e !== null
                              ? general(
                                  [
                                    {
                                      prop: 'data.form.roles',
                                      value: e,
                                    },
                                  ],
                                  UPDATE_USER_ROLES_PROPS
                                )
                              : general(
                                  [
                                    {
                                      prop: 'data.form.roles',
                                      value: [],
                                    },
                                  ],
                                  UPDATE_USER_ROLES_PROPS
                                )
                          }
                          isClearable={true}
                          isRtl={isRtl()}
                          styles={selectFormStyle}
                          isSearchable={true}
                        />
                      </div>
                    </div>
                    {renderErrorMessage(form.role_id, errorMessages.Role)}
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    className='mt-4 mx-auto'
                  >
                    <div className='container_add_input mx-auto'>
                      <img src={strik} alt='strik' className='strik_icon' />
                      <div className='form_select_container'>
                        <AsyncPaginate
                          key={form.users}
                          isMulti={true}
                          placeholder={local.User}
                          value={form.users}
                          loadOptions={(search, options, page) =>
                            loadUserOptions(search, options, page, true)
                          }
                          onChange={this.haandleSelectUser}
                          additional={{
                            page: 1,
                          }}
                          styles={selectFormStyle}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                    </div>
                    {renderErrorMessage(form.user_id, errorMessages.User)}
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className='mt-5 mx-auto'
                  >
                    <button
                      className='save_btn'
                      onClick={(e) =>
                        handleSave(e, addRoleToUser, form, general)
                      }
                    >
                      {isLoadingCreate === true ? <Spinner /> : local.Save}
                    </button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ UserRolesR, GeneralR, UsersR, RolesR }) => {
  return {
    form: UserRolesR.data.form,
    users: UserRolesR.data.users,
    errorMessages: UserRolesR.data.errorMessages,
    userId: UserRolesR.data.userId,
    isLoadingCreate: UserRolesR.isLoadingCreate,
    location: GeneralR.location,
    usersOptions: UsersR.usersOptions,
    rolesOptions: RolesR.rolesOptions,
  };
};
export default connect(mapStateToProps, actions)(CreateUserRole);
