import {
  convertToFormData,
  showConfirmMessage,
  removeDublicatedValues,
  showSuccessedConfirm,
  filterWorkingHours,
  removeDuplicateElement,
} from "../../General/GeneralFunctions";
import { getLanguage, local } from "../../lang/local";
import { SendRequest, Request } from "./api";
import {
  DELETE_CONTRIBUTION_PROPS,
  MERGE_CONTRIBUTION_PROPS,
  UPDATE_CONTRIBUTION_PROPS,
} from "./types";
import { _Layers } from "../../General/_Layers";
import {
  renderImages,
  storeWorkingHours,
} from "../../Pages/Branches/BranchFunctions";
import { getGeneralUsers } from ".";
export const fetchContribtions =
  (filterArray, page, generalUsers) => (dispatch) => {
    if (filterArray.length > 0) {
      dispatch({
        type: UPDATE_CONTRIBUTION_PROPS,
        payload: [{ prop: "data.isLoadingSearch", value: true }],
      });
    }
    dispatch({
      type: UPDATE_CONTRIBUTION_PROPS,
      payload: [{ prop: "data.isLoading", value: true }],
    });
    SendRequest()
      .post(`/Contribution/Get?page=${page}`, filterArray)
      .then((res) => {
        if (res.status === 200) {
          let pagination = Object.assign({}, res.data.data);
          let ids = res.data.data.items.map((cont, i) => {
            return { id: cont.gisId, index: i };
          });

          if (res.data.data.items) {
            let users = res.data.data.items.map((u) => u.userId);
            let uniqueTotals = removeDuplicateElement(users);
            if (generalUsers !== undefined) {
              dispatch(getGeneralUsers(uniqueTotals, generalUsers));
            } else {
              dispatch(getGeneralUsers(uniqueTotals, []));
            }
          }

          dispatch(fetchImages(ids));
          delete pagination.items;
          if (res.data.data.items.length === 0 && filterArray.length > 0) {
            showConfirmMessage(local.NoSearch, "error");
          }
          res.data.data.items.forEach((item) => {
            item.images = [];
          });

          dispatch({
            type: UPDATE_CONTRIBUTION_PROPS,
            payload: [
              { prop: "data.items", value: res.data.data.items },
              { prop: "data.pagination", value: pagination },
            ],
          });
        }

        dispatch({
          type: UPDATE_CONTRIBUTION_PROPS,
          payload: [
            { prop: "data.isLoading", value: false },
            { prop: "data.isLoadingSearch", value: false },
          ],
        });
      })
      .catch((e) => {
        dispatch({
          type: UPDATE_CONTRIBUTION_PROPS,
          payload: [
            { prop: "data.isLoading", value: false },
            { prop: "data.isLoadingSearch", value: false },
          ],
        });
      })
      .finally();
  };

export const changePlaceStatus = (id, index, status) => (dispatch) => {
  SendRequest()
    .post(`/contribution/Confirm/${id}`, { status })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: UPDATE_CONTRIBUTION_PROPS,
          payload: [
            { prop: "data.items." + index + ".approved", value: status },
          ],
        });
        if (status === true) {
          showSuccessedConfirm(local.ApprovedPlace);
        } else {
          showSuccessedConfirm(local.RefusedSuccessfully);
        }
      }
    })
    .catch((e) => {
      if (Object.values(e)[2] !== undefined) {
        if (Object.values(e)[2].data.message !== undefined) {
          showConfirmMessage(Object.values(e)[2].data.message, "error");
        }
      }
      console.log(e);
    })
    .finally();
};

export const addContribution = (formValues) => (dispatch) => {
  dispatch({
    type: UPDATE_CONTRIBUTION_PROPS,
    payload: [{ prop: "isLoadingCreate", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);
  newFormValues.WorkingHours = filterWorkingHours(newFormValues.WorkingHours);

  let formData = convertToFormData(newFormValues);
  SendRequest()
    .post("/Contribution", formData)
    .then((res) => {
      if (res.status === 200) {
        showSuccessedConfirm(local.AddedSuccessfully);

        dispatch({
          type: UPDATE_CONTRIBUTION_PROPS,
          payload: [
            {
              prop: "data.form",
              value: {
                Geometry: "",
                ContributionName: { en: "", ar: "" },
                Description: { key: "" },
                Images: [],
                Layer: "",
                PhoneNumber: "",
                WorkingHours: [
                  { day: "Monday", from: null, to: null },
                  { day: "Tuesday", from: null, to: null },
                  { day: "Wednesday", from: null, to: null },
                  { day: "Thursday", from: null, to: null },
                  { day: "Friday", from: null, to: null },
                  { day: "Saturday", from: null, to: null },
                  { day: "Sunday", from: null, to: null },
                ],
                Categories: [],
                Website: "",
                Facebook: "",
                Instgram: "",
                Twitter: "",
                Email: "",
                Snapshat: "",
                Whatsapp: "",
              },
            },
            { prop: "data.exists_places", value: [] },
          ],
        });
      }
      dispatch({
        type: UPDATE_CONTRIBUTION_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_CONTRIBUTION_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const updateContribution = (formValues, history) => (dispatch) => {
  dispatch({
    type: UPDATE_CONTRIBUTION_PROPS,
    payload: [{ prop: "isLoadingCreate", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);
  newFormValues.WorkingHours = filterWorkingHours(newFormValues.WorkingHours);

  let formData = convertToFormData(newFormValues);
  SendRequest()
    .put(`/Contribution/${newFormValues.id}`, formData)
    .then((res) => {
      if (res.status === 200) {
        showSuccessedConfirm(local.SuccessfullyUpdated);

        history.push("/dashboard/places");
      }
      dispatch({
        type: UPDATE_CONTRIBUTION_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_CONTRIBUTION_PROPS,
        payload: [{ prop: "isLoadingCreate", value: false }],
      });
      console.log(e);
    })
    .finally();
};

export const deleteContribution = (id, index) => (dispatch) => {
  dispatch({
    type: UPDATE_CONTRIBUTION_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: null }],
  });
  SendRequest()
    .delete("Contribution/" + id)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_CONTRIBUTION_PROPS,
          payload: [
            {
              prop: "data.items." + index,
            },
          ],
        });
        showSuccessedConfirm(local.DeletedSuccessfully);
      }
      dispatch({
        type: UPDATE_CONTRIBUTION_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
    })
    .catch((err) => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_CONTRIBUTION_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
      console.log(err);
    })
    .finally();
};

export const deleteMultipleContributions = (ids, setChecked) => (dispatch) => {
  dispatch({
    type: UPDATE_CONTRIBUTION_PROPS,
    payload: [{ prop: "data.isLoadingDelete", value: true }],
  });

  SendRequest()
    .delete("Contribution/" + ids[0])
    .then((res) => {
      if (res.status === 200) {
        ids.shift();

        if (ids.length > 0) {
          dispatch(deleteMultipleContributions(ids, setChecked));
        } else {
          setChecked([]);
          showSuccessedConfirm(local.DeletedSuccessfully);
          dispatch(fetchContribtions([], 1));
        }
      }
      dispatch({
        type: UPDATE_CONTRIBUTION_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
    })
    .catch((err) => {
      if (Object.values(err)[2] !== undefined) {
        showConfirmMessage(Object.values(err)[2].data.message, "error");
      }
      dispatch({
        type: UPDATE_CONTRIBUTION_PROPS,
        payload: [{ prop: "data.isLoadingDelete", value: null }],
      });
      console.log(err);
    })
    .finally();
};

export const findContribution = (lat, lng, form) => (dispatch) => {
  dispatch({
    type: UPDATE_CONTRIBUTION_PROPS,
    payload: [{ prop: "data.isLoadingPlaces", value: true }],
  });
  dispatch({
    type: UPDATE_CONTRIBUTION_PROPS,
    payload: [{ prop: "editedPlaces", value: { items: [], isLoading: true } }],
  });
  let lang = getLanguage() === "en" ? "en" : "ar";

  SendRequest()
    .get(`/GeoServices/reverse?lng=${lng}&lat=${lat}&lang=${lang}`)
    .then((res) => {
      let places = [];

      if (res.status === 200) {
        if (res.data.data) {
          if (res.data.data.features) {
            if (res.data.data.features.length > 0) {
              res.data.data.features.forEach((place) => {
                let lng = place.geometry.coordinates[0];
                let lat = place.geometry.coordinates[1];

                let categories = [];
                let entry = _Layers.find(
                  (lay) => lay.act_layers[0] === place.properties.layer
                );

                if (entry !== undefined) {
                  categories = entry.act_categories;
                }

                places.push({
                  Layer: place.properties.layer,
                  Geometry: `POINT(${lng + " " + lat})`,
                  Gid: place.properties.gid,
                  Website: form.Website ? form.Website : "",
                  WorkingHours: form.WorkingHours ? form.WorkingHours : [],
                  PhoneNumber: form.PhoneNumber ? form.PhoneNumber : "",
                  Categories: categories,
                  ContributionName: {
                    en:
                      getLanguage() === "en"
                        ? place.properties.label
                        : form.ContributionName.en
                        ? form.ContributionName.en
                        : "",
                    ar:
                      getLanguage() === "ar"
                        ? place.properties.label
                        : form.ContributionName.ar
                        ? form.ContributionName.ar
                        : "",
                  },
                  Description: {
                    key: form.Description.key
                      ? form.Description.key
                      : removeDublicatedValues(
                          place.properties.name,
                          place.properties.street,
                          place.properties.county,
                          place.properties.region,
                          place.properties.country,
                          place.properties.continent
                        ),
                  },

                  Images: form.Images ? form.Images : [],
                });
              });
              dispatch({
                type: UPDATE_CONTRIBUTION_PROPS,
                payload: [
                  { prop: "data.exists_places", value: places },
                  { prop: "editedPlaces.items", value: places },
                ],
              });
            }
          }
        }
      }
      dispatch({
        type: UPDATE_CONTRIBUTION_PROPS,
        payload: [
          { prop: "data.isLoadingPlaces", value: false },
          { prop: "editedPlaces.isLoading", value: false },
        ],
      });
    })
    .catch((e) => {
      dispatch({
        type: UPDATE_CONTRIBUTION_PROPS,
        payload: [
          { prop: "data.isLoadingPlaces", value: false },
          { prop: "editedPlaces.isLoading", value: false },
        ],
      });
      console.log(e);
    })
    .finally();
};

export const getLayers = () => (dispatch) => {
  Request()
    .get("https://assets.mapy.world/layers/layers.json")
    .then((res) => {
      let layers = [];
      res.data.layers.forEach((layer) => {
        layers.push({
          label: getLanguage() === "en" ? layer.name_en : layer.name_ar,
          value: layer,
          layer: layer.act_layers[0],
          categories: layer.act_categories,
        });
      });
      return dispatch({
        type: UPDATE_CONTRIBUTION_PROPS,
        payload: [{ prop: "layersOptions", value: layers }],
      });
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const fetchImages = (Items, isEdit) => (dispatch) => {
  Items.forEach((item, index) => {
    let filterArray = [
      {
        op: 0,
        fieldName: "GisId",
        value: item.id,
      },
    ];

    SendRequest()
      .post("/Gallery/Get", filterArray)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.items.length > 0) {
            if (isEdit !== undefined) {
              dispatch({
                type: UPDATE_CONTRIBUTION_PROPS,
                payload: [
                  {
                    prop: "data.form.Images",
                    value: renderImages(res.data.data.items[0].images),
                  },
                ],
              });
            } else {
              dispatch({
                type: MERGE_CONTRIBUTION_PROPS,
                payload: [
                  {
                    prop: "data.photos",
                    value: res.data.data.items[0],
                  },
                ],
              });
              dispatch({
                type: UPDATE_CONTRIBUTION_PROPS,
                payload: [
                  {
                    prop: "data.items." + index + ".images",
                    value: res.data.data.items[0].images,
                  },
                ],
              });
            }
          }
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally();
  });
};

export const fetchContribution = (id) => (dispatch) => {
  SendRequest()
    .get(`/Contribution/${id}`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data) {
          let item = res.data.data;
          let website = item.osm.website;
          let key = item.osm.description ? item.osm.description.key : "";
          let enName = item.name ? (item.name.en ? item.name.en : "") : "";
          let arName = item.name ? (item.name.ar ? item.name.ar : "") : "";
          let ids = [{ id: item.gisId, index: 0 }];

          dispatch(fetchImages(ids, true));
          dispatch({
            type: UPDATE_CONTRIBUTION_PROPS,
            payload: [
              {
                prop: "data.form",
                value: {
                  Gid: item.gisId,
                  Geometry: item.geometryText,
                  ContributionName: { en: enName, ar: arName },
                  Description: { key },
                  Images: renderImages(item.images),
                  Layer: item.layer,
                  PhoneNumber: item.osm.phone ? item.osm.phone : "",
                  Website: website,
                  WorkingHours: storeWorkingHours(item.osm.workinghours),
                  Categories: item.categories,
                  approved: item.approved,
                  id,
                },
              },

              {
                prop: "data.venueId",
                value: item.venueId,
              },
            ],
          });
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const deleteImage = () => (dispatch) => {};
