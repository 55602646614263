export const _Layers = [
  {
    act_categories: ["Education"],
    act_layers: ["primary_school", "secondary_school", "school"],
    category: "Education",
    category_ar: "تعليم",
    icon: "https://assets.mapy.world/layers/icons/Schools.png",
    name_ar: "تعليم",
    action: "sort",
    is_tags_imp: false,
    tag: "school",
    order: 2,
    name_en: "Education",
  },
  {
    act_categories: ["food"],
    act_layers: ["cafe"],
    category: "Food & Drink",
    category_ar: "اطعمة ومشروبات",
    icon: "https://assets.mapy.world/layers/icons/Cafe.png",
    name_ar: "كوفي شوب",
    action: "sort",
    is_tags_imp: true,
    tag: "cafe",
    order: 1,
    name_en: "Cafe",
  },
  {
    act_categories: ["food"],
    act_layers: ["restaurant"],
    category: "Food & Drink",
    category_ar: "اطعمة ومشروبات",
    icon: "https://assets.mapy.world/layers/icons/Restaurants.png",
    name_ar: "مطاعم",
    action: "sort",
    is_tags_imp: true,
    tag: "resturant",
    order: 1,
    name_en: "Restaurants",
  },
  {
    act_categories: ["religion"],
    act_layers: ["church"],
    category: "Religion",
    category_ar: "دور العبادة",
    icon: "https://assets.mapy.world/layers/icons/Churches.png",
    name_ar: "دور العبادة",
    action: "sort",
    is_tags_imp: false,
    tag: "",
    order: 2,
    name_en: "Religion",
  },
  {
    act_categories: ["transport"],
    act_layers: ["gas_station"],
    category: "Services",
    category_ar: "خدمات",
    icon: "https://assets.mapy.world/layers/icons/Gas.png",
    name_ar: "محطة وقود",
    action: "filter",
    is_tags_imp: true,
    tag: "gas_station",
    order: 1,
    name_en: "Gas",
  },

  {
    act_categories: ["finance"],
    act_layers: ["atm"],
    category: "Services",
    category_ar: "خدمات",
    icon: "https://assets.mapy.world/layers/icons/ATMs.png",
    name_ar: "صراف آلي",
    action: "sort",
    is_tags_imp: true,
    tag: "bank",
    order: 1,
    name_en: "ATMs",
  },
  {
    act_categories: ["finance"],
    act_layers: ["bank"],
    category: "Services",
    category_ar: "خدمات",
    icon: "https://assets.mapy.world/layers/icons/Banks.png",
    name_ar: "بنوك",
    action: "sort",
    is_tags_imp: true,
    tag: "bank",
    order: 2,
    name_en: "Banks",
  },

  {
    act_categories: ["health"],
    act_layers: ["hospital", "health", "doctor", "dentist", "physiotherapist"],
    category: "Services",
    category_ar: "خدمات",
    icon: "https://assets.mapy.world/layers/icons/hospital%20&%20clinics.png",
    name_ar: "مستشفي & عيادات",
    action: "sort",
    is_tags_imp: false,
    tag: "",
    order: 2,
    name_en: "hospital & clinics",
  },
  {
    act_categories: ["accommodation"],
    act_layers: ["lodging"],
    category: "Services",
    category_ar: "خدمات",
    icon: "https://assets.mapy.world/layers/icons/hotels.png",
    name_ar: "فنادق",
    action: "sort",
    is_tags_imp: true,
    tag: "hotel",
    order: 2,
    name_en: "hotels",
  },
  {
    act_categories: ["transport"],
    act_layers: ["parking"],
    category: "Services",
    category_ar: "خدمات",
    icon: "https://assets.mapy.world/layers/icons/parking.png",
    name_ar: "موقف سيارات",
    action: "sort",
    is_tags_imp: false,
    tag: "",
    order: 2,
    name_en: "parking",
  },
  {
    act_categories: ["health"],
    act_layers: ["drugstore", "pharmacy"],
    category: "Shopping",
    category_ar: "تسوق",
    icon: "https://assets.mapy.world/layers/icons/pharmacies.png",
    name_ar: "صيدليات",
    action: "sort",
    is_tags_imp: true,
    tag: "pharmacy",
    order: 1,
    name_en: "pharmacies",
  },
  {
    act_categories: ["shop"],
    act_layers: ["shopping_mall"],
    category: "Shopping",
    category_ar: "تسوق",
    icon: "https://assets.mapy.world/layers/icons/shopping%20centers.png",
    name_ar: "مراكز تسوق",
    action: "sort",
    is_tags_imp: false,
    tag: "",
    order: 2,
    name_en: "shopping centers",
  },
  {
    act_categories: ["health"],
    act_layers: ["gym"],
    category: "Thing To Do",
    category_ar: "اشياء اخري",
    icon: "https://assets.mapy.world/layers/icons/GYMs.png",
    name_ar: "صالة العاب",
    action: "sort",
    is_tags_imp: true,
    tag: "gym",
    order: 2,
    name_en: "GYMs",
  },
  {
    act_categories: ["education"],
    act_layers: ["library", "book_store"],
    category: "Thing To Do",
    category_ar: "اشياء اخري",
    icon: "https://assets.mapy.world/layers/icons/libraries.png",
    name_ar: "مكتبات",
    action: "sort",
    is_tags_imp: false,
    tag: "",
    order: 2,
    name_en: "libraries",
  },

  {
    act_categories: ["transport"],
    act_layers: ["airport"],
    category: "Transportation",
    category_ar: "تنقل",
    icon: "https://assets.mapy.world/layers/icons/Airports.png",
    name_ar: "مطارات",
    action: "sort",
    is_tags_imp: true,
    tag: "airPort",
    order: 2,
    name_en: "Airports",
  },
  {
    act_categories: ["transport"],
    act_layers: ["train_station", "light_rail_station"],
    category: "Transportation",
    category_ar: "تنقل",
    icon: "https://assets.mapy.world/layers/icons/train%20station.png",
    name_ar: "محطة قطار",
    action: "sort",
    is_tags_imp: true,
    tag: "train_station",
    order: 2,
    name_en: "train station",
  },
];
