import React from "react";
import { local } from "../../lang/local";
import { confirmAlert } from "react-confirm-alert";
import { UPDATE_ROLES_PROPS } from "../../Redux/Actions/types";
import { ReactComponent as ViewIcon } from "../../assets/img/view_icon.svg";
import { ReactComponent as AddIcon } from "../../assets/img/add_icon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/img/remove_icon.svg";
import { ReactComponent as EditIcon } from "../../assets/img/edit_icon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/img/profile_icon.svg";
import { ReactComponent as StatusIcon } from "../../assets/img/status_icon.svg";
export const unsetErrorMessage = (general) => {
  general(
    [
      {
        prop: "data.errorMessages",
        value: { Name: "", Description: "" },
      },
    ],
    UPDATE_ROLES_PROPS
  );
};

export const validateForm = (form, general) => {
  if (!form.name || !form.description) {
    window.scrollTo(0, 0);

    if (!form.name) {
      general(
        [
          {
            prop: "data.errorMessages.Name",
            value: local.NameRequired,
          },
        ],
        UPDATE_ROLES_PROPS
      );
    }

    if (!form.description) {
      general(
        [
          {
            prop: "data.errorMessages.Description",
            value: local.DescriptionRequired,
          },
        ],
        UPDATE_ROLES_PROPS
      );
    }
  } else {
    unsetErrorMessage(general);
    return true;
  }
};

export const handleSave = (e, action_type, form, general, history) => {
  e.preventDefault();

  if (validateForm(form, general) === true) {
    let newform = Object.assign({}, form);

    if (form.id === undefined) {
      action_type(newform);
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{local.Sure}</h1>
              <p>{local.ConfirmEdit}</p>
              <button onClick={onClose}>{local.No}</button>
              <button
                onClick={() => {
                  action_type(newform, history);
                  onClose();
                }}
              >
                {local.Yes}
              </button>
            </div>
          );
        },
      });
    }
  }
};

export const getPermissionIcon = (label) => {
  if (label === "View" || label === "عرض") {
    return <ViewIcon className="icon" />;
  } else if (
    label.includes("Add") ||
    label.includes("اضافة") ||
    label.includes("Attach")
  ) {
    return <AddIcon className="icon" />;
  } else if (
    label.includes("Delete") ||
    label.includes("حذف") ||
    label.includes("Remove")
  ) {
    return <RemoveIcon className="icon" />;
  } else if (label.includes("Edit") || label.includes("تعديل")) {
    return <EditIcon className="icon" />;
  } else if (label.includes("الملف الشخصي") || label.includes("Profile")) {
    return <ProfileIcon className="icon" />;
  }

  if (
    label.includes("Accept/Reject Place") ||
    label === "حظر" ||
    label.includes("قبول/رفض المكان") ||
    label.includes("Deactivate")
  ) {
    return <StatusIcon className="icon" />;
  }
};
