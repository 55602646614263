import React from 'react';
import { removeUnsetValuesFromFilters } from '../../General/GeneralFunctions';
import { getUser } from '../../utils/StorageFunctions';
import avater2 from '../../assets/img/avater2.svg';
import TabItem from './TabItem';
import { Row, Col, Button } from 'reactstrap';
import { getLanguage, local } from '../../lang/local';
import plus from '../../assets/img/plus.svg';
import Flatpickr from 'react-flatpickr';
import { UPDATE_TICKETS_PROPS } from '../../Redux/Actions/types';
import { IMAGE_BASE_PATH } from '../../General/StaticKeys';

const TicketList = ({
  data,
  history,
  DeleteTicket,
  general,
  ticketId,
  fetchTicketMessages,
  filterMessageArray,
  data_message,
  closeTicket,
  fetchTickets,
  generalUsers,
}) => {
  const onChangeDate = (date) => {
    if (date.length === 1) {
      general(
        [
          {
            prop: 'data.filterArray.' + 2 + '.value',
            value: date[0],
          },
          {
            prop: 'data.filterArray.' + 3 + '.value',
            value: '',
          },
        ],
        UPDATE_TICKETS_PROPS
      );
    }

    if (date.length === 2) {
      general(
        [
          {
            prop: 'data.filterArray.' + 2 + '.value',
            value: date[0],
          },
          {
            prop: 'data.filterArray.' + 3 + '.value',
            value: date[1],
          },
        ],
        UPDATE_TICKETS_PROPS
      );

      let newFilterArr = [...data.filterArray];

      newFilterArr[2].value = date[0];
      newFilterArr[3].value = date[1];

      newFilterArr = removeUnsetValuesFromFilters(newFilterArr);
      fetchTickets(newFilterArr, 1);
    }
  };
  return (
    <div className='chat_list_card'>
      <Row
        className={getLanguage() === 'en' ? 'alignItem mr-5' : 'alignItem ml-5'}
      >
        <Col className='padding0_margin0' xl={2} lg={2} md={2} sm={12} xs={12}>
          {getUser() ? (
            <center>
              <div className='img_div_chat_header'>
                <img
                  src={
                    !getUser().data.image
                      ? avater2
                      : IMAGE_BASE_PATH + getUser().data.image
                  }
                  className='img_chat_header'
                  alt=''
                />
              </div>
              <p className='mt-2 chat_header_Name'>
                {getUser() !== null ? getUser().data.fullName : ''}
              </p>
            </center>
          ) : null}
        </Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <center>
            {/* <p className="page_title">{local.Tickets}</p> */}
            <Flatpickr
              className='nav-link_chat cursor text-center'
              placeholder={local.RecentChats}
              data-enable-time={false}
              value={data.filterArray[0].value}
              onChange={(date) => onChangeDate(date)}
              options={{
                enableTime: false,
                altInput: true,
                noCalendar: false,
                mode: 'range',
              }}
            />
          </center>
        </Col>

        {/* {getPermissions(true, true).Ticket_Create !== undefined ||
        getPermissions(true, true).Administrator !== undefined ? ( */}
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <center>
            <Button
              className='create_btn'
              onClick={(e) => {
                e.preventDefault();
                history.push('/dashboard/addticket');
              }}
            >
              <img src={plus} alt='' />
              <span>{local.CreateTicket}</span>
            </Button>
          </center>
        </Col>
        {/* // ) : null} */}
      </Row>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mt-2'>
          <TabItem
            data={data}
            DeleteTicket={DeleteTicket}
            general={general}
            history={history}
            ticketId={ticketId}
            fetchTicketMessages={fetchTicketMessages}
            filterMessageArray={filterMessageArray}
            data_message={data_message}
            closeTicket={closeTicket}
            fetchTickets={fetchTickets}
            generalUsers={generalUsers}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TicketList;
