import React, { useState } from "react";
import { Row, Col, Card } from "reactstrap";
import { local } from "../../lang/local";
import {
  UPDATE_GENERAL_PROPS,
  UPDATE_CONTRIBUTION_PROPS,
} from "../../Redux/Actions/types";
import PaginationItem from "../../General/PaginationItem";
import {
  renderAddButton,
  renderMultipleDeleteButton,
  renderCheckoBox,
  renderTableDropDown,
} from "../../General/TableFunctions";
import { getPermissions } from "../../utils/Permissions";
import {
  renderMultipleImages,
  handleArrawCardClick,
  getLatLng,
  ShareIcon,
  renderName,
} from "../../General/GeneralFunctions";
import { confirmAlert } from "react-confirm-alert";
import { renderImages, storeWorkingHours } from "../Branches/BranchFunctions";
import inApprove from "../../assets/img/inApprove.svg";
import inReject from "../../assets/img/inReject.svg";
import inPending from "../../assets/img/inPending.svg";
import approve from "../../assets/img/approve.svg";
import reject from "../../assets/img/reject.svg";
import pending from "../../assets/img/pending.svg";
import Share from "../../General/Share";
import { extractLatLng } from "../../General/MapFunctions";
import { unsetErrorMessage } from "./ContribuationFunctions";

const WebList = ({
  history,
  data,
  changePlaceStatus,
  deleteContribution,
  general,
  deleteMultipleContributions,
  fetchContribtions,
  rows_collabse,
  generalUsers,
}) => {
  const [checked, setChecked] = useState([]);

  const TogglePlace = (e, id, index, type, title) => {
    e.preventDefault();
    if (
      getPermissions(true, true).Contribution_Confirm !== undefined ||
      getPermissions(true, true).Administrator !== undefined
    ) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{local.Sure}</h1>
              <p>{title}</p>
              <button onClick={onClose}>{local.No}</button>
              <button
                onClick={() => {
                  changePlaceStatus(id, index, type);
                  onClose();
                }}
              >
                {local.Yes}
              </button>
            </div>
          );
        },
      });
    }
  };

  const deleteItem = (e, item, index) => {
    e.preventDefault();
    deleteContribution(item.id, index);
  };

  const editItem = (e, item, index) => {
    e.preventDefault();

    unsetErrorMessage(general);
    let website = item.osm.website;
    let key = item.osm.description ? item.osm.description.key : "";
    let englishName = item.name.en;
    let arabicName = item.name.ar;

    let Gid = item.gisId ? +"too_maps:primary_school:180".split(":")[2] : "";
    general(
      [
        {
          prop: "data.form",
          value: {
            Gid,
            Geometry: item.geometryText,
            ContributionName: {
              en:
                englishName === undefined
                  ? ""
                  : englishName === null
                  ? ""
                  : englishName,
              ar:
                arabicName === undefined
                  ? ""
                  : arabicName === null
                  ? ""
                  : arabicName,
            },
            Description: { key },
            Images: renderImages(item.images),
            Layer: item.layer,
            PhoneNumber: item.osm.phone ? item.osm.phone : "",
            Website: website === null ? "" : website,
            WorkingHours: storeWorkingHours(item.osm.workinghours),
            Categories: item.categories,
            Facebook: item.osm.facebook,
            Instgram: item.osm.instgram,
            Twitter: item.osm.twitter,
            Snapshat: item.osm.snapshat,
            Email: item.osm.email,
            Whatsapp: item.osm.whatsapp,
          },
        },

        {
          prop: "data.item_index",
          value: index,
        },
        {
          prop: "data.form.id",
          value: item.id,
        },
      ],
      UPDATE_CONTRIBUTION_PROPS
    );
    history.push(`/dashboard/editplace/${item.id}`);
  };

  const renderTableBody = () => {
    if (data.items) {
      if (data.items.length > 0) {
        return data.items.map((item, index) => {
          if (!item.user && generalUsers.length > 0) {
            item.user = generalUsers.find((u) => u.id === item.userId);
          }
          let name = item.name[local.lang] ? item.name[local.lang] : "";
          return (
            <React.Fragment>
              <Card
                className="header_card"
                style={{
                  width: rows_collabse[index] === true ? "100%" : "300px",
                  zIndex: 10,
                }}
                key={index}
              >
                {handleArrawCardClick(
                  rows_collabse,
                  index,
                  general,
                  name,
                  "marker",
                  getLatLng(item.geometryText)
                )}
                <div className="flex aligmItem fullWidthHeight">
                  <ul class="parent-container">
                    <center className="center">
                      <li class="child-item_place">
                        {renderCheckoBox(
                          item.id,
                          checked,
                          setChecked,
                          "single",
                          "Contribution_Delete"
                        )}
                      </li>
                    </center>
                    <li class="child-item_place center" style={{ width: "7%" }}>
                      {item.id}
                    </li>
                    <li class="child-item_place center">
                      <a
                        href=""
                        className="blueLight"
                        onClick={(e) => {
                          e.preventDefault();
                          general(
                            [
                              {
                                prop: "profileModal",
                                value: { isOpen: true, data: item },
                              },
                            ],
                            UPDATE_GENERAL_PROPS
                          );
                        }}
                      >
                        {renderName(item.name)}
                      </a>
                    </li>

                    {rows_collabse[index] === true ? (
                      <>
                        

                        <li class="child-item_place center">
                          <Share
                            TagItem={ShareIcon}
                            point={extractLatLng(item.geometryText)}
                            type="place"
                            type_id={item.id}
                          />
                        </li>
                        <li class="child-item_place center">
                          {item.osm.phone}
                        </li>
                        <li
                          class="child-item_place center"
                          style={{ width: "12%" }}
                        >
                          {item.osm.description
                            ? item.osm.description.key
                            : null}
                        </li>
                        <li class="child-item_place center">
                          {item.osm.workinghours ? (
                            Object.values(item.osm.workinghours).length > 0 ? (
                              <p
                                className="view_text"
                                onClick={(e) => {
                                  e.preventDefault();
                                  general(
                                    [
                                      {
                                        prop: "workingDaysModal.isOpen",
                                        value: true,
                                      },
                                      {
                                        prop: "workingDaysModal.data",
                                        value: item.osm.workinghours,
                                      },
                                    ],
                                    UPDATE_GENERAL_PROPS
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {local.View}
                              </p>
                            ) : null
                          ) : null}
                        </li>

                        <li class="child-item_place center">
                          {item.user ? item.user.fullName : null}
                        </li>

                        <li class="child-item_place" style={{ width: "12%" }}>
                          <div className="container_status center">
                            <div style={{ width: "50px" }} className="center">
                              <span>{local.Accept}</span>
                            </div>
                            <div style={{ width: "50px" }} className="center">
                              <span>{local.Pedning}</span>
                            </div>

                            <div style={{ width: "50px" }} className="center">
                              <span>{local.Reject}</span>
                            </div>
                          </div>
                          <div
                            className="container_status center"
                            style={{
                              background: "#E6E6E6",
                              boxShadow:
                                "0px 2.97015px 2.97015px rgba(0, 0, 0, 0.25)",
                              borderRadius: "20.0485px",
                              border: "0.37px solid #12A9CC",
                            }}
                          >
                            <div style={{ width: "50px" }} className="center">
                              <button
                                disabled={item.approved === true ? true : false}
                                onClick={(e) =>
                                  TogglePlace(
                                    e,
                                    item.id,
                                    index,
                                    true,
                                    local.ConfirmAcceptPlace
                                  )
                                }
                                className={
                                  item.approved === true
                                    ? "selected_btn_status"
                                    : "inSelect_btn_status"
                                }
                              >
                                {item.approved === true ? (
                                  <img
                                    src={approve}
                                    fill="red"
                                    className="imgFull"
                                  />
                                ) : (
                                  <img
                                    src={inApprove}
                                    fill="red"
                                    className="imgFull"
                                  />
                                )}
                              </button>
                            </div>
                            <div style={{ width: "50px" }} className="center">
                              <button
                                className="selected_btn_status"
                                disabled={true}
                              >
                                {item.approved === null ? (
                                  <img
                                    src={pending}
                                    fill="red"
                                    className="imgFull"
                                  />
                                ) : (
                                  <img
                                    src={inPending}
                                    fill="red"
                                    className="imgFull"
                                  />
                                )}
                              </button>
                            </div>
                            <div style={{ width: "50px" }} className="center">
                              <button
                                className={
                                  item.approved === false
                                    ? "selected_btn_status"
                                    : "inSelect_btn_status"
                                }
                                disabled={
                                  item.approved === false ? true : false
                                }
                                onClick={(e) =>
                                  TogglePlace(
                                    e,
                                    item.id,
                                    index,
                                    false,
                                    local.ConfirmRejectPlace
                                  )
                                }
                              >
                                {item.approved === false ? (
                                  <img
                                    src={reject}
                                    fill="red"
                                    className="imgFull"
                                  />
                                ) : (
                                  <img
                                    src={inReject}
                                    fill="red"
                                    className="imgFull"
                                  />
                                )}
                              </button>
                            </div>
                          </div>
                        </li>

                        <li class="child-item_place center">
                          {renderMultipleImages(item.images, general)}
                        </li>
                        <li
                          class="child-item_place center"
                          style={{ width: "7%" }}
                        >
                          {renderTableDropDown(
                            item,
                            index,
                            deleteItem,
                            editItem,
                            "Contribution_Delete",
                            "Contribution_Update"
                          )}
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              </Card>

              <hr
                className="border_list_hr"
                style={{
                  width: rows_collabse[index] === true ? "100%" : "300px",
                }}
              />
            </React.Fragment>
          );
        });
      }
    }
  };
  return (
    <React.Fragment>
      <Card
        className="header_card cursor"
        style={{
          width:
            rows_collabse.length > 0
              ? rows_collabse.find((col) => col === true)
                ? "100%"
                : "300px"
              : "300px",
          height: "100px",

          zIndex: 10,
        }}
      >
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderAddButton(
              local.AddPlace,
              history,
              "/dashboard/addplace",
              "Contribution_Create"
            )}

            {renderMultipleDeleteButton(
              checked,
              setChecked,
              deleteMultipleContributions,
              data.isLoadingDelete,
              "Contribution_Delete"
            )}
          </Col>
        </Row>
        <div className="flex aligmItem fullWidthHeight">
          <ul class="parent-container">
            <center className="center">
              <li class="child-item_place">
                {data.items
                  ? data.items.length > 0
                    ? renderCheckoBox(
                        data.items.map((i) => i.id),
                        checked,
                        setChecked,
                        "all",
                        "Contribution_Delete"
                      )
                    : null
                  : null}
              </li>
            </center>
            <li class="child-item_place center bold" style={{ width: "7%" }}>
              {local.Id}
            </li>
            <li class="child-item_place center bold"> {local.Name}</li>

            {rows_collabse.length > 0 ? (
              rows_collabse.find((col) => col === true) ? (
                <>
                  <li className="child-item center bold">{local.ShareLink}</li>

                  <li class="child-item_place center bold">{local.Mobile}</li>

                  <li
                    class="child-item_place center bold"
                    style={{ width: "12%" }}
                  >
                    {local.Address}
                  </li>

                  <li class="child-item_place center">{local.OpenningHours}</li>
                  <li class="child-item_place center bold">{local.AddedBy}</li>
                  <li
                    class="child-item_place center bold"
                    style={{ width: "12%" }}
                  >
                    {local.PlaceStatus}
                  </li>
                  <li class="child-item_place center bold">{local.Images}</li>
                  <li
                    class="child-item_place center bold"
                    style={{ width: "7%" }}
                  ></li>
                </>
              ) : null
            ) : null}
          </ul>
        </div>
      </Card>
      <hr className="border_list_hr" />
      {renderTableBody()}

      <Card
        className="header_card"
        style={{
          width: "300px",
          zIndex: 100,
        }}
      >
        <div className="center fullWidthHeight mb-4 mt-4">
          {data.pagination ? (
            <PaginationItem
              pagination={data.pagination}
              general={general}
              fetchData={fetchContribtions}
              filterArray={data.filterArray}
            />
          ) : null}
        </div>
      </Card>
    </React.Fragment>
  );
};

export default WebList;
