import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import 'toasted-notes/src/styles.css';
import './assets/style/map.css';
import './assets/style/form.css';
import 'react-phone-input-2/lib/style.css';
import './assets/style/select.css';
import 'moment-timezone';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'aos/dist/aos.css';
import 'flatpickr/dist/themes/material_blue.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './Redux/Store';
import AOS from 'aos';

AOS.init();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
